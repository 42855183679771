export default class MovideskHelper {
  public static setVisibilityOfChat(element: HTMLElement|null, visibility: 'hidden' | 'visible'): void {
    if (element === null) {
      return;
    }

    element.style.visibility = visibility;
  }

  public static removeMovideskScript(element: HTMLElement|null, document: Document): void {
    if (element === null) {
      return;
    }

    document.head.removeChild(element);
  }
}
