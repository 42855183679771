import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c(VProgressCircular,{attrs:{"size":100,"width":7,"indeterminate":"","color":"primary"}}):_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VWindow,{attrs:{"show-arrows":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"arrow-buttons-login-news",attrs:{"icon":"","color":"white"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"arrow-buttons-login-news",attrs:{"icon":"","color":"white"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)]}}]),model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.allNewsGroups),function(newsGroup,index){return _c(VWindowItem,{key:index},[_c('div',{staticClass:"d-flex flex-column align-center"},_vm._l((newsGroup),function(news,groupIndex){return _c('div',{key:("" + (news.text) + groupIndex),class:_vm.getClassBasedOnIndex(newsGroup, groupIndex),on:{"click":function($event){return _vm.redirectTo(news.link)}}},[_c('div',[_c('img',{staticClass:"news-image",attrs:{"src":news.image_url}})]),_c('div',[_c('span',{staticClass:"news-text news-title"},[_vm._v(_vm._s(news.title))]),_c('span',{staticClass:"news-text news-subtext"},[_vm._v(_vm._s(news.text))])]),_c('div',[_c(VIcon,{staticClass:"login-news-icons",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(news.icon)+" ")])],1)])}),0)])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }