export default class Bank {
  constructor(
    public account: string,
    public agency: string,
    public code: string,
    public company: number | string | null,
    public id: number,
    public name: string,
    public shortName: string,
    public idCustomer: string,
  ) { }

  static make(data: any): Bank {
    const idCustomer = data.idCustomer
      ? data.idCustomer.trim()
      : data.idCustomer;

    return new Bank(
      data.account ?? '',
      data.agency ?? '',
      data.code ?? '',
      data.company,
      data.id,
      data.name ?? '',
      data.shortName ?? '',
      idCustomer ?? '',
    );
  }
}
