import qs from 'qs';
import Repository from '@/repositories/base/Repository';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import SingularNews from '@/domain/models/SingularNews';
import NewsList from '@/domain/models/NewsList';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';
import LoginNews from '@/domain/models/LoginNews';

export default class NewsRepository extends Repository {
  async getPaginatedNews(
    params: ILaravelOrdenationTableParams,
  ): Promise<IDataTableWithLength<NewsList[]>> {
    const { data: response } = await this.datasource.get('news/paginated', {
        params,
        paramsSerializer: qs.stringify,
    });

    return {
      data: response.data.map(NewsList.make),
      total: response.total,
    };
  }

  async getNewsForLoginScreen(): Promise<any> {
    const { data: response } = await this.datasource.get('news/login');

    return response.data.map(LoginNews.make);
  }

  async getNewsById(id: number): Promise<SingularNews> {
    const { data: response } = await this.datasource.get(`news/find/${id}`);

    return SingularNews.make(response.data);
  }

  async createNews(params: FormData): Promise<void> {
    await this.datasource.send('news', params);
  }

  async updateNews(id: number, params: FormData): Promise<void> {
    await this.datasource.send(`news/${id}`, params);
  }

  async delete(id: number): Promise<void> {
    await this.datasource.destroy(`news/${id}`);
  }
}
