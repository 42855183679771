















































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { PublicClientApplication } from '@azure/msal-browser';
import { getModule } from 'vuex-module-decorators';
import { VueRecaptcha } from 'vue-recaptcha';
import VUE_APP_AZURE_ACTIVE_DIRECTORY_URL from '@config/activeDirectory.ts';
import { FRONT_ENDPOINT } from '@config/api.js';
import CAPTCHA_SITE_KEY from '@config/captcha.ts';
import LoginHelper from '@/views/login/helpers/LoginHelper';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import UserGroupModuleRepository from '@/repositories/UserGroupModuleRepository';
import AuthenticationRepository from '@/repositories/AuthenticationRepository';
import InputRules from '@/helpers/InputRulesHelper';
import { VForm } from '@/types/VForm';
import Authentication from '@/domain/models/Authentication';
import { AxiosError } from 'axios';
import ActiveDirectoryCredentials from '@/domain/models/ActiveDirectoryCredentials';

@Component({
  components: {
    VueRecaptcha,
  },
})
export default class LoginForm extends Vue {
  inputRules: InputRules = new InputRules();

  authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  authenticationRepository: AuthenticationRepository =
    new AuthenticationRepository();
  userGroupModuleRepository: UserGroupModuleRepository =
    new UserGroupModuleRepository();

  mustShowCaptcha: boolean = false;
  recaptchaValidated: boolean = false;
  rememberPassword: boolean = false;
  loading: boolean = false;

  captchaSiteKey: string = CAPTCHA_SITE_KEY;
  submitedMail: string = '';
  mail: string = '';
  password: string = '';

  @Ref('LoginForm') readonly loginForm!: VForm;
  @Ref('vueRecaptcha') readonly vueRecaptcha!: { reset: () => void };

  get getIfIsLowerDesktopViewport(): boolean {
    return (
      this.$vuetify.breakpoint.width <= 1366
      && this.$vuetify.breakpoint.width > 960
    );
  }

  get captchaAreValidated(): boolean {
    return this.mustShowCaptcha && !this.recaptchaValidated;
  }

  async getAdUsersByDomain(): Promise<ActiveDirectoryCredentials|null> {
    try {
      const adCredentials = await this.authenticationRepository.getActiveDirectory(this.mail);

      return adCredentials;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os dados de AD.');
      return null;
    }
  }

  async authenticateUser(): Promise<Authentication | null> {
    try {
      const authData = await this.authenticationRepository.authenticate(
        this.mail,
        this.password,
      );

      return authData;
    } catch (error) {
      const axiosError = error as AxiosError;

      if (
        axiosError.response
        && axiosError.response.data
        && axiosError.response.data.login_attempts
      ) {
        this.mustShowCaptcha = axiosError.response.data.login_attempts >= 5;
      }

      const errorMessage = this.$helpers.extractAxiosErrorMessage(
        error,
        'Houve um problema ao tentar fazer login.',
      );

      this.$notification.error(errorMessage);
      this.loading = false;

      return null;
    }
  }

  async loadAvailableModules(): Promise<void> {
    try {
      const routeName = await LoginHelper.loadAvailableModules();

      this.$router.replace({ name: routeName });
    } catch (error) {
      const typedError = error as Error;
      let errorMessage = 'Houve um problema ao definir os módulos no usuário!';

      if (typedError.name === 'InvalidResponseException') {
        errorMessage = typedError.message;
      }

      this.$notification.error(errorMessage);
      this.loading = false;
    }
  }

  handleForgotPassword(): void {
    if (this.loading) {
      return;
    }

    this.$router.push({ name: 'ForgotPassword' });
  }

  async handleLogin(): Promise<void> {
    if (!this.loginForm.validate()) {
      this.$notification.warn('Os campos de login estão inválidos.');

      return;
    }

    if (this.submitedMail !== this.mail) {
      this.mustShowCaptcha = false;
    }

    this.submitedMail = this.mail;

    if (this.captchaAreValidated) {
      this.$notification.error('O Captcha deve ser resolvido.');
      return;
    }

    this.loading = true;

    const adCredentials = await this.getAdUsersByDomain();

    if (adCredentials) {
      const authOnAD = await this.authenticateWithActiveDiretory(adCredentials);

      if (!authOnAD) {
        this.loading = false;
        return;
      }
    }

    if (this.password === '' && adCredentials === null) {
      this.$notification.warn('A senha é obrigatória para esse usuário.');
      this.loading = false;

      return;
    }

    const authenticatedUser = await this.authenticateUser();

    if (authenticatedUser === null) {
      return;
    }

    this.$session.start();

    if (authenticatedUser.forceChangePassword) {
      this.$session.set('userMailToRedefinePassword', authenticatedUser.email);
      this.authenticationModule.setUserMailToRedefinePassword(authenticatedUser.email);

      this.$router.push({ name: 'ResetPassword' });
      return;
    }

    LoginHelper.setSessionPermissions(authenticatedUser);

    await this.loadAvailableModules();
  }

  async authenticateWithActiveDiretory(
    adCredentials: ActiveDirectoryCredentials,
  ): Promise<boolean> {
    try {
      const msalInstance = new PublicClientApplication({
        auth: {
          clientId: adCredentials.clientId,
          authority: `${VUE_APP_AZURE_ACTIVE_DIRECTORY_URL}/${adCredentials.tenantId}`,
          redirectUri: `${FRONT_ENDPOINT}/redirect`,
        },
      });

      await msalInstance.loginPopup({
        scopes: ['user.read'],
        extraQueryParameters: {
          login_hint: this.mail,
        },
      });

      await this.authenticationRepository.upsertUserFromActiveDirectory({
        company_group_id: adCredentials.companyGroupId,
        mail: this.mail,
        username: this.mail.substring(0, this.mail.indexOf('@')),
      });

      return true;
    } catch (error) {
      console.log(error);
      this.$notification.error('Houve um problema ao autenticar com o AD.');
      return false;
    }
  }

  handleEnterContact(): void {
    this.$router.push({ name: 'ContactSupport' });
  }

  handleRecaptchaVerified(): void {
    this.recaptchaValidated = true;
  }

  handleRecaptchaExpired(): void {
    this.vueRecaptcha.reset();
    this.recaptchaValidated = false;
  }

  handleRecaptchaFailed(): void {
    this.recaptchaValidated = false;
  }

  handleRecaptchaError(): void {
    this.recaptchaValidated = false;
  }
}
