import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VList,{staticStyle:{"cursor":"pointer","background-color":"rgb(84, 86, 107)"}},[_c(VListItem,{staticStyle:{"cursor":"text"},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c(VAvatar,{staticClass:"mr-2",attrs:{"size":"50"}},[(_vm.avatar)?_c('img',{attrs:{"src":_vm.avatar,"alt":"avatar"}}):_vm._e(),(!_vm.avatar)?_c('img',{attrs:{"src":require("@/assets/user-placeholder.png"),"alt":"avatar"}}):_vm._e()]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.auth.name)+" ")]),_c(VListItemSubtitle,{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.auth.email)+" ")])],1)],1)],1),_c(VDivider,{staticClass:"mt-n1"}),_c(VList,{staticStyle:{"cursor":"pointer"}},[_c(VListItem,{staticClass:"my-n4",on:{"click":function($event){return _vm.$router.push('/user/profile')}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Acessar o perfil ")])],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Sair")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }