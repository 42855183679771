










































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import EAction from '@/domain/enums/InstructionRemittanceAction';
import EStatus from '@/domain/enums/InstructionRemittanceStatus';
import InstructionRemittance from '@/domain/models/InstructionRemittance';

@Component
export default class InstructionsRemittanceListMenu extends Vue {
  @Prop({
    type: Object as () => InstructionRemittance,
  }) item!: InstructionRemittance;

  @Emit()
  action(type: EAction) {
    return { type, items: [this.item] };
  }

  readonly Status = EStatus;
  readonly Action = EAction;

  get status(): EStatus {
    return this.item.status;
  }

  get isRejected(): boolean {
    return this.status === EStatus.REJECTED;
  }

  get isPending(): boolean {
    return this.status === EStatus.PENDING;
  }
}
