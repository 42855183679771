import Repository from '@/repositories/base/Repository';
import EconomicGroup from '@/domain/models/EconomicGroup';
import IUpsertEconomicGroupRequestParams from '@/repositories/parameters/IUpsertEconomicGroupRequestParams';
import EconomicGroupList from '@/domain/models/EconomicGroupList';
import EconomicGroupGroupingTypesEnum from '@/views/economicGroups/enums/EconomicGroupGroupingTypesEnum';
import EconomicGroupCustomer from '@/domain/models/EconomicGroupCustomer';
import EconomicGroupCompany from '@/domain/models/EconomicGroupCompany';

export default class EconomicGroupRepository extends Repository {
  public async getEconomicGroups(
    group: number,
    companyIds: number[],
    groupingType?: EconomicGroupGroupingTypesEnum,
  ): Promise<EconomicGroupList[]> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${group}/economic-groups`,
      {
        params: { grouping_type: groupingType },
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    if (this.isStatusOK(status)) {
      return response.data.map(EconomicGroupList.make);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a listagem de grupos econômicos!');
  }

  public async getEconomicGroup(
    group: number,
    companyIds: number[],
    id: number,
  ): Promise<EconomicGroup> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${group}/economic-groups/${id}`,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    if (this.isStatusOK(status)) {
      return EconomicGroup.make(response.data);
    }

    throw new Error('Ops, algo inesperado ocorreu na obtenção do grupo econômico!');
  }

  async getCustomersThatAreNotInUse(
    companyGroupId: number,
    companyIds: number[],
    search: string,
  ): Promise<EconomicGroupCustomer[]> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/economic-groups-customers`,
      {
        params: { search },
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado aconteceu na listagem dos clientes.');
    }

    return response.data.map(EconomicGroupCustomer.make);
  }

  async getCompaniesThatAreNotInUse(
    companyGroupId: number,
    companyIds: number[],
    search: string,
  ): Promise<EconomicGroupCompany[]> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/economic-groups-companies`,
      {
        params: { search },
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado aconteceu na listagem das empresas.');
    }

    return response.data.map(EconomicGroupCompany.make);
  }

  public async sendEconomicGroup(
    group: number,
    companyIds: number[],
    record: IUpsertEconomicGroupRequestParams,
  ): Promise<void> {
    const { status } = await this.datasource.send(
      `company-groups/${group}/economic-groups`,
      record,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a criação do grupo econômico!');
    }
  }

  public async updateEconomicGroup(
    group: number,
    companyIds: number[],
    id: number,
    record: IUpsertEconomicGroupRequestParams,
  ): Promise<void> {
    const { status } = await this.datasource.update(
      `company-groups/${group}/economic-groups/${id}`,
      record,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a atualização do grupo econômico!');
    }
  }

  public async destroyEconomicGroup(
    group: number,
    companyIds: number[],
    id: number,
  ): Promise<void> {
    const { status } = await this.datasource
      .destroy(`company-groups/${group}/economic-groups/${id}`, {
        headers: { 'company-ids': JSON.stringify(companyIds) },
      });

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a exclusão do grupo econômico!');
    }
  }
}
