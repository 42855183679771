export default class PermissionCategory {
  constructor(
    public id : number,
    public name : string,
  ) {}

  static make(data : any) : PermissionCategory {
    return new PermissionCategory(
      data.id,
      data.name,
    );
  }
}
