import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"close-on-click":false,"activator":_vm.selected,"max-width":"350px"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,{attrs:{"flat":"","color":"grey lighten-4","min-width":"350px"}},[_c(VToolbar,{attrs:{"flat":"","dense":"","color":_vm.getToolbarTitleColorBasedOnStatus()}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.event.clientName)+" ")]),_c(VSpacer),_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","color":"grey lighten-2","buttonIcon":"mdi-pencil","tooltipText":"Editar"},on:{"click":_vm.handleEdit}}),_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","color":"grey lighten-2","buttonIcon":"mdi-account","tooltipText":"Histórico"},on:{"click":_vm.handleClientClick}}),(_vm.event.status !== 'success' && _vm.event.status !== 'failure')?_c('button-options',{attrs:{"fab":"","icon":"","small":"","color":"grey lighten-2","icon-symbol":"mdi-dots-vertical","closeOnContentClick":true,"offsetY":true,"options":_vm.eventInteractionOptions},on:{"selected-option":function($event){return _vm.handleSelectedOption($event)}}}):_vm._e()],1),_c(VCardText,[(_vm.event)?_c('span',[_vm._v(" "+_vm._s(_vm.event.observation)+" ")]):_vm._e()]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"red darken-1"},on:{"click":function($event){_vm.model = false}}},[_vm._v(" FECHAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }