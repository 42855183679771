























































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
  Ref,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { DateTime } from 'luxon';
import { formatErrorForNotification } from '@/utils/error';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import PaymentMethodType from '@/domain/enums/PaymentMethodType';
import IInvoicePaymentData from '@/views/paymentRemittances/interfaces/IInvoicePaymentData';
import { formatCNPJ } from '../../utils';

interface Data {
  code: string,
  document: string,
  date: string,
  value: number,
  increase: number,
  fees: number,
  balance: number,
}

@Component
export default class PaymentRemittancesActionEditOther extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Prop({
    type: Object as () => CompanyGroupConfig,
  }) groupConfig!: CompanyGroupConfig;

  @Emit()
  close(reload: boolean = false) {
    return reload;
  }

  @Watch('item', { immediate: true })
  onChangeItem() {
    if (this.item) {
      const supplierDocument = this.item.account_payable_supplier_document;
      const document = this.justNumbers(supplierDocument).length <= 11
        ? formatCNPJ(supplierDocument)
        : formatCNPJ(supplierDocument);

      const date = this.item.period
        && DateTime.fromFormat(this.item.period, 'yyyy-MM-dd').isValid
          ? this.item.period
          : '';

      this.data = {
        code: this.item.code,
        date,
        document,
        value: this.item.total,
        increase: this.item.increase,
        fees: this.item.fees,
        balance: this.formatSum(this.item.total, this.item.fees),
      };

      this.isDARF = this.item.form_of_payment_type === PaymentMethodType.DARF;
      this.isGPS = this.item.form_of_payment_type === PaymentMethodType.GPS;
    }
  }

  readonly paymentRemittanceRepository: PaymentRemittanceRepository =
    new PaymentRemittanceRepository();

  readonly required = (value: string | number) => !!value || 'Campo obrigatório!';
  readonly requiredMinDocument = (value: string) => {
    const { length } = this.justNumbers(value);
    return (length === 11 || length === 14) || 'Valor inválido!';
  };

  data: Data = {
    code: '',
    date: '',
    document: '',
    value: 0,
    increase: 0,
    fees: 0,
    balance: 0,
  };

  isDARF: boolean = false;
  isGPS: boolean = false;

  get codeLabel(): string {
    if (this.isDARF) return 'Código da Receita';
    if (this.isGPS) return 'Código de Pagamento';
    return 'code';
  }

  get documentLabel(): string {
    if (this.isDARF) return 'Número do CPF ou CNPJ';
    if (this.isGPS) return 'Identificador';
    return 'document';
  }

  get dateLabel(): string {
    if (this.isDARF) return 'Período de Apuração';
    if (this.isGPS) return 'Competência';
    return 'date';
  }

  get valueLabel(): string {
    if (this.isDARF) return 'Valor do Principal';
    if (this.isGPS) return 'Valor do INSS';
    return 'value';
  }

  get increaseLabel(): string {
    if (this.isDARF) return 'Valor do Juros e/ou Encargos DL 1025/69';
    if (this.isGPS) return 'ATM/Multa e Juros';
    return 'increase';
  }

  get feesLabel(): string {
    if (this.isDARF) return 'Valor da Multa';
    if (this.isGPS) return 'Valor Outras Entidades';
    return 'fees';
  }

  get balanceLabel(): string {
    if (this.isDARF) return 'Valor Total';
    if (this.isGPS) return 'Total';
    return 'balance';
  }

  get documentMask(): string {
    let mask: string = '##################';

    if (!this.data.document) return mask;

    const value = this.justNumbers(this.data.document);

    if (value.length <= 11) {
      mask = '###.###.###-##';
    } else if (value.length >= 12) {
      mask = '##.###.###/####-##';
    }

    return mask;
  }

  justNumbers(value: string): string {
    return value.replace(/[^\d]/g, '');
  }

  changeBalance() {
    const { value, fees } = this.data;
    this.data.balance = this.formatSum(value, fees);
  }

  save(): void {
    if (!this.isDARF && !this.isGPS) {
      this.$notification.warn('Forma de pagamento não indentificada! Recarregue a página e tente novamente.');
      return;
    }

    if (!this.form.validate()) {
      this.$notification.warn('Campos inválidos.');
      return;
    }

    if (this.item.origin !== 'inncash') {
      this.handleUpdate();
    } else {
      this.handleUpdatePaymentOnInvoice();
    }
  }

  reset(): void {
    this.data = {
      code: '',
      date: '',
      document: '',
      value: 0,
      increase: 0,
      fees: 0,
      balance: 0,
    };

    this.isDARF = false;
    this.isGPS = false;

    this.form.resetValidation();
  }

  async handleUpdate(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const company = this.item.company_id;
      const { use_ncc: NCC, id: group } = this.groupConfig;
      const useNCC = Boolean(NCC) && this.item.type === 'NCC';
      const type = useNCC ? 'account_receivable' : 'account_payable';

      const document = this.data.document.replace(/\D/g, '');

      let period = this.data.date;

      if (this.isGPS) {
        const dateDay = DateTime
          .fromFormat(period, 'yyyy-MM')
            .daysInMonth;

        if (dateDay) period += `-${dateDay}`;
      }

      const formattedData: Record<string, string | number | null> = {
        code: this.data.code,
        document,
        period,
        fees: this.isDARF ? this.data.fees : null,
        value_rent: this.isGPS ? this.data.fees : null,
        added_value: this.data.increase,
        value: this.data.balance,
        type,
      };

      const params = {
        id: this.item.id_customer,
        data: formattedData,
        event: 'update_darf_and_gps',
      };

      const response = await this.paymentRemittanceRepository.updateERP(group, company, params);

      if (response.error) throw new Error('Ocorreu um erro na alteração do dados do bancários!');

      const supplierFields = [
        {
          field: 'E2_ZCODREC',
          value: this.data.code,
        },
        {
          field: 'E2_ZCGC',
          value: document,
        },
        {
          field: 'E2_ZPERIOD',
          value: period,
        },
        {
          field: 'E2_ACRESC',
          value: this.data.increase,
        },
      ];

      if (this.isDARF) {
        supplierFields.push({
          field: 'E2_MULTA',
          value: this.data.fees,
        });
      }

      if (this.isGPS) {
        supplierFields.push({
          field: 'E2_ZVLENT',
          value: this.data.fees,
        });
      }

      await Promise.all(
        supplierFields.map((fields) => this.paymentRemittanceRepository.updateInternal(
          'updateImportTable',
          {
            identify_values: 'CP',
            id: this.item.id,
            ...fields,
          },
        )),
      );

      if (!response.error) {
        this.$notification.success('Dados salvos com sucesso!');
        this.close(true);
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      this.$dialog.stopLoading();
    }
  }

  async handleUpdatePaymentOnInvoice(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const companyId = this.item.company_id;
      const { id: groupId } = this.groupConfig;

      let period = this.data.date;

      if (this.isGPS) {
        const dateDay = DateTime
          .fromFormat(period, 'yyyy-MM')
            .daysInMonth;

        if (dateDay) period += `-${dateDay}`;
      }

      const paymentData: IInvoicePaymentData = {
        account_reference_code: this.data.code,
        account_document: this.data.document.replace(/\D/g, ''),
        period,
        invoice_value_addition: this.data.increase,
        type: this.isDARF ? 'darf' : 'gps',
      };

      if (this.isDARF) {
        paymentData.fine = this.data.fees;
      }

      if (this.isGPS) {
        paymentData.fees = this.data.fees;
      }

      await this.paymentRemittanceRepository.changeInvoice(
        groupId,
        companyId,
        this.item.id_customer,
        paymentData,
      );

      this.$dialog.stopLoading();
      this.close(true);
      this.$notification.success('Pagamento alterado com sucesso!');
    } catch (error) {
      this.$notification.error('Houve um problema ao atualizar os dados de pagamento da fatura.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  formatCurrency(value: number): string {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    });
  }

  formatSum(total: number, fees: number): number {
    return Math.round((total + fees) * 100) / 100;
  }
}
