import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"pageTitle":"Remessas de Cobrança"}}),_c(VCard,{staticClass:"elevation-0 p-4"},[_c(VCardTitle,{staticClass:"p-0"},[_c(VRow,{attrs:{"justify":"end","align":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","x-large":""},on:{"click":_vm.handleToggle}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.getToggleIcon())+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getToggleTooltip()))])])],1),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"loading":_vm.loading,"color":"success","depressed":"","large":"","block":"","dark":""},on:{"click":_vm.handleValidate}},[_vm._v(" VISUALIZAR ")])],1)],1)],1),_c(VCardText,{staticClass:"p-0"},[_c('billing-remittance-filter',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilter),expression:"showFilter"}],ref:"billing-remittance-filter",on:{"validate":_vm.handleLoad}}),(_vm.showList)?_c('billing-remittance-list',{attrs:{"data":_vm.generateData,"list":_vm.list,"limit":_vm.limit,"approval":_vm.approval,"companies":_vm.currentData.companies,"sort-and-direction":_vm.sortAndDirection},on:{"reload":_vm.handleReload,"on-sort-list":_vm.handleSortList}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }