import PermissionType from '../enums/PermissionType';
import PermissionCategory from './PermissionCategory';

export default class Permission {
  constructor(
    public id : number,
    public name : string,
    public reference : string,
    public category : PermissionCategory|null,
    public type : PermissionType,
    public isActive? : boolean,
  ) {}

  static make(data : any) : Permission {
    const category = data.permission_category
     ? PermissionCategory.make(data.permission_category)
     : null;
    const typeKey = data.type.toUpperCase() as keyof typeof PermissionType;

    return new Permission(
      data.id,
      data.name,
      data.code_reference,
      category,
      PermissionType[typeKey],
      data.is_active ?? false,
    );
  }
}
