



































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import PreNegotiationRepository from '@/repositories/PreNegotiationRepository';
import TradingPolicyRepository from '@/repositories/TradingPolicyRepository';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import PreNegotiationDeadline from '@/views/preNegotiation/deadline/PreNegotiationDeadline.vue';
import PreNegotiationSubscribers from '@/views/preNegotiation/subscriber/PreNegotiationSubscribers.vue';
import PreNegotiationInstallments from '@/views/preNegotiation/installment/PreNegotiationInstallments.vue';
import AccountReceivablePreNegotiation from '@/domain/models/AccountReceivablePreNegotiation';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import IUpsertPreNegotiationParams from '@/domain/interfaces/IUpsertPreNegotiationParams';
import IRedefinedAccountsNegotiationRequestParams from '@/domain/interfaces/IRedefinedAccountsNegotiationRequestParams';
import IAccountRequestParams from '@/domain/interfaces/IAccountRequestParams';
import AccountReceivablePreNegotiationDeadline from '@/domain/models/AccountReceivablePreNegotiationDeadline';
import FinancialStatementBillingOriginType from '@/views/financialStatement/types/FinancialStatementBillingOriginType';

@Component({
  components: {
    PreNegotiationInstallments,
    PreNegotiationSubscribers,
    PreNegotiationDeadline,
  },
})
export default class CreatePreNegotiation extends Vue {
  private readonly preNegotiationRepository
    : PreNegotiationRepository = new PreNegotiationRepository();
  private readonly tradingPolicyRepository
    : TradingPolicyRepository = new TradingPolicyRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);

  public hasPoliciesLoaded: boolean = false;

  public actualStep: 1 | 2 | 3 = 1;

  public accountsReceivable: AccountReceivablePreNegotiation[] = [];

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public get identifierGroupValue(): string {
    return this.$route.params.groupIdentifierValue;
  }

  public get origin(): FinancialStatementBillingOriginType|null {
    return this.preNegotiationModule.origin;
  }

  public get componentNameByActualStep(): string {
    return {
      1: 'PreNegotiationInstallments',
      2: 'PreNegotiationDeadline',
      3: 'PreNegotiationSubscribers',
    }[this.actualStep];
  }

  public get canConfirm(): boolean {
    if (this.actualStep === 1 && this.preNegotiationModule.hasInstallmentsDefined) {
      return true;
    }

    if (
      this.actualStep === 2
      && (
        this.preNegotiationModule.deadlineRedefinedAccounts.length > 0
        && this.preNegotiationModule.expirationDate
      )
    ) {
      return true;
    }

    if (
      this.actualStep === 3
    ) {
      return true;
    }

    return false;
  }

  public get userId(): number {
    return Number(this.authenticationModule.user.user_id);
  }

  public created(): void {
    if (this.origin === null) {
      this.$router.push({ name: 'BillingPendencyList' });

      return;
    }

    if (!this.preNegotiationModule.hasSelectedSecurity) {
      this.$notification.error('Nenhum título selecionado para esta Pré-Negociação.');

      if (this.origin === 'economic_group') {
        this.$router.push({
          name: 'FinancialStatementBillingPendenciesEconomicGroup',
          params: { economicGroupId: this.identifierGroupValue },
        });
      } else {
        this.$router.push({
          name: 'ClientFinancialStatement',
          params: { origin: this.origin },
        });
      }
    }

    this.getTradingPolicyNegotiationByOperator();
  }

  public beforeDestroy(): void {
    this.preNegotiationModule.resetPreNegotiationStoreData();
  }

  public async getTradingPolicyNegotiationByOperator(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const tradingPolicy = await this.tradingPolicyRepository.getTradingPolicyByOperator(
        this.companyGroupId,
        this.userId,
      );

      if (tradingPolicy) {
        this.preNegotiationModule.setTradingPolicy(tradingPolicy);
      }

      this.hasPoliciesLoaded = true;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar a politica de negociação.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async savePreNegotiation(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const {
        quantity,
        periodicity,
        customPeriodicity,
      } = this.preNegotiationModule.installmentConfigs;
      const isSelectingAll = !!(
        this.preNegotiationModule.selectedSecuritiesParams.isSelectingAllSecurities
      );
      const formattedSelectedAccounts = this.getFormattedSelectedAccountsFromPreNegotiation();
      const redefinedAccountsNegotiation = this.getFormattedRedefinedAccountsNegotiation(
        this.preNegotiationModule.deadlineRedefinedAccounts,
      );
      const formattedInstallmentChanges = this.preNegotiationModule.installmentChanges
        .map((change) => ({
          index: change.index,
          value: change.value,
          changed_field: change.changedField,
        }));
      const formattedSignatureGroups = this.preNegotiationModule.signatureGroups
        .filter(({ signatures }) => signatures.length > 0)
        .map((signatureGroup) => ({
          economic_group_id: signatureGroup.economicGroupId,
          company_id: signatureGroup.companyId,
          customer_id_customer: signatureGroup.customerIdCustomer,
          signatures: signatureGroup.signatures,
        }));

      const params: IUpsertPreNegotiationParams = {
        origin: this.origin!,
        is_selecting_all: isSelectingAll,
        installment_quantity: Number(quantity!),
        installment_periodicity: periodicity!,
        installment_custom_periodicity: customPeriodicity,
        total_fee_value: this.preNegotiationModule.totalFee!,
        total_fee_type: this.preNegotiationModule.totalFeeType,
        expiration_date: this.preNegotiationModule.expirationDate!,
        selected_accounts_negotiation: formattedSelectedAccounts,
        redefined_accounts_negotiation: redefinedAccountsNegotiation,
        installments_changes: formattedInstallmentChanges,
        signatures_negotiation: formattedSignatureGroups,
      };

      if (this.origin === 'economic_group') {
        params.economic_group_id = Number(this.identifierGroupValue);
      } else {
        params.id_customer_or_document = this.identifierGroupValue;
      }

      await this.preNegotiationRepository.savePreNegotiation(
        this.companyGroupId,
        this.companyIds,
        params,
      );

      this.$notification.success('Pré-Negociação salva com sucesso.');
      this.$router.push({ name: 'PreNegotiationList' });
    } catch (error) {
      this.$notification.error('Houve um problema ao gerar a Pré-Negociação');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getFormattedRedefinedAccountsNegotiation(
    groupedAccounts: AccountReceivablePreNegotiationDeadline[],
  ): IRedefinedAccountsNegotiationRequestParams[] {
    const redefinedAccountsList: IRedefinedAccountsNegotiationRequestParams[] = [];

    for (let i = 0; i < groupedAccounts.length; i += 1) {
      const {
        accounts,
        companyId,
        customerIdCustomer,
        economicGroupId,
      } = groupedAccounts[i];

      const newAccounts = accounts.map((account) => ({
        account_id_customer: account.idCustomer,
        number: account.number,
        due_date: account.dueDate,
        value: account.value,
      }));

      redefinedAccountsList.push({
        economic_group_id: economicGroupId,
        customer_id_customer: customerIdCustomer,
        company_id: companyId,
        new_accounts: newAccounts,
      });
    }

    return redefinedAccountsList;
  }

  public getFormattedSelectedAccountsFromPreNegotiation(): IAccountRequestParams[] {
    const selectedAccountsNegotiation: IAccountRequestParams[] = [
      ...this.preNegotiationModule.accountFees.map((accountFee) => ({
        id_customer: accountFee.idCustomer,
        fee: accountFee.fee,
        fee_type: accountFee.feeType,
      })),
    ];

    if (this.preNegotiationModule.selectedSecuritiesParams.isSelectingAllSecurities) {
      return selectedAccountsNegotiation;
    }

    const { securitiesIdsCustomer } = this.preNegotiationModule.selectedSecuritiesParams;

    for (let i = 0; i < securitiesIdsCustomer.length; i += 1) {
      const idCustomer = securitiesIdsCustomer[i];

      const existentAccountFee = this.preNegotiationModule.accountFees
        .find((accountFee) => accountFee.idCustomer === idCustomer);

      if (!existentAccountFee) {
        selectedAccountsNegotiation.push({
          fee: 0,
          fee_type: null,
          id_customer: idCustomer,
        });
      }
    }

    return selectedAccountsNegotiation;
  }

  public changeStep(action: 'previous' | 'next'): void {
    if (action === 'previous' && this.actualStep === 1) {
      if (this.origin === 'economic_group') {
        this.$router.push({
          name: 'FinancialStatementBillingPendenciesEconomicGroup',
          params: { economicGroupId: this.identifierGroupValue },
        });
      } else {
        this.$router.push({
          name: 'FinancialStatementBillingPendenciesCustomer',
          params: {
            idCustomerOrDocument: this.identifierGroupValue,
          },
        });
      }

      return;
    }

    if (action === 'next' && this.actualStep === 1) {
      const {
        totalFromInstallments,
        accountsTotal,
        hasEqualValuesInstallments,
        installmentConfigs,
      } = this.preNegotiationModule;

      if (totalFromInstallments > accountsTotal.totalBalanceWithFees) {
        this.$notification.warn('O valor total das parcelas deve ser menor ou igual ao valor total dos títulos.');

        return;
      }

      if (this.preNegotiationModule.tradingPolicy) {
        const { tradingPolicy } = this.preNegotiationModule;

        if (
          installmentConfigs.quantity
          && installmentConfigs.quantity > tradingPolicy.amountInstallments
        ) {
          this.$notification.warn('A quantidade de parcela está inválida.');

          return;
        }

        if (!tradingPolicy.differentInstallments && !hasEqualValuesInstallments) {
          this.$notification.warn('As parcelas devem ter os valores totais iguais');

          return;
        }

        if (
          tradingPolicy.maximumDiscount
          && (
            (accountsTotal.totalBalanceWithFees - totalFromInstallments)
            > tradingPolicy.maximumDiscount
          )
        ) {
          this.$notification.warn(`O desconto do valor total deve ser menor do que R$ ${tradingPolicy.maximumDiscount}`);

          return;
        }
      }
    }

    if (
      action === 'next'
      && this.actualStep === 2
      && !this.preNegotiationModule.hasValidExpirationDate
    ) {
      this.$notification.warn('A data de expiração está inválida.');

      return;
    }

    if (action === 'next' && this.actualStep === 3) {
      this.savePreNegotiation();
    }

    if (action === 'next') {
      this.actualStep += 1;

      return;
    }

    this.actualStep -= 1;
  }
}
