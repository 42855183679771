import Repository from '@/repositories/base/Repository';
import IIncreaseSecuritiesParams from '@/repositories/parameters/IIncreaseSecuritiesParams';
import IDecreaseSecuritiesParams from '@/repositories/parameters/IDecreaseSecuritiesParams';
import IChangeSecurityDueDateParams from '@/repositories/parameters/IChangeSecurityDueDateParams';

export default class SecuritiesRepository extends Repository {
  public async changeIncreaseSecurities(
    companyGroupId: number,
    companyId: number,
    params: IIncreaseSecuritiesParams,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroupId}/securities/increase-securities`, params, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });
  }

  public async changeDecreaseSecurities(
    companyGroupId: number,
    companyId: number,
    params: IDecreaseSecuritiesParams,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroupId}/securities/decrease-securities`, params, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });
  }

  public async changeDueDateSecurities(
    companyGroupId: number,
    companyId: number,
    params: IChangeSecurityDueDateParams,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroupId}/securities/due-date-securities`, params, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });
  }
}
