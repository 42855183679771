<template>

    <root-modal>
        <template slot="header">
            <h5 class="modal-title">{{tittleModalInfo}}</h5>
        </template>
        <template slot="body">
            <div class="modal-body" style="height: 300px; overflow-x: scroll;">
                <div class="row printDates">
                    <p class="col-sm-12">Selecione a data para efetuar a impressão, ou deixe em branco para todas:</p>

                    <button v-for="activeDate in activeDatesLocal" class="btn btn-default col-md-2"
                            style="margin:0 15px"
                            :data-date="activeDate.dateHidden" @click="markDate(activeDate)">
                        {{activeDate.date}}
                        <i class="fa fa-check" v-show="activeDate.checked"
                           :style="activeDate.checked ? 'float: right;margin-right: -25px;margin-top: -20px;font-size: 20px; background-color: #ffffff' : 'float: right;margin-right: -25px;margin-top: -20px;font-size: 20px; background-color: #f4f5f8'"></i>
                    </button>
                </div>
                <div class="row" style="margin-top: 30px;">
                    <div class="col-12">
                        <div class="m-form__group form-group" style="text-align: left">
                            <div class="col-3" style="float: left">
                                <label style="font-weight: 400">
                                    Formato da Página:
                                </label>
                            </div>
                            <div class="col-9">
                                <label class="m-radio" style="margin-left: 5px">
                                    <input type="radio" name="example_1" value="1" v-model="printModeParam">
                                    Retrato
                                    <span></span>
                                </label>
                                <label class="m-radio" style="margin-left: 5px">
                                    <input type="radio" name="example_1" value="2" v-model="printModeParam">
                                    Paisagem
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="viewBillingOnRealized">
                    <div class="col-12">
                        <div class="m-form__group form-group" style="text-align: left">
                            <div class="col-3" style="float: left">
                                <span class="m-list-settings__item-label" style="font-weight: 400">
                                    Imprimir Faturamento?
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="m-list-settings__item-control">
                                    <span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
                                        <label>
                                            <input type="checkbox" v-model="printBillingParam">
                                            <span style="margin-top: -4px;"></span>
                                        </label>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template slot="footer">
            <slot name="footer">
            </slot>
        </template>
    </root-modal>
</template>

<script>
    import RootModal from "./RootModal.vue";
    import {PrintCashFlow} from "./print-cashflow-realized.js"


    let printObject = new PrintCashFlow();

    export default {
        components: {RootModal},
        props: ['modalTitle', 'tittleModalInfo', 'activeDates'],
        name: "PrintModal",
        data() {
            return {
                choosedDates: [],
                activeDatesLocal: this.activeDates,
                printBillingParam: false,
                printModeParam: '1',
                viewBillingOnRealized: false
            }
        },
        mounted: function () {
            this.viewBillingOnRealized = this.$session.get('user_access-view_billing_on_realized') == "1" ? true : false;
        },
        methods: {
            hide() {
                this.$emit('close');
            },
            markDate(activeDate) {
                let self = this;
                /*let index;
                if (index = self.choosedDates.indexOf(activeDate.dateHidden) < 0) {
                    self.choosedDates.push(activeDate.dateHidden);
                } else {
                    self.choosedDates.splice(index, 1);
                }*/

                let index = self.activeDatesLocal.indexOf(activeDate);
                activeDate.checked = !activeDate.checked;
                self.activeDatesLocal.splice(index, 1, activeDate);

            },

            print(cashFlowData, cashFlowBillingData, selfComponent) {
                let self = this;
                let companyDescription = "";
                let i = 0;
                let params = {};

                /*if (self.cashFlowHiddenDates.length > 0) {
                    allDates = _.concat(self.cashFlowActiveDates, self.cashFlowHiddenDates);
                } else {
                    allDates = _.concat(self.cashFlowActiveDates, self.configs.rightHiddenDates);
                    allDates = _.concat(self.configs.leftHiddenDates, allDates);
                }*/

                if ($("#company_id").val().length > 0) {
                    $.each($('.select2-selection__rendered > .select2-selection__choice'), function () {
                        i++;
                        companyDescription += $(this).attr('title');

                        if (i < $("#company_id").val().length) {
                            companyDescription += ', ';
                        }
                    });
                } else {
                    companyDescription = 'Todas as empresas';
                }

                params.printModeParam = self.printModeParam;
                params.printBillingParam = self.printBillingParam;


                printObject.print(companyDescription, self.activeDatesLocal, cashFlowData, cashFlowBillingData, selfComponent, params);
            }

        }

    }
</script>