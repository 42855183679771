import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"page-title":"Totalizadores de crédito"}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c(VSelect,{staticClass:"action-select",attrs:{"label":"Escolha uma empresa","items":_vm.companyOptions,"hide-details":"","filled":""},on:{"change":_vm.handleSelectCompany},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c(VSelect,{staticClass:"action-select",attrs:{"label":"Escolha um status","items":_vm.statusOptions,"hide-details":"","filled":""},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('inn-text-field-debounce',{attrs:{"label":"Digite algum valor para a pesquisa","icon":"mdi-magnify","hide-details":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-bold",attrs:{"x-large":"","block":"","dark":"","color":"primary","depressed":""},on:{"click":_vm.handleAddTotalizer}},[_vm._v(" ADICIONAR ")])],1)],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c(VDataTable,{staticClass:"pointer-cursor-data-table",attrs:{"disable-pagination":"","disable-sort":"","hide-default-footer":"","items":_vm.getFilteredTotalizers,"headers":_vm.headers,"loading":_vm.loadingTable,"search":_vm.search},on:{"click:row":function($event){return _vm.handleRowClick($event)}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":_vm.getChipColor(item.active)}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.active))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","buttonIcon":"mdi-delete","tooltipText":"Remover"},on:{"click":function($event){$event.stopPropagation();return _vm.removeTotalizer(item)}}})]}}],null,true)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }