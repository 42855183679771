<template>
	<div
		class="modal fade"
		id="modalSearchInformation"
		tabindex="-1"
		role="dialog"
		aria-labelledby="modalSearchInformation"
		aria-hidden="true"
		data-backdrop="false"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5
						class="modal-title"
						id="title_data_view_information"
					>Procurar movimentos bancários para {{'R$ ' + formatAmount(conciliationrecord.valor)}}</h5>
					<button style="cursor: pointer" type="button" class="close" data-dismiss="modal">&times;</button>
				</div>
				<form id="form_data_view_information" class="m-form m-form--fit m-form--label-align-right">
					<div id="data_view_information" class="modal-body">
						<div class="m-form m-form--label-align-right m--margin-bottom-30">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Pesquisar</label>
										<div class="input-group">
											<input
												id="term"
												v-on:keyup.enter="filter(false)"
												type="text"
												class="form-control"
												aria-label="..."
											/>
											<!-- /btn-group -->
										</div>
										<!-- /input-group -->
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label>Data inicial</label>
										<div class="input-group date">
											<input
												id="initial_date"
												type="text"
												class="form-control m-input text-center"
												placeholder="Selecione a data"
											/>
											<span class="input-group-addon">
												<i class="la la-calendar-check-o"></i>
											</span>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label>Data final</label>
										<div class="input-group date">
											<input
												id="final_date"
												type="text"
												class="form-control m-input text-center"
												placeholder="Selecione a data"
											/>
											<span class="input-group-addon">
												<i class="la la-calendar-check-o"></i>
											</span>
										</div>
									</div>
								</div>
								<div class="col-md-3" style="margin-bottom: 30px">
									<label>Valor</label>
									<input
										id="value"
										type="text"
										class="form-control m-input text-center"
										placeholder="Informe o valor"
										v-on:keyup.enter="filter(false)"
									/>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<label>Conciliados?</label>
										<select
											class="form-control select2 hide"
											field-name="Conciliados?"
											id="conciliates"
											name="conciliates"
											style="width: 100%;"
										>
											<option value="1">Sim</option>
											<option value="2" selected>Não</option>
										</select>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<label>Tipo</label>
										<select
											class="form-control select2 hide"
											field-name="Conciliados?"
											id="type"
											name="type"
											style="width: 100%;"
										>
											<option value>Selecione...</option>
											<option value="D">Pagamento</option>
											<option value="C">Recebimento</option>
										</select>
									</div>
								</div>
								<div class="col-md-3">
									<button
										style="margin-top: 25px; width: 165px"
										type="button"
										@click="filter(false)"
										class="btn btn-primary"
									>
										<i class="la la-search"></i> Filtrar
									</button>
								</div>
							</div>
							<div class="row" v-show="auxSelected.length > 0">
								<div class="col-md-12">
									<label>
										Itens selecionados
										<strong>(Total: {{totalValue}}) <span :class="conciliationValueClass" v-if="total"> (Diferença: {{conciliationTotalValue}} )</span></strong>
									</label>
									<div class="form-group">
										<select
											v-model="auxSelected"
											multiple="multiple"
											style="width: 100%"
											class="form-control"
											id="selectedItems"
											name="selectedItems"
										>
											<option v-for="id in auxSelected" :value="id" v-bind:key="id">{{id}}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="m-section" v-if="items.length <= 0">
								<table class="table table-striped m-table">
									<tbody>
										<tr>
											<td colspan="6" style="text-align: center">Nenhum registro encontrado.</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div
								class="m-section"
								v-if="(conciliationrecord && conciliationrecord.status == 6 || conciliationrecord.status == 7) && items.length > 0"
							>
								<table style="font-size: 12px" class="table table-striped m-table">
									<tbody>
										<tr>
											<td style="vertical-align: middle;">
												<strong>Data</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Nº Doc.</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Tipo</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Natureza</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Histórico</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Valor</strong>
											</td>
											<td>
												<div @change="checkAll()">
													<label class="m-checkbox m-checkbox--success">
														<input :class="'checkbox'" type="checkbox" name="selectItems" id="checkAll" v-model="checkedAll"/>
														<span></span>
													</label>
												</div>
											</td>
										</tr>
										<!-- Se for ERP, mostra os registros do banco -->
										<tr v-for="(item, index) in displayedItems" v-bind:key="item.id + '-banco'">
											<td style="vertical-align: middle;">{{ formatDate(item.data_movimento) }}</td>
											<td style="vertical-align: middle;">{{ item.numero_documento }}</td>
											<td
												style="vertical-align: middle;"
											>{{ (item.tp_lancamento == 'C' ? 'Crédito' : item.tp_lancamento == 'D' ? 'Débito' : getDescriptionOperation(item.tp_lancamento, item.e5_numero)) }}</td>
											<td style="vertical-align: middle;">{{ item.natureza_lancamento}}</td>
											<td style="vertical-align: middle;">{{ item.historico }}</td>
											<td style="vertical-align: middle;">{{ formatAmount(item.valor) }}</td>
											<td style="vertical-align: middle;">
												<div @click.shift.exact="checkMany(index)">
													<label class="m-checkbox m-checkbox--success">
														<input
															@change.exact="check(index)"
															v-model="selected"
															:class="'checkbox'"
															type="checkbox"
															:value="{item, ...{type: 'conciliation_record'}}"
															name="selectedItems"
														/>
														<span></span>
													</label>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div
								class="m-section"
								v-if="(conciliationrecord && conciliationrecord.status !== '6' && conciliationrecord.status !== '7') && items.length > 0"
							>
								<table style="font-size: 12px" class="table table-striped m-table">
									<tbody>
										<tr>
											<td style="vertical-align: middle;">
												<strong>Data</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Título</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Nº Doc.</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Tipo</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Natureza</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Histórico</strong>
											</td>
											<td style="vertical-align: middle;">
												<strong>Valor</strong>
											</td>
											<td>
												<div @change="checkAll()">
													<label class="m-checkbox m-checkbox--success">
														<input :class="'checkbox'" type="checkbox" name="selectItems" id="checkAll" v-model="checkedAll"/>
														<span></span>
													</label>
												</div>
											</td>
										</tr>
										<!-- Se for banco, mostra os registros do ERP -->
										<tr v-for="(item, index) in displayedItems" v-bind:key="item.id + '-erp'">
											<td style="vertical-align: middle;">{{ formatDate(item.e5_dtdispo) }}</td>
											<td style="vertical-align: middle;">{{ getNumberDocument(item) }}</td>
											<td style="vertical-align: middle;">{{ (item.e5_documen ? item.e5_documen : '') }}</td>
											<td
												style="vertical-align: middle;"
											>{{getDescriptionOperation(item.e5_recpag, item.e5_numero)}}</td>
											<td style="vertical-align: middle;">{{ item.ed_descric }}</td>
											<td style="vertical-align: middle;">{{ item.e5_histor }}</td>
											<td style="vertical-align: middle;">{{ formatAmount(item.e5_valor) }}</td>
											<td style="vertical-align: middle;">
												<div @click.shift.exact="checkMany(index)">
													<label class="m-checkbox m-checkbox--success">
														<input
															@change.exact="check(index)"
															:class="'checkbox'"
															v-model="selected"
															type="checkbox"
															:value="{item, ...{type: 'movimento_bancario'}}"
															name="selectedItems"
														/>
														<span></span>
													</label>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div class="row" v-if="items.length > 0">
								<div class="col-md-12">
									<div class="pagination-center">
										<div class="pagination">
											<a @click="pagination.page -= 1">&laquo;</a>
											<a
												@click="pagination.page = nPage"
												:class="pagination.page == nPage ? 'active' : ''"
												v-for="(nPage, index) in pagination.pages"
												v-bind:key="index"
											>{{nPage}}</a>
											<a @click="pagination.page += 1">&raquo;</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button type="button" class="btn m-btn--air btn-success" @click="saveConciliation()" id="btnUpdate">Salvar</button>
						<button
							type="button"
							class="btn m-btn--air btn-primary"
							id="btnModalClose"
							data-dismiss="modal"
						>Fechar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import {API_BASE} from '@config/api';

export default {
	props: ["list"],
	data() {
		return {
			selected: [],		//modelo dos dados que estao selecionados
			auxSelected: [],	//array dos selecionados formatado para a UX
			checkedAll: false,	//interruptor da funcao checkAll
			savedIndex: null,	//variavel auxiliar da funcao check para o evento shift+click
			displayed: [],	//auxiliar da funcao check no evento de shift+click
			items: [],
			conciliationrecord: "",
			conciliationValueClass: "",
			bankCode: "",
			isRequestPending: false,
			total: 0,
			pagination: {
				perPage: 10,
				pages: [],
				page: 1,
				maxVisiblePages: 10
			},
			totalRecords: 0,
			thisStatementconciliation: null
		};
	},
	computed: {
		displayedItems() {
			let type = "";
			this.displayed = this.paginate(this.items)

			if((this.conciliationrecord && this.conciliationrecord.status !== '6' && this.conciliationrecord.status !== '7') && this.items.length > 0){
				type = "movimento_bancario"
			} else {
				type = "conciliation_record"
			}

			this.displayed = this.displayed.map((element) => {
				let formatedItem = {item: element, type: type}
				return formatedItem
			})

			return this.paginate(this.items);
		},
		totalValue() {
			return 'R$ ' + this.formatAmount(this.total)
		},
		conciliationTotalValue() {
			let valor = this.conciliationrecord.valor;

			if (this.conciliationrecord.tp_lancamento == 'D' || this.conciliationrecord.tp_lancamento == 'P'){
				//Caso seja DEBITO/PAGAMENTO o valor tem que ser negativo
				valor = valor * -1;
			}

			if(valor > this.total) {
				this.conciliationValueClass = ""
			} else if (valor == this.total){
				this.conciliationValueClass = "text-success"
			} else if (valor < this.total){
				this.conciliationValueClass = "text-danger"
			}

			return 'R$ ' + this.formatAmount(valor - this.total)
		}
	},
	mounted() {
		$("#selectedItems")
			.select2({
				placeholder: "Itens selecionados"
			})
			.on('select2:unselect', (event) => {this.auxiliarChange(event, "unselect")})
			.on('select2:select', (event) => {this.auxiliarChange(event, "select")})
	},
	methods: {
		/*
		*	CHECBOX EVENTs
		*	Funcoes de checking das caixas de extrato bancario, funciona igualmente para as duas
		*	adiciona os dados no modelo do selected e do array auxSelected
		*	serve para
		*	de/selecionar uma unica caixa - usa da funcao check()
		*	de/selecionar todas as caisas - funcao checkAll()
		*	de/selecionar todas atraves da tecla shift - funcao checkMany() !funciona apenas com os itens sendo mostrados
		* 	!deve ser alterado para cumprir minimamente o padrao SOLID
		*/
		check: function (index) {

			this.savedIndex = index;
			let item = event.target._value.item
			let aux;
			if(event.target.checked) {

				if(this.conciliationrecord && this.conciliationrecord.status !== '6' && this.conciliationrecord.status !== '7'){
					let doc = item.e5_documen ? item.e5_documen : "";
					aux = item.id+" "+doc+" - "+this.formatDate(item.e5_dtdispo)+" - "+this.formatAmount(item.e5_valor);

					this.total += (item.e5_recpag == 'P') ? (parseFloat(item.e5_valor) * -1) : (parseFloat(item.e5_valor) );
				} else {
					let doc = item.numero_documento ? item.numero_documento : ""
					aux = item.id+" "+doc+" - "+this.formatDate(item.data_movimento)+" - "+this.formatAmount(item.valor);

					this.total += (item.tp_lancamento == 'D') ? (parseFloat(item.valor) * -1) : (parseFloat(item.valor));
				}

				this.auxSelected.push(aux)
			} else {
				if(this.checkedAll) this.checkedAll = false

				if(this.conciliationrecord && this.conciliationrecord.status !== '6' && this.conciliationrecord.status !== '7'){
					let doc = item.e5_documen ? item.e5_documen : ""
					aux = item.id+" "+doc+" - "+this.formatDate(item.e5_dtdispo)+" - "+this.formatAmount(item.e5_valor)
					this.auxSelected = this.auxSelected.filter((element) => element != aux)

					this.total -= (item.e5_recpag == 'P') ? (parseFloat(item.e5_valor) * -1) : (parseFloat(item.e5_valor));
				} else {
					let doc = item.numero_documento ? item.numero_documento : ""
					aux = item.id+" "+doc+" - "+this.formatDate(item.data_movimento)+" - "+this.formatAmount(item.valor)
					this.auxSelected = this.auxSelected.filter((element) => element != aux)

					this.total -= (item.tp_lancamento == 'D') ? (parseFloat(item.valor) * -1) : (parseFloat(item.valor));
				}
			}
		},

		checkAll: function (forceUncheck = false) {

			let type = ""
			let aux;
			if(this.conciliationrecord && this.conciliationrecord.status !== '6' && this.conciliationrecord.status !== '7'){
				type = "movimento_bancario"
			} else {
				type = "conciliation_record"
			}

			if (this.checkedAll && !forceUncheck) {
				this.items.forEach((item) => {
					let len = this.selected.filter((element) => element.item.id == item.id)

					if(len.length) return;

					if(type == "movimento_bancario") {
						this.selected.push({item: item, type: 'movimento_bancario'});
						let doc = item.e5_documen ? item.e5_documen : ""
						let aux = item.id+" "+doc+" - "+this.formatDate(item.e5_dtdispo)+" - "+this.formatAmount(item.e5_valor)
						this.auxSelected.push(aux)

						this.total += (item.e5_recpag == 'P') ? (parseFloat(item.e5_valor) * -1) : (parseFloat(item.e5_valor));
					} else {
						this.selected.push({item: item, type: 'conciliation_record'});
						let doc = item.numero_documento ? item.numero_documento : ""
						let aux = item.id+" "+doc+" - "+this.formatDate(item.data_movimento)+" - "+this.formatAmount(item.valor)
						this.auxSelected.push(aux)

						this.total += (item.tp_lancamento == 'D') ? (parseFloat(item.valor) * -1) : (parseFloat(item.valor));
					}
				})
			} else {
				this.items.forEach((item) => {
					this.selected.forEach((select, index) => {
						if(select.item.id == item.id) {
							if(type == "movimento_bancario"){
								let doc = item.e5_documen ? item.e5_documen : ""
								let aux = item.id+" "+doc+" - "+this.formatDate(item.e5_dtdispo)+" - "+this.formatAmount(item.e5_valor)
								this.auxSelected = this.auxSelected.filter((element) => element != aux)

								this.total -= (item.e5_recpag == 'P') ? (parseFloat(item.e5_valor) * -1) : (parseFloat(item.e5_valor));
							} else {
								let doc = item.numero_documento ? item.numero_documento : ""
								let aux = item.id+" "+doc+" - "+this.formatDate(item.data_movimento)+" - "+this.formatAmount(item.valor)
								this.auxSelected = this.auxSelected.filter((element) => element != aux)

								this.total -= (item.tp_lancamento == 'D') ? (parseFloat(item.valor) * -1) : (parseFloat(item.valor));
							}

							this.selected.splice(index, 1)
						}
					})
				})
			}

			this.$forceUpdate
		},

		checkMany: function (index) {

			const lastIndex = this.savedIndex
			this.savedIndex = index;
			let aux;
			if(this.savedIndex == null || !event.shiftKey) return;

			if (index == lastIndex) return;

			//corta os arquivos que estão aparecendo na tela para adicionar no modelo de select
			const checkboxes = this.displayedItems.slice(
				Math.min(index, lastIndex),
				Math.max(index, lastIndex) + 1
			)

			let type = '';

			//define o tipo de arquivo para adicionar, se for conciliacao bancario ou no ERP
			if(this.conciliationrecord && this.conciliationrecord.status !== '6' && this.conciliationrecord.status !== '7'){
				type = "movimento_bancario"
			} else {
				type = "conciliation_record"
			}

			checkboxes.forEach((item, index) => {
				let existsInSelected = false

				this.selected.forEach((selectItem) => {
					if(selectItem.item.id == item.id) {
						existsInSelected = true;
					}
				})


				if(!existsInSelected){
					this.selected.push({item: item, type: type});

					if(type == "movimento_bancario"){
						let doc = item.e5_documen ? item.e5_documen : ""
						aux = item.id+" "+doc+" - "+this.formatDate(item.e5_dtdispo)+" - "+this.formatAmount(item.e5_valor)
						this.auxSelected.push(aux)

						this.total += parseFloat(item.e5_valor)
					} else {
						let doc = item.numero_documento ? item.numero_documento : ""
						aux = item.id+" "+doc+" - "+this.formatDate(item.data_movimento)+" - "+this.formatAmount(item.valor)
						this.auxSelected.push(aux)

						this.total += parseFloat(item.valor)
					}
				}
			})

		},

		auxiliarChange: function (event, type) {

			let id = event.params.data.id
			id = id.split(" ")[0]

			if(type == "unselect"){
				this.selected = this.selected.filter( (element) => {
					let valor = 0;
					if(element.item.valor) valor = parseFloat(element.item.valor)
					if(element.item.e5_valor) valor =  parseFloat(element.item.e5_valor)

					if(element.item.id == id){
						this.total -= valor
					} else {
						return true;
					}

				})

				this.auxSelected.splice(
					this.auxSelected.indexOf(event.params.data.id),
					1
				)

				if(this.checkedAll) {
					this.checkedAll = !this.checkedAll
				}
			} else {
				this.auxSelected.push(event.params.data.id)
				this.items.forEach((element) => {
					if(element.id == id) {
						if(this.conciliationrecord && this.conciliationrecord.status !== '6' && this.conciliationrecord.status !== '7'){
							this.selected.push({item: element, type: 'movimento_bancario'})

							this.total += parseFloat(element.e5_valor)
						} else {
							this.selected.push({item: element, type: 'conciliation_record'})

							this.total += parseFloat(element.valor)
						}
					}
				})
			}
		},

		formatAmount(value) {
			let val = (value / 1).toFixed(2).replace(".", ",");
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		openModal: function(conciliationrecord, bank, thisStatementconciliation) {
			this.checkedAll = false;
			this.savedIndex = null;
			this.displayed = []
			this.total = 0;
			this.items = [];
			this.selected = [];
			this.auxSelected = [];
			this.conciliationrecord = conciliationrecord;
			this.bankCode = JSON.parse(bank)["code"];
			this.pagination.page = 1;
			this.thisStatementconciliation = thisStatementconciliation;

			let currentDate = moment(conciliationrecord.data_movimento);

			$("#term").val("");
			$("#value").val("");
			$("#dtdispo").val("");

            $("#conciliates").select2();
            $("#type").select2();

			$("#initial_date").val(currentDate.format("DD/MM/YYYY"));

			$("#final_date").val(currentDate.format("DD/MM/YYYY"));

			$("#initial_date").datepicker({
				autoclose: true,
				format: "dd/mm/yyyy",
				todayHighlight: !0,
				orientation: "bottom left",
				defaultDate: currentDate,
				templates: {
					leftArrow: '<i class="la la-angle-left"></i>',
					rightArrow: '<i class="la la-angle-right"></i>'
				}
			});

			$("#final_date").datepicker({
				autoclose: true,
				format: "dd/mm/yyyy",
				todayHighlight: !0,
				orientation: "bottom left",
				defaultDate: currentDate,
				templates: {
					leftArrow: '<i class="la la-angle-left"></i>',
					rightArrow: '<i class="la la-angle-right"></i>'
				}
			});

			$("#value").mask("00.000.000.000,00", {
				reverse: true,
				watchDataMask: true
			});

			$("#modalSearchInformation").modal("show");

			setTimeout(() => {
				this.filter(false);
			}, 500);
		},
		setPages() {
			this.pagination.pages = [];

			let numberOfPages = Math.ceil(
				this.totalRecords / this.pagination.perPage
			);

			if (numberOfPages > this.pagination.maxVisiblePages) {
				numberOfPages = this.pagination.maxVisiblePages;
			}

			for (let index = 1; index <= numberOfPages; index++) {
				this.pagination.pages.push(index);
			}
		},
		paginate(items) {
			let page = this.pagination.page;
			let perPage = this.pagination.perPage;

			let itemIndexForPage = 0+ (perPage * (page - 1))
			let lastItemIndex = perPage * (page)

			let ret = items.slice(itemIndexForPage, lastItemIndex);

			return ret;
		},
		/**
		 * Funcao responsavel pelo envio dos dados de extrato bancario ou de movimentos bancarios do ERP para o protheus
		 * envia os dados separado em partes para que o mesmo possa enviar grandes quantia de dados
		 * @var object finalResult - dados resultantes de todos as querys
		 * @var string error - verifica se houve erro em algum dos envios
		 */
		async send(arrayToSave) {
			blockPage("Carregando os dados...");

			let error
			const result = arrayToSave["conciliation_record_movimento_bancario"].map(async (element) => {
				let traitSend = arrayToSave;
				traitSend["conciliation_record_movimento_bancario"] = element

				return await request({
					api: API_BASE,
					method: "POST",
					endpoint: "conciliation/conciliateMultiples",
					token: this.$session.get("token"),
					data: {
						conciliationrecord: traitSend
					},
					finally: () => {}
				}).done(result => {
					if(result.error) {
						error = result.error;
						showNotification("Atenção", "algo deu errado na conciliação dos dados", "danger");
						return false;
					}
					return true;
				});
			})

			await Promise.all(result);

			unblockPage();

			if(error) return false;

			this.conciliationrecord.status = 1;

			this.conciliationrecord.conciliation_record_movimento_bancario = this.selected.map(
				(movement, index) => {
					if ("e5_agencia" in this.conciliationrecord) {
						//Entra aqui se selecionou um item do BANCO
						this.conciliationrecord.origin = "ERP";

						this.thisStatementconciliation.listConciliationRecords = this.thisStatementconciliation.listConciliationRecords.filter((currentRecord) => {
							return currentRecord.id != movement.item.id
						});

						return {
							id: movement.item.id,
							e5_data: movement.item.data_movimento,
							e5_dtdispo: this.conciliationrecord.dtdispo,
							e5_recpag: movement.item.tp_lancamento,
							ed_descric: movement.item.natureza_lancamento,
							e5_histor: movement.item.historico,
							e5_valor: movement.item.valor,
							e5_numero: this.conciliationrecord.e5_numero,
							e5_filial: this.conciliationrecord.e5_filial,
							e5_prefixo: this.conciliationrecord.e5_prefixo,
							e5_parcela: this.conciliationrecord.e5_parcela,
							e5_tipo: this.conciliationrecord.e5_tipo,
							e5_documen: movement.item.numero_documento,
							tp_lancamento: movement.item.tp_lancamento,
							natureza_lancamento: movement.item.natureza_lancamento,
							historico: movement.item.historico,
							origin: "Banco",
							company_id: movement.item.company_id,
							company_group_id: movement.item.company_group_id,
							recno: this.conciliationrecord.recno,
							TABLE_COMPANY_CODE: this.conciliationrecord.TABLE_COMPANY_CODE,
							banco: movement.item.banco,
							agencia: movement.item.agencia,
							dvage: movement.item.dvage,
							numcon: movement.item.numcon,
							dvcta: movement.item.dvcta,
							numero_documento: movement.item.numero_documento,
							data_movimento: movement.item.data_movimento,
							valor: movement.item.valor,
							data_processamento: movement.item.data_processamento,
							status: movement.item.status,
							action_id: movement.item.action_id
						};
					} else {
						//Entra aqui se selecionou um item do ERP
						this.conciliationrecord.origin = "Banco";

						//Filtrar para quando selecionar um item da SE5, não aparecer mais o ERP sem conciliação
						this.thisStatementconciliation.listConciliationRecords = this.thisStatementconciliation.listConciliationRecords.filter((currentRecord) => {
							return currentRecord.recno != movement.item.r_e_c_n_o_
						});

						this.thisStatementconciliation.$forceUpdate();

						movement.item.recno = movement.item.r_e_c_n_o_;
						return movement.item;
					}
				});

			$("#modalSearchInformation").modal("hide");

			showNotification(
				"Sucesso",
				"Dados conciliados com sucesso!",
				"success"
			);

			if(this.thisStatementconciliation) {
				this.thisStatementconciliation.loadBankbalance();
			}

			this.$parent.loadConciliationRecord()


		},
		saveConciliation () {
			let self = this;

			checkImportBeforeSearch(self).then(
				function (result) {
					if (!result.body.payload) {
						self.save();
					} else {
						showNotification('Atenção', 'Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.', 'warning')
						unblockPage();
					}

				},
				function (error) {
					showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
					unblockPage();
				});
		},
		save() {
			if (this.selected.length <= 0) {
				showNotification(
					"Atenção",
					"É necessário selecionar pelo menos um item para continuar!",
					"warning"
				);
				return;
			}

			let areDivergentDates = this.selected.filter(record => {
				return "e5_dtdispo" in record.item
					? record.item.e5_dtdispo !==
							this.conciliationrecord.data_movimento
					: record.item.data_movimento !==
							this.conciliationrecord.data_movimento;
			});
			/*
			* Busca de divergencias na data de disponibilidade:
			* verifica se o valor foi adicionado, ignorado ou existe.
			* se ocorrer erro define um SweetAlert para reescrever a variavel e5_data
			*/
			if (areDivergentDates.length > 0) {
				Swal.fire({
					title: "Informe a data de disponibilidade:",
					html: `<div class="row" style="justify-content: center;">
                    <div class="col-md-5">
                      <div class="form-group" id="datepicker">
                        <input
                          id="dtdispo"
                          type="text"
                          class="form-control m-input text-center"
                          placeholder="Selecione a data"
                        />
                      </div>
                    </div>
                  </div>`,
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Continuar",
					cancelButtonText: "Voltar",
					didOpen: () => {
						let dtDispo = moment(
							this.conciliationrecord.data_movimento
						);

						$("#dtdispo").val(dtDispo.format("DD/MM/YYYY"));

						$("#dtdispo").datepicker({
              container: 'div.swal2-container',
							autoclose: true,
							format: "dd/mm/yyyy",
							todayHighlight: !0,
							orientation: "bottom left",
							defaultDate: dtDispo,
							templates: {
								leftArrow: '<i class="la la-angle-left"></i>',
								rightArrow: '<i class="la la-angle-right"></i>'
							}
						});
					}
				}).then((value) => {
					if(value.value) {
						this.prepareSend()
					}
				})
			} else {
				this.prepareSend()
			}
		},
		/*
		 * prepareSend
		 * Funcao para tratamento dos dados existentes chamados em save()
		 * preenche um post com todos os dados necessários para o acesso do sistema
		 */
		prepareSend: function () {
			this.conciliationrecord.dtdispo = $("#dtdispo").val() ? $("#dtdispo").val()
							: this.conciliationrecord.data_movimento;

			let arrayToSave = [
				Object.assign({dtdispo: this.conciliationrecord.dtdispo}, this.conciliationrecord, {
					conciliation_record_movimento_bancario: this.selected.map(movement => {
						return Object.assign({
								conciliation_record_id: movement.item.id,
								e5_data: movement.item.e5_data,
								e5_dtdispo: this.conciliationrecord.dtdispo ? this.conciliationrecord.dtdispo : movement.item.e5_dtdispo,
								e5_filial: movement.item.e5_filial,
								e5_filorig: movement.item.e5_filorig,
								e5_banco: movement.item.e5_banco,
								e5_agencia: movement.item.e5_agencia,
								e5_conta: movement.item.e5_conta,
								e5_documen: movement.item.e5_documen,
								e5_recpag: movement.item.e5_recpag,
								e5_valor: movement.item.e5_valor,
								e5_idorig: movement.item.e5_idorig,
								e5_tipo: movement.item.e5_tipo,
								e5_prefixo: movement.item.e5_prefixo,
								e5_numero: movement.item.e5_numero,
								e5_parcela: movement.item.e5_parcela,
								e5_clifor: movement.item.e5_clifor,
								e5_loja: movement.item.e5_loja,
								e5_situaca: movement.item.e5_situaca,
								e5_tipodoc: movement.item.e5_tipodoc,
								e5_seq: movement.item.e5_seq,
								ed_descric: movement.item.ed_descric,
								ed_codigo: movement.item.ed_codigo,
								e5_histor: movement.item.e5_histor,
								r_e_c_n_o_: movement.item.r_e_c_n_o_,
								table_company_code: movement.item.table_company_code,
								type: movement.type
							},
							{ recno: "" }
						);
					})
				})
			];

			arrayToSave = arrayToSave[0];
			arrayToSave["conciliation_record_movimento_bancario"] = new Array(arrayToSave["conciliation_record_movimento_bancario"])

			this.send(arrayToSave);
		},
		getDescriptionOperation(e5_recpag, e5_numero) {
			let description = "";

			if (e5_recpag == "P" && e5_numero) {
				description = "Pagamento";
			}

			if (e5_recpag == "R" && e5_numero) {
				description = "Recebimento";
			}

			if (e5_recpag == "P" && !e5_numero) {
				description = "Mov. Bancário Pagar";
			}

			if (e5_recpag == "R" && !e5_numero) {
				description = "Mov. Bancário Receber";
			}

			return description;
		},
		getNumberDocument(key) {
			let num = "";

			if (key.e5_numero) {
				if (key.e5_filial) num += key.e5_filial + " - ";
				if (key.e5_prefixo) num += key.e5_prefixo + " - ";
				if (key.e5_numero) num += key.e5_numero + " - ";
				if (key.e5_parcela) num += key.e5_parcela + " - ";
				if (key.e5_tipo) num += key.e5_tipo;
			} else if (key.numero_documento) {
				num = key.numero_documento;
			} else if (key.e5_documen) {
				num = key.e5_documen;
			}

			return num;
		},
		filter(isChangingPage = false) {

			/*
			if (!isChangingPage) {
				this.checkAll(true);
				this.checkedAll = false;
			}*/

			this.checkedAll = false;
			let params = {
				filters: {
					checkedAll: this.checkedAll,
					initial_date: $("#initial_date").val(),
					final_date: $("#final_date").val(),
					term: $("#term").val(),
					value: $("#value").val(),
                    bank: this.bankCode,
                    reconciled: $("#conciliates").val(),
                    type: $("#type").val(),
					perPage: this.pagination.perPage,
					notSearchThisStatus: [4, 9],
					page:
						typeof isChangingPage == "boolean" && isChangingPage
							? this.pagination.page
							: 0,
					agency:
						"e5_agencia" in this.conciliationrecord
							? this.conciliationrecord.e5_agencia
							: this.conciliationrecord.agencia,
					account:
						"e5_conta" in this.conciliationrecord
							? this.conciliationrecord.e5_conta
							: this.conciliationrecord.numcon
				}
			};

			if (!this.isRequestPending) {
				if (
					this.conciliationrecord &&
					(this.conciliationrecord.status == 6 ||
						this.conciliationrecord.status == 7)
				) {
					this.getConciliationRecords(params)
						.done(result => {
							if (result.error == false) {
								if(!isChangingPage)	this.items = result.data;
								this.totalRecords = result.total_records;
								this.isRequestPending = false;
							} else {
								showNotification(
									"Atenção",
									result.error,
									"danger"
								);
								return false;
							}
						})
						.fail(error => {
							this.isRequestPending = false;
							showNotification(
								"Opss!",
								"Erro ao buscar conciliações do banco",
								"danger"
							);
						});
				} else {
					this.getBankMovements(params)
						.done(result => {
							if (result.error == false) {
								if(!isChangingPage) this.items = result.data;
								this.totalRecords = result.total_records;
								this.isRequestPending = false;
							} else {
								showNotification(
									"Atenção",
									result.message,
									"danger"
								);
								return false;
							}
						})
						.fail(error => {
							this.isRequestPending = false;
							showNotification(
								"Opss!",
								"Erro ao buscar movimentações do ERP",
								"danger"
							);
						});
				}
			}
		},
		getBankMovements(params = {}) {
			blockPage("Carregando os dados...");

			this.isRequestPending = true;

			return request({
				api: API_BASE,
				method: "GET",
				endpoint: "conciliation/getBankMovements",
				token: this.$session.get("token"),
				data: params,
				finally: () => {
					unblockPage();
				}
			});
		},
		getConciliationRecords(params = {}) {
			blockPage("Carregando os dados...");

			this.isRequestPending = true;

			return request({
				api: API_BASE,
				method: "GET",
				endpoint: "conciliation/getConciliationRecords",
				token: this.$session.get("token"),
				data: params,
				finally: () => {
					unblockPage();
				}
			});
		}
	},
	watch: {
		items() {
			this.setPages();
		},
		"pagination.page": {
			handler() {
				this.filter(true);
			},
			deep: true
		}
	}
};
</script>

<style scoped>
.filter-label {
	margin-top: 10px;
}
.pagination {
	display: inline-block;
}

.pagination a {
	color: black;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
}

.pagination a.active {
	background-color: #386bd9;
	color: white;
}

.pagination-center {
	text-align: center;
}

.pagination a:hover:not(.active) {
	background-color: #ddd;
}

.pagination a {
	cursor: pointer;
}

.modal-lg {
	max-width: 1000px;
}
</style>

<style>
.swal2-html-container {
  overflow: hidden !important;
}
</style>
