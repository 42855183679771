export default class Customer {
  constructor(
    public id: string,
    public name: string,
    public code: string,
    public label: string,
    public document: string,
    public store?: string,
  ) {}

  static make(data: any): Customer {
    return new Customer(
      data.id ?? '',
      data.name ?? '',
      data.code ?? '',
      data.code_message ?? '',
      data.document ?? '',
      data.store ?? '',
    );
  }
}
