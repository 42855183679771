import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"650px"},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.model.open),callback:function ($$v) {_vm.$set(_vm.model, "open", $$v)},expression:"model.open"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c(VRow,{attrs:{"justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"10","lg":"auto","md":"auto","sm":"auto"}},[_vm._v(" Comprovantes de Pagamento ")]),_c(VCol,{attrs:{"cols":"2","lg":"auto","md":"auto","sm":"auto"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_vm._v(" Como deseja baixar os comprovantes? ")])],1),_c(VRow,{staticClass:"pt-4"},[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c(VBtn,{attrs:{"dark":"","block":"","large":"","depressed":"","outlined":"","color":"info"},on:{"click":function () { return _vm.download('separate'); }}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v(" mdi-file-outline ")]),_vm._v(" SEPARADOS ")],1)],1),_c(VCol,{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c(VBtn,{attrs:{"dark":"","block":"","large":"","depressed":"","color":"info"},on:{"click":function () { return _vm.download('together'); }}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v(" mdi-file-document-outline ")]),_vm._v(" JUNTOS ")],1)],1),_c(VCol,{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c(VBtn,{attrs:{"dark":"","block":"","large":"","depressed":"","color":"primary"},on:{"click":function () { return _vm.download('compress'); }}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v(" mdi-zip-box-outline ")]),_vm._v(" COMPACTADOS ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }