













































































































































































































































































































import {
  Component,
    Ref,
    Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import CreditTotalizersRepository from '@/repositories/CreditTotalizersRepository';
import CreditRuleRepository from '@/repositories/CreditRuleRepository';
import CompanyRepository from '@/repositories/CompanyRepository';
import CreditTotalizer from '@/domain/models/CreditTotalizer';
import { VForm } from '@/types/VForm';
import IBinding from '@/views/creditTotalizers/interfaces/IBinding';
import IBindingWithText from '@/views/creditTotalizers/interfaces/IBindingWithText';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import IInputValueTypeOption from '@/views/creditRules/interfaces/IInputValueTypeOption';

@Component
export default class CreditTotalizersUpsert extends Vue {
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();
  private readonly creditRuleRepository: CreditRuleRepository = new CreditRuleRepository();
  private readonly companyRepository: CompanyRepository = new CompanyRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly creditTotalizersRepository:
    CreditTotalizersRepository = new CreditTotalizersRepository();

  public selectedBindingToEdit: number|null = null;
  public selectedCompanies: number[] = [];

  public loading: boolean = false;

  public name: string = '';
  public description: string = '';
  public query: string|null = null;

  public bindingName: string = '';
  public bindingValue: string|number = '';

  public selectedExecutionType: 'query' | 'code' = 'query';
  public active: 'active' | 'inactive' = 'active';
  public definedInputValueType: 'executable_expression' | 'text' | 'numeric' | 'variable' = 'text';

  public bindings: IBindingWithText[] = [];
  public companyItems: SelectOptions[] = [];
  public variableOptions: SelectOptions[] = [];
  public executionTypeOptions: SelectOptions<string>[] = [
    { value: 'query', text: 'Query' },
    { value: 'code', text: 'Código' },
  ];
  public statusOptions: SelectOptions[] = [
    { value: 'active', text: 'Ativo' },
    { value: 'inactive', text: 'Desabilitado' },
  ];
  public inputValueTypes: IInputValueTypeOption[] = [
    {
      text: 'TEXTO',
      value: 'text',
      isSelected: true,
    },
    {
      text: 'NUMÉRICO',
      value: 'numeric',
      isSelected: false,
    },
    {
      text: 'VARIÁVEIS',
      value: 'variable',
      isSelected: false,
    },
    {
      text: 'EXPRESSÃO EXECUTÁVEL',
      value: 'executable_expression',
      isSelected: false,
    },
  ];

  public get isUpdate(): boolean {
    return !!this.$route.params.id && !!this.$route.params.companyId;
  }

  public get getPageTitleText(): string {
    return this.isUpdate ? 'Editar totalizador' : 'Novo totalizador';
  }

  public get hasCompanySelected(): boolean {
    return this.selectedCompanies.length > 0;
  }

  public get buttonTooltipTextBinding(): string {
    return this.selectedBindingToEdit !== null ? 'Editar parâmetro' : 'Adicionar parâmetro';
  }

  public get getQueryFieldMessage(): string {
    if (this.selectedExecutionType === 'query') {
      return 'Query do totalizador';
    }

    return 'Código do totalizador';
  }

  @Ref('CreditTotalizerForm') readonly creditTotalizerForm!: VForm;

  @Ref('CreditTotalizerBindingsForm') readonly creditTotalizerBindingsForm!: VForm;

  public async created(): Promise<void> {
    if (this.isUpdate) {
      await this.getCreditTotalizerValues(this.$route.params.id, this.$route.params.companyId);
    }

    await this.getCompanies();
  }

  public async insertCreditTotalizer(): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.creditTotalizersRepository.insertTotalizer(
        this.authenticationModule.user.company_group_id,
        {
          name: this.name,
          description: this.description,
          companyIds: this.selectedCompanies,
          active: this.active,
          executionType: this.selectedExecutionType,
          query: this.setStringWithSubQuoteValid(this.query!),
          bindings: this.getFormattedBindingsJsonString(this.bindings),
        },
      );

      this.$notification.success('Totalizador de crédito cadastrado com sucesso!');
      this.cancel();
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao inserir o totalizador de crédito.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async updateCreditTotalizer(): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.creditTotalizersRepository.updateTotalizer(
        this.authenticationModule.user.company_group_id,
        {
          name: this.name,
          description: this.description,
          companyIds: this.selectedCompanies,
          active: this.active,
          executionType: this.selectedExecutionType,
          query: this.setStringWithSubQuoteValid(this.query!),
          bindings: this.getFormattedBindingsJsonString(this.bindings),
        },
        parseInt(this.$route.params.id, 10),
      );

      this.$notification.success('Totalizador de crédito atualizado com sucesso!');
      this.cancel();
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao atualizar o totalizador de crédito.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getCreditTotalizerValues(totalizerId: string, companyId: string): Promise<void> {
    this.$dialog.startLoading();

    try {
      const creditTotalizer = await this.creditTotalizersRepository.getTotalizerById(
        this.authenticationModule.user.company_group_id,
        parseInt(companyId, 10),
        parseInt(totalizerId, 10),
      );

      await this.setValuesOnTotalizerFields(creditTotalizer);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar o totalizador de crédito.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getCompanies(): Promise<void> {
    this.loading = true;

    try {
      const companies = await this.companyRepository.getCompaniesByLoggedUser(
        parseInt(this.authenticationModule.user.company_group_id, 10),
      );

      this.companyItems = companies.map(({ fantasyName, id }) => ({
        text: fantasyName,
        value: id,
      }));
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as empresas.');

      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async getVariables(companyIds: number[]): Promise<void> {
    this.$dialog.startLoading();

    try {
      const variables = await this.creditRuleRepository.getVariablesFromCompanyId(
        parseInt(this.authenticationModule.user.company_group_id, 10),
        companyIds,
      );

      this.variableOptions = variables.map(({ id, name, companyId }) => ({
        value: id,
        text: `${companyId} - ${name}`,
      }));
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as variáveis.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getFormattedBindingsJsonString(bindings: IBindingWithText[]): string {
    const arrayToStringfy = bindings.map((binding) => {
      let formattedBindingValue = binding.value;

      if (typeof binding.value === 'string') {
        formattedBindingValue = this.setStringWithSubQuoteValid(binding.value);
      }

      return {
        name: binding.name,
        type: binding.type,
        value: formattedBindingValue,
      };
    });

    return JSON.stringify(arrayToStringfy);
  }

  public setStringWithSubQuoteValid(value: string): string {
    return value.replace(/[']/g, '\'$&');
  }

  public getTypeText(type: 'executable_expression' | 'text' | 'numeric' | 'variable'): string {
    return {
      executable_expression: 'Expressão executável',
      text: 'Texto',
      numeric: 'Numérico',
      variable: 'Variável',
    }[type];
  }

  public getTextFormatOfValue(binding: IBinding): string|number {
    if (binding.type === 'variable') {
      const variableOption = this.variableOptions.find(({ value }) => value === binding.value);

      if (variableOption) {
        return variableOption.text!;
      }
    }

    if (binding.type === 'text') {
      return `"${binding.value}"`;
    }

    return binding.value;
  }

  public getClassBasedOnIsEditing(index: number) {
    if (index === this.selectedBindingToEdit) {
      return 'is-editing-row-binding-background elevation-5';
    }

    return 'default-row-binding-background';
  }

  public async setValuesOnTotalizerFields(totalizer: CreditTotalizer): Promise<void> {
    this.name = totalizer.name;
    this.description = totalizer.description;
    this.active = totalizer.active;
    this.selectedExecutionType = totalizer.executionType;
    this.query = totalizer.query;

    await this.getVariables(totalizer.companyIds);

    this.selectedCompanies = totalizer.companyIds;
    this.bindings = totalizer.bindings.map((binding) => ({
      ...binding,
      text: this.getTextFormatOfValue(binding),
    }));
  }

  public setInputTypeValue(inputType: 'executable_expression' | 'variable' | 'text' | 'numeric'): void {
    this.definedInputValueType = inputType;

    for (let i = 0; i < this.inputValueTypes.length; i += 1) {
      const actualInputType = this.inputValueTypes[i];

      let isInputSelected = false;

      if (actualInputType.value === inputType) {
        isInputSelected = true;
      }

      actualInputType.isSelected = isInputSelected;
    }

    this.creditTotalizerBindingsForm.resetValidation();
    this.bindingValue = '';
  }

  public addOrUpdateNewBinding(): void {
    if (!this.creditTotalizerBindingsForm.validate()) {
      this.$notification.error('Há campos inválidos nos parâmetros!');

      return;
    }

    const bindingValues = {
      name: this.bindingName,
      value: this.bindingValue,
      type: this.definedInputValueType,
    };

    if (this.selectedBindingToEdit !== null) {
      this.bindings[this.selectedBindingToEdit] = {
        ...bindingValues,
        text: this.getTextFormatOfValue(bindingValues),
      };

      this.selectedBindingToEdit = null;
    } else {
      this.bindings.push({
        ...bindingValues,
        text: this.getTextFormatOfValue(bindingValues),
      });
    }

    this.resetFields();
  }

  public validateCreditTotalizer(): void {
    if (!this.creditTotalizerForm.validate()) {
      this.$notification.error('Alguns campos estão inválidos!');

      return;
    }

    if (this.bindings.length < 1) {
      this.$notification.error('Nenhum parâmetro de query foi inserido!');

      return;
    }

    if (this.isUpdate) {
      this.updateCreditTotalizer();

      return;
    }

    this.insertCreditTotalizer();
  }

  public resetFields(): void {
    this.creditTotalizerBindingsForm.resetValidation();

    this.bindingName = '';
    this.bindingValue = '';
  }

  public cancel(): void {
    this.$router.push('/totalizadores-de-credito');
  }

  public handleSelectCompany(companyIds: number[]): void {
    if (companyIds.length < 1) {
      this.variableOptions = [];

      return;
    }

    this.getVariables(companyIds);
  }

  public handleRemoveQueryBinding(index: number): void {
    this.bindings.splice(index, 1);

    if (this.selectedBindingToEdit === index) {
      this.selectedBindingToEdit = null;
      this.resetFields();
    }
  }

  public handleEditQueryBinding(index: number): void {
    if (this.selectedBindingToEdit !== null && this.selectedBindingToEdit === index) {
      this.selectedBindingToEdit = null;

      this.resetFields();

      return;
    }

    const bindToEdit = this.bindings[index];

    this.setInputTypeValue(bindToEdit.type);
    this.bindingName = bindToEdit.name;
    this.bindingValue = bindToEdit.value;

    this.selectedBindingToEdit = index;
  }
}
