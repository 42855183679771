import pdfMake from "pdfmake/build/pdfmake.min.js";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { API_BASE } from '@config/api'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class PrintCashFlow {

    constructor() {
        //this._name = name
    }

    /*get name() {
        return this._name
    }*/

    print(companyDescription, activeDates, cashFlowData, cashFlowBillingData, selfComponent, params) {
        let self = this;
        let lineBreak = '\r\n';

        blockPage('Gerando relatório...');

        console.log(selfComponent);

        $.ajax({
            method: "GET",
            url: API_BASE + 'companygroup/logo/' + selfComponent.$session.get('company_group_id'),
            headers: {
                'token': selfComponent.$session.get('token')
            },
        }).done(function (result) {
            let title = 'Fluxo de Caixa Realizado';
            let activeDatesChecked = activeDates.filter((activeDate) => {
                return activeDate.checked;
            });

            if (activeDatesChecked.length > 0) {
                activeDates = activeDatesChecked;
            }

            if (params.printBillingParam) {
                title = 'Faturamento';
            }

            let docDefinition = {
                pageOrientation: params.printModeParam == 1 ? 'portrait' : 'landscape',


                header: function (currentPage, pageCount) {
                    let headerText = {text: ''};

                    if (currentPage == 1) {
                        headerText = {
                            text: 'Impresso em ' + moment().format('DD/MM/YY') + ' às ' + moment().format('HH:mm:ss'),
                            alignment: 'right',
                            fontSize: 8,
                            margin: [0, 10, 10, 0]
                        };
                    }

                    return headerText;
                },
                footer: function (currentPage, pageCount) {
                    let footerText = {
                        text: currentPage.toString() + '/' + pageCount,
                        alignment: 'right',
                        fontSize: 10,
                        margin: [0, 10, 20, 0]
                    };

                    return footerText;
                },

                content: [
                    {
                        image: result.image,
                        width: 200
                    },
                    {
                        text: title,
                        alignment: 'right',
                        fontSize: 15,
                        bold: true,
                    },
                    lineBreak,
                    {
                        text: [
                            {
                                text: 'Empresa: ',
                                alignment: 'left',
                                fontSize: 10,
                                bold: true,
                            },
                            {
                                text: companyDescription,
                                alignment: 'left',
                                fontSize: 10,
                            },
                        ]
                    },
                    lineBreak,

                    params.printBillingParam ? self.mountCashflowBillingTable(activeDates, cashFlowBillingData) : '',

                    params.printBillingParam ? {
                        text: 'Fluxo de Caixa Realizado',
                        alignment: 'right',
                        fontSize: 15,
                        bold: true,
                        pageBreak: 'before'
                    } : '',

                    lineBreak,

                    params.printBillingParam ? {
                        text: [
                            {
                                text: 'Empresa: ',
                                alignment: 'left',
                                fontSize: 10,
                                bold: true,
                            },
                            {
                                text: companyDescription,
                                alignment: 'left',
                                fontSize: 10,
                            },

                            lineBreak,
                            lineBreak,
                        ]
                    } : '',

                    self.mountCashflowTable(activeDates, cashFlowData)

                ],
                styles: {
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }


            };

            pdfMake.createPdf(docDefinition).print();

            unblockPage();


        }).fail(function (error) {
            console.log(error);
            unblockPage();
        });


    }

    mountCashflowTable(activeDates, cashflowData) {

        let self = this;

        //Essa variável já vem setada com o header da primeira coluna.
        let header = [
            {
                text: "",
            }
        ];
        let body = [];
        let widths = ['*'];

        console.log('ActiveDates', activeDates);
        console.log('cashflowData', cashflowData);

        //Percorre o array com as datas e adiciona no array de retorno do body. Nesse caso os valores das datas serão os cabeçalhos.
        activeDates.forEach((element, index, array) => {
            header.push(
                {
                    text: element.date + '\r\n' + element.week,
                    style: 'tableHeader',
                    alignment: 'right',
                    fontSize: 10
                },
            );

            //Seta tamanho da coluna. A primeira coluna é *, pois a Descrição não tem tamanho definido.
            widths.push(80);

        });

        //Aqui percorre o array de registros e concatena no body as informações dos valores por coluna
        cashflowData.forEach((dataRow, index, array) => {
            let localBody = [
                {
                    text: dataRow.description,
                    alignment: 'left',
                    fontSize: 10,
                    bold: true,
                    color: '#6b6b6b'
                }
            ];

            dataRow.data_values.forEach((dataValue, index, array) => {
                if (self.printDate(activeDates, dataValue)) {
                    localBody.push(
                        {
                            text: numeral(dataValue.value).format("0,0.00"),
                            alignment: 'right',
                            fontSize: 10,
                            bold: true
                        }
                    );
                }

            });

            //Adiciona na linha da tabela.
            body.push(
                localBody
            );

            localBody = [];

            if (dataRow.data) {
                //Percorre cada registro do level main
                dataRow.data.forEach((levelMainDataValue, index, array) => {

                    localBody.push(
                        {
                            text: levelMainDataValue.level_one.description,
                            alignment: 'left',
                            fontSize: 10,
                            bold: true,
                            margin: [5, 0, 0, 0]
                        }
                    )

                    //Percorre cara registro do level level one
                    levelMainDataValue.level_one.data_values.forEach((levelOneDataValue, index, array) => {
                        if (self.printDate(activeDates, levelOneDataValue)) {
                            localBody.push(
                                {
                                    text: numeral(levelOneDataValue.value).format("0,0.00"),
                                    alignment: 'right',
                                    fontSize: 10,
                                    bold: true
                                }
                            );
                        }

                    });

                    if (localBody.length > 0) {
                        //Adiciona na linha da tabela.
                        body.push(
                            localBody
                        );
                    }
                    localBody = [];

                    //Percorre cada registro do level two.
                    levelMainDataValue.level_two.forEach((levelTwo, index, array) => {

                        localBody.push(
                            {
                                text: levelTwo.description,
                                alignment: 'left',
                                fontSize: 10,
                                bold: true,
                                margin: [10, 0, 0, 0]
                            }
                        );

                        levelTwo.data_values.forEach((leveTwoDataValues, index, array) => {

                            if (self.printDate(activeDates, leveTwoDataValues)) {
                                localBody.push(
                                    {
                                        text: numeral(leveTwoDataValues.value).format("0,0.00"),
                                        alignment: 'right',
                                        fontSize: 10,
                                        bold: true
                                    }
                                );
                            }

                        });


                        if (localBody.length > 0) {
                            //Adiciona na linha da tabela.
                            body.push(
                                localBody
                            );
                        }
                        localBody = [];

                        //Percorre cada registro do level three.
                        levelTwo.level_three.forEach((levelThree, index, array) => {

                            localBody.push(
                                {
                                    text: levelThree.description,
                                    alignment: 'left',
                                    fontSize: 10,
                                    bold: true,
                                    margin: [15, 0, 0, 0],
                                }
                            );

                            levelThree.data_values.forEach((levelThreeDataValue, index, array) => {
                                if (self.printDate(activeDates, levelThreeDataValue)) {
                                    localBody.push(
                                        {
                                            text: numeral(levelThreeDataValue.value).format("0,0.00"),
                                            alignment: 'right',
                                            fontSize: 10,
                                            bold: true
                                        }
                                    );
                                }

                            });


                            if (localBody.length > 0) {
                                //Adiciona na linha da tabela.
                                body.push(
                                    localBody
                                );
                            }
                            localBody = [];

                            //Percorre cada registro do level four.
                            levelThree.level_four.forEach((levelFour, index, array) => {

                                localBody.push(
                                    {
                                        text: levelFour.description,
                                        alignment: 'left',
                                        fontSize: 10,
                                        margin: [20, 0, 0, 0]
                                    }
                                );

                                levelFour.data_values.forEach((levelFourDataValue, index, array) => {
                                    if (self.printDate(activeDates, levelFourDataValue)) {
                                        localBody.push(
                                            {
                                                text: numeral(levelFourDataValue.value).format("0,0.00"),
                                                alignment: 'right',
                                                fontSize: 10
                                            }
                                        );
                                    }

                                });

                                if (localBody.length > 0) {
                                    //Adiciona na linha da tabela.
                                    body.push(
                                        localBody
                                    );
                                }
                                localBody = [];

                            });


                        });


                    });


                });

                if (localBody.length > 0) {
                    //Adiciona na linha da tabela.
                    body.push(
                        localBody
                    );
                }
            }


        });

        //Adiciona o header na primeira posição.
        body.unshift(header);


        return {
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: widths,
                body: body
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 1 ? 2 : 0);
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                },
                vLineColor: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                },
                fillColor: function (i, node) {
                    return (i % 2 === 0) ? '#f4f4f4' : null;
                }
            }
        }


    }


    mountCashflowBillingTable(activeDates, cashflowBillingData) {

        let self = this;

        //Essa variável já vem setada com o header da primeira coluna.
        let header = [
            {
                text: "",
            }
        ];
        let body = [];
        let widths = ['*'];

        //Percorre o array com as datas e adiciona no array de retorno do body. Nesse caso os valores das datas serão os cabeçalhos.
        activeDates.forEach((element, index, array) => {
            header.push(
                {
                    text: element.date + '\r\n' + element.week,
                    style: 'tableHeader',
                    alignment: 'right',
                    fontSize: 10
                },
            );

            //Seta tamanho da coluna. A primeira coluna é *, pois a Descrição não tem tamanho definido.
            widths.push(80);

        });

        //Aqui percorre o array de registros e concatena no body as informações dos valores por coluna
        cashflowBillingData.forEach((dataRow, index, array) => {
            let localBody = [
                {
                    text: dataRow.description,
                    alignment: 'left',
                    fontSize: 10,
                    bold: true,
                    color: '#6b6b6b'
                }
            ];

            dataRow.data_values.forEach((dataValue, index, array) => {
                if (self.printDate(activeDates, dataValue)) {
                    localBody.push(
                        {
                            text: numeral(dataValue.value).format("0,0.00"),
                            alignment: 'right',
                            fontSize: 10,
                            bold: true
                        }
                    );
                }

            });

            //Adiciona na linha da tabela.
            body.push(
                localBody
            );

            localBody = [];

            if (dataRow.data) {
                //Percorre cada registro do level main
                dataRow.data.forEach((levelMainDataValue, index, array) => {

                    localBody.push(
                        {
                            text: levelMainDataValue.level_one.description,
                            alignment: 'left',
                            fontSize: 10,
                            bold: true,
                            margin: [5, 0, 0, 0]
                        }
                    )

                    //Percorre cara registro do level level one
                    levelMainDataValue.level_one.data_values.forEach((levelOneDataValue, index, array) => {
                        if (self.printDate(activeDates, levelOneDataValue)) {
                            localBody.push(
                                {
                                    text: numeral(levelOneDataValue.value).format("0,0.00"),
                                    alignment: 'right',
                                    fontSize: 10,
                                    bold: true
                                }
                            );
                        }

                    });

                    if (localBody.length > 0) {
                        //Adiciona na linha da tabela.
                        body.push(
                            localBody
                        );
                    }
                    localBody = [];

                    //Percorre cada registro do level two.
                    levelMainDataValue.level_two.forEach((levelTwo, index, array) => {

                        localBody.push(
                            {
                                text: levelTwo.description,
                                alignment: 'left',
                                fontSize: 10,
                                bold: true,
                                margin: [10, 0, 0, 0]
                            }
                        );

                        levelTwo.data_values.forEach((leveTwoDataValues, index, array) => {

                            if (self.printDate(activeDates, leveTwoDataValues)) {
                                localBody.push(
                                    {
                                        text: numeral(leveTwoDataValues.value).format("0,0.00"),
                                        alignment: 'right',
                                        fontSize: 10,
                                        bold: true
                                    }
                                );
                            }

                        });


                        if (localBody.length > 0) {
                            //Adiciona na linha da tabela.
                            body.push(
                                localBody
                            );
                        }
                        localBody = [];

                        //Percorre cada registro do level three.
                        levelTwo.level_three.forEach((levelThree, index, array) => {

                            localBody.push(
                                {
                                    text: levelThree.description,
                                    alignment: 'left',
                                    fontSize: 10,
                                    bold: true,
                                    margin: [15, 0, 0, 0],
                                }
                            );

                            levelThree.data_values.forEach((levelThreeDataValue, index, array) => {
                                if (self.printDate(activeDates, levelThreeDataValue)) {
                                    localBody.push(
                                        {
                                            text: numeral(levelThreeDataValue.value).format("0,0.00"),
                                            alignment: 'right',
                                            fontSize: 10,
                                            bold: true
                                        }
                                    );
                                }

                            });


                            if (localBody.length > 0) {
                                //Adiciona na linha da tabela.
                                body.push(
                                    localBody
                                );
                            }
                            localBody = [];

                            //Percorre cada registro do level four.
                            levelThree.level_four.forEach((levelFour, index, array) => {

                                localBody.push(
                                    {
                                        text: levelFour.description,
                                        alignment: 'left',
                                        fontSize: 10,
                                        margin: [20, 0, 0, 0]
                                    }
                                );

                                levelFour.data_values.forEach((levelFourDataValue, index, array) => {
                                    if (self.printDate(activeDates, levelFourDataValue)) {
                                        localBody.push(
                                            {
                                                text: numeral(levelFourDataValue.value).format("0,0.00"),
                                                alignment: 'right',
                                                fontSize: 10
                                            }
                                        );
                                    }

                                });

                                if (localBody.length > 0) {
                                    //Adiciona na linha da tabela.
                                    body.push(
                                        localBody
                                    );
                                }
                                localBody = [];

                            });


                        });


                    });


                });

                if (localBody.length > 0) {
                    //Adiciona na linha da tabela.
                    body.push(
                        localBody
                    );
                }
            }


        });

        //Adiciona o header na primeira posição.
        body.unshift(header);


        return {
            style: 'tableExample',
            table: {
                headerRows: 1,
                widths: widths,
                body: body
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 1 ? 2 : 0);
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                },
                vLineColor: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                },
                fillColor: function (i, node) {
                    return (i % 2 === 0) ? '#f4f4f4' : null;
                }
            }
        }


    }


    printDate(activeDateToPrint, dateToVerify) {
        let lRet = false;
        for (let i = 0; i < activeDateToPrint.length; i++) {
            if (activeDateToPrint[i].dateHidden == dateToVerify.date) {
                lRet = true;
                break;
            }
        }
        return lRet;

    }


}
