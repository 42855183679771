import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6"},[_c(VRow,[_c(VCol,[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)],1),_c(VCardText,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1),(_vm.number && _vm.number.length > 0)?_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"append-icon":"mdi-content-copy","hide-details":"","readonly":"","filled":"","dense":""},on:{"click":function($event){return _vm.clipboardCopy(_vm.number)}},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1)],1):_vm._e()],1),_c(VCardActions,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"info","depressed":"","large":"","block":"","dark":""},on:{"click":function($event){return _vm.next()}}},[_vm._v(" CONTINUAR ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }