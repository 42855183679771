import { isDateBetween } from '@/utils/date';
import PeriodicityEnum from '@/views/cashFlow/predictedFlow/enums/PeriodicityEnum';
import IPeriodicityRangeDates from '@/views/cashFlow/predictedFlow/interfaces/IPeriodicityRangeDates';

export default class PredictedFlowTotalsHelper {
  public static isDateBetweenSomeRange(
    date: string,
    selectedPeriodicity: PeriodicityEnum,
    periodicityDates: IPeriodicityRangeDates[],
  ): boolean {
    const firstIndex = 0;
    const lastIndex = periodicityDates.length - 1;

    if (selectedPeriodicity === PeriodicityEnum.DAILY) {
      return isDateBetween(
        date,
        periodicityDates[firstIndex].dateUnformatted!,
        periodicityDates[lastIndex].dateUnformatted!,
      );
    }

    return isDateBetween(
      date,
      periodicityDates[firstIndex].dateRange!.unformattedInitial,
      periodicityDates[lastIndex].dateRange!.unformattedFinal,
    );
  }

  public static getTotalsIndexBasedOnDate(
    date: string,
    selectedPeriodicity: PeriodicityEnum,
    periodicityDates: IPeriodicityRangeDates[],
  ): number {
    let definedIndex = NaN;

    for (let i = 0; i < periodicityDates.length; i += 1) {
      const { dateUnformatted, dateRange } = periodicityDates[i];

      if (selectedPeriodicity === PeriodicityEnum.DAILY && date === dateUnformatted) {
        definedIndex = i;
        break;
      }

      if (
        [PeriodicityEnum.WEEKLY, PeriodicityEnum.MONTHLY].includes(selectedPeriodicity)
        && isDateBetween(date, dateRange!.unformattedInitial, dateRange!.unformattedFinal)
      ) {
        definedIndex = i;
        break;
      }
    }

    return definedIndex;
  }
}
