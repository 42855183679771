<template>
  <v-btn icon :small="small" :color="color" v-bind="$attrs" v-on="$listeners">
    <v-icon :small="small">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'InnIconButton',
  props: {
    icon: String,
    color: String,
    small: Boolean,
  },
};
</script>

<style></style>
