import ColumnToShow from '@/types/ColumnToShow';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';

export default class FilterParameterCustomerSellsInOrderScreen {
  public constructor(
    public tableDirection: boolean[],
    public tableSort: string[],
    public columnToSearch: string,
    public nfEmissionRangeDate: string[],
    public saleInclusionDateRange: string[],
    public rowsPerPage: number,
    public actualPage: number,
    public columnsToShow: ColumnToShow[],
  ) {
  }

  public static make(data: any): FilterParameterCustomerSellsInOrderScreen {
    const dataColumn = {
      data_table_sale_orders_order_screen_show_column_invoice_serie: 'invoiceSerie',
      data_table_sale_orders_order_screen_show_column_issue_date: 'invoiceEmission',
      data_table_sale_orders_order_screen_show_column_order: 'order',
      data_table_sale_orders_order_screen_show_column_order_inclusion_date: 'orderInclusionDate',
      data_table_sale_orders_order_screen_show_column_channel: 'channel',
      data_table_sale_orders_order_screen_show_column_value: 'value',
      data_table_sale_orders_order_screen_show_column_status: 'status',
    };

    let arrayDirection: boolean[] = [];
    let arraySort: string[] = [];

    if (data.sort_table_sale_orders_order_screen) {
      const { direction, sort } = FilterParameterHelper
      .defineOrdenation(data.sort_table_sale_orders_order_screen);

      arrayDirection = [direction];
      arraySort = [sort];
    }

    const parsedRowsPerPage = parseInt(data.rows_per_page_sale_orders_order_screen, 10);
    const parsedActualPage = parseInt(data.actual_page_sale_orders_order_screen, 10);

    const nfEmissionRangeDate = data.nf_emission_range_date_search_sale_orders_order_screen
      ? JSON.parse(
        data.nf_emission_range_date_search_sale_orders_order_screen,
      ) : [];
    const saleInclusionDateRange = data.inclusion_range_date_search_sale_orders_order_screen
      ? JSON.parse(
        data.inclusion_range_date_search_sale_orders_order_screen,
      ) : [];

    return new FilterParameterCustomerSellsInOrderScreen(
      arrayDirection,
      arraySort,
      data.column_to_search_sale_orders_order_screen ?? '',
      nfEmissionRangeDate,
      saleInclusionDateRange,
      !Number.isNaN(parsedRowsPerPage) ? parsedRowsPerPage : 10,
      !Number.isNaN(parsedActualPage) ? parsedActualPage : 1,
      FilterParameterHelper.mountColumnToShowArray(data, dataColumn),
    );
  }
}
