export default class Operator {
  constructor(
    public id: number,
    public name: string,
    public idCustomer?: string,
  ) {}

  static make(data: any): Operator {
    return new Operator(
      data.id,
      data.name,
      data.id_customer,
    );
  }
}
