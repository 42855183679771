


























































import {
  Component,
  Emit,
  Mixins,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import BillingPendencyRepository from '@/repositories/BillingPendencyRepository';
import BillingPendency from '@/domain/models/BillingPendency';
import IOption from '@/domain/interfaces/ISelectOptions';

@Component
export default class BillingPendencyTransferDialog
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  @Prop({
    type: Array as () => Array<BillingPendency>,
    required: true,
    default: [],
  }) public pendencies!: Array<BillingPendency>;

  @Emit('close')
  public close(reload: boolean = false) {
    this.model = false;
    this.loading = false;
    this.operator = 0;

    return reload;
  }

  @Watch('model')
  public onOpen(value: boolean) {
    if (value && !this.operators.length) this.loadOperators();
  }

  public readonly billingPendencyRepository
    : BillingPendencyRepository = new BillingPendencyRepository();

  public loading: boolean = false;
  public loadingOperators: boolean = false;

  public operator: number = 0;
  public operators: Array<IOption<number>> = [];

  public get isMultiple(): boolean {
    return this.pendencies.length > 1;
  }

  public get groupId(): number {
    return this.$session.get('company_group_id');
  }

  public get maxWidth(): string {
    if (this.$vuetify.breakpoint.xs) return '100%';

    if (this.$vuetify.breakpoint.smAndDown) return '80%';

    if (this.$vuetify.breakpoint.mdAndDown) return '60%';

    return '40%';
  }

  public save(): void {
    const costumers = this.pendencies.map(({ id }) => id);
    const { operator } = this;

    this.transferPendencies(costumers, operator);
  }

  public async loadOperators(): Promise<void> {
    try {
      this.loadingOperators = true;
      const operators = await this.billingPendencyRepository
        .getOperators(this.groupId);

      this.operators = operators
        .map((operator) => ({ text: operator.name, value: operator.id }));
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingOperators = false;
    }
  }

  public async transferPendencies(
    costumers: Array<string | number>,
    operator: number,
  ): Promise<void> {
    try {
      this.loading = true;

      await Promise.all(this.pendencies.map(async (pendency) => {
        await this.billingPendencyRepository
        .sendPendenciesTransfer(this.groupId, pendency.id, {
          costumers,
          operator,
        });
      }));

      this.$notification.success('Trasnferência de pendências concluída com sucesso!');

      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
