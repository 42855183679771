import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"headers":_vm.availableHeadersToShow,"items":_vm.accountsReceivable,"disable-sort":"","loading":_vm.loadingTable,"server-items-length":_vm.totalItems,"options":_vm.dataTableOptions,"footer-props":_vm.footerOptions},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(((item.number) + " " + (item.type) + " " + (item.prefix) + " " + (item.installment)))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.companyFantasyName)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.customerFantasyName)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.setDateToBrazilianFormat(item.dueDate))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.daysLate)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getMoneyFormat(item.value))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getMoneyFormat(item.balance))+" ")]),_c('td',{staticStyle:{"vertical-align":"middle"}},[(_vm.canEdit)?_c('content-editable-component',{attrs:{"value":item.financialFee,"formatted-value-to-show":_vm.getMoneyFormat(item.financialFee),"type-of-value":"number","with-button":"","rules":[
                  _vm.inputRules.required(),
                  _vm.inputRules.isNumber(),
                  _vm.inputRules.positive() ]},on:{"on-confirm":function($event){return _vm.handleContentEditableListConfirm(
                  $event,
                  item.idCustomer,
                  'financial'
                )}}}):_c('span',[_vm._v(" "+_vm._s(_vm.getMoneyFormat(item.financialFee))+" ")])],1),_c('td',{staticStyle:{"vertical-align":"middle"}},[(_vm.canEdit)?_c('content-editable-component',{attrs:{"value":item.percentageFee,"formatted-value-to-show":((item.percentageFee) + "%"),"type-of-value":"number","with-button":"","rules":[
                  _vm.inputRules.required(),
                  _vm.inputRules.isNumber(),
                  _vm.inputRules.positive() ]},on:{"on-confirm":function($event){return _vm.handleContentEditableListConfirm(
                  $event,
                  item.idCustomer,
                  'percentage'
                )}}}):_c('span',[_vm._v(" "+_vm._s(((item.percentageFee) + "%"))+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.getMoneyFormat(item.totalValue))+" ")]),(_vm.canEdit)?_c('td',[_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-close","tooltip-text":"Desconsiderar Título"},on:{"click":function($event){return _vm.handleExcludeAccount(item)}}})],1):_vm._e()])}),_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" Total ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMoneyFormat(_vm.preNegotiationModule.accountsTotal.totalValue))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMoneyFormat(_vm.preNegotiationModule.accountsTotal.totalBalance))+" ")]),_c('td',{staticClass:"font-weight-bold"},[(_vm.canEdit)?_c('content-editable-component',{attrs:{"value":_vm.preNegotiationModule.accountsTotal.financialFee,"formatted-value-to-show":_vm.getMoneyFormat(
                    _vm.preNegotiationModule.accountsTotal.financialFee
                ),"type-of-value":"number","with-button":"","rules":[
                  _vm.inputRules.required(),
                  _vm.inputRules.isNumber(),
                  _vm.inputRules.positive() ]},on:{"on-confirm":function($event){return _vm.handleContentEditableTotalConfirm($event, 'financial')}}}):_c('span',[_vm._v(" "+_vm._s(_vm.getMoneyFormat(_vm.preNegotiationModule.accountsTotal.financialFee))+" ")])],1),_c('td',{staticClass:"font-weight-bold"},[(_vm.canEdit)?_c('content-editable-component',{attrs:{"value":_vm.preNegotiationModule.accountsTotal.percentageFee,"formatted-value-to-show":((_vm.preNegotiationModule.accountsTotal.percentageFee) + "%"),"type-of-value":"number","with-button":"","rules":[
                  _vm.inputRules.required(),
                  _vm.inputRules.isNumber(),
                  _vm.inputRules.positive() ]},on:{"on-confirm":function($event){return _vm.handleContentEditableTotalConfirm($event, 'percentage')}}}):_c('span',[_vm._v(" "+_vm._s(((_vm.preNegotiationModule.accountsTotal.percentageFee) + "%"))+" ")])],1),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMoneyFormat(_vm.preNegotiationModule.accountsTotal.totalBalanceWithFees))+" ")]),(_vm.canEdit)?_c('td'):_vm._e()])],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }