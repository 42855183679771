<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <div class="input-group date">
      <datepicker
        ref="picker"
        :id="id"
        class="form-control m-input"
        placeholder="Selecione a data"
        :language="language"
        :disabled-dates="disabledDates"
        :value="model"
        @selected="emit"
        :format="customFormater"
      >
        <span slot="afterDateInput" @click="openPicker" class="input-group-addon vdp-addon">
          <i class="la la-calendar-check-o"></i>
        </span>
      </datepicker>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
/* eslint-disable object-shorthand */
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale';

export default {
	name: 'DateSelect',
	components: {
		Datepicker,
	},
	props: {
		label: String,
		id: String,
		value: [String, Date],
		disabledDates: Object,
	},
	data() {
		return {
			language: ptBR,
			model: this.value ? moment(this.value, 'DD/MM/YYYY').toDate() : null,
		};
	},
	methods: {
    customFormater(date) {
      return moment(date).format('DD/MM/YYYY');
    },
		openPicker() {
      this.$refs.picker.showCalendar();
		},
		emit(newValue) {
			this.$emit('change', moment(newValue).format('DD/MM/YYYY'));
		},
	},
  watch: {
    // eslint-disable-next-line func-names
    value: function (newVal) {
      this.model = moment(newVal, 'DD/MM/YYYY').toDate();
    },
  },
};
</script>

<style>
.vdp-addon {
  margin: 0px;
  max-width: 48px;
  position: absolute;
  right: -1px;
  top: -1px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  align-items: center;
  justify-content: center;
  bottom: -1px;
}
.vdp-datepicker {
  padding: 0;
  z-index: auto !important;
}
.vdp-datepicker > div:not(.vdp-datepicker__calendar) {
  flex: 1 1 auto;
  display: flex;
}
.vdp-datepicker input {
  padding: 0.65rem 1.25rem;
  text-align: center;
  width: calc(100% - 48px);
}
.vdp-datepicker input::placeholder {
  color: #9499a0;
  opacity: 1;
}
.vdp-datepicker div:focus,
.vdp-datepicker input:focus {
  outline: unset;
}
.vdp-datepicker__calendar {
  top: 40px;
  left: 0;
  width: 266px;
  border-radius: 6px;
}
.vdp-datepicker__calendar .prev::after {
  content: ">";
}
.vdp-datepicker__calendar .cell.selected {
  background: #5867dd !important;
  color: #fff;
}
</style>
