export default class CreditTotalizerList {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public active: 'active' | 'inactive',
    public executionType: 'query' | 'code',
    public companyId: number,
  ) {}

  public static make(data: any): CreditTotalizerList {
    return new CreditTotalizerList(
      data.id,
      data.name,
      data.description,
      data.active,
      data.executionType,
      data.companyId,
    );
  }
}
