<template>
	<v-btn
		class="font-weight-bold"
		:class="customClasses()"
		:color="customOrDefaultColor()"
		@click="add"
		large
		block
		depressed
	>
		ADICIONAR
	</v-btn>
</template>

<script>
/**
 * Propriedades
 *
 * button-class: classes adicionais
 * color: cor
 *
 * eventos (ambos fazem a mesma coisa)
 * @click
 * @add
 */
export default {
	name: 'ListAddButton',
	components: {},
	props: {
		buttonClass: String,
		color: String,
	},
	methods: {
		add() {
			this.$emit('add');
			this.$emit('click');
		},
		customOrDefaultColor() {
			return this.color || 'info';
		},
		customClasses() {
			return this.buttonClass || '';
		},
	},
};
</script>

<style>

</style>
