import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getStatusColor(item.status),"text-color":_vm.getStatusTextColor(item.status),"small":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","buttonIcon":"mdi-pencil","tooltipText":"Editar"},on:{"click":function($event){return _vm.handlerUpdateBillingMethod(item.id)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }