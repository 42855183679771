import axios from 'axios';
import FileHelper from '@/helpers/FileHelper';

export default class RequestExceptionHelper {
  public static async extractBodyFromRequestException<T = any>(
    requestError: unknown,
    isBlobFile: boolean = false,
  ): Promise<T | undefined> {
    if (!axios.isAxiosError(requestError)) {
      return undefined;
    }

    const { response } = requestError.request;

    if (isBlobFile) {
      const base64 = await FileHelper.blobToBase64(response);
      const errorMessageJson = FileHelper.extractOnlyBase64Code(base64);

      return JSON.parse(atob(errorMessageJson));
    }

    return response;
  }
}
