<template>
  <v-radio-group v-model="model" :row="row" :label="label" v-bind="$attrs" v-on="$listeners">
    <v-radio
      v-for="option in items"
      :key="option.id"
      :label="option.label"
      :value="option.value"
    ></v-radio>
  </v-radio-group>
</template>

<script>
import ModelManagementMixin from '@/mixins/ModelManagementMixin';

export default {
  name: 'InnRadioGroup',
  mixins: [ModelManagementMixin],
  props: {
    label: String,
    items: Array,
    placeholder: String,
    hideDetails: Boolean,
    rules: Array,
    prependInnerIcon: String,
    row: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
