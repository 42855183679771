<template>
  <v-menu close-on-click bottom nudge-bottom="20">
    <template v-slot:activator="{ on, attrs }">
      <i class="fa fa-bars dropdown-menu-icon ml-n1" v-bind="attrs" v-on="on" />
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title @click="showOccurrencesModal(item)">
          Ver Ocorrências
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title @click="emit('showDetailsModal')">
          Visualizar
        </v-list-item-title>
      </v-list-item>
      <v-list-item  v-if="item.borderoNumber">
        <v-list-item-title @click="emit('removeAccount')">
          Remover do borderô
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="item.documentUrl != null">
        <v-list-item-title @click="showBoleto(item)">
          Visualizar Boleto
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'BillingRemittanceItemMenu',
  props: {
    item: Object,
    showOccurrencesModal: Function,
  },
  data() {
    return {
      showDetailsModal: false,
    };
  },
  methods: {
    showBoleto(item) {
      window.open(item.documentUrl, '_blank');
    },
    showModal() {
      this.showDetailsModal = true;
    },
    emit(event) {
      this.$emit(event);
    },
  },
};
</script>

<style scoped>
.dropdown-menu-icon {
  cursor: pointer;
}
.v-list-item {
  padding: 0;
  min-height: 20px;
}
.v-list-item__title {
  padding: 0.4rem 1.2rem;
  cursor: pointer;
}
.v-list-item__title:hover {
  background: #f4f5f8;
}
</style>
