<template>
  <v-chip small :color="color" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-chip>
</template>

<script>
export default {
  name: 'InnChip',
  props: {
    color: String,
  },
};
</script>

<style></style>
