export default class BillingRemittanceAccountReceivableOccurrence {
  constructor(
    public code: string,
    public date: string,
    public description: string,
  ) { }

  static make(data: any): BillingRemittanceAccountReceivableOccurrence {
    return new BillingRemittanceAccountReceivableOccurrence(
      data.code,
      data.date,
      data.description,
    );
  }
}
