import Repository from '@/repositories/base/Repository';
import SettleBankAccount from '@/domain/models/SettleBankAccount';

export default class SettledBankRepository extends Repository {
  public async getSettledBankReturnByRemittanceId(
    companyGroupId: number,
    remittanceId: number,
  ): Promise<SettleBankAccount> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/bank-returns/${remittanceId}`);

    return SettleBankAccount.make(response.data);
  }
}
