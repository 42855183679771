

















































































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClientModule from '@/stores/modules/ClientModule';
import ICreditBlockingSelectOptions from '../interfaces/ICreditBlockingSelectOptions';
import CreditBlockingSituationsEnum from '../enums/CreditBlockingSituationsEnum';

@Component
export default class ClientGeneral extends Vue {
  public clientModule: ClientModule = getModule(ClientModule);

  public creditBlockingSituationOptions: ICreditBlockingSelectOptions[] = [
    { value: CreditBlockingSituationsEnum.NO_BLOCKING, text: 'Sem Bloqueio', disabled: false },
    { value: CreditBlockingSituationsEnum.DAY_RELEASE, text: 'Liberação dia', disabled: false },
    { value: CreditBlockingSituationsEnum.NO_RESTRICTIONS, text: 'Cliente sem restrições', disabled: true },
    { value: CreditBlockingSituationsEnum.BLOCKED, text: 'Bloqueado', disabled: true },
  ];

  public handleChangeDeadlineForBlocking() {
    if (this.clientModule.client.deadlineForBlocking) {
      this.clientModule.client.creditBlockingSituation = CreditBlockingSituationsEnum.BLOCKED;
    } else {
      this
        .clientModule.client.creditBlockingSituation = CreditBlockingSituationsEnum.NO_BLOCKING;
    }
  }

  public handleChangeCreditBlockingSituation() {
    this.clientModule.client.deadlineForBlocking = null;
  }
}
