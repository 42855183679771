<template>
  <input 
    ref="inputRef"
    @focus="$event.target.select()"
    class="currency-input-style"
  />
</template>

<script>
  import { watch } from '@vue/composition-api'
  import { useCurrencyInput } from 'vue-currency-input'

  export default {
    name: 'CurrencyInput',
    props: {
      value: Number,
    },
    setup(props) {
      const config = { 
        currency: 'BRL', 
        locale: 'pt-BR', 
        currencyDisplay: 'hidden', 
        autoDecimalDigits: true, 
        hideGroupingSeparatorOnFocus: false, 
        hideNegligibleDecimalDigitsOnFocus: false,
        valueRange: {
          min: 0, 
          max: 1000000000
        }
      };

      const { inputRef, setValue } = useCurrencyInput(config);

      watch(
        () => props.value,
        (value, oldValue) => {
          (value != undefined && value != null)? setValue(value) : setValue(oldValue);
        }
      );

      return { inputRef };
    }
  }
</script>

<style>
  .currency-input-style {
    margin-left: 8px;
    border: 1px solid #6666;
    border-radius: 4px;
    width: 100px;
    height: 32px;
    text-align: right;
    padding: 8px 12px;
    background: white;
    transition: border-color ease-in-out .15s;
  }

  .currency-input-style:hover {
    border-color: rgba(0,0,0,.50);
  }

  .currency-input-style:focus {
    outline: none;
    border-width: 2px;
    border-color: #5867dd;
  }

  .theme--dark .currency-input-style {
    background: #6666;
  }


</style>
