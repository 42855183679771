import debounce from 'lodash/debounce';

const sticky = (el, value, oldValue) => {
  let observer;

  if (value && oldValue && value.length > 0 && value.length != oldValue.length) {
    const table = el.querySelector('table');
    const thead = el.querySelector('thead');
    const rows = el.querySelectorAll('tr');
    const theadHeight = thead.getBoundingClientRect().height || 0;
    const lastRowHeight = rows[rows.length - 1].getBoundingClientRect().height || 0;

    const startSticky = document.createElement('div');
    const endSticky = document.createElement('div');
    const backgroundSticky = document.createElement('div');
    startSticky.className = 'start-sticky';
    endSticky.className = 'end-sticky';
    backgroundSticky.className = 'background-sticky';
    endSticky.style.top = `-${theadHeight + lastRowHeight / 2}px`;
    table.insertAdjacentElement('afterbegin', startSticky);
    table.insertAdjacentElement('afterbegin', backgroundSticky);
    table.insertAdjacentElement('beforeend', endSticky);

    const calculateColumnsWidths = () => {
      (table.querySelectorAll('thead .header-column') || []).forEach((col) => {
        if (!col.parentElement) return;
        const { width } = col.parentElement.getBoundingClientRect();
        col.style.width = `${width}px`;
        col.parentElement.style.width = `${width}px`;
      });
    };

    const calculateBackgroundWidth = () => {
      const theadWidth = thead.getBoundingClientRect().width;
      backgroundSticky.style.width = `${theadWidth}px`;
    };

    const show = () => {
      calculateColumnsWidths();
      calculateBackgroundWidth();
      table.parentElement.classList.add('sticky-header');
    };

    const hide = () => {
      backgroundSticky.style.removeProperty('width');
      (table.querySelectorAll('thead .header-column') || []).forEach((col) => {
        if (!col.parentElement) return;
        col.style.removeProperty('width');
        col.parentElement.style.removeProperty('width');
      });
      table.parentElement.classList.remove('sticky-header');
    };

    const onResize = debounce(() => {
      const isActive = table.parentElement.classList.contains('sticky-header');
      if (isActive) {
        calculateBackgroundWidth();
      }
    }, 300);

    window.onresize = onResize;

    const states = new Map();

    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          states.set(e.target, e.boundingClientRect);
        });
        const { top } = states.get(startSticky) || {};
        const { top: bottom } = states.get(endSticky) || {};

        if (top < 0 && bottom > 0) {
          show();
        } else {
          hide();
        }
      },
      {
        threshold: [0],
      },
    );

    observer.observe(startSticky);
    observer.observe(endSticky);
    el.dataset.sticky = true;
  } else if (!value || value.length <= 0) {
    el.dataset.sticky = false;
    const startSticky = el.querySelector('.start-sticky');
    const endSticky = el.querySelector('.end-sticky');
    const backgroundSticky = el.querySelector('.background-sticky');
    if (startSticky) startSticky.remove();
    if (endSticky) endSticky.remove();
    if (backgroundSticky) backgroundSticky.remove();
    if (observer) observer.disconnect();
  }
};

export default {
  componentUpdated(el, { value, oldValue }) {
    sticky(el, value, oldValue);
  },
  inserted(el, { value }) {
    sticky(el, value, []);
  },
};
