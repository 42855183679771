



























































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';

import AccountType from '@/domain/enums/AccountTypeEnum';
import BillingRemittanceActionType from '@/domain/enums/BillingRemittanceActionType';
import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';

import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';

import { formatErrorForNotification } from '../utils';

@Component({
})
export default class BillingRemittanceRemove extends Vue {
  @Prop(Boolean) open!: boolean;

  @Prop({
    type: Object as () => BillingRemittanceAccountReceivable,
  }) item!: BillingRemittanceAccountReceivable;

  @Emit()
  close() {
    return BillingRemittanceActionType.REMOVE;
  }

  @Emit()
  reload() {
    this.close();
  }

  readonly billingRemittanceRepository = new BillingRemittanceRepository();

  loading: boolean = false;

  async removeFromRemittance(): Promise<void> {
    try {
      this.loading = true;
      this.$dialog.startLoading();

      const success = await this.billingRemittanceRepository
        .excludeFromRemittance(
          this.$session.get('company_group_id'),
          this.item.cnab_remi_generated_id,
          this.item.idCustomer,
          AccountType.RECEIVABLE,
        );

      if (success) {
        this.loading = false;
        this.$dialog.stopLoading();
        this.$notification.success('Título removido do borderô.');
        this.reload();
      }
    } catch (error: any) {
      this.loading = false;
      this.$dialog.stopLoading();
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    }
  }
}
