export default class SaleGroup {
  constructor(
    public id: number,
    public code: string,
    public description: string,
  ) { }

  static make(data: any): SaleGroup {
    return new SaleGroup(
      data.id,
      data.code ?? '',
      data.description ?? '',
    );
  }
}
