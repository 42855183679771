















































































































import {
  Component,
  Vue,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ISignatureGroupByEconomicGroupCompanyAndCustomer from '@/views/preNegotiation/interfaces/ISignatureGroupByEconomicGroupCompanyAndCustomer';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IPreNegotiationSignature from '@/views/preNegotiation/interfaces/IPreNegotiationSignature';
import { VForm } from '@/types/VForm';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import SignatureTypeEnum from '@/views/preNegotiation/enums/SignatureTypeEnum';

@Component
export default class PreNegotiationSignature extends Vue {
  private readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();
  public readonly documentFormattation = DocumentFormattation;

  public signatureForm: IPreNegotiationSignature = {
    name: '',
    document: '',
    mail: '',
    type: SignatureTypeEnum.PART,
  }

  public subscriberTypeItems: ISelectOptions[] = [
    { value: 'part', text: 'Parte' },
    { value: 'witness', text: 'Testemunha' },
  ];
  public headers: IVDataTableHeader[] = [
    { text: 'Nome', value: 'name', width: '20%' },
    { text: 'CPF', value: 'document', width: '20%' },
    { text: 'E-Mail', value: 'mail', width: '20%' },
    { text: 'Tipo', value: 'type', width: '20%' },
    {
      text: '',
      value: 'action',
      width: '5%',
      sortable: false,
    },
  ];

  @Prop({
    type: Object,
    required: true,
  }) readonly signatureGroup!: ISignatureGroupByEconomicGroupCompanyAndCustomer;

  @Prop({
    type: Number,
    required: true,
  }) readonly index!: number;

  @Ref('PreNegotiationSignatureForm') readonly preNegotiationSignatureForm!: VForm;

  public get titleWithCompanyAndCustomerName(): string {
    const {
      customerName,
      companyName,
    } = this.signatureGroup;

    return `Assinaturas de ${customerName} para ${companyName}`;
  }

  public get canEdit(): boolean {
    if (this.$route.params.preNegotiationId) {
      return false;
    }

    return true;
  }

  public get availableHeaders(): IVDataTableHeader[] {
    if (this.canEdit) {
      return this.headers;
    }

    return this.headers.filter((header) => header.value !== 'action');
  }

  public getSubscriberTypeText(type: string): string {
    switch (type) {
      case 'part': return 'Parte';
      case 'witness': return 'Testemunha';
      default: return '';
    }
  }

  public getSubscriberTypeColor(type: string): string {
    switch (type) {
      case 'part': return '#34BFA3';
      case 'witness': return '#36A3F7';
      default: return '';
    }
  }

  public handleAddButtonClick(): void {
    if (!this.preNegotiationSignatureForm.validate()) {
      this.$notification.error('Os campos de assinatura estão inválidos.');

      return;
    }

    this.preNegotiationModule.setNewSignatureToSpecificGroup({
      index: this.index,
      name: this.signatureForm.name,
      document: this.documentFormattation.removeFormattationFromCnpj(this.signatureForm.document),
      mail: this.signatureForm.mail,
      type: this.signatureForm.type,
    });

    this.signatureForm = {
      name: '',
      document: '',
      mail: '',
      type: SignatureTypeEnum.PART,
    };
    this.preNegotiationSignatureForm.resetValidation();
  }

  public handleExcludeSignature(signatureIndex: number): void {
    this.preNegotiationModule.removeSpecificSignature({
      signatureGroupIndex: this.index,
      signatureRemovedIndex: signatureIndex,
    });
  }
}
