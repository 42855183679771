import axios, { AxiosError } from 'axios';

export function formatErrorForNotification(error: Error | AxiosError): string {
  let returnMessage = '';
  const errorMessageOrName = error.message ? error.message : error.name;

  if (axios.isAxiosError(error)) {
    const { message, errors } = error.response?.data
      || { message: errorMessageOrName, erros: null };

    if (typeof errors === 'object' && errors !== null) {
      let errorsStringify = '';

      if (Array.isArray(errors)) {
        errorsStringify = errors.reduce((prev, error) => {
          prev += Object
            .entries(error)
            .map(([key, value]) => (value == message ? '' : value))
            .join('\n');

          prev += '\n';
          return prev;
        }, '');
      } else {
        errorsStringify = Object.entries(errors)
          .map(([key, value]) => (value == message ? '' : value)).join('\n');
      }

      returnMessage = `${message} \n\n ${errorsStringify}`;
    } else {
      returnMessage = message;
    }
  }

  console.error(error);
  return returnMessage.length
    ? returnMessage
    : errorMessageOrName;
}

export function consoleError(error: any) {
  console.error(error);
}
