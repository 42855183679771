<style>
</style>
<template>
  <div>
    <div class="collapse show">
      <div class="row levels level-four">
        <div class="col-sm-3 pl-6">
          <span
            class="description"
            style="
              float: left;
              padding-left: 3px;
              padding-right: 30px;
              width: 330px;
            "
          >
            {{ spanDescription }}
          </span>
        </div>
        <div
          class="col-sm-8 mainInfos"
          style="padding-right: 0; padding-left: 0"
        >
          <div class="row" style="padding-right: 0; padding-left: 0">
            <div class="col-sm-1" v-if="showTotalizer === 'S'">
              <span>{{ sum(mainData.data_values) | formatNumber }}</span>
            </div>

            <div
              class="col-sm-1 text-center"
              style="padding-right: 0; padding-left: 0"
            >
              &nbsp;
            </div>

            <date-value
              :cash-flow-hidden-dates="cashFlowHiddenDates"
              :identify-value="identifyValue"
              v-for="(dataValue, key) in mainData.data_values"
              :key="key"
              class-value="col-sm-2 text-right containerLevelFour containerDate"
              :data-value="dataValue"
            ></date-value>

            <div
              class="col-sm-1 text-center"
              style="padding-right: 0; padding-left: 0"
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DateValue from "./DateValue.vue";

export default {
  props: [
    "typeLevel",
    "dataRows",
    "cashFlowHiddenDates",
    "spanDescription",
    "identifyValue",
    "showTotalizer",
    "showDefault",
  ],
  components: { DateValue },
  data() {
    return {
      showSection: true,
      description: "",
      mainData: this.dataRows,
    };
  },
  mounted() {
      if (!!this.showDefault) {
        this.toggle();
      }
  },
  watch: {
    dataRows: function (newVal, oldVal) {
      var self = this;
      self.mainData = newVal;
    },
  },
  methods: {
    sum(data) {
      return data.reduce((a, b) => {
        return a + parseFloat(b.value);
      }, 0);
    },
    toggle() {
      this.showSection = !this.showSection;
    },
  },
};
</script>
