import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"position":"fixed","z-index":"1050"},attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Concluir evento "),_c(VChip,{staticClass:"ml-2",attrs:{"small":"","dark":"","color":_vm.eventAction[this.status].color}},[_vm._v(" "+_vm._s(_vm.eventAction[this.status].text)+" ")])],1),_c(VCardText,[_c(VForm,{ref:"ConcludeEventForm",attrs:{"lazy-validation":""}},[_c('p',{staticClass:"mb-5"},[_vm._v(" Para concluir, descreva mais detalhes sobre a interação realizada no campo abaixo. ")]),_c(VTextarea,{staticClass:"mt-5",attrs:{"placeholder":"Descreva detalhes sobre a interação","label":"Descrição","rules":[_vm.inputRules.required()]},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.handleClose('close')}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"type":"submit","color":"blue darken-1","text":""},on:{"click":function($event){return _vm.handleSave()}}},[_vm._v(" SALVAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }