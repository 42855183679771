










































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';

import AccountType from '@/domain/enums/AccountTypeEnum';
import BillingRemittanceActionType from '@/domain/enums/BillingRemittanceActionType';
import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';

import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';

import { formatErrorForNotification } from '../utils';

@Component({
})
export default class BillingRemittanceClearBankSlip extends Vue {
  @Prop(Boolean) open!: boolean;

  @Prop({
    type: Object as () => BillingRemittanceAccountReceivable,
  }) item!: BillingRemittanceAccountReceivable;

  @Emit()
  close() {
    return BillingRemittanceActionType.CLEAR_BANK_SLIP_DATA;
  }

  @Emit()
  reload() {
    this.close();
  }

  readonly billingRemittanceRepository = new BillingRemittanceRepository();

  loading: boolean = false;

  async action(): Promise<void> {
    try {
      this.loading = true;
      this.$dialog.startLoading();
      await this.billingRemittanceRepository.clearBankSlipData(
        this.$session.get('company_group_id'),
        this.item.cnab_remi_generated_id,
        this.item.idCustomer,
        AccountType.RECEIVABLE,
      );
      this.$notification.success('Dados do boleto limpo.');
      this.loading = false;
      this.$dialog.stopLoading();
      this.reload();
    } catch (error: any) {
      this.loading = false;
      this.$dialog.stopLoading();
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    }
  }
}
