




































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import CompanyRepository from '@/repositories/CompanyRepository';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import FilterParameterInvoiceList from '@/domain/models/filter-parameters/FilterParameterInvoiceList';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import { VForm } from '@/types/VForm';
import IListInvoiceFilters from '@/views/invoices/interfaces/IListInvoiceFilters';

export default defineComponent({
  props: {
    isLoading: Boolean,
  },
  data() {
    const startEmissionDate: string = '';
    const finalEmissionDate: string = '';
    const startDueDate: string = '';
    const finalDueDate: string = '';
    const invoiceValue: string = '';
    const invoiceLinkNumber: string = '';

    const loading: boolean = false;

    const invoiceLinkNumbers: string[] = [];

    const selectedCompanies: number[] = [];
    const companies: ISelectOptions<number>[] = [];

    const companyRepository: CompanyRepository = new CompanyRepository();
    const filterParametersRepository: FilterParametersRepository = new FilterParametersRepository();

    return {
      startEmissionDate,
      finalEmissionDate,
      startDueDate,
      finalDueDate,
      invoiceValue,
      invoiceLinkNumber,
      loading,
      invoiceLinkNumbers,
      companies,
      selectedCompanies,
      companyRepository,
      filterParametersRepository,
    };
  },
  computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id,
    }),
  },
  async created() {
    Promise.all([
      this.loadCompanies(),
      this.getGroupFilterParameters(),
    ]);
  },
  methods: {
    async loadCompanies(): Promise<void> {
      const companies = await this.companyRepository.getCompaniesByLoggedUser(
        this.companyGroupId,
      );

      this.companies = companies.map(({ name, id }) => ({
        text: name,
        value: id,
      }));
    },
    async getGroupFilterParameters(): Promise<void> {
      this.loading = true;

      try {
        const filterParameters = await this.filterParametersRepository
          .getFilterByGroup(GroupFilterParametersEnum.INVOICES_LIST);
        const formattedFilters = FilterParameterInvoiceList.make(filterParameters);

        this.applyFiltersOnActualPage(formattedFilters);
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
      } finally {
        this.loading = false;
      }
    },
    applyFiltersOnActualPage(filters: FilterParameterInvoiceList) {
      this.startEmissionDate = filters.startEmissionDate;
      this.finalEmissionDate = filters.finalEmissionDate;
      this.startDueDate = filters.startDueDate;
      this.finalDueDate = filters.finalDueDate;
      this.selectedCompanies = filters.companyIds;
    },
    validate(): IListInvoiceFilters|false {
      const invoiceForm = this.$refs.InvoiceFilterForm as VForm;

      if (!invoiceForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');

        return false;
      }

      let selectedCompanies: number[] = [];

      if (this.selectedCompanies.length > 0) {
        selectedCompanies = this.selectedCompanies;
      } else {
        selectedCompanies = this.companies.map(({ value }) => value);
      }

      return {
        startEmissionDate: this.startEmissionDate,
        finalEmissionDate: this.finalEmissionDate,
        startDueDate: this.startDueDate,
        finalDueDate: this.finalDueDate,
        invoiceValue: this.invoiceValue,
        invoiceLinkNumber: this.invoiceLinkNumber,
        selectedCompanies,
      };
    },
  },
});
