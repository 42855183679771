import { stringify } from 'qs';
import Repository from '@/repositories/base/Repository';
import IMainGroupersListRequestFilters from '@/views/cashFlowGroupers/mainGrouper/interfaces/IMainGroupersListRequestFilters';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import MainGrouperList from '@/domain/models/groupers/MainGrouperList';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';
import MainGrouper from '@/domain/models/groupers/MainGrouper';
import IMainGrouperUpsertRequestData from '@/views/cashFlowGroupers/mainGrouper/interfaces/IMainGrouperUpsertRequestData';

export default class CashFlowGrouperRepository extends Repository {
  public async getMainGroupersTableList(
    companyGroupId: number,
    companyIds: number[],
    filters: ILaravelOrdenationTableParams<IMainGroupersListRequestFilters>,
  ): Promise<IDataTableWithLength<MainGrouperList[]>> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/cashflow-groupers/main/table-list`,
      {
        params: filters,
        paramsSerializer: stringify,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return {
      data: response.data.map(MainGrouperList.make),
      total: response.total,
    };
  }

  public async getMainGroupersByCompanyGroupId(companyGroupId: number): Promise<MainGrouper[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/cashflow-groupers/main`);

    return response.data.map(MainGrouperList.make);
  }

  public async getMainGrouperById(
    companyGroupId: number,
    id: number,
  ): Promise<MainGrouper> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/cashflow-groupers/main/${id}`);

    return MainGrouper.make(response.data);
  }

  public async createMainGrouperList(
    companyGroupId: number,
    params: IMainGrouperUpsertRequestData,
  ): Promise<void> {
    await this.datasource.send(
      `company-groups/${companyGroupId}/cashflow-groupers/main`,
      params,
    );
  }

  public async updateMainGrouperList(
    companyGroupId: number,
    mainGrouperId: number,
    params: IMainGrouperUpsertRequestData,
  ): Promise<void> {
    await this.datasource.update(
      `company-groups/${companyGroupId}/cashflow-groupers/main/${mainGrouperId}`,
      params,
    );
  }

  public async deleteMainGrouper(
    companyGroupId: number,
    mainGrouperId: number,
  ): Promise<void> {
    await this.datasource.destroy(`company-groups/${companyGroupId}/cashflow-groupers/main/${mainGrouperId}`);
  }
}
