export default class PaymentCondition {
  constructor(
    public code: string,
    public description: string,
  ) {}

  static make(data : any) : PaymentCondition {
    return new PaymentCondition(
      data.code,
      data.description,
    );
  }
}
