


















































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import { getModule } from 'vuex-module-decorators';

import AuthenticationModule from '@/stores/modules/AuthenticationModule';

import OperatorDistributionRepository from '@/repositories/OperatorDistributionRepository';
import VDataTableHeader from '@/types/IVDataTableHeader';
import StatusType from '@/domain/enums/StatusType';
import OperatorDistributionListModel from '@/domain/models/OperatorDistributionList';

import StatusChip from '@/components/tables/StatusChip.vue';
import ActionButton from '@/components/tables/ActionButton.vue';
import DeleteDialog from './components/OperatorDistributionDeleteDialog.vue';

@Component({
  components: {
    StatusChip,
    ActionButton,
    DeleteDialog,
  },
})
export default class OperatorDistributionList extends Vue {
  public readonly operatorDistributionRepository
    : OperatorDistributionRepository = new OperatorDistributionRepository();
  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);

  public loading: boolean = false;
  public search: string = '';

  public openDelete: boolean = false;
  public operatorDelete: OperatorDistributionListModel|null = null;
  public operators: OperatorDistributionListModel[] = [];

  public headers: Array<VDataTableHeader> = [
    {
      text: 'Status',
      value: 'status',
      sortable: true,
    },
    {
      text: 'Operador',
      value: 'operatorName',
      sortable: true,
    },
    {
      text: '',
      value: 'id',
      sortable: false,
      align: 'end',
    },
  ];

  public status = [
    {
      value: StatusType.ACTIVE,
      label: 'Ativo',
      color: 'success',
    },
    {
      value: StatusType.INACTIVE,
      label: 'Inativo',
      color: 'error',
    },
  ];

  public get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public mounted(): void {
    this.loadOperators();
  }

  public handleSearch(search: string): void {
    this.search = search;
    this.loadOperators();
  }

  public handleCreate(): void {
    this.$router.push({ name: 'CreateOperatorDistribution' });
  }

  public handleEdit(id: string): void {
    this.$router.push({ name: 'EditOperatorDistribution', params: { id } });
  }

  public handleDelete(operator: OperatorDistributionListModel): void {
    this.operatorDelete = operator;
    this.openDelete = true;
  }

  public handleClose(reload: boolean): void {
    if (reload) {
      this.loadOperators();
    }
  }

  public async loadOperators(): Promise<void> {
    try {
      this.loading = true;

      this.operators = await this.operatorDistributionRepository.getOperatorDistributions(
        this.companyGroupId,
        this.companyIds,
        this.search || undefined,
      );

      if (!this.operators.length) {
        this.$notification.warn('Nenhum operador encontrado!');
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
