<template>
  <v-btn
    class="custom-button"
    :class="customClasses()"
    :color="customOrDefaultColor()"
    @click="onClick()"
    x-large
    depressed
    :disabled="disabled"
    :loading="loading"
  >
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: 'CustomButton',
  components: {},
  props: {
    buttonClass: String,
    color: String,
    label: String,
    click: Function,
    right: Boolean,
    disabled: Boolean,
    loading: Boolean,
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    customOrDefaultColor() {
      return this.color || 'success';
    },
    customClasses() {
      return `${this.buttonClass || ''} ${this.right ? 'float-right' : ''}`;
    },
  },
};
</script>

<style>
.custom-button.v-btn {
  padding: 0 !important;
  height: auto !important;
  min-width: 20px;
  max-height: 44px;
}
.custom-button.v-btn span {
  padding: 0.65rem 1.25rem;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: normal;
  text-indent: 0px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
