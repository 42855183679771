export default class FilterParameterPayrollSelectList {
  public constructor(
    public initialIssueDate: string,
    public endIssueDate: string,
    public initialDueDate: string,
    public endDueDate: string,
    public companies: Array<number>,
    public suppliers: Array<string>,
    public number: string,
    public value: number | null,
    public page?: number,
    public itemsPerPage?: number,
    public sortBy?: string,
    public sortDesc?: boolean,
  ) {}

  public static make(data: any): FilterParameterPayrollSelectList {
    const relationship = 'payroll_select_list';

    return new FilterParameterPayrollSelectList(
      data[`initial_issue_date_${relationship}`] ?? '',
      data[`end_issue_date_${relationship}`] ?? '',
      data[`initial_due_date_${relationship}`] ?? '',
      data[`end_due_date_${relationship}`] ?? '',
      data[`companies_${relationship}`] ? JSON.parse(data[`companies_${relationship}`]) : [],
      data[`suppliers_${relationship}`] ? JSON.parse(data[`suppliers_${relationship}`]) : [],
      data[`number_${relationship}`] || null,
      data[`value_${relationship}`] || null,
      data[`page_${relationship}`] || 1,
      data[`items_per_page_${relationship}`] || 100,
      data[`sort_by_${relationship}`] || undefined,
      data[`sort_desc_${relationship}`] || undefined,
    );
  }
}
