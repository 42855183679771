import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":_vm.dialogWidth,"scrollable":"","persistent":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v(" Excluir as Pré-Negociações selecionadas ")]),_c('div',[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close(false)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]),_c(VCardText,[_c(VRow,[_c(VCol,[_vm._v(" Deseja excluir as Pré-Negociações selecionadas? ")])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"button-width",attrs:{"large":"","disabled":_vm.loading},on:{"click":function($event){return _vm.close(false)}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{staticClass:"button-width",attrs:{"color":"error","large":"","loading":_vm.loading},on:{"click":_vm.removePreNegotiations}},[_vm._v(" EXCLUIR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }