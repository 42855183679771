<template>
  <div v-if="showScreen" class="mx-4">
    <v-card flat>
      <v-container class="m-portlet px-0" fluid>
        <v-row class="px-8 py-5">
          <v-col xs="12">
            <h3 class="header-title">
              {{
                type === "payment" ? "Gerar fatura" : "Baixar Título a Receber"
              }}
            </h3>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="px-8 pt-5">
          <v-col xs="12">
            <search-income-record-filter
              :companyId="companyId"
              :record="record"
              :operationType="type"
              :setItems="setItems"
            />
          </v-col>
        </v-row>

        <v-row class="px-8 pt-1">
          <v-col xs="12" class="pt-0 pb-6">
            <search-income-record-list-table
              :companyId="companyId"
              :items="items"
              :total="recordTotal"
              :type="type"
              :record="record"
              :selectedItems="selectedItems"
              :writeOffReason="writeOffReason"
              @input="setValues($event)"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="px-8 py-5">
          <v-col xs="12" class="d-flex justify-end">
            <!-- disabled="(type !== 'payment' && isDisabled) || (type === 'payment' && calculatedDifference !== 0)" -->
            <custom-button
              buttonClass="mr-2"
              @click="writeOff"
              :label="type === 'payment' ? 'Salvar' : 'Baixar'"
              :type="type"
              :disabled="type !== 'payment' && isDisabled"
            />
            <custom-button color="primary" @click="close()" label="Voltar" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <provider
      v-if="showProviderSelect"
      :showProviderSelect="showProviderSelect"
      :item="paymentMethodsSelected"
      :company="companyId"
      @save="generateInvoice"
      @close="showProviderSelect = false"
    />
  </div>
</template>

<script>
import CustomButton from "../../../../src/components/buttons/CustomButton.vue";
import ParamnsListStructure from "../../../../src/components/defaults/ParamnsListStructure.vue";
import SearchIncomeRecordFilter from "./Filter.vue";
import SearchIncomeRecordListTable from "./ListTable.vue";
import Provider from "../../remittancepayable/Provider.vue";
import ArtificialInvoices from "@/services/artificial-invoices.service";

export default {
  components: {
    ParamnsListStructure,
    CustomButton,
    SearchIncomeRecordFilter,
    SearchIncomeRecordListTable,
    Provider,
  },
  name: "SearchIncomeRecord",
  props: {
    record: Object,
    type: { type: String, default: "" },
  },
  data() {
    return {
      items: [],
      companyId: $("#company_id").val(),
      selectedItems: [],
      writeOffReason: "",
      acquittanceType: "",
      selectedItem: null,
      scrollTo: 0,
      dueDate: null,
      showProviderSelect: false,
      paymentMethodsSelected: null,
      showScreen: false,
    };
  },
  mounted() {
    this.$emitter.on("income_record_search", this.clearInputs);

    this.showScreen = true;
    this.dueDate = moment(this.record.values[0].data_vencto).format(
      "DD/MM/YYYY"
    );
  },
  methods: {
    setItems(newData) {
      this.items = newData;
    },
    setValues(data) {
      if (typeof this[data.key] != "undefined") {
        this[data.key] = data.value;
      }
    },
    writeOff() {
      if (this.type === "payment") {
        this.prepareDataForInvoice();
        return;
      }

      const bank = JSON.parse($("#bank").val());
      const dataMovimento = this.record.values[0].data_movimento;
      let recordsId = [];
      let aggroupRecords = false;

      this.record.values.forEach((item) => {
        recordsId.push(item.id);
      });

      if (this.record.values.length > 1) {
        aggroupRecords = true;
      }

      const data = {
        conciliationRecordsId: recordsId,
        aggroupRecords: aggroupRecords,
        conciliationRecordValue: this.recordTotal,
        companyId: this.companyId,
        companyGroupId: this.$session.get("company_group_id"),
        bank_data: {
          BANK: bank.code,
          AGENCY: bank.agency,
          ACCOUNT: bank.account,
        },
        additional_information: {
          HISTORIC: this.writeOffReason,
          ACQUITTANCEREASON: this.acquittanceType,
          DATE: dataMovimento,
        },
        keys: this.selectedItems.map((item) => ({
          TABLE_COMPANY_CODE: item.table_company_code,
          BRANCH: item.e1_filial,
          DOCUMENTNUMBER: item.e1_num,
          PARCEL: item.e1_parcela,
          PREFIX: item.e1_prefixo,
          TYPE: item.e1_tipo,
          CLIENTCODE: item.e1_cliente,
          STORE: item.e1_loja,
          VALUE: item.e1_valor,
          TAX: item.e1_txmoeda,
          ASSESSMENT: numeral(item.e1_acresc)["_value"],
          DISCOUNT: numeral(item.e1_decresc)["_value"],
          RECEIVEDVALUE: numeral(item.received_value)["_value"],
        })),
      };

      showNotification(
        "AVISO",
        "Processo de conciliação iniciado, você será notificado em breve",
        "info"
      );
      this.$emit("conciliation:search", data);
    },
    close() {
      this.$emit("close");
    },
    clearInputs() {
      this.writeOffReason = "";
      this.acquittanceType = "";
    },

    prepareDataForInvoice() {
      const dueDate = moment(this.dueDate, 'DD-MM-YYYY').format("YYYY-MM-DD") ?? null
      
      const data = {
        company: this.companyId,
        dueDate: dueDate,
        titles: [],
      };

      this.selectedItems.forEach((title, index) => {
        data.titles.push({
          table_company_code: title.table_company_code,
          E2_FILIAL: title.e1_filial,
          A2_CGC: title.a1_cgc,
          E2_EMISSAO: moment().format("YYYYMMDD"),
          E2_VENCREA: this.record.values[0].data_vencto, // title.e1_vencrea,
          E2_PARCELA: title.e1_parcela,
          TABLE_COMPANY_CODE: title.table_company_code,
          E2_ZDTFLUX: "",
          E2_FORNECE: title.e1_cliente,
          E2_NUM: title.e1_num,
          E2_PORTADO: title.e1_portado,
          E2_PREFIXO: title.e1_prefixo,
          E2_FORNECE: title.e1_cliente,
          E2_FILIAL: title.e1_filial,
          E2_TIPO: title.e1_tipo,
          id_customer: title.id_customer,
          E2_LOJA: title.e1_loja,
          E2_ZFORPG: title.e1_zforpg,
          E2_VALOR: title.e1_valor,
          E2_MOEDA: title.e1_moeda,
          E2_CODBAR: this.record.values[0].cod_barra,
          ASSESSMENT: numeral(title.e1_acresc)["_value"],
          DISCOUNT: numeral(title.e1_decresc)["_value"],
          TOTAL: numeral(title.received_value)["_value"],
          dda_record_id: this.record.values[0].id,
          dda_record: this.record.values[0],
        });
      });

      if (!this.isSameProvider(data)) {
        this.selectProvider([data]);

        return;
      }

      this.generateInvoice([data]);
    },

    selectProvider(payments) {
      this.scrollTo = window.scrollY;
      this.showProviderSelect = true;
      this.paymentMethodsSelected = payments;
      this.companyId = $("#company_id").val();
    },

    isSameProvider(data) {
      return data.titles.every((title, index) => {
        if (data.titles[index + 1]) {
          return title.A2_CGC === data.titles[index + 1].A2_CGC;
        }
        return true;
      });
    },
    async generateInvoice(paymentsCategories) {
      try {
        blockPage("Gerando fatura...");
        const artificialInvoicesService = new ArtificialInvoices();
        await artificialInvoicesService.store(paymentsCategories);
        this.close();
      } catch (error) {
        showNotification(
          "Atenção",
          "Oops! Ocorreu um erro ao gerar a fatura.",
          "danger"
        );
      } finally {
        unblockPage();
      }
    },
  },
  computed: {
    recordTotal() {
      const initial = this.record.values.reduce(
        (acc, rc) => acc + Number(rc.valor),
        0
      );
      const movements = this.record.conciliation_record_movimento_bancario;
      return movements.reduce((acc, m) => acc - Number(m.e5_valor), initial);
    },

    isPayment() {
      return this.type !== "payment" || this.selectedItems.length <= 1;
    },
    isDisabled() {
      const selectedTotal = this.selectedItems.reduce((prev, item) => {
        if (typeof item.received_value === "object") {
          return prev + numeral(item.received_value).value();
        }
        return prev + parseFloat(item.received_value);
      }, 0);

      let result =
        Number.parseFloat(this.recordTotal) - Number.parseFloat(selectedTotal);
      return (
        !this.selectedItems ||
        !this.acquittanceType ||
        this.acquittanceType == "" ||
        this.selectedItems.length == 0 ||
        Number.parseFloat(result.toFixed(2)) < 0
      );
    },
    calculatedDifference() {
      let selectedValue = 0;

      this.selectedItems.forEach((value) => {
        selectedValue += Number.parseFloat(value.e1_saldo);
      });

      let result =
        Number.parseFloat(this.recordTotal) - Number.parseFloat(selectedValue);

      return Number.parseFloat(result.toFixed(2));
    },
  },
};
</script>

<style scoped></style>