import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"pageTitle":"Tipos de Usuário"}}),_c('page-card',{attrs:{"dividerOn":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,{attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12","xs":"12"}},[_c('inn-text-field-debounce',{attrs:{"label":"Digite algum valor para a pesquisa","icon":"mdi-magnify","hide-details":"","dense":""},on:{"on-finished-debounce":_vm.handleSearch},model:{value:(_vm.filterTerm),callback:function ($$v) {_vm.filterTerm=$$v},expression:"filterTerm"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12","align-self":"center"}},[_c(VBtn,{attrs:{"large":"","block":"","dark":"","color":"primary"},on:{"click":_vm.handleNewGroup}},[_vm._v(" ADICIONAR ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c(VDataTable,{staticClass:"data-table data-table--with-edit",attrs:{"headers":_vm.headers,"items":_vm.userGroups,"loading":_vm.loading,"server-items-length":_vm.totalItems,"options":_vm.dataTableOptions,"footer-props":_vm.footerOptions},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","buttonIcon":"mdi-pencil","tooltipText":"Editar"},on:{"click":function($event){return _vm.handleEdit(item.id)}}})]}}],null,true)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }