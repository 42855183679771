import Repository from '@/repositories/base/Repository';

export default class RemittanceRepository extends Repository {
    public async excludeFromRemittance(
        companyGroupId: number,
        cnabRemiGeneratedId: number,
        idCustomer: string,
        type: string,
    ) {
        const { status, data: response } = await this.datasource
            .destroy(`company-groups/${companyGroupId}/remittances/${cnabRemiGeneratedId}/${type}/${idCustomer}`);

        if (!this.isStatusOK(status)) {
            throw new Error('Aconteceu um erro inesperado');
        }

        return response.data;
    }
}
