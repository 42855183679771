<template>
	<page-container>
		<v-row>
			<v-col>
				<breadcrumbs page-title="Configurações de API bancária"/>
				<page-card dividerOn>
					<template #title>
					<v-row>
							<v-spacer/>
							<v-col cols="12" lg="2" md="3" sm="12" xs="12">
								<v-btn
									@click="add"
									class="font-weight-bold"
									x-large
									block
									depressed
									color="primary"
								>
									ADICIONAR
								</v-btn>
							</v-col>
						</v-row>
					</template>
					<template #body>
						<list-table :items="items"/>
					</template>
				</page-card>
			</v-col>
		</v-row>
	</page-container>
</template>

<script>

// eslint-disable-next-line
import ApiBankingService from '@/services/apibanking.service';

import ListTable from './ListTable.vue';

export default {
	components: {
		ListTable,
	},
	name: 'ApiBankinConfigList',
	data() {
		return {
			items: [
			],
			loading: false,
		};
	},
	created() {
		this.loadApiBankingConfigs();
	},
	methods: {
		async loadApiBankingConfigs() {
			try {
				this.loading = true;

				const apiBankingService = new ApiBankingService();

				const result = await apiBankingService.configurated().index();

				this.items = result.data;
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
		add() {
			this.$router.push({ name: 'APIConfigurationsSave', params: { service: 'new' } });
		},
	},
};
</script>

<style>

</style>
