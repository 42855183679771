<template>
  <router-view/>
</template>

<script>
export default {
	name: 'NewInncashLayout',
};
</script>

<style scoped>
.main {
  background-color: #f0f0f7;
}
</style>
