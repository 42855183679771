

























































import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import UserGroup from '@/domain/models/UserGroup';
import Company from '@/domain/models/Company';
import User from '@/domain/models/User';
import UserRepository from '@/repositories/UserRepository';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import CompanyRepository from '@/repositories/CompanyRepository';
import CompanyGroupRepository from '@/repositories/CompanyGroupRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import CompanyGroup from '@/domain/models/CompanyGroup';
import IUserEditParams from '@/repositories/parameters/IUserEditParams';
import IUserCompaniesParams from '@/repositories/parameters/IUserCompaniesParams';
import PageAction from '@/types/PageAction';
import ICompanyActivePair from './types/ICompanyActivePair';
import RegisterTab from './components/RegisterTab.vue';
import CompaniesTab from './components/CompaniesTab.vue';

@Component({
  components: {
    RegisterTab,
    CompaniesTab,
  },
})
export default class UserEdit extends Vue {
  userParameters: IUserEditParams = {
    user_id: '',
    name: '',
    email: '',
    user_group_id: '',
    password: '',
    erp_code: '',
    url_powerbi: '',
    company_group_id: '',
    status: false,
    force_change_password: false,
  };
  companiesParameters: IUserCompaniesParams[] = [];
  userGroups: UserGroup[] = [];
  companyActivePairs: ICompanyActivePair[] = [];
  companyGroups: CompanyGroup[] = [];
  authStore?: AuthenticationModule;
  readonly currentPageAction: PageAction = PageAction.EDIT;
  readonly userRepository: UserRepository = new UserRepository();
  readonly userGroupRepository: UserGroupRepository = new UserGroupRepository();
  readonly companyRepository: CompanyRepository = new CompanyRepository();
  readonly companyGroupRepository: CompanyGroupRepository = new CompanyGroupRepository();

  get userId(): number {
    return Number(this.$route.params.id);
  }

  get isOwner(): boolean {
    return this.authStore!.isOwner ?? false;
  }

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);
  }

  mounted() {
    const getUserInfo = this.userRepository.listOne(this.userId);
    const getUserGroups = this.userGroupRepository.getAll();
    const getCompanies = this.companyRepository.getAllActivie();
    const getCompanyGroups: Promise<CompanyGroup[]> = this.isOwner
      ? this.companyGroupRepository.getAll()
      : new Promise((res) => res([]));

    this.$dialog.startLoading();
    Promise.all([getUserInfo, getUserGroups, getCompanies, getCompanyGroups])
      .then(([user, userGroups, companies, companyGroups]) => {
        this.$dialog.stopLoading();

        const { data: userGroupsData } = userGroups;
        this.userGroups = userGroupsData;

        this.assignUserData(user);
        this.assignCompanyPairs(user, companies);
        this.companyGroups = companyGroups;
      })
      .catch((error) => {
        this.$dialog.stopLoading();
        this.$notification.error(error);
        this.$router.push({ name: 'UserList' });
      });
  }

  assignUserData(user: User) {
    this.userParameters.user_id = user.id.toString();
    this.userParameters.name = user.name;
    this.userParameters.email = user.email;
    this.userParameters.user_group_id = user.userGroupId.toString();
    this.userParameters.erp_code = user.erpCode;
    this.userParameters.url_powerbi = user.powerBIUrl;
    this.userParameters.company_group_id = user.companyGroupId.toString();
    this.userParameters.status = user.status;
    this.userParameters.force_change_password = user.forceChangePassword;
  }

  assignCompanyPairs(user: User, companies: Company[]) {
    this.companyActivePairs = companies.map((company) => ({
      company,
      active: user.companyIds.includes(company.id),
    }));
  }
  handleCancel() {
    this.$router.push({ name: 'UserList' });
  }

  handleUpdate() {
    const companiesParameters: IUserCompaniesParams[] = this.companyActivePairs
      .filter((pair) => pair.active)
      .map((pair) => ({ company_id: pair.company.id.toString() }));

    this.$dialog.startLoading();
    this.userRepository
      .update(this.userParameters, companiesParameters)
      .then(() => {
        this.$dialog.stopLoading();
        this.$notification.success('Usuário atualizado com sucesso!');
        this.$router.push({ name: 'UserList' });
      })
      .catch((error) => {
        this.$dialog.stopLoading();
        this.$notification.error(error);
      });
  }
}
