class TableUtils {
  public tableOrdenationFormatterToLaravel(
    direction: boolean | undefined,
    column: string | undefined,
  ): string {
    const isDescDirection = direction ?? false;
    const definedColumn = column ?? '';

    return `${isDescDirection ? '-' : ''}${definedColumn}`;
  }
}

export default new TableUtils();
