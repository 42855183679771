


























































import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import LooseObjectType from '@/types/LooseObjectType';

@Component
export default class DynamicColumnMenu extends Mixins<ModelManagementMixin<IVDataTableHeader[]>>(
  ModelManagementMixin,
) {
  public filterParametersRepository: FilterParametersRepository;

  public constructor() {
    super();

    this.filterParametersRepository = new FilterParametersRepository();
  }

  @Prop(Object) readonly correlationBetweenKeysAndHeaders !: LooseObjectType<string>;

  @Prop(String) readonly groupEnum !: keyof typeof GroupFilterParametersEnum;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  }) readonly small?: boolean;

  public selecteds: Array<Pick<IVDataTableHeader, 'text' | 'value' | 'show'>> = [];
  public columns: Array<IVDataTableHeader> = [];
  public loading: boolean = false;

  public get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  public get getInfoCardColor(): string {
    if (this.$vuetify.theme.dark) {
      return '#565656';
    }

    return '#F2F3F7';
  }

  public async persistTableState(data: Required<Pick<IVDataTableHeader, 'text' | 'value' | 'show'>>): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.filterParametersRepository.setFilter(GroupFilterParametersEnum[this.groupEnum], [
        { key: this.correlationBetweenKeysAndHeaders[data.value], value: data.show },
      ]);
    } catch (error) {
      this.$notification.error('Houve um problema ao salvar a configuração de exibição de colunas!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public changeSelecteds(item: Pick<IVDataTableHeader, 'text' | 'value' | 'show'>) {
    const index = this.selecteds.findIndex((value) => value.value === item.value);

    if (index >= 0) {
      this.selecteds.splice(index, 1);
      return;
    }

    this.selecteds.push(item);
  }

  public async persistSelecteds(): Promise<void> {
    if (this.loading || !this.selecteds.length) return;

    try {
      this.$dialog.startLoading();
      this.loading = true;

      const state: Array<{ key: string, value: unknown }> = this.selecteds
        .map((selected) => ({
          key: this.correlationBetweenKeysAndHeaders[selected.value],
          value: selected.show,
        }));

      await this.filterParametersRepository
        .setFilter(GroupFilterParametersEnum[this.groupEnum], state);

      this.model = this.columns;
    } catch (error) {
      this.$notification.error('Houve um problema ao salvar a configuração de exibição de colunas!');
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  public handleMenu(open: boolean) {
    if (open) {
      this.selecteds = [];
      this.columns = JSON.parse(JSON.stringify(this.model));
    } else {
      this.persistSelecteds();
    }
  }
}
