















































































































import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import StatusType from '@/domain/enums/StatusType';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import BillingMethod from '@/domain/models/BillingMethod';
import BillingMethodType from '@/domain/enums/BillingMethodType';
import BillingMethodRepository from '@/repositories/BillingMethodRepository';

@Component({})
export default class BillingMethodSave extends Vue {
  @Ref('form') readonly form!: VForm;

  public loading: boolean = false;
  public formValid: boolean = false;
  public companyGroupLabel: string = this.$session.get('company_group_name');
  readonly billingMethodRepository: BillingMethodRepository = new BillingMethodRepository();

  public selectMethodType: SelectOptions[] = [
    { text: 'Outros', value: BillingMethodType.OTHERS },
    { text: 'Pix', value: BillingMethodType.PIX },
  ];

  public selectStatus: SelectOptions[] = [
    { text: 'Ativo', value: StatusType.ACTIVE },
    { text: 'Inativo', value: StatusType.INACTIVE },
  ];

  public selectCompany: SelectOptions[] = [
    { text: 'Todas', value: 0 },
  ];

  public data: BillingMethod = {
    id: 0,
    code: '',
    description: '',
    method_type: BillingMethodType.OTHERS,
    status: StatusType.ACTIVE,
    company_group_id: 0,
    company_id: 0,
  };

  public rules = {
    fieldRequired: (v: string | number) => !!v || 'Campo obrigatório!',
    lengthCod: (v: string) => (v && v.length === 3) || 'O código deve contar 3 números.',
    lengthDescription: (v: string) => ((v && v.length <= 200)) || 'Descrição muito longa.',
  };

  mounted() {
    if (this.isNewBillingMethod()) {
      this.getCompanyGroupId();
    } else {
      this.loadBillingMethodData();
    }
  }

  isNewBillingMethod(): boolean {
    return this.$route.params.id === undefined;
  }

  getPageTitle(): string {
    return this.isNewBillingMethod()
      ? 'Nova Forma de Recebimento'
      : 'Editar Forma de Recebimento';
  }

  async getAllCompanyGroup() {
    const allCompanyGroup = await this.billingMethodRepository.getAllCompanyGroup();

    const moreSelectCompany = allCompanyGroup
      .map((res: any) => ({ text: res.name, value: res.id }));

    this.selectCompany = this.selectCompany.concat(moreSelectCompany);
  }

  async getCompanyGroupId() {
    this.$dialog.startLoading();

    const companyGroupId = this.$session.get('company_group_id');
    this.data.company_group_id = companyGroupId;

    await this.getAllCompanyGroup();

    this.$dialog.stopLoading();
  }

  async loadBillingMethodData() {
    try {
      this.$dialog.startLoading();

      const { id } = this.$route.params;
      const result = await this.billingMethodRepository.getOne(parseInt(id, 10));
      this.data = result;

      await this.getAllCompanyGroup();
    } catch (error) {
      this.$notification.error(error);
      this.handlerCancel();
    } finally {
      this.$dialog.stopLoading();
      this.$forceUpdate();
    }
  }

  handlerCancel(): void {
    this.$router.go(-1);
  }

  handlerSave(): void {
    this.form.validate();

    if (this.formValid) {
      const data = {
        ...this.data,
      };

      if (this.isNewBillingMethod()) {
        this.createBillingMethod(data);
      } else {
        this.updateBillingMethod(data);
      }
    }
  }

  async createBillingMethod(data: BillingMethod) {
    try {
      this.$dialog.startLoading();
      this.loading = true;

      const success = await this.billingMethodRepository.save(data);

      if (success) {
        this.$notification.success('Forma de cobrança salva!');
        this.$router.replace({ name: 'BillingMethodList' });
      }
    } catch (error) {
      this.$notification.error(error);
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  async updateBillingMethod(data: BillingMethod) {
    try {
      this.$dialog.startLoading();
      this.loading = true;

      const success = await this.billingMethodRepository.update(data);

      if (success) {
        this.$notification.success('Alteração salva!');
        this.$router.replace({ name: 'BillingMethodList' });
      }
    } catch (error) {
      this.$notification.error(error);
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }
}
