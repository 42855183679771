export default class Company {
  constructor(
    public id : number,
    public name : string,
    public fantasyName : string,
    public initials : string,
    public cnpj : string,
    public status: boolean,
    public companyGroupId : number,
    public erpId : number,
    public erpCompanyCode : string,
  ) { }

  static make(data: any): Company {
    return new Company(
      data.id,
      data.name,
      data.fantasy_name,
      data.initials,
      data.cnpj,
      data.status,
      data.company_group_id,
      data.erp_id,
      data.erp_company_code,
    );
  }
}
