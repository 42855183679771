export default class MaskHelper {
  public static phoneMask(phoneNumber: string|null): string|null {
    if (!phoneNumber) return null;

    const { length } = phoneNumber
      .replaceAll(' ', '');

    return length <= 13
      ? '(##) ####-####?'
      : '(##) # ####-####';
  }
}
