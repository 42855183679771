export default class EconomicGroupCompany {
  constructor(
    public id: number,
    public fantasyName: string,
  ) {}

  static make(data: any): EconomicGroupCompany {
    return new EconomicGroupCompany(
      data.id,
      data.fantasy_name,
    );
  }
}
