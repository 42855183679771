export default class BankConfigSendType {
  constructor(
    public account_digit: string,
    public account_number: string,
    public agency_code: string,
    public agency_digit: string,
    public bank_code: string,
    public bank_config_id: number,
    public branch_code: string,
    public client_bank_id: string,
    public cnab_id: number,
    public created_at: string,
    public description: string,
    public id: number,
    public send_type: number,
    public send_type_id: number,
    public table_company_code: string,
    public transaction_type: string,
    public updated_at: string,
  ) { }

  static make(data: any): BankConfigSendType {
    return new BankConfigSendType(
      data.account_digit,
      data.account_number,
      data.agency_code,
      data.agency_digit,
      data.bank_code,
      data.bank_config_id,
      data.branch_code,
      data.client_bank_id,
      data.cnab_id,
      data.created_at,
      data.description,
      data.id,
      data.send_type,
      data.send_type_id,
      data.table_company_code,
      data.transaction_type,
      data.updated_at,
    );
  }
}
