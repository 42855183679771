import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider,{staticClass:"mt-5 mb-6"}),_vm._l((_vm.list),function(listItem,index){return _c(VDataTable,{directives:[{name:"sticky",rawName:"v-sticky",value:(_vm.list[index].items),expression:"list[index].items"}],key:listItem.description + index,staticClass:"remittance-table pb-10",attrs:{"items":_vm.list[index].items,"headers":_vm.headers,"hide-default-header":"","hide-default-footer":"","disable-pagination":"","disable-filtering":"","show-select":"","dense":""},on:{"toggle-select-all":function($event){return _vm.handleSelectAllToggle($event, index)},"item-selected":function($event){return _vm.handleSelectItemToggle($event, index)},"input":_vm.changedSelectedItems},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var ref_props = ref.props;
    var headers = ref_props.headers;
    var someItems = ref_props.someItems;
    var everyItem = ref_props.everyItem;
    var toggle = ref.on['toggle-select-all'];
return [_c('thead',[_c('tr',_vm._l((headers),function(ref){
    var value = ref.value;
    var text = ref.text;
    var sortable = ref.sortable;
return _c('sticky-cell',{key:listItem.description + index + value,class:sortable ? 'custom-sortable' : '',on:{"click":function($event){return _vm.onSortList(value)}}},[(value === 'data-table-select')?_c('div',[_c(VSimpleCheckbox,{attrs:{"ripple":false,"value":everyItem,"indeterminate":someItems},on:{"input":toggle}})],1):(value === 'action')?_c('div',[_vm._v(" "+_vm._s(text)+" "),_c(VMenu,{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":_vm.handleOpenGenerate}},[_c(VListItemTitle,[_vm._v(" Gerar Remessa ")])],1)],1)],1)],1):([
                'accountReceivable',
                'balanceValue',
                'fluxDate',
                'bankCode',
                'bankingSituationDescription' ].includes(value))?_c('div',{class:_vm.sortAndDirection.direction && _vm.sortAndDirection.sort === value
                    ? 'active d-flex align-center justify-center text-center'
                    : 'd-flex align-center justify-center text-center'},[_vm._v(" "+_vm._s(_vm.getCorrectTextBasedOnHeader(listItem, index, text, value))+" "),(sortable)?_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.sortAndDirection.direction === 'asc' ? 'mdi-arrow-down' : 'mdi-arrow-up')+" ")]):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(text)+" ")])])}),1)])]}},{key:"item.data-table-select",fn:function(ref){
                    var item = ref.item;
                    var isSelected = ref.isSelected;
                    var select = ref.select;
return [_c(VSimpleCheckbox,{attrs:{"value":_vm.isItemSelected(item, index) ? isSelected : false,"readonly":_vm.isItemDisabled(item),"disabled":_vm.isItemSelected(item, index) ? false : _vm.isItemDisabled(item),"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.accountReceivable",fn:function(ref){
                    var item = ref.item;
return [_c('td',{class:_vm.isItemDisabled(item) ? 'not-liberated' : ''},[_vm._v(" "+_vm._s(item.branchCode)+" - "+_vm._s(item.companyName)+" - "+_vm._s(item.prefixCode)+" - "+_vm._s(item.documentNumber)+" "+_vm._s(item.quoteNumber ? (" - " + (item.quoteNumber)) : "")+" - "+_vm._s(item.documentType)+" ")])]}},{key:"item.balanceValue",fn:function(ref){
                    var item = ref.item;
return [_c('td',{class:_vm.isItemDisabled(item) ? 'not-liberated' : ''},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.balanceValue))+" ")])]}},{key:"item.fluxDate",fn:function(ref){
                    var item = ref.item;
return [_c('td',{class:_vm.isItemDisabled(item) ? 'not-liberated' : ''},[_vm._v(" "+_vm._s(item.fluxDate ? _vm.formatDate(item.fluxDate) : '')+" ")])]}},{key:"item.bankCode",fn:function(ref){
                    var item = ref.item;
return [_c('td',{class:_vm.isItemDisabled(item) ? 'not-liberated' : ''},[_vm._v(" "+_vm._s(item.bankCode)+" ")])]}},{key:"item.bankingSituationDescription",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('billing-remittance-list-status',{attrs:{"code":item.bankingSituationDescription}})],1)]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('billing-remittance-list-action',{attrs:{"item":item},on:{"action":_vm.handleAction}})],1)]}}],null,true)})}),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Títulos")]),_c('th',[_vm._v("Valor")])])]),_c('tbody',[_c('tr',[_c('th',[_vm._v("Total Geral")]),_c('td',[_vm._v(_vm._s(_vm.totalItems))]),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalItemsValue)))])]),_c('tr',[_c('th',[_vm._v("Total Selecionados")]),_c('td',[_vm._v(_vm._s(_vm.selectedItemsTotal))]),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(_vm.selectedItemsTotalValue)))])])])]},proxy:true}])}),_c('billing-remittance-details',{attrs:{"open":_vm.openDetails,"item":_vm.itemDetails},on:{"close":_vm.handleClose}}),_c('billing-remittance-occurrences',{attrs:{"open":_vm.openOccurrences,"item":_vm.itemOccurrences},on:{"close":_vm.handleClose}}),_c('billing-remittance-remove',{attrs:{"open":_vm.openRemove,"item":_vm.itemRemove},on:{"close":_vm.handleClose,"reload":_vm.reload}}),_c('billing-remittance-clear-bank-slip',{attrs:{"open":_vm.openClearBankSlipData,"item":_vm.itemRemove},on:{"close":_vm.handleClose,"reload":_vm.reload}}),_c('billing-remittance-generate',{attrs:{"open":_vm.openGenerate,"items":_vm.itemsGenerate,"data":_vm.data,"companiesSelected":_vm.companies},on:{"close":_vm.handleClose,"reload":_vm.reload}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }