<template>
  <v-select
    v-model="model"
    :label="label"
    :items="items"
    item-text="label"
    item-value="value"
    :placeholder="placeholder"
    :hide-details="hideDetails"
    :rules="allRules"
    :prepend-inner-icon="prependInnerIcon"
    filled
    v-bind="$attrs"
    v-on="$listeners"
  ></v-select>
</template>

<script>
import ModelManagementMixin from '@/mixins/ModelManagementMixin';

export default {
  name: 'InnSelect',
  mixins: [ModelManagementMixin],
  props: {
    label: String,
    items: Array,
    placeholder: String,
    hideDetails: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    prependInnerIcon: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allRules() {
      const requiredRule = (v) => !!v || 'Esse campo é obrigatório';
      return this.required ? [...this.rules, requiredRule] : this.rules;
    },
  },
};
</script>

<style></style>
