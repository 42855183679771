import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.mainGroupers,"loading":_vm.isLoading,"server-items-length":_vm.totalItems,"options":_vm.dataTableOptions,"footer-props":_vm.footerOptions},on:{"update:options":function($event){_vm.dataTableOptions=$event},"click:row":_vm.goToSingularMainGrouper},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status,"list":_vm.availableStatus}})]}},{key:"item.screensToShown",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setScreensToShownLegibleString(item))+" ")]}},{key:"item.table",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setTableLegibleString(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-delete","tooltip-text":"Remover Grupo Principal"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveMainGrouper(item)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }