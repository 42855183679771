<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Empresas"/>
			<page-card dividerOn>
				<template #title>
					<v-row>
						<v-col cols="12" lg="4" md="4" sm="12" xs="12">
							<v-select 
							v-model="configs.filterStatus"
							filled
							hide-details
							label="Filtrar por status"
							:items="filterStatusItems"/>
						</v-col>
						<v-col cols="12" lg="6" md="5" sm="12" xs="12">
							<v-text-field
								filled
								label="Pesquisar"
								hide-details
								prepend-inner-icon="mdi-magnify"
								v-model="configs.filterTerm"
							/>
						</v-col>
						<v-spacer />
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<v-btn
								@click="add"
              	class="font-weight-bold"
								x-large
								block
								depressed
								color="primary"
							>
								ADICIONAR
							</v-btn>
						</v-col>
					</v-row>
				</template>
				<template #body>
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>CNPJ</th>
								<th>Nome</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						<!-- eslint-disable-next-line -->
							<tr v-for="company in listData">
								<td style="vertical-align: middle;">
									<v-chip
										v-if="company.status == 0"
										dense
										small>
										Inativo
									</v-chip>
									<v-chip
										v-else
										dense
										small
										color="green"
										text-color="white">
										Ativo
									</v-chip>
								</td>
								<td style="vertical-align: middle;">{{ company.cnpj }}</td>
								<td style="vertical-align: middle;">{{ company.name }}</td>
								<td class="m--align-right">
									<button-tooltip
										fab
										icon
										small
										buttonIcon="mdi-pencil"
										tooltipText="Editar"
										@click="edit(company)"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</page-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash'
	import { API_BASE } from '@config/api';

	export default {
		data () {
			return {
				companys: [],
				configs: {
					filterTerm: '',
					filterStatus: ''
				},
				filterStatusItems: [
					{
						text: "Todos os status",
						value: "",
					},
					{
						text: "Inativos",
						value: "0",
					},
					{
						text: "Ativos",
						value: "1",
					},
				]
			}
		},

		computed: {
			listData() {
				const self = this;
				const filterStatus = self.configs.filterStatus;
				const filterTerm = self.configs.filterTerm.toLowerCase();
				var dataRet = this.companys;

				if (!_.isEmpty(filterStatus)) {
					dataRet = _.filter(dataRet, function(data) { return data.status == filterStatus; })
				}

				if (!_.isEmpty(filterTerm)) {
					dataRet = _.filter(dataRet, list => list.name.toLowerCase().indexOf(filterTerm) >= 0 || list.cnpj.indexOf(filterTerm) >= 0)
				}

				return dataRet
			}
		},

		mounted: function () {
			this.loadCompanys();
		},

		methods: {
			loadCompanys: function () {
				blockPage('Carregando os dados...');

				this.$http.get(API_BASE + 'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
					function (result) {
						this.companys = result.body.data
						unblockPage();
					},
					function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				).bind(this)
			},
			add() {
				this.$router.push('/company/new');
			},	
			edit(company) {
				const params = {
					company_id: company.id,
				};
				this.$router.push({name: 'editcompany',params});
			},
		}	
	}
</script>
