export default class ConciliationJobIntegrationError {
  constructor(
    public errorMessage: string,
    public jobId: string,
  ) {}

  static make(data: any): ConciliationJobIntegrationError {
    return new ConciliationJobIntegrationError(
      data.formatted_message,
      data.job_id,
    );
  }
}
