import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"page-title":"Negociação"}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,{attrs:{"justify":"space-between","no-gutters":""}},[_c(VCol,{attrs:{"cols":"5"}},[_c(VStepper,{staticClass:"pre-negotiation-stepper elevation-0",model:{value:(_vm.actualStep),callback:function ($$v) {_vm.actualStep=$$v},expression:"actualStep"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"step":1,"complete":_vm.actualStep > 1}},[_vm._v(" Parcelas ")]),_c(VDivider),_c(VStepperStep,{attrs:{"step":2,"complete":_vm.actualStep > 2}},[_vm._v(" Prazos ")]),_c(VDivider),_c(VStepperStep,{attrs:{"step":3,"complete":_vm.actualStep > 3}},[_vm._v(" Assinantes ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"4","align-self":"center"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"px-2"},[_c(VBtn,{attrs:{"large":"","block":""},on:{"click":function($event){return _vm.changeStep('previous')}}},[_vm._v(" CANCELAR ")])],1),_c(VCol,{staticClass:"px-2"},[_c(VBtn,{attrs:{"disabled":!_vm.canConfirm,"large":"","block":"","color":"success"},on:{"click":function($event){return _vm.changeStep('next')}}},[_vm._v(" CONFIRMAR ")])],1)],1)],1)],1)]},proxy:true},{key:"body",fn:function(){return [(_vm.hasPoliciesLoaded)?_c(VContainer,[_c(VSlideXTransition,{attrs:{"leave-absolute":"","group":""}},[(_vm.actualStep === 1)?_c('pre-negotiation-installments',{key:"1"}):_vm._e(),(_vm.actualStep === 2)?_c('pre-negotiation-deadline',{key:"2"}):_vm._e(),(_vm.actualStep === 3)?_c('pre-negotiation-subscribers',{key:"3"}):_vm._e()],1)],1):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }