




































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import {
  formateDate,
  formatMonth,
  dateGreaterOrEqualToday,
  ptBrDateFormat,
} from '@/utils/date';

@Component
export default class InnDateField extends Vue {
  @Prop({
    type: String,
    default: '',
  }) readonly value!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly required!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly shouldBeGreater!: boolean;

  @Prop({
    type: String,
    default: '',
  }) readonly nudgeTop!: string;

  @Prop({
    type: String,
    default: 'date',
  }) readonly type!: 'date' | 'month';

  @Prop({
    type: Array,
    required: false,
    default: () => ([]),
  }) readonly customRules!: Function[];

  @Watch('value')
  onValueChanged(newValue: string) {
    this.model = newValue;
  }

  @Watch('model')
  onModelChanged(newModel: string) {
    this.$emit('input', newModel);
  }

  menu: boolean = false;
  model: string = this.value;

  public get selected() {
    if (!this.model) return this.model;

    if (this.type === 'month') return formatMonth(`${this.model}`);

    return formateDate(`${this.model}`);
  }

  public set selected(v : string) {
    this.model = v;
  }

  get rules() {
    const rules = [];

    if (this.required) rules.push((v: string) => !!v || 'Esse campo é obrigatório');

    if (this.shouldBeGreater) {
      rules.push((v: string) => dateGreaterOrEqualToday(v, ptBrDateFormat) || 'Escolha uma data atual ou futura');
    }

    if (this.customRules) {
      rules.push(...this.customRules);
    }

    return rules;
  }
}
