


























import {
  Component,
  Emit,
  Prop,
  Ref,
  Vue,
  Watch,
} from 'vue-property-decorator';

@Component
export default class ContentEditableComponent extends Vue {
  public model: string | number = '';
  public valueToShowOnField: string = '';

  public isOpen: boolean = false;
  public isContentEditable: boolean = false;

  @Prop({
    type: [String, Number],
    required: true,
  }) readonly value!: string|number;

  @Prop({
    type: String,
    required: true,
  }) readonly formattedValueToShow!: string;

  @Prop({
    type: Array,
    required: false,
    default: () => ([]),
  }) readonly rules!: Function[];

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) readonly withButton!: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'string',
  }) readonly typeOfValue!: 'number' | 'string';

  @Ref('ContentEditableInput') readonly contentEditableInput!: {
      click: () => void;
      focus: () => void;
  };

  @Emit('on-confirm')
  public onValidatedRule(): string|number {
    if (this.typeOfValue === 'number') {
      return Number(this.model);
    }

    return this.model;
  }

  @Watch('formattedValueToShow')
  public onFormattedValueToShowChange(): void {
    this.valueToShowOnField = this.formattedValueToShow;
  }

  public get onClickClass(): string {
    let baseClass = 'content-editable-input';

    if (this.isOpen) {
      baseClass = `${baseClass} text-center`;
    }

    return baseClass;
  }

  public created(): void {
    this.model = this.value;
    this.valueToShowOnField = this.formattedValueToShow;
  }

  public onInput(inputValue: InputEvent): void {
    const target = inputValue.target! as HTMLInputElement;
    this.model = target.innerText;
  }

  public async onClick(): Promise<void> {
    if (!this.isContentEditable) {
      return;
    }

    this.isOpen = true;
    this.valueToShowOnField = String(this.value);
  }

  public onBlur(): void {
    this.isOpen = false;
    this.valueToShowOnField = this.formattedValueToShow;
    this.isContentEditable = false;
  }

  public validateRules($event: { target: HTMLElement }): void {
    let errorMessage = '';

    for (let i = 0; i < this.rules.length; i += 1) {
      const rule = this.rules[i];
      const executedRule = rule(String(this.model));

      if (typeof executedRule === 'string') {
        errorMessage = executedRule;
        break;
      }
    }

    if (errorMessage) {
      this.$notification.error(errorMessage);

      return;
    }

    $event.target.blur();
    this.onValidatedRule();
  }

  public async clickOnContentEditableInput(): Promise<void> {
    this.isContentEditable = true;

    await this.$forceUpdate();

    this.contentEditableInput.click();
    this.contentEditableInput.focus();
  }
}
