import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import store from '@/stores';
import AccountsReceivableListFeeTypeEnum from '@/views/preNegotiation/enums/AccountsReceivableListFeeTypeEnum';
import ISelectedSecuritiesParams from '@/views/financialStatement/interfaces/ISelectedSecuritiesParams';
import AccountsReceivableTotalPreNegotiation from '@/domain/models/AccountsReceivableTotalPreNegotiation';
import IAccountFee from '@/views/preNegotiation/interfaces/IAccountFee';
import IInstallmentConfigs from '@/views/preNegotiation/interfaces/IInstallmentConfigs';
import IInstallmentChange from '@/views/preNegotiation/interfaces/IInstallmentChange';
import AccountReceivablePreNegotiationDeadline from '@/domain/models/AccountReceivablePreNegotiationDeadline';
import ISignatureGroupByEconomicGroupCompanyAndCustomer from '@/views/preNegotiation/interfaces/ISignatureGroupByEconomicGroupCompanyAndCustomer';
import IPreNegotiationSignature from '@/views/preNegotiation/interfaces/IPreNegotiationSignature';
import TradingPolicy from '@/domain/models/TradingPolicy';
import FinancialStatementBillingOriginType from '@/views/financialStatement/types/FinancialStatementBillingOriginType';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'pre-negotiation',
  store,
})
export default class PreNegotiationModule extends VuexModule {
  public hasInstallmentsDefined: boolean = false;
  public hasEqualValuesInstallments: boolean = false;
  public sameSignatureForAllCompanies: boolean = false;
  public hasValidExpirationDate: boolean = false;

  public expirationDate: string|null = null;
  public origin: FinancialStatementBillingOriginType|null = null;

  public totalFromInstallments: number = 0;
  public totalFee: number = 0;
  public totalFeeType: AccountsReceivableListFeeTypeEnum|null = null;

  public installmentConfigs: IInstallmentConfigs = {
    quantity: null,
    periodicity: null,
    customPeriodicity: null,
  };
  public selectedSecuritiesParams: ISelectedSecuritiesParams = {
    securitiesIdsCustomer: [],
    isSelectingAllSecurities: 0,
  }
  public accountsTotal: AccountsReceivableTotalPreNegotiation = {
    totalValue: 0,
    totalBalance: 0,
    financialFee: 0,
    percentageFee: 0,
    totalBalanceWithFees: 0,
  }
  public tradingPolicy: TradingPolicy|null = null;

  public excludedAccounts: string[] = [];
  public accountFees: IAccountFee[] = [];
  public installmentChanges: IInstallmentChange[] = [];
  public deadlineRedefinedAccounts: AccountReceivablePreNegotiationDeadline[] = [];
  public signatureGroups: ISignatureGroupByEconomicGroupCompanyAndCustomer[] = [];

  public get hasSelectedSecurity(): boolean {
    return this.selectedSecuritiesParams.securitiesIdsCustomer.length > 0
      || (
        this.selectedSecuritiesParams.isSelectingAllSecurities === true
        || this.selectedSecuritiesParams.isSelectingAllSecurities === 1
      );
  }

  @Mutation
  public setSelectedSecurites(selectedSecuritesParams: ISelectedSecuritiesParams): void {
    this.selectedSecuritiesParams = selectedSecuritesParams;
  }

  @Mutation
  public setAccountsTotal(totals: AccountsReceivableTotalPreNegotiation): void {
    this.accountsTotal.totalValue = totals.totalValue;
    this.accountsTotal.totalBalance = totals.totalBalance;
    this.accountsTotal.financialFee = totals.financialFee;
    this.accountsTotal.percentageFee = totals.percentageFee;
    this.accountsTotal.totalBalanceWithFees = totals.totalBalanceWithFees;
  }

  @Mutation
  public setAccountFee(newAcccountFee: IAccountFee): void {
    const accountFeeIndex = this.accountFees
      .findIndex((accountFee) => accountFee.idCustomer === newAcccountFee.idCustomer);

    if (accountFeeIndex < 0 && newAcccountFee.fee > 0) {
      this.accountFees.push(newAcccountFee);
    } else if (accountFeeIndex >= 0 && newAcccountFee.fee > 0) {
      this.accountFees[accountFeeIndex] = newAcccountFee;
    } else if (accountFeeIndex >= 0 && newAcccountFee.fee === 0) {
      this.accountFees = this.accountFees.filter((fee, index) => index !== accountFeeIndex);
    }
  }

  @Mutation
  public setExcludedAccount(idCustomer: string): void {
    const excludedAccountIndex = this.excludedAccounts
      .findIndex((excludedAccount) => idCustomer === excludedAccount);

    if (excludedAccountIndex > -1) {
      return;
    }

    this.excludedAccounts.push(idCustomer);
  }

  @Mutation
  public setAccountFeeEmpty(): void {
    this.accountFees = [];
  }

  @Mutation
  public setTotalFee(totalFee: number): void {
    this.totalFee = totalFee;
  }

  @Mutation
  public setTotalFeeType(totalFeeType: AccountsReceivableListFeeTypeEnum|null): void {
    this.totalFeeType = totalFeeType;
  }

  @Mutation
  public setTotalFromInstallments(totalValue: number): void {
    this.totalFromInstallments = totalValue;
  }

  @Mutation
  public setHasInstallmentsDefined(isDefined: boolean): void {
    this.hasInstallmentsDefined = isDefined;
  }

  @Mutation
  public setInstallmentConfigs(installmentConfigs: IInstallmentConfigs): void {
    this.installmentConfigs.quantity = installmentConfigs.quantity;
    this.installmentConfigs.periodicity = installmentConfigs.periodicity;
    this.installmentConfigs.customPeriodicity = installmentConfigs.customPeriodicity;
  }

  @Mutation
  public setInstallmentChanges(installmentChange: IInstallmentChange): void {
    const existentInstallmentChange = this.installmentChanges.findIndex((actualChange) => (
      actualChange.index === installmentChange.index
      && actualChange.changedField === installmentChange.changedField
    ));

    if (existentInstallmentChange > -1) {
      this.installmentChanges[existentInstallmentChange] = installmentChange;

      return;
    }

    this.installmentChanges.push({ ...installmentChange });
  }

  @Mutation
  public setAllInstallmentChanges(installmentChanges: IInstallmentChange[]): void {
    this.installmentChanges = installmentChanges;
  }

  @Mutation
  public setExpirationDate(expirationDate: string|null): void {
    this.expirationDate = expirationDate;
  }

  @Mutation
  public setHasValidExpirationDate(isValid: boolean): void {
    this.hasValidExpirationDate = isValid;
  }

  @Mutation
  public setDeadlineRedefinedAccounts(accounts: AccountReceivablePreNegotiationDeadline[]): void {
    this.deadlineRedefinedAccounts = accounts;
  }

  @Mutation
  public setTradingPolicy(tradingPolicy: TradingPolicy): void {
    this.tradingPolicy = tradingPolicy;

    if (this.tradingPolicy.standardInterest) {
      this.totalFeeType = AccountsReceivableListFeeTypeEnum.PERCENTAGE;
      this.totalFee = this.tradingPolicy.standardInterest;
    }

    if (this.tradingPolicy.periodicityInstallments && this.tradingPolicy.amountInstallments) {
      this.installmentConfigs = {
        customPeriodicity: null,
        periodicity: this.tradingPolicy.periodicityInstallments,
        quantity: this.tradingPolicy.amountInstallments,
      };
    }
  }

  @Mutation
  public setHasEqualValuesInstallments(state: boolean): void {
    this.hasEqualValuesInstallments = state;
  }

  @Mutation
  public setOrigin(origin: FinancialStatementBillingOriginType): void {
    this.origin = origin;
  }

  @Mutation
  public resetInstallmentChanges(): void {
    this.installmentChanges = [];
  }

  @Mutation
  public resetPreNegotiationStoreData(): void {
    this.hasInstallmentsDefined = false;
    this.sameSignatureForAllCompanies = false;

    this.totalFee = 0;
    this.totalFeeType = null;
    this.totalFromInstallments = 0;
    this.expirationDate = null;
    this.installmentConfigs = {
      quantity: null,
      periodicity: null,
      customPeriodicity: null,
    };
    this.selectedSecuritiesParams = {
      securitiesIdsCustomer: [],
      isSelectingAllSecurities: 0,
    };
    this.accountsTotal = {
      totalValue: 0,
      totalBalance: 0,
      financialFee: 0,
      percentageFee: 0,
      totalBalanceWithFees: 0,
    };
    this.excludedAccounts = [];
    this.accountFees = [];
    this.installmentChanges = [];
    this.deadlineRedefinedAccounts = [];
    this.signatureGroups = [];
    this.tradingPolicy = null;
    this.origin = null;
  }

  @Mutation
  public setSameSignatureForAllCompanies(shouldConisder: boolean): void {
    this.sameSignatureForAllCompanies = shouldConisder;
  }

  @Mutation
  public setNewSignatureGroup(
    signatureGroup: ISignatureGroupByEconomicGroupCompanyAndCustomer,
  ): void {
    this.signatureGroups.push(signatureGroup);
  }

  @Mutation
  public setNewSignatureToSpecificGroup(
    signature: IPreNegotiationSignature & { index: number },
  ): void {
    this.signatureGroups[signature.index].signatures.push({
      document: signature.document,
      mail: signature.mail,
      name: signature.name,
      type: signature.type,
    });
  }

  @Mutation
  public removeSpecificSignature(indexes: {
    signatureGroupIndex: number;
    signatureRemovedIndex: number;
  }): void {
    const {
      signatureGroupIndex,
      signatureRemovedIndex,
    } = indexes;
    const findedSignatures = this.signatureGroups[signatureGroupIndex].signatures;

    this.signatureGroups[signatureGroupIndex].signatures = findedSignatures
      .filter((signatureGroup, subIndex) => subIndex !== signatureRemovedIndex);
  }
}
