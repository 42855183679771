export default class CompanyGroup {
  constructor(
    public id: number,
    public status: boolean,
    public description: string,
    public imageUrl: string,
  ) { }

  static make(data: any): CompanyGroup {
    return new CompanyGroup(
      data.id,
      data.status,
      data.description,
      data.image_url,
    );
  }
}
