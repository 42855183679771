import moment from 'moment';

const isRangeIntersecting = (initialA, finalA, initialB, finalB) => {
  const momentDates = [initialA, finalA, initialB, finalB].map((date) => {
    const ddmmyyyy = new RegExp(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i);
    const yyyymmdd = new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
    if (ddmmyyyy.test(date)) {
      return moment(date, 'DD/MM/YYYY');
    }
    if (yyyymmdd.test(date)) {
      return moment(date, 'YYYY-MM-DD');
    }
    throw new Error(`Invalid date format: ${date}`);
  });
  return !(
    (momentDates[0].diff(momentDates[3]) > 0 && momentDates[1].diff(momentDates[3]) > 0)
    || (momentDates[0].diff(momentDates[2]) < 0 && momentDates[1].diff(momentDates[2]) < 0)
  );
};

export default isRangeIntersecting;
