import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component
export default class StartLoadingMixin extends Vue {
  loading : boolean = true;

  @Watch('loading')
  onLoadingChange(newLoading : boolean) {
    if (newLoading) {
      this.$dialog.startLoading();
    } else {
      this.$dialog.stopLoading();
    }
  }
}
