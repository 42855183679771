import IPeriodicityRangeDates from '@/views/cashFlow/predictedFlow/interfaces/IPeriodicityRangeDates';
import IMainGrouper from '@/views/cashFlow/predictedFlow/interfaces/groupers/IMainGrouper';
import ITotalsData from '@/views/cashFlow/predictedFlow/interfaces/groupers/ITotalsData';

export default class CashFlowList {
  constructor(
    public cashFlowItems: IMainGrouper[],
    public totals: ITotalsData[],
    public periodicityDates: IPeriodicityRangeDates[],
  ) { }

  static make(data: any): CashFlowList {
    return new CashFlowList(
      data.cash_flow_items,
      data.totals,
      data.periodicity_dates,
    );
  }
}
