














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Menu from '@/domain/models/Menu';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';

const UNOESC_COMPANY_GROUP_ID = 7;

@Component
export default class DrawerLink extends Vue {
  @Prop() readonly menu!: Menu;
  @Prop() readonly subgroup!: boolean;
  @Prop() readonly currentMenu!: string;
  authStore?: AuthenticationModule;

  get isActive(): boolean {
    return this.currentMenu == this.menu.routeName;
  }

  get companyGroupId(): number {
    return (this.authStore && Number(this.authStore!.user.company_group_id)) ?? 0;
  }

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);
  }

  goToLink() {
    let { routeName } = this.menu;

    if (this.companyGroupId === UNOESC_COMPANY_GROUP_ID && routeName === 'ProjectedCashFlow') {
      routeName = 'ProjectedCashFlowUnoesc';
    }

    this.$router.replace({ name: routeName });
  }
}
