






























































import {
  Vue,
  Component,
  Ref,
} from 'vue-property-decorator';

import IBillingRemittanceSearch from '@/domain/interfaces/IBillingRemittanceSearch';
import IBillingRemittanceFilterData from '@/domain/interfaces/IBillingRemittanceFilter';

import BillingRemittancePaymentMethod from '@/domain/models/BillingRemittancePaymentMethod';

import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';

import ISortAndDirection from '@/helpers/interfaces/ISortAndDirection';

import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';

import SortHelper from '@/helpers/SortHelper';
import SortDataType from '@/helpers/types/SortDataType';
import IGenerateSortConfig from '@/helpers/interfaces/IGenerateSortConfig';

import BillingRemittanceFilter from './components/Filter.vue';
import BillingRemittanceList from './components/List.vue';
import { formatErrorForNotification } from './utils';

interface GenerateData {
  companies: Array<number>,
  initialDate: string,
  endDate: string,
}

@Component({
  components: {
    BillingRemittanceFilter,
    BillingRemittanceList,
  },
})
export default class BillingRemittancePage extends Vue {
  @Ref('billing-remittance-filter') billingRemittanceFilter!: BillingRemittanceFilter;

  readonly billingRemittanceRepository:
    BillingRemittanceRepository = new BillingRemittanceRepository();

  loading: boolean = false;

  showFilter: boolean = true;
  showList: boolean = false;

  public sortAndDirection: ISortAndDirection = {
    direction: null,
    sort: null,
  }

  list: Array<BillingRemittancePaymentMethod> = [];
  limit: number = 0;
  approval: boolean = true;

  currentData: IBillingRemittanceFilterData = {} as IBillingRemittanceFilterData;
  generateData: GenerateData = {} as GenerateData;

  get initialDate(): string {
    return this.currentData.initial_emission_date != ''
      ? this.currentData.initial_emission_date
      : this.currentData.initial_due_date;
  }

  get endDate(): string {
    return this.currentData.end_emission_date != ''
      ? this.currentData.end_emission_date
      : this.currentData.end_due_date;
  }

  get companies(): Array<number> {
    return this.currentData.companies;
  }

  getToggleIcon(): string {
    return this.showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down';
  }

  getToggleTooltip(): string {
    return this.showFilter ? 'Ocultar Parâmetros' : 'Exibir Parâmetros';
  }

  handleToggle(): void {
    this.showFilter = !this.showFilter;
  }

  handleValidate(): void {
    this.billingRemittanceFilter.validate();
  }

  async handleLoad(data: IBillingRemittanceFilterData): Promise<void> {
    try {
      this.$dialog.startLoading();
      this.loading = true;
      this.showFilter = true;
      this.showList = false;

      const params: IBillingRemittanceSearch = {
        issueDate: {
          initial: data.initial_emission_date,
          final: data.end_emission_date,
        },
        dueDate: {
          initial: data.initial_due_date,
          final: data.end_due_date,
        },
        returnDate: {
          initial: data.initial_return_date,
          final: data.end_return_date,
        },
        liberated: data.liberation ? data.liberation : 'ALL',
        documentStatus: data.status ? data.status : '',
        transactionType: data.types.length ? data.types : '',
        paymentMethod: data.methods.length ? data.methods : '',
        bankCode: data.banks.length ? data.banks
          .map(({ code, account, isNull }) => ({ code, account, isNull })) : [],
        clientCode: [],
        registrationNumber: data.customers.length ? data.customers : '',
        occurencyCode: data.occurrences.length ? data.occurrences : '',
        borderoNumber: data.borderos.length
          ? data.borderos.map((bordero) => {
              if (typeof bordero == 'string') return bordero;
              return `${bordero.value}`;
            })
          : [],
        customer_type: data.customerType,
      };

      const { list, limit, approval } = await this.billingRemittanceRepository
        .getRemittances(params, data.companies);

      this.list = list;
      this.limit = limit;
      this.approval = approval;
      this.currentData = data;

      this.generateData = {
        companies: data.companies,
        initialDate: data.initial_emission_date != '' ? data.initial_emission_date : data.initial_due_date,
        endDate: data.end_emission_date != '' ? data.end_emission_date : data.end_due_date,
      };

      if (this.list.length > 0) {
        this.showFilter = false;
        this.showList = true;
      } else {
        this.$notification.warn('Nenhum resultado encontrado.');
      }
    } catch (error: any) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  handleReload() {
    this.handleLoad(this.currentData);
  }

  public handleSortList(headerName: string) {
    if (headerName !== this.sortAndDirection.sort) {
      this.sortAndDirection.direction = null;
    }

    if (this.sortAndDirection.direction === null || this.sortAndDirection.direction === 'asc') {
      this.sortAndDirection.direction = 'desc';
    } else if (this.sortAndDirection.direction === 'desc') {
      this.sortAndDirection.direction = 'asc';
    }

    this.sortAndDirection.sort = headerName;

    for (let i = 0; i < this.list.length; i += 1) {
      const { items } = this.list[i];

      items.sort((
        a: BillingRemittanceAccountReceivable,
        b: BillingRemittanceAccountReceivable,
      ) => {
        if (this.sortAndDirection.direction === null) {
          return 0;
        }

        const valueA = this.getValueBasedOnColumn(headerName, a);
        const valueB = this.getValueBasedOnColumn(headerName, b);
        const sortMethod = this.getSortMethodBasedOnColumnName(headerName);

        const configs: IGenerateSortConfig = {
          dateFormat: 'yyyy-MM-dd',
        };

        if (this.sortAndDirection.direction === 'desc') {
          return SortHelper.getSortResultBasedOnValueType(sortMethod, valueA, valueB, configs);
        }

        return SortHelper.getSortResultBasedOnValueType(sortMethod, valueB, valueA, configs);
      });
    }
  }

  public getSortMethodBasedOnColumnName(columnName: string): SortDataType {
    if (columnName === 'balanceValue') {
      return 'number';
    }

    if (columnName === 'fluxDate') {
      return 'date';
    }

    return 'string';
  }

  public getValueBasedOnColumn(
    columnName: string,
    item: BillingRemittanceAccountReceivable,
  ): string|number {
    switch (columnName) {
      case 'accountReceivable': return `${item.companyName}${item.documentNumber}${item.quoteNumber}`;
      case 'bankCode': return item.bankCode;
      case 'fluxDate': return item.fluxDate;
      case 'bankingSituationDescription': return item.bankingSituationDescription;
      case 'balanceValue': return item.balanceValue;
      default: return '';
    }
  }
}
