<template>
	<div class="d-flex">
		<link rel="stylesheet" :href="darkThemeUrl" id="darktheme"/>

		<v-switch
			v-model="nocturne_mode"
			class="switch"
			hide-details
			inset
		>
		</v-switch>

		<v-icon
			@click="nocturne_mode = !nocturne_mode"
			:class="nocturne_mode ? nocturne.class : lighten.class"
		>
			{{nocturne_mode ? nocturne.icon : lighten.icon}}
		</v-icon>
	</div>
</template>

<script>
import { API_BASE } from '@config/api';
import FilterParameterService from '@/services/filterparameter.service';

export default {
	name: 'NightSwitch',
	data() {
		return {
			nocturne_mode: false,
			nocturne: {
				icon: 'mdi-weather-night',
				class: 'switch-nightly',
			},
			lighten: {
				icon: 'mdi-weather-sunny',
				class: 'switch-sunny',
			},
			old_value: false,
			darkThemeUrl: 'false',
			user_preferences_column: 'user_night_theme',
		};
	},

	mounted() {
		this.loadUserPreference();
	},

	watch: {
		nocturne_mode(newValue) {
			this.$vuetify.theme.dark = newValue;

			this.changeNocturneMode(newValue);

			if (newValue == this.old_value) return;

			this.updateUserPreference(newValue);
		},
	},

	methods: {
		async loadUserPreference() {
			try {
				if (!this.$session.exists() || !this.$session.get('token')) {
					return;
				}
				const filterParameterService = new FilterParameterService();

				const response = await filterParameterService.show(this.user_preferences_column);

				this.old_value = Number(response.data);
				this.nocturne_mode = Number(response.data);
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			}
		},

		async updateUserPreference(theme) {
			try {
				const filterParameterService = new FilterParameterService();

				const toUpdate = {
					id: 'user_night_theme',
					column: 'user_night_theme',
					value: theme,
				};

				await filterParameterService.update(toUpdate);

				this.nocturne_mode = theme;
				this.old_value = theme;
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			}
		},

		changeNocturneMode(nocturne) {
			if (nocturne) {
				this.darkThemeUrl = this.getDarkThemeFileUrl();
				return;
			}

			this.darkThemeUrl = 'false';
		},
		getDarkThemeFileUrl() {
			const url = `${API_BASE}css/darktheme.css`;

			return url;
		},
	},
};
</script>

<style lang="scss">

.sync {
    animation: spin 6s linear infinite;
}

.notification {
    &-btn {
        &__new > span > i{
            color:#00B0FF !important;
        }

        &__error > span > i {
            color: #FF1744 !important;
        }
    }

    &-badge {
        .v-badge__wrapper {
            transform: translate(-8px, 6px);
        }
    }
}

@keyframes spin {
 from {
     -webkit-transform: rotate(0deg);
     transform:rotate(0deg);
 }
 to {
     -webkit-transform: rotate(360deg);
     transform:rotate(360deg); }
}

.switch {
    transform: translate(20px, 0px);

    &-nightly {
        color: yellow !important;
        font-size: 16px !important;
        transform: translate(-13px, 0px);

        &:focus {
            outline: none !important;
        }
    }

    &-sunny {
        color: black !important;
        font-size: 16px !important;
        transform: translate(-34px, 0px);

        &:focus {
            outline: none !important;
        }
    }
}
</style>
