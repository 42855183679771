<style>


</style>
<template>
    <div>
        <div class="collapse show">
            <div class="row levels level-three"
                 :style="(identifyValues != 'BC' && mainData.level_four.length > 0) ? 'font-weight: bold; background-color: #f0f4f7;padding-top: 6px;padding-bottom: 6px;color: black;' : 'padding-top: 6px;padding-bottom: 6px;color: black;'">
                <div class="col-sm-3" style="padding-right:0;padding-left:10px;">
                    <i data-toggle="collapse" @click="toggle"
                       :class="showSection ? 'fa fa-chevron-down item-collapse' : 'fa fa-chevron-right item-collapse'"
                       style="font-size: 11px;padding-top: 3px;float: left;cursor: pointer;"
                       v-show="identifyValues != 'BC' && mainData.level_four.length > 0"></i>
                    <span class="description" style="padding-left: 3px;width: 185px;white-space: normal;">
                    {{spanDescription}}
                </span>
                </div>
                <div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">
                    <div class="row" style="padding-right:0;padding-left:0;">
                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;
                        </div>

                        <div
                                class="col-sm-2 text-right containerLevelThree containerDate"
                                :data-date="dataValue.date"
                                style="padding-right:0;padding-left:0;"
                                v-show="verifyShowDate(dataValue.date)"
                                v-for="(dataValue, idx) in mainData.data_values" :key="'L3-' + idx">
                            <span>{{dataValue.value | formatNumber}}</span>
                        </div>

                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="showSection" v-if="childName">
                <slot :name="childName"></slot>
            </div>

        </div>
    </div>
</template>


<script>
    export default {
        props: ['typeLevel', 'dataRows', 'cashFlowHiddenDates', 'childName', 'spanDescription', 'identifyValues'],
        data() {
            return {
                showSection: false,
                description: "",
                mainData: this.dataRows
            }
        },
        watch: {
            dataRows: function (newVal, oldVal) {
                var self = this;
                self.mainData = newVal;
            }
        }
        ,
        methods: {
            toggle() {
                this.showSection = !this.showSection
            },
            verifyShowDate(dateParam) {
                var self = this;
                var lShow = true;
                for (var i = 0; i < self.cashFlowHiddenDates.length; i++) {
                    if (self.cashFlowHiddenDates[i].dateHidden == dateParam || self.cashFlowHiddenDates[i].dateNoConvert == dateParam) {
                        lShow = false;
                        break;
                    }
                }

                return lShow;

            },
        }
    }
</script>
