import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":_vm.dialogWidth,"persistent":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Enviar 2º via dos boletos selecionados por E-mail ")]),_c(VCardText,[_c(VForm,{ref:"SendBilletsToMailForm"},[_c(VRow,{attrs:{"justify":"space-between","align-content":"center"}},[(_vm.companyGroupId === 12)?_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Nome do solicitante","filled":"","rules":[
                _vm.inputRulesHelper.required(),
                _vm.inputRulesHelper.lowerOrEqualThen(255) ],"disabled":_vm.loading},model:{value:(_vm.requestedBy),callback:function ($$v) {_vm.requestedBy=$$v},expression:"requestedBy"}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"E-mail a ser enviado a 2º via do boleto","filled":"","rules":[
                _vm.inputRulesHelper.required(),
                _vm.inputRulesHelper.isEmail(true),
                _vm.inputRulesHelper.lowerOrEqualThen(255) ],"disabled":_vm.loading},model:{value:(_vm.mailToSendBillets),callback:function ($$v) {_vm.mailToSendBillets=$$v},expression:"mailToSendBillets"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"button-width",attrs:{"large":"","disabled":_vm.loading},on:{"click":_vm.handleCancel}},[_vm._v(" CANCELAR ")]),_c(VBtn,{staticClass:"button-width",attrs:{"color":"success","large":"","loading":_vm.loading},on:{"click":_vm.handleConfim}},[_vm._v(" SALVAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }