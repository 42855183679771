<template>
  <v-row>
    <v-col>
      <breadcrumbs page-title="Ocorrências"/>
      <page-card dividerOn>
        <template #title>
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="12" xs="12">
              <v-select 
                v-model="filter.status"
                filled
                dense
                hide-details
							  label="Filtrar por status"
                :items="filterStatusItems"/>
            </v-col>
            <v-col cols="12" lg="6" md="5" sm="12" xs="12">
              <v-text-field
                filled
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar"
                v-model="filter.term"
              />
            </v-col>
            <v-col cols="12" lg="2" md="3" sm="12" xs="12">
              <v-btn
                @click="add"
                class="font-weight-bold"
                x-large
                block
                depressed
                color="primary"
              >
                ADICIONAR
              </v-btn>    
            </v-col>
          </v-row>
        </template>
        <template #body>
           <occurrences-table :occurrences="occurrences" :filter="filter" />
        </template>
      </page-card>
    </v-col>
  </v-row>
</template>

<script>
import OccurrencesService from "@/services/occurrences.service";
import OccurrencesTable from "./OccurrencesTable.vue";
export default {
  name: "ListOccurrences",
  components: {
    OccurrencesTable,
  },
  data: () => ({
    occurrences: [],

    filter: {
      status: "",
      term: ""
    },
    filterStatusItems: [
      {
        text: "Todos os status",
        value: "",
      },
      {
        text: "Inativo",
        value: "F",
      },
      {
        text: "Ativo",
        value: "T",
      },
    ]
  }),
  mounted() {
    this.getData();
  },
  created() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        blockPage("Carregando os dados...");
        const occurrencesService = new OccurrencesService();
        const response = await occurrencesService.index(this.filter.term);
        this.occurrences = response;
      } catch (error) {
        this.$helpers.defaultCatchError(error);
        unblockPage();

      } finally {
        unblockPage();
      }
    },
    add() {
      this.$router.push('/occurrences/new');
    },
  }
};
</script>
