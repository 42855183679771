import BillingRemittanceAccountReceivableLog from '@/domain/models/BillingRemittanceAccountReceivableLog';

export default class BillingRemittanceAccountReceivableDetail {
  constructor(
    public a1_tipo: string,
    public acrescimo: string,
    public agencia: string,
    public bordero: string,
    public branchName: string,
    public cnpj_cliente: string,
    public codigo_natureza: string,
    public conta: string,
    public data_emissao: string,
    public data_fluxo: string,
    public data_log: Array<BillingRemittanceAccountReceivableLog>,
    public data_vencimento: string,
    public data_vencimento_real: string,
    public decrescimo: string,
    public descricao_natureza: string,
    public filial: string,
    public filial_origem: string,
    public forma_pagamento: string,
    public historico_movimento: string,
    public id: number,
    public moeda: number,
    public nome_cliente: string,
    public numero: string,
    public observacao: string,
    public parcela: string,
    public pessoa: string,
    public portador: string,
    public prefixo: string,
    public razao_cliente: string,
    public saldo: string,
    public table_company_code: string,
    public tipo: string,
    public valor_alterado: string,
    public valor_juros: string,
    public valor_original: string,
    public valor_total: string,
  ) {}

  static make(data: any): BillingRemittanceAccountReceivableDetail {
    const log = data.data_log ? data.data_log.map(BillingRemittanceAccountReceivableLog.make) : [];
    return new BillingRemittanceAccountReceivableDetail(
      data.a1_tipo,
      data.acrescimo,
      data.agencia,
      data.bordero,
      data.branchName,
      data.cnpj_cliente,
      data.codigo_natureza,
      data.conta,
      data.data_emissao,
      data.data_fluxo,
      log,
      data.data_vencimento,
      data.data_vencimento_real,
      data.decrescimo,
      data.descricao_natureza,
      data.filial,
      data.filial_origem,
      data.forma_pagamento,
      data.historico_movimento,
      data.id,
      data.moeda,
      data.nome_cliente,
      data.numero,
      data.observacao,
      data.parcela,
      data.pessoa,
      data.portador,
      data.prefixo,
      data.razao_cliente,
      data.saldo,
      data.table_company_code,
      data.tipo,
      data.valor_alterado,
      data.valor_juros,
      data.valor_original,
      data.valor_total,
    );
  }
}
