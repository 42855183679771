import Repository from '@/repositories/base/Repository';
import ListAccountLogs from '@/domain/models/accountLogs/ListAccountLogs';
import ICreateAccountLogsParams from '@/repositories/parameters/AccountLogs/interfaces/ICreateAccountLogsParams';

export default class AccountsLogRepository extends Repository {
  async getLogs(
    companyGroupId: number,
    accountType: 'payable' | 'receivable',
    idCustomer: string,
  ): Promise<ListAccountLogs[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/account-logs/${idCustomer}`, {
      params: {
        account_type: accountType,
      },
    });

    return response.data.map(ListAccountLogs.make);
  }

  async createLog(
    companyGroupId: number,
    params: ICreateAccountLogsParams,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/account-logs`, params);
  }
}
