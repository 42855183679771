import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import MainGroupersScreensShownEnum from '@/views/cashFlowGroupers/mainGrouper/enums/MainGroupersScreensShownEnum';

export default class FilterParameterMainGrouperList {
  public constructor(
    public tables: CashFlowTablesEnum[],
    public screensToShown: MainGroupersScreensShownEnum[],
    public status: number[],
    public companyIds: number[],
  ) {}

  public static make(data: any): FilterParameterMainGrouperList {
    return new FilterParameterMainGrouperList(
      data.tables ? JSON.parse(data.tables) : [],
      data.screens_to_shown ? JSON.parse(data.screens_to_shown) : [],
      data.status ? JSON.parse(data.status) : [],
      data.company_ids ? JSON.parse(data.company_ids) : [],
    );
  }
}
