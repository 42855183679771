





















































































































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import ICreditRuleGroup from '@/views/creditRules/interfaces/ICreditRuleGroup';

@Component
export default class RuleGroupBlock extends Vue {
  public get getAdjustedTooltipTextBasedOnCreditRuleGroupType(): string {
    if (this.typeOfRule === 'credit-rule') {
      return 'Atualizar regra de crédito';
    }

    return 'Atualizar filtro de crédito';
  }

  public get getAdjustedTittleBasedOnCreditRuleGroupType(): string {
    if (this.typeOfRule === 'credit-rule') {
      return 'Regra de crédito';
    }

    return 'Filtro de crédito';
  }

  @Prop({
    type: String,
    required: true,
  }) readonly typeOfRule!: 'credit-rule' | 'credit-filter';

  @Prop({
    type: String,
    required: false,
    default: null,
  }) readonly actualAction!: 'update-credit-rules-group' | 'insert-error-message' | 'update-error-message' | null;

  @Prop({
    type: Object,
    required: true,
  }) readonly creditRuleGroup!: ICreditRuleGroup;

  public getClassCreditRuleBlock(): string {
    let definedClass = '';

    if (this.actualAction === 'update-credit-rules-group') {
      definedClass = 'is-editing-row-credit-rule-group-background elevation-5';
    } else {
      definedClass = 'default-row-credit-rule-group-background elevation-2';
    }

    return `complete-rule-wrapper ${definedClass}`;
  }

  public handleUpdateCreditRuleGroup() {
    this.$emit('update-credit-rule-or-filter');
  }

  public handleEditErrorMessage() {
    this.$emit('update-edit-error-message');
  }

  public handleRemoveCompleteRule() {
    this.$emit('remove-credit-rule-or-filter');
  }
}
