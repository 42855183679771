





















































import { defineComponent } from '@vue/composition-api';
import {
  formatToSpecificFormat,
  ptBrDateTimeFormat,
  dateTimeFormat,
} from '@/utils/date';
import AccountsLogRepository from '@/repositories/AccountsLogRepository';
import ListAccountLogs from '@/domain/models/accountLogs/ListAccountLogs';
import AccountActionsEnum from '@/repositories/parameters/AccountLogs/enums/AccountActionsEnum';

export default defineComponent({
  data() {
    const logs: ListAccountLogs[] = [];
    const loading: boolean = false;

    return {
      logs,
      loading,
      accountLogRepository: new AccountsLogRepository(),
      formatToSpecificFormat,
      ptBrDateTimeFormat,
      dateTimeFormat,
    };
  },
  props: {
    idCustomer: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.getLogsFromAccountReceivable();
  },
  methods: {
    async getLogsFromAccountReceivable(): Promise<void> {
      try {
        this.loading = true;

        this.logs = await this.accountLogRepository.getLogs(
          this.$store.state.authentication.user.company_group_id,
          'receivable',
          this.idCustomer,
        );
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os logs.');
      } finally {
        this.loading = false;
      }
    },
    formatAction(action: AccountActionsEnum): string {
      switch (action) {
        case AccountActionsEnum.REMOVE_FROM_REMITTANCE: return 'Remoção da remessa';
        case AccountActionsEnum.REMITTANCE_INCLUSION: return 'Inclusão da remessa';
        case AccountActionsEnum.SEND_REMITTANCE_API: return 'Envio da remessa para a API';
        case AccountActionsEnum.CHANGE_DATE: return 'Alteração de data';
        case AccountActionsEnum.CHANGE_CARRIER: return 'Alteração de portador';
        default: return '';
      }
    },
  },
});
