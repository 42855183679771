






















































































































































































import {
  Component,
  Emit,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import LooseObjectType from '@/types/LooseObjectType';
import ColumnToShow from '@/types/ColumnToShow';

import Type from '@/domain/enums/PaymentRemittancesType';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';

import Summary from '@/domain/models/PaymentRemittancesSummary';
import Balance from './actions/SummaryBalance.vue';
import MenuCustom from './SummaryMenu.vue';
import ToggleButton from './ToggleButton.vue';
import { CloseSummaryAction, OpenSummaryAction } from '../utils/interfaces';

interface RowClickParams {
  // eslint-disable-next-line no-unused-vars
  expand: (value: boolean) => void,
  isExpanded: boolean,
}

@Component({
  components: {
    DynamicColumnMenu,
    ToggleButton,
    MenuCustom,
    Balance,
  },
})
export default class PaymentRemittancesSummary extends Vue {
  @Prop(String) type!: string;

  @Prop({
    type: Array as () => Array<Summary>,
    required: true,
  }) items!: Array<Summary>;

  @Watch('type', { immediate: true })
  onChangeType(type: Type) {
    this.expand = type == Type.ANALYTICAL;
  }

  @Emit() reload(): boolean {
    return true;
  }

  @Emit() action(data: OpenSummaryAction): OpenSummaryAction {
    return data;
  }

  @Prop({
    type: Array as () => Array<ColumnToShow>,
  }) columns!: Array<ColumnToShow>;

  @Emit('set-columns')
  setColumns(headers: Array<IVDataTableHeader>) {
    return this.columns.map((column) => {
      const columnToShow = headers.find(({ value }) => value === column.value);
      let willShowColumn = true;

      if (columnToShow !== undefined) {
        willShowColumn = columnToShow.show ?? false;
      }

      return {
        ...column,
        show: willShowColumn,
      };
    });
  }

  @Watch('availableHeaders', { deep: true })
  emitHeaders(headers: Array<IVDataTableHeader>) {
    this.setColumns(headers);
  }

  readonly ActionType = ActionType;
  readonly groupFilterParametersEnum = GroupFilterParametersEnum;

  expand: boolean = this.type == Type.ANALYTICAL;
  expanded: Array<Summary> = [];

  open: Record<
    ActionType.EDIT
    | ActionType.VIEW_DETAILS
    | ActionType.APPROVE
    | ActionType.DISAPPROVE,
    boolean
  > = {} as Record<
    ActionType.EDIT
    | ActionType.VIEW_DETAILS
    | ActionType.APPROVE
    | ActionType.DISAPPROVE,
    boolean
    >;

  item: Summary = {} as Summary;

  availableHeaders: IVDataTableHeader[] = [
    {
      text: 'Resumo Bancário',
      value: 'title',
      show: true,
    },
    {
      text: 'Juros',
      value: 'fees',
      show: false,
      align: 'end',
    },
    {
      text: 'Desconto',
      value: 'discount',
      show: false,
      align: 'end',
    },
    {
      text: 'Acréscimo',
      value: 'addition',
      show: false,
      align: 'end',
    },
    {
      text: 'Decréscimo',
      value: 'decrease',
      show: false,
      align: 'end',
    },
    {
      text: 'Valor',
      value: 'value',
      show: true,
      align: 'end',
    },
    {
      text: 'Valor Pendente',
      value: 'pending',
      show: true,
      align: 'end',
    },
    {
      text: 'Valor Enviado',
      value: 'sent',
      show: true,
      align: 'end',
    },
  ];

  headerKeys: LooseObjectType<string> = {
    title: 'data_table_payment_remittances_summary_show_column_title',
    value: 'data_table_payment_remittances_summary_show_column_value',
    sent: 'data_table_payment_remittances_summary_show_column_sent',
    approved: 'data_table_payment_remittances_summary_show_column_approved',
    fees: 'data_table_payment_remittances_summary_show_column_fees',
    discount: 'data_table_payment_remittances_summary_show_column_discount',
    addition: 'data_table_payment_remittances_summary_show_column_addition',
    decrease: 'data_table_payment_remittances_summary_show_column_decrease',
  }

  get hasPermissionToApproveOrReprove(): boolean {
    return Boolean(this.$session.get('user_access-approve_or_reprove_remittance'));
  }

  get headers(): Array<IVDataTableHeader> {
    const showHeaders = this.availableHeaders
      .filter((h) => h.show);

    return [
      { text: '', value: 'data-table-expand', sortable: false },
      ...showHeaders,
      {
        text: '',
        value: 'column',
        align: 'end',
        sortable: false,
      },
    ];
  }

  mounted() {
    if (this.hasPermissionToApproveOrReprove) {
      this.availableHeaders.push({
        text: 'Valor Aprovado',
        value: 'approved',
        show: true,
        align: 'end',
      });
    }

    this.setHeaders();
  }

  setHeaders() {
    this.availableHeaders = FilterParameterHelper
      .defineColumnsToShowOnSpecificHeader(this.availableHeaders, this.columns);
  }

  totalValueOf(column: string): number {
    const value = column as 'value' | 'sent' | 'approved' | 'fees' | 'discount' | 'addition' | 'decrease';

    const total = this.items.reduce((prev, current) => {
      const sum = current.bankBalance[value] - current.paymentBalance[value];
      return prev + sum;
    }, 0);

    return total;
  }

  toggleexpand() {
    this.expand = !this.expand;
  }

  expandItem(item: Summary, { expand, isExpanded }: RowClickParams) {
    expand(!isExpanded);
  }

  formatCurrency(value: number): string {
    if (typeof value !== 'number') return 'Valor Inválido';

    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    });
  }

  openAction({ type, summary }: OpenSummaryAction) {
    if (type === ActionType.APPROVE || type === ActionType.DISAPPROVE) {
      this.action({ type, summary });
      return;
    }

    this.open[type] = true;
    this.item = summary;
  }

  closeAction({ type, reload }: CloseSummaryAction) {
    this.open[type] = false;
    this.item = {} as Summary;

    if (reload) {
      this.reload();
    }
  }
}
