import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800"},on:{"click:outside":_vm.closeModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[_c(VCardTitle,[_vm._t("title")],2),_c(VCardText,[_vm._t("content")],2),_vm._t("actions",[_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary darken-1"},on:{"click":_vm.closeModal}},[_vm._v(" Fechar ")])],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }