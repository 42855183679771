import axios from 'axios';
import { API_BASE } from '@config/api';
import vue from '@/main';

const locationToRedirect = '/login';

function getAuthentication() {
  const token = vue.$session.get('token');

  return token || 'none';
}

function authenticateAllRoutes(config) {
  config.headers.common.token = getAuthentication();

  return config;
}

function redirectIfUnauthorized(error) {
  if (error && error.response && error.response.status === 401) {
    sessionStorage.clear();
    window.location = locationToRedirect;
  }

  return Promise.reject(error);
}

/**
 * Build
 * funcao responsavel por definir os valores padrao para acesso das rotas do sistema
 * imediatamente apos a instancia do Vue ser criada os dados de http devem ser modificados
 */
const Client = axios.create({
  baseURL: API_BASE,
  timeout: 1800000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

/**
 * Interceptor
 * adiciona o token a requisicao
 */
Client.interceptors.request.use(authenticateAllRoutes, (error) => Promise.reject(error));

/**
 * Interceptor
 * responsavel por retornar o usuário a tela inicial
 * caso alguma requisicao retorne o codigo 401
 */
Client.interceptors.response.use((response) => response, redirectIfUnauthorized);

export default Client;
