export default class AccountPayable {
    constructor(
        public companyName: string,
        public corporateName: string,
        public tradeName: string,
        public prefix: string,
        public number: string,
        public type: string,
        public document: string,
        public value: number,
        public parcel: string,
        public increase: number,
        public decrease: number,
        public total: number,
        public balance: number,
        public nature: string,
        public emissionDate: string,
        public dueDate: string,
        public flowDate: string,
        public bankCode: string,
        public form: string,
        public interactions: Array<any>,
    ) { }

    static make(data: any): AccountPayable {
      return new AccountPayable(
        data.company_name,
        data.corporate_name,
        data.trade_name,
        data.prefix,
        data.number,
        data.type,
        data.document,
        data.value,
        data.parcel,
        data.increase,
        data.decrease,
        data.total,
        data.balance,
        data.nature,
        data.emission_date,
        data.due_date,
        data.flow_date,
        data.bank_code,
        data.form,
        data.interactions,
      );
    }
  }
