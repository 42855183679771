import Repository from '@/repositories/base/Repository';
import OrderItems from '@/domain/models/OrderItems';

export default class OrderItemsRepository extends Repository {
  public async listOrderItems(
      companyGroupId: string,
      companyId: number,
      orderInnId: string,
  ): Promise<any> {
    const { status, data: response } = await this.datasource.get(`company-groups/${companyGroupId}/orders/order-item/${orderInnId}`, {
      headers: { 'company-id': companyId },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return response.data.map(OrderItems.make);
  }
}
