import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VCard,_vm._b({staticClass:"elevation-0"},'v-card',_vm.$attrs,false),[_c(VCardTitle,[_c(VContainer,{attrs:{"fluid":""}},[_vm._t("title")],2)],1),_c(VDivider,{directives:[{name:"show",rawName:"v-show",value:(_vm.dividerOn),expression:"dividerOn"}],staticClass:"mt-0"}),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!_vm.showList),expression:"!showList"}],staticClass:"mt-n5 pa-6"},[_vm._t("body")],2),_c(VDivider,{directives:[{name:"show",rawName:"v-show",value:(_vm.actionsDividerOn),expression:"actionsDividerOn"}],staticClass:"mt-0"}),_c(VCardActions,[_vm._t("actions")],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }