import BillingMethod from '@/domain/models/BillingMethod';
import Repository from './base/Repository';

export default class BillingMethodRepository extends Repository {
  async getAll(groupId: number): Promise<BillingMethod> {
    const { status, data: response } = await this.datasource.get(`billingmethod/listAll/${groupId}`);

    if (status == 200) return response.data.map(BillingMethod.make);
    throw new Error('Ops, algo inesparado aconteceu na listagem de todas as formas de cobrança.');
  }

  async getOne(id : number): Promise<BillingMethod> {
    const { status, data: response } = await this.datasource.get(`/billingmethod/listOne/${id}`);

    if (status == 200) return BillingMethod.make(response.data);
    throw new Error('Ops, algo inesparado aconteceu na listagem da forma de cobrança.');
  }

  async save(billingMethodData: BillingMethod): Promise<boolean> {
    const { status, data: response } = await this.datasource.send('/billingmethod/save/', billingMethodData);

    if (status == 200) {
      if (!response.error) {
        return true;
      }
      throw new Error(response.message);
    }
    throw new Error('Ops, algo inesperado aconteceu na criação de uma nova forma de recebimento.');
  }

  async update(billingMethodData: BillingMethod): Promise<boolean> {
    const { status, data: response } = await this.datasource.update('/billingmethod/update/', billingMethodData);

    if (status == 200) {
      if (!response.error) {
        return true;
      }
      throw new Error(response.message);
    }
    throw new Error('Ops, algo inesperado aconteceu na atualização da forma de cobrança.');
  }

  async getAllCompanyGroup(): Promise<any> {
    const { status, data: response } = await this.datasource.get('/company/listAll/');

    if (status == 200) return response.data;
    throw new Error('Ops, algo inesperado aconteceu na listagem das companhia do grupo');
  }
}
