















































































import { defineComponent } from '@vue/composition-api';
import ERPRepository from '@/repositories/ERPRepository';
import DialogHelper from '@/helpers/DialogHelper';
import PredictedFlowHelper from '@/views/cashFlow/predictedFlow/helpers/PredictedFlowHelper';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import { VForm } from '@/types/VForm';
import ERPEventsEnum from '@/domain/enums/ERPEventsEnum';
import PredictedFlowActionsEnum from '@/views/cashFlow/predictedFlow/enums/PredictedFlowActionsEnum';
import ActionsWithoutReloadEnum from '@/views/cashFlow/predictedFlow/enums/ActionsWithoutReloadEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';
import IIncludeDecreaseExternalERPRequestParams from '@/views/cashFlow/predictedFlow/interfaces/actions/IIncludeDecreaseExternalERPRequestParams';
import IIncludeIncreaseExternalERPRequestParams from '@/views/cashFlow/predictedFlow/interfaces/actions/IIncludeIncreaseExternalERPRequestParams';
import IUpdateInternalTablesRequestParams from '@/repositories/parameters/IUpdateInternalTablesRequestParams';
import IPredictedFlowGrouperItemData from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperItemData';

export default defineComponent({
  name: 'IncludeIncreaseDecreaseDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    register: {
      required: false,
      type: Object as () => IPredictedFlowGrouperList,
    },
    itemIndex: {
      required: false,
      type: Number,
    },
    totalIndex: {
      required: false,
      type: Number,
    },
  },
  computed: {
    dialogWidth(): string {
      return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
        smAndDown: '100%',
        md: '60%',
        default: '40%',
      });
    },
    tableName(): string {
      return PredictedFlowHelper.getTableBasedOnNcc(this.register!, Boolean(this.$session.get('use_ncc')));
    },
    itemData(): IPredictedFlowGrouperItemData {
      return this.register!.itemData!;
    },
    grouperType(): CashFlowTablesEnum {
      return this.register!.grouperType;
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const inputRules: InputRulesHelper = new InputRulesHelper();
    const erpRepository: ERPRepository = new ERPRepository();

    const type: ERPEventsEnum|null = null;
    const financialValue: string = '';

    const loading: boolean = false;
    const isOpen: boolean = this.value;

    const options: ISelectOptions<ERPEventsEnum>[] = [
      { text: 'Acréscimo', value: ERPEventsEnum.INCREASE },
      { text: 'Decréscimo', value: ERPEventsEnum.DECREASE },
    ];

    return {
      inputRules,
      erpRepository,
      type,
      financialValue,
      loading,
      isOpen,
      options,
    };
  },
  methods: {
    async includeIncreaseOrDecrease(): Promise<void> {
      const increaseOrDecreaseText = '';

      try {
        this.loading = true;

        const response = await Promise.allSettled([
          this.updateExternalERPRequest(),
          this.updateInternalERPRequest(true),
          this.updateInternalERPRequest(),
        ]);

        if (!response.every(({ status }) => status === 'fulfilled')) {
          throw new Error();
        }

        this.$notification.success(`${increaseOrDecreaseText} incluido com sucesso!`);

        this.$store.commit('predicted_flow/triggerEventSend', {
          action: ActionsWithoutReloadEnum.include_increase_decrease,
          itemIndex: this.itemIndex,
          data: {
            type: this.type,
            value: Number(this.financialValue),
            totalIndex: this.totalIndex,
          },
        });

        this.close();
      } catch (error) {
        this.$notification.error(`Houve um problema ao incluir um ${increaseOrDecreaseText}`);
      } finally {
        this.loading = false;
      }
    },
    async updateExternalERPRequest(): Promise<void> {
      const numberFinancialValue = Number(this.financialValue);
      let externalData: IIncludeDecreaseExternalERPRequestParams
        |IIncludeIncreaseExternalERPRequestParams
        |null = null;

      if (this.type === 'increase') {
        externalData = {
          added_value: numberFinancialValue,
          addition_balance: numberFinancialValue,
          type: this.tableName,
        } as IIncludeIncreaseExternalERPRequestParams;
      } else {
        externalData = {
          decrease_balance: numberFinancialValue,
          decrease_value: numberFinancialValue,
          type: this.tableName,
        } as IIncludeDecreaseExternalERPRequestParams;
      }

      await this.erpRepository.updateExternalERP<
          IIncludeDecreaseExternalERPRequestParams
          |IIncludeIncreaseExternalERPRequestParams
        >(
          this.companyGroupId,
          this.itemData.companyId,
          {
            id: this.itemData.idCustomer,
            event: this.type!,
            data: externalData,
          },
        );
    },
    async updateInternalERPRequest(isSaleType: boolean = false): Promise<void> {
      const numberFinancialValue = Number(this.financialValue);
      const fields = this.getFieldNameBasedOnAccountTypeAndSaleType();
      const originalTableAlias = PredictedFlowHelper
        .getTableAliasByGrouperType(this.grouperType);

      let internalData: IUpdateInternalTablesRequestParams|null = null;

      if (this.type === ERPEventsEnum.INCREASE) {
        internalData = {
          id: this.itemData.id,
          identify_values: originalTableAlias,
          field: isSaleType ? fields.increase : fields.increaseSale,
          value: numberFinancialValue,
        };
      } else {
        internalData = {
          id: this.itemData.id,
          identify_values: originalTableAlias,
          field: isSaleType ? fields.decrease : fields.decreaseSale,
          value: numberFinancialValue,
        };
      }

      await this.erpRepository.updateInternalERP(internalData);
    },
    getFieldNameBasedOnAccountTypeAndSaleType(): {
      increase: string;
      increaseSale: string;
      decrease: string;
      decreaseSale: string;
    } {
      if (this.grouperType === CashFlowTablesEnum.ACCOUNTS_PAYABLE) {
        return {
          increase: 'e2_sdacres',
          increaseSale: 'e2_acresc',
          decrease: 'e2_sddecre',
          decreaseSale: 'e2_decresc',
        };
      }

      return {
        increase: 'e1_sdacres',
        increaseSale: 'e1_acresc',
        decrease: 'e1_sddecre',
        decreaseSale: 'e1_decresc',
      };
    },
    close(): void {
      this.$emit('close', PredictedFlowActionsEnum.include_increase_decrease);
    },
    handleIncludeOrDecrease(): void {
      const increaseOrDecreaseForm = this.$refs.IncreaseOrDecreaseForm as VForm;

      if (!increaseOrDecreaseForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');
        return;
      }

      this.includeIncreaseOrDecrease();
    },
  },
});
