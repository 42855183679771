<template>
  <div>
    <!--begin::Modal-->
    <div
      class="modal fade"
      id="modalCnabReplicate"
      tabindex="-1"
      role="dialog"
      aria-labelledby="m_viewInformation"
      aria-hidden="false"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title_data_save_view">Replicar configuração CNAB</h5>
          </div>
          <form class="m-form m-form--fit m-form--label-align-right">
            <div id="data_view_information" class="modal-body">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Database</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Database"
                    id="database"
                    :name="database"
                    :value="database"
                    required
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Grupo de destino</label>
                  <select
                    class="form-control select"
                    :items="vsgroups"
                    v-model="group_destination"
                    id="cnab"
                    style="width: 100%"
                  >
                    <option v-for="vgroup in vsgroups" :value="vgroup" :key="vgroup.id">
                      {{ vgroup.id }} - {{ vgroup.description }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn m-btn--air btn-success"
                v-on:click="processDuplicateCnab()"
              >
                Replicar
              </button>
              <button
                type="button"
                class="btn m-btn--air btn-primary"
                id="btnModalClose"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      database: "fluxocaixa",
      group_origin: 0,
      group_destination: 0,
      vsgroups: [{ id: 1, description: "asdasds" }]
    };
  },
  methods: {
    openModal(group_origin) {
      this.group_origin = group_origin.id;

      if (parseInt(this.$session.get("is_admin")) == 1) {
        blockPage("Carregando os dados...");

        this.$http
          .get(this.$store.state.API_BASE + "companygroup/listAll", {
            headers: { token: this.$session.get("token") }
          })
          .then(
            function(result) {
              unblockPage();
              this.vsgroups = result.body.data;

              this.vsgroups.forEach((group, index) => {
                if (parseInt(group.id) == parseInt(this.$session.get("company_group_id"))) {
                  this.vsgroups.splice(index, 1);
                }
              });

              $("#modalCnabReplicate").modal("show");
            },
            function(error) {
              unblockPage();
              showNotification(
                "Atenção",
                "Opa! Parece que algo saiu errado, tente recarregar a página",
                "danger"
              );
            }
          );
      } else {
        this.vsgroups = [
          {
            id: this.$session.get("company_group_id"),
            description: this.$session.get("company_group_name"),
          }
        ];
        $("#modalCnabReplicate").modal("show");
      }
    },
    processDuplicateCnab() {
      blockPage('Processando ...');
      this.$http
        .post(
          `${this.$store.state.API_BASE}cnabremittance/${this.group_origin}/copytogroup/${this.group_destination.id}?database=${database.value}`,
          null,
          { headers: { token: this.$session.get("token") } }
        )
        .then(
          result => {
            showNotification("Atenção", "Configuração duplicada com sucesso!", "success");
            unblockPage();
            this.loadCnabRemittances();
          },
          error => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    }
  }
};
</script>
