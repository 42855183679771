import PreNegotiationStatusOptionsEnum from '@/views/preNegotiation/enums/PreNegotiationStatusOptionsEnum';

export default class PreNegotiationList {
  constructor(
    public id: number,
    public name: string,
    public numberOfAccounts: number,
    public expirationDate: string,
    public totalValue: number,
    public status?: PreNegotiationStatusOptionsEnum,
  ) {}

  static make(data : any): PreNegotiationList {
    return new PreNegotiationList(
      data.pre_negotiation_id,
      data.name,
      data.number_of_accounts,
      data.pre_negotiation_expiration_date,
      data.total_value,
      data.status,
    );
  }
}
