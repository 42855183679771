

















import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import Permission from '@/domain/models/Permission';

@Component
export default class PermissionItemsList extends Vue {
  @Prop() readonly permission!: Permission;

  @Prop(String) readonly textClass!: string;
}
