













































































import Component, { mixins } from 'vue-class-component';
import StartLoadingMixin from '@/mixins/ts/StartLoadingMixin';
import UserPartial from '@/domain/models/UserPartial';
import UserRepository from '@/repositories/UserRepository';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import SelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class Users extends mixins(StartLoadingMixin) {
  public filterTerm: string = '';
  public filterStatus: string = '';

  public users: UserPartial[] = [];
  readonly userReposity: UserRepository = new UserRepository();
  readonly statusItems: SelectOptions[] = [
    { value: '', text: 'Todos os status' },
    { value: '0', text: 'Inativos' },
    { value: '1', text: 'Ativos' },
  ];
  readonly headers: IVDataTableHeader[] = [
    {
      text: 'Status',
      value: 'status',
      filterable: false,
      sortable: false,
    },
    {
      text: 'Nome',
      value: 'name',
      filterable: false,
      sortable: false,
    },
    {
      text: 'E-mail',
      value: 'email',
      filterable: false,
      sortable: false,
    },
    {
      text: '',
      value: 'edit',
      filterable: false,
      class: 'edit-columns',
      cellClass: 'edit-columns',
      sortable: false,
    },
  ];

  public get filteredUsers(): UserPartial[] {
    return this.users.filter(this.filterByStatus).filter(this.filterByTerm);
  }

  public mounted(): void {
    this.userReposity
      .listAll()
      .then((users) => {
        this.users = users;
      })
      .catch((error) => {
        this.$notification.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public filterByTerm(user: UserPartial): boolean {
    const filterTerm = this.filterTerm.toLowerCase();
    return filterTerm != ''
      ? user.name.toLowerCase().includes(filterTerm)
          || user.email.toLowerCase().includes(filterTerm)
      : true;
  }

  public filterByStatus(user: UserPartial): boolean {
    const status = this.filterStatus;
    return status != '' ? user.status == (this.filterStatus === '1') : true;
  }

  public handleNewUser(): void {
    this.$router.push({ name: 'UserCreate' });
  }

  public handleEdit(userId: number): void {
    this.$router.push({
      name: 'UserEdit',
      params: {
        id: userId.toString(),
      },
    });
  }

  getStatusColor(status: boolean): string {
    return status ? 'success' : '';
  }

  getStatusText(status: boolean): string {
    return status ? 'Ativo' : 'Inativo';
  }
}
