





































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';

import ActionType from '@/domain/enums/StatementConciliationActionType';
import StatusType from '@/domain/enums/StatementConciliationStatusType';
import DeconciliationType from '@/domain/enums/DeconciliationType';
import DeconciliationMovements from '@/domain/models/DeconciliationMovements';

import IDeconciliation from '@/domain/interfaces/IDeconciliation';

import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';

import { DeconciliationData } from '../utils/interfaces';
import { formatErrorForNotification } from '../utils';

@Component({})
export default class StatementConciliationDeconciliation extends Vue {
  @Prop(Boolean) readonly open!: boolean;

  @Prop({
    type: Object as () => DeconciliationData,
  }) readonly data!: DeconciliationData;

  @Emit()
  close(): ActionType {
    return ActionType.UNDO;
  }

  @Emit()
  reload(): boolean {
    return true;
  }

  @Emit()
  onDeconciliate(desconciliationMovements: DeconciliationMovements): DeconciliationMovements {
    return desconciliationMovements;
  }

  readonly StatementConciliationRepository
    : StatementConciliationRepository = new StatementConciliationRepository();

  getType(status: StatusType): DeconciliationType {
    let type = DeconciliationType.CONCILIATION;

    switch (status) {
      case StatusType.ERP_CONCILIATED:
        type = DeconciliationType.ERP_MOVEMENT;
        break;
      case StatusType.DIVERGENCE:
        type = DeconciliationType.DIVERGENT_CONCILIATION;
        break;
      default:
        type = DeconciliationType.CONCILIATION;
        break;
    }

    return type;
  }

  save() {
    this.deconciliation();
  }

  async deconciliation(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const companyGroupId = this.$session.get('company_group_id');

      const {
        id, status, dispositionDate, companyId, bankId,
      } = this.data;

      const type = this.getType(status);

      const deconciliationData: IDeconciliation = {
        id,
        type,
        disposition_date: dispositionDate,
        bank_id: bankId,
      };

      const desconciliationMovements = await this.StatementConciliationRepository
        .deconciliation(companyGroupId, companyId, deconciliationData);

      desconciliationMovements.deconciliatedConciliationId = id;

      this.$notification.success('Registro desconciliado com sucesso.');
      this.$dialog.stopLoading();
      this.onDeconciliate(desconciliationMovements);
      this.close();
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
