<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Configurações de Ações de Conciliação Bancária"/>
			<page-card dividerOn>
				<template #title>
					<v-row>
						<v-col cols="12" lg="4" md="4" sm="12" xs="12">
							<v-select 
								v-model="configs.filterStatus"
								filled
								hide-details
								label="Filtrar por status"
								:items="filterStatusItems"/>
						</v-col>
						<v-col cols="12" lg="6" md="5" sm="12" xs="12">
							<v-text-field
								filled
								hide-details
								prepend-inner-icon="mdi-magnify"
								label="Pesquisar"
								v-model="configs.filterTerm"
							/>
						</v-col>
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<v-btn
								@click="add"
								class="font-weight-bold"
								x-large
								block
								depressed
								color="primary"
							>
								ADICIONAR
							</v-btn>    
						</v-col>
					</v-row>
				</template>
				<template #body>
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Descrição</th>
								<th>Banco / Empresa</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="conciliationactionconfig in listData" :key="conciliationactionconfig.id">
								<td style="vertical-align: middle;">
										<v-chip
										v-if="conciliationactionconfig.status == 0"
										dense
										small>
										Inativo
									</v-chip>
									<v-chip
										v-else
										dense
										small
										color="green"
										text-color="white">
										Ativo
									</v-chip>
								</td>
								<td style="vertical-align: middle;">{{ conciliationactionconfig.description }}</td>
								<td style="vertical-align: middle;">{{ getDescriptionConfig( conciliationactionconfig )}}</td>
								<td class="m--align-right">
									<button-tooltip
										fab
										icon
										small
										buttonIcon="mdi-pencil"
										tooltipText="Editar"
										@click="edit(conciliationactionconfig)"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</page-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash';
	import { API_BASE } from '@config/api'

	export default {
		data () {
			return {
				conciliationactionconfigs: [],
				configs: {
					filterTerm: '',
					filterStatus: ''
				},
				filterStatusItems: [
					{
						text: "Todos os status",
						value: "",
					},
					{
						text: "Inativo",
						value: "0",
					},
					{
						text: "Ativo",
						value: "1",
					},
				]
			}
		},

		computed: {
			listData() {
				let dataRet = this.conciliationactionconfigs;

				if (this.configs.filterStatus) {
					dataRet = _.filter(dataRet, data => data.status == this.configs.filterStatus)
				}

				if (this.configs.filterTerm) {
					dataRet = _.filter(dataRet, list => list.description.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 || list.code_bank.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0)
				}

				if (this.conciliationactionconfigs.name) {
					dataRet = _.filter(dataRet, list => list.name.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0)
				}

				return dataRet;
			}
		},

		mounted: function () {
				this.loadConfigs();
		},

		methods: {
			loadConfigs: function () {
				blockPage('Carregando os dados...');

				this.$http.get(`${API_BASE}conciliation/action/listAll`, {headers: {'token': this.$session.get('token')}}).then(
					(result) => {
							this.conciliationactionconfigs = result.body.data;
					unblockPage();
					},
					(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				)
			},
			getDescriptionConfig: function (config) {
				return 'Banco: ' + config.code_bank +  ' / Empresa: ' + (config.name || 'Todas');
			},
			add() {
				this.$router.push('/conciliationactionconfig/new');
			},
			edit(conciliationactionconfig) {
				const params = {
					conciliation_action_config_id: conciliationactionconfig.id,
				};
				this.$router.push({name: 'newconciliationactionconfig',params});
			},
		}
	}
</script>