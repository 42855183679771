<template>
  <v-row class="pa-11">
    <v-col class="pa-0">
      <v-card class="elevation-0" :loading="loading">
        <v-form ref="formRegisterAcquittanceReason">
          <v-card-title>
            <v-btn
							icon
							fab
							small
							@click="$router.go(-1)"
							class="mx-2">
								<v-icon>
								mdi-arrow-left
								</v-icon>
						</v-btn>
            <h4 class="text-h6 font-weight-bold ml-4 mt-2">
            {{ pageTitle }}
            </h4>
            <v-spacer />
            <v-btn
							class="elevation-0 mr-2"
							color="disable"
              large
							@click="$router.go(-1)">
								<v-icon class="mr-2">
									mdi-close
								</v-icon>
								Cancelar
						</v-btn>
						<v-btn
							v-show="saveButton"
							class="elevation-0"
							color="info"
              large
							:loading="loadingButton"
							@click="onSubmit">
								<v-icon class="mr-2">
									mdi-check
								</v-icon>
								Salvar
						</v-btn>
						<v-btn
							v-show="updateButton"
							class="elevation-0"
							color="info"
							:loading="loadingButton"
							@click="onUpdate">
								<v-icon class="mr-2">
									mdi-check
								</v-icon>
								Salvar
						</v-btn>
          </v-card-title>
          <v-divider class="mt-0" />
          <v-card-text class="mt-9">
            <v-row>
              <v-col cols="12" lg="4" md="12">
                <span>Selecione uma forma de vinculo</span>
                <v-select
                  v-model="acquittanceReason.companyType"
                  class="mt-2"
                  :rules="[rules.required]"
                  outlined
                  dense
                  small-chips
                  :disabled="updateFields"
                  :items="options"
                  item-text="name"
                  item-value="value"
                  @change="onCompanyTypeChange"
                />
              </v-col>
							<v-col cols="12" lg="8" md="12">
                <span>
									{{acquittanceReason.companyType === "G"? "Grupo de e": "E"}}mpresas
								</span>
                <div v-if="acquittanceReason.companyType === 'G'">
                  <v-select
                    v-model="acquittanceReason.group"
                    :items="enterprisesList"
                    class="mt-2"
                    outlined
                    small-chips
                    dense
                    :rules="[rules.required]"
                    :disabled="updateFields"
                    item-text="description"
                    item-value="id"
                  />
                </div>
                <div v-else>
                  <v-select
                    v-model="acquittanceReason.group"
                    :items="enterprisesList"
                    class="mt-2"
                    outlined
                    small-chips
                    :rules="[rules.required]"
                    dense
                    :disabled="updateFields"
                    multiple
                    item-text="name"
                    item-value="id"
                  />
                </div>
              </v-col>
							<v-col cols="12" lg="3" md="3">
                <span>Status</span>
                <v-select
                  v-model="acquittanceReason.status"
                  class="mt-2"
                  outlined
                  :rules="[rules.required]"
                  dense
                  :items="statusSelect"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
              <v-col cols="12" lg="3" md="3">
                <span>Código ERP</span>
                <v-text-field
                  v-model="acquittanceReason.erpCode"
                  class="mt-2"
                  outlined
                  maxlength="3"
                  dense
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" lg="6" md="6">
                <span>Descrição</span>
                <v-text-field
                  v-model="acquittanceReason.description"
                  class="mt-2"
                  outlined
                  maxlength="50"
                  :rules="[rules.required]"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <div v-show="updatePage">
            <v-col cols="12" lg="3" md="3">
              <v-btn
                small
                @click="save"
                class="mr-3 elevation-0"
                color="green"
                fab>
								<v-icon style="color: white">
									{{acquittanceReason.id ? "mdi-check" : "mdi-plus"}}
								</v-icon>
              </v-btn>
              {{ acquittanceReason.id ? "Salvar" : "Adicionar" }}
            </v-col>
            <v-col cols="12" lg="3" md="3">
              <v-btn
                v-show="acquittanceReason.id ? true : false"
                small
                @click="clear"
                class="mr-3 elevation-0"
                color="red"
                fab
              >
                <v-icon style="color: white"
                  >mdi-window-close
                </v-icon>
              </v-btn>
              <span v-show="acquittanceReason.id ? true : false">
								Cancelar
							</span>
            </v-col>
            <v-data-table
              v-model="acquittanceReason.items"
              class="elevation-0 pa-4 mt-9"
              :items="items"
              :headers="headers"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:[`item.companyType`]="{ item }">
                {{ item | idToName(options, "companyType") }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
								<v-chip
									v-if="item.status === 'I'"
									class="ma-1">
										{{item | idToName(statusSelect, "status")}}
								</v-chip>
								<v-chip
									v-else
									class="ma-2"
									color="green"
									text-color="white">
										{{item | idToName(statusSelect, "status")}}
								</v-chip>
              </template>
              <template v-slot:[`item.group`]="{ item }">
                {{filterMixin_company(item)}}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn @click="edit(item)" icon class="mr-5">
                  <v-icon> mdi-pencil-outline </v-icon>
                </v-btn>
                <v-btn @click="remove(item)" icon>
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { v1 as uuid } from 'uuid';
// eslint-disable-next-line
import AcquittanceReasonService from "@/services/acquittancereason.service";
// eslint-disable-next-line
import CompanyService from "@/services/company.service";
// eslint-disable-next-line
import CompanyGroupService from "@/services/company_group.service";
import FilterMixin from './FilterMixin.vue';

export default {
  name: 'AcquittanceReason',
  mixins: [FilterMixin],
  data() {
    return {
      pageTitle: 'Cadastrar',
      updatePage: true,
      saveButton: true,
      updateFields: false,
      updateButton: false,
      acquittanceReason: {},
      defaultAcquittanceReason: {
        id: null,
        description: '',
        status: null,
        erpCode: '',
      },
      loading: false,
      headers: [
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Vinculo',
          value: 'companyType',
          sortable: false,
          width: '15%',
        },
        {
          text: 'Empresa',
          value: 'group',
          sortable: false,
          width: '25%',
        },
        {
          text: 'Código ERP',
          value: 'erpCode',
          sortable: false,
        },
        {
          text: 'Descricao',
          value: 'description',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
					width: '15%',
        },
      ],
      items: [],
      enterprisesGroupSelect: [],
      enterprisesSelect: [],
      enterprisesList: [],
      statusSelect: [
        { name: 'Ativo', id: 1, value: 'A' },
        { name: 'Inativo', id: 2, value: 'I' },
      ],
      options: [
        { name: 'Grupo de empresas', id: 1, value: 'G' },
        { name: 'Empresas', id: 2, value: 'C' },
      ],
      status: null,
      loadingButton: false,
      rules: {
        required: (value) => !!value || 'Campo obrigatório',
      },
    };
  },

  async mounted() {
    if (this.$route.params.id) {
      this.pageTitle = 'Editar';
      this.updatePage = false;
      this.updateFields = true;
      this.saveButton = false;
      this.updateButton = true;
      this.id = this.$route.params.id;
      await this.loadById(this.id);
    }
  },

  async created() {
    try {
      this.loading = true;
			await this.loadCompanies();
      await this.loadGroup();
    } catch (error) {
				this.$helpers.defaultCatchError(error);
    } finally {
          this.loading = false;
    }
  },

  methods: {
    async loadById(id) {
      const acquittanceReason = new AcquittanceReasonService();
      const result = await acquittanceReason.listOne().show(id);
      this.acquittanceReason = result.data;
      await this.loadUpdate();
    },
    async loadCompanies() {
      const companyService = new CompanyService();
      const response = await companyService.index();
      this.enterprisesSelect = response.data;
    },
    async loadGroup() {
      const companyGroupService = new CompanyGroupService();
      const response = await companyGroupService.index();
      this.enterprisesGroupSelect = [response.data];
    },
    async onSubmit() {
      if (this.items.length === 0) {
      // eslint-disable-next-line
        showNotification('Atenção', 'Campos vazios', 'danger');
      } else {
          try {
						const acquittanceReason = new AcquittanceReasonService();
            const result = await acquittanceReason
            .save()
            .store({ parameters: this.items });
            if (result.error == true) {
            // eslint-disable-next-line
            showNotification('Atenção', result.message, 'danger');
            } else {
              // eslint-disable-next-line
              showNotification('Sucesso', result.message, 'success');
              this.$router.push('/acquittancereason');
            }
          } catch (error) {
						this.$helpers.defaultCatchError(error);
          } finally {
            this.loadAcquittanceTable = false;
            this.$router.push('/acquittancereason');
          }
      }
    },

    async loadUpdate() {
      if (this.acquittanceReason.companyType == 'G') {
        const companyGroupService = new CompanyGroupService();
        const response = await companyGroupService.listOne()
        .show(this.acquittanceReason.companyTypeId);
        this.enterprisesList = this.enterprisesGroupSelect;
        // eslint-disable-next-line
        this.acquittanceReason.group = response.data[0];
      } else {
          const companyService = new CompanyService();
          const response = await companyService.listOne()
          .show(this.acquittanceReason.companyTypeId);
          this.enterprisesList = this.enterprisesSelect;
          this.acquittanceReason.group = response.data;
      }
    },

    async onUpdate() {
      if (!this.$refs.formRegisterAcquittanceReason.validate()) return;
        try {
          const parameters = {
          id: this.id,
          status: this.acquittanceReason.status,
          description: this.acquittanceReason.description,
          erpCode: this.acquittanceReason.erpCode,
					};
          const acquittanceReason = new AcquittanceReasonService();
          const result = await acquittanceReason.update().send(parameters);
          if (result.error == true) {
						// eslint-disable-next-line
						showNotification('Atenção', result.message, 'danger');
					} else {
							// eslint-disable-next-line
              showNotification('Sucesso', result.message, 'success');
              this.$router.push('/acquittancereason');
          }
        } catch (error) {
						this.$helpers.defaultCatchError(error);
        }
      },

    save() {
      if (this.acquittanceReason.id) {
        this.update();
      } else {
        this.add();
      }
    },
    add() {
      if (!this.$refs.formRegisterAcquittanceReason.validate()) return;
				this.acquittanceReason.id = uuid();
        this.items.push({ ...this.acquittanceReason });
        this.clear();
      },
    edit(item) {
      this.acquittanceReason = { ...item };
      this.changeCompanyTypeSelect();
    },
    update() {
      if (!this.$refs.formRegisterAcquittanceReason.validate()) return;
        this.items = this.items.map((acquittanceReason) => {
        if (acquittanceReason.id == this.acquittanceReason.id) {
          return { ...this.acquittanceReason };
        }
      return acquittanceReason;
      });
			this.clear();
    },
    remove(item) {
      this.items = this.items.filter((acquittanceReason) => {
      if (acquittanceReason.id == item.id) {
        return false;
      }
      return true;
			});
    },
    clear() {
      this.acquittanceReason = {
      ...this.acquittanceReason,
      ...this.defaultAcquittanceReason,
			};
			this.$refs.formRegisterAcquittanceReason.reset();
		},
		changeCompanyTypeSelect() {
			if (this.acquittanceReason.companyType === 'G') {
        this.enterprisesList = [...this.enterprisesGroupSelect];
        return;
      }
      this.enterprisesList = [...this.enterprisesList];
    },
    onCompanyTypeChange(event) {
      if (event === 'G') {
        this.enterprisesList = [...this.enterprisesGroupSelect];
        this.acquittanceReason.group = [];
        return;
      }
      this.enterprisesList = [...this.enterprisesSelect];
      this.acquittanceReason.group = [];
    },
  },

  filters: {
    idToName(item, options, itemKey, listKey, listName) {
			try {
				if (!item) {
					return '';
				}
				if (Array.isArray(item[itemKey])) {
					return item[itemKey]
					.map((current) => options.reduce((optPrevious, optCurrent) => {
					if (optCurrent[listKey || 'value'] === current) {
						return optCurrent[listName || 'name'];
					}
					return optPrevious;
					}, '')).join(', ');
				}
				return options.reduce((previous, current) => {
					if (current[listKey || 'value'] === item[itemKey]) {
						return current[listName || 'name'];
					}
					return previous;
				}, '');
			} catch (error) {
					this.$helpers.defaultCatchError(error);
					return '';
			}
		},
  },
};
</script>
