<template>
    <v-menu :close-on-content-click="false" :nudge-width="200" bottom :offset-y="false">
        <template v-slot:activator="{ on }">
            <v-btn icon large v-on="on">
                <v-avatar size="30">
                    <img v-if="avatar" :src="avatar" alt="avatar" />
                    <img v-if="!avatar" src="@/assets/user-placeholder.png" alt="avatar" />
                </v-avatar>
            </v-btn>
        </template>

        <account-manager />
    </v-menu>
</template>

<script>
import AccountManager from './AccountManager.vue';

export default {
	name: 'UserProfileMenu',
	components: {
		AccountManager,
	},
	data: () => ({
		avatar: null,
	}),
	created() {
		this.avatar = this.$session.get('user_gravatar');
	},
};
</script>

<style scoped>

.in-btn_sync {
	transform: translate(-55px, 40px);
	position: absolute;
	color: #757575;
}

.v-menu__content {
	top: 50px !important;
	max-width: 440px;
}

.v-list-item__icon {
	margin: 16px 16px !important;
}
</style>
