<template>
    <div>
        <div id="params" class="m-portlet m-portlet--mobile">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <h3 id="teste" class="m-portlet__head-text">
                            Parâmetros
                        </h3>
                    </div>
                </div>
                <div class="m-portlet__head-tools">
                    <ul class="m-portlet__nav">
                        <li class="m-portlet__nav-item">
                            <button type="button" class="btn m-btn--air btn-success"
                                    @click="prepareGenerateCashFlowRealized()">Gerar fluxo
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="m-portlet__body">
                <!--begin: Search Form -->
                <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                    <div class="row align-items-center">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>Data inicial</label>
                                <div class="input-group date" id="dateInitial">
                                    <input type="text" class="form-control m-input text-center" readonly=""
                                           placeholder="Selecione a data">
                                    <span class="input-group-addon">
										<i class="la la-calendar-check-o"></i>
									</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>Data final</label>
                                <div class="input-group date" id="dateFinish">
                                    <input type="text" class="form-control m-input text-center" readonly=""
                                           placeholder="Selecione a data">
                                    <span class="input-group-addon">
										<i class="la la-calendar-check-o"></i>
									</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Tipo relatório</label>
                                <select class="form-control select2 hide" field-name="Tipo relatório" id="report_type"
                                        name="report_type" style="width: 100%;">
                                    <option value="A">Analítico</option>
                                    <option value="S">Sintético</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Periodicidade</label>
                                <select class="form-control select2 hide" field-name="Periodicidade" id="periodicity"
                                        name="periodicity" style="width: 100%;">
                                    <option value="weekly">Semanal</option>
                                    <option value="monthly">Mensal</option>
                                    <option value="semiannual">Semestral</option>
                                    <option value="yearly">Anual</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Empresa</label>
                                <select class="form-control select2Company hide" field-name="Empresa" id="company_id" name="company_id[]" style="width: 100%;" multiple="multiple">
                                    <option v-for="(company, idx) in companys" :value="company.id" :key="idx">{{ company.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end: Search Form -->
            </div>
        </div>

        <div id="fluxo" class="m-portlet m-portlet--mobile" v-if="isSearchComplete">

            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <h3 class="m-portlet__head-text">Fluxo de Caixa Projetado</h3>
                    </div>
                </div>
                <div class="m-portlet__head-tools" v-if="!viewBillingOnRealized">
                    <ul class="m-portlet__nav">
                        <li class="m-portlet__nav-item" aria-expanded="true">
                            <button type="button"
                                    class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                                    style="width: 44px;height: 44px;" data-toggle="m-tooltip" data-placement="bottom"
                                    data-original-title=" Imprimir o fluxo " @click="openModalPrint()">
                                <i class="la la-print" style="font-size:25px;"></i>
                            </button>
                            <print-modal ref="printModal" :active-dates="allDatesPrint" @close="showModalPrint = false"
                                         v-if="showModalPrint"
                                         tittle-modal-info="Impressão">
                                <div slot="footer">
                                    <button type="button" class="btn m-btn--air btn-info"
                                            @click="$refs.printModal.print(cashFlowData,cashFlowBillingData,selfComponent)">
                                        Imprimir
                                    </button>
                                    <button type="button" class="btn m-btn--air btn-primary"
                                            @click="$refs.printModal.hide()">
                                        Fechar
                                    </button>
                                </div>
                            </print-modal>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="m-portlet__body" id="fluxo-body">
                <div class="row header-inncash" v-if="!viewBillingOnRealized">
                    <div class="row sub-header-inncash w-100 m-0">
                        <div class="col-sm-2" style="padding-right:0;padding-left:0;">&nbsp;</div>
                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;padding-top:0;">
                            <button type="button" class="btn m-btn--pill btn-secondary btn-sm"
                                    @click="previousDate" v-if="cashFlowHiddenDates.length > 0">
                                <i class="la la-angle-left" style="padding-top:5px;"></i>
                            </button>
                        </div>
                        <div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">
                            <div class="row rangeDates" style="padding-right:0;padding-left:0;">
                                <div class="col-sm-1" style="padding-right:0;padding-left:0;">&nbsp;</div>

                                <div class="col-sm-2 dates containerDate" :date-value="date.dateHidden"
                                     :data-date="date.dateHidden" :data-date-br="date.date"
                                     style="padding-right:0;padding-left:30px;" v-for="(date, idxDt) in cashFlowActiveDates" :key="idxDt">
                                    <span v-if="periodicityParamSearch != 'weekly'">{{date.date}}</span>
                                    <br v-if="periodicityParamSearch != 'weekly'"/>
                                    <span v-html="date.description"></span>
                                </div>
                                <div class="col-sm-2 dates containerDate" :date-value="date.dateHidden"
                                     :data-date="date.dateHidden" :data-date-br="date.date"
                                     style="padding-right:0;padding-left:30px;display:none;"
                                     v-for="(date, idxDt) in cashFlowHiddenDates" :key="idxDt">
                                    <span v-if="periodicityParamSearch != 'weekly'">{{date.date}}</span>
                                    <br v-if="periodicityParamSearch != 'weekly'"/>
                                    <span v-html="date.description"></span>
                                </div>

                                <div class="col-sm-1" style="padding-right:0;padding-left:0;">&nbsp;</div>

                            </div>
                        </div>
                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;padding-top:0;">
                            <button type="button" class="btn m-btn--pill btn-secondary btn-sm"
                                    @click="nextDate" v-if="cashFlowHiddenDates.length > 0">
                                <i class="la la-angle-right" style="padding-top:5px;"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <collapse-div-main name="col_1"
                                   :type-level="'levelMain'"
                                   :all-data="cashFlowData"
                                   :data-rows="levelMain"
                                   :span-description="levelMain.description"
                                   :cash-flow-hidden-dates="cashFlowHiddenDates"
                                   :child-name="'col_' + levelMain.type + indexLevelMain"
                                   :type="levelMain.type"
                                   :key="levelMain.type"
                                   :allDates="cashFlowAllDates"
                                   v-for="(levelMain, indexLevelMain) in cashFlowData"
                                   v-on:recalculeTotal="recalculeTotal">

                    <collapse-div-one name="col_1" :type-level="'levelOne'" :data-rows="levelOne.level_one"
                                      :span-description="levelOne.level_one.description"
                                      :cash-flow-hidden-dates="cashFlowHiddenDates"
                                      v-for="(levelOne,indexLevelOne) in levelMain.data"
                                      :child-name="'col_' + levelMain.type + indexLevelOne"
                                      :slot="'col_' + levelMain.type + indexLevelMain"
                                      :key="levelOne.id">

                        <collapse-div-two name="col_2"
                                          :slot="'col_' + levelMain.type + indexLevelOne"
                                          :type-level="'levelTwo'" :data-rows="levelTwo"
                                          :cash-flow-hidden-dates="cashFlowHiddenDates"
                                          :span-description="levelTwo.description"
                                          :child-name="'col_' + levelMain.type + indexLevelOne + indexLevelTwo"
                                          :allDates="cashFlowAllDates"
                                          v-for="(levelTwo, indexLevelTwo) in levelOne.level_two" :key="levelTwo.id"
                                          v-show="nivel == 'two' || nivel == 'three' || nivel == 'four'"
                                          v-on:recalculeTotal="recalculeTotal">

                            <collapse-div-three name="col_2"
                                                :slot="'col_' + levelMain.type + indexLevelOne + indexLevelTwo"
                                                :type-level="'levelThree'" :data-rows="levelThree"
                                                :cash-flow-hidden-dates="cashFlowHiddenDates"
                                                :span-description="levelThree.description"
                                                :identify-values="levelThree.identifyValues"
                                                :child-name="'col_' + levelMain.type + indexLevelOne + indexLevelTwo + indexLevelThree"
                                                v-for="(levelThree,indexLevelThree) in levelTwo.level_three"
                                                :key="levelThree.id" v-show="nivel == 'three' || nivel == 'four'">


                                <collapse-div-four name="col_2"
                                                   :slot="'col_' + levelMain.type + indexLevelOne + indexLevelTwo + indexLevelThree"
                                                   :type-level="'levelFour'" :data-rows="levelFour"
                                                   :cash-flow-hidden-dates="cashFlowHiddenDates"
                                                   :span-description="levelFour.description"
                                                   :identify-value="levelFour.identifyValues"
                                                   v-for="levelFour in levelThree.level_four"
                                                   :key="levelFour.id" v-show="nivel == 'four'">


                                </collapse-div-four>

                            </collapse-div-three>

                        </collapse-div-two>

                    </collapse-div-one>

                </collapse-div-main>


            </div>

        </div>


    </div>

</template>

<script>
    import _ from 'lodash'
    import Vue from 'vue/dist/vue.js';
    import CollapseDivOne from './CollapseDivOne.vue';
    import CollapseDivTwo from './CollapseDivTwo.vue';
    import CollapseDivThree from "./CollapseDivThree.vue";
    import CollapseDivFour from "./CollapseDivFour.vue";
    import CollapseDivMain from "./CollapseDivMain.vue";
    import PrintModal from "./PrintModal.vue";
    import {API_BASE} from '@config/api';
    
    Vue.filter("formatNumber", function (value) {
        return numeral(value).format("0,0.00");
    });

    ajustaHeaderFluxoPrevistoRealizado();

    export default {
        components: {PrintModal, CollapseDivThree, CollapseDivOne, CollapseDivTwo, CollapseDivFour, CollapseDivMain},
        data() {
            return {
                cashFlowActiveDates: [],
                cashFlowHiddenDates: [],
                cashFlowAllDates: [],
                cashFlowData: [],
                cashFlowBillingData: [],
                cashFlowAccumulatedData: {},
                cashFlowEntranceData: {},
                cashFlowExitsData: {},
                companys: [],
                banks: [],
                companysSelected: [],
                reportType: "",
                nivel: 'four',
                collapseControl: {
                    "main": true,
                    "levelOne": true,
                    "leveTwo": true,
                    "levelThree": true
                },
                allDatesPrint: [],
                showModalPrint: false,
                selfComponent: this,
                isSearchComplete: false,
                periodicityParamSearch: "",
                viewBillingOnRealized: false,
                simulationDAS: {}
            }
        },

        directives: {},

        mounted: function () {
            numeral.language('pt-br');
            this.initPage();

            this.viewBillingOnRealized = this.$session.get('user_access-view_billing_on_realized') == "1" ? true : false;
        },

        methods: {

            toggle(divToggle) {
                let self = this;
                self.collapseControl[divToggle] = !self.collapseControl[divToggle];
            },

            initPage() {
                let self = this;
                $(".select2Company").select2({
                    placeholder: "Selecione as empresas ou deixe em branco para todas"
                });

                $(".select2Bank").select2({
                    placeholder: "Selecione os bancos ou deixe em branco para todas"
                });

                $("#dateInitial, #dateFinish").datepicker({
                    autoclose: true,
                    format: 'dd/mm/yyyy',
                    todayHighlight: !0,
                    orientation: "bottom left",
                    templates: {
                        leftArrow: '<i class="la la-angle-left"></i>',
                        rightArrow: '<i class="la la-angle-right"></i>'
                    }
                });

                $("#report_type").change(function () {
                    let reportTypeVal = $("#report_type").val()
                    self.reportType = reportTypeVal;
                });

                $('#report_type').trigger('change');

                $("#company_id").change(function() {
                    self.companysSelected = $(this).val();
                    self.loadBanks();
                });

                this.loadCompanys(true);
            },

            loadCompanys(init = false) {
                let self = this;
                blockPage('Carregando os dados...');

                self.$http.get(API_BASE + 'company/listAllActv', {headers: {'token': self.$session.get('token')}}).then(
                    function (result) {
                        self.companys = result.body.data;
                        unblockPage();

                        if (init) {
                            this.loadBanks(init);
                        }
                    },
                    function (error) {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                );
            },

            loadBanks(init = false) {
                let self = this;

                let params = {
                    companys: JSON.stringify(self.companysSelected)
                };

                let headers = {
                    'token': self.$session.get('token')
                };

                blockPage('Carregando os dados...');

                self.$http.get(API_BASE + 'bank/listAllByGroup', {headers: headers, params: params}).then(
                    function (result) {
                        self.banks = result.body.data;
                        unblockPage();

                        if (init) {
                            this.loadParameter();
                        }
                    },
                    function (error) {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                );
            },

            loadParameter() {
                let self = this;

                self.$http.get(API_BASE + 'fieldparameter/getDataDashboard',
                    {
                        timeout: 1000 * 60 * 10,
                        params: {type: 'projected'},
                        headers: {
                            'token': self.$session.get('token')
                        }
                    })
                    .then(
                        function (result) {
                            $("#dateInitial > input").val(result.body.data.date_initial);
                            $("#dateInitial > input").datepicker({
                                autoclose: true,
                                format: 'dd/mm/yyyy',
                                todayHighlight: !0,
                                orientation: "bottom left",
                                defaultDate: result.body.data.date_initial,
                                templates: {
                                    leftArrow: '<i class="la la-angle-left"></i>',
                                    rightArrow: '<i class="la la-angle-right"></i>'
                                }
                            });
                            $("#dateFinish > input").val(result.body.data.date_finish);
                            $("#dateFinish > input").datepicker({
                                autoclose: true,
                                format: 'dd/mm/yyyy',
                                todayHighlight: !0,
                                orientation: "bottom left",
                                defaultDate: result.body.data.date_finish,
                                templates: {
                                    leftArrow: '<i class="la la-angle-left"></i>',
                                    rightArrow: '<i class="la la-angle-right"></i>'
                                }
                            });

                            $('#report_type').val(result.body.data.report_type);
                            $('#report_type').trigger('change');

                            $('#company_id').val(result.body.data.company_id);
                            $('#company_id').trigger('change');

                            $('#periodicity').val(result.body.data.periodicity);
                            $('#periodicity').trigger('change');

                            setTimeout(() => {
                                $('#bank_id').val(result.body.data.bank_id);
                                $('#bank_id').trigger('change');
                            }, 500);
                        },
                        function (error) {
                            showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                            unblockPage();
                        }
                    );
            },

            verifyShowDate(dateParam) {
                let self = this;
                let lShow = true;
                for (let i = 0; i < self.cashFlowHiddenDates.length; i++) {
                    if (self.cashFlowHiddenDates[i].dateHidden == dateParam) {
                        lShow = false;
                        break;
                    }
                }

                return lShow;

            },
            prepareGenerateCashFlowRealized() {
                blockPage('Gerando Fluxo Projetado...');
                let self = this;

                checkImportBeforeSearch(self).then(
                    function (result) {
                        if (!result.body.payload) {
                            self.generateCashFlowRealized();
                        } else {
                            showNotification('Atenção', 'Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.', 'warning')
                            unblockPage();
                        }

                    },
                    function (error) {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    });

            },
            generateCashFlowRealized() {
                let self = this;
                let companys = "ALL";

                self.isSearchComplete = false;

                self.cashFlowData = [];

                if ($("#company_id").val().length > 0) {
                    companys = $("#company_id").val();
                }

                let headers = {
                    'token': self.$session.get('token')
                };

                let params = {
                    dateInitial: $("#dateInitial > input").val(),
                    dateFinish: $("#dateFinish > input").val(),
                    company_id: companys,
                    nivel: 'four',
                    report_type: $("#report_type").val(),
                    periodicity: $("#periodicity").val()
                };

                self.$http.get(API_BASE + 'loadinncashprojected', {
                    headers: headers,
                    params: params
                }).then(
                    function (result) {
                        self.periodicityParamSearch = $("#periodicity").val();
                        if (self.cashFlowActiveDates = result.body.payload.cashflowProjected.dates) {
                            self.cashFlowActiveDates = result.body.payload.cashflowProjected.dates.dates.activeDates;
                            self.cashFlowHiddenDates = result.body.payload.cashflowProjected.dates.dates.hiddenDates;
                            self.cashFlowAllDates = result.body.payload.cashflowProjected.dates.dates.allDates;
                            self.cashFlowData = result.body.payload.cashflowProjected.data;
                        } else {
                            showNotification('Sem dados', 'Dados não encontrados.', 'info');
                        }
                        self.isSearchComplete = true;
                        unblockPage();
                    },
                    function (error) {
                        unblockPage();
                        showNotification('Atenção', 'Oops! Ocorreu um erro ao gerar os dados.', 'danger');
                        console.log(error);
                    }
                );
            },
            nextDate() {
                let self = this;
                let dateRemoveActive, dateRemoveHidden, indexActive, indexHidden;

                if (self.cashFlowHiddenDates.length > 0 && self.cashFlowActiveDates.length > 0) {

                    dateRemoveActive = self.cashFlowActiveDates[0];
                    indexActive = self.cashFlowActiveDates.indexOf(dateRemoveActive);
                    dateRemoveHidden = self.cashFlowHiddenDates[0];
                    indexHidden = self.cashFlowHiddenDates.indexOf(dateRemoveHidden);


                    //Só permite mudar o range se a ultima data ativa for menor que a proxima data escondida.
                    if (self.cashFlowActiveDates[self.cashFlowActiveDates.length - 1].dateHidden < dateRemoveHidden.dateHidden || self.cashFlowActiveDates[self.cashFlowActiveDates.length - 1].dateNoConvert < dateRemoveHidden.dateNoConvert) {
                        self.cashFlowActiveDates.splice(indexActive, 1);
                        self.cashFlowHiddenDates.splice(indexHidden, 1);
                        self.cashFlowHiddenDates.push(Object.assign({}, dateRemoveActive));
                        self.cashFlowActiveDates.push(Object.assign({}, dateRemoveHidden));

                    }
                }
            },
            previousDate() {
                let self = this;
                let dateRemoveActive, dateRemoveHidden, indexActive, indexHidden;

                if (self.cashFlowHiddenDates.length > 0 && self.cashFlowActiveDates.length > 0) {

                    dateRemoveActive = self.cashFlowActiveDates[self.cashFlowActiveDates.length - 1];
                    indexActive = self.cashFlowActiveDates.indexOf(dateRemoveActive);
                    dateRemoveHidden = self.cashFlowHiddenDates[self.cashFlowHiddenDates.length - 1];
                    indexHidden = self.cashFlowHiddenDates.indexOf(dateRemoveHidden);

                    //Só permite mudar o range se a ultima data ativa for maior que a proxima data escondida.
                    if (self.cashFlowActiveDates[0].dateHidden > dateRemoveHidden.dateHidden || self.cashFlowActiveDates[0].dateNoConvert > dateRemoveHidden.dateNoConvert) {
                        self.cashFlowActiveDates.splice(indexActive, 1);
                        self.cashFlowHiddenDates.splice(indexHidden, 1);
                        self.cashFlowHiddenDates.unshift(Object.assign({}, dateRemoveActive));
                        self.cashFlowActiveDates.unshift(Object.assign({}, dateRemoveHidden));

                    }
                }
            },
            openModalPrint() {
                let self = this;
                self.allDatesPrint = self.cashFlowActiveDates;
                self.showModalPrint = true;
            },

            recalculeTotal (value) {
              let totalMain = [];
              let totalLvlOne = [];

              if (value) {
                for (const main of this.cashFlowData) {
                  totalMain = [];

                  if (main.type === 'DAS') {
                    continue;
                  }

                  if (main.data) {
                    for (const lvlOne of main.data) {
                      totalLvlOne = [];

                      for (const lvlTwo of lvlOne.level_two) {
                        for (const data of lvlTwo.data_values) {
                          totalLvlOne.push({
                            date: data.date,
                            value: data.value
                          });
                        }
                      }

                      for (const data of lvlOne.level_one.data_values) {
                        data.value = 0;

                        for (const total of totalLvlOne) {
                          if (data.date === total.date) {
                            data.value += parseFloat(total.value);
                          }
                        }

                        totalMain.push({
                          date: data.date,
                          value: data.value
                        });
                      }
                    }
                  }

                  for (const data of main.data_values) {
                    if (data.type === 'DAS') {
                      continue;
                    }

                    data.value = 0;
                    for (const total of totalMain) {
                      if (data.date === total.date) {
                        data.value += parseFloat(total.value);
                      }
                    }
                  }
                }

                this.calculeAccumulated();

                let saldoInicial = 0;
                for (const main of this.cashFlowData) {
                  if (main.type !== 'ACU') {
                    continue;
                  }

                  for (let i = 0; i < main.data_values.length; i += 1) {
                    if (i === 0) {
                      saldoInicial = main.data_values[i].value;
                    }
                  }
                }

                for (const main of this.cashFlowData) {
                  if (main.type !== 'SLD') {
                    continue;
                  }

                  for (let i = 0; i < main.data_values.length; i += 1) {
                    if (i > 0) {
                      main.data_values[i].value += saldoInicial;
                    }
                  }
                }

                this.calculeAccumulated();
              }
            },

            calculeAccumulated () {
                const objTot = [];
                for (const main of this.cashFlowData) {
                  for (const lvl of main.data_values) {
                    if (main.type === 'ACU') {
                      lvl.value = 0;
                    }

                    if (main.type === 'DAS') {
                      continue;
                    }

                    objTot.push({
                      type: main.type,
                      dateHidden: '',
                      date: lvl.date,
                      value: lvl.value
                    });
                  }
                }

                for (const main of this.cashFlowData) {
                  if (main.type === 'DAS') {
                    for (const simulation of main.simulations) {
                      for (const data of simulation.data_values) {
                        objTot.push({
                          type: main.type,
                          dateHidden: data.dateHidden,
                          date: data.date,
                          value: parseFloat(data.value)
                        });
                      }
                    }
                  }
                }

                for (const main of this.cashFlowData) {
                  if (main.type !== 'ACU') {
                    continue;
                  }

                  for (const lvl of main.data_values) {
                    for (const tot of objTot) {
                      if (lvl.date === tot.date || lvl.date === tot.dateHidden) {
                        if (tot.type === 'SAI') {
                          lvl.value -= tot.value;
                        } else {
                          lvl.value += tot.value;
                        }
                      }
                    }
                  }
                }
            }
        }
    }
</script>
