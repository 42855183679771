<template>
  <div>
    <v-btn class="button" :ripple="false" @click="show = !show">
      Enviar por email
      <v-icon color="white">mdi-email-outline</v-icon>
    </v-btn>
    <v-expand-transition>
      <div v-show="show">
        <v-input>
          <input
            type="text"
            class="pa-5 form-control m-input m-input--solid pix-email-input"
            v-model="email"
          />
          <template slot="append">
            <v-btn
              :loading="loading"
              :disabled="checkEmail()"
              class="pix-email-button"
              color="success"
              @click="send"
            >
              Enviar
            </v-btn>
          </template>
        </v-input>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div v-show="success">
        Email enviado com sucesso
        <v-icon color="green">mdi-check</v-icon>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { API_BASE } from '@config/api';

export default {
  name: 'PixBillingEmailButton',
  data() {
    return {
      email: '',
      show: false,
      loading: false,
      success: false,
    };
  },
  props: {
    requestId: Number,
  },
  methods: {
    checkEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(String(this.email).toLowerCase());
    },
    send() {
      if (this.checkEmail()) {
        return;
      }
      this.loading = true;
      this.$http
        .post(`${API_BASE}pix/sendmail`, {
          requestId: this.requestId,
          email: this.email,
        })
        .then(
          () => {
            this.show = false;
            this.loading = false;
            setTimeout(() => {
              this.success = true;
              setTimeout(() => {
                this.success = false;
              }, 4000);
            }, 500);
          },
          (error) => {
            this.$helpers.defaultCatchError(error);
            this.show = false;
            this.loading = false;
          },
        );
    },
  },
};
</script>

<style>
.v-input__append-outer {
  margin: 0 !important;
}
.pix-email-input {
  max-height: 41px !important;
  border: none;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.pix-email-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: none;
}
</style>
