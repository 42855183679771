













































































import {
  Component,
  Mixins,
  Emit,
Prop,
} from 'vue-property-decorator';
import DialogHelper from '@/helpers/DialogHelper';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import ActionType from '@/domain/enums/StatementConciliationActionType';
import JobsConciliationRepository from '@/repositories/JobsConciliationRepository';
import ConciliationJobIntegrationError from '@/domain/models/ConciliationJobIntegrationError';

@Component
export default class StatementConciliationErpErrorDialog extends
Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  private readonly jobsConciliationRepository:
    JobsConciliationRepository = new JobsConciliationRepository();

  public model: boolean = this.value;
  public loading: boolean = true;

  public errorMessage: string = '';

  public integrationData: ConciliationJobIntegrationError|null = null;

  public get dialogWidth(): string {
    return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, { md: '50vw', default: '50vw' });
  }

  public get companyGroupId(): number {
    return Number(this.$session.get('company_group_id'));
  }

  public get companyId(): number {
    return this.$store.getters['authentication/companyIds'][0];
  }

  @Emit('close')
  handleClose() {
    return ActionType.SHOW_ERP_ERRORS;
  }

  @Emit()
  reload(): boolean {
    return true;
  }

  @Prop({
    required: true,
    type: Number,
  }) readonly conciliationId!: number;

  public created(): void {
    this.getERPErrorMessageFromIntegration();
  }

  public async getERPErrorMessageFromIntegration(): Promise<void> {
    try {
      this.loading = true;

      this.integrationData = await this.jobsConciliationRepository
        .getIntegrationFromConciliationId(this.companyGroupId, this.companyId, this.conciliationId);
    } catch (error) {
      console.log(error);
      this.$notification.error('Houve um problema ao requisitar as integrações de conciliação');
    } finally {
      this.loading = false;
    }
  }

  public async handleRerunJob(jobId: string): Promise<void> {
    try {
      this.loading = true;

      const response = await this.jobsConciliationRepository
        .rerunFailedJobFromConciliationIntegration(
          this.companyGroupId,
          this.companyId,
          jobId,
        );

      this.$notification.success(response);
      this.handleClose();
      this.reload();
    } catch (error) {
      this.$notification.error(this.$helpers.extractAxiosErrorMessage(error, 'Ocorreu um problema na re-execução do job'));
    } finally {
      this.loading = false;
    }
  }
}
