import PaymentRemittancesSummaryBalance from './PaymentRemittancesSummaryBalance';

export default class PaymentRemittancesSummary {
  constructor(
    public id: string,
    public bank: string,
    public agency: string,
    public account: string,
    public bankBalance: PaymentRemittancesSummaryBalance,
    public paymentBalance: PaymentRemittancesSummaryBalance,
  ) {}

  static make(data: any): PaymentRemittancesSummary {
    const bankBalance = PaymentRemittancesSummaryBalance.make(data.bank_balance);
    const paymentBalance = PaymentRemittancesSummaryBalance.make(data.payment_balance);

    return new PaymentRemittancesSummary(
      data.id,
      data.bank ?? '',
      data.agency ?? '',
      data.account ?? '',
      bankBalance,
      paymentBalance,
    );
  }
}
