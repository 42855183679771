import ArtificialInvoiceLink from './ArtificialInvoiceLink';

export default class ArtificialInvoice {
  constructor(
    public id: number,
    public document: string,
    public name: string,
    public artificial_invoice_links: Array<ArtificialInvoiceLink>,
  ) { }

  static make(data: any): ArtificialInvoice {
    const links = data.artificial_invoice_links.map(ArtificialInvoiceLink.make)
      || [] as Array<ArtificialInvoiceLink>;

    return new ArtificialInvoice(
      data.id,
      data.document,
      data.name,
      links,
    );
  }
}
