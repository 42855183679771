










































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import DDAConciliation from '@/domain/models/DDAConciliation';
import OriginType from '@/domain/enums/DDAConciliationOriginType';
import ActionType from '@/domain/enums/DDAConciliationActionType';

import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';

import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';
import IDDAConciliationFilter from '@/domain/interfaces/IDDAConciliationFilter';

import { formateErrorForNotification } from '../utils';

@Component({})
export default class DDAConciliationUndo extends Vue {
  @Prop(Boolean) readonly open!: boolean;

  @Prop({
    type: Object as () => DDAConciliation,
  }) readonly item!: DDAConciliation;

  @Prop({
    type: Object as () => IDDAConciliationFilter,
  }) readonly data!: IDDAConciliationFilter;

  @Emit()
  close(): ActionType {
    return ActionType.UNDO;
  }

  @Emit()
  reload(): boolean {
    this.close();
    return true;
  }

   readonly DDAConciliationRepository:
    DDAConciliationRepository = new DDAConciliationRepository();

  get hasInvoice(): boolean {
    return this.item.movements?.some((mov) => mov.origin === OriginType.INN);
  }

  undo() {
    this.deconciliation();
  }

  async deconciliation(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const group = this.$session.get('company_group_id');
      const { companies } = this.data;

      const dda = this.item.movements.find((mov) => mov.origin === OriginType.DDA);

      const { id } = dda || {} as DDAConciliationMovement;

      const success = await this.DDAConciliationRepository.deconciliation(group, companies, id);

      if (success) {
        this.$notification.success('Registro desconciliado com sucesso.');
        this.reload();
      }
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formateErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
