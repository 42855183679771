import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider),_c(VCardText,{staticClass:"p-0 mt-6"},[_c(VRow,{staticClass:"mb-2",attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"6","sm":"12","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"info","depressed":"","block":"","large":"","dark":""},on:{"click":_vm.handleSelecteds}},[_vm._v(" VINCULAR TÍTULOS SELECIONADOS ")])],1)],1),_c(VDataTable,{directives:[{name:"sticky",rawName:"v-sticky",value:(_vm.items),expression:"items"}],attrs:{"items":_vm.items,"server-items-length":_vm.serverItemsLength,"headers":_vm.headers,"options":_vm.options,"footer-props":_vm.footerProps,"item-key":"idCustomer","id":"payroll-select-list","hide-default-header":"","disable-filtering":"","show-select":"","dense":""},on:{"update:options":function($event){_vm.options=$event},"update:sort-by":_vm.onChangeSortBy,"update:sort-desc":_vm.onChangeSortDesc,"update:page":_vm.onChangePage,"update:items-per-page":_vm.onChangeItemsPerPage},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var ref_props = ref.props;
        var headers = ref_props.headers;
        var someItems = ref_props.someItems;
        var everyItem = ref_props.everyItem;
        var ref_props_options = ref_props.options;
        var sortBy = ref_props_options.sortBy;
        var sortDesc = ref_props_options.sortDesc;
        var ref_on = ref.on;
        var toggle = ref_on['toggle-select-all'];
        var sort = ref_on.sort;
return [_c('thead',[_c('tr',_vm._l((headers),function(ref){
        var value = ref.value;
        var text = ref.text;
        var sortable = ref.sortable;
return _c('sticky-cell',{key:value,class:sortable ? 'custom-sortable' : '',on:{"click":function($event){sortable && sort(value)}}},[(value === 'data-table-select')?_c('div',[_c(VSimpleCheckbox,{attrs:{"ripple":false,"value":everyItem,"indeterminate":someItems},on:{"input":toggle}})],1):_c('div',{class:sortBy[0] == value
                  ? 'active d-flex align-center justify-start text-center'
                  : 'd-flex align-center justify-center text-center'},[_vm._v(" "+_vm._s(text)+" "),(sortable)?_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(sortDesc[0] && sortBy[0] == value ? 'mdi-arrow-down' : 'mdi-arrow-up')+" ")]):_vm._e()],1)])}),1)])]}},{key:"item.title",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTitle(item))+" ")]}},{key:"item.supplier",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSupplier(item))+" ")]}},{key:"item.emissionDate",fn:function(ref){
                  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.dueDate",fn:function(ref){
                  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.value",fn:function(ref){
                  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value))+" ")]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Títulos")]),_c('th',[_vm._v("Valor")])])]),_c('tbody',[_c('tr',[_c('th',[_vm._v("Total Geral")]),_c('td',[_vm._v(_vm._s(_vm.items.length))]),_c('td',[_vm._v(_vm._s(_vm.getItemsTotalValue))])]),_c('tr',[_c('th',[_vm._v("Total Selecionados")]),_c('td',[_vm._v(_vm._s(_vm.getSelectedLength))]),_c('td',[_vm._v(_vm._s(_vm.getSelectedTotalValue))])])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }