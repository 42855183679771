<template>
    <paramns-list-structure
      class="mt-4"
      :paramnsVisibility="paramnsVisibility"
      :tableVisibility="items.length > 0"
    >
      <template v-slot:search>
        <h3 class="header-title">Parâmetros</h3>
      </template>
      <template v-slot:actions>
        <v-row justify="end">
          <custom-button
            :disabled="checkSearchButtonDisabled()"
            buttonClass="btn-success"
            @click="search()"
            label="Visualizar"
          />
          <v-btn icon class="ml-1" depressed @click="toggleParams()">
            <v-icon
              x-large
              class="text--disabled chevron"
              :class="!paramnsVisibility ? 'toggle-chevron' : ''"
            >
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:paramns>
        <billing-remittance-search
          :searchData="searchData"
          :paramnsVisibility="paramnsVisibility"
          :setSearchData="setSearchData"
        />
      </template>

      <template v-slot:table>
        <list-table
          @success="search()"
          :items="items"
          :remittanceLimit="remittanceLimit"
          :billingApproval="billingApproval"
          :initial-date="cnabInitialDate"
          :final-date="cnabFinalDate"
        />
      </template>
    </paramns-list-structure>
</template>

<script>
import { API_BASE } from '@config/api';
/* eslint-disable no-undef */
import CustomButton from '@/components/buttons/CustomButton.vue';
import ParamnsListStructure from '@/components/defaults/ParamnsListStructure.vue';
import {
  validateImporting,
  validateBillingRemittance,
  ValidationError,
} from '@/utils/processes_validation';

// eslint-disable-next-line
// import ApiBankingService from "@/services/apibanking.service";

import ListTable from './ListTable.vue';
import BillingRemittanceSearch from './components/Search.vue';

export default {
  components: {
    BillingRemittanceSearch,
    CustomButton,
    ParamnsListStructure,
    ListTable,
  },
  name: 'billingRemittance',
  data() {
    return {
      items: [],
      paramnsVisibility: true,
      billingApproval: true,
      remittanceLimit: 0,
      searchData: {
        emissionStartDate: null,
        emissionEndDate: null,
        dueDateStartDate: null,
        dueDateEndDate: null,
        returnStartDate: null,
        returnEndDate: null,
        transaction_type: [],
        payment_method: [],
        client: '',
        registration_number: [],
        bank_code: [],
        liberated: { code: 'ALL', label: 'Todos' },
        returnedOccurrency: [],
      },
    };
  },
  methods: {
    convertDate(date) {
      if (!date) {
        return '';
      }
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
    search() {
      blockPage('Carregando os dados...');
      const companiesId = Array.of(this.searchData.companyId);
      checkImportBeforeSearch(this, companiesId)
        .then(validateImporting)
        .then((data) => validateBillingRemittance(data, this.cnabInitialDate, this.cnabFinalDate))
        .then(this.loadData)
        .catch((reason) => {
          if (reason instanceof ValidationError) {
            showNotification('Atenção', reason.message, 'warning');
          } else {
            showNotification(
              'Atenção',
              'Opa! Parece que algo saiu errado, tente recarregar a página',
              'danger',
            );
          }
          unblockPage();
        });
    },
    loadData() {
      this.items = [];
      const companyId = $('#company_id').val();

      const data = {
        issueDate: {
          initial: this.convertDate(this.searchData.emissionStartDate),
          final: this.convertDate(this.searchData.emissionEndDate),
        },
        dueDate: {
          initial: this.convertDate(this.searchData.dueDateStartDate),
          final: this.convertDate(this.searchData.dueDateEndDate),
        },
        returnDate: {
          initial: this.convertDate(this.searchData.returnStartDate),
          final: this.convertDate(this.searchData.returnEndDate),
        },
        clientCode: [],
        // bankCode: this.searchData.bankCode.map((item) => item.code).filter((item) => item),
        // registrationNumber: this.searchData.registrationNumber.map((item) => item.code),
        // transactionType: this.searchData.transactionType.map((item) => item.code),
        // occurencyCode: this.searchData.returnedOccurrency,
        // paymentMethod: this.searchData.paymentMethod.map((item) => item.code),
        // liberated: this.searchData.liberated.code || '',
        registrationNumber:
          $('#registration_number').val().length > 0 ? $('#registration_number').val() : '',
        bankCode: $('#bank_code').val().length > 0 ? $('#bank_code').val() : '',
        transactionType:
          $('#transaction_type').val().length > 0 ? $('#transaction_type').val() : '',
        occurencyCode: $('#occurency_code').val().length > 0 ? $('#occurency_code').val() : '',
        paymentMethod: $('#payment_method').val().length > 0 ? $('#payment_method').val() : '',
        liberated: $('#liberated').val().length > 0 ? $('#liberated').val() : 'ALL',
        companyId: $('#company_id').val(),
        borderoNumber: $('#bordero_number').val(),
        documentStatus: $('#document_status').val(),
      };

      this.$http
        .post(`${API_BASE}chargeRemittance/list`, data, {
          headers: {
            token: this.$session.get('token'),
            'company-ids': JSON.stringify([companyId]),
          },
        })
        .then(
          (result) => {
            this.items = result.body.data || [];
            this.remittanceLimit = result.body.remittanceLimit;
            this.billingApproval = result.body.billingApproval
              ? result.body.billingApproval === 'Y'
              : true;

            if (this.items.length === 0) {
              showNotification('Atenção', 'Sem dados para listar', 'warning');
              unblockPage();
              return;
            }

            this.paramnsVisibility = false;
            unblockPage();
          },
          (error) => {
            this.$helpers.defaultCatchError(error);
            unblockPage();
          },
        );
    },
    toggleParams() {
      this.paramnsVisibility = !this.paramnsVisibility;
    },
    setSearchData(newData) {
      this.searchData = { ...this.searchData, ...newData };
    },
    checkSearchButtonDisabled() {
      return !(
        this.searchData.emissionStartDate
        && this.searchData.emissionEndDate
        && this.searchData.dueDateStartDate
        && this.searchData.dueDateEndDate
        && this.searchData.companyId
      );
    },
  },
  computed: {
    cnabInitialDate() {
      return this.searchData.emissionStartDate != ''
        ? this.searchData.emissionStartDate
        : this.searchData.dueDateStartDate;
    },
    cnabFinalDate() {
      return this.searchData.emissionEndDate != ''
        ? this.searchData.emissionEndDate
        : this.searchData.dueDateEndDate;
    },
  },
};
</script>

<style></style>
