import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":_vm.maxWidth,"persistent":"","scrollable":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Excluir Distribuição de Operador ")]),_c(VCardText,[_vm._v(" Tem certeza que deseja "),_c('strong',[_vm._v("excluir permanentemente")]),_vm._v(" a distribuição do operador \""+_vm._s(_vm.name)+"\"? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"scoped-button mr-4",attrs:{"large":"","color":"warning","disabled":_vm.loading},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{staticClass:"scoped-button",attrs:{"large":"","color":"success","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" SALVAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }