export default class CompanyBySearch {
  constructor(
    public id: number,
    public fantasyName: string,
  ) { }

  static make(data: any): CompanyBySearch {
    return new CompanyBySearch(
      data.id,
      data.fantasy_name,
    );
  }
}
