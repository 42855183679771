import Repository from '@/repositories/base/Repository';
import IUpsertPreNegotiationParams from '@/domain/interfaces/IUpsertPreNegotiationParams';
import PreNegotiationList from '@/domain/models/PreNegotiationList';
import PreNegotiation from '@/domain/models/PreNegotiation';

export default class PreNegotiationRepository extends Repository {
  public async savePreNegotiation(
    companyGroupId: number,
    companyIds: number[],
    params: IUpsertPreNegotiationParams,
  ): Promise<void> {
    await this.datasource.send(
      `company-groups/${companyGroupId}/billings/pre-negotiations`,
      params,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );
  }

  public async getPreNegotiationListBySearch(
    companyGroupId: number,
    companyIds: number[],
    search: string,
  ): Promise<PreNegotiationList[]> {
    const { data: request } = await this.datasource.get(`company-groups/${companyGroupId}/billings/pre-negotiations`, {
      params: { search },
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return request.data.map(PreNegotiationList.make);
  }

  public async getPreNegotiationById(
    companyGroupId: number,
    companyIds: number[],
    preNegotiationId: number,
  ): Promise<PreNegotiation> {
    const { data: request } = await this.datasource.get(`company-groups/${companyGroupId}/billings/pre-negotiations/${preNegotiationId}`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return PreNegotiation.make(request.data);
  }

  public async sendPreNegotiationsToERP(
    companyGroupId: number,
    companyIds: number[],
    preNegotiationIds: number[],
  ): Promise<void> {
    await this.datasource.send(
      `company-groups/${companyGroupId}/billings/pre-negotiations/erp`,
      { pre_negotiation_ids: preNegotiationIds },
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );
  }

  public async removePreNegotiations(
    companyGroupId: number,
    companyId: number,
    preNegotiationIds: number[],
  ): Promise<void> {
    await this.datasource.destroy(
      `company-groups/${companyGroupId}/billings/pre-negotiations`,
      {
        headers: { 'company-id': companyId },
        params: { ids: preNegotiationIds },
      },
    );
  }
}
