import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":""}},Object.assign({}, onMenu, onTooltip)),[_c(VIcon,{attrs:{"dense":""}},[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}])},[_c(VList,[(!_vm.isRow)?_c(VListItem,{on:{"click":function($event){return _vm.action('TRANSFER')}}},[_c(VListItemTitle,[_vm._v(" Transferir Pendências Selecionadas ")])],1):_vm._e(),(_vm.isRow)?_c(VListItem,{on:{"click":function($event){return _vm.action('TRANSFER')}}},[_c(VListItemTitle,[_vm._v(" Transferir Pendências ")])],1):_vm._e(),(_vm.isRow)?_c(VListItem,{on:{"click":function($event){return _vm.action('HISTORY')}}},[_c(VListItemTitle,[_vm._v(" Visualizar Histórico ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }