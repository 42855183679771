



































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import ISignatureGroupByEconomicGroupCompanyAndCustomer from '@/views/preNegotiation/interfaces/ISignatureGroupByEconomicGroupCompanyAndCustomer';
import PreNegotiationSignature from '@/views/preNegotiation/subscriber/PreNegotiationSignature.vue';

@Component({
  components: { PreNegotiationSignature },
})
export default class PreNegotiationSubscribers extends Vue {
  public readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);

  public sameSignatureForAllCompanies: boolean = false;

  public groupedCompanyAndCustomers: ISignatureGroupByEconomicGroupCompanyAndCustomer[] = [];

  public get canEdit(): boolean {
    if (this.$route.params.preNegotiationId) {
      return false;
    }

    return true;
  }

  public created(): void {
    if (this.preNegotiationModule.signatureGroups.length < 1 && this.canEdit) {
      this.generateSignatureGroups();
    }
  }

  public generateSignatureGroups(): void {
    for (let i = 0; i < this.preNegotiationModule.deadlineRedefinedAccounts.length; i += 1) {
      const accountGroup = this.preNegotiationModule.deadlineRedefinedAccounts[i];

      this.preNegotiationModule.setNewSignatureGroup({
        economicGroupId: accountGroup.economicGroupId,
        economicGroupName: accountGroup.economicGroupName,
        companyId: accountGroup.companyId,
        companyName: accountGroup.companyName,
        customerIdCustomer: accountGroup.customerIdCustomer,
        customerName: accountGroup.customerName,
        signatures: [],
      });
    }
  }

  public handleSignatureChanges(): void {
    this.preNegotiationModule.setSameSignatureForAllCompanies(this.sameSignatureForAllCompanies);
  }
}
