
















































































import {
  Component,
  Ref,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { DataOptions } from 'vuetify';
import { toCurrency } from '@/utils/index';
import { formateDate } from '@/utils/date';
import { formatErrorForNotification } from '@/utils/error';
import VDataTableHeader from '@/types/IVDataTableHeader';
import LooseObjectType from '@/types/LooseObjectType';
import ColumnToShow from '@/types/ColumnToShow';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import DataTableHelper from '@/helpers/DataTableHelper';
import BillingPendencyRepository from '@/repositories/BillingPendencyRepository';
import BillingPendency from '@/domain/models/BillingPendency';
import FilterParameters from '@/domain/models/filter-parameters/FilterParameterBillingPendencyList';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import ISortAndDirectionDataTable from '@/helpers/interfaces/ISortAndDirectionDataTable';
import BillingPendencyStore from './store/BillingPendencyStore';
import BillingPendencyListFilter from './components/ListFilter.vue';
import BillingPendencyListMenu from './components/ListMenu.vue';
import BillingPendencyHistoryDialog from './components/HistoryDialog.vue';
import BillingPendencyTransferDialog from './components/TransferDialog.vue';

@Component({
  components: {
    DynamicColumnMenu,
    BillingPendencyListFilter,
    BillingPendencyListMenu,
    BillingPendencyHistoryDialog,
    BillingPendencyTransferDialog,
  },
})
export default class BillingPendencyList extends Vue {
  @Ref('filter') private filterComponent!: BillingPendencyListFilter;

  @Watch('store.state.onFilter')
  public onFilter(value: boolean) {
    if (value) {
      this.handleFilter();
      this.store.state.onFilter = false;
    }
  }

  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  public readonly repository: BillingPendencyRepository = new BillingPendencyRepository();
  public readonly groupFilterParametersEnum = GroupFilterParametersEnum;
  public readonly formatCurrency = toCurrency;
  public readonly formatDate = formateDate;

  public readonly dataTableOptions: DataOptions =
    DataTableHelper.getDefaultDataTableOptions();
  public readonly footerOptions: DataTableFooterOptions =
    DataTableHelper.getDefaultFooterProps();

  public store = new BillingPendencyStore();

  public loading: boolean = false;
  public showFilter: boolean = true;
  public hasLoadTableItems: boolean = false;

  public totalItems: number = 0;

  public headers: Array<VDataTableHeader> = [];

  public billingPendencies: Array<BillingPendency> = [];
  public billingPendenciesSelecteds: Array<BillingPendency> = [];
  public billingPendenciesToTransfer: Array<BillingPendency> = [];

  public billingPendenciesToHistory: BillingPendency = {} as BillingPendency;

  public data: Omit<FilterParameters, 'columnsToShow'> = {} as Omit<FilterParameters, 'columnsToShow'>;

  public openDialog: Record<string, boolean> = {
    TRANSFER: false,
    HISTORY: false,
  };

  public headerKeys: LooseObjectType<string> = {
    name: 'data_table_billing_pendency_list_show_column_name',
    overdues: 'data_table_billing_pendency_list_show_column_overdues',
    daysOverdue: 'data_table_billing_pendency_list_show_column_days_overdue',
    totalOverdue: 'data_table_billing_pendency_list_show_column_total_overdue',
    totalDue: 'data_table_billing_pendency_list_show_column_total_due',
    totalOverdueOverall: 'data_table_billing_pendency_list_show_column_total_overdue_overall',
    totalDueOverall: 'data_table_billing_pendency_list_show_column_total_due_overall',
    operator: 'data_table_billing_pendency_list_show_column_operator',
    date: 'data_table_billing_pendency_list_show_column_date',
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public get useGroup(): boolean {
    return false;
  }

  public get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  public get headersActive(): Array<VDataTableHeader> {
    const headers: Array<VDataTableHeader> = [];

    if (this.store.state.manager && !this.isMobile) {
      headers.push({
        text: '',
        value: 'data-table-select',
        sortable: false,
        align: 'center',
      });
    }

    const headersShow = this.headers.filter((h) => h.show);

    return [
      ...headers,
      ...headersShow,
      {
        text: '',
        value: 'id',
        sortable: false,
        align: 'end',
      },
    ];
  }

  public get isBillingManager(): boolean {
    return Boolean(parseInt(this.$session.get('user_access-is_billing_manager'), 10) ?? 0);
  }

  public get actualSortAndDirection(): ISortAndDirectionDataTable {
    return {
      sort: this.dataTableOptions.sortBy[0],
      direction: this.dataTableOptions.sortDesc[0],
    };
  }

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    if (this.hasLoadTableItems) {
      this.handleFilter();
    }
  }

  public created(): void {
    this.store.state.manager = this.isBillingManager;

    this.headers = this.store.state.manager
      ? [
          {
            text: this.useGroup ? 'Grupo Econômico' : 'Razão Social',
            value: 'name',
            sortable: true,
            show: true,
          },
          {
            text: 'Títulos em Atraso no Período',
            value: 'overdues',
            sortable: false,
            show: false,
          },
          {
            text: 'Dias em Atraso no Período',
            value: 'daysOverdue',
            sortable: false,
            show: false,
          },
          {
            text: 'Total em Atraso no Período',
            value: 'totalOverdue',
            sortable: false,
            show: false,
          },
          {
            text: 'Total a Vencer no Período',
            value: 'totalDue',
            sortable: false,
            show: false,
          },
          {
            text: 'Total em Atraso Geral',
            value: 'totalOverdueOverall',
            sortable: false,
            show: true,
          },
          {
            text: 'Total a Vencer Geral',
            value: 'totalDueOverall',
            sortable: false,
            show: true,
          },
          {
            text: 'Operador',
            value: 'operator',
            sortable: false,
            show: true,
          },
          {
            text: 'Data de Aprovação',
            value: 'aprovedAt',
            sortable: false,
            show: true,
          },
        ]
      : [
          {
            text: this.useGroup ? 'Grupo Econômico' : 'Razão Social',
            value: 'name',
            sortable: true,
            show: true,
          },
          {
            text: 'Títulos em Atraso no Período',
            value: 'overdues',
            sortable: false,
            show: true,
          },
          {
            text: 'Dias em Atraso no Período',
            value: 'daysOverdue',
            sortable: false,
            show: true,
          },
          {
            text: 'Total em Atraso no Período',
            value: 'totalOverdue',
            sortable: false,
            show: true,
          },
          {
            text: 'Total a Vencer no Período',
            value: 'totalDue',
            sortable: false,
            show: true,
          },
          {
            text: 'Total em Atraso Geral',
            value: 'totalOverdueOverall',
            sortable: false,
            show: false,
          },
          {
            text: 'Total a Vencer Geral',
            value: 'totalDueOverall',
            sortable: false,
            show: false,
          },
        ];
  }

  public action({ type, item }: { type: string, item?: BillingPendency }) {
    if (type === 'TRANSFER' && !item && !this.billingPendenciesSelecteds.length) {
      this.$notification.warn('Nenhuma pendência selecionada!');
      return;
    }

    if (type === 'HISTORY') {
      this.billingPendenciesToHistory = item ?? {} as BillingPendency;
      this.openDialog[type] = true;

      return;
    }

    this.billingPendenciesToTransfer = item ? [item] : this.billingPendenciesSelecteds;
    this.openDialog[type] = true;
  }

  public close(reload: boolean): void {
    this.billingPendenciesToTransfer = [];

    if (reload) {
      this.billingPendenciesSelecteds = [];
      this.loadBillingPendencies(this.data);
    }
  }

  public goToPendencies({ origin, id }: BillingPendency) {
    if (origin === 'economic_group') {
      this.$router.push({
        name: 'FinancialStatementBillingPendenciesEconomicGroup',
        params: {
          economicGroupId: String(id),
        },
      });
    } else {
      this.$router.push({
        name: 'FinancialStatementBillingPendenciesCustomer',
        params: {
          origin,
          idCustomerOrDocument: String(id),
        },
      });
    }
  }

  public rowClasses(): string {
    return 'row-pointer';
  }

  public setColumns(columnsToShow: Array<ColumnToShow>): void {
    this.headers = FilterParameterHelper
      .defineColumnsToShowOnSpecificHeader(this.headers, columnsToShow);
  }

  public handleFilter(): void {
    const validate = this.filterComponent.validate();
    this.filter(validate);
  }

  public filter({ valid, data }: { valid: boolean, data: Omit<FilterParameters, 'columnsToShow'> }): void {
    if (valid) {
      this.loadBillingPendencies(data);
    }
  }

  public async loadBillingPendencies(params: Omit<FilterParameters, 'columnsToShow'>): Promise<void> {
    try {
      if (!this.hasLoadTableItems) {
        this.hasLoadTableItems = true;
      }

      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      if (!params.companies.length) {
        params.companies = this.companyIds;
      }

      const { sort, direction } = this.actualSortAndDirection;
      const tableConfigParams = {
        filter: params,
        sort: DataTableHelper.formatTableOrdenationColumn(direction, sort),
        page: this.dataTableOptions.page,
        items_per_page: this.dataTableOptions.itemsPerPage,
      };

      const { data, total } = await this.repository
        .getPendencies(group, tableConfigParams);

      this.billingPendencies = data;
      this.totalItems = total;

      this.data = params;

      if (!this.billingPendencies.length) {
        this.$notification.warn('Nenhuma pendência de cobrança encontrada!');
      }

      await this.$nextTick();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
