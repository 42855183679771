import { formatToSpecificFormat } from '@/utils/date';

export default class SerasaFilesInfoList {
  constructor(
    public id: number,
    public fileName: string,
    public idCustomer: string,
    public createdAt: string,
    public userName: string,
    public fileUrl: string,
  ) {}

  public static make(data: any): SerasaFilesInfoList {
    return new SerasaFilesInfoList(
      data.id,
      data.file_name,
      data.id_customer,
      formatToSpecificFormat(data.created_at, 'dd/MM/yyyy hh:mm:ss', 'yyyy-MM-dd hh:mm:ss'),
      data.username_who_insert,
      data.file_url,
    );
  }
}
