<template>
  <v-container fluid>
    <history-timeline></history-timeline>
  </v-container>
</template>

<script>
import HistoryTimeline from '@/components/timeline/HistoryTimeline.vue';

export default {
  name: 'ClientHistory',
  components: {
    HistoryTimeline,
  },
};
</script>

<style></style>
