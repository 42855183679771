<template>
    <!-- main of bankret -->
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="row m--align-left">
                    <div class="col-md-4">
                        <select class="form-control m-input m-input--solid" v-model="configs.filterStatus">
                            <option value="">Todos os status</option>
                            <option value="0"> Inativo </option>
                            <option value="1"> Ativos </option>
                        </select> 
                    </div>
                </div>
            </div>
            <div class="m-portlet__head-tools">
                <router-link :to="{ name: 'bankretconfigadd', params: {bankretConfig: 'new'} }" class="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--air">
                    <span>
                        <i class="la la-plus"></i>
                        <span>
                            Nova Configuração
                        </span>
                    </span>
                </router-link>
	        </div>
        </div>

        <div class="m-portlet__body">
			<div v-if="length" class="m-section">
				<div class="m-section__content">
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Empresa</th>
								<th>Banco</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="bankretConfig in bankretConfigs" v-bind:key="bankretConfig.id">
								<td style="vertical-align: middle;">
                                    <span v-if="bankretConfig.status == 0" class="m-badge m-badge--wide m-badge--metal">Inativo</span>
                                    <span v-else class="m-badge m-badge--wide m-badge--success">Ativo</span>
								</td>
								<td style="vertical-align: middle;">{{ bankretConfig.name || 'Todas' }}</td>
								<td style="vertical-align: middle;">{{ bankretConfig.code_bank }}</td>
								<td class="m--align-right">
									<router-link :to="{ name: 'bankretconfigadd', params: {bankretConfigId: bankretConfig.id} }" class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill" title="Editar">
										<i class="la la-edit"></i>
									</router-link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div v-else class="m-section">
				<div class="m-section__content">
					<h4 class="m-text--bold text-center mt-5" v-show="!loadError">Nenhum dado encontrado</h4>
					<h4 class="m-text--bold text-center mt-5" v-show="loadError">Algo saiu errado</h4>
				</div>
			</div>
	    </div>
    </div>
</template>

<script>
	import _ from 'lodash'
	import {API_BASE} from '@config/api';
	
    export default {
        data () {
            return {
                bankretConfigs: [],
                configs: {
	                filterStatus: ''
				},
				length: 0,
				loadError: false,
            }
        },

		computed: {
			listData() {
				let dataRet = this.bankretConfigs;

				if (this.configs.filterStatus) {
					dataRet = _.filter(dataRet, data => data.status == this.configs.filterStatus)
				}

				return dataRet
			}
		},

        mounted: function () {
            this.loadConfigs();
        },

        methods: {
            loadConfigs: function () {
            	blockPage('Carregando os dados...');

                this.$http.get(`${API_BASE}bankret/config/all`, {headers: {'token': this.$session.get('token')}}).then(
                	(result) => {
						this.bankretConfigs = result.body.data
						this.length = result.body.data.length
						this.loadError = false;
		            	unblockPage();
                	},
                	(error) => {
						this.loadError = true;
                        console.log(error);
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                )
            }
        }
    }
</script>