import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider),_c(VCardText,{staticClass:"p-0 mt-6"},[_c(VDataTable,{directives:[{name:"sticky",rawName:"v-sticky",value:(_vm.items),expression:"items"}],attrs:{"items":_vm.items,"server-items-length":_vm.serverItemsLength,"headers":_vm.headers,"options":_vm.options,"footer-props":_vm.footerProps,"item-key":"id","id":"payroll-history-list","hide-default-header":"","disable-filtering":"","dense":""},on:{"update:options":function($event){_vm.options=$event},"update:sort-by":_vm.onChangeSortBy,"update:sort-desc":_vm.onChangeSortDesc,"update:page":_vm.onChangePage,"update:items-per-page":_vm.onChangeItemsPerPage},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var ref_props = ref.props;
        var headers = ref_props.headers;
        var ref_props_options = ref_props.options;
        var sortBy = ref_props_options.sortBy;
        var sortDesc = ref_props_options.sortDesc;
        var sort = ref.on.sort;
return [_c('thead',[_c('tr',_vm._l((headers),function(ref){
        var value = ref.value;
        var text = ref.text;
        var sortable = ref.sortable;
return _c('sticky-cell',{key:value,class:sortable ? 'custom-sortable' : '',on:{"click":function($event){sortable && sort(value)}}},[_c('div',{class:sortBy[0] == value
                  ? 'active d-flex align-center justify-start text-center'
                  : 'd-flex align-center justify-center text-center'},[_vm._v(" "+_vm._s(text)+" "),(sortable)?_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(sortDesc[0] && sortBy[0] == value ? 'mdi-arrow-down' : 'mdi-arrow-up')+" ")]):_vm._e()],1)])}),1)])]}},{key:"item.type",fn:function(ref){
                  var value = ref.value;
return [_c(VChip,{attrs:{"color":_vm.getTypeColor(value),"small":""}},[_vm._v(_vm._s(_vm.getTypeLabel(value)))])]}},{key:"item.date",fn:function(ref){
                  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.totalValue",fn:function(ref){
                  var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value, {}, true))+" ")]}},{key:"item.branchCode",fn:function(ref){
                  var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.accountNumber",fn:function(ref){
                  var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.id",fn:function(ref){
                  var value = ref.value;
                  var item = ref.item;
return [_c(VMenu,{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var onMenu = ref.on;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","icon":""}},Object.assign({}, onMenu, onTooltip)),[_c(VIcon,{attrs:{"dense":""}},[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}],null,true)},[_c(VList,[(_vm.userCanDownloadFile())?_c(VListItem,{on:{"click":function () { return _vm.downloadPayroll(item); }}},[_c(VListItemTitle,[_vm._v(" Baixar Arquivo ")])],1):_vm._e(),(_vm.mustDownloadReceipt(item) && _vm.userCanDownloadFile())?_c(VListItem,{on:{"click":function () { return _vm.openReceipt(value); }}},[_c(VListItemTitle,[_vm._v(" Baixar Comprovante ")])],1):_vm._e(),(item.type === _vm.logType.RETURN)?_c(VListItem,{on:{"click":function () { return _vm.openViewLog(value); }}},[_c(VListItemTitle,[_vm._v(" Visualizar Títulos ")])],1):_vm._e()],1)],1)]}}])})],1),_c('payroll-history-receipt',{attrs:{"companies":_vm.data.companies},model:{value:(_vm.receipt),callback:function ($$v) {_vm.receipt=$$v},expression:"receipt"}}),_c('payroll-history-view',{attrs:{"companies":_vm.data.companies},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }