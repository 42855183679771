export default class AccountReceivablePreNegotiation {
    constructor(
        public id: number,
        public idCustomer: string,
        public number: string,
        public type: string,
        public prefix: string,
        public installment: string,
        public companyFantasyName: string,
        public customerFantasyName: string,
        public dueDate: string,
        public balance: number,
        public value: number,
        public daysLate: number,
        public financialFee: number,
        public percentageFee: number,
        public totalValue: number,
    ) {}

    static make(data: any): AccountReceivablePreNegotiation {
        return new AccountReceivablePreNegotiation(
            data.id,
            data.id_customer,
            data.number,
            data.type,
            data.prefix,
            data.installment ?? '',
            data.company_fantasy_name,
            data.customer_fantasy_name,
            data.due_date,
            data.balance,
            data.value,
            data.days_late,
            data.financial_fee,
            data.percentage_fee,
            data.total_balance_with_fee,
        );
    }
}
