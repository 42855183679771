<template>
	<v-select
		label="Api Bancária"
		placeholder="Selecione a API Bancária"
		v-model="model"
		:items="items"
		item-text="name"
		item-value="id"
		return-object
		:rules="rules"
		:loading="loading"
		:readonly="disabled"
	/>
</template>

<script>
import ApiBankingService from '@/services/apibanking.service';

export default {
	name: 'ApiBankingSelect',
	//  two-way data-binding
	props: {
		value: [Object, String],
		disabled: Boolean,
		rules: [Array, Object],
	},
	data() {
		return {
			loading: false,
			model: this.value,
			items: [],
		};
	},
	watch: {
		model() {
			this.$emit('input', this.model);
		},
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
	},
	created() {
		this.loadApiBankings();
	},
	methods: {
		async loadApiBankings() {
			try {
				this.loading = true;

				const apiBankingService = new ApiBankingService();

				const response = await apiBankingService.index();

				this.items = response.data;
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style>

</style>
