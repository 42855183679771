































































import {
  Component,
  Prop,
  Ref,
  Vue,
} from 'vue-property-decorator';
import CompanyRepository from '@/repositories/CompanyRepository';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import IGeneralCreditRuleForm from '@/views/creditRules/interfaces/IGeneralCreditRuleForm';
import { VForm } from '@/types/VForm';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import { getModule } from 'vuex-module-decorators';

@Component
export default class GeneralCreditRuleForm extends Vue {
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();
  private readonly companyRepository: CompanyRepository = new CompanyRepository();
  public authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public loading: boolean = false;

  public selectedCompanies: number[] = [];

  public name: string = '';
  public description: string = '';
  public active: 'active' | 'inactive' = 'active';

  public companyItems: ISelectOptions[] = [];
  public statusOptions: ISelectOptions[] = [
    { value: 'active', text: 'Ativo' },
    { value: 'inactive', text: 'Desabilitado' },
  ];

  @Ref('GeneralCreditRuleForm') readonly generalCreditRuleForm!: VForm;

  @Prop({
    type: Object,
    required: false,
    default: () => ({}),
  }) readonly definedGeneralCreditRule!: IGeneralCreditRuleForm;

  public created(): void {
    if (this.definedGeneralCreditRule !== null) {
      this.setGeneralCreditRule();
    }

    this.getCompanies();
  }

  public setGeneralCreditRule(): void {
    this.name = this.definedGeneralCreditRule.name;
    this.description = this.definedGeneralCreditRule.description;
    this.selectedCompanies = this.definedGeneralCreditRule.companyIds;
    this.active = this.definedGeneralCreditRule.active;
  }

  public async getCompanies(): Promise<void> {
    this.loading = true;

    try {
      const companies = await this.companyRepository.getCompaniesByLoggedUser(
        parseInt(this.authenticationModule.user.company_group_id, 10),
      );

      this.companyItems.push(...companies.map(({ fantasyName, id }) => ({
        text: fantasyName,
        value: id,
      })));
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as empresas.');

      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public getGeneralRuleInfo(): IGeneralCreditRuleForm|boolean {
    if (!this.generalCreditRuleForm.validate()) {
      this.$notification.error('Há campos inválidos nos dados gerais!');

      return false;
    }

    return {
      name: this.name,
      description: this.description,
      companyIds: this.selectedCompanies,
      active: this.active,
    };
  }

  public handleSelectCompany(companyIds: number[]): void {
    this.$emit('on-set-company', companyIds);
  }

  handleCompleteCompaniesClear(): void {
    console.log('all clear');
  }
}
