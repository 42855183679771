import Rest from './base/Rest';

export default class CompanyService extends Rest {
	static resource = 'companygroup';

	listOne() {
        this.relationship = 'listOne';
        return this;
    }

    listAll() {
        this.relationship = '/listAll';
        return this;
    }
}
