
































































import {
  Component,
  Vue,
  Watch,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/VForm';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import ConcludeActionsIdentifiers from '@/components/timeline/types/ConcludeActionsIdentifiers';
import EventsRepository from '@/repositories/EventsRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';

@Component
export default class ConcludeEventDialog extends Vue {
  public model: boolean;
  public loading: boolean = false;

  public observation: string = '';

  public readonly inputRules: InputRulesHelper = new InputRulesHelper();
  private readonly eventsRepository: EventsRepository = new EventsRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public eventAction: ConcludeActionsIdentifiers = {
    success: { text: 'Sucesso', color: 'green' },
    failure: { text: 'Falha', color: 'red darken-1' },
  };

  public constructor() {
    super();

    this.model = this.value;
  }

  @Ref('ConcludeEventForm') readonly concludeEventForm!: VForm;

  @Prop(Boolean) readonly value!: boolean;

  @Prop(Number) readonly id!: number;

  @Prop(String) readonly status!: 'failure' | 'success';

  @Watch('value')
  OnChangeValue(newValue: boolean) {
    this.model = newValue;
  }

  @Watch('model')
  OnChangeModel(newModel: boolean) {
    this.$emit('input', newModel);
  }

  public get companyGroupId(): string {
    return this.authenticationModule.user.company_group_id;
  }

  public async handleSave(): Promise<void> {
    if (!this.concludeEventForm.validate()) {
      return;
    }

    this.loading = true;

    try {
      await this.eventsRepository
        .concludeEvent(
          parseInt(this.authenticationModule.user.company_group_id, 10),
          this.id,
          {
            observation: this.observation,
            status: this.status,
          },
        );

      this.$notification.success('Interação concluida com sucesso!');
      this.handleClose('save');
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao concluir a interação!');

      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
    }
  }

  public handleClose(action: string): void {
    this.$emit(action);
    this.reset();
    this.model = false;
  }

  public reset(): void {
    this.observation = '';
  }
}
