
































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import CurrencyHelper from '@/helpers/CurrencyHelper';
import NumberHelper from '@/helpers/NumberHelper';
import NavigationTabs from '@/components/navigation/tabs/NavigationTabs.vue';
import SerasaFile from '@/views/clients/components/SerasaFile.vue';
import RoutineLockDialog from '@/components/dialogs/routineLock/RoutineLockDialog.vue';
import OrderModule from '@/stores/modules/OrderModule';
import ClientModule from '@/stores/modules/ClientModule';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import NavigationTabsType from '@/types/NavigationTabsType';
import ClientRepository from '@/repositories/ClientRepository';
import OrderRepository from '@/repositories/OrderRepository';
import IWebhookBaseParams from '@/repositories/parameters/IWebhookBaseParams';
import IUpdateCustomerOrderInformation from '@/views/orders/interfaces/IUpdateCustomerOrderInformation';
import RoutineLockRepository from '@/repositories/RoutineLockRepository';
import RoutineLockEnum from '@/domain/enums/RoutineLockEnum';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    NavigationTabs,
    SerasaFile,
    RoutineLockDialog,
  },
})
export default class Order extends Vue {
  public selectedTab: number = 0;
  public intervalId: number|null = null;

  public orderLoading: boolean = true;
  public hasErrorOnDataUpdate: boolean = false;
  public showDialogOrderInUse: boolean = false;

  public additionalPageTitleInformation: string = '';
  public userNameUsingRoutineLock: string = '';

  public tabs: NavigationTabsType[] = [
    { name: 'Análise Crédito', path: this.baseSpecificOrderPath },
    { name: 'Informações Cliente', path: `${this.baseSpecificOrderPath}/cliente` },
    { name: 'Ficha Financeira', path: `${this.baseSpecificOrderPath}/ficha-financeira` },
    { name: 'Itens do pedido', path: `${this.baseSpecificOrderPath}/itens` },
    { name: 'Vendas', path: `${this.baseSpecificOrderPath}/vendas` },
    { name: 'Histórico Cobrança', path: `${this.baseSpecificOrderPath}/historico-cobranca` },
  ];

  public readonly clientModule: ClientModule = getModule(ClientModule);
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly orderModule: OrderModule = getModule(OrderModule);
  private readonly clientRepository: ClientRepository = new ClientRepository();
  private readonly orderRepository: OrderRepository = new OrderRepository()
  private readonly routineLockRepository: RoutineLockRepository = new RoutineLockRepository();;

  public get orderId(): string {
    return this.$route.params.orderId;
  }

  public get idCustomer(): string {
    return this.$route.params.idCustomer;
  }

  public get companyId(): number {
    return Number(this.$route.params.companyId);
  }

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get baseSpecificOrderPath(): string {
    return `/pedidos/${this.orderId}/${this.idCustomer}/${this.companyId}`;
  }

  public get showSerasaConsultationActions(): boolean {
    if (this.$route.name === null || this.$route.name === undefined) {
      return false;
    }

    return this.$route.name === 'OrderAnalisys';
  }

  public async created(): Promise<void> {
    this.selectedTab = this.defineTabByRoute(this.$route.name);

    this.$dialog.startLoading();

    const isLocked = await this.setRoutineUseIfNotExists();

    if (isLocked) {
      return;
    }

    await Promise.all([
      this.getClientById(),
      this.getOrderInformations(),
    ]);

    this.generateTitleAdditionalInformation();

    this.$dialog.stopLoading();

    this.defineIntervalOfUsingThisScreen();
  }

  public async getClientById(): Promise<void> {
    try {
      const client = await this.clientRepository.getClientById(
        this.idCustomer,
        Number(this.authenticationModule.user.company_group_id),
        this.authenticationModule.companyIds,
      );

      client.limit = CurrencyHelper
        .toCurrency(client.limit, { showCurrencySymbol: true });
      client.balanceDue = CurrencyHelper
        .toCurrency(client.balanceDue, { showCurrencySymbol: true });

      this.clientModule.setClient(client);
      this.clientModule.setClientLoading(false);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o cliente selecionado!');
    }
  }

  public async getOrderInformations(): Promise<void> {
    try {
      const response = await this.orderRepository.getCreditAnalisysOrderFinancialValuesByOrderInnId(
        this.authenticationModule.user.company_group_id,
        this.companyId,
        this.$route.params.orderId,
      );

      this.orderModule.setOrderNumber(response.orderNumber);
      this.orderModule.setOrderValue(response.totalValueOrder);
      this.orderModule.setHigherOrderValue(response.higherOrderValue);
      this.orderModule.setOrderStatus(response.status);
      this.orderModule.setOrderPaymentCondition(response.orderPaymentCondition);
      this.orderModule.setOrderObservationMessage(response.orderObservationMessage);
      this.orderModule
        .setHasOrderObservationMessageDefined(response.hasOrderObservationMessageDefined);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as informações desse pedido!');
    } finally {
      this.orderLoading = false;
    }
  }

  public generateTitleAdditionalInformation(): void {
    this.additionalPageTitleInformation = `${this.orderModule.orderNumber} - ${this.clientModule.client.companyName} - ${this.clientModule.client.cnpj}`;
  }

  public async handleSaveChanges(): Promise<void> {
    if (this.clientModule.client.companyId === null) {
      this.$notification.error('Este cliente não possui o id da empresa que pertence!');

      return;
    }

    if (!this.orderModule.orderPaymentCondition) {
      this.$notification.error('A condição de pagamento não foi definida!');

      return;
    }

    if (!this.clientModule.client.risk || !this.clientModule.client.clientClass) {
      this.$notification.error('Risco|Classe do cliente não foi definido!');

      return;
    }

    this.$dialog.startLoading();

    const promissesToResolve = [
      this.saveCustomerChanges(this.clientModule.client.companyId),
      this.saveOrderChanges(this.clientModule.client.companyId),
    ];

    await Promise.all(promissesToResolve);

    if (this.hasErrorOnDataUpdate) {
      this.hasErrorOnDataUpdate = false;

      this.$notification.error('Houve um problema ao atualizar os dados.');
    } else {
      this.$notification.success('Dados editados com sucesso.');
    }

    this.$dialog.stopLoading();
  }

  public async saveCustomerChanges(companyId: number): Promise<void> {
    const clientParameters: IWebhookBaseParams<IUpdateCustomerOrderInformation> = {
        id: this.clientModule.client.id,
        data: {
          risk: this.clientModule.client.risk,
          clientClass: this.clientModule.client.clientClass,
          notes: this.clientModule.client.notes,
          deadline: this.orderModule.orderPaymentCondition,
        },
    };

    if (
      typeof this.clientModule.client.limit === 'number'
      || (
        typeof this.clientModule.client.limit === 'string'
        && this.clientModule.client.limit !== ''
      )
    ) {
      if (typeof this.clientModule.client.limit === 'string') {
        clientParameters.data.limit = NumberHelper
          .toStringFinancialNumberToFloatFormat(this.clientModule.client.limit);
      } else {
        clientParameters.data.limit = this.clientModule.client.limit;
      }
    }

    try {
      await this.clientRepository.updateCustomerOrderInformation(
        companyId,
        this.authenticationModule.user.company_group_id,
        clientParameters,
      );
    } catch (error) {
      this.hasErrorOnDataUpdate = true;
      this.$notification.error('Houve um problema ao salvar o cliente selecionado.');
    }
  }

  public async saveOrderChanges(companyId: number): Promise<void> {
    try {
      await this.orderRepository.updateOrder(
        this.companyGroupId,
        this.orderId,
        companyId,
        {
          observation: this.orderModule.orderObservationMessage,
          paymentCondition: this.orderModule.orderPaymentCondition,
        },
      );

      this.orderModule.setHasOrderObservationMessageDefined(true);
    } catch (error) {
      this.hasErrorOnDataUpdate = true;
      this.$notification.error('Houve um problema ao salvar pedido.');
    }
  }

  public async setRoutineUseIfNotExists(): Promise<boolean> {
    try {
      const response = await this.routineLockRepository.setRoutineLockedIfExists({
        routine_code: this.$route.params.orderId,
        routine_name: RoutineLockEnum.SELECT_ORDER,
      });

      if (response.isLocked) {
        this.userNameUsingRoutineLock = response.userName;
        this.showDialogOrderInUse = true;
      }

      return response.isLocked;
    } catch (error) {
      return true;
    }
  }

  public async defineIntervalOfUsingThisScreen(): Promise<void> {
    this.intervalId = setInterval(() => this.setRoutineUseIfNotExists(), 60000);
  }

  public async releaseOrder(): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.orderRepository.releaseOrderOnERP(
        this.authenticationModule.user.company_group_id,
        [Number(this.$route.params.companyId)],
        this.orderId,
        this.idCustomer,
      );

      this.$notification.info('O pedido foi enviado para a fila de liberação!');
      await this.handleCancel();
    } catch (error) {
      this.$notification.error('Houve um problema ao efetivar a liberação do pedido.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async handleCancel(): Promise<void> {
    this.showDialogOrderInUse = false;
    this.$router.push({ name: 'OrderList' });
  }

  public defineTabByRoute(routeName: string | null | undefined): number {
    switch (routeName) {
      case 'OrderAnalisys':
        return 0;
      case 'OrderCustomer':
        return 1;
      case 'OrderFinancialStatement':
        return 2;
      case 'OrderItems':
        return 3;
      case 'OrderCustomerSells':
        return 4;
      case 'OrderHistory':
        return 5;
      default:
        return 0;
    }
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.$dialog.startLoading();

    clearInterval(this.intervalId as number);

      try {
        await this.routineLockRepository.unlockRoutine(RoutineLockEnum.SELECT_ORDER);
      } catch (error) {
        this.$notification.error('Houve um problema ao desbloquear o uso da rotina.');
      } finally {
        this.$dialog.stopLoading();
      }

    next();
  }
}
