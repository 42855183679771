<template>

    <root-modal>
        <template slot="header">
            <h5 class="modal-title">{{tittleModalInfo}}</h5>
        </template>
        <template slot="body">
            <div class="modal-body" style="height: 70vh; overflow-x: auto;">
                <div class="m-form m-form--fit m-form--label-align-right" style="margin-top: -15px;">

                    <div class="m-form m-form--label-align-right m--margin-bottom-30">

                        <ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link active" data-toggle="tab" href="#tab1" role="tab"
                                   aria-expanded="true">Geral</a>
                            </li>

                            <li class="nav-item m-tabs__item"
                                v-if="informationData && informationData.length > 0 && informationData[0].origem && informationData[0].origem.substring(0,4) == 'MATA' && informationData[0].total_data_nota > 0">
                                <a class="nav-link m-tabs__link" data-toggle="tab" href="#tab2" role="tab"
                                   aria-expanded="true">Dados NF</a>
                            </li>

                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link" data-toggle="tab" href="#tab3" role="tab"
                                   aria-expanded="false">Log</a>
                            </li>

                        </ul>

                        <div class="tab-content">
                            <div class="tab-pane active" id="tab1" role="tabgeneral">
                                <div class="row"
                                     style="text-align: left; font-size: 14px;">
                                    <div class="col-md-4">
                                        <label class="control-label">Empresa/Filial:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">
                                            {{(informationData[0].filial != '' ? informationData[0].filial + '/' : '') +
                                            informationData[0].filial_origem + ( informationData[0].branchName != '' ? ' (' +  informationData[0].branchName + ')' : '')}}
                                        </label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Título:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].prefixo + ' ' +
                                            informationData[0].numero + ' '
                                            +
                                            informationData[0].parcela + ' ' + informationData[0].tipo}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Razão social:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{(informationData[0].razao_fornecedor ? informationData[0].razao_fornecedor : '') + '' + (informationData[0].razao_cliente ? informationData[0].razao_cliente :  '')}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Nome fantasia:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{(informationData[0].nome_fornecedor ? informationData[0].nome_fornecedor : '') + '' + (informationData[0].nome_cliente ? informationData[0].nome_cliente : '')}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">CNPJ:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{(informationData[0].cnpj_fornecedor ? informationData[0].cnpj_fornecedor : '') + '' + (informationData[0].cnpj_cliente ? informationData[0].cnpj_cliente : '')}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Valor original:</label>
                                    </div>
                                    <div class="col-md-2">
                                        <label class="control-label">{{informationData[0].valor_original}}</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="control-label">Valor alterado:</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="control-label">{{informationData[0].valor_alterado}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Acréscimo:</label>
                                    </div>
                                    <div class="col-md-2">
                                        <label class="control-label">{{informationData[0].acrescimo}}</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="control-label">Valor de juros:</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="control-label">{{informationData[0].valor_juros}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Decréscimo:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].decrescimo}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Valor total:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].valor_total}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Saldo:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].saldo}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Natureza de Operação:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].codigo_natureza + ' - ' +
                                            informationData[0].descricao_natureza}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Data de emissão:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].data_emissao}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Data de vencimento:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].data_vencimento}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Data de vencimento real:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].data_vencimento_real}}</label>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Data do fluxo:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <label class="control-label">{{informationData[0].data_fluxo}}</label>
                                    </div>
                                    <div class="col-md-4" v-if="informationData[0].observacao && informationData[0].observacao != ''">
                                        <label class="control-label">Observação:</label>
                                    </div>
                                    <div class="col-md-8" v-if="informationData[0].observacao && informationData[0].observacao != ''">
                                        <label class="control-label">{{informationData[0].observacao}}</label>
                                    </div>
                                    <div class="col-md-4" v-if="informationData[0].forma_pagamento && informationData[0].forma_pagamento != ''">
                                        <label class="control-label">Forma de pagamento:</label>
                                    </div>
                                    <div class="col-md-8" v-if="informationData[0].forma_pagamento && informationData[0].forma_pagamento != ''">
                                        <label class="control-label">{{informationData[0].forma_pagamento}}</label>
                                    </div>
                                    <div class="col-md-4" v-if="informationData[0].dados_bancarios && informationData[0].dados_bancarios != ''">
                                        <label class="control-label">Dados bancários:</label>
                                    </div>
                                    <div class="col-md-8" v-if="informationData[0].dados_bancarios && informationData[0].dados_bancarios != ''">
                                        <label class="control-label">{{informationData[0].dados_bancarios}}</label>
                                    </div>
                                    <div class="col-md-4" v-if="informationData[0].e5_banco && informationData[0].e5_banco != ''">
                                        <label class="control-label">Portador:</label>
                                    </div>
                                    <div class="col-md-8" v-if="informationData[0].e5_banco && informationData[0].e5_banco != ''">
                                        <label class="control-label">{{informationData[0].e5_banco}}</label>
                                    </div>
                                    <div class="col-md-4" v-if="informationData[0].agencia && informationData[0].agencia != ''">
                                        <label class="control-label">Agência:</label>
                                    </div>
                                    <div class="col-md-8" v-if="informationData[0].agencia && informationData[0].agencia != ''">
                                        <label class="control-label">{{informationData[0].agencia}}</label>
                                    </div>
                                    <div class="col-md-4" v-if="informationData[0].conta && informationData[0].conta != ''">
                                        <label class="control-label">Conta:</label>
                                    </div>
                                    <div class="col-md-8" v-if="informationData[0].conta && informationData[0].conta != ''">
                                        <label class="control-label">{{informationData[0].conta}}</label>
                                    </div>
                                    <div class="col-md-4" v-if="informationData[0].historico_movimento && informationData[0].historico_movimento != ''">
                                        <label class="control-label">Histórico da Movimentação:</label>
                                    </div>
                                    <div class="col-md-8" v-if="informationData[0].historico_movimento && informationData[0].historico_movimento != ''">
                                        <label class="control-label">{{informationData[0].historico_movimento}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="tab-pane" id="tab2" role="tabnf"
                                 v-if="informationData && informationData.length > 0 && informationData[0].origem && informationData[0].origem.substring(0,4) == 'MATA' && informationData[0].total_data_nota > 0">
                                <div class="row" style="text-align: left; font-size: 14px;">
                                    <div class="col-md-12">
                                        <label class="control-label">{{'Mensagem NF: ' +
                                            informationData[0].mensagem_nota}}</label>
                                    </div>
                                    <table class="table table-striped m-table" style="margin-top:15px;">
                                        <thead>
                                        <tr>
                                            <th width="10%">Item</th>
                                            <th>Produto</th>
                                            <th width="15%" class="text-right">Qtd.</th>
                                            <th width="15%" class="text-right">Vlr.</th>
                                            <th width="15%" class="text-right">Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="itemNota in informationData[0].data_nota">
                                            <td>{{itemNota.d1_item}}</td>
                                            <td>{{itemNota.d1_cod + ' - ' + itemNota.d1_descri}}</td>
                                            <td class="text-right">{{itemNota.d1_quant}}</td>
                                            <td class="text-right">{{itemNota.d1_vunit}}</td>
                                            <td class="text-right">{{itemNota.d1_total}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div class="tab-pane" id="tab3" role="tablog">
                                <div class="row" style="text-align: left; font-size: 14px;"
                                     v-for="log in informationData[0].data_log">
                                    <div class="col-md-12">
                                        <span>{{'[' + log.date_time + '] ' + log.user_name + ' - ' + log.description}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template slot="footer">
            <slot name="footer">
            </slot>
        </template>
    </root-modal>

</template>

<script>
    import RootModal from './RootModal.vue'

    export default {
        props: ['modalTitle', 'informationData', 'tittleModalInfo'],
        components: {RootModal},
        name: "InformationModal",
        data() {
            return {}
        },
        methods: {
            hide() {
                this.$emit('close');
                $('.row.levels.level-four.levels-hover').removeClass('levels-hover');
            },
        }

    }
</script>

<style scoped>

</style>