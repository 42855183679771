





















































































































































































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { DataOptions } from 'vuetify';
import { toCurrency } from '@/utils';
import { formatToSpecificFormat } from '@/utils/date';
import ContentEditableComponent from '@/components/fields/ContentEditableComponent.vue';
import DataTableHelper from '@/helpers/DataTableHelper';
import AccountsReceivablePreNegotiationsRepository from '@/repositories/AccountsReceivablePreNegotiationsRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import InputRules from '@/helpers/InputRulesHelper';
import PreNegotiationHelper from '@/views/preNegotiation/helpers/PreNegotiationHelper';
import AccountReceivablePreNegotiation from '@/domain/models/AccountReceivablePreNegotiation';
import IGetAccountsReceivableListFromPreNegotiationParams from '@/repositories/parameters/IGetAccountsReceivableListFromPreNegotiationParams';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import IGetAccountsReceivablePreNegotiationParams from '@/repositories/parameters/IGetAccountsReceivablePreNegotiationParams';
import AccountsReceivableListFeeTypeEnum from '@/views/preNegotiation/enums/AccountsReceivableListFeeTypeEnum';

@Component({
  components: {
    ContentEditableComponent,
  },
})
export default class PreNegotiationSelectedAccountsTable extends Vue {
  public readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  private accountsReceivablePreNegotiationsRepository: AccountsReceivablePreNegotiationsRepository
    = new AccountsReceivablePreNegotiationsRepository();

  public readonly inputRules: InputRules = new InputRules();

  public dataTableOptions
    : DataOptions = DataTableHelper.getDefaultDataTableOptions();
  public footerOptions
    : DataTableFooterOptions = DataTableHelper.getDefaultFooterProps();

  public loadingTable: boolean = false;

  public totalItems: number = 0;
  public totalFeeFinancial: number = 0;
  public totalFeePercentage: number = 0;

  public totalFeeType: AccountsReceivableListFeeTypeEnum|null = null;

  public accountsReceivable: AccountReceivablePreNegotiation[] = [];
  public accountsReceivableIdsCustomerToNotConsider: string[] = [];
  public headers: IVDataTableHeader[] = [
    { text: 'Número', value: 'number' },
    { text: 'Empresa/Filial', value: 'companyFantasyName' },
    { text: 'Razão Social do Cliente', value: 'customerFantasyName' },
    { text: 'Vencimento', value: 'dueDate' },
    { text: 'Dias Atraso', value: 'daysLate' },
    { text: 'Valor', value: 'value' },
    { text: 'Saldo', value: 'balance' },
    { text: 'Juros (R$)', value: 'financialFee' },
    { text: 'Juros (%)', value: 'percentageFee' },
    { text: 'Valor Total (R$)', value: 'totalValue' },
    { text: '', value: 'actions' },
  ];

  @Watch('dataTableOptions')
  public onTableFiltersChange(): void {
    this.getAccountsReceivableValues();
  }

  @Watch('preNegotiationModule.excludedAccounts')
  public onExcludedAccountsChange(): void {
    this.getAccountsReceivableValues();
  }

  private get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  private get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  private get isSelectingAllSecurities(): 0 | 1 {
    return this.preNegotiationModule.selectedSecuritiesParams.isSelectingAllSecurities as 0 | 1;
  }

  private get securitiesIdsCustomer(): string[] {
    return this.preNegotiationModule.selectedSecuritiesParams.securitiesIdsCustomer;
  }

  private get preNegotiationId(): number|null {
    if (this.$route.params.preNegotiationId) {
      return Number(this.$route.params.preNegotiationId);
    }

    return null;
  }

  private get totalFeeBasedOnType(): number {
    if (this.totalFeeType === null) {
      return 0;
    }

    if (this.totalFeeType === AccountsReceivableListFeeTypeEnum.FINANCIAL) {
      return this.totalFeeFinancial;
    }

    return this.totalFeePercentage;
  }

  public get origin(): 'create' | 'show' {
    return this.preNegotiationId ? 'show' : 'create';
  }

  public get canEdit(): boolean {
    return this.origin === 'create';
  }

  public get availableHeadersToShow(): IVDataTableHeader[] {
    if (this.canEdit) {
      return this.headers;
    }

    return this.headers.filter((header) => header.value !== 'actions');
  }

  public created(): void {
    const totalFeeKey = this.getTotalFeeKeyBasedOnType(this.preNegotiationModule.totalFeeType);

    if (totalFeeKey) {
      this[totalFeeKey] = this.preNegotiationModule.totalFee;
      this.totalFeeType = this.preNegotiationModule.totalFeeType;
    }
  }

  public async getAccountsReceivableValues(): Promise<void> {
    try {
      this.loadingTable = true;

      const accountsReceivableToConsider = PreNegotiationHelper.getAvailableAccounts(
        this.securitiesIdsCustomer,
        this.preNegotiationModule.excludedAccounts,
      );

      const getAccountsReceivableRequests = [];

      if (this.origin === 'create') {
        getAccountsReceivableRequests.push(
          this.getAccountsReceivableByIdsCustomer(accountsReceivableToConsider),
          this.getAccountsReceivableTotalByIdsCustomer(accountsReceivableToConsider),
        );
      } else {
        getAccountsReceivableRequests.push(
          this.getSelectedAccountsReceivableListByPreNegotiationId(),
          this.getSelectedAccountsReceivableTotalsByPreNegotiationId(),
        );
      }

      await Promise.all(getAccountsReceivableRequests);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as contas a receber');
    } finally {
      this.loadingTable = false;
    }
  }

  public async getAccountsReceivableByIdsCustomer(
    accountsToConsider: string[],
  ): Promise<void> {
    const formattedAccountsFees = this.preNegotiationModule.accountFees.map((accountFee) => ({
      fee: accountFee.fee,
      fee_type: accountFee.feeType,
      id_customer: accountFee.idCustomer,
    }));

    const params: IGetAccountsReceivableListFromPreNegotiationParams = {
      is_selecting_all: this.isSelectingAllSecurities,
      accounts_ids_customer: accountsToConsider,
      accounts_fees: formattedAccountsFees,
      total_fee: this.totalFeeBasedOnType,
      total_fee_type: this.totalFeeType!,
      items_per_page: this.dataTableOptions.itemsPerPage,
      page: this.dataTableOptions.page,
    };

    const { data, total } = await this.accountsReceivablePreNegotiationsRepository
      .getAccountsReceivableListFromSpecificAccounts(
        this.companyGroupId,
        this.companyIds,
        params,
      );

    this.accountsReceivable = data;
    this.totalItems = total;
  }

  public async getAccountsReceivableTotalByIdsCustomer(
    accountsToConsider: string[],
  ): Promise<void> {
    const formattedAccountsFees = this.preNegotiationModule.accountFees.map((accountFee) => ({
      fee: accountFee.fee,
      fee_type: accountFee.feeType,
      id_customer: accountFee.idCustomer,
    }));

    const params: IGetAccountsReceivablePreNegotiationParams = {
      accounts_ids_customer: accountsToConsider,
      accounts_fees: formattedAccountsFees,
      is_selecting_all: this.isSelectingAllSecurities,
      total_fee: this.totalFeeBasedOnType,
      total_fee_type: this.totalFeeType!,
    };

    const response = await this.accountsReceivablePreNegotiationsRepository
      .getAccountsReceivableTotalFromSpecificAccounts(
        this.companyGroupId,
        this.companyIds,
        params,
      );

    this.preNegotiationModule.setAccountsTotal(response);
  }

  public async getSelectedAccountsReceivableListByPreNegotiationId(): Promise<void> {
    const { data, total } = await this.accountsReceivablePreNegotiationsRepository
      .getSelectedAccountsReceivableListFromPreNegotiation(
        this.companyGroupId,
        this.companyIds,
        this.preNegotiationId!,
        {
          items_per_page: this.dataTableOptions.itemsPerPage,
          page: this.dataTableOptions.page,
          total_fee: this.totalFeeBasedOnType,
          total_fee_type: this.totalFeeType!,
        },
      );

      this.accountsReceivable = data;
      this.totalItems = total;
  }

  public async getSelectedAccountsReceivableTotalsByPreNegotiationId(): Promise<void> {
    const response = await this.accountsReceivablePreNegotiationsRepository
        .getSelectedAccountsReceivableTotalsFromPreNegotiation(
          this.companyGroupId,
          this.companyIds,
          this.preNegotiationId!,
          {
            total_fee: this.totalFeeBasedOnType,
            total_fee_type: this.totalFeeType!,
          },
        );

      this.preNegotiationModule.setAccountsTotal(response);
  }

  public setDateToBrazilianFormat(date: string): string {
    return formatToSpecificFormat(date, 'dd/MM/yyyy');
  }

  public getMoneyFormat(num: number): string {
      return toCurrency(num, {}, true);
  }

  public getTotalFeeKeyBasedOnType(totalFeeType: string|null): 'totalFeeFinancial' | 'totalFeePercentage' | null {
    if (totalFeeType === null) {
      return null;
    }

    if (totalFeeType === AccountsReceivableListFeeTypeEnum.FINANCIAL) {
      return 'totalFeeFinancial';
    }

    return 'totalFeePercentage';
  }

  public resetTotalFeeValues(): void {
    this.preNegotiationModule.setTotalFee(0);
    this.preNegotiationModule.setTotalFeeType(null);
    this.totalFeeFinancial = 0;
    this.totalFeePercentage = 0;
    this.totalFeeType = null;
  }

  public validateIfNewValuePassRulesFromTradingPolicy(
    value: number,
    minimunInterest: number,
    type: 'financial' | 'percentage',
  ): boolean {
    let definedPercentualValue = value;

    if (type === AccountsReceivableListFeeTypeEnum.FINANCIAL) {
      const { totalBalance } = this.preNegotiationModule.accountsTotal;
      definedPercentualValue = (value / totalBalance) * 100;
    }

    return !(definedPercentualValue < minimunInterest);
  }

  public handleContentEditableListConfirm(
    value: number,
    idCustomer: string,
    type: 'financial' | 'percentage',
  ): void {
    this.preNegotiationModule.setAccountFee({
      fee: value,
      feeType: type,
      idCustomer,
    });
    this.resetTotalFeeValues();

    this.getAccountsReceivableValues();
  }

  public handleContentEditableTotalConfirm(
    value: number,
    type: 'financial' | 'percentage',
  ): void {
    if (
      this.preNegotiationModule.tradingPolicy
      && this.preNegotiationModule.tradingPolicy.minimumInterest
    ) {
      if (!this.validateIfNewValuePassRulesFromTradingPolicy(
        value,
        this.preNegotiationModule.tradingPolicy.minimumInterest,
        type,
      )) {
        this.$notification.warn(`O valor em porcentagem total de juros não pode ser menor do que o valor definido na política de negociação: ${this.preNegotiationModule.tradingPolicy.minimumInterest}%`);

        return;
      }
    }

    this.totalFeeFinancial = 0;
    this.totalFeePercentage = 0;

    if (value === 0) {
      this.totalFeeType = null;
    } else if (type === AccountsReceivableListFeeTypeEnum.FINANCIAL) {
      this.totalFeeType = AccountsReceivableListFeeTypeEnum.FINANCIAL;
      this.totalFeeFinancial = value;
    } else if (type === AccountsReceivableListFeeTypeEnum.PERCENTAGE) {
      this.totalFeePercentage = value;
      this.totalFeeType = AccountsReceivableListFeeTypeEnum.PERCENTAGE;
    }

    this.preNegotiationModule.setTotalFee(value);
    this.preNegotiationModule.setTotalFeeType(this.totalFeeType);
    this.preNegotiationModule.setAccountFeeEmpty();

    this.getAccountsReceivableValues();
  }

  public handleExcludeAccount({ idCustomer }: AccountReceivablePreNegotiation): void {
    this.preNegotiationModule.setExcludedAccount(idCustomer);
  }
}
