



























































































































































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { dateTimeLowerOrEqualThanOther } from '@/utils/date';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import NewsRepository from '@/repositories/NewsRepository';
import { VForm } from '@/types/VForm';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class UpsertNews extends Vue {
  inputRules: InputRulesHelper = new InputRulesHelper();
  newsRepository: NewsRepository = new NewsRepository();

  title: string = '';
  text: string = '';
  icon: string = '';
  imageFile: File|null = null;
  link: string = '';
  startDate: string = '';
  startTime: string = '';
  finalDate: string = '';
  finalTime: string = '';
  status: string = 'active';

  avaialableStatus: ISelectOptions[] = [
    {
      value: 'active',
      text: 'Ativo',
    },
    {
      value: 'disabled',
      text: 'Desativado',
    },
  ];

  get newsId(): number|null {
    if (this.$route.params.id) {
      return Number(this.$route.params.id);
    }

    return null;
  }

  get pageTitle(): string {
    if (this.$route.params.id) {
      return 'Atualizar Notícia';
    }

    return 'Cadastrar Notícia';
  }

  get buttonTextBasedOnScreen(): string {
    if (this.$route.params.id) {
      return 'ATUALIZAR';
    }

    return 'CADASTRAR';
  }

  @Ref('UpsertNewsForm') readonly upsertNewsForm!: VForm;

  created(): void {
    if (this.newsId) {
      this.getNewsById(this.newsId);
    }
  }

  async getNewsById(id: number): Promise<void> {
    try {
      this.$dialog.startLoading();

      const news = await this.newsRepository.getNewsById(id);

      this.title = news.title;
      this.text = news.text;
      this.icon = news.icon;
      this.link = news.link;
      this.startDate = news.startDate;
      this.startTime = news.startTime;
      this.finalDate = news.finalDate;
      this.finalTime = news.finalTime;
      this.status = news.status;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisistar os dados da notícia.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  async createNews(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const formDataNews = this.createFormDataNews();

      await this.newsRepository.createNews(formDataNews);

      this.$notification.success('Notícia cadastrada com sucesso.');
      this.goToNewsList();
    } catch (error) {
      this.$notification.error('Houve um problema ao cadastrar a notícia.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  async updateNews(newsId: number): Promise<void> {
    try {
      this.$dialog.startLoading();

      const formDataNews = this.createFormDataNews();
      await this.newsRepository.updateNews(newsId, formDataNews);

      this.$notification.success('Notícia atualizada com sucesso.');
      this.goToNewsList();
    } catch (error) {
      this.$notification.error('Houve um problema ao atualizar a notícia.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  createFormDataNews(): FormData {
    const formData = new FormData();
    const statusToBoolean = this.status === 'active' ? '1' : '0';

    formData.append('title', this.title);
    formData.append('text', this.text);
    formData.append('icon', this.icon);
    formData.append('status', statusToBoolean);
    formData.append('start_date_time', `${this.startDate} ${this.startTime}`);
    formData.append('final_date_time', `${this.finalDate} ${this.finalTime}`);
    formData.append('link', this.link);

    if (this.imageFile) {
      formData.append('image_file', this.imageFile!);
    }

    return formData;
  }

  goToNewsList(): void {
    this.$router.push({ name: 'NewsList' });
  }

  startDateTimeAreMinorOrEqualToFinalDateTime(): boolean {
    const startDateTime = `${this.startDate} ${this.startTime}`;
    const finalDateTime = `${this.finalDate} ${this.finalTime}`;

    return dateTimeLowerOrEqualThanOther(startDateTime, finalDateTime);
  }

  handleCancelNews(): void {
    this.goToNewsList();
  }

  handleUpsertNews(): void {
    if (!this.upsertNewsForm.validate()) {
      this.$notification.error('Existem campos inválidos!');
      return;
    }

    if (!this.startDateTimeAreMinorOrEqualToFinalDateTime()) {
      this.$notification.error('A data/hora inicial são maiores que a data/hora final!');
      return;
    }

    if (this.newsId) {
      this.updateNews(this.newsId);
    } else {
      this.createNews();
    }
  }
}
