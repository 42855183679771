import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":_vm.dialogWidth,"persistent":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Alterar data de vencimento ")]),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column align-stretch",attrs:{"md":"12","sm":"12","cols":"12"}},[_c('inn-date-field',{attrs:{"placeholder":"Selecione a data","label":"Data de vencimento","required":true,"buttons":false},model:{value:(_vm.dueDate),callback:function ($$v) {_vm.dueDate=$$v},expression:"dueDate"}})],1),_c(VCol,{staticClass:"d-flex flex-column align-stretch py-0",attrs:{"md":"12","sm":"12","cols":"12"}},[_c(VTextarea,{attrs:{"filled":"","label":"Motivo","disabled":_vm.loading,"required":true},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"button-width",attrs:{"large":"","disabled":_vm.loading},on:{"click":_vm.handleCancel}},[_vm._v(" CANCELAR ")]),_c(VBtn,{staticClass:"button-width",attrs:{"color":"success","large":"","loading":_vm.loading},on:{"click":_vm.handleConfim}},[_vm._v(" SALVAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }