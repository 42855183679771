<template>
  <div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title"></div>
      </div>
      <div class="m-portlet__head-tools">
        <router-link
          :to="{ name: 'cnabremittanceconfig' }"
          class="m-btn btn btn-warning mr-3"
          >Cancelar</router-link
        >
        <button
          id="btn-save"
          class="m-btn btn btn-success"
          form="formCnabConfig"
        >
          Salvar
        </button>
      </div>
    </div>
    <div class="m-portlet__body">
      <!--begin: Search Form -->
      <div
        class="
          m-form m-form--label-align-right
          m--margin-top-20 m--margin-bottom-30
        "
      >
        <div class="row">
          <form
            novalidate
            data-vv-scope="formCnabConfig"
            id="formCnabConfig"
            @submit.prevent="saveConfig('formCnabConfig')"
            class="row"
          >
            <div class="col-md-6">
              <div :class="['form-group']">
                <label>Banco</label>
                <select
                  class="form-control select2 hide"
                  id="bank_field"
                  name="bank_field"
                  style="width: 100%"
                >
                  <option :value="undefined">Selecione...</option>
                  <option
                    v-for="bank in banks"
                    :value="bank.code"
                    :key="bank.id"
                  >
                    {{ bank.code }} - {{ bank.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div :class="['form-group']">
                <label>Tipo de Remessa</label>
                <select
                  class="form-control select2 hide"
                  disabled
                  id="remittance_field"
                  name="remittance_field"
                  style="width: 100%"
                >
                  <option :value="undefined">Selecione...</option>
                  <option
                    v-for="cnabRemittance in cnabRemittances"
                    :value="cnabRemittance.id"
                    :data-type="cnabRemittance.type"
                    :key="cnabRemittance.id"
                  >
                    {{ cnabRemittance.description }} -
                    {{ cnabRemittance.size }} posições
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div
                :class="[
                  'form-group',
                  errors.has('formCnabConfig.description_field')
                    ? 'has-danger'
                    : '',
                ]"
              >
                <label>Descrição</label>
                <input
                  v-model="cnabRemittanceConfig.description"
                  type="text"
                  data-vv-as="'Descrição'"
                  v-validate="'required'"
                  name="description_field"
                  :class="['form-control']"
                />
                <label
                  v-show="errors.has('formCnabConfig.description_field')"
                  >{{ errors.first("formCnabConfig.description_field") }}</label
                >
              </div>
            </div>
            <div class="col-md-6">
              <div
                :class="[
                  'form-group',
                  errors.has('formCnabConfig.fileName_field')
                    ? 'has-danger'
                    : '',
                ]"
              >
                <label>Nome do Arquivo</label>
                <input
                  v-model="cnabRemittanceConfig.fileName"
                  type="text"
                  :class="['form-control']"
                  name="fileName_field"
                  data-vv-as="'Nome do Arquivo'"
                  v-validate="'required'"
                />
                <label v-show="errors.has('formCnabConfig.fileName_field')">{{
                  errors.first("formCnabConfig.fileName_field")
                }}</label>
              </div>
            </div>
            <div class="col-md-6">
              <div
                :class="[
                  'form-group',
                  errors.has('formCnabConfig.extension_field')
                    ? 'has-danger'
                    : '',
                ]"
              >
                <label>Extensão</label>
                <input
                  v-model="cnabRemittanceConfig.extension"
                  type="text"
                  :class="['form-control']"
                  data-vv-as="'Extensão'"
                  name="extension_field"
                  v-validate="'required'"
                />
                <label v-show="errors.has('formCnabConfig.extension_field')">{{
                  errors.first("formCnabConfig.extension_field")
                }}</label>
              </div>
            </div>
            <div class="col-md-6">
              <div :class="['form-group']">
                <label>Situação Carteira</label>
                <select
                  class="form-control select2 hide"
                  style="width: 100%"
                  field-name="Situação Carteira"
                  id="situation_field"
                  name="situation_field"
                >
                  <option value="">Selecione...</option>
                  <option value="0">Carteira</option>
                  <option value="1">Simples</option>
                  <option value="2">Descontada</option>
                  <option value="3">Caucionada</option>
                  <option value="4">Vinculada</option>
                  <option value="5">Com Advogado</option>
                  <option value="6">Judicial</option>
                  <option value="7">Caução Descontada</option>
                  <option value="F">Carteira Protesto</option>
                  <option value="G">Carteira Acordo</option>
                  <option value="H">Cartório</option>
                  <option value="I">Carteira Caixa Loja</option>
                  <option value="J">Carteira Caixa Geral</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div :class="['form-group']">
                <label>VAN</label>
                <select
                  class="form-control select2 hide"
                  style="width: 100%"
                  field-name="VAN"
                  id="van_id"
                  name="van_id"
                >
                  <option value="0">Selecione...</option>
                  <option v-for="van in vans" :value="van.id" :key="van.id">
                    {{ van.description }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-md-12" style="margin-top: 20px">
            <h4>Posições</h4>
          </div>
          <div class="col-md-12">
            <div
              class="
                m-form m-form--label-align-right
                m--margin-top-20 m--margin-bottom-30
              "
            >
              <ul
                class="
                  nav nav-tabs
                  m-tabs-line m-tabs-line--2x m-tabs-line--primary
                "
                role="tablist"
              >
                <li
                  class="nav-item m-tabs__item"
                  v-for="(segment, index) in cnabRemittancesSegments"
                  :key="index"
                >
                  <a
                    :class="[
                      'nav-link',
                      'm-tabs__link',
                      index === 0 ? 'active' : '',
                    ]"
                    data-toggle="tab"
                    :href="'#tab' + index"
                    role="tab"
                    aria-expanded="true"
                  >
                    {{ segment.description }}
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div
                  v-for="(segment, index) in cnabRemittancesSegments"
                  :class="['tab-pane', index === 0 ? 'active' : '']"
                  :id="'tab' + index"
                  role="tabpanel"
                  :key="index"
                >
                  <div class="row">
                    <form
                      novalidate
                      @submit.prevent="addPosition(segment, 'formNewPosition')"
                      class="row"
                      data-vv-scope="formNewPosition"
                    >
                      <div class="row col-md-12">
                        <div class="col-md-6">
                          <div
                            :class="[
                              'form-group',
                              errors.has('formNewPosition.description_pos')
                                ? 'has-danger'
                                : '',
                            ]"
                          >
                            <label>Descrição</label>
                            <input
                              v-model="positionConfig.description"
                              type="text"
                              name="description_pos"
                              autocomplete="new-password"
                              :class="['form-control']"
                              data-vv-as="'Descrição'"
                              v-validate="'required'"
                            />
                            <label
                              v-show="
                                errors.has('formNewPosition.description_pos')
                              "
                              >{{
                                errors.first("formNewPosition.description_pos")
                              }}</label
                            >
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div
                            :class="[
                              'form-group',
                              errors.has('formNewPosition.pos_init')
                                ? 'has-danger'
                                : '',
                            ]"
                          >
                            <label>Pos. Inicial</label>
                            <input
                              v-model="positionConfig.initialPosition"
                              type="number"
                              step="1"
                              name="pos_init"
                              :class="['form-control']"
                              data-vv-as="'Pos. Inicial'"
                              v-validate="'required'"
                            />
                            <label
                              v-show="errors.has('formNewPosition.pos_init')"
                              >{{
                                errors.first("formNewPosition.pos_init")
                              }}</label
                            >
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div
                            :class="[
                              'form-group',
                              errors.has('formNewPosition.pos_finish')
                                ? 'has-danger'
                                : '',
                            ]"
                          >
                            <label>Pos. Final</label>
                            <input
                              v-model="positionConfig.finishPosition"
                              type="number"
                              step="1"
                              name="pos_finish"
                              :class="['form-control']"
                              data-vv-as="'Pos. Final'"
                              v-validate="'required'"
                            />
                            <label
                              v-show="errors.has('formNewPosition.pos_finish')"
                              >{{
                                errors.first("formNewPosition.pos_finish")
                              }}</label
                            >
                          </div>
                        </div>
                        <div class="col-md-2">
                          <label>Validação</label>
                          <select
                            v-model="positionConfig.segmentValidation"
                            :class="['form-control']"
                            style="width: 100%"
                            field-name="validation"
                            id="validation"
                            name="validation"
                          >
                            <option :value="'T'">Recebe validação</option>
                            <option :value="'F'">Não recebe validação</option>
                          </select>
                        </div>
                        <div class="col-md-2">
                          <label>Precisão decimal</label>
                          <input
                            min="0"
                            v-model="positionConfig.decimalPrecision"
                            type="number"
                            name="decimal_precision"
                            :class="['form-control']"
                            data-vv-as="'decimal_precision'"
                          />
                        </div>
                      </div>

                      <div class="row col-md-12">
                        <div class="col-md-10">
                          <div
                            :class="[
                              'form-group',
                              errors.has('formNewPosition.content_config')
                                ? 'has-danger'
                                : '',
                            ]"
                          >
                            <label>Conteúdo</label>
                            <textarea
                              v-model="positionConfig.content"
                              type="text"
                              class="form-control"
                              name="content_config"
                              :class="['form-control']"
                              data-vv-as="'Conteúdo'"
                              required="false"
                            ></textarea>
                            <label
                              v-show="
                                errors.has('formNewPosition.content_config')
                              "
                              >{{
                                errors.first("formNewPosition.content_config")
                              }}</label
                            >
                          </div>
                        </div>

                        <div
                          class="col-md-1"
                          style="padding-top: 25px; text-align: right"
                        >
                          <button
                            type="submit"
                            class="btn m-btn--pill btn-success"
                          >
                            <i class="la la-plus"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <table class="table table-striped m-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Linha</th>
                        <th>Descrição</th>
                        <th>Posição Inicial</th>
                        <th>Posição Final</th>
                        <th>Conteúdo</th>
                        <th>Validação</th>
                        <th>Precisão décimal</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(positionConfig, index) in filterCnabPositions(
                          segment.cnabConfigPositions
                        )"
                        :key="index"
                      >
                        <td class="navigation">
                          <i
                            class="fa fa-sort-up"
                            style="cursor: pointer; display: flex"
                            v-on:click="moveUp(segment, positionConfig)"
                          ></i>
                          <i
                            class="fa fa-sort-down"
                            style="cursor: pointer; display: flex"
                            title="Duplicar"
                            v-on:click="moveDown(segment, positionConfig)"
                          ></i>
                        </td>
                        <td style="vertical-align: middle">
                          {{ positionConfig.line }}
                        </td>
                        <td style="vertical-align: middle">
                          {{ positionConfig.description }}
                        </td>
                        <td style="vertical-align: middle">
                          {{ positionConfig.initialPosition }}
                        </td>
                        <td style="vertical-align: middle">
                          {{ positionConfig.finishPosition }}
                        </td>
                        <td style="vertical-align: middle">
                          {{ positionConfig.content }}
                        </td>
                        <td style="vertical-align: middle">
                          {{
                            positionConfig.segmentValidation === "T"
                              ? "Sim"
                              : "Não"
                          }}
                        </td>
                        <td style="vertical-align: middle">
                          {{ positionConfig.decimalPrecision}}
                        </td>
                        <td class="m--align-right">
                          <a
                            @click="editPosition(positionConfig)"
                            class="
                              m-portlet__nav-link
                              btn
                              m-btn
                              m-btn--hover-accent
                              m-btn--icon
                              m-btn--icon-only
                              m-btn--pill
                            "
                          >
                            <i class="la la-edit"></i>
                          </a>
                          <a
                            @click="removePosition(segment, positionConfig)"
                            class="
                              m-portlet__nav-link
                              btn
                              m-btn
                              m-btn--hover-accent
                              m-btn--icon
                              m-btn--icon-only
                              m-btn--pill
                            "
                          >
                            <i class="la la-trash"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <select
                id="segment_from"
                name="segment_from"
                class="form-control hide"
                style="width: 100%"
              >
                <option :value="undefined">Selecione...</option>
                <option
                  v-for="segment in cnabRemittancesSegments"
                  :key="segment.id"
                  :value="segment.id"
                >
                  {{ segment.description }}
                </option>
              </select>
            </div>
            <div class="col-md-2">
              <span>Para</span>
            </div>
            <div class="col-md-4">
              <select
                id="segment_for"
                name="segment_for"
                lass="form-control hide"
                style="width: 100%"
              >
                <option :value="undefined">Selecione...</option>
                <option
                  v-for="segment in cnabRemittancesSegments"
                  :key="segment.id"
                  :value="segment.id"
                >
                  {{ segment.description }}
                </option>
              </select>
            </div>
            <div class="col-md-2">
              <button
                type="button"
                v-on:click="copyPositions()"
                class="btn m-btn--pill btn-success"
              >
                Copiar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navigation {
  align-items: center !important;
  vertical-align: inherit !important;
  flex-direction: column;
  color: #898b96;
  height: 100%;
}

.navigation i {
  font-size: 18px;
}

.navigation .fa:hover {
  color: black;
}
</style>

<script>
export default {
  data() {
    return {
      cnabRemittanceConfigId: this.$route.params.cnab_remittance_config_id,
      cnabRemittanceConfig: {},
      positionConfig: { deleted: 0 },
      cnabRemittances: [],
      cnabRemittancesSegments: [],
      banks: [],
      vans: [],
      validationContent: {
        required: true,
        regex: /^[^"]+$/,
      },
    };
  },
  mounted: function () {
    blockPage("Carregando os dados...");

    this.loadMethod();
  },
  methods: {
    filterCnabPositions: function (cnabConfigPositions) {
      return cnabConfigPositions.filter((position) => position.deleted == 0);
    },
    loadCnabRemittances: function () {
      let self = this;
      self.$http
        .get(this.$store.state.API_BASE + "cnabremittance/listAll", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          function (result) {
            self.cnabRemittances = result.body.data;
            self.loadBanks();
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        )
        .bind(this);
    },
    loadBanks: function () {
      let self = this;
      self.$http
        .get(
          this.$store.state.API_BASE +
            "bank/listAll/" +
            this.$session.get("company_group_id"),
          { headers: { token: this.$session.get("token") } }
        )
        .then(
          function (result) {
            self.banks = result.body.data;
            self.loadVans();
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        )
        .bind(this);
    },
    loadVans: function () {
      let self = this;
      self.$http
        .get(this.$store.state.API_BASE + "van/listAll", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          function (result) {
            self.vans = result.body.data;
            self.loadCnabRemittanceConfig();
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        )
        .bind(this);
    },
    loadCnabRemittanceConfig: function () {
      let self = this;
      $.ajax({
        method: "GET",
        url:
          self.$store.state.API_BASE +
          "cnabremittanceconfig/listOne/" +
          self.cnabRemittanceConfigId,
        headers: {
          token: self.$session.get("token"),
        },
      })
        .done(function (result) {
          let remittanceField = $("#remittance_field");
          let bankField = $("#bank_field");
          let situationField = $("#situation_field");
          let vanId = $("#van_id");
          self.cnabRemittanceConfig = result.data[0];
          remittanceField.val(self.cnabRemittanceConfig.cnabRemittance.id);
          bankField.val(self.cnabRemittanceConfig.codeBank);
          situationField.val(self.cnabRemittanceConfig.situation);
          vanId.val(self.cnabRemittanceConfig.vanId);
          remittanceField.trigger("change");
          bankField.trigger("change");
          situationField.trigger("change");
          vanId.trigger("change");
          self.loadSegments();
        })
        .fail(function (error) {
          console.log(error.responseText);
          unblockPage();
        });
    },
    loadMethod: function () {
      var self = this;
      $(".select2").select2();
      $("#remittance_field").change(function () {
        var $this = $("#remittance_field");

        if (!$this.val()) {
          $(".form-group:has(#" + $this.attr("id") + ")").addClass(
            "has-danger"
          );
          $("span[aria-labelledby='select2-remittance_field-container']").css(
            "border-color",
            "red"
          );
        } else {
          $("span[aria-labelledby='select2-remittance_field-container']").css(
            "border-color",
            "#ebedf2"
          );
          $(".form-group:has(#remittance_field)").removeClass("has-danger");
        }
      });
      $("#bank_field").change(function () {
        var $this = $("#bank_field");

        if (!$this.val()) {
          $(".form-group:has(#" + $this.attr("id") + ")").addClass(
            "has-danger"
          );
          $("span[aria-labelledby='select2-bank_field-container']").css(
            "border-color",
            "red"
          );
        } else {
          $("span[aria-labelledby='select2-bank_field-container']").css(
            "border-color",
            "#ebedf2"
          );
          $(".form-group:has(#bank_field)").removeClass("has-danger");
        }
      });

      $("#situation_field").change(function () {
        var $this = $("#situation_field");

        if ($("#remittance_field option:selected").attr("data-type") == "1") {
          if (!$this.val()) {
            $(".form-group:has(#" + $this.attr("id") + ")").addClass(
              "has-danger"
            );
            $("span[aria-labelledby='select2-situation_field-container']").css(
              "border-color",
              "red"
            );
          } else {
            $("span[aria-labelledby='select2-situation_field-container']").css(
              "border-color",
              "#ebedf2"
            );
            $(".form-group:has(#situation_field)").removeClass("has-danger");
          }
        }
      });

      self.loadCnabRemittances();
    },
    loadSegments: function () {
      var self = this;
      self.cnabRemittanceConfig.cnabRemittance.id = Number(
        $("#remittance_field").val()
      );
      if (self.cnabRemittanceConfig.cnabRemittance.id) {
        $.ajax({
          method: "GET",
          url:
            self.$store.state.API_BASE +
            "cnabremittance/listOne/" +
            self.cnabRemittanceConfig.cnabRemittance.id,
          headers: {
            token: self.$session.get("token"),
          },
        })
          .done(function (result) {
            var typeDesc = "";
            self.cnabRemittancesSegments = result.data[0].segments;

            self.cnabRemittancesSegments.map((segment) => {
              segment.cnabConfigPositions =
                self.cnabRemittanceConfig.cnabConfigPositions.filter(
                  (value) => {
                    //Deleta atributo circular
                    delete value.segment;
                    return value.idSegment == segment.id;
                  }
                );
            });

            unblockPage();
          })
          .fail(function (error) {
            unblockPage();
          });
      }
    },
    removePosition: function (segment, positionSegment) {
      let self = this;
      positionSegment.deleted = 1;
      segment.cnabConfigPositions.splice(
        segment.cnabConfigPositions.indexOf(positionSegment),
        1,
        positionSegment
      );

      segment.cnabConfigPositions
        .filter((configPosition) => configPosition.deleted == 0)
        .map((configPosition, index) => {
          configPosition.line = index + 1;
        });
    },

    addPosition: function (segment, scope) {
      var self = this;
      var pos = -1;

      if (!segment.cnabConfigPositions) {
        segment.cnabConfigPositions = [];
      }

      self.$validator.validateAll(scope).then(function (result) {
        if (result) {
          pos = segment.cnabConfigPositions.indexOf(self.positionConfig);
          if (self.validPosition(segment, pos)) {
            if (pos < 0) {
              self.positionConfig.line = segment.cnabConfigPositions.length + 1;
              segment.cnabConfigPositions.push(self.positionConfig);
            } else {
              segment.cnabConfigPositions.splice(pos, 1, self.positionConfig);
            }

            self.positionConfig = { deleted: 0 };
            segment.cnabConfigPositions = this._.orderBy(
              segment.cnabConfigPositions,
              (event) => {
                return Number(event["initialPosition"]);
              }
            );
            return;
          }
        }
      });
    },
    validPosition: function (segment, line) {
      let self = this;
      let lRet = true;

      if (
        Number(self.positionConfig.initialPosition) >
        Number(self.positionConfig.finishPosition)
      ) {
        showNotification(
          "Atenção",
          "Posição inicial maior que a Posição final.",
          "warning"
        );
        lRet = false;
      } else {
        for (let i = 0; i < segment.cnabConfigPositions.length; i++) {
          if (line != i) {
            let pos = segment.cnabConfigPositions[i];
            if (
              Number(self.positionConfig.initialPosition) >
                Number(pos.initialPosition) &&
              Number(self.positionConfig.initialPosition) <
                Number(pos.finishPosition)
            ) {
              lRet = false;
            } else if (
              Number(self.positionConfig.initialPosition) ==
                Number(pos.initialPosition) ||
              Number(self.positionConfig.initialPosition) ==
                Number(pos.finishPosition)
            ) {
              lRet = false;
            } else if (
              Number(self.positionConfig.finishPosition) >
                Number(pos.initialPosition) &&
              Number(self.positionConfig.finishPosition) <
                Number(pos.finishPosition)
            ) {
              lRet = false;
            } else if (
              Number(self.positionConfig.finishPosition) ==
                Number(pos.initialPosition) &&
              Number(self.positionConfig.finishPosition) ==
                Number(pos.finishPosition)
            ) {
              lRet = false;
            }

            if (!lRet) {
              if (segment.allowDuplicateLine == "1") {
                lRet = true;
                showNotification(
                  "Atenção",
                  "Posição final já foi informada na linha " +
                    pos.line +
                    " porém o este segmento permite linha duplicada.",
                  "info"
                );
              } else {
                showNotification(
                  "Atenção",
                  "Posição final já foi informada na linha " + pos.line + ".",
                  "warning"
                );
              }
              break;
            }
          }
        }
      }
      return lRet;
    },
    moveUp: function (segment, position) {
      let self = this;
      let line = position.line;
      let ind;

      //percorre para saber a posicao no array
      segment.cnabConfigPositions.forEach((element, index) => {
        if (element.id == position.id) {
          ind = index;
        }
      });

      if (line != 1) {
        line = line - 1;

        position.line = line;
        segment.cnabConfigPositions[ind - 1].line = line + 1;

        segment.cnabConfigPositions = self.arrayMove(
          segment.cnabConfigPositions,
          ind,
          ind - 1
        );
      }
    },
    moveDown: function (segment, position) {
      let self = this;
      let line = position.line;
      let ind;

      //percorre para saber a posicao no array
      segment.cnabConfigPositions.forEach((element, index) => {
        if (element.id == position.id) {
          ind = index;
        }
      });

      if (line != segment.cnabConfigPositions.length) {
        line = line + 1;

        position.line = line;
        segment.cnabConfigPositions[ind + 1].line = line - 1;

        segment.cnabConfigPositions = self.arrayMove(
          segment.cnabConfigPositions,
          ind,
          ind + 1
        );
      }
    },
    /*
     *
     *   ArrayMove
     *   Funcao padrao para o movimento, utiliza dos parametros oldindex e newindex para modificar a posicao no array
     *   esse metodo pode ser utilizado com posicoes negativas (assim como os metodos nativos) devido as duas funcoes while
     *   observacao:
     *   array.push(undefined) força o array criado a ficar do tamanho certo para ser usado
     */
    arrayMove: function (array, oldIndex, newIndex) {
      while (oldIndex < 0) {
        oldIndex += array.length;
      }

      while (newIndex < 0) {
        newIndex += array.length;
      }

      if (newIndex >= array.length) {
        let k = newIndex - array.length + 1;

        while (k--) {
          array.push(undefined);
        }
      }

      array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
      return array;
    },
    editPosition: function (editingPositionSegment) {
      var self = this;
      self.positionConfig = editingPositionSegment;
    },
    copyPositions: function () {
      let self = this;

      let idSegmentFrom = $("#segment_from").val();
      let idSegmentFor = $("#segment_for").val();

      if (idSegmentFor && idSegmentFrom) {
        blockPage("Copiando posições...");
        $.ajax({
          method: "POST",
          url:
            self.$store.state.API_BASE + "cnabremittanceconfig/copy-positions",
          headers: {
            token: self.$session.get("token"),
          },
          data: {
            idSegmentFrom: idSegmentFrom,
            idSegmentFor: idSegmentFor,
          },
        })
          .done(function (result) {
            if (!result.error) {
              showNotification("Sucesso", "Posições Copiadas!", "success");
            } else {
              showNotification("Atenção", result.message, "danger");
              return false;
            }
            unblockPage();
          })
          .fail(function (error) {
            showNotification("Atenção", result.message, "danger");
            unblockPage();
          });
      }
    },
    saveConfig: function (scope) {
      let self = this;
      let remittanceField = $("#remittance_field");
      let bankField = $("#bank_field");
      let situationField = $("#situation_field");
      let vanId = $("#van_id");
      let bankOk = true;
      let remittanceOk = true;

      self.cnabRemittanceConfig.vanId = vanId.val();

      if (!remittanceField.val()) {
        $(".form-group:has(#" + remittanceField.attr("id") + ")").addClass(
          "has-danger"
        );
        $("span[aria-labelledby='select2-remittance_field-container']").css(
          "border-color",
          "red"
        );
        remittanceOk = false;
      } else {
        $("span[aria-labelledby='select2-remittance_field-container']").css(
          "border-color",
          "#ebedf2"
        );
        $(".form-group:has(#remittance_field)").removeClass("has-danger");
        self.cnabRemittanceConfig.cnabRemittance.id = remittanceField.val();
      }
      if (!bankField.val()) {
        $(".form-group:has(#" + bankField.attr("id") + ")").addClass(
          "has-danger"
        );
        $("span[aria-labelledby='select2-bank_field-container']").css(
          "border-color",
          "red"
        );
        bankOk = false;
      } else {
        $("span[aria-labelledby='select2-bank_field-container']").css(
          "border-color",
          "#ebedf2"
        );
        $(".form-group:has(#bank_field)").removeClass("has-danger");
        self.cnabRemittanceConfig.codeBank = bankField.val();
      }
      if ($("#remittance_field option:selected").attr("data-type") == "1") {
        if (!situationField.val()) {
          $(".form-group:has(#" + situationField.attr("id") + ")").addClass(
            "has-danger"
          );
          $("span[aria-labelledby='select2-situation_field-container']").css(
            "border-color",
            "red"
          );
          bankOk = false;
        } else {
          $("span[aria-labelledby='select2-situation_field-container']").css(
            "border-color",
            "#ebedf2"
          );
          $(".form-group:has(#situation_field)").removeClass("has-danger");
          self.cnabRemittanceConfig.situation = situationField.val();
        }
      }

      self.$validator.validateAll(scope).then((result) => {
        if (result && bankOk && remittanceOk) {
          blockPage("Salvando as informações...");
          self.cnabRemittanceConfig.cnabRemittancesSegments =
            self.cnabRemittancesSegments;

          $.ajax({
            method: "POST",
            url: self.$store.state.API_BASE + "cnabremittanceconfig/edit",
            headers: {
              token: self.$session.get("token"),
            },
            data: {
              cnabRemittanceConfig: JSON.stringify(self.cnabRemittanceConfig),
            },
          })
            .done(function (result) {
              if (!result.error) {
                showNotification(
                  "Sucesso",
                  "Configuração alterada!",
                  "success"
                );
                self.$router.replace({ name: "cnabremittanceconfig" });
              } else {
                showNotification("Atenção", result.message, "danger");
                return false;
              }
            })
            .fail(function (error) {
              showNotification("Atenção", result.message, "danger");
              unblockPage();
            });
        }
      });
    },
  },
};
</script>