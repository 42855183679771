import Repository from '@/repositories/base/Repository';
import IInternalTableSaveDataArrayRequestParams from '@/repositories/parameters/IInternalTableSaveDataArrayRequestParams';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';

export default class CashFlowActionRepository extends Repository {
  public async release(
    companyGroupId: number,
    grouperType: CashFlowTablesEnum,
    items: IInternalTableSaveDataArrayRequestParams[],
  ): Promise<void> {
    await this.datasource.patch(
      `company-groups/${companyGroupId}/cash-flow/predicted/actions/release`,
      {
        grouper_table: grouperType,
        items,
      },
    );
  }
}
