































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
  Ref,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';
import { formateErrorForNotification } from '../utils';

@Component({})
export default class DdaConciliationInvoiceSupplier extends Vue {
  @Ref('form-supplier') readonly form!: VForm;

  @Prop(Boolean) readonly open!: boolean;

  @Prop(String) company!: string;

  @Emit()
  close(): boolean {
    return false;
  }

  @Emit()
  next(): string {
    this.close();
    return this.supplier;
  }

  @Watch('searchSuppliers')
  changedSearchSuppliers(search: string, oldSearch: string): void {
    if (search && search.length && search != oldSearch) {
      this.loadSuppliersDebounce(search);
    } else {
      clearTimeout(this.timerSuppliers);
    }
  }

  readonly DDAConciliationRepository:
    DDAConciliationRepository = new DDAConciliationRepository();

  supplier: string = '';
  searchSuppliers: string = '';
  loadingSuppliers: boolean = false;
  suppliers: Array<SelectOptions> = [];
  timerSuppliers!: ReturnType<typeof setTimeout>;

  valueRequerid = (value: string) => (!!value && value.length > 0) || 'Campo obrigatório!';

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  async loadSuppliers(search: string): Promise<void> {
    try {
      this.loadingSuppliers = true;

      const suppliers = await this.DDAConciliationRepository.getSuppliers(
        this.groupId, [+this.company], search,
      );

      this.suppliers.push(...suppliers);
    } catch (error: any) {
      const errorMessage = formateErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loadingSuppliers = false;
    }
  }

  loadSuppliersDebounce(search: string): void {
    clearTimeout(this.timerSuppliers);

    this.timerSuppliers = setTimeout(() => {
      this.loadSuppliers(search);
    }, 500);
  }

  handleNext() {
    if (this.form.validate()) this.next();
  }
}
