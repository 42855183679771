import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-item py-4 px-6",class:{
    'module-item--not-available': !_vm.moduleInfo.isPurchased,
    'module-item--activated': _vm.active,
  }},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"module-name d-flex align-center"},[_c(VIcon,{attrs:{"color":_vm.iconColor,"size":"24"}},[_vm._v(_vm._s(_vm.moduleInfo.icon))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.moduleInfo.name))])],1),(_vm.moduleInfo.isPurchased)?_c(VSwitch,{staticClass:"ma-0",attrs:{"hide-details":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}}):_c('span',{staticClass:"not-available-text text"},[_vm._v(" Não contratado ")])],1),_c('div',{staticClass:"module-item-text my-2 text"},[_vm._v(" "+_vm._s(_vm.moduleInfo.description)+" ")]),_c('div',{staticClass:"module-item-buttons d-flex justify-end"},[_c(VBtn,{attrs:{"small":"","color":_vm.buttonColor,"disabled":!_vm.moduleInfo.isPurchased},on:{"click":_vm.handlePermissonsClick}},[_vm._v(" VER PERMISSÕES ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }