import { API_PREFIX } from '@config/api';
import Http from './Http';

/**
 * @type Api
 */
export default class Api extends Http {
  /**
   * @type {String}
   */
  static base = API_PREFIX;

  /**
   * CONSTRUCTOR
   * If you have a API system you can put the /api route to the base variable
   * thats function implements Http.normalize to return the api.base with "/api" prefix.
   *
   * @param {String} path
   * @param {Object} options
   * @param {Object} http
   * @return {this}
   */
  constructor(path = '', options = {}, http = null) {
    super(Api.normalize(Api.base, path), options, http);
  }
}
