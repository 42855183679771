import ESubscriberType from '@/domain/enums/SubscriberType';

export default class BillingSignatureSubscriber {
  constructor(
    public name: string,
    public document: string,
    public mail: string,
    public type: ESubscriberType,
  ) {}

  static make(data: any): BillingSignatureSubscriber {
    return new BillingSignatureSubscriber(
      data.name,
      data.document,
      data.mail,
      data.type === 'part' ? ESubscriberType.PART : ESubscriberType.WITNESS,
    );
  }
}
