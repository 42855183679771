import vuetify from '@/plugins/vuetify';
import InnSnackBar from './InnSnackBar.vue';

const plugin = {
  install(Vue) {
    const VueSnackBar = Vue.extend(InnSnackBar);
    const vm = new VueSnackBar({
      vuetify,
    }).$mount();
    document.body.appendChild(vm.$el);

    Vue.prototype.$notification = {
      error: vm.error,
      info: vm.info,
      success: vm.success,
      warn: vm.warn,
    };
  },
};

export default plugin;
