import Repository from '@/repositories/base/Repository';
import IUpdateERPRequestParams from '@/repositories/parameters/IUpdateERPRequestParams';
import IUpdateInternalTablesRequestParams from '@/repositories/parameters/IUpdateInternalTablesRequestParams';
import IInternalTableSaveDataRequestParams from '@/repositories/parameters/IInternalTableSaveDataRequestParams';
import InvalidResponseException from '@/repositories/exceptions/InvalidResponseException';

export default class ERPRepository extends Repository {
  async updateExternalERP<T = object>(
    companyGroupId: number,
    companyId: number,
    params: IUpdateERPRequestParams<T>,
  ): Promise<void> {
    await this.datasource.patch(
        `company-groups/${companyGroupId}/erps`,
        params,
        {
          headers: { 'company-id': companyId },
        },
    );
  }

  async updateInternalERP(
    params: IUpdateInternalTablesRequestParams,
  ): Promise<void> {
    const { data: request } = await this.datasource.send('internaltable/updateImportTable', params);

    if (request && typeof request === 'object' && request.error) {
      throw new InvalidResponseException();
    }
  }

  async saveDataInternalTable(
    params: IInternalTableSaveDataRequestParams,
  ): Promise<void> {
    const { data: request } = await this.datasource.send('internaltable/savedata', params);

    if (request && typeof request === 'object' && request.error) {
      throw new InvalidResponseException();
    }
  }

  async getInternalTableData(
    params: Omit<IInternalTableSaveDataRequestParams, '_data'>,
  ): Promise<Record<string, any>> {
    const { data: request } = await this.datasource.send('internaltable/getdata', params);

    if (request && typeof request === 'object' && request.error) {
      throw new InvalidResponseException();
    }

    return request.data;
  }
}
