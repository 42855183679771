










































































































import {
  Vue,
  Component,
  Ref,
  Watch,
  Emit,
} from 'vue-property-decorator';

import { VForm } from '@/types/VForm';

import StatusType from '@/domain/enums/DDAConciliationStatusType';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import IDDAConciliationFilter from '@/domain/interfaces/IDDAConciliationFilter';
import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';

import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import FilterParameterDDAConciliationList from '@/domain/models/filter-parameters/FilterParameterDDAConciliationList';

import { validateDateRange } from '@/utils/date';
import { formateErrorForNotification } from '../utils';

interface DateRangeError {
  due: boolean,
  process: boolean,
  initialMessage: string,
  endMessage: string,
}

@Component
export default class DdaConciliationFilter extends Vue {
  @Ref('form-filter')
  readonly formFilter!: VForm;

  @Watch('searchSuppliers')
  changedSearchSuppliers(search: string) {
    if (search && search.length) {
      this.loadSuppliersDebounce(search);
    } else {
      clearTimeout(this.timerSuppliers);
    }
  }

  @Emit('validate')
  emitValidate(): IDDAConciliationFilter {
    return this.data;
  }

  readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();

  readonly DDAConciliationRepository:
    DDAConciliationRepository = new DDAConciliationRepository();

  data: IDDAConciliationFilter = {} as IDDAConciliationFilter;

  loading: boolean = false;

  loadingSuppliers: boolean = false;
  timerSuppliers!: ReturnType<typeof setTimeout>;
  searchSuppliers: string = '';

  companies: Array<SelectOptions> = [];
  suppliers: Array<SelectOptions> = [];

  status: Array<SelectOptions> = [
    { text: 'Pendente', value: StatusType.PENDING },
    { text: 'Conciliado', value: StatusType.CONCILIATED },
    { text: 'Divergente', value: StatusType.DIVERGENCE },
    { text: 'ERP Não Encontrado', value: StatusType.NOT_FOUND_ERP },
    { text: 'ERP Com Código de Barras', value: StatusType.ERP_WITH_BARCODE },
    { text: 'DDA Não Encontrado', value: StatusType.NOT_FOUND_DDA },
  ];

   errorDateRange: DateRangeError = {
    due: false,
    process: false,
    initialMessage: 'Data inicial deve ser menor ou igual a data final.',
    endMessage: 'Data final deve ser menor ou igual a data inicial.',
  };

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  valueRequerid = (value: string | number) => !!value || 'Campo obrigatório.';

  mounted() {
   this.loadData();
  }

  changedCompanies(): void {
    this.suppliers = [];
    this.data.suppliers = [];
  }

  async loadData() {
    try {
      this.$dialog.startLoading();
      this.loading = true;

      await this.loadCompanies();
      await this.loadFilterParameters();
    } catch (error: any) {
      const errorMessage = formateErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  async loadCompanies(): Promise<void> {
    const companies = await this.DDAConciliationRepository.getCompanies();
    this.companies = companies;
  }

  async loadSuppliers(companies: Array<number>, search: string): Promise<void> {
    try {
      this.loadingSuppliers = true;

      const suppliers = await this.DDAConciliationRepository.getSuppliers(
        this.groupId, companies, search,
      );

      this.suppliers.push(...suppliers);
    } catch (error: any) {
      const errorMessage = formateErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loadingSuppliers = false;
    }
  }

  loadSuppliersDebounce(search: string): void {
    clearTimeout(this.timerSuppliers);
    const { companies } = this.data;
    this.timerSuppliers = setTimeout(() => {
      this.loadSuppliers(companies, search);
    }, 500);
  }

  validateAllDateRanges(data: IDDAConciliationFilter): boolean {
    const initialDue = data.initialDueDate;
    const endDue = data.endDueDate;
    const initialProcessing = data.initialProcessingDate;
    const endProcessing = data.endProcessingDate;

    const areValidDueDates = validateDateRange(initialDue, endDue);
    const areValidProcessingDates = validateDateRange(initialProcessing, endProcessing);

    if (!areValidDueDates) {
      this.errorDateRange.due = true;
      this.$notification.error('Intevalo de emissão inválido!');
    } else {
      this.errorDateRange.due = false;
    }

     if (!areValidProcessingDates) {
      this.errorDateRange.process = true;
      this.$notification.error('Intevalo de processamento inválido!');
    } else {
      this.errorDateRange.process = false;
    }

    if (!areValidDueDates && !areValidProcessingDates) {
      this.$notification.error('Intevalo de emissão e de processamento inválidos!');
    }

    return areValidDueDates && areValidProcessingDates;
  }

  validate(): void {
    const isValidDateRange = this.validateAllDateRanges(this.data);
    const isValidFilter = this.formFilter.validate();
    if (isValidDateRange && isValidFilter) {
      this.emitValidate();
    }
  }

  async loadFilterParameters(): Promise<void> {
    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.DDA_CONCILIATION_LIST);
      const definedFilters = FilterParameterDDAConciliationList.make(filterParameters);

      this.data.initialDueDate = definedFilters.initial_due_date;
      this.data.endDueDate = definedFilters.end_due_date;
      this.data.initialProcessingDate = definedFilters.initial_processing_date;
      this.data.endProcessingDate = definedFilters.end_processing_date;
      this.data.companies = definedFilters.companies;
      this.data.status = definedFilters.status;
      this.data.sort = definedFilters.sort;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    }
  }
}
