import PaymentCondition from '@/domain/models/PaymentCondition';
import Repository from '@/repositories/base/Repository';

export default class PaymentConditionRepository extends Repository {
  public async getPaymentConditionsByCode(
      companyGroupId: number,
      company: number,
      search: string,
  ): Promise<PaymentCondition[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/payment-conditions/${search}`, {
      headers: { 'company-id': company },
    });

    return response.data.map(PaymentCondition.make);
  }

  public async getPaymentConditions(
      companyGroupId: number,
      companyIds: number[],
  ): Promise<PaymentCondition[]> {
    const { status, data: response } = await this.datasource.get(`company-groups/${companyGroupId}/payment-conditions`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    if (this.isStatusOK(status)) {
      return response.data.map(PaymentCondition.make);
    }

    throw new Error('Ops, algo inesperado ocorreu na listagem das condições de pagamento.');
  }
}
