import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import MainGroupersScreensShownEnum from '@/views/cashFlowGroupers/mainGrouper/enums/MainGroupersScreensShownEnum';

export default class MainGrouper {
  constructor(
    public id: number,
    public name: string,
    public companyId: number,
    public description: string,
    public status: number,
    public table: CashFlowTablesEnum,
    public screensToShown: MainGroupersScreensShownEnum[],
  ) { }

  static make(data: any): MainGrouper {
    return new MainGrouper(
      data.id,
      data.name,
      data.company_id,
      data.description,
      data.status ? 1 : 0,
      data.selected_table,
      data.screens_to_shown,
    );
  }
}
