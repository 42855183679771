<template>
	<v-subheader class="mb-6 mt-2">
		<div v-if="returnPage == true">
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-on="on"
						v-bind="attrs"
						icon
						@click="returnToPreviousRoute"
						class="mr-3"
					>
						<v-icon> mdi-arrow-left </v-icon>
					</v-btn>
				</template>

				<span>Voltar</span>
			</v-tooltip>
		</div>
		<div v-else>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-on="on"
						v-bind="attrs"
						icon
						@click="goToHomePage"
					>
						<v-icon> mdi-home-outline </v-icon>
					</v-btn>
				</template>
				<span>Página inicial</span>
			</v-tooltip>
		</div>
		<span class="text-h6 font-weight-bold ml-3">{{ pageTitle }}</span>
	</v-subheader>
</template>

<script>
export default {
	name: 'Breadcrumbs',
	props: {
		pageTitle: String,
		returnPage: Boolean,
	},
	methods: {
		goToHomePage() {
			this.$router.replace({ name: 'dashboard' });
		},
		returnToPreviousRoute() {
			this.$router.go(-1);
		},
	},
};
</script>

<style>

</style>
