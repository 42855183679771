


























































import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import VMenuOptions from '@/types/VMenuOptions';

@Component
export default class ButtonFilterMenuOptions extends Vue {
  @Prop({
    type: Array,
    default: () => ([]),
  }) readonly options!: VMenuOptions[];

  @Prop({
    type: Object,
    default: null,
  }) readonly definedOption!: VMenuOptions | null;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly showClearButton!: Boolean;

  @Prop({
    type: String,
    default: '',
  }) readonly tooltipText!: String;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly dense!: Boolean;

  @Watch('definedOption')
  applyNewOption(option: VMenuOptions | null) {
    this.actualSelectedOption = option;
  }

  public actualSelectedOption: VMenuOptions | null = null;

  constructor() {
    super();
  }

  public get hasSelectedOption(): boolean {
    if (this.actualSelectedOption === null) {
      return false;
    }

    return Object.keys(this.actualSelectedOption).length > 0;
  }

  public get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  public get buttonHeight(): string {
    return this.dense || this.$vuetify.breakpoint.smAndDown ? '40px' : '48px';
  }

  public emitChangeOfValueOnOption(selectedOption: VMenuOptions | null): void {
    this.actualSelectedOption = selectedOption;
    this.$emit('on-change-value-in-option', this.actualSelectedOption);
  }
}
