





















import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

@Component
export default class IconTooltip extends Vue {
  @Prop(String) readonly color!: string;
  @Prop(String) readonly icon!: string;
  @Prop(String) readonly text!: string;

  @Prop(String) readonly position!: 'left' | 'right' | 'bottom' | 'top' | '';

  public isLeft: boolean = false;
  public isRight: boolean = false;
  public isTop: boolean = false;
  public isBottom: boolean = false;

  public created(): void {
    this.setTooltipPosition(this.position);
  }

  public setTooltipPosition(position: string): void {
    switch (position) {
      case 'left':
        this.isLeft = true;
        break;
      case 'right':
        this.isRight = true;
        break;
      case 'bottom':
        this.isBottom = true;
        break;
      case 'top':
        this.isTop = true;
        break;
      default:
        this.isBottom = true;
        break;
    }
  }
}
