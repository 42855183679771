export default class FilterParameterInvoiceList {
  public constructor(
    public startEmissionDate: string,
    public finalEmissionDate: string,
    public startDueDate: string,
    public finalDueDate: string,
    public companyIds: number[],
  ) {}

  public static make(data: any): FilterParameterInvoiceList {
    return new FilterParameterInvoiceList(
      data.start_emission_date ?? '',
      data.final_emission_date ?? '',
      data.start_due_date ?? '',
      data.final_due_date ?? '',
      data.company_ids ? JSON.parse(data.company_ids) : [],
    );
  }
}
