<template>
	<page-container>
		<breadcrumbs :page-title="pagetitle" returnPage/>
		<v-form
			ref="save_form"
			v-model="valid"
		>
			<form-structure>
				<template v-slot:content>
					<v-col md="6" xs="12" sm="12">
						<api-banking-select
							v-model="apibankingconfig.api"
							:rules="[ ...rules.required ]"
							:disabled="!serviceIsNewOrNotDefined()"
						/>
					</v-col>

					<v-col md="6" class="d-none d-md-flex"></v-col>

					<v-col md="6" xs="12" sm="12">
						<api-banking-service-select
							:api="apibankingconfig.api"
							v-model="apibankingconfig.id"
							@change="loadFields"
							:rules="[ ...rules.required ]"
							:disabled="!serviceIsNewOrNotDefined()"
						/>
					</v-col>

					<v-col md="6" class="d-none d-md-flex"></v-col>

					<v-col md="6" xs="12" sm="12">
						<sharing-type-select
							v-model="apibankingconfig.company_type"
							:rules="[ ...rules.required ]"
							:disabled="!serviceIsNewOrNotDefined()"
						/>
					</v-col>

					<v-col md="6" xs="12" sm="12">
						<company-select
							v-if="apibankingconfig.company_type == 'C'"
							v-model="apibankingconfig.company_type_id"
							:rules="[ mustDefineIfTypeIsCompany() ]"
							:disabled="!serviceIsNewOrNotDefined()"
						/>
					</v-col>

					<v-col md="6" xs="12" sm="12">
						<company-group-select
							v-if="apibankingconfig.company_type == 'G'"
							v-model="apibankingconfig.company_type_id"
							:rules="[ mustDefineIfTypeIsCompanyGroup() ]"
							:disabled="!serviceIsNewOrNotDefined()"
						/>
					</v-col>

					<v-col
						cols="12"
						class="mt-n12"
						v-if="valid && sharingConfigurationDefined()"
					>
						<span
							class="error--text text-caption mb-4 ml-2"
							v-if="!valid_fields"
						>
							Alguns dos campos necessários do sistema não foram preenchidos. por favor, preencha-os
						</span>
						<save-fields-table
							v-model="apibankingconfig.details"
							:banking-fields="apibankingconfig.fields"
							@validate="validateFields"
						/>
					</v-col>

					<v-col
						cols="12"
						v-else
					>
						<disclaimer
							title="Campos não definidos"
							subtitle="Preencha todos os campos antes de descrever os relacionamentos com a api"
						/>
					</v-col>
				</template>

				<template v-slot:actions>
					<form-cancel-button/>

					<form-save-button
						label="salvar"
						:valid="valid && valid_fields"
						@click="save"
					/>
				</template>
			</form-structure>
		</v-form>
	</page-container>
</template>

<script>
import FormCancelButton from '@/components/buttons/FormCancelButton.vue';
import FormSaveButton from '@/components/buttons/FormSaveButton.vue';
import FormStructure from '@/components/defaults/FormStructure.vue';
import PageContainer from '@/components/defaults/PageContainer.vue';
import Disclaimer from '@/components/defaults/Disclaimer.vue';

// eslint-disable-next-line
import ApiBankingService from '@/services/apibanking.service';
import ApiBankingSelect from './components/ApiBankingSelect.vue';
import ApiBankingServiceSelect from './components/ApiBankingServiceSelect.vue';
import SharingTypeSelect from './components/SharingTypeSelect.vue';
import CompanySelect from './components/CompanySelect.vue';
import CompanyGroupSelect from './components/CompanyGroupSelect.vue';
import Breadcrumbs from '../../components/defaults/Breadcrumbs.vue';
import SaveFieldsTable from './SaveFieldsTable.vue';

export default {
	components: {
		PageContainer,
		FormStructure,
		FormCancelButton,
		FormSaveButton,
		ApiBankingSelect,
		ApiBankingServiceSelect,
		SharingTypeSelect,
		CompanySelect,
		CompanyGroupSelect,
		Breadcrumbs,
		SaveFieldsTable,
		Disclaimer,
	},
	name: 'ApiBankingConfigSave',
	data() {
		return {
			apibankingconfig: {
				name: '',
				details: [],
			},
			valid: false,
			valid_fields: false,
			rules: {
				required: [(value) => !!value || 'Campo obrigatório.'],
			},
		};
	},
	created() {
		if (!this.serviceIsNewOrNotDefined()) {
			this.loadConfiguratedApiBankingConfig();
		}
	},
	computed: {
		pagetitle() {
			return this.serviceIsNewOrNotDefined() ? 'Criação de Configuração de API' : 'Edição de Configuração de API';
		},
	},
	methods: {
		validateFields(valid) {
			this.valid_fields = valid;
		},
		sharingConfigurationDefined() {
			return this.apibankingconfig.company_type_id
				&& this.apibankingconfig.company_type;
		},
		mustDefineIfTypeIsCompany() {
			if (this.apibankingconfig.company_type == 'C') {
				return !!this.apibankingconfig.company_type_id || 'Defina uma empresa';
			}

			return true;
		},
		mustDefineIfTypeIsCompanyGroup() {
			if (this.apibankingconfig.company_type == 'G') {
				return !!this.apibankingconfig.company_type_id || 'Defina o grupo de empresa';
			}

			return true;
		},
		async loadConfiguratedApiBankingConfig() {
			try {
				this.loading = true;

				const { service, type, company } = this.$route.params;

				const apiBankingService = new ApiBankingService();

				const result = await apiBankingService.configurated().show(service, type, company);

				this.apibankingconfig = result.data;

				this.serializeConfiguratedApi();
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
				this.$forceUpdate();
			}
		},

		async loadFields(id) {
			try {
				this.loading = true;

				const apiBankingService = new ApiBankingService();
				const { api } = this.apibankingconfig;

				const result = await apiBankingService.field(api.id, id).index();

				this.apibankingconfig.fields = result.data;
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
				this.$forceUpdate();
			}
		},

		serializeConfiguratedApi() {
			const [relatedValues] = this.apibankingconfig.details;

			if (!relatedValues
				|| !relatedValues.company_type_id
				|| !relatedValues.company_type) {
				return;
			}

			this.apibankingconfig.company_type = relatedValues.company_type;
			this.apibankingconfig.company_type_id = relatedValues.company_type_id;
		},

		cancel() {
			this.$router.push({ name: 'ApiBankingConfig' });
		},
		save() {
			if (this.serviceIsNewOrNotDefined()) {
				this.create();
			} else {
				this.update();
			}
		},
		async update() {
			try {
				this.loading = true;

				const apiBankingService = new ApiBankingService();

				const result = await apiBankingService
					.configurated()
					.update(this.apibankingconfig);

				this.apibankingconfig = result.data;

				// eslint-disable-next-line
				showNotification('Sucesso', 'Configuração Salva', 'success');

				this.$router.push({ name: 'APIConfigurations' });
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
		async create() {
			try {
				this.loading = true;

				const apiBankingService = new ApiBankingService();

				const result = await apiBankingService
					.configurated()
					.store(this.apibankingconfig);

				this.apibankingconfig = result.data;

				// eslint-disable-next-line
				showNotification('Sucesso', 'Configuração Salva','success');

				this.$router.push({ name: 'APIConfigurations' });
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
		serviceIsNewOrNotDefined() {
			return !this.$route.params.service || this.$route.params.service == 'new';
		},
	},
};
</script>

<style>

</style>
