import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class ModelManagementMixin<T> extends Vue {
  @Prop() readonly value! : T;
  model : T = this.value;

  @Watch('value')
  onValueChanged(newValue : T) {
    this.model = newValue;
  }

  @Watch('model')
  onModelChanged(newModel : T) {
    this.$emit('input', newModel);
  }
}
