enum IntegratorJobsEnum {
  synchronizer_supplier = 'synchronizer_supplier',
  synchronizer_financial_nature = 'synchronizer_financial_nature',
  synchronizer_customer = 'synchronizer_customer',
  synchronizer_currency = 'synchronizer_currency',
  synchronizer_cost_center = 'synchronizer_cost_center',
  synchronizer_bank_transaction = 'synchronizer_bank_transaction',
  synchronizer_bank_balance = 'synchronizer_bank_balance',
  synchronizer_bank = 'synchronizer_bank',
  synchronizer_accounting_item = 'synchronizer_accounting_item',
  synchronizer_account_receivable = 'synchronizer_account_receivable',
  synchronizer_account_payable = 'synchronizer_account_payable',
  synchronizer_value_class = 'synchronizer_value_class',
  update_integrations_in_inncash = 'update_integrations_in_inncash',
}

export default IntegratorJobsEnum;
