import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.model.open),callback:function ($$v) {_vm.$set(_vm.model, "open", $$v)},expression:"model.open"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c(VRow,{attrs:{"justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_vm._v(" Visualizar Títulos ")]),_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c(VCardText,[(_vm.items.length)?_c(VRow,[_c(VSimpleTable,{staticClass:"w-100",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Data")]),_c('th',[_vm._v("Nome")]),_c('th',[_vm._v("Valor")]),_c('th',[_vm._v("Dados Bancários")]),_c('th',[_vm._v("Ocorrência")]),_c('th',[_vm._v("Observação")]),_c('th',{staticClass:"text-right"},[_vm._v("Comprovante")])])]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:("log-item-" + index)},[_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.value))+" ")]),_c('td',[_vm._v(" "+_vm._s(JSON.stringify(item.bank))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.occurrence)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.observation)+" ")]),_c('td',{staticClass:"text-right"},[(item.occurrence === '00')?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","icon":""},on:{"click":function () { return _vm.download(item.id); }}},Object.assign({}, onTooltip)),[_c(VIcon,{attrs:{"dense":""}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v("Baixar Comprovante")])]):_vm._e()],1)])}),0)]},proxy:true}],null,false,993150573)})],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_vm._v(" "+_vm._s(_vm.loading ? "Carregando..." : "Nenhum resultado encontrado.")+" ")])],1)],1),_c(VCardActions,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"warning","depressed":"","large":"","block":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }