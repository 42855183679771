















import { Component, Vue, Watch } from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import VDataTableHeader from '@/types/IVDataTableHeader';
import BillingPendencyRepository from '@/repositories/BillingPendencyRepository';
import BillingPendencyTransfer from '@/domain/models/BillingPendencyTransfer';
import BillingPendencyStore from './store/BillingPendencyStore';

@Component
export default class BillingPendencyTransfers extends Vue {
  @Watch('store.state.approve')
  public onApprove(approve: boolean) {
    if (approve) {
      this.approveTranfers();
    }
  }

  @Watch('store.state.disapprove')
  public onDisapprove(disapprove: boolean) {
    if (disapprove) {
      this.disapproveTranfers();
    }
  }

  public readonly billingPendencyRepository: BillingPendencyRepository =
    new BillingPendencyRepository();

  public store = new BillingPendencyStore();

  public loading: boolean = false;

  public items: Array<BillingPendencyTransfer> = [];
  public selecteds: Array<BillingPendencyTransfer> = [];

  public headers: Array<VDataTableHeader> = [
    {
      text: 'Solicitante',
      value: 'requester',
      sortable: true,
    },
    {
      text: 'Operador Origem',
      value: 'oldOperator',
      sortable: true,
    },
    {
      text: 'Operador Destino',
      value: 'newOperator',
      sortable: true,
    },
    {
      text: 'Total de Pendências',
      value: 'pendencies',
      sortable: true,
    },
  ];

  public get groupId(): number {
    return this.$session.get('company_group_id');
  }

  public created(): void {
    if (!this.store.state.manager) {
      this.$router.replace({ name: 'BillingPendencyList' });
      return;
    }

    this.loadTranfers();
  }

  public reload(): void {
    this.selecteds = [];
    this.loadTranfers();
  }

  public async loadTranfers(): Promise<void> {
    try {
      this.loading = true;

      this.items = await this.billingPendencyRepository.getPendencyTransfers(
        this.groupId,
      );
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async approveTranfers(): Promise<void> {
    if (!this.selecteds.length) {
      this.$notification.warn('Nenhuma transfêrencia selecionada!');
      this.store.state.approve = false;
      return;
    }

    try {
      this.$dialog.startLoading();

      await Promise.all(
        this.selecteds.map(async (selected) => {
          const id = selected.economicGroupId ?? selected.customerId;

          if (id) {
            await this.billingPendencyRepository.approvePendencyTransfers(
              this.groupId,
              id,
              selected.id,
            );
          }
        }),
      );

      this.reload();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.store.state.approve = false;
      this.$dialog.stopLoading();
    }
  }

  public async disapproveTranfers(): Promise<void> {
    if (!this.selecteds.length) {
      this.$notification.warn('Nenhuma transfêrencia selecionada!');
      this.store.state.disapprove = false;
      return;
    }

    try {
      this.$dialog.startLoading();

      await Promise.all(
        this.selecteds.map(async (selected) => {
          const id = selected.economicGroupId ?? selected.customerId;

          if (id) {
            await this.billingPendencyRepository.disapprovePendencyTransfers(
              this.groupId,
              id,
              selected.id,
            );
          }
        }),
      );

      this.reload();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.store.state.disapprove = false;
      this.$dialog.stopLoading();
    }
  }
}
