












































import {
  Component,
  Emit,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { formatErrorForNotification } from '@/utils/error';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import EconomicGroupRepository from '@/repositories/EconomicGroupRepository';
import EconomicGroup from '@/domain/models/EconomicGroup';

@Component
export default class EconomicGroupDeleteDialog
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);

  @Prop({
    type: Object as () => EconomicGroup,
    required: true,
    default: {} as EconomicGroup,
  }) group!: EconomicGroup;

  @Emit('close')
  close(reload: boolean = false) {
    this.model = false;
    this.loading = false;

    return reload;
  }

  public readonly economicGroupRepository
    : EconomicGroupRepository = new EconomicGroupRepository();

  public loading: boolean = false;

  public get maxWidth(): string {
    if (this.$vuetify.breakpoint.xs) return '100%';

    if (this.$vuetify.breakpoint.smAndDown) return '80%';

    if (this.$vuetify.breakpoint.mdAndDown) return '60%';

    return '40%';
  }

  public save(): void {
    this.deleteContact(this.group.id);
  }

  public async deleteContact(id: number): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      await this.economicGroupRepository
        .destroyEconomicGroup(group, this.authenticationModule.companyIds, id);

      this.$notification.success('Grupo econômico excluido com sucesso!');
      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
