import Rest from './base/Rest';

export default class AcquittanceReasonService extends Rest {
	static resource = 'acquittancereason';

    listAll() {
        this.relationship = 'listAllByGroup';
        return this;
    }

    save() {
        this.relationship = 'save';
        return this;
    }

    listOne() {
        this.relationship = 'listOne';
        return this;
    }

    update() {
        this.relationship = 'update';
        return this;
    }
}
