










































































































import Component, { mixins } from 'vue-class-component';
import { Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import UserGroupModuleRepository from '@/repositories/UserGroupModuleRepository';
import StartLoadingMixin from '@/mixins/ts/StartLoadingMixin';
import Module from '@/domain/models/Module';
import IFieldChoises from '@/types/IFieldChoises';
import IUserGroupUpdateParams from '@/repositories/parameters/IUserGroupUpdateParams';
import UserGroupModule from '@/stores/modules/UserGroupModule';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ModuleItem from './components/ModuleItem.vue';

@Component({
  components: {
    ModuleItem,
  },
})
export default class UserGroupEdit extends mixins(StartLoadingMixin) {
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  parameters: IUserGroupUpdateParams = {
    id: 0,
    name: '',
    status: false,
    module_ids: [],
    company_group_id: 0,
  };
  companyGroupId: number = 0;
  modules: Module[] = [];
  userGroupStore?: UserGroupModule;
  readonly userGroupRepository: UserGroupRepository = new UserGroupRepository();
  readonly userGroupModuleRepository: UserGroupModuleRepository = new UserGroupModuleRepository();
  readonly statusItems: IFieldChoises[] = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ];

  created() {
    this.userGroupStore = getModule(UserGroupModule, this.$store);
    const authStore = getModule(AuthenticationModule, this.$store);
    this.companyGroupId = Number(authStore.user.company_group_id);
  }

  get groupId(): number {
    return Number(this.$route.params.groupId);
  }

  get purchasedModules(): Module[] {
    return this.modules?.filter((mod) => mod.isPurchased) ?? [];
  }

  get notPurchasedModules(): Module[] {
    return this.modules?.filter((mod) => !mod.isPurchased) ?? [];
  }

  mounted() {
    const getGroupAsync = this.userGroupRepository.get(this.groupId);
    const getModulesAsync = this.userGroupModuleRepository.getAll(
      this.companyGroupId,
      this.groupId,
    );

    Promise.all([getGroupAsync, getModulesAsync])
      .then(([userGroup, modules]) => {
        this.parameters.id = userGroup.id;
        this.parameters.name = userGroup.name;
        this.parameters.status = userGroup.status;
        this.parameters.company_group_id = userGroup.companyGroup!.id;
        this.modules = modules;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.$notification.error(error);
        this.$router.push({ name: 'UserGroupList' });
      });
  }

  handleSave(): void {
    this.parameters.module_ids = this.purchasedModules
      .filter((map) => map.isActive)
      .map((mod) => mod.id);
    this.$dialog.startLoading();
    this.userGroupRepository
      .update(this.parameters)
      .then(() => {
        this.$notification.success('Usuário atualizado com sucesso!');
        this.$router.push({ name: 'UserGroupList' });
      })
      .catch(this.$notification.error)
      .finally(() => this.$dialog.stopLoading());
  }

  handlePermissionClick(moduleInfo: Module): void {
    this.userGroupStore!.persist({
      userGroupName: this.parameters.name,
      moduleName: moduleInfo.name,
    });

    this.$router.push(`/grupos-de-usuario/${this.groupId}/modulos/${moduleInfo.id}`);
  }

  handleChangeActive(moduleId: number, isActive: boolean) {
    this.modules!.forEach((mod) => {
      if (mod.id == moduleId) {
        mod.isActive = isActive;
      }
    });
  }

  beforeRouteLeave(to: Route, from: Route, next: Function) {
    if (to.name != 'UserGroupModules') {
      this.userGroupStore!.clear();
    }
    next();
  }

  handleCancel(): void {
    this.$router.push({ name: 'UserGroupList' });
  }
}
