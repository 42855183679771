import Repository from '@/repositories/base/Repository';
import Module from '@/domain/models/Module';
import InvalidResponseException from '@/repositories/exceptions/InvalidResponseException';

export default class UserGroupModuleRepository extends Repository {
  async getAll(companyGroupId: number, userGroupId: number): Promise<Module[]> {
    const { data: response } = await this.datasource
      .get(`/company-groups/${companyGroupId}/user-groups/${userGroupId}/modules`);

    if (Array.isArray(response.data) && response.data.length === 0) {
      throw new InvalidResponseException('O usuário não está vinculado à nenhum módulo.');
    }

    return response.data.map(Module.make);
  }
}
