<template>
  <v-btn icon dark @click="handleClick">
    <v-icon>mdi-view-grid-outline</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ModuleSelector',
  methods: {
    handleClick() {
      this.$router.replace('/modulos');
    },
  },
};
</script>

<style></style>
