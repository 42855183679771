import IIconStatusObject from '@/views/statementConciliation/interfaces/IIconStatusObject';
import StatementConciliationMovement from '@/domain/models/StatementConciliationMovement';
import StatementConciliationListHelpers from '@/views/statementConciliation/helpers/StatementConciliationListHelpers';
import StatementConciliationStatusType from '@/domain/enums/StatementConciliationStatusType';
import MovementOriginType from '@/domain/enums/StatementConciliationMovementOriginType';
import MovementType from '@/domain/enums/StatementConciliationMovementType';

export default class StatementConciliation {
    constructor(
        public id: number | string,
        public date: string,
        public status: number,
        public isDivergent: boolean,
        public bankType: string,
        public movements: Array<StatementConciliationMovement>,
        public statusObject: IIconStatusObject,
        public shouldShowTableInformation: boolean,
        public selected?: boolean,
        public uid?: string,
    ) {}

    static make(data: any, index: number): StatementConciliation {
        const movements = data.movements.map(StatementConciliationMovement.make);
        const statusObject = StatementConciliationListHelpers.getStatusObject(data.status);
        const uid = index + Math.random().toString(16).substring(2);

        const isAllCredit = movements
            .filter((movement: StatementConciliationMovement) => (
                movement.origin == MovementOriginType.BANK
            ))
            .every((movement: StatementConciliationMovement) => (
                movement.type == MovementType.CREDIT
            ));

        const bankType = isAllCredit ? MovementType.CREDIT : '';

        return new StatementConciliation(
            data.id,
            data.date,
            data.status,
            data.status === StatementConciliationStatusType.DIVERGENCE,
            bankType,
            movements,
            statusObject,
            false,
            data.selected,
            uid,
        );
    }
}
