import Repository from '@/repositories/base/Repository';

export default class PasswordManagementRepository extends Repository {
  public async sendMailToResetPassword(
    email: string,
  ): Promise<void> {
    await this.datasource.send('auth/reset-password/send-mail', { email });
  }

  public async validateToken(
    token: string,
  ): Promise<void> {
    await this.datasource.get('auth/reset-password/validate-token', { params: { token } });
  }
}
