import Vue from 'vue';
import moment from 'moment';
import 'moment/locale/pt-br';
import VueMoment from 'vue-moment';
import VeeValidate, { Validator } from 'vee-validate';
import ptBr from 'vee-validate/dist/locale/pt_BR';
import lodash from 'lodash';
import VueLodash from 'vue-lodash';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import VPopover from 'vue-js-popover';
import VueSession from 'vue-session';
import VueMask from 'v-mask';
import { VTextField } from 'vuetify/lib';
import VCurrencyField from 'v-currency-field';

// Custom plugins and helpers
import InnDesign from './plugins/inn-design';
import DialogPlugin from './plugins/dialog';
import NotificationsPlugin from './plugins/notifications';
import AppConsts from './constants/App';
import helpers from './plugins/helpers/helpers';

Vue.use(VueMoment, { moment });
Vue.use(VeeValidate);
Validator.localize('pt_BR', ptBr);
Vue.use(VueLodash, lodash);
Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(VPopover, { tooltip: true });
Vue.use(VueSession);
Vue.use(VueMask);
Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

Vue.use(InnDesign);
Vue.use(DialogPlugin);
Vue.use(NotificationsPlugin);
Vue.use(AppConsts);
Vue.use(helpers);
