import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"pageTitle":"Usuários"}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c(VSelect,{attrs:{"items":_vm.statusItems,"label":"Filtrar por status","hide-details":"","filled":"","dense":""},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"5","md":"5","sm":"12","xs":"12"}},[_c(VTextField,{attrs:{"label":"Digite um valor para pesquisa","icon":"mdi-magnify","hide-details":"","filled":"","dense":""},model:{value:(_vm.filterTerm),callback:function ($$v) {_vm.filterTerm=$$v},expression:"filterTerm"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"12","xs":"12"}},[_c(VBtn,{attrs:{"color":"primary","block":"","large":"","depressed":""},on:{"click":_vm.handleNewUser}},[_vm._v(" ADICIONAR ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c(VDataTable,{staticClass:"data-table data-table--with-edit",attrs:{"items":_vm.filteredUsers,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","buttonIcon":"mdi-pencil","tooltipText":"Editar"},on:{"click":function($event){return _vm.handleEdit(item.id)}}})]}}],null,true)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }