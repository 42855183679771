












































































import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import CompanyGroupRepository from '@/repositories/CompanyGroupRepository';
import IUserGroupCreateParams from '@/repositories/parameters/IUserGroupCreateParams';
import IFieldChoises from '@/types/IFieldChoises';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import SelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class UserGroupCreate extends Vue {
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  parameters: IUserGroupCreateParams = {
    name: '',
    status: false,
    company_group_id: 0,
  };
  readonly statusItems: IFieldChoises[] = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ];
  authStore?: AuthenticationModule;
  companyGroupsOptions: SelectOptions[] = [];

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);
    if (!this.authStore!.isOwner) {
      this.parameters.company_group_id = Number(this.authStore.user.company_group_id);
    } else {
      this.$dialog.startLoading();
      new CompanyGroupRepository()
        .getAll()
        .then((companies) => {
          this.companyGroupsOptions = companies.map((group) => ({
            value: group.id,
            label: group.description,
          }));
        })
        .catch(this.$notification.error)
        .finally(() => this.$dialog.stopLoading());
    }
  }

  validate(): boolean {
    if (this.parameters.name == '') {
      this.$notification.warn('O nome não pode ser vazio');
      return false;
    }
    return true;
  }

  handleSave() {
    if (this.validate()) {
      this.$dialog.startLoading();
      new UserGroupRepository()
        .save(this.parameters)
        .then(() => {
          this.$notification.success('Grupo de usuário criado com sucesso!');
          this.$router.push({ name: 'UserGroupList' });
        })
        .catch((error) => {
          this.$notification.error(error);
        })
        .finally(() => {
          this.$dialog.stopLoading();
        });
    }
  }

  handleCancel() {
    this.$router.push({ name: 'UserGroupList' });
  }
}
