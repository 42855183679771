import Rest from './base/Rest';

export default class UserService extends Rest {
	static resource = 'user';

	config() {
		this.relationship = 'config';

		return this;
	}
}
