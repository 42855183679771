export default class OperatorUser {
  constructor(
    public id: number,
    public name: string,
    public isUsedInDistribution: boolean,
  ) {}

  static make(data: any) : OperatorUser {
    return new OperatorUser(
      data.id,
      data.name,
      data.is_used_on_distribution,
    );
  }
}
