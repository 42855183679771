














































































import {
  Component,
  Ref,
  Vue,
  Prop,
} from 'vue-property-decorator';
import FileHelper from '@/helpers/FileHelper';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import SerasaConsultationRepository from '@/repositories/SerasaConsultationRepository';
import { VForm } from '@/types/VForm';
import { API_BASE } from '@config/api';
import FileIdentifierType from '@/types/FileIdentifierType';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import SerasaFilesInfoList from '@/domain/models/SerasaFilesInfoList';

@Component
export default class SerasaFile extends Vue {
  private readonly serasaConsultationRepository:
    SerasaConsultationRepository = new SerasaConsultationRepository();
  public readonly rulesHelper: InputRulesHelper = new InputRulesHelper();

  public serasaFilesInput: File[] = [];
  public serasaFilePathLink: string = '';

  public serasaFilesList: SerasaFilesInfoList[] = [];
  public headers: IVDataTableHeader[] = [
    { text: 'Arquivo', value: 'fileName' },
    { text: 'Inserido pelo usuário', value: 'userName' },
    { text: 'Data de inserção', value: 'createdAt' },
    {
      text: '',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ]

  @Prop({
    type: Number,
    required: true,
  }) readonly companyGroupId!: number;

  @Prop({
    type: String,
    required: true,
  }) readonly idCustomer!: string;

  @Ref('SerasaFileForm') readonly serasaFileForm!: VForm;

  public async created(): Promise<void> {
    await this.getSerasaFilesInfo();
  }

  public async getSerasaFilesInfo(): Promise<void> {
    this.$dialog.startLoading();

    try {
      this.serasaFilesList = await this.serasaConsultationRepository.getSerasaFilesInfoList(
        this.companyGroupId,
        this.idCustomer,
      );
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as informações de arquivo do Serasa.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getSerasaFilePath(serasaFilePath: string): string {
    let serasaDownloadFilePath = API_BASE;
    serasaDownloadFilePath += 'company-groups/';
    serasaDownloadFilePath += `${this.companyGroupId}/`;
    serasaDownloadFilePath += 'customers/serasa/download/';
    serasaDownloadFilePath += serasaFilePath;
    serasaDownloadFilePath += `?token=${this.$session.get('token')}`;

    return serasaDownloadFilePath;
  }

  public async attachSerasaFiles(): Promise<void> {
    if (!this.serasaFileForm.validate() || !this.serasaFilesInput) {
      return;
    }

    this.$dialog.startLoading();

    try {
      const formattedFiles: FileIdentifierType[] = this.serasaFilesInput
        .map((file, index) => ({ file, identifier: `file_${index}` }));
      const formData = FileHelper.appendFilesToFormData(formattedFiles, 'files[]');

      await this.serasaConsultationRepository.attachSerasaFiles(
        this.companyGroupId,
        this.idCustomer,
        formData,
      );

      this.serasaFilesInput = [];

      this.$notification.success('O arquivo de consulta de Serasa foi anexado com sucesso!');

      await this.getSerasaFilesInfo();
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao anexar o arquivo de consulta do Serasa!');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async handleRemove(selectedSerasaFile: SerasaFilesInfoList): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.serasaConsultationRepository.removeSerasaFile(
        this.companyGroupId,
        this.idCustomer,
        selectedSerasaFile.id,
      );

      this.$notification.success('Consulta do Serasa apagado com sucesso!');

      await this.getSerasaFilesInfo();
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao excluir a consulta do Serasa!');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async handleDownload(selectedSerasaFile: SerasaFilesInfoList): Promise<void> {
    const completeDownloadPath = this.getSerasaFilePath(selectedSerasaFile.fileUrl);

    this.$dialog.startLoading();

    try {
      FileHelper.downloadFileByObjectUrl(completeDownloadPath, selectedSerasaFile.fileName);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao fazer download do arquivo de consulta do Serasa!');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }
}
