import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","icon":""}},Object.assign({}, onMenu, onTooltip)),[_c(VIcon,{attrs:{"dense":""}},[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}])},[_c(VList,[(_vm.isPending)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.CHANGE_INSTRUCTION)}}},[_c(VListItemTitle,[_vm._v(" Alterar Instrução ")])],1):_vm._e(),_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.VIEW_DETAILS)}}},[_c(VListItemTitle,[_vm._v(" Visualizar Detalhes ")])],1),_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.VIEW_OCCURRENCE)}}},[_c(VListItemTitle,[_vm._v(" Visualizar Ocorrência ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }