import Repository from '@/repositories/base/Repository';
import TradingPolicy from '@/domain/models/TradingPolicy';
import Operator from '@/domain/models/Operator';
import OperatorUser from '@/domain/models/OperatorUser';

export default class TradingPolicyRepository extends Repository {
  public async getTradingPolicies(
    groupId: number,
    search?: string,
  ): Promise<Array<TradingPolicy>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/company-trading-policies`,
      { params: { search } },
    );

    if (this.isStatusOK(status)) {
      return response.data.map(TradingPolicy.make);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a listagem das políticas de negociação!');
  }

  public async getTradingPolicy(
    groupId: number,
    id: number,
  ): Promise<TradingPolicy> {
    const { status, data: response } = await this.datasource
    .get(`company-groups/${groupId}/company-trading-policies/${id}`);

    if (this.isStatusOK(status)) {
      return TradingPolicy.make(response.data);
    }

    throw new Error('Ops, algo inesperado ocorreu na obtenção da política de negociação!');
  }

  public async getTradingPolicyByOperator(
    companyGroupId: number,
    operatorId: number,
  ): Promise<TradingPolicy|null> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${companyGroupId}/company-trading-policies/operators/${operatorId}`);

    if (this.isStatusOK(status)) {
      if (response instanceof Array) {
        return null;
      }

      return TradingPolicy.make(response.data);
    }

    throw new Error('Ops, algo inesperado ocorreu na obtenção da política de negociação!');
  }

  public async sendTradingPolicy(
    groupId: number,
    record: Partial<TradingPolicy>,
    companyIds: number[],
  ): Promise<TradingPolicy> {
    const { status, data: response } = await this.datasource.send(
      `company-groups/${groupId}/company-trading-policies`,
      record,
      {
        headers: {
          'company-ids': JSON.stringify(companyIds),
        },
      },
    );

    if (this.isStatusOK(status)) {
      return TradingPolicy.make(response.data);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a criação da política de negociação!');
  }

  public async updateTradingPolicy(
    groupId: number,
    id: number,
    record: Partial<TradingPolicy>,
  ): Promise<TradingPolicy> {
    const { status, data: response } = await this.datasource.update(
      `company-groups/${groupId}/company-trading-policies/${id}`,
      record,
    );

    if (this.isStatusOK(status)) {
      return TradingPolicy.make(response.data);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a atualização da política de negociação!');
  }

  public async destroyTradingPolicy(
    groupId: number,
    id: number,
  ): Promise<TradingPolicy> {
    const { status, data: response } = await this.datasource
      .destroy(`company-groups/${groupId}/company-trading-policies/${id}`);

    if (this.isStatusOK(status)) {
      return TradingPolicy.make(response.data);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a exclusão da política de negociação!');
  }

  public async getOperators(groupId: number): Promise<Array<Operator>> {
    const { status, data: response } = await this.datasource
    .get(`company-groups/${groupId}/operators`);

    if (this.isStatusOK(status)) {
      return response.data.map(Operator.make);
    }

    throw new Error('Ops, algo inesperado ocorreu na listagem dos operadores.');
  }

  public async getBillingManagerUsers(
    companyGroupId: number,
  ): Promise<OperatorUser[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/users/operator-distributions`);

    return response.data.map(OperatorUser.make);
  }
}
