import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"page-title":"Motivo de Baixa"}}),_c('page-card',{attrs:{"dividerOn":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"6","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-bold",attrs:{"x-large":"","block":"","depressed":"","color":"primary"},on:{"click":_vm.add}},[_vm._v(" ADICIONAR ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c(VDataTable,{staticClass:"elevation-0 pa-3",attrs:{"headers":_vm.headers,"loading":_vm.loadAcquittanceTable,"items":_vm.acquittanceReasonTable,"hide-default-footer":"","disable-pagination":"","group-by":"name"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"12"}},[_c(VIcon,{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_c('span',[_vm._v(" "+_vm._s(group))])],1)]}},{key:"item.acquittanceDetailStatus",fn:function(ref){
var item = ref.item;
return [(item.acquittanceDetailStatus === 'I')?_c(VChip,{staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" Inativo ")]):_c(VChip,{staticClass:"ma-2",attrs:{"small":"","color":"green","text-color":"white"}},[_vm._v(" Ativo ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","buttonIcon":"mdi-pencil","tooltipText":"Editar"},on:{"click":function($event){return _vm.edit(item)}}})]}}],null,true)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }