import qs from 'qs';
import Repository from '@/repositories/base/Repository';
import AccountReceivableHistory from '@/domain/models/AccountReceivableHistory';

export default class AccountsReceivableHistoryRepository extends Repository {
  public async getByAccountReceivableId(
    companyGroupId: number,
    companyId: number,
    accountReceivableId: string,
  ): Promise<Array<AccountReceivableHistory>> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/accounts-receivable/${accountReceivableId}/histories`,
      {
        paramsSerializer: qs.stringify,
        headers: { 'company-id': companyId },
      },
    );

    return response.data;
  }
}
