import ECompanySubscribersType from '@/domain/enums/CompanySubscribersType';
import StatusEnum from '@/domain/enums/StatusEnum';
import BillingSignatureSubscriber from '@/domain/models/BillingSignatureSubscriber';

export default class CompanySubscribers {
  constructor(
    public status: StatusEnum,
    public type: ECompanySubscribersType,
    public value: number,
    public mandatories: number,
    public subscribers: BillingSignatureSubscriber[],
  ) {}

  static make(data: any): CompanySubscribers {
    return new CompanySubscribers(
      data.status === 0 ? StatusEnum.INACTIVE : StatusEnum.ACTIVE,
      data.vinculation_type === 'group' ? ECompanySubscribersType.GROUP : ECompanySubscribersType.COMPANY,
      data.company_or_group_id,
      data.mandatories,
      data.subscribers.map(BillingSignatureSubscriber.make),
    );
  }
}
