
































































import { defineComponent } from '@vue/composition-api';
import ERPRepository from '@/repositories/ERPRepository';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import DialogHelper from '@/helpers/DialogHelper';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import PredictedFlowHelper from '@/views/cashFlow/predictedFlow/helpers/PredictedFlowHelper';
import { VForm } from '@/types/VForm';
import PredictedFlowActionsEnum from '@/views/cashFlow/predictedFlow/enums/PredictedFlowActionsEnum';
import ERPEventsEnum from '@/domain/enums/ERPEventsEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IChangePaymentFormExternalERPRequestParams from '@/views/cashFlow/predictedFlow/interfaces/actions/IChangePaymentFormExternalERPRequestParams';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';
import IPredictedFlowGrouperItemData from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperItemData';

export default defineComponent({
  name: 'ChangePaymentFormDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    register: {
      required: false,
      type: Object as () => IPredictedFlowGrouperList,
    },
  },
  computed: {
    dialogWidth(): string {
      return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
        smAndDown: '100%',
        md: '60%',
        default: '40%',
      });
    },
    tableName(): string {
      return PredictedFlowHelper.getTableBasedOnNcc(this.register!, Boolean(this.$session.get('use_ncc')));
    },
    itemData(): IPredictedFlowGrouperItemData {
      return this.register!.itemData!;
    },
    grouperType(): CashFlowTablesEnum {
      return this.register!.grouperType;
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const inputRules: InputRulesHelper = new InputRulesHelper();
    const erpRepository: ERPRepository = new ERPRepository();
    const paymentRemittanceRepository:
      PaymentRemittanceRepository = new PaymentRemittanceRepository();

    const paymentForm: string = '';

    const loading: boolean = false;
    const isOpen: boolean = this.value;

    const paymentFormOptions: ISelectOptions[] = [];

    return {
      inputRules,
      erpRepository,
      paymentRemittanceRepository,
      paymentForm,
      loading,
      isOpen,
      paymentFormOptions,
    };
  },
  created() {
    this.getPaymentFormsList();
  },
  methods: {
    async getPaymentFormsList(): Promise<void> {
      try {
        this.loading = true;

        const paymentForms = await this.paymentRemittanceRepository.getMethods(
          this.companyGroupId,
          [this.itemData.companyId],
        );

        this.paymentFormOptions = paymentForms;
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar as formas de pagamento.');
      } finally {
        this.loading = false;
      }
    },
    async changePaymentForm(): Promise<void> {
      try {
        this.loading = true;

        const response = await Promise.allSettled([
          this.updateExternalERP(),
          this.updateInternalCustomerERP(),
        ]);

        if (!response.every(({ status }) => status === 'fulfilled')) {
          throw new Error();
        }

        this.$notification.success('Forma de Pagamento alterado com sucesso!');
      } catch (error) {
        this.$notification.error('Houve um problema ao alterar a forma de pagamento.');
      } finally {
        this.loading = false;
      }
    },
    async updateExternalERP(): Promise<void> {
      await this.erpRepository.updateExternalERP<IChangePaymentFormExternalERPRequestParams>(
        this.companyGroupId,
        this.itemData.companyId,
        {
          id: this.itemData.idCustomer,
          event: ERPEventsEnum.PAYMENT_FORM,
          data: {
            payment_form: this.paymentForm,
            type: this.tableName,
          },
        },
      );
    },
    async updateInternalCustomerERP(): Promise<void> {
      const fieldBasedOnTable = this.grouperType === CashFlowTablesEnum.ACCOUNTS_RECEIVABLE ? 'e1_zforpg' : 'e2_zforpg';

      await this.erpRepository.updateInternalERP({
        id: this.itemData.id,
        field: fieldBasedOnTable,
        identify_values: PredictedFlowHelper.getTableAliasByGrouperType(this.grouperType),
        value: this.paymentForm,
      });
    },
    close(): void {
      this.$emit('close', PredictedFlowActionsEnum.change_payment_form);
    },
    handleChangePaymentForm(): void {
      const changePaymentForm = this.$refs.ChangePaymentForm as VForm;

      if (!changePaymentForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');
        return;
      }

      this.changePaymentForm();
    },
  },
});
