import EInstructionRemittanceStatus from '@/domain/enums/InstructionRemittanceStatus';

export default class FilterParameterInstructionsRemittanceList {
  public constructor(
    public initial_issue_date: string,
    public end_issue_date: string,
    public initial_due_date: string,
    public end_due_date: string,
    public company_id: Array<number>,
    public bank_code: string,
    public wallet_type: string,
    public payment_method: string,
    public occurrence_return: Array<string>,
    public type: Array<string>,
    public status: Array<EInstructionRemittanceStatus>,
    public number: string,
    public customer: Array<string>,
  ) {}

  public static make(data: any): FilterParameterInstructionsRemittanceList {
    const relationship = 'instructions_remittance_list';

    return new FilterParameterInstructionsRemittanceList(
      data[`initial_issue_date_${relationship}`] ?? '',
      data[`end_issue_date_${relationship}`] ?? '',
      data[`initial_due_date_${relationship}`] ?? '',
      data[`end_due_date_${relationship}`] ?? '',
      data[`company_id_${relationship}`] ? JSON.parse(data[`company_id_${relationship}`]) : [],
      data[`bank_code_${relationship}`] ?? '',
      data[`wallet_type_${relationship}`] ?? '',
      data[`payment_method_${relationship}`] ?? '',
      data[`occurrence_return_${relationship}`] ? JSON.parse(data[`occurrence_return_${relationship}`]) : [],
      data[`type_${relationship}`] ? JSON.parse(data[`type_${relationship}`]) : [],
      data[`status_${relationship}`] ? JSON.parse(data[`status_${relationship}`]) : [],
      data[`number_${relationship}`] ?? '',
      data[`customer_${relationship}`] ? JSON.parse(data[`customer_${relationship}`]) : [],
    );
  }
}
