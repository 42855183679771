import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import MainGroupersScreensShownEnum from '@/views/cashFlowGroupers/mainGrouper/enums/MainGroupersScreensShownEnum';

export default class MainGrouperOptionsHelper {
  public static getTableOptions(): ISelectOptions<CashFlowTablesEnum>[] {
    return [
      { text: 'Conta a Pagar', value: CashFlowTablesEnum.ACCOUNTS_PAYABLE },
      { text: 'Conta a Receber', value: CashFlowTablesEnum.ACCOUNTS_RECEIVABLE },
      { text: 'Banco', value: CashFlowTablesEnum.BANKS },
    ];
  }

  public static getScreensOptions(): ISelectOptions<MainGroupersScreensShownEnum>[] {
    return [
      { text: 'Fluxo Previsto', value: MainGroupersScreensShownEnum.PREDICTED_FLOW },
      { text: 'Fluxo Realizado', value: MainGroupersScreensShownEnum.REALIZED_FLOW },
    ];
  }

  public static getStatusOptions(): ISelectOptions<number>[] {
    return [
      { text: 'Ativado', value: 1 },
      { text: 'Desativado', value: 0 },
    ];
  }
}
