import ColumnToShow from '@/types/ColumnToShow';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';

export default class FilterParameterClientList {
    public constructor(
        public tableDirection: boolean[],
        public tableSort: string[],
        public columnToSearch: string,
        public rowsPerPage: number,
        public actualPage: number,
        public columnsToShow: ColumnToShow[],
    ) {
    }

    public static make(data: any): FilterParameterClientList {
      const dataColumn = {
        data_table_client_list_show_column_status: 'status',
        data_table_client_list_show_column_company_name: 'companyName',
        data_table_client_list_show_column_trade_name: 'tradeName',
        data_table_client_list_show_column_cnpj: 'cnpj',
        data_table_client_list_show_column_address: 'address',
        data_table_client_list_show_column_city: 'city',
        data_table_client_list_show_column_neighbourhood: 'neighbourhood',
        data_table_client_list_show_column_state: 'state',
        data_table_client_list_show_column_country: 'country',
      };

      let arrayDirection: boolean[] = [];
      let arraySort: string[] = [];

      if (data.sort_table_client_list) {
        const { direction, sort } = FilterParameterHelper
        .defineOrdenation(data.sort_table_client_list);

        arrayDirection = [direction];
        arraySort = [sort];
      }

      const parsedRowsPerPage = parseInt(data.rows_per_page_client_list, 10);
      const parsedActualPage = parseInt(data.actual_page_client_list, 10);

      return new FilterParameterClientList(
        arrayDirection,
        arraySort,
        data.column_to_search_client_list ?? 'search',
        !Number.isNaN(parsedRowsPerPage) ? parsedRowsPerPage : 10,
        !Number.isNaN(parsedActualPage) ? parsedActualPage : 1,
        FilterParameterHelper.mountColumnToShowArray(data, dataColumn),
      );
    }
}
