import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('breadcrumbs',{attrs:{"page-title":"Cadastro de Acesso do Tipo de Usuário"}}),_c(VCard,{staticClass:"elevation-0"},[_c(VCardTitle,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c(VTextField,{attrs:{"filled":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify","placeholder":"Pesquisar"},model:{value:(_vm.configs.filterTerm),callback:function ($$v) {_vm.$set(_vm.configs, "filterTerm", $$v)},expression:"configs.filterTerm"}})],1)],1),_c(VCardText,{staticClass:"pa-6 pt-0"},[_c('table',{staticClass:"table table-striped m-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Grupo Empresa")]),_c('th',[_vm._v("Descrição")])])]),_c('tbody',_vm._l((_vm.listData),function(userGroup){return _c('tr',{staticStyle:{"cursor":"pointer"}},[_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(userGroup.description_company_group))]),_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(userGroup.description))]),_c('td',{staticClass:"m--align-right"},[_c('table-edit-button',{on:{"click":function($event){return _vm.edit(userGroup)}}})],1)])}),0)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }