import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"page-title":"Faturas"}}),_c('page-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","x-large":""},on:{"click":_vm.handleShowFilters}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.showFilters ? 'Ocultar Parâmetros' : 'Exibir Parâmetros'))])])],1),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12","align-self":"center"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"loading":_vm.loading,"color":"success","depressed":"","large":"","block":"","dark":""},on:{"click":_vm.handleGetInvoices}},[_vm._v(" VISUALIZAR ")])],1)],1),_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}]},[_c(VCol,{staticClass:"pt-0"},[_c(VDivider),_c('invoice-filter',{ref:"InvoiceFilter",attrs:{"is-loading":_vm.loading}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"pt-0"},[(_vm.hasInvoices)?_c(VDivider):_vm._e()],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('invoice-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasInvoices),expression:"hasInvoices"}],ref:"InvoiceDataTable",attrs:{"invoices":_vm.invoices,"total-items":_vm.totalItems,"is-loading":_vm.loading},on:{"data-table-changes":_vm.handleDataTableChanges,"action":_vm.handleAction}})]},proxy:true}])}),(_vm.showDialogs.show_accounts)?_c('list-accounts-dialog',{attrs:{"company-group-id":_vm.companyGroupId,"invoice-id":_vm.selectedInvoiceId},on:{"close":_vm.handleCloseDialog},model:{value:(_vm.showDialogs.show_accounts),callback:function ($$v) {_vm.$set(_vm.showDialogs, "show_accounts", $$v)},expression:"showDialogs.show_accounts"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }