import CompanyGroup from '@/domain/models/CompanyGroup';
import Repository from './base/Repository';

export default class CompanyGroupRepository extends Repository {
  async getAll() : Promise<CompanyGroup[]> {
    const { status, data: response } = await this.datasource.get('/companygroup/listAll');
    if (status == 200) {
      return response.data.map(CompanyGroup.make);
    }
    throw new Error('Aconteceu um erro inesperado');
  }
}
