














































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { toCurrency } from '@/utils';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import { formatToSpecificFormat } from '@/utils/date';
import AccountReceivablePreNegotiationDeadline from '@/domain/models/AccountReceivablePreNegotiationDeadline';

@Component
export default class PreNegotiationCompanyAccounts extends Vue {
  public headers: IVDataTableHeader[] = [
    { text: 'Número', value: 'number', width: '33%' },
    { text: 'Vencimento', value: 'dueDate', width: '33%' },
    { text: 'Valor', value: 'value', width: '33%' },
  ];

  @Prop({
    type: Object,
    required: false,
    default: () => ([]),
  }) readonly companyAccounts!: AccountReceivablePreNegotiationDeadline;

  public get totalValue(): number {
    return this.companyAccounts.accounts
      .map(({ value }) => value)
      .reduce((prev, item) => (prev ?? 0) + item);
  }

  public get titleName(): string {
    const {
      economicGroupName,
      companyName,
      customerName,
    } = this.companyAccounts;

    return `${economicGroupName}/${companyName}/${customerName}`;
  }

  public setDateToBrazilianFormat(date: string): string {
    return formatToSpecificFormat(date, 'dd/MM/yyyy');
  }

  public getMoneyFormat(num: number): string {
      return toCurrency(num, {}, true);
  }
}
