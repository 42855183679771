import IPreNegotiationSignature from '@/views/preNegotiation/interfaces/IPreNegotiationSignature';

export default class CompanySubscriberPreNegotiation {
    constructor(
        public economicGroupId: number,
        public economicGroupName: string,
        public companyId: number,
        public companyName: string,
        public customerIdCustomer: string,
        public customerName: string,
        public signatures: IPreNegotiationSignature[],
    ) {}

    public static make(data: any): CompanySubscriberPreNegotiation {
      return new CompanySubscriberPreNegotiation(
        data.economic_group_id,
        data.economic_group_name,
        data.company_id,
        data.company_name,
        data.customer_id_customer,
        data.customer_name,
        data.signatures,
      );
    }
}
