import IOrderStatusOptions from '@/views/orders/interfaces/IOrderStatusOptions';

export default class OrderSupport {
  public static getStatusChipColor(status: string): string| undefined {
    if (status === 'Bloqueio no crédito') {
      return 'red darken-1';
    }

    return 'green darken-1';
  }

  public static getStatusText(status: keyof IOrderStatusOptions): string {
    return {
      0: 'Confirmado',
      1: 'Bloqueado no crédito',
      2: 'Em espera',
      3: 'Sincronizado',
    }[status];
  }
}
