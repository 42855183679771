










import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

import {
  watch,
} from '@vue/composition-api';

import {
  CurrencyInputOptions,
  CurrencyDisplay,
  useCurrencyInput,
} from 'vue-currency-input';

@Component({
  setup(props: any) {
    const options: CurrencyInputOptions = {
      currency: 'BRL',
      locale: 'pt-BR',
      currencyDisplay: CurrencyDisplay.hidden,
      autoDecimalDigits: true,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      valueRange: {
        min: 0,
        max: 1000000000,
      },
    };

    const { inputRef, setValue } = useCurrencyInput(options);

    watch(
      () => props.value,
      (value: number) => (
        (value != undefined) ? setValue(value) : setValue(0)
      ),
    );

    return { inputRef };
  },
})
export default class WriteOffMovementCurrencyInput extends Vue {
  @Prop(Number || String)
  value!: number | string;

  @Prop(Boolean)
  readonly!: boolean;

  @Prop(Boolean)
  disabled!: boolean;
}
