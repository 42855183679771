import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"pageTitle":"Pendências de Cobrança"}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,{attrs:{"align":"end","justify":"space-between","justify-sm":"start"}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[(_vm.store.state.manager)?_c('navigation-tabs',{attrs:{"tabs":_vm.tabs},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[(!_vm.isTransferList)?_c(VRow,{attrs:{"align":"center","justify":"end"}},[_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"2","sm":"6"}},[_c(VTooltip,{attrs:{"bottom":_vm.isMobile,"left":!_vm.isMobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","x-large":""},on:{"click":_vm.toggle}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.store.state.filter ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1)]}}],null,false,662912406)},[_c('span',[_vm._v(_vm._s(_vm.store.state.filter ? 'Ocultar Parâmetros' : 'Exibir Parâmetros'))])])],1),_c(VCol,{attrs:{"cols":"10","sm":"6","md":"6"}},[_c(VBtn,{attrs:{"block":"","large":"","color":"info"},on:{"click":_vm.filter}},[_vm._v(" VISUALIZAR ")])],1)],1):_vm._e(),(_vm.isTransferList)?_c(VRow,{attrs:{"align":"center","justify":"end"}},[_c(VCol,{attrs:{"cols":"6","sm":"6","md":"4"}},[_c(VBtn,{attrs:{"block":"","large":"","color":"warning"},on:{"click":_vm.disapprove}},[_vm._v(" REPROVAR ")])],1),_c(VCol,{attrs:{"cols":"6","sm":"6","md":"4"}},[_c(VBtn,{attrs:{"block":"","large":"","color":"success"},on:{"click":_vm.approve}},[_vm._v(" APROVAR ")])],1)],1):_vm._e()],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('router-view')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }