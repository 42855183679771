import IOrderStatusOptions from '@/views/orders/interfaces/IOrderStatusOptions';

export default class CreditAnalisysOrderValues {
    constructor(
        public totalValueOrder: number,
        public higherOrderValue: number,
        public status: string,
        public orderPaymentCondition: string,
        public orderObservationMessage: string,
        public hasOrderObservationMessageDefined: boolean,
        public orderNumber: string,
    ) {}

    public static make(data: any): CreditAnalisysOrderValues {
        return new CreditAnalisysOrderValues(
            data.total_value_order,
            data.higher_order_value,
            data.status,
            data.order_payment_condition,
            data.order_observation_message,
            !!data.order_observation_message,
            data.order_number,
        );
    }
}
