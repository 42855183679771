import TAvailableChangedFieldsOnInstallment from '@/views/preNegotiation/types/TAvailableChangedFieldsOnInstallment';

export default class InstallmentChange {
  constructor(
    public index: number,
    public value: string|number,
    public changedField: TAvailableChangedFieldsOnInstallment,
  ) {}

  public static make(data: any): InstallmentChange {
    return new InstallmentChange(
      data.index,
      data.value,
      data.changed_field,
    );
  }
}
