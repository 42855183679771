export default class AccountReceivableLog {
  constructor(
    public description: string,
    public userName: string,
    public date: string,
    public type: string,
  ) {}

  static make(data: any): AccountReceivableLog {
    return new AccountReceivableLog(
      data.description ?? '',
      data.user_name ?? '',
      data.date ?? '',
      data.type ?? '',
    );
  }
}
