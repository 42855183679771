<template>
  <component class="header-content-cell" :is="cellType" v-on="$listeners" v-bind="$attrs">
    <div class="header-column">
      <slot></slot>
    </div>
  </component>
</template>

<script>
export default {
  name: 'StickyCell',
  props: {
    type: String,
  },
  computed: {
    cellType() {
      return ['td', 'th'].includes(this.type) ? this.type : 'th';
    },
  },
};
</script>

<style scoped></style>
