






















































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { formatErrorForNotification } from '@/utils/error';
import { getModule } from 'vuex-module-decorators';

import AuthenticationModule from '@/stores/modules/AuthenticationModule';

import OperatorDistributionRepository from '@/repositories/OperatorDistributionRepository';

import IOption from '@/domain/interfaces/ISelectOptions';
import StatusType from '@/domain/enums/StatusType';
import OperatorDistribution from '@/domain/models/OperatorDistribution';
import OperatorUser from '@/domain/models/OperatorUser';

import IOperatorDistributionData from '@/views/operatorDistribution/interfaces/IOperatorDistributionData';
import Absences from '@/views/operatorDistribution/components/OperatorDistributionAbsences.vue';
import ISendOperatorDistributionParams from '@/repositories/parameters/ISendOperatorDistributionParams';
import IAbsencesOnOperatorDistributionParams from '@/repositories/parameters/IAbsencesOnOperatorDistributionParams';
import IOperatorAbsences from '@/views/operatorDistribution/interfaces/IOperatorAbsences';

@Component({
  components: {
    Absences,
  },
})
export default class OperatorDistributionView extends Vue {
  @Ref('form') readonly form!: VForm;

  public readonly operatorDistributionRepository: OperatorDistributionRepository =
    new OperatorDistributionRepository();
  private readonly authenticationModule: AuthenticationModule =
    getModule(AuthenticationModule);

  public originalOperatorId: number | null = null;

  public upsertLoading: boolean = false;
  public getDataLoading: boolean = false;
  public valuePrefix: boolean = false;
  public balancePrefix: boolean = false;

  public operators: OperatorUser[] = [];
  public companies: Array<IOption<number>> = [];

  public data: IOperatorDistributionData = {
    recurrence: false,
    value: null,
    balance: null,
    absences: [],
    companies: [],
  };

  public status: Array<IOption<StatusType>> = [
    {
      value: StatusType.ACTIVE,
      text: 'Ativo',
    },
    {
      value: StatusType.INACTIVE,
      text: 'Inativo',
    },
  ];

  public rule: Record<string, any> = {
    required: (v: string | number) => !!v || 'Campo obrigatório.',
    requiredMultiple: (v: Array<string>) => !!v.length || 'Campo obrigatório.',
  };

  public get isCreate(): boolean {
    return this.$route.name === 'CreateOperatorDistribution';
  }

  public get distributionId(): number {
    return +this.$route.params.id;
  }

  public get title(): string {
    return this.isCreate
      ? 'Nova Distribuição de Operador'
      : 'Editar Distribuição de Operador';
  }

  public get companyGroupId(): number {
    return this.$session.get('company_group_id');
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public get unusedOperators(): OperatorUser[] {
    return this.operators.filter((operator) => {
      if (
        !this.isCreate
        && this.data.operator
        && this.originalOperatorId === operator.id
      ) {
        return true;
      }

      return !operator.isUsedInDistribution;
    });
  }

  public mounted(): void {
    this.loadData();
  }

  public save(): Promise<void> | null {
    if (this.form.validate()) {
      return this.isCreate
        ? this.createDistribution()
        : this.updateDistribution();
    }

    return null;
  }

  public cancel(): void {
    this.form.resetValidation();
    this.$router.push({ name: 'OperatorDistributionList' });
  }

  public togglePrefix(
    event: FocusEvent,
    prefix: boolean,
    value: number | null,
  ): boolean {
    if (event.type === 'focus' && prefix !== true) {
      return true;
    }

    if (event.type === 'blur' && value === null) {
      return false;
    }

    return prefix;
  }

  public togglePrefixOfValue(event: FocusEvent) {
    this.valuePrefix = this.togglePrefix(
      event,
      this.valuePrefix,
      this.data.value,
    );
  }

  public togglePrefixOfBalance(event: FocusEvent) {
    this.balancePrefix = this.togglePrefix(
      event,
      this.balancePrefix,
      this.data.balance,
    );
  }

  public async getDistribution(id: number): Promise<void> {
    const operatorDistribution: OperatorDistribution = await this.operatorDistributionRepository
    .getOperatorDistribution(
        this.companyGroupId,
        this.companyIds,
        id,
      );

    this.data = {
      operator: operatorDistribution.operatorId,
      status: operatorDistribution.status,
      delay: `${operatorDistribution.delay}`,
      value: operatorDistribution.value || null,
      balance: operatorDistribution.balance || null,
      recurrence: operatorDistribution.recurrence,
      absences: operatorDistribution.absences,
      companies: operatorDistribution.companies,
    };

    this.originalOperatorId = operatorDistribution.operatorId;

    this.valuePrefix = operatorDistribution.value > 0;
    this.balancePrefix = operatorDistribution.balance > 0;
  }

  public async loadData(): Promise<void> {
    try {
      this.getDataLoading = true;

      this.operators = await this.operatorDistributionRepository.getBillingManagerUsers(
          this.companyGroupId,
        );

      this.companies = await this.operatorDistributionRepository.getCompanies(this.companyGroupId);

      if (!this.isCreate) await this.getDistribution(this.distributionId);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      this.cancel();
    } finally {
      this.getDataLoading = false;
    }
  }

  public async createDistribution(): Promise<void> {
    try {
      this.upsertLoading = true;

      const record: ISendOperatorDistributionParams = {
        operator_id: this.data.operator!,
        status: this.data.status!,
        recurrence: this.data.recurrence,
        delay: this.data.delay ? +this.data.delay : 0,
        value: this.data.value ?? 0,
        balance: this.data.balance ?? 0,
        absences: this.formatAbsencesToRequestFormat(this.data.absences),
        companies: this.data.companies,
      };

      await this.operatorDistributionRepository.sendOperatorDistribution(
        this.companyGroupId,
        this.companyIds,
        record,
      );

      this.$notification.success(
        'Distribuição de operador criada com sucesso!',
      );
      this.$router.push({ name: 'OperatorDistributionList' });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.upsertLoading = false;
    }
  }

  public async updateDistribution(): Promise<void> {
    try {
      this.upsertLoading = true;

      const record: ISendOperatorDistributionParams = {
        operator_id: this.data.operator!,
        status: this.data.status!,
        recurrence: this.data.recurrence,
        delay: this.data.delay ? +this.data.delay : 0,
        value: this.data.value ?? 0,
        balance: this.data.balance ?? 0,
        absences: this.formatAbsencesToRequestFormat(this.data.absences),
        companies: this.data.companies,
      };

      await this.operatorDistributionRepository.updateOperatorDistribution(
        this.companyGroupId,
        this.companyIds,
        this.distributionId,
        record,
      );

      this.$notification.success(
        'Distribuição de operador alterada com sucesso!',
      );
      this.$router.push({ name: 'OperatorDistributionList' });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.upsertLoading = false;
    }
  }

  public formatAbsencesToRequestFormat(
    absences: IOperatorAbsences[],
  ): IAbsencesOnOperatorDistributionParams[] {
    return absences.map((absence) => ({
      end_date: absence.endDate!,
      initial_date: absence.initialDate!,
      substitute_id: absence.substituteId!,
    }));
  }
}
