import Module from '@/domain/models/Module';
import Permission from '@/domain/models/Permission';
import Repository from './base/Repository';
import IPermissionSaveParams from './parameters/IPermissionSaveParams';

export default class PermissionRepository extends Repository {
  async getAll(userGroupId: number, moduleId: number): Promise<Permission[]> {
    const url = `/user-groups/${userGroupId}/modules/${moduleId}/permissions`;
    const { status, data: response } = await this.datasource.get(url);
    if (status == 200) {
      return response.data.map(Permission.make);
    }
    throw new Error('Aconteceu um erro inesperado');
  }

  async save(permissions: IPermissionSaveParams, userGroupId: number, moduleId: number)
    : Promise<boolean> {
    const url = `/user-groups/${userGroupId}/modules/${moduleId}/permissions`;
    const { status } = await this.datasource.send(url, permissions);
    if (status == 200) {
      return true;
    }
    throw new Error('Aconteceu um erro inesperado');
  }
}
