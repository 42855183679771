
















































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

@Component({})
export default class StatementConciliationSearchMovementAvailabilityDate extends Vue {
  @Prop(Boolean) readonly open!: boolean;

  @Prop(String) readonly date!: string;

  @Emit()
  back(): boolean {
    return false;
  }

  @Emit()
  next(): string {
    return this.availabilityDate;
  }

  @Watch('date')
  loadedDate(date: string) {
    this.availabilityDate = date;
  }

  availabilityDate: string = '';
}
