<template>
  <v-snackbar class="notification" v-model="opened" :color="color" right top>
    <v-icon class="mx-2" v-if="icon">{{ icon }}</v-icon>
    <span v-if="hasNewLine(message)" v-html="newLineToBreakRow(message)"></span>
    <span v-else>{{message}}</span>
    <template #action="{ attrs }">
      <v-btn class="mx-2" small icon v-bind="attrs" @click="opened = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'InnSnackBar',
  data() {
    return {
      opened: false,
      icon: '',
      message: '',
      color: '#5867dd',
    };
  },
  methods: {
    show(message, icon, color) {
      this.message = message;
      this.icon = icon;
      this.color = color;
      this.opened = true;
    },
    error(message) {
      this.show(message, 'mdi-alert-circle', '#FF1744');
    },
    info(message) {
      this.show(message, 'mdi-alert-information', '#5867dd');
    },
    success(message) {
      this.show(message, 'mdi-check-circle', '#34bfa3');
    },
    warn(message) {
      this.show(message, 'mdi-alert', '#FB8C00');
    },
    hasNewLine(message) {
      return message.includes('\n');
    },
    newLineToBreakRow(message) {
      return message.replace(/\n/g, '<br>');
    },
  },
};
</script>

<style scope>
  .notification {
    z-index: 90000 !important;
  }
</style>
