import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"450px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6 justify-center"},[_c(VIcon,{staticClass:"mr-4",attrs:{"large":"","color":"error"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" Atenção! ")],1),_c(VCardText,[(_vm.hasInvoice)?_c('p',{staticClass:"mb-0 text-center"},[_vm._v(" A conciliação está vinculada a uma fatura, deseja excluir a fatura? ")]):_c('p',{staticClass:"mb-0 text-center"},[_vm._v(" Deseja realmente desfazer a conciliação desse registro? ")])]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"px-10",attrs:{"dark":"","depressed":"","color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" NÃO ")]),_c(VBtn,{staticClass:"px-10",attrs:{"dark":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.undo()}}},[_vm._v(" SIM ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }