<template>
    <form>
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__head">
                <div class="m-portlet__head-tools">
                    <!-- area dos botoes de cancelamento e salvamento -->
                    <router-link :to="{name: 'companygroup' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
                    <button @click="save" type="button" class="m-btn btn btn-success">Salvar</button>
                </div>
            </div>

            <!-- area de input fields -->
            <div class="m-portlet__body">
                <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Descrição</label>
                                <input type="text" class="form-control" field-name="Descrição" v-model="companyGroup.description"/>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Prefixo</label>
                                <input type="text" class="form-control" field-name="prefix_table" v-model="companyGroup.prefix_table" value="ICASH_" disabled/>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Tabela de Importação</label>
                                <input type="text" class="form-control" field-name="Tabela de importacao" v-model="companyGroup.name_table"/>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Status</label>
                                <select class="form-control select2 hide" field-name="status" v-model="companyGroup.status" required>
                                    <option value="0">Inativo</option>
                                    <option value="1">Ativo</option>
                                </select>
                            </div>
                        </div>


                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Utiliza Dígito conta</label>
                                <select class="form-control select2 hide" field-name="digcta" v-model="companyGroup.use_digcta" required>
                                    <option value="0">Inativo</option>
                                    <option value="1">Ativo</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Utiliza dígito de agência</label>
                                <select class="form-control select2 hide" field-name="digag" v-model="companyGroup.use_digag" required>
                                    <option value="0">Inativo</option>
                                    <option value="1">Ativo</option>
                                </select>
                            </div>
                        </div>

                        
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Imagem</label>
                            <div></div>
                            <label class="custom-file">
                                <input type="file" class="custom-file-input" field-name="Imagem" id="image"
                                       name="image" @change="changeFile">
                                <span class="custom-file-control"></span>
                            </label>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Responsável pela cobrança</label>
                                <select class="form-control select2 hide" field-name="invoiceGenerator" v-model="companyGroup.invoice_generator" required>
                                    <option value="BANK">Banco</option>
                                    <option value="SELF">Cliente</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Aprovação para cobrança?</label>
                                <select class="form-control select2 hide" field-name="billingApproval" v-model="companyGroup.billing_approval" required>
                                    <option value="Y">Sim</option>
                                    <option value="N">Não</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
								<div class="form-group">
									<label>Limite de remessas</label>
									<input type="text" class="form-control" field-name="Limite de remessas" v-model="companyGroup.remittance_limit" required/>
                                </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <h3>Dados personalizados</h3>
                        </div>
                    </div>

                    <div class="row">
                        <!-- dados personalizaveis para a companhia -->
                        <table class="table table-striped m-table mt-4">
                            <thead class="form-group">
                                <tr>
                                    <th>Tabela</th>
                                    <th>Coluna Padrão</th>
                                    <th>Coluna Personalizada</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="dictionary in companyGroupDictionary" :key="dictionary.id">
                                    <td>{{dictionary.tabela}}</td>
                                    <td>{{dictionary.coluna_padrao}}</td>
                                    <td>{{dictionary.coluna_personalizada}}</td>
                                    <td class="m--align-right">
                                        <i class="fa remove fa-trash-o" v-on:click="this.companyGroupDictionary.splice(dictionary)" style="cursor:pointer;"></i>
                                    </td>
                                </tr>

                                <!-- codigo de insercao de novo dado -->
                                <tr>
                                    <td><input type="text" class="form-control hide" v-model="newDictionary.tabela"/></td>
                                    <td><input type="text" class="form-control hide" v-model="newDictionary.coluna_padrao" /></td>
                                    <td><input type="text" class="form-control hide" v-model="newDictionary.coluna_personalizada"/></td>
                                    <td class="m--align-right">
                                        <i class="fa remove fa-plus" @click="addNewDictionary" style="cursor:pointer;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    data () {
        return {
            companyGroup: {
                description: "",
                status: "",
                prefix_table: "",
                name_table: "",
                use_digag: "",
                use_digcta: "",
                invoice_generator:"",
                billing_approval: "",
                remittance_limit: "",

            },
            companyGroupDictionary: {},
            newDictionary: {
                coluna_padrao: "",
                coluna_personalizada: "",
                tabela: ""
            }
        }
    },

    mounted: function () {
        this.loadCompanySettings();
    },

    methods: {
        /**
         * Carrega os valores de companyGroup
         * e companyGroupDictionary e adiciona para os modelos respectivos
         */
        loadCompanySettings: function () {
            let self = this;
            blockPage("Carregando os dados...");

            $.ajax({
                method: "GET",
                url: self.$store.state.API_BASE + 'companygroup/listOne/' + self.$route.params.company_group_id,
                headers: {
                    'token': self.$session.get('token')
                },
            }).done(function (result) {
                self.companyGroup = result.data[0]
                self.companyGroupDictionary = result.data[0].company_group_dictionary
                
                unblockPage();
            }).fail(function (error) {
                console.log(error.responseText)
                unblockPage();
            })
        },

        addNewDictionary: function () {
            let self = this;

            self.newDictionary.company_group_id = self.companyGroup.id;
            self.companyGroupDictionary.push(self.newDictionary);

            self.newDictionary = {
                coluna_padrao: "",
                coluna_personalizada: "",
                tabela: ""
            }
        },

        save: function () {
            let self = this;
            $.ajax({
                method: "POST",
                url: self.$store.state.API_BASE + 'companygroup/edit',
                headers: {
                    'token': self.$session.get('token')
                },
                data: {
                    arrayData: JSON.stringify(self.companyGroup)
                }
            }).done(function (result) {
                if (result.error == false) {
                    showNotification('Sucesso', 'Grupo de empresa alterado!', 'success');
                } else {
                    showNotification('Atenção', result.error, 'danger');
                    return false;
                }
            }).fail(function (error) {
                console.log(error.responseText);
            });

            $.ajax({
                method: "POST",
                url: self.$store.state.API_BASE + 'companygroup/dictionary/save',
                headers: {
                    'token': self.$session.get('token')
                },
                data: { data: JSON.stringify(self.companyGroupDictionary) }
            }).done(function (result) {
                if (result.error == false) {
                    showNotification('Sucesso', 'Dados personalizados da empresa alterados!', 'success');
                    self.$router.replace({name: 'companygroup'});
                } else {
                    showNotification('Atenção', result.error, 'danger');
                    return false;
                }
            }).fail(function (error) {
                console.log(error.responseText);
            });
        }, 

        changeFile: function (event) {
            let self = this;

            let fileContent = event.target.files

            if(fileContent[0]) {
                let fr = new FileReader()
                fr.readAsDataURL(fileContent[0])
                fr.onload = function (e) {
                    self.companyGroup.url_image = e.target.result;
                }
            } else {
                self.companyGroup.url_image = "";
            }
        }
    }
}
</script>