<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Tipo de Usuário"/>
			<v-card class="elevation-0">
				<v-card-title>
					<v-col cols="12" lg="4" md="4" sm="12" xs="12">
							<v-select 
							v-model="configs.filterStatus"
							filled
							dense
							hide-details
							:items="filterStatusItems"/>
					</v-col>
					<v-col cols="12" lg="6" md="5" sm="12" xs="12">
						<v-text-field
							filled
							dense
							hide-details
							prepend-inner-icon="mdi-magnify"
							placeholder="Pesquisar"
							v-model="configs.filterTerm"
						/>
					</v-col>
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<inn-add-btn @click="add"/>
						</v-col>
				</v-card-title>
				<v-card-text class="pa-6 pt-0">
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Descrição</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(userGroup, index) in listData" :key="index">
								<td style="vertical-align: middle">									
									<v-chip
										v-if="userGroup.status == 0"
										dense
										small>
										Inativo
									</v-chip>
									<v-chip
										v-else
										dense
										small
										color="green"
										text-color="white">
										Ativo
									</v-chip>
								</td>
								<td style="vertical-align: middle;">{{ userGroup.description }}</td>
								<td class="m--align-right">
									<inn-edit-btn @click="edit(userGroup)"/>
								</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash'
	import { API_BASE } from '@config/api'

	export default {
		data () {
			return {
				userGroups: [],
				configs: {
					filterTerm: '',
					filterStatus: ''
				},
				filterStatusItems: [
					{
						text: "Todos os status",
						value: "",
					},
					{
						text: "Inativos",
						value: "0",
					},
					{
						text: "Ativos",
						value: "1",
					},
				]
			}
		},

	computed: {
		listData() {
			const self = this;
			const filterStatus = self.configs.filterStatus;
			const filterTerm = self.configs.filterTerm.toLowerCase();
			var dataRet = this.userGroups;

			if (!_.isEmpty(filterStatus)) {
				dataRet = _.filter(dataRet, function(data) { return data.status == filterStatus; })
			}

			if (!_.isEmpty(filterTerm)) {
				dataRet = _.filter(dataRet, list => list.description.toLowerCase().indexOf(filterTerm) >= 0)
			}

			return dataRet
		}
	},

		mounted: function () {
			this.loadUserGroups();
		},

		methods: {
			loadUserGroups: function () {
				blockPage('Carregando os dados...');

				this.$http.get(API_BASE + 'usergroup/listAll', {headers: {'token': this.$session.get('token')}}).then(
					function (result) {
						this.userGroups = result.body.data
						unblockPage();
					},
					function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				).bind(this)
			},
			add() {
				this.$router.push('/usergroup/new');
			},	
			edit(userGroup) {
				const params = {
					user_group_id: userGroup.id,
				};
				this.$router.push({name: 'editusergroup',params});
			},
		}
	}
</script>
