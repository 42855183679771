import isRangeIntersecting from './is_range_intersecting';

export function ValidationError(message) {
  this.name = 'ValidationError';
  this.message = message ?? 'Erro de validação';
  this.stack = Error().stack;
}
ValidationError.prototype = Object.create(Error.prototype);
ValidationError.prototype.constructor = ValidationError;

export const validateImporting = (response) => {
  const processes = response.data.active;
  if (processes.import.isRunning) {
    throw new ValidationError(
      'Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente',
    );
  }
  return response;
};

export const validatePaymentRemittance = (response, initial, final) => {
  const processes = response.data.active;
  if (processes.payment_remittance && processes.payment_remittance.isRunning) {
    const isIntersectingPaymentRemittance = isRangeIntersecting(
      initial,
      final,
      processes.payment_remittance.initialReferenceDate,
      processes.payment_remittance.finalReferenceDate,
    );

    if (isIntersectingPaymentRemittance) {
      throw new ValidationError(
        'Existe um processo de remessa de pagamento em andamento nesse mesmo intervalo de datas. Aguarde até que finalize',
      );
    }
  }
  return response;
};

export const validateBillingRemittance = (response, initial, final) => {
  const processes = response.data.active;
  if (processes.billing_remittance && processes.billing_remittance.isRunning) {
    const isIntersectingPaymentRemittance = isRangeIntersecting(
      initial,
      final,
      processes.billing_remittance.initialReferenceDate,
      processes.billing_remittance.finalReferenceDate,
    );

    if (isIntersectingPaymentRemittance) {
      throw new ValidationError(
        'Existe um processo de remessa de cobrança em andamento nesse mesmo intervalo de datas. Aguarde até que finalize',
      );
    }
  }
  return response;
};

export const validateConciliationRemittance = (response, initial, final) => {
  const processes = response.data.active;
  if (processes.conciliation && processes.conciliation.isRunning) {
    const isIntersectingPaymentRemittance = isRangeIntersecting(
      initial,
      final,
      processes.conciliation.initialReferenceDate,
      processes.conciliation.finalReferenceDate,
    );

    if (isIntersectingPaymentRemittance) {
      throw new ValidationError(
        'Existe um processo de conciliação em andamento nesse mesmo intervalo de datas. Aguarde até que finalize',
      );
    }
  }
  return response;
};
