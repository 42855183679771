<template>
  <span class="m-switch m-switch--sm m-switch--icon">
    <label>
      <input type="checkbox" :name="name" :id="id" :checked="value" @input="emit($event)">
      <span class="accessSwitch" :input-field="id" :input-value="value"></span>
    </label>
  </span>
</template>

<script>
export default {
  name: 'CustomSwitch',
  props: {
    name: String,
    id: String,
    value: Boolean,
  },
  methods: {
    emit(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style></style>
