import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":_vm.dialogWidth,"persistent":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c(VRow,{attrs:{"justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" Visualizar erro na integração com o ERP ")]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"icon":"","large":""},on:{"click":_vm.handleClose}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1),_c(VCardText,{staticStyle:{"max-height":"50vh"}},[(!_vm.loading)?_c('div',[(_vm.integrationData && _vm.integrationData.errorMessage)?_c(VRow,[_c(VCol,[_c('span',[_vm._v("Mensagem de Erro: ")]),_c('span',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.integrationData.errorMessage))])])],1):_c(VRow,[_c(VCol,[_vm._v(" Nenhuma mensagem de erro encontrada... ")])],1)],1):_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" Carregando... ")])]),_c(VCardActions,[(_vm.integrationData)?_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"success","depressed":"","large":"","block":"","dark":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.handleRerunJob(_vm.integrationData.jobId)}}},[_vm._v(" INTEGRAR NOVAMENTE ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }