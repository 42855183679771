export default class BillingPendencyHistory {
  constructor(
    public id: number,
    public oldOperator: string,
    public newOperator: string,
    public requester: string,
    public manager: string,
    public requestedAt: string,
    public approvedAt?: string,
  ) {}

  static make(data: any): BillingPendencyHistory {
    return new BillingPendencyHistory(
      data.id ?? 0,
      data.old_operator ?? '',
      data.new_operator ?? '',
      data.requester ?? '',
      data.manager ?? '',
      data.requested_at ?? '',
      data.approved_at,
    );
  }
}
