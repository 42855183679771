import { API_BASE, API_PREFIX } from '@config/api';

const APP_CONSTS = {
  MASK_CNPJ: '##.###.###/####-##',
  BANK_CONFIG_SEND_TYPES: {
    1: 'Download em Tela',
    2: 'VAN',
    3: 'API',
  },
  API_BASE,
  API_PREFIX,
};

const AppConsts = {};

AppConsts.install = (Vue) => {
  Vue.prototype.$getConst = (key) => APP_CONSTS[key];
};

export default AppConsts;
