import StatusType from '../enums/StatusType';

export default class ClientContact {
  constructor(
    public id: number,
    public name: string,
    public department: string,
    public phone: string,
    public email: string,
    public status: StatusType,
  ) {}

  static make(data: any): ClientContact {
    return new ClientContact(
      data.id,
      data.name ?? '',
      data.sector ?? '',
      data.phone_number ?? '',
      data.mail ?? '',
      data.status ?? StatusType.INACTIVE,
    );
  }
}
