import DataSource from './DataSource';

export default abstract class Repository {
  protected datasource : DataSource

  constructor() {
    this.datasource = new DataSource();
  }

  protected isStatusOK(status : number) : boolean {
    return status >= 200 && status < 300;
  }
}
