import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"absolute":"","bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button-tooltip',_vm._g(_vm._b({attrs:{"fab":"","icon":"","max-height":"29px","max-width":"29px","button-icon":"mdi-pencil","tooltip-text":"Editar"}},'button-tooltip',attrs,false),on))]}}])},[_c(VCard,{staticClass:"filled pa-4"},[_c(VForm,[_c(VTextField,{staticClass:"menu-input",attrs:{"type":"number","filled":"","error-messages":_vm.errorMessages,"rules":[function () { return _vm.model === 0 || !!_vm.model || 'Esse campo é obrigatório'; }, _vm.percentCheck],"label":_vm.label},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }