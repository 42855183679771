import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"cols":"12","lg":"5","md":"5","sm":"12","xs":"12"}},[_vm._t("actions")],2),_c(VCol,{staticClass:"order-md-first order-lg-first order-xl-first",attrs:{"cols":"auto"}},[_c(VTabs,{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item,index){return _c(VTab,{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1),_c(VRow,[_c(VCol,[_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._t("tabs")],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }