export enum EInstructionRemittanceField {
  e1_saldo = 'e1_saldo',
  e1_acresc = 'e1_acresc',
  e1_decresc = 'e1_decresc',
  e1_zdtflux = 'e1_zdtflux',
  e1_portado = 'e1_portado',
}

export enum EInstructionRemittanceFieldTranslate {
  e1_saldo = 'Saldo',
  e1_acresc = 'Acréscimo',
  e1_decresc = 'Decréscimo',
  e1_zdtflux = 'Data de Fluxo',
  e1_portado = 'Portador',
}

export default EInstructionRemittanceField;
