import Repository from '@/repositories/base/Repository';
import HealthCheck from '@/domain/models/healthCheck/HealthCheck';
import InvalidResponseException from './exceptions/InvalidResponseException';

export default class CashFlowRepository extends Repository {
  public async getHealthCheckIntegrator(
    companyGroupId: number,
    companyId: number,
  ): Promise<HealthCheck> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/health-check/integrator`,
      { headers: { 'company-id': companyId } },
    );

    return HealthCheck.make(response.data);
  }
}
