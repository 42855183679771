





































































import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';

@Component
export default class PaymentRemittancesListButtons extends Vue {
  @Prop(Boolean) generate: boolean = false;
  @Prop(Boolean) invoice: boolean = false;
  @Prop(Boolean) approve: boolean = false;

  @Emit('click')
  click(action: ActionType) {
    return action;
  }

  readonly action = ActionType;
}
