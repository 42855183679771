






















































































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import { toCurrency, arraySumProperty } from '@/utils';
import EditorMenu from '@/components/tables/EditorMenu.vue';

@Component({
  components: { EditorMenu },
})
export default class TotalCalculator extends Vue {
  public fee: number = 0;

  public get getTextPriceValuesColor(): string {
    if (this.$vuetify.theme.dark) {
        return '';
      }

      return '#007BDD';
  }

  public get getInfoCardColor(): string {
    if (this.$vuetify.theme.dark) {
      return '#565656';
    }

    return '#F2F3F7';
  }

  public get getQuantity(): number {
    return this.selected.length;
  }

  public get getSubtotal(): number {
    return arraySumProperty(this.selected, 'value');
  }

  public get formattedSubtotal(): number {
    return toCurrency(this.getSubtotal);
  }

  public get getCalculatedFee(): number {
    return (this.getSubtotal * Number(this.fee)) / 100;
  }

  public get getFormattedCalculatedFee(): number {
    return toCurrency(this.getCalculatedFee);
  }

  public get getTotal(): number {
    return this.getSubtotal + this.getCalculatedFee;
  }

  public get getFormattedTotal(): number {
    return toCurrency(this.getTotal);
  }

  public get getFormattedFee(): string {
    return `${Number(this.fee).toFixed(2)}%`;
  }

  public get getColumns(): Object {
    return this.$vuetify.breakpoint.smAndDown ? { cols: '12' } : {};
  }

  @Prop({
    type: Array,
    default: () => ([]),
    required: false,
  }) readonly selected!: number[];

  @Emit()
  public onChangeFee() {
    return this.fee;
  }
}
