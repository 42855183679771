<template>
  <input ref="input" :class="inputClasses" v-model="displayValue" onfocus="this.select();" />
</template>
<script>
/* eslint-disable no-undef */
export default {
  name: 'FormatedInput',
  props: {
    value: [Number, String, Object],
    filter: String,
    inputClasses: String,
    clearable: Boolean,
  },
  computed: {
    displayValue: {
      get() {
        if (!this.value && this.clearable) {
          return null;
        }
        if (this.$refs.input) {
          const length = this.$refs.input.selectionStart || 1;
          this.$nextTick(() => {
            let first = true;
            let newLength = length;

            if (first && (newLength == 4 || newLength == 8)) {
              newLength += 1;
              first = false;
            }

            this.$refs.input.setSelectionRange(newLength, newLength);
          });
        }
        return this.$options.filters[this.filter](this.value);
      },
      set(newValue) {
        if (this.clearable && newValue == ',00') {
          this.$emit('input', null);
        } else {
          this.$emit('input', numeral(newValue).value());
        }
      },
    },
  },
};
</script>

<style></style>
