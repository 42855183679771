import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"page-title":_vm.pageTitle}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"large":"","block":"","color":"error","disabled":_vm.isLoading},on:{"click":_vm.backToList}},[_vm._v(" CANCELAR ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"large":"","block":"","color":"info","disabled":_vm.isLoading},on:{"click":_vm.handleUpsertMainGrouper}},[_vm._v(" "+_vm._s(_vm.buttonTextBasedOnScreen)+" ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c(VForm,{ref:"UpsertMainGrouperCashFlowForm",staticClass:"px-10 py-7"},[_c(VRow,[_c(VCol,{attrs:{"cols":"5"}},[_c(VTextField,{attrs:{"label":"Nome","filled":"","rules":[
                _vm.inputRules.required(),
                _vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"items":_vm.companies,"disabled":_vm.isLoading,"label":"Empresa","height":"56px","clearable":"","filled":"","dense":""},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"items":_vm.statusOptions,"label":"Status","filled":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Descrição","filled":"","rules":[
                _vm.inputRules.required(),
                _vm.inputRules.lowerOrEqualThen(128) ]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"items":_vm.tableOptions,"label":"Tabela","rules":[_vm.inputRules.required()],"filled":""},model:{value:(_vm.table),callback:function ($$v) {_vm.table=$$v},expression:"table"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"items":_vm.screensOptions,"label":"Telas Exibidas","rules":[_vm.inputRules.required()],"multiple":"","filled":""},model:{value:(_vm.screensToShow),callback:function ($$v) {_vm.screensToShow=$$v},expression:"screensToShow"}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }