import Repository from '@/repositories/base/Repository';
import IUpsertSimulationFiltersRequest from '@/views/cashFlow/predictedFlow/interfaces/IUpsertSimulationFiltersRequest';
import InvalidResponseException from '@/repositories/exceptions/InvalidResponseException';

export default class CashFlowSimulationRepository extends Repository {
  public async saveSimulation(
    params: IUpsertSimulationFiltersRequest[],
  ): Promise<void> {
    const { data: request } = await this.datasource.send('simulation/addNew', { arrayData: JSON.stringify(params) });

    if (request && typeof request === 'object' && request.error) {
      throw new InvalidResponseException();
    }
  }

  public async updateSimulation(
    params: IUpsertSimulationFiltersRequest[],
  ): Promise<void> {
    const { data: request } = await this.datasource.send('simulation/updateIten', { arrayData: JSON.stringify(params) });

    if (request && typeof request === 'object' && request.error) {
      throw new InvalidResponseException();
    }
  }

  public async removeSimulation(simulationId: number): Promise<void> {
    const { data: request } = await this.datasource.send(
      'simulation/removeIten',
      { simulation_id: simulationId },
    );

    if (request && typeof request === 'object' && request.error) {
      throw new InvalidResponseException();
    }
  }
}
