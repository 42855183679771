<template>
  <div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title"></div>
      </div>
      <div class="m-portlet__head-tools">
        <router-link :to="{ name: 'company' }" class="m-btn btn btn-warning mr-3"
          >Cancelar</router-link
        >
        <button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
      </div>
    </div>
    <div class="m-portlet__body">
      <!--begin: Search Form -->
      <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
        <ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
          <li class="nav-item m-tabs__item">
            <a
              class="nav-link m-tabs__link active"
              data-toggle="tab"
              href="#tab1"
              role="tab"
              aria-expanded="true"
            >
              Cadastro
            </a>
          </li>
          <li class="nav-item m-tabs__item">
            <a
              class="nav-link m-tabs__link"
              data-toggle="tab"
              href="#tab2"
              role="tab"
              aria-expanded="false"
            >
              Configurações
            </a>
          </li>
          <li class="nav-item m-tabs__item">
            <a
              class="nav-link m-tabs__link"
              data-toggle="tab"
              href="#tab3"
              role="tab"
              aria-expanded="false"
            >
              Configurações de Retorno
            </a>
          </li>
        </ul>
        <div class="tab-content" id="frmCompany">
          <div class="tab-pane active" id="tab1" role="tabpanel">
            <div class="row" id="frmCompanyRegister">
              <input type="hidden" name="company_id" id="company_id" v-model="company_id" />
              <div class="col-md-3">
                <div class="form-group">
                  <label>CNPJ</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="CNPJ"
                    id="cnpj"
                    name="cnpj"
                    required
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>IE</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="IE"
                    id="state_registration"
                    name="state_registration"
                    required
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Nome abreviado</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Nome abreviado"
                    id="initials"
                    name="initials"
                    required
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Nome fantasia</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Nome fantasia"
                    id="fantasy_name"
                    name="fantasy_name"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Razão social</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Razão social"
                    id="description"
                    name="description"
                    required
                  />
                </div>
              </div>
              <div class="col-md-7">
                <div class="form-group">
                  <label>Endereço</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Endereço"
                    id="street"
                    name="street"
                    required
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Bairro</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Bairro"
                    id="neighborhood"
                    name="neighborhood"
                    required
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Cidade</label>
                  <select
                    class="form-control select2 hide"
                    field-name="Cidade"
                    id="city_id"
                    name="city_id"
                    style="width: 100%;"
                  >
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>CEP</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="CEP"
                    id="zipcode"
                    name="zipcode"
                    required
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Telefone</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Telefone"
                    id="phone"
                    name="phone"
                    required
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Status</label>
                  <select
                    class="form-control select2 hide"
                    field-name="Status"
                    id="status"
                    name="status"
                    style="width: 100%;"
                  >
                    <option value="0">Inativo</option>
                    <option value="1">Ativo</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab2" role="tabpanel">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Erp</label>
                  <select
                    class="form-control select2 hide"
                    field-name="Erp"
                    id="erp_id"
                    name="erp_id"
                    style="width: 100%;"
                  >
                    <option value="">Selecione...</option>
                    <option v-for="erp in erps" :key="erp.id" :value="erp.id">{{
                      erp.description
                    }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Código da empresa no ERP</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Código da empresa no ERP"
                    id="erp_company_code"
                    name="erp_company_code"
                    required
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Dias para importação</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Dias para importação"
                    id="import_days"
                    name="import_days"
                    required
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Hora para importação</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Hora para importação"
                    id="import_time"
                    name="import_time"
                    required
                  />
                </div>
              </div>
              <!--<div class="col-md-4">
			                    <div class="form-group">
			                        <label>Imagem</label>
			                        <input type="text" class="form-control" field-name="Imagem" id="image" name="image">
			                    </div>
			                </div>-->
            </div>
          </div>
          <div class="tab-pane" id="tab3" role="tabpanel">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Tempo para aglutinar</label>
                  <input
                    v-mask="mask"
                    class="form-control"
                    field-name="AgglutinattionTime"
                    id="agglutition_time"
                    name="agglutition_time"
                    style="width: 100%;"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Envia WorkFlow de retornos bancários</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Envia WorkFlow de retornos bancários"
                    id="send_wf"
                    name="send_wf"
                    required
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Dias para importação</label>
                  <input
                    type="text"
                    class="form-control"
                    field-name="Dias para importação"
                    id="import_days"
                    name="import_days"
                    required
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Hora para importação</label>
                  <input
                    v-mask='mask'
                    type="text"
                    class="form-control"
                    field-name="Hora para importação"
                    id="import_time"
                    name="import_time"
                    required
                  />
                </div>
              </div>
              <!--<div class="col-md-4">
			                    <div class="form-group">
			                        <label>Imagem</label>
			                        <input type="text" class="form-control" field-name="Imagem" id="image" name="image">
			                    </div>
			                </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      erps: [],
      company_id: this.$route.params.company_id,

      mask: '#########################'
    };
  },

  mounted: function() {
    this.loadData();
    this.loadMethod();
    this.loadCompany();
    this.setMaskForFields();
  },

  methods: {
    setMaskForFields() {
      $('#agglutition_time').mask('00:00, 00:00, 00:00, 00:00', {reverse: true});
    },
    loadData: function() {
      var self = this;
      let arrayData = [],
        exitButton = true,
        fieldName = "",
        i = 0,
        post = "";

      blockPage("Carregando os dados...");

      $("#city_id").select2({
        ajax: {
          url: self.$store.state.API_BASE + "city/listCitys",
          dataType: "json",
          delay: 250,
          headers: {
            token: self.$session.get("token")
          },
          data: function(params) {
            var par = {
              q: params.term
            };
            return par;
          },
          processResults: function(data) {
            return {
              results: data
            };
          },
          cache: true
        },
        minimumInputLength: 4
      });

      $("#erp_id").select2();

      $.ajax({
        method: "GET",
        url: self.$store.state.API_BASE + "erp/listAll",
        assync: true,
        headers: {
          token: self.$session.get("token")
        }
      })
        .done(function(result) {
          self.erps = result.data;
          unblockPage();
        })
        .fail(function(error) {
          console.log(error.responseText);
          unblockPage();
        });

      if (self.$session.get("user_type") == "O") {
        $.ajax({
          method: "GET",
          url: self.$store.state.API_BASE + "companygroup/listAll",
          assync: true,
          headers: {
            token: self.$session.get("token")
          }
        })
          .done(function(result) {
            var options = "";

            $.each(result.data, function(resultItem) {
              options =
                options +
                '<option value="' +
                result.data[resultItem].id +
                '">' +
                result.data[resultItem].description +
                "</option>";
            });

            $("#frmCompanyRegister").prepend(
              '<div class="col-md-12">' +
                '<div class="form-group">' +
                "<label>Grupo de empresa</label>" +
                '<select class="form-control select2 hide" field-name="Grupo de empresa" id="company_group_id" name="company_group_id" style="width: 100%;">' +
                '<option value="">Selecione...</option>' +
                options +
                "</select>" +
                "</div>" +
                "</div>"
            );

            $("#company_group_id").select2();
            unblockPage();
          })
          .fail(function(error) {
            console.log(error.responseText);
            unblockPage();
          });
      } else {
        $("#frmCompanyRegister").prepend(
          '<input type="hidden" id="company_group_id" name="company_group_id" value="' +
            self.$session.get("company_group_id") +
            '"/>'
        );
      }
    },

    loadCompany: function() {
      var self = this;

      $.ajax({
        method: "GET",
        url: self.$store.state.API_BASE + "company/listOne/" + self.$route.params.company_id,
        headers: {
          token: self.$session.get("token")
        }
      })
        .done(function(result) {
          var statusDesc = "";

          $("#cnpj").val(result.data[0].cnpj);
          $("#state_registration").val(result.data[0].state_registration);
          $("#initials").val(result.data[0].initials);
          $("#fantasy_name").val(result.data[0].fantasy_name);
          $("#description").val(result.data[0].name);
          $("#street").val(result.data[0].street);
          $("#neighborhood").val(result.data[0].neighborhood);
          //$('#city_id').val(result.data[0].city_id)
          $("#zipcode").val(result.data[0].zipcode);
          $("#phone").val(result.data[0].phone);
          $("#status").val(result.data[0].status);
          $("#company_group_id").val(result.data[0].company_group_id);
          $("#erp_id").val(result.data[0].erp_id);
          $("#erp_company_code").val(result.data[0].erp_company_code);
          $("#import_days").val(result.data[0].import_days);
          $("#import_time").val(result.data[0].import_time);
          $("#image").val(result.data[0].url_image);

          $("#city_id").val(result.data[0].city_id);
          $("#city_id").append(
            '<option value="' +
              result.data[0].city_id +
              '">' +
              result.data[0].city_name +
              "</option>"
          );
          $("#select2-city_id-container").text(result.data[0].city_name);

          if (self.$session.get("user_type") == "O") {
            $("#select2-company_group_id-container").attr(
              "title",
              result.data[0].company_group_name
            );
            $("#select2-company_group_id-container").html(result.data[0].company_group_name);
          }

          $("#select2-city_id-container").attr("title", result.data[0].city_name);
          $("#select2-city_id-container").html(result.data[0].city_name);

          $("#select2-erp_id-container").attr("title", result.data[0].erp_name);
          $("#select2-erp_id-container").html(result.data[0].erp_name);

          if (result.data[0].status == 1) {
            statusDesc = "Ativo";
          } else {
            statusDesc = "Inativo";
          }
          $("#select2-status-container").attr("title", statusDesc);
          $("#select2-status-container").html(statusDesc);
          unblockPage();
        })
        .fail(function(error) {
          console.log(error.responseText);
          unblockPage();
        });
    },

    loadMethod: function() {
      var self = this;

      $("#import_days").mask("000");
      $("#import_time").mask("00:00");
      $("#cnpj").mask("00.000.000/0000-00");
      $("#phone").mask("(00) 00000-0000");
      $("#zipcode").mask("00000-000");

      $("#btn-save").click(function() {
        var arrayData = [],
          exitButton = true,
          fieldName = "",
          i = 0,
          post = "";
        var btn = $(this);

        btn.addClass("m-loader m-loader--right m-loader--light").attr("disabled", true);

        $.each($("input, select", "#frmCompany"), function() {
          var $this = $(this);

          if ($.trim(this.value) == "") {
            exitButton = true;
            fieldName = $this.attr("field-name");

            $(".form-group:has(#" + this.id + ")").addClass("has-danger");
            $("#" + this.id).addClass("input-error");
            $("#" + this.id).css("border-color", "red");
            return false;
          }

          if (this.id == "import_time") {
            if ($this.val().length != 5) {
              exitButton = true;
              fieldName = $this.attr("field-name");

              $(".form-group:has(#" + this.id + ")").addClass("has-danger");
              $("#" + this.id).addClass("input-error");
              $("#" + this.id).css("border-color", "red");
              return false;
            } else {
              var hour = $this.val().split(":")[0];
              var minute = $this.val().split(":")[1];

              if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
                exitButton = true;
                fieldName = $this.attr("field-name");

                $(".form-group:has(#" + this.id + ")").addClass("has-danger");
                $("#" + this.id).addClass("input-error");
                $("#" + this.id).css("border-color", "red");
                return false;
              }
            }
          }

          exitButton = false;
          arrayData[i] = {};
          arrayData[i][this.id] = this.value;

          i++;
        });

        if (exitButton) {
          btn.removeClass("m-loader m-loader--right m-loader--light").attr("disabled", false);
          showNotification(
            "Atenção",
            "O campo <strong>" + fieldName + "</strong> não foi informado, ou é invalido!",
            "warning"
          );
          return false;
        }

        $.ajax({
          method: "POST",
          url: self.$store.state.API_BASE + "company/edit",
          headers: {
            token: self.$session.get("token")
          },
          data: {
            arrayData: JSON.stringify(arrayData)
          }
        })
          .done(function(result) {
            btn.removeClass("m-loader m-loader--right m-loader--light").attr("disabled", false);

            if (result.error == false) {
              showNotification("Sucesso", "Empresa alterada!", "success");
            } else {
              showNotification("Atenção", result.message, "danger");
              return false;
            }
          })
          .fail(function(error) {
            btn.removeClass("m-loader m-loader--right m-loader--light").attr("disabled", false);
            console.log(error.responseText);
          });
      });

      $("input").keyup(function() {
        if ($("#" + this.id).hasClass("input-error")) {
          $("#" + this.id).removeClass("input-error");
          $("#" + this.id).css("border-color", "#ebedf2");
          $(".form-group:has(#" + this.id + ")").removeClass("has-danger");
        }
      });
    }
  }
};
</script>