<template>
  <v-checkbox
    v-model="model"
    :label="label"
    :hide-details="hideDetails"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-checkbox>
</template>

<script>
import ModelManagementMixin from '@/mixins/ModelManagementMixin';

export default {
  name: 'InnCheckbox',
  mixins: [ModelManagementMixin],
  props: {
    label: String,
    color: String,
    hideDetails: Boolean,
  },
};
</script>

<style></style>
