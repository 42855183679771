
































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';

import IPayrollSelectFilter from '@/domain/interfaces/IPayrollSelectFilter';
import PayrollSelectItem from '@/domain/models/PayrollSelectItem';
import PayrollRepository from '@/repositories/PayrollRepository';

import PayrollCardHeader from './components/PayrollCardHeader.vue';
import PayrollSelectFilter from './components/PayrollSelectFilter.vue';
import PayrollSelectList from './components/PayrollSelectList.vue';

@Component({
  components: {
    PayrollCardHeader,
    PayrollSelectFilter,
    PayrollSelectList,
  },
})
export default class PayrollSelect extends Vue {
  @Ref('filter') filterComponent!: PayrollSelectFilter;

  readonly payrollRepository: PayrollRepository = new PayrollRepository();

  showFilter: boolean = true;
  loading: boolean = false;
  items: Array<PayrollSelectItem> = [];
  selecteds: Array<PayrollSelectItem> = [];

  serverItemsLength: number = 0;
  data: IPayrollSelectFilter = {} as IPayrollSelectFilter;

  handleFilter(): void {
    const { valid, data } = this.filterComponent.validate();

    if (valid) {
      this.data = data;
      this.data.page = 1;

      const { companies, ...filters } = data;

      this.load(companies, filters);
    }
  }

  handleOnChangePage(page: number): void {
    this.data.page = page;
    this.reload();
  }

  handleOnChangeItemsPerPage(itemsPerPage: number): void {
    this.data.itemsPerPage = itemsPerPage;
    this.reload();
  }

  handleOnChangeSortBy(sortBy: string | Array<string>) {
    if (typeof sortBy === 'string') {
      this.data.sortBy = sortBy;
    }

    if (typeof sortBy === 'object' && Array.isArray(sortBy)) {
      const [by] = sortBy;
      this.data.sortBy = by;

      if (!by) {
        this.data.sortDesc = undefined;
      }
    }

    if (!this.loading) this.reload();
  }

  handleOnChangeSortDesc(sortDesc: boolean | Array<boolean>) {
    if (typeof sortDesc === 'boolean') {
      this.data.sortDesc = sortDesc;
    }

    if (typeof sortDesc === 'object' && Array.isArray(sortDesc)) {
      const [desc] = sortDesc;
      this.data.sortDesc = desc;
    }

    if (!this.loading && this.data.sortBy) this.reload();
  }

  reload(): void {
    if (!this.data) {
      this.$notification.warn('Sem filtros selecionados!');
      return;
    }

    const { companies, ...filters } = this.data;

    this.load(companies, filters);
  }

  async load(companies: Array<number>, filters: Omit<IPayrollSelectFilter, 'companies'>): Promise<void> {
    try {
      this.loading = true;
      this.$dialog.startLoading();

      const groupId: number = this.$session.get('company_group_id');

      const params = {
        end_due_date: filters.endDueDate,
        end_issue_date: filters.endIssueDate,
        initial_due_date: filters.initialDueDate,
        initial_issue_date: filters.initialIssueDate,
        per_page: filters.itemsPerPage,
        number: filters.number ?? undefined,
        page: filters.page,
        prefixes: filters.prefixes,
        sort_by: filters.sortBy,
        sort_desc: filters.sortDesc,
        suppliers: filters.suppliers,
        value: filters.value ?? undefined,
       };

      const response = await this.payrollRepository.getTitles(groupId, companies, params);

      this.items = response.data;
      this.serverItemsLength = response.meta.total ?? 0;

      if (!this.items.length) {
        this.$notification.warn('Nenhum resultado encontrado!');
        return;
      }

      this.showFilter = false;
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
      this.loading = false;
    }
  }
}
