<template>
  <v-switch
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    :hide-details="hideDetails"
    :color="color"
    :label="label"
  >
  </v-switch>
</template>

<script>
import ModelManagementMixin from '@/mixins/ModelManagementMixin';

export default {
  name: 'InnSwitch',
  mixins: [ModelManagementMixin],
  props: {
    label: String,
    color: String,
    hideDetails: Boolean,
  },
};
</script>

<style></style>
