






























import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import SummaryData, { BankingMovementBalance } from '@/domain/models/SummaryBankingMovement';
import { formateDate } from '@/utils/date';
import { SummaryDate } from '../utils/interfaces';
import { formatCurrency } from '../utils';

interface SummaryDataWithEndBankBalance {
  initialBankBalance: BankingMovementBalance,
  credits: BankingMovementBalance,
  debts: BankingMovementBalance,
  endBankBalance: BankingMovementBalance,
}

@Component({})
export default class StatementConciliationSummary extends Vue {
  @Prop({
    type: Object as () => SummaryDate,
  }) readonly date!: SummaryDate;

  @Prop({
    type: Object as () => SummaryData,
  }) readonly summary!: SummaryData;

  @Prop(Number) readonly value!: number;

  @Watch('value')
  changedValues() {
    this.data = {
      ...this.summary,
      credits: {
        erp: this.creditsErp,
        bank: this.summary.credits.bank,
        diff: this.creditsDiff,
      },
      debts: {
        erp: this.summary.debts.erp,
        bank: this.summary.debts.bank,
        diff: this.debtsDiff,
      },
      endBankBalance: {
        erp: this.endBankBalanceErp,
        bank: this.endBankBalanceBank,
        diff: this.endBankBalanceDiff,
      },
    };
  }

  mounted() {
    this.data = {
      ...this.summary,
      credits: {
        erp: this.creditsErp,
        bank: this.summary.credits.bank,
        diff: this.creditsDiff,
      },
      debts: {
        erp: this.summary.debts.erp,
        bank: this.summary.debts.bank,
        diff: this.debtsDiff,
      },
      endBankBalance: {
        erp: this.endBankBalanceErp,
        bank: this.endBankBalanceBank,
        diff: this.endBankBalanceDiff,
      },
    };
  }

  readonly formatCurrency = formatCurrency;

  data: SummaryDataWithEndBankBalance = {} as SummaryDataWithEndBankBalance;

  get creditsErp(): number {
    return this.summary.credits.erp + this.value;
  }

  get creditsDiff(): number {
    return this.creditsErp - this.summary.credits.bank;
  }

  get debtsDiff(): number {
    return this.summary.debts.erp - this.summary.debts.bank;
  }

  get endBankBalanceErp(): number {
    return this.summary.initialBankBalance.erp + this.creditsErp - this.summary.debts.erp;
  }

  get endBankBalanceBank(): number {
    return this.summary.initialBankBalance.bank
    + this.summary.credits.bank
    - this.summary.debts.bank;
  }

  get endBankBalanceDiff(): number {
    return this.summary.initialBankBalance.diff + this.creditsDiff - this.debtsDiff;
  }

  formatLabel(label: keyof SummaryDataWithEndBankBalance): string {
    let formattedLabel: string = '';

    switch (label) {
      case 'initialBankBalance': {
        const { initialBankBalance } = this.date;
        formattedLabel = `Saldo Inicial - ${formateDate(initialBankBalance)}`;
        break;
      }
      case 'credits': {
        formattedLabel = 'Créditos';
        break;
      }
      case 'debts': {
        formattedLabel = 'Débitos';
        break;
      }
      case 'endBankBalance': {
        const { endBankBalance } = this.date;
        formattedLabel = `Saldo Final - ${formateDate(endBankBalance)}`;
        break;
      }
      default: {
        formattedLabel = label;
      }
    }

    return formattedLabel;
  }
}
