<template>
  <v-row class="pa-12">
		<v-col class="pa-0">
			<v-card class="elevation-0">
				<v-form ref="formApiBankingCredential">
					<v-card-title>
						<v-btn
							icon
							fab
							small
							@click="$router.go(-1)"
							class="mx-2">
								<v-icon>
									mdi-arrow-left
								</v-icon>
						</v-btn>
						<h4 class="text-h6 font-weight-bold ml-4 mt-2">
							{{ pageTitle }}
						</h4>
						<v-spacer />
						<v-btn
							class="elevation-0 mr-2"
							color="disable"
							@click="$router.go(-1)">
							<v-icon class="mr-2">
								mdi-close
							</v-icon>
							Cancelar
						</v-btn>
						<v-btn
							v-show="registerPage"
							:loading="loadingButton"
							class="elevation-0"
							color="info"
							@click="onSubmit">
							<v-icon class="mr-2">
								mdi-check
							</v-icon>
							Salvar
						</v-btn>
						<v-btn
							v-show="updatePage"
							:loading="loadingButton"
							class="elevation-0"
							color="info"
							@click="onUpdate">
							<v-icon class="mr-2">
								mdi-check
							</v-icon>
							Salvar
						</v-btn>
					</v-card-title>
					<v-divider class="mt-0" />
					<v-card-text>
						<v-row>
							<v-col cols="12" lg="4" md="4">
								<span>
									Selecione o grupo de empresa
								</span>
								<v-select
									v-model="apiBankingCredential.companyGroupId"
									class="mt-2"
									outlined
									:loading="loading"
									dense
									:rules="[rules.required]"
									@change="loadCompanies()"
									:items="companyGroupSelect"
                  item-text="description"
                  item-value="id"
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									Selecione a empresa
								</span>
								<v-select
									v-model="apiBankingCredential.companyId"
									class="mt-2"
									outlined
									:rules="[rules.required]"
									dense
									:loading="loadingCompanies"
									:items="companySelect"
                  item-text="name"
                  item-value="id"
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									Status
								</span>
								<v-select
									v-model="apiBankingCredential.status"
									class="mt-2"
									outlined
									:rules="[rules.required]"
									dense
									:items="statusSelect"
                  item-text="name"
                  item-value="value"
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									Nome
								</span>
								<v-text-field
									v-model="apiBankingCredential.userName"
									class="mt-2"
									:rules="[rules.required]"
									outlined
									dense
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									Usuário
								</span>
								<v-text-field
									v-model="apiBankingCredential.userLogin"
									class="mt-2"
									:rules="[rules.required]"
									outlined
									dense
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									{{passwordTitle}}
								</span>
								<v-text-field
									v-model="apiBankingCredential.userPass"
									class="mt-2"
									outlined
									:rules="updatePage ? [] : [rules.required]"
									dense
									:append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
									@click:append="showPassword = !showPassword"
									:type="showPassword ? 'text' : 'password'"
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									Tipo de acesso
								</span>
								<v-select
									v-model="apiBankingCredential.accessType"
									class="mt-2"
									outlined
									dense
									:rules="[rules.required]"
									:items="accessTypeSelect"
                  item-text="name"
                  item-value="value"
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									Chave Pix
								</span>
								<v-text-field
									v-model="apiBankingCredential.pixKey"
									class="mt-2"
									outlined
									:rules="[rules.required]"
									dense
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									Vinculo à API
								</span>
								<v-select
									v-model="apiBankingCredential.apiBankingId"
									@change="loadApiServices"
									class="mt-2"
									outlined
									:loading="loading"
									dense
									:rules="[rules.required]"
									:items="apiBanking"
                  item-text="name"
                  item-value="id"
								/>
							</v-col>
							<v-col cols="12" lg="4" md="4">
								<span>
									Serviços API
								</span>
								<v-select
									v-model="apiBankingCredential.apiBankingServiceId"
									class="mt-2"
									:rules="[rules.required]"
									outlined
									dense
									:items="apiBankingService"
                  item-text="service"
                  item-value="id"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-form>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
// eslint-disable-next-line
import ApiBankingCredentialService from "@/services/apibankingcredential.service";
// eslint-disable-next-line
import CompanyService from "@/services/company.service";
// eslint-disable-next-line
import CompanyGroupService from "@/services/company_group.service";
// eslint-disable-next-line
import ApiBankingService from "@/services/apibanking.service";
export default {
	name: 'ApiBankingCredentialSave',

  data() {
    return {
			pageTitle: 'Cadastrar',
			passwordTitle: 'Senha',
			showPassword: false,
			loading: false,
			loadingCompanies: false,
			loadingApiServices: false,
			loadingButton: false,
			registerPage: true,
			updatePage: false,
			rules: {
        required: (value) => !!value || 'Campo obrigatório',
      },
			apiBankingCredential: {},
			companyGroupSelect: [],
			companySelect: [],
			statusSelect: [
        { name: 'Ativo', value: 'A' },
        { name: 'Inativo', value: 'I' },
      ],
			accessTypeSelect: [
        { name: 'Homologa', value: 'H' },
        { name: 'Produção', value: 'P' },
      ],
			apiBanking: [],
			apiBankingService: [],
		};
  },

	async mounted() {
		if (this.$route.params.id) {
			this.pageTitle = 'Editar';
			this.passwordTitle = 'Alterar senha';
			this.id = this.$route.params.id;
			this.registerPage = false;
			this.updatePage = true;
			await this.loadById(this.id);
			await this.loadCompanies();
			await this.loadApiServices();
		}
	},

	async created() {
    try {
      this.loading = true;
			await this.loadGroup();
			await this.loadApiBanking();
    } catch (error) {
				this.$helpers.defaultCatchError(error);
    } finally {
      this.loading = false;
    }
  },

	methods: {
		async loadById(id) {
      const apiBankingCredentialService = new ApiBankingCredentialService();
      const result = await apiBankingCredentialService.listOne().show(id);
      // eslint-disable-next-line prefer-destructuring
      this.apiBankingCredential = result.data[0];
    },
    async loadGroup() {
      const companyGroupService = new CompanyGroupService();
      const response = await companyGroupService.listAll().index();
      this.companyGroupSelect = response.data;
    },
		async loadCompanies() {
			try {
				this.loadingCompanies = true;
				const companyByGroup = this.apiBankingCredential.companyGroupId;
				const companyService = new CompanyService();
				const response = await companyService.listAllbyGroup().show(companyByGroup);
				this.companySelect = response.data;
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loadingCompanies = false;
			}
    },
		async loadApiBanking() {
			const apiBankingService = new ApiBankingService();
      const response = await apiBankingService.index();
      this.apiBanking = response.data;
		},
		async loadApiServices() {
			try {
				this.loadingApiServices = true;
				const serviceByApi = this.apiBankingCredential.apiBankingId;
				const apiBankingService = new ApiBankingService();
				const response = await apiBankingService.service(serviceByApi).index();
				this.apiBankingService = response.data;
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loadingApiServices = false;
			}
		},
		async onSubmit() {
			if (!this.$refs.formApiBankingCredential.validate()) return;
			try {
				this.loadingButton = true;
				const apiBankingCredentialService = new ApiBankingCredentialService();
				const result = await apiBankingCredentialService
				.save()
				.store(this.apiBankingCredential);
				if (result.error == true) {
          // eslint-disable-next-line
          showNotification('Atenção', result.message, 'danger');
          } else {
					this.loadingButton = false;
          // eslint-disable-next-line
          showNotification('Sucesso', result.message, 'success');
          this.$router.push('/apibankingcredential');
			}
			} catch (error) {
					this.$helpers.defaultCatchError(error);
      }
		},
		async onUpdate() {
			if (!this.$refs.formApiBankingCredential.validate()) return;
			try {
				const parameters = {
          id: this.id,
          companyId: this.apiBankingCredential.companyId,
          companyGroupId: this.apiBankingCredential.companyGroupId,
          userName: this.apiBankingCredential.userName,
					userLogin: this.apiBankingCredential.userLogin,
					userPass: this.apiBankingCredential.userPass,
					accessType: this.apiBankingCredential.accessType,
					pixKey: this.apiBankingCredential.pixKey,
					status: this.apiBankingCredential.status,
					apiBankingId: this.apiBankingCredential.apiBankingId,
					apiBankingServiceId: this.apiBankingCredential.apiBankingServiceId,
					};
				this.loadingButton = true;
				const apiBankingCredentialService = new ApiBankingCredentialService();
				const result = await apiBankingCredentialService
				.update().send(parameters);
				if (result.error == true) {
          // eslint-disable-next-line
          showNotification('Atenção', result.message, 'danger');
          } else {
					this.loadingButton = false;
          // eslint-disable-next-line
          showNotification('Sucesso', result.message, 'success');
          this.$router.push('/apibankingcredential');
        }
			} catch (error) {
					this.$helpers.defaultCatchError(error);
      }
		},
	},
};
</script>
