import Customer from '@/domain/models/Customer';
import Repository from '@/repositories/base/Repository';

export default class CustomerRepository extends Repository {
  public async getCustomersByIdsCustomer(
    companyGroupId: number,
    companyIds: number[],
    idsCustomer: string[],
  ): Promise<Customer[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/customers/search/ids-customer`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
      params: { ids_customer: idsCustomer },
    });

    return response.data.map(Customer.make);
  }
}
