import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-4"},[_c(VCard,{attrs:{"flat":""}},[_c(VContainer,{staticClass:"m-portlet px-0",attrs:{"fluid":"","loading":_vm.loading}},[_c(VRow,{staticClass:"px-8 py-5"},[_c(VCol,{attrs:{"xl":"3","lg":"3","md":"6","sm":"6","xs":"12"}},[_vm._t("search")],2),_c(VCol,{attrs:{"xl":"9","lg":"9","md":"6","sm":"6","xs":"12"}},[_vm._t("actions")],2)],1),_c(VDivider,{directives:[{name:"show",rawName:"v-show",value:(_vm.paramnsVisibility),expression:"paramnsVisibility"}]}),_c(VSlideYReverseTransition,[_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.paramnsVisibility),expression:"paramnsVisibility"}],staticClass:"px-8 py-5"},[_c(VCol,{attrs:{"cols":"12"}},[_vm._t("paramns")],2)],1)],1)],1)],1),_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.tableVisibility),expression:"tableVisibility"}],staticClass:"m-portlet",attrs:{"flat":""}},[_c(VContainer,{staticClass:"m-portlet px-0",attrs:{"fluid":""}},[_vm._t("table")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }