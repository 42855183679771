import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c(VRow,{attrs:{"justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_vm._v(" Gerar Remessa ")]),_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"disabled":_vm.loading}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.companyOptions,"rules":[_vm.required],"label":"Empresa","placeholder":"Selecione uma empresa","no-data-text":_vm.loading
                    ? 'Carregando...'
                    : 'Não há dados disponíveis.',"auto-select-first":"","deletable-chips":"","clearable":"","filled":"","dense":""},on:{"change":function($event){return _vm.changedCompany()}},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.bankOptions,"rules":[_vm.required],"label":"Banco","placeholder":"Selecione um banco","loading":_vm.loadingBank,"no-data-text":!_vm.company
                    ? 'Selecione uma empresa para exibir as opções.'
                    : _vm.loadingBank
                      ? 'Carregando...'
                      : 'Não há dados disponíveis.',"auto-select-first":"","clearable":"","filled":"","dense":""},on:{"change":function($event){return _vm.changedBank()}},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.sendTypesItems,"rules":[_vm.required],"label":"Remessa","placeholder":"Selecione um tipo de remessa","no-data-text":!_vm.bank
                    ? 'Selecione um banco para exibir as opções.'
                    : 'Não há dados disponíveis.',"auto-select-first":"","clearable":"","filled":"","dense":""},on:{"change":function($event){return _vm.changedSendType()}},model:{value:(_vm.sendType),callback:function ($$v) {_vm.sendType=$$v},expression:"sendType"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('inn-date-field',{attrs:{"label":"Data de Pagamento","placeholder":"Selecione a data de pagamento","required":true,"buttons":false,"nudge-top":'25%'},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Filial")]),_c('th',[_vm._v("Portador")]),_c('th',[_vm._v("Valor")])])]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(_vm.formatTitle(item, _vm.name))+" "),(!_vm.hasPaymentData(item, _vm.groupConfig))?_c('span',[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"warning","dense":"","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},[_c('span',[_vm._v("Dados de pagamento não preenchidos!")])])],1):_vm._e()]),_c('td',[_vm._v(_vm._s(item.branch))]),_c('td',[_vm._v(_vm._s(item.ported))]),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(item.total)))])])}),0),_c('tfoot',[_c('tr',[_c('th',[_vm._v("Total Selecionados")]),_c('td',[_vm._v(_vm._s(_vm.items.length))]),_c('th',[_vm._v("Valor Total")]),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(_vm.total)))])])])]},proxy:true}])})],1),_c(VCardActions,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"warning","depressed":"","large":"","block":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")])],1),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"success","depressed":"","large":"","block":"","dark":""},on:{"click":_vm.save}},[_vm._v(" CONTINUAR ")])],1)],1)],1)],1)],1),(_vm.openFeedback)?_c('generate-feedback',{attrs:{"open":_vm.openFeedback,"title":_vm.titleFeedback,"text":_vm.textFeedback,"number":_vm.numberFeedback,"type":_vm.typeFeedback},on:{"close":_vm.handleCloseFeedback,"reload":function($event){return _vm.close(true)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }