import SerasaFilesInfoList from '@/domain/models/SerasaFilesInfoList';
import Repository from '@/repositories/base/Repository';

export default class SerasaConsultationRepository extends Repository {
    public async attachSerasaFiles(
        companyGroupId: number,
        idCustomer: string,
        file: FormData,
    ): Promise<void> {
        const { status, data: response } = await this.datasource.send(`company-groups/${companyGroupId}/customers/${idCustomer}/serasa`, file);

        if (!this.isStatusOK(status)) {
            throw new Error('Aconteceu um erro inesperado');
        }
    }

    public async removeSerasaFile(
        companyGroupId: number,
        idCustomer: string,
        fileId: number,
    ): Promise<void> {
        await this.datasource.destroy(`company-groups/${companyGroupId}/customers/${idCustomer}/serasa/${fileId}`);
    }

    public async getSerasaFilesInfoList(
        companyGroupId: number,
        idCustomer: string,
    ): Promise<SerasaFilesInfoList[]> {
        const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/customers/${idCustomer}/serasa`);

        return response.data.map(SerasaFilesInfoList.make);
    }
}
