<template>
  <v-container :class="['pa-0', { darkmode: isDarkMode }]" fluid>
    <v-row justify="space-between">
      <v-col cols="12" lg="12" class="d-flex justify-space-around">
        <div>Valor Total: R$ {{ total | formatNumber }}</div>

        <v-spacer></v-spacer>
        <date-select
          v-if="type === 'payment'"
          :value="dueDate"
          label="Data de vencimento"
          id="emissionEndDate"
          @change="
            $emit('input', {
              key: 'dueDate',
              value: $event,
            })
          "
        />
      </v-col>
    </v-row>
    <v-expand-transition>
      <div class="mt-n4" v-show="selectedItems.length">
        <p>
          Itens Selecionados
          <b>
            (Total R$ {{ totalSelectedItemsValue | formatNumber }})
            <span
              :class="{
                'red--text': calculatedDifference != 0,
                'green--text': calculatedDifference == 0,
              }"
              >(Diferença: R$ {{ calculatedDifference | formatNumber }})</span
            >
          </b>
        </p>
        <v-card class="mb-8 p-2 selectedItemsCard">
          <span v-for="item in selectedItems" :key="item.e1_num">
            <v-icon color="#575962" class="remove" @click="uncheckItem(item)">
              mdi-close
            </v-icon>
            {{ item.e1_prefixo }} - {{ item.e1_num }} -
            {{ item.e1_parcela || "001" }} -
            {{ item.e1_tipo }}
          </span>
        </v-card>
      </div>
    </v-expand-transition>
    <input
      v-if="items.length != 0"
      v-model="search"
      append-icon="mdi-magnify"
      label="Pesquisar"
      placeholder="Pesquisar"
      single-line
      hide-details
      class="form-control mb-2"
      id="search-input"
    />
    <v-data-table
      class="income-record-table"
      v-sticky="items"
      show-select
      disable-sort
      disable-pagination
      hide-default-footer
      hide-default-header
      :items="itemsToNumber"
      :search="formattedSeachString()"
      :headers="headers"
    >
      <template v-slot:header="">
        <thead>
          <tr class="group-row" v-if="items.length == 0">
            <td class="font-weight-regular">Nenhum registro encontrado</td>
          </tr>
          <tr v-else class="group-row">
            <sticky-cell class="text-left pr-0">
              <div class="m-checkbox-inline">
                <label
                  class="m-checkbox m-checkbox--success p-0"
                  style="margin-bottom: 0px; color: white"
                >
                  <input
                    type="checkbox"
                    :checked="checkedAll"
                    @change="checkAll"
                  />
                  <span
                    style="
                      height: 15px;
                      width: 15px;
                      background-color: white;
                      position: relative;
                      display: flex;
                    "
                  ></span>
                </label>
              </div>
            </sticky-cell>
            <sticky-cell>Emissão</sticky-cell>
            <sticky-cell>Vencimento</sticky-cell>
            <sticky-cell class="text-left">Título</sticky-cell>
            <sticky-cell class="text-left">{{
              type === "payment" ? "Razão Social" : "Razão Social Cliente"
            }}</sticky-cell>
            <sticky-cell class="text-center">Valor</sticky-cell>
            <sticky-cell class="text-center">Saldo</sticky-cell>
            <sticky-cell class="text-center" v-if="type !== 'payment'"
              >Desconto</sticky-cell
            >
            <sticky-cell class="text-center" v-if="type !== 'payment'"
              >Juros</sticky-cell
            >
            <sticky-cell
              class="text-center"
              v-if="type !== 'payment' && hasOtherCurrency"
              >Taxa Contratada(%)</sticky-cell
            >
            <sticky-cell class="text-center" v-if="type !== 'payment'"
              >Valor Recebido</sticky-cell
            >
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <search-income-record-list-table-line
          :items="items"
          :type="type"
          :selectedItems="selectedItems"
          :checkItem="checkItem"
          :hasOtherCurrency="hasOtherCurrency"
          :search="formattedSeachString()"
        />
      </template>
    </v-data-table>
    <template>
      <v-container>
        <v-row class="mb-6" no-gutters>
          <v-col sm="5" md="6" v-if="!type || type !== 'payment'">
            <label>Motivo da Baixa:</label>
            <div class="form-group">
              <select
                class="form-control select2 hide"
                field-name="acquittanceType"
                id="acquittanceType"
                name="acquittanceType"
                style="width: 70%"
                @input="
                  $emit('input', {
                    key: 'acquittanceType',
                    value: $event.target.value,
                  })
                "
              >
                <option value="">Selecione...</option>
                <option
                  v-for="acquittanceReason in acquittanceReasons"
                  :value="acquittanceReason.erpCode"
                  :key="acquittanceReason.id"
                >
                  {{ acquittanceReason.description }}
                </option>
              </select>
            </div>
          </v-col>

          <v-col sm="5" md="6" offset-md="0" v-if="!type || type !== 'payment'">
            <label>Histórico:</label>
            <div class="form-group">
              <textarea
                class="form-control select2 hide"
                id="writeOffReason"
                name="writeOffReason"
                style="width: 50%"
                :value="writeOffReason"
                maxlength="40"
                @input="
                  $emit('input', {
                    key: 'writeOffReason',
                    value: $event.target.value,
                  })
                "
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-container>
</template>

<script>
import SearchIncomeRecordListTableLine from "./ListTableLine.vue";
import DateSelect from "../../../../src/components/fields/DateSelect.vue";

export default {
  name: "SearchIncomeRecordListTable",
  components: {
    SearchIncomeRecordListTableLine,
    DateSelect,
  },
  props: {
    items: Array,
    selectedItems: Array,
    record: Object,
    total: Number | String,
    writeOffReason: String,
    acquittanceType: String,
    companyId: String,
    type: { type: String, default: "" },
  },
  mounted() {
    this.$emitter.on("income_record_search", () => {
      jQuery("#acquittanceType").val(null).trigger("change");
    });

    this.dueDate = moment(this.record.values[0].data_vencto).format(
      "DD/MM/YYYY"
    );
  },
  data() {
    return {
      headers: [
        {
          value: "item",
          filterable: false,
        },
        {
          value: "e1_emissao",
          filterable: true,
        },
        {
          value: "e1_vencrea",
          filterable: true,
        },
        {
          value: "e1_num",
          filterable: true,
        },
        {
          value: "a1_nome",
          filterable: true,
        },
        {
          value: "e1_valor",
          filterable: true,
        },
        {
          value: "e1_saldo",
          filterable: true,
        },
        {
          value: "e1_decresc",
          filterable: false,
        },
        {
          value: "e1_acresc",
          filterable: false,
        },
        {
          value: "received_value",
          filterable: false,
        },
        {
          value: "e1_txmoeda",
          filterable: false,
        },
      ],
      search: "",
      acquittanceReasons: [],
      acquittanceReason: null,
      dueDate: null,
      checkedAll: false,
      selectableItems: this.items.reduce((prev, item) => {
        return prev + (item.e1_saldo > 0 ? 1 : 0);
      }, 0),
    };
  },
  computed: {
    isDarkMode() {
      this.loadAcquittances();
      return this.$vuetify.theme.dark;
    },
    totalSelectedItemsValue() {
      return this.selectedItems.reduce((prev, item) => {
        if (typeof item.received_value === "object") {
          return prev + numeral(item.received_value).value();
        }
        return prev + parseFloat(item.received_value);
      }, 0);
    },
    hasOtherCurrency() {
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (element.e1_moeda != "01") {
          return true;
        }
      }
      return false;
    },
    calculatedDifference() {
      let result =
        Number.parseFloat(this.total) -
        Number.parseFloat(this.totalSelectedItemsValue);
      return Number.parseFloat(result.toFixed(2));
    },
    itemsToNumber() {
      const formattedItems = this.items;
      if (formattedItems.length > 0) {
        formattedItems.forEach((item) => {
          if (typeof item === "object") {
            if (item.e1_decresc && typeof item.e1_decresc === "object") {
              item.e1_decresc = parseFloat(numeral(item.e1_decresc).value());
            }

            if (item.e1_acresc && typeof item.e1_acresc === "object") {
              item.e1_acresc = parseFloat(numeral(item.e1_acresc).value());
            }

            if (item.e1_txmoeda && typeof item.e1_txmoeda === "object") {
              item.e1_txmoeda = parseFloat(numeral(item.e1_txmoeda).value());
            }

            if (
              item.received_value &&
              typeof item.received_value === "object"
            ) {
              item.received_value = parseFloat(
                numeral(item.received_value).value()
              ).toFixed(2);
            }
          }
        });
      }
      return formattedItems;
    },
  },
  methods: {
    loadAcquittances: async function () {
      let companyGroup = this.$session.get("company_group_id");
      await this.$http
        .get(
          this.$store.state.API_BASE +
            "acquittancereason/getOptions/" +
            companyGroup +
            "/" +
            this.companyId,
          { headers: { token: this.$session.get("token") } }
        )
        .then(
          (result) => {
            this.acquittanceReasons =
              result.body.data && result.body && result.body.data
                ? result.body.data
                : [];
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    checkAll() {
      if (this.selectedItems.length > 0 && this.checkedAll) {
        this.$emit("input", { key: "selectedItems", value: [] });
        this.checkedAll = false;
        return false;
      }

      const newSelectedItems = [];
      this.items.forEach((item) => {
        if (item.e1_saldo > 0) {
          newSelectedItems.push(item);
        }
      });
      this.$emit("input", { key: "selectedItems", value: newSelectedItems });
      this.checkedAll = true;
      return true;
    },
    checkItem(item) {
      const index = this.selectedItems.findIndex(
        (element) => element.id === item.id
      );

      if (index > -1) {
        this.selectedItems.splice(index, 1);
        this.checkedAll = false;
        this.$emit("input", {
          key: "selectedItems",
          value: this.selectedItems,
        });
        return;
      }

      this.selectedItems.push(item);
      if (this.selectedItems.length == this.selectableItems) {
        this.checkedAll = true;
      }
      this.$emit("input", { key: "selectedItems", value: this.selectedItems });
    },
    uncheckItem(item) {
      const index = this.selectedItems.findIndex(
        (element) => element.id === item.id
      );

      if (index > -1) {
        this.selectedItems.splice(index, 1);
        this.$emit("input", {
          key: "selectedItems",
          value: this.selectedItems,
        });
        this.checkedAll = false;
        return;
      }
    },
    onSuccess() {
      this.selectedItems = [];
      this.$emit("success");
    },
    formattedSeachString() {
      return this.search.replace(".", "").replace(",", ".");
    },
  },
};
</script>

<style scoped>
.v-data-table__wrapper {
  overflow: unset;
}
.theme--light.v-data-table {
  color: #575962 !important;
}
.v-data-table th {
  padding: 0 0.3rem !important;
  font-size: 1rem !important;
  font-weight: 500;
}
.v-data-table td {
  font-size: 1rem !important;
  text-align: center;
}
.v-data-table tr.group-row,
.v-data-table tr.group-row label,
.v-data-table tr.group-row:hover {
  background: #f2f2f2 !important;
  font-weight: 600;
}
.v-data-table tr.group-row .v-simple-checkbox i {
  background: #fff;
  background-clip: content-box;
  padding: 5px;
}
.selectedItemsCard {
  border: 1px solid #ebedf2;
  box-shadow: none !important;
}
.selectedItemsCard span {
  color: #575962;
  background: #ebedf2;
  border: 1px solid #ebedf2;
  padding: 0.05rem 0.4rem 0.05rem 0.4rem;
  font-size: 1rem;
  margin: 0.1rem 0.4rem 0.1rem 0;
  border-radius: 4px;
}
.selectedItemsCard .remove {
  font-size: 1.1rem;
  margin-top: -3px;
  cursor: pointer;
}
.income-record-table .header-column {
  padding: 0.65rem 0.2rem;
}
.income-record-table .text-center .header-column {
  justify-content: center;
}
</style>

<style>
.income-record-table .header-column:first-of-type {
  padding-left: 0.2rem !important;
}
</style>
