






































import Component, { mixins } from 'vue-class-component';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import CompanyItem from './CompanyItem.vue';
import ICompanyActivePair from '../types/ICompanyActivePair';

@Component({
  components: {
    CompanyItem,
  },
})
export default class CompaniesTab extends mixins<ModelManagementMixin<ICompanyActivePair[]>>(
  ModelManagementMixin,
) {
  public considerAllCompanies: boolean = false;

  public get getTextTitleColor() {
    if (this.$vuetify.theme.dark) {
      return 'white--text';
    }

    return 'grey--text text--darken-4';
  }

  public handleConsiderAllCompanies(isConsider: boolean): void {
    for (let i = 0; i < this.model.length; i += 1) {
      const actualModel = this.model[i];

      actualModel.active = isConsider;
    }
  }

  public handleUpdateActive(companyId: number, newActive: boolean): void {
    this.model.forEach((pair) => {
      if (pair.company.id == companyId) {
        pair.active = newActive;
      }
    });
  }
}
