import Repository from '@/repositories/base/Repository';
import InvalidResponseException from '@/repositories/exceptions/InvalidResponseException';
import IGenerateBankSlipsByAccountsReceivableAndSendMailParams from '@/repositories/parameters/IGenerateBankSlipsByAccountsReceivableAndSendMailParams';
import IDownloadAccountReceivableBankSlipParams from '@/repositories/parameters/IDownloadAccountReceivableBankSlipParams';

export default class AccountReceivableBankSlipRepository extends Repository {
  public async downloadAccountReceivableBankSlip(
    companyGroupId: number,
    companyId: number,
    params: IDownloadAccountReceivableBankSlipParams,
  ): Promise<Blob> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/accounts-receivable/bank-slip/download/customer`, {
      params,
      headers: { 'company-id': JSON.stringify(companyId) },
      responseType: 'blob',
    });

    if (!(response instanceof Blob)) {
      throw new InvalidResponseException();
    }

    return response;
  }

  public async generateBilletsAndSendByMailBySpecificCustomer(
    companyGroupId: number,
    companyId: number,
    params: IGenerateBankSlipsByAccountsReceivableAndSendMailParams,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/accounts-receivable/bank-slip/send-mail/customer`, params, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });
  }
}
