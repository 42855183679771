<template>
  <div>
    <table class="table table-striped m-table">
      <thead>
        <tr>
          <th>Status</th>
          <th style="width: 50%">Descrição</th>
          <th style="vertical-align: middle; text-align: center;">Tipo</th>
          <th style="vertical-align: middle; text-align: center;">Ação</th>
          <th style="vertical-align: middle; text-align: center;">Código do banco</th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="item in listData" :key="item.id">
        <td style="vertical-align: middle">
          <v-chip
            v-if="item.status !== 'T'"
            dense
            small>
            Inativo
          </v-chip>
          <v-chip
            v-else
            dense
            small
            color="green"
            text-color="white">
            Ativo
          </v-chip>
        </td>
        <td style="vertical-align: middle">
          {{ item.description }}
        </td>
        <td style="vertical-align: middle; text-align: center;">
          {{ item.type }}
        </td>
        <td style="vertical-align: middle; text-align: center;">
          {{ item.action }}
        </td>
        <td style="vertical-align: middle; text-align: center;">
          {{ item.bank_code }}
        </td>
        <td class="m--align-right">
          <button-tooltip
            fab
            icon
            small
            buttonIcon="mdi-pencil"
            tooltipText="Editar"
            @click="edit(item)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: "OccurrencesTable",
  props: {
    occurrences: { type: Array, require: true },
    filter: { type: Object, require: true },
  },
  data: () => ({
    items: [],
  }),
  computed: {
    listData() {
      const filterTerm = this.filter.term.toLowerCase();
      const status = this.filter.status;

      let dataRet = this.items;

      if (!_.isEmpty(filterTerm)) {
        dataRet = _.filter(
          dataRet,
          (list) => list.description.toLowerCase().indexOf(filterTerm) >= 0 || list.type.toLowerCase().indexOf(filterTerm) >= 0
        );
      }

      if (status && status.length > 0) {
        dataRet = dataRet.filter((item) => item.status === status);
      }
      return dataRet;
    },
  
  },
  watch: {
    occurrences(items) {
      this.items = items;
    },
  },
  methods: {
    edit(item) {
			const params = {
				id: item.id
			};
			this.$router.push({name: 'EditOccurrences',params});
		},
  }
};
</script>
