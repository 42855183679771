import IRoutineLockParams from '@/repositories/parameters/IRoutineLockParams';
import Repository from '@/repositories/base/Repository';
import RoutineLock from '@/domain/models/RoutineLock';
import RoutineLockEnum from '@/domain/enums/RoutineLockEnum';

export default class RoutineLockRepository extends Repository {
  public async verifyIfRoutineLockExists(params: IRoutineLockParams) : Promise<RoutineLock> {
    const { data: response } = await this.datasource.get('/routine-lock', { params });

    return RoutineLock.make(response.data);
  }

  public async setRoutineLockedIfExists(params: IRoutineLockParams) : Promise<RoutineLock> {
    const { data: response } = await this.datasource.send('/routine-lock', params);

    return RoutineLock.make(response.data);
  }

  public async unlockRoutine(routineName: RoutineLockEnum) : Promise<void> {
    await this.datasource.destroy(`/routine-lock/${routineName}`);
  }
}
