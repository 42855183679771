































































































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { toCurrency } from '@/utils';
import TableUtils from '@/utils/tableUtils';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';
import DataTableHelper from '@/helpers/DataTableHelper';
import DateInputHelper from '@/helpers/DateInputHelper';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import ClientRepository from '@/repositories/ClientRepository';
import ClientModule from '@/stores/modules/ClientModule';
import ClientSaleOrders from '@/domain/models/ClientSaleOrders';
import FilterParameterCustomerSellsInOrderScreen from '@/domain/models/filter-parameters/FilterParameterCustomerSellsInOrderScreen';
import OnRequestTableOptions from '@/types/OnRequestTableOptions';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import VMenuOptions from '@/types/VMenuOptions';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import GetClientSalesOrderFilterParams from '@/views/clients/types/GetClientSalesOrderFilterParams';
import RangeDate from '@/types/RangeDate';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import LooseObjectType from '@/types/LooseObjectType';

@Component({
  components: {
    DynamicColumnMenu,
    ButtonFilterMenuOptions,
  },
})
export default class OrderCustomerSells extends Vue {
  public loading: boolean = false;

  public search: string = '';
  public consideredColumnToSearch: string = '';

  public totalItems: number = 0;

  private readonly clientModule: ClientModule = getModule(ClientModule);
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();

  public dataTableOptions: DataOptions = DataTableHelper.getDefaultDataTableOptions();
  public footerOptions: DataTableFooterOptions = DataTableHelper.getDefaultFooterProps();

  public formattedNfEmissionRangeDate: Partial<RangeDate> = {};
  public formattedInclusionRangeDate: Partial<RangeDate> = {};

    public headerKeysObject: LooseObjectType<string> = {
    invoiceSerie: 'data_table_sale_orders_order_screen_show_column_invoice_serie',
    invoiceEmission: 'data_table_sale_orders_order_screen_show_column_issue_date',
    order: 'data_table_sale_orders_order_screen_show_column_order',
    orderInclusionDate: 'data_table_sale_orders_order_screen_show_column_order_inclusion_date',
    channel: 'data_table_sale_orders_order_screen_show_column_channel',
    value: 'data_table_sale_orders_order_screen_show_column_value',
    status: 'data_table_sale_orders_order_screen_show_column_status',
  }

  public defaultButtonFilterMenuOption: VMenuOptions | null = null;

  public nfEmissionRangeDate: string[] = [];
  public saleInclusionDateRange: string[] = [];

  public saleOrders: ClientSaleOrders[] = [];
  public availableHeaders: IVDataTableHeader[] = [
    {
      text: 'Nota Fiscal / Série',
      value: 'invoiceSerie',
      show: true,
      filterable: false,
    },
    {
      text: 'Emissão NF',
      value: 'invoiceEmission',
      show: true,
      filterable: false,
    },
    {
      text: 'Pedido',
      value: 'order',
      show: true,
      filterable: false,
    },
    {
      text: 'Inclusão Pedido',
      value: 'orderInclusionDate',
      show: true,
      filterable: false,
    },
    {
      text: 'Canal de Vendas',
      value: 'channel',
      show: true,
      filterable: false,
    },
    {
      text: 'Valor Total',
      value: 'value',
      show: true,
      filterable: false,
    },
    {
      text: 'Status',
      value: 'status',
      show: true,
      filterable: false,
    },
  ];

  public get filtersFromInput(): VMenuOptions[] {
    let availableHeaders = [...this.availableHeaders];

    availableHeaders = availableHeaders.slice(1);
    availableHeaders.unshift({ text: 'Nota Fiscal', value: 'invoice' });

    const totalIndex = availableHeaders.findIndex(({ value }) => value === 'value');
    availableHeaders.splice(totalIndex, 1);

    return availableHeaders.map(({ text, value }) => ({ text, value }));
  }

  public get headers(): IVDataTableHeader[] {
    return this.availableHeaders
      .filter((h) => h.show)
      .concat({
        text: '',
        value: 'actions',
        class: 'actions-columns',
        cellClass: 'actions-columns',
        sortable: false,
        filterable: false,
      });
  }

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    this.getCustomerSells({ resetToFirst: false });
  }

  async created() {
    await this.getGroupFilterParameters();
    await this.getCustomerSells({ resetToFirst: false });
  }

  public async getGroupFilterParameters(): Promise<void> {
    this.loading = true;

    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.SALES_ORDER_ORDER_LIST);
      const formattedFilters = FilterParameterCustomerSellsInOrderScreen.make(filterParameters);

      this.applyFiltersOnActualPage(formattedFilters);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.loading = false;
    }
  }

  public applyFiltersOnActualPage(filters: FilterParameterCustomerSellsInOrderScreen): void {
    this.dataTableOptions.sortBy = filters.tableSort;
    this.dataTableOptions.sortDesc = filters.tableDirection;
    this.dataTableOptions.itemsPerPage = filters.rowsPerPage;
    this.dataTableOptions.page = filters.actualPage;

    this.nfEmissionRangeDate = filters.nfEmissionRangeDate;
    this.saleInclusionDateRange = filters.saleInclusionDateRange;

    const formattedNfEmissionRangeDate = DateInputHelper
      .formatRangeDate(filters.nfEmissionRangeDate);

    if (formattedNfEmissionRangeDate) {
      this.formattedNfEmissionRangeDate = formattedNfEmissionRangeDate;
    }

    const formattedInclusionRangeDate = DateInputHelper
      .formatRangeDate(filters.saleInclusionDateRange);

    if (formattedInclusionRangeDate) {
      this.formattedInclusionRangeDate = formattedInclusionRangeDate;
    }

    this.defaultButtonFilterMenuOption = FilterParameterHelper
      .getFormattedColumnObjectOfOptionsButton(this.filtersFromInput, filters.columnToSearch);

    this.consideredColumnToSearch = filters.columnToSearch;

    this.availableHeaders = FilterParameterHelper
      .defineColumnsToShowOnSpecificHeader(this.availableHeaders, filters.columnsToShow);
  }

  public async getCustomerSells(onRequestTableOptions: OnRequestTableOptions = {}): Promise<void> {
    if (this.loading) {
      return;
    }

    if (
      this.clientModule.client.companyId === null
      || this.clientModule.client.companyId === undefined
    ) {
      this.$notification.warn('O id da empresa desse cliente não foi definido!');

      return;
    }

    if (onRequestTableOptions.resetToFirst) {
      this.dataTableOptions.page = 1;
    }

    this.loading = true;

    const { sortDesc } = this.dataTableOptions;
    const sortBy = this.dataTableOptions.sortBy[0] === 'invoiceSerie' ? ['invoice'] : this.dataTableOptions.sortBy;

    const tableConfigParams: GetClientSalesOrderFilterParams = {
      search: this.search,
      columnToSearch: this.consideredColumnToSearch,
      initialNfEmissionDate: this.formattedNfEmissionRangeDate.initialDate,
      finalNfEmissionDate: this.formattedNfEmissionRangeDate.finalDate,
      initialSaleInclusionDate: this.formattedInclusionRangeDate.initialDate,
      finalSaleInclusionDate: this.formattedInclusionRangeDate.finalDate,
      sort: TableUtils.tableOrdenationFormatterToLaravel(sortDesc[0], sortBy[0]),
      actualPage: this.dataTableOptions.page,
      rowsPerPage: this.dataTableOptions.itemsPerPage,
    };

    try {
      this.filterParametersRepository
        .setFilter(GroupFilterParametersEnum.SALES_ORDER_ORDER_LIST, [
          { key: 'sort_table_sale_orders_order_screen', value: tableConfigParams.sort },
          { key: 'rows_per_page_sale_orders_order_screen', value: this.dataTableOptions.itemsPerPage },
          { key: 'actual_page_sale_orders_order_screen', value: this.dataTableOptions.page },
          { key: 'nf_emission_range_date_search_sale_orders_order_screen', value: JSON.stringify(this.nfEmissionRangeDate) },
          { key: 'inclusion_range_date_search_sale_orders_order_screen', value: JSON.stringify(this.saleInclusionDateRange) },
          { key: 'column_to_search_sale_orders_order_screen', value: this.consideredColumnToSearch },
        ]);

      const { data, total } = await new ClientRepository()
        .getClientSells(
          this.$route.params.idCustomer,
          this.authenticationModule.user.company_group_id,
          parseInt(this.$route.params.companyId, 10),
          tableConfigParams,
        );

      this.saleOrders = data;
      this.totalItems = total;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as vendas!');
    } finally {
      this.loading = false;
    }
  }

  public getMoneyFormat(num: number): string {
    return (num > 0 ? toCurrency(num) : '-');
  }

  public handleSearch(search: string): void {
    this.search = search;

    this.getCustomerSells({ resetToFirst: true });
  }

  public async handleSelectDateRange(dateField: 'nfEmissionRangeDate' | 'saleInclusionDateRange'): Promise<void> {
    await this.$nextTick();

    const correlationBetweenDateAndFormattedFieldDate: {
      nfEmissionRangeDate: 'formattedNfEmissionRangeDate',
      saleInclusionDateRange: 'formattedInclusionRangeDate',
    } = {
      nfEmissionRangeDate: 'formattedNfEmissionRangeDate',
      saleInclusionDateRange: 'formattedInclusionRangeDate',
    };

    const selectedDate = this[dateField];
    const formattedRangeDate = DateInputHelper.formatRangeDate(selectedDate);

    if (formattedRangeDate === null) {
      this.$notification.warn('O range de data está inválido!');

      return;
    }

    this[correlationBetweenDateAndFormattedFieldDate[dateField]] = formattedRangeDate;
    this.getCustomerSells();
  }

  public handleSelectedOptionChange(selectedOption: VMenuOptions | null): void {
    let valueToConsideredColumn = '';

    if (selectedOption !== null) {
      valueToConsideredColumn = selectedOption.value;
    }

    this.consideredColumnToSearch = valueToConsideredColumn;

    if (!this.search) {
      return;
    }

    this.getCustomerSells({
      resetToFirst: true,
    });
  }
}
