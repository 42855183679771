<template>
  <v-btn
    :icon="false"
    :fab="false"
    :color="color"
    v-bind="$attrs"
    v-on="$listeners"
    class="text-uppercase"
  >
    <slot></slot>
    <v-icon v-if="appendIcon" right dark>{{ appendIcon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'InnButton',
  props: {
    color: String,
    appendIcon: String,
  },
  computed: {
    isIconButton() {
      return this.fab || this.icon;
    },
  },
};
</script>

<style></style>
