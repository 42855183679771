












































import {
  Component,
  Emit,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import BillingSignatureGroupsRepository from '@/repositories/BillingSignatureGroupsRepository';
import CompanySubscribersList from '@/domain/models/CompanySubscribersList';

@Component
export default class CompanySubscribersDeleteDialog
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  @Prop({
    type: Object as () => CompanySubscribersList,
    required: true,
    default: {} as CompanySubscribersList,
  }) companySubscribers!: CompanySubscribersList;

  @Emit('close')
  close(reload: boolean = false) {
    this.model = false;
    this.loading = false;

    return reload;
  }

  public readonly billingSignatureGroupsRepository
    : BillingSignatureGroupsRepository = new BillingSignatureGroupsRepository();

  public loading: boolean = false;

  public get name(): string {
    return this.companySubscribers.name;
  }

  public get maxWidth(): string {
    if (this.$vuetify.breakpoint.xs) return '100%';

    if (this.$vuetify.breakpoint.smAndDown) return '80%';

    if (this.$vuetify.breakpoint.mdAndDown) return '60%';

    return '40%';
  }

  public save(): void {
    this.deleteCompanySubscribers(this.companySubscribers.id);
  }

  public async deleteCompanySubscribers(id: number): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      await this.billingSignatureGroupsRepository
        .destroyCompanySubscribers(group, id);

      this.$notification
        .success('Assinaturas da empresa excluidas com sucesso!');
      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
