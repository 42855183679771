<template>
	<v-select
		label="Serviço"
		placeholder="Selecione o serviço da API"
		v-model="model"
		:items="items"
		:loading="loading"
		:readonly="disabled"
		:disabled="!api"
		item-text="service"
		item-value="id"
		:rules="rules"
		@change="onChange"
	/>
</template>

<script>
import ApiBankingService from '@/services/apibanking.service';

export default {
	name: 'ApiBankingServiceSelect',
	//  two-way data-binding
	props: {
		value: [Object, String, Number],
		api: [Object, String, Number],
		disabled: Boolean,
		rules: [Array, Object],
	},
	data() {
		return {
			loading: false,
			model: this.value,
			items: [],
		};
	},
	watch: {
		model() {
			this.$emit('input', this.model);
		},
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
		api() {
			this.loadApiBankingServices();
		},
	},
	created() {
		this.loadApiBankingServices();
	},
	methods: {
		onChange() {
			this.$emit('change', this.model);
		},
		async loadApiBankingServices() {
			try {
				if (!this.api) {
					return;
				}

				this.loading = true;

				const apiBankingService = new ApiBankingService();

				const response = await apiBankingService.service(this.api).index();

				this.items = response.data;
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style>

</style>
