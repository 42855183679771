<template>
  <div>
    <div id="params" class="m-portlet m-portlet--mobile">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <h3 id="teste" class="m-portlet__head-text">Parâmetros</h3>
          </div>
        </div>
        <div class="m-portlet__head-tools">
          <ul class="m-portlet__nav">
            <li class="m-portlet__nav-item">
              <button
                type="button"
                class="btn m-btn--air btn-success elevation-0"
                @click="prepareGenerateCashFlowRealized()"
              >
                Gerar fluxo
              </button>
            </li>
          </ul>
        </div>
        <div class="d-table-cell pa-4 icon-cell">
          <v-btn
            icon
            class="d-flex ml-auto mt-1"
            depressed
            @click="toggleParams()"
          >
            <v-icon
              x-large
              class="text--disabled chevron"
              :class="!paramsVisibility ? 'toggle-chevron' : ''"
            >
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </div>
      </div>

      <v-slide-y-reverse-transition>
        <div class="m-portlet__body" v-show="paramsVisibility">
          <!--begin: Search Form -->
          <div
            class="
              m-form m-form--label-align-right
              m--margin-top-20 m--margin-bottom-30
            "
          >
            <div class="row align-items-center">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Data inicial</label>
                  <div class="input-group date" id="dateInitial">
                    <input
                      type="text"
                      class="form-control m-input text-center"
                      readonly=""
                      placeholder="Selecione a data"
                    />
                    <span class="input-group-addon">
                      <i class="la la-calendar-check-o"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Data final</label>
                  <div class="input-group date" id="dateFinish">
                    <input
                      type="text"
                      class="form-control m-input text-center"
                      readonly=""
                      placeholder="Selecione a data"
                    />
                    <span class="input-group-addon">
                      <i class="la la-calendar-check-o"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Tipo relatório</label>
                  <select
                    class="form-control select2 hide"
                    field-name="Tipo relatório"
                    id="report_type"
                    name="report_type"
                    style="width: 100%"
                  >
                    <option value="A">Analítico</option>
                    <option value="S">Sintético</option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Empresa</label>
                  <select
                    class="form-control select2Company hide"
                    field-name="Empresa"
                    id="company_id"
                    name="company_id[]"
                    style="width: 100%"
                    multiple="multiple"
                  >
                    <option
                      v-for="company in companys"
                      :key="company.id"
                      :value="company.id"
                    >
                      {{ company.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Banco</label>
                  <select
                    class="form-control select2Bank hide"
                    field-name="Banco"
                    id="bank_id"
                    name="bank_id[]"
                    style="width: 100%"
                    multiple="multiple"
                  >
                    <option
                      v-for="bank in banks"
                      :key="bank.bank_key_id"
                      :value="bank.a6_cod + bank.a6_agencia + bank.a6_conta"
                    >
                      {{
                        "(" +
                        bank.a6_cod +
                        "-" +
                        bank.a6_agencia +
                        "-" +
                        bank.a6_conta +
                        ") " +
                        bank.a6_nome
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Periodicidade</label>
                  <select
                    class="form-control select2 hide"
                    field-name="Periodicidade"
                    id="periodicity"
                    name="periodicity"
                    style="width: 100%"
                  >
                    <option value="daily">Diário</option>
                    <!-- <option value="weekly">Semanal</option> - Retirado dia 13/05/2019 - Saionara -->
                    <option value="monthly">Mensal</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Mostrar totalizador</label>
                  <select
                    class="form-control select2 hide"
                    style="width: 100%"
                    v-model="showTotalizer"
                  >
                    <option value="S">Sim</option>
                    <option value="N">Não</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!--end: Search Form -->
        </div>
      </v-slide-y-reverse-transition>
    </div>

    <div
      id="billing"
      class="m-portlet m-portlet--mobile"
      v-if="viewBillingOnRealized && isSearchComplete"
    >
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <h3 class="m-portlet__head-text">Faturamento</h3>
          </div>
        </div>
        <div class="m-portlet__head-tools">
          <ul class="m-portlet__nav">
            <li class="m-portlet__nav-item" aria-expanded="true">
              <button
                type="button"
                class="
                  btn btn-secondary
                  m-btn
                  m-btn--hover-brand
                  m-btn--icon
                  m-btn--icon-only
                  m-btn--pill
                "
                style="width: 44px; height: 44px"
                data-toggle="m-tooltip"
                data-placement="bottom"
                data-original-title=" Imprimir o fluxo "
                @click="openModalPrint()"
              >
                <i class="la la-print" style="font-size: 25px"></i>
              </button>
              <print-modal
                ref="printModal"
                :active-dates="allDatesPrint"
                @close="showModalPrint = false"
                v-if="showModalPrint"
                tittle-modal-info="Impressão"
              >
                <div slot="footer">
                  <button
                    type="button"
                    class="btn m-btn--air btn-info"
                    @click="
                      $refs.printModal.print(
                        cashFlowData,
                        cashFlowBillingData,
                        selfComponent
                      )
                    "
                  >
                    Imprimir
                  </button>
                  <button
                    type="button"
                    class="btn m-btn--air btn-primary"
                    @click="$refs.printModal.hide()"
                  >
                    Fechar
                  </button>
                </div>
              </print-modal>
            </li>
          </ul>
        </div>
      </div>

      <div class="m-portlet__body">
        <div class="row header-inncash">
          <div
            class="row sub-header-inncash w-100"
            :style="periodicityParamSearch != 'weekly' ? '' : ''"
          >
            <div class="col-sm-2" style="padding-right: 0; padding-left: 0">
              &nbsp;
            </div>
            <div
              class="col-sm-1 text-center"
              style="padding-right: 0; padding-left: 0; padding-top: 0"
            >
              <button
                type="button"
                class="btn m-btn--pill btn-secondary btn-sm"
                @click="previousDate"
                v-if="cashFlowHiddenDates.length > 0"
              >
                <i class="la la-angle-left" style="padding-top: 5px"></i>
              </button>
            </div>
            <div
              class="col-sm-8 mainInfos"
              style="padding-right: 0; padding-left: 0"
            >
              <div
                class="row rangeDates"
                style="padding-right: 0; padding-left: 0"
              >
                <div class="col-sm-1" style="padding-right: 0; padding-left: 0">
                  &nbsp;
                </div>

                <div
                  class="col-sm-2 dates containerDate"
                  :date-value="date.dateHidden"
                  :data-date="date.dateHidden"
                  :data-date-br="date.date"
                  style="padding-right: 0; padding-left: 30px"
                  v-for="date in cashFlowActiveDates"
                  :key="date.date"
                >
                  <span v-if="periodicityParamSearch != 'weekly'">{{
                    date.date
                  }}</span>
                  <br v-if="periodicityParamSearch != 'weekly'" />
                  <span v-html="date.description"></span>
                </div>
                <div
                  class="col-sm-2 dates containerDate"
                  :date-value="date.dateHidden"
                  :data-date="date.dateHidden"
                  :data-date-br="date.date"
                  style="padding-right: 0; padding-left: 30px; display: none"
                  v-for="date in cashFlowHiddenDates"
                  :key="date.date"
                >
                  <span v-if="periodicityParamSearch != 'weekly'">{{
                    date.date
                  }}</span>
                  <br v-if="periodicityParamSearch != 'weekly'" />
                  <span v-html="date.description"></span>
                </div>

                <div class="col-sm-1" style="padding-right: 0; padding-left: 0">
                  &nbsp;
                </div>
              </div>
            </div>
            <div
              class="col-sm-1 text-center"
              style="padding-right: 0; padding-left: 0; padding-top: 0"
            >
              <button
                type="button"
                class="btn m-btn--pill btn-secondary btn-sm"
                @click="nextDate"
                v-if="cashFlowHiddenDates.length > 0"
              >
                <i class="la la-angle-right" style="padding-top: 5px"></i>
              </button>
            </div>
          </div>
        </div>

        <collapse-div-main
          name="col_1"
          :type-level="'levelMain'"
          :data-rows="levelMain"
          :span-description="levelMain.description"
          :cash-flow-hidden-dates="cashFlowHiddenDates"
          v-for="(levelMain, indexLevelMain) in cashFlowBillingData"
          :child-name="'col_' + levelMain.type + indexLevelMain"
          :type="levelMain.type"
          :key="levelMain.type"
        >
          <collapse-div-one
            name="col_1"
            :type-level="'levelOne'"
            :data-rows="levelOne.level_one"
            :span-description="levelOne.level_one.description"
            :cash-flow-hidden-dates="cashFlowHiddenDates"
            v-for="(levelOne, indexLevelOne) in levelMain.data"
            :child-name="'col_' + levelMain.type + indexLevelOne"
            :slot="'col_' + levelMain.type + indexLevelMain"
            :key="levelOne.id"
          >
            <collapse-div-two
              name="col_2"
              :slot="'col_' + levelMain.type + indexLevelOne"
              :type-level="'levelTwo'"
              :data-rows="levelTwo"
              :cash-flow-hidden-dates="cashFlowHiddenDates"
              :span-description="levelTwo.description"
              :child-name="
                'col_' + levelMain.type + indexLevelOne + indexLevelTwo
              "
              v-for="(levelTwo, indexLevelTwo) in levelOne.level_two"
              :key="levelTwo.id"
            >
              <collapse-div-three
                name="col_2"
                :slot="'col_' + levelMain.type + indexLevelOne + indexLevelTwo"
                :type-level="'levelThree'"
                :data-rows="levelThree"
                :cash-flow-hidden-dates="cashFlowHiddenDates"
                :span-description="levelThree.description"
                :identify-values="levelThree.identifyValues"
                :child-name="
                  'col_' +
                  levelMain.type +
                  indexLevelOne +
                  indexLevelTwo +
                  indexLevelThree
                "
                v-for="(levelThree, indexLevelThree) in levelTwo.level_three"
                :key="levelThree.id"
              >
                <collapse-div-four
                  name="col_2"
                  :slot="
                    'col_' +
                    levelMain.type +
                    indexLevelOne +
                    indexLevelTwo +
                    indexLevelThree
                  "
                  :type-level="'levelFour'"
                  :data-rows="levelFour"
                  :cash-flow-hidden-dates="cashFlowHiddenDates"
                  :span-description="levelFour.description"
                  :identify-value="levelFour.identifyValues"
                  v-for="levelFour in levelThree.level_four"
                  :key="levelFour.id"
                >
                </collapse-div-four>
              </collapse-div-three>
            </collapse-div-two>
          </collapse-div-one>
        </collapse-div-main>
      </div>
    </div>

    <div id="fluxo" class="m-portlet m-portlet--mobile" v-if="isSearchComplete">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <h3 class="m-portlet__head-text">Fluxo de Caixa Realizado</h3>
          </div>
        </div>
        <div class="m-portlet__head-tools" v-if="!viewBillingOnRealized">
          <ul class="m-portlet__nav">
            <li class="m-portlet__nav-item" aria-expanded="true">
              <button
                type="button"
                class="
                  btn btn-secondary
                  m-btn
                  m-btn--hover-brand
                  m-btn--icon
                  m-btn--icon-only
                  m-btn--pill
                "
                style="width: 44px; height: 44px"
                data-toggle="m-tooltip"
                data-placement="bottom"
                data-original-title=" Gerar XLS (Excel) do Fluxo "
                @click="generateXlsFile"
              >
                <i class="la la-file-excel-o" style="font-size: 25px"></i>
              </button>
            </li>
            <li class="m-portlet__nav-item" aria-expanded="true">
              <button
                type="button"
                class="
                  btn btn-secondary
                  m-btn
                  m-btn--hover-brand
                  m-btn--icon
                  m-btn--icon-only
                  m-btn--pill
                "
                style="width: 44px; height: 44px"
                data-toggle="m-tooltip"
                data-placement="bottom"
                data-original-title=" Imprimir o fluxo "
                @click="openModalPrint()"
              >
                <i class="la la-print" style="font-size: 25px"></i>
              </button>
              <print-modal
                ref="printModal"
                :active-dates="allDatesPrint"
                @close="showModalPrint = false"
                v-if="showModalPrint"
                tittle-modal-info="Impressão"
              >
                <div slot="footer">
                  <button
                    type="button"
                    class="btn m-btn--air btn-info"
                    @click="
                      $refs.printModal.print(
                        cashFlowData,
                        cashFlowBillingData,
                        selfComponent
                      )
                    "
                  >
                    Imprimir
                  </button>
                  <button
                    type="button"
                    class="btn m-btn--air btn-primary"
                    @click="$refs.printModal.hide()"
                  >
                    Fechar
                  </button>
                </div>
              </print-modal>
            </li>
          </ul>
        </div>
      </div>
      <div class="m-portlet__body" id="fluxo-body">
        <div class="row header-inncash" v-if="!viewBillingOnRealized">
          <div class="row sub-header-inncash w-100 m-0">
            <div class="col-sm-2" style="padding-right: 0; padding-left: 0">
              &nbsp;
            </div>
            <div
              class="col-sm-1 text-center"
              style="padding-right: 0; padding-left: 0; padding-top: 0"
            >
              <button
                type="button"
                class="btn m-btn--pill btn-secondary btn-sm"
                @click="previousDate"
                v-if="cashFlowHiddenDates.length > 0"
              >
                <i class="la la-angle-left" style="padding-top: 5px"></i>
              </button>
            </div>
            <div
              class="col-sm-8 mainInfos"
              style="padding-right: 0; padding-left: 0"
            >
              <div
                class="row rangeDates"
                style="padding-right: 0; padding-left: 0"
              >
                <div
                  v-if="showTotalizer === 'S'"
                  class="col-sm-1 containerDate dates"
                  style="padding-right: 0; padding-left: 0"
                >
                  Total
                </div>
                <div class="col-sm-1" style="padding-right: 0; padding-left: 0">
                  &nbsp;
                </div>

                <div
                  class="col-sm-2 dates containerDate"
                  :date-value="date.dateHidden"
                  :data-date="date.dateHidden"
                  :data-date-br="date.date"
                  style="padding-right: 0; padding-left: 30px"
                  v-for="date in cashFlowActiveDates"
                  :key="date.date"
                >
                  <span v-if="periodicityParamSearch != 'weekly'">{{
                    date.date
                  }}</span>
                  <br v-if="periodicityParamSearch != 'weekly'" />
                  <span v-html="date.description"></span>
                </div>
                <div
                  class="col-sm-2 dates containerDate"
                  :date-value="date.dateHidden"
                  :data-date="date.dateHidden"
                  :data-date-br="date.date"
                  style="padding-right: 0; padding-left: 30px; display: none"
                  v-for="date in cashFlowHiddenDates"
                  :key="date.date"
                >
                  <span v-if="periodicityParamSearch != 'weekly'">{{
                    date.date
                  }}</span>
                  <br v-if="periodicityParamSearch != 'weekly'" />
                  <span v-html="date.description"></span>
                </div>

                <div class="col-sm-1" style="padding-right: 0; padding-left: 0">
                  &nbsp;
                </div>
              </div>
            </div>
            <div
              class="col-sm-1 text-center"
              style="padding-right: 0; padding-left: 0; padding-top: 0"
            >
              <button
                type="button"
                class="btn m-btn--pill btn-secondary btn-sm"
                @click="nextDate"
                v-if="cashFlowHiddenDates.length > 0"
              >
                <i class="la la-angle-right" style="padding-top: 5px"></i>
              </button>
            </div>
          </div>
        </div>

        <collapse-div-main
          name="col_1"
          :type-level="'levelMain'"
          :data-rows="levelMain"
          :span-description="levelMain.description"
          :cash-flow-hidden-dates="cashFlowHiddenDates"
          v-for="(levelMain, indexLevelMain) in cashFlowData"
          :child-name="'col_' + levelMain.type + indexLevelMain"
          :type="levelMain.type"
          :key="levelMain.type"
          :showTotalizer="showTotalizer"
          :showDefault="reportType === 'A'"
          v-on:openLevelOne="openLevelOne($event, levelMain)"
        >
          <collapse-div-one
            name="col_1"
            :type-level="'levelOne'"
            :data-rows="levelOne.level_one"
            :span-description="levelOne.level_one.description"
            :cash-flow-hidden-dates="cashFlowHiddenDates"
            v-for="(levelOne, indexLevelOne) in levelMain.data"
            :child-name="'col_' + levelMain.type + indexLevelOne"
            :slot="'col_' + levelMain.type + indexLevelMain"
            :key="levelOne.id"
            :showTotalizer="showTotalizer"
            :showDefault="reportType === 'A'"
            v-on:openLevelTwo="openLevelTwo($event, levelOne)"
          >
            <collapse-div-two
              name="col_2"
              :slot="'col_' + levelMain.type + indexLevelOne"
              :type-level="'levelTwo'"
              :data-rows="levelTwo"
              :cash-flow-hidden-dates="cashFlowHiddenDates"
              :span-description="levelTwo.description"
              :child-name="
                'col_' + levelMain.type + indexLevelOne + indexLevelTwo
              "
              v-for="(levelTwo, indexLevelTwo) in levelOne.level_two"
              :key="levelTwo.id"
              :showTotalizer="showTotalizer"
              :showDefault="reportType === 'A'"
              v-on:openLevelThree="openLevelThree($event, levelTwo)"
            >
              <collapse-div-three
                name="col_2"
                :slot="'col_' + levelMain.type + indexLevelOne + indexLevelTwo"
                :type-level="'levelThree'"
                :data-rows="levelThree"
                :cash-flow-hidden-dates="cashFlowHiddenDates"
                :span-description="levelThree.description"
                :identify-values="levelThree.identifyValues"
                :showTotalizer="showTotalizer"
                :showDefault="reportType === 'A'"
                :child-name="
                  'col_' +
                  levelMain.type +
                  indexLevelOne +
                  indexLevelTwo +
                  indexLevelThree
                "
                v-for="(levelThree, indexLevelThree) in levelTwo.level_three"
                :key="levelThree.id"
                v-on:openLevelFour="openLevelFour($event, levelThree)"
              >
                <collapse-div-four
                  name="col_2"
                  :slot="
                    'col_' +
                    levelMain.type +
                    indexLevelOne +
                    indexLevelTwo +
                    indexLevelThree
                  "
                  :type-level="'levelFour'"
                  :data-rows="levelFour"
                  :cash-flow-hidden-dates="cashFlowHiddenDates"
                  :span-description="levelFour.description"
                  :showTotalizer="showTotalizer"
                  :showDefault="reportType === 'A'"
                  :identify-value="levelFour.identifyValues"
                  v-for="levelFour in levelThree.level_four"
                  :key="levelFour.id"
                  v-if="showLevelFour"
                >
                </collapse-div-four>
              </collapse-div-three>
            </collapse-div-two>
          </collapse-div-one>
        </collapse-div-main>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import { API_BASE } from "@config/api";
import CollapseDivOne from "./CollapseDivOne.vue";
import CollapseDivTwo from "./CollapseDivTwo.vue";
import CollapseDivThree from "./CollapseDivThree.vue";
import CollapseDivFour from "./CollapseDivFour.vue";
import CollapseDivMain from "./CollapseDivMain.vue";
import PrintModal from "./PrintModal.vue";
import GenerateXlsTemplateCashFlowRealized from "./generate-xls-template-cash-flow-realized";

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.00");
});

export default {
  components: {
    PrintModal,
    CollapseDivThree,
    CollapseDivOne,
    CollapseDivTwo,
    CollapseDivFour,
    CollapseDivMain,
  },
  data() {
    return {
      paramsVisibility: true,
      showLevelOne: false,
      showLevelTwo: false,
      showLevelThree: false,
      showLevelFour: false,
      showTotalizer: "N",
      cashFlowActiveDates: [],
      cashFlowHiddenDates: [],
      cashFlowAllDates: [],
      cashFlowData: [],
      cashFlowBillingData: [],
      cashFlowAccumulatedData: {},
      cashFlowEntranceData: {},
      cashFlowExitsData: {},
      companys: [],
      banks: [],
      companysSelected: [],
      reportType: "",
      nivel: "",
      collapseControl: {
        main: true,
        levelOne: true,
        leveTwo: true,
        levelThree: true,
      },
      allDatesPrint: [],
      showModalPrint: false,
      selfComponent: this,
      isSearchComplete: false,
      periodicityParamSearch: "",
      viewBillingOnRealized: false,
    };
  },

  directives: {},

  mounted: function () {
    ajustaHeaderFluxoPrevistoRealizado(this.paramsVisibility);

    numeral.language("pt-br");
    this.initPage();

    this.viewBillingOnRealized =
      this.$session.get("user_access-view_billing_on_realized") == "1"
        ? true
        : false;
  },

  methods: {
    toggleParams() {
      this.paramsVisibility = !this.paramsVisibility;
    },
    hiddenParams() {
      this.paramsVisibility = false;
    },
    toggle(divToggle) {
      let self = this;
      self.collapseControl[divToggle] = !self.collapseControl[divToggle];
    },
    initPage() {
      let self = this;
      $(".select2Company").select2({
        placeholder: "Selecione as empresas ou deixe em branco para todas",
      });

      $(".select2Bank").select2({
        placeholder: "Selecione os bancos ou deixe em branco para todas",
      });

      $("#dateInitial, #dateFinish").datepicker({
        autoclose: true,
        format: "dd/mm/yyyy",
        todayHighlight: !0,
        orientation: "bottom left",
        templates: {
          leftArrow: '<i class="la la-angle-left"></i>',
          rightArrow: '<i class="la la-angle-right"></i>',
        },
      });

      $("#report_type").change(function () {
        let reportTypeVal = $("#report_type").val();
        self.reportType = reportTypeVal;
      });

      $("#report_type").trigger("change");

      $("#company_id").change(() => {
        self.companysSelected = $(this).val();
        this.loadBanks();
      });

      this.loadParameter(false);
      this.loadCompanys(false);
      this.loadBanks(false);
    },
    loadCompanys(init = false) {
      let self = this;
      blockPage("Carregando os dados...");

      self.$http
        .get(API_BASE + "company/listAllActv", {
          headers: { token: self.$session.get("token") },
        })
        .then(
          (result) => {
            this.companys = result?.body?.data ?? [];
            unblockPage();
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    loadBanks(init = false) {
      console.log("ae");
      let params = {
        companys: JSON.stringify(this.companysSelected),
      };

      let headers = {
        token: this.$session.get("token"),
      };

      blockPage("Carregando os dados...");

      this.$http
        .get(API_BASE + "bank/listAllByGroup", {
          headers: headers,
          params: params,
        })
        .then(
          (result) => {
            if (result) {
              this.banks = result?.body?.data ?? [];
            }
            unblockPage();
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    loadParameter() {
      let self = this;

      self.$http
        .get(API_BASE + "fieldparameter/getDataDashboard", {
          headers: {
            token: self.$session.get("token"),
          },
        })
        .then(
          function (result) {
            $("#dateInitial > input").val(result.body.data.date_initial);
            $("#dateInitial > input").datepicker({
              autoclose: true,
              format: "dd/mm/yyyy",
              todayHighlight: !0,
              orientation: "bottom left",
              defaultDate: result.body.data.date_initial,
              templates: {
                leftArrow: '<i class="la la-angle-left"></i>',
                rightArrow: '<i class="la la-angle-right"></i>',
              },
            });
            $("#dateFinish > input").val(result.body.data.date_finish);
            $("#dateFinish > input").datepicker({
              autoclose: true,
              format: "dd/mm/yyyy",
              todayHighlight: !0,
              orientation: "bottom left",
              defaultDate: result.body.data.date_finish,
              templates: {
                leftArrow: '<i class="la la-angle-left"></i>',
                rightArrow: '<i class="la la-angle-right"></i>',
              },
            });

            $("#report_type").val(result.body.data.report_type);
            $("#report_type").trigger("change");

            $("#company_id").val(result.body.data.company_id);
            $("#company_id").trigger("change");

            setTimeout(() => {
              $("#bank_id").val(result.body.data.bank_id);
              $("#bank_id").trigger("change");
            }, 500);
          },
          function (error) {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    verifyShowDate(dateParam) {
      let self = this;
      let lShow = true;
      for (let i = 0; i < self.cashFlowHiddenDates.length; i++) {
        if (self.cashFlowHiddenDates[i].dateHidden == dateParam) {
          lShow = false;
          break;
        }
      }

      return lShow;
    },
    prepareGenerateCashFlowRealized() {
      this.hiddenParams();

      blockPage("Gerando Fluxo Realizado...");
      let self = this;
      let companiesId = self.companysSelected;
      checkImportBeforeSearch(self, companiesId).then(
        function (result) {
          if (!result.body.payload) {
            self.generateCashFlowRealized();
          } else {
            showNotification(
              "Atenção",
              "Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.",
              "warning"
            );
            unblockPage();
          }
        },
        function (error) {
          showNotification(
            "Atenção",
            "Opa! Parece que algo saiu errado, tente recarregar a página",
            "danger"
          );
          unblockPage();
        }
      );
    },
    generateCashFlowRealized() {
      let self = this;
      let companys = "ALL";
      let report_type = $("#report_type").val();
      let nivel = "4";

      if ($("#company_id").val().length > 0) {
        companys = $("#company_id").val();
      }

      self.isSearchComplete = false;
      self.cashFlowActiveDates = [];
      self.cashFlowHiddenDates = [];
      self.cashFlowAllDates = [];
      self.cashFlowData = [];
      self.cashFlowBillingData = [];

      let headers = {
        token: self.$session.get("token"),
      };

      let params = {
        dateInitial: $("#dateInitial > input").val(),
        dateFinish: $("#dateFinish > input").val(),
        company_id: companys,
        nivel: nivel,
        report_type: report_type,
        periodicity: $("#periodicity").val(),
        banks: JSON.stringify($("#bank_id").val()),
        viewBillingOnRealized: this.viewBillingOnRealized,
      };

      self.$http
        .get(API_BASE + "loadinncashrealized", {
          headers: headers,
          params: params,
        })
        .then(
          function (result) {
            self.periodicityParamSearch = $("#periodicity").val();
            if (
              (self.cashFlowActiveDates =
                result.body.payload.cashflowRealized.dates)
            ) {
              self.cashFlowActiveDates =
                result.body.payload.cashflowRealized.dates.dates.activeDates;
              self.cashFlowHiddenDates =
                result.body.payload.cashflowRealized.dates.dates.hiddenDates;
              self.cashFlowAllDates =
                result.body.payload.cashflowRealized.dates.dates.allDates;
              self.cashFlowData = result.body.payload.cashflowRealized.data;
              self.cashFlowBillingData =
                result.body.payload.cashflowBilling.data;
            } else {
              showNotification("Sem dados", "Dados não encontrados.", "info");
            }
            self.isSearchComplete = true;

            unblockPage();
          },
          function (error) {
            unblockPage();
            showNotification(
              "Atenção",
              "Oops! Ocorreu um erro ao gerar os dados.",
              "danger"
            );
            console.log(error);
          }
        );
    },
    nextDate() {
      let self = this;
      let dateRemoveActive, dateRemoveHidden, indexActive, indexHidden;

      if (
        self.cashFlowHiddenDates.length > 0 &&
        self.cashFlowActiveDates.length > 0
      ) {
        dateRemoveActive = self.cashFlowActiveDates[0];
        indexActive = self.cashFlowActiveDates.indexOf(dateRemoveActive);
        dateRemoveHidden = self.cashFlowHiddenDates[0];
        indexHidden = self.cashFlowHiddenDates.indexOf(dateRemoveHidden);

        //Só permite mudar o range se a ultima data ativa for menor que a proxima data escondida.
        if (
          self.cashFlowActiveDates[self.cashFlowActiveDates.length - 1]
            .dateHidden < dateRemoveHidden.dateHidden
        ) {
          self.cashFlowActiveDates.splice(indexActive, 1);
          self.cashFlowHiddenDates.splice(indexHidden, 1);
          self.cashFlowHiddenDates.push(Object.assign({}, dateRemoveActive));
          self.cashFlowActiveDates.push(Object.assign({}, dateRemoveHidden));
        }
      }
    },
    previousDate() {
      let self = this;
      let dateRemoveActive, dateRemoveHidden, indexActive, indexHidden;

      if (
        self.cashFlowHiddenDates.length > 0 &&
        self.cashFlowActiveDates.length > 0
      ) {
        dateRemoveActive =
          self.cashFlowActiveDates[self.cashFlowActiveDates.length - 1];
        indexActive = self.cashFlowActiveDates.indexOf(dateRemoveActive);
        dateRemoveHidden =
          self.cashFlowHiddenDates[self.cashFlowHiddenDates.length - 1];
        indexHidden = self.cashFlowHiddenDates.indexOf(dateRemoveHidden);

        //Só permite mudar o range se a ultima data ativa for maior que a proxima data escondida.
        if (
          self.cashFlowActiveDates[0].dateHidden > dateRemoveHidden.dateHidden
        ) {
          self.cashFlowActiveDates.splice(indexActive, 1);
          self.cashFlowHiddenDates.splice(indexHidden, 1);
          self.cashFlowHiddenDates.unshift(Object.assign({}, dateRemoveActive));
          self.cashFlowActiveDates.unshift(Object.assign({}, dateRemoveHidden));
        }
      }
    },
    generateXlsFile() {
      GenerateXlsTemplateCashFlowRealized.create(
        this.cashFlowAllDates,
        this.cashFlowData,
        this.periodicityParamSearch,
        this.showTotalizer
      );
    },
    openModalPrint() {
      let self = this;
      self.allDatesPrint = self.cashFlowActiveDates;
      self.showModalPrint = true;
    },
    openLevelOne(show, data) {
      this.showLevelOne = show;

      for (const main of data.data) {
        main.level_one.render = show;
      }
    },
    openLevelTwo(show, data) {
      this.showLevelTwo = show;

      for (const main of data.level_two) {
        main.render = show;
      }
    },
    openLevelThree(show, data) {
      this.showLevelThree = show;
    },
    openLevelFour(show, data) {
      this.showLevelFour = show;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/cashflow";
</style>
