enum StatementConciliationActionType {
  CREATE = 'create',
  GENERATE_ADVANCE = 'generate-advance',
  SEARCH = 'search',
  WRITE_OFF = 'write-off',
  UNDO = 'undo',
  SHOW_ERP_ERRORS = 'show_erp_errors',
}

export default StatementConciliationActionType;
