export default class ActiveDirectoryCredentials {
  constructor(
    public companyGroupId: number,
    public clientId: string,
    public tenantId: string,
    public url: string,
    public sso: string,
  ) {}

  static make(data: any): ActiveDirectoryCredentials {
    return new ActiveDirectoryCredentials(
      data.company_group_id,
      data.client_id,
      data.tenant_id,
      data.url,
      data.sso,
    );
  }
}
