import { render, staticRenderFns } from "./generateRemittance.vue?vue&type=template&id=2cd6d955&scoped=true"
import script from "./generateRemittance.vue?vue&type=script&lang=ts"
export * from "./generateRemittance.vue?vue&type=script&lang=ts"
import style0 from "./generateRemittance.vue?vue&type=style&index=0&id=2cd6d955&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cd6d955",
  null
  
)

export default component.exports