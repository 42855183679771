<template>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                </div>
            </div>
            <div class="m-portlet__head-tools">
                <div class="btn-group m-btn-group--air" role="group" aria-label="...">
                    <button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
                </div>
            </div>
        </div>
        <div class="m-portlet__body">
            <!--begin: Search Form -->
            <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                <div class="col-md-12" v-if="userType == 'O'">
                    <div class="form-group">
                        <label>Grupo de empresa</label>
                        <select class="form-control select2 hide" field-name="Grupo de empresa"
                                id="company_group_id"
                                style="width: 100%;">
                            <option value="">Selecione...</option>
                            <option v-for="companyGroup in companyGroups" :value="companyGroup.id">
                                {{companyGroup.description}}
                            </option>
                        </select>
                    </div>
                </div>


                <ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link active"
                           data-toggle="tab" href="#tab1" role="tab"
                           aria-expanded="true">
                            Configurações da Empresa
                        </a>
                    </li>
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link"
                           data-toggle="tab" href="#tab2" role="tab"
                           aria-expanded="true">
                            Consultas de Importação
                        </a>
                    </li>

                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="tab1" role="tabpanel"><div class="tab-content" id="frmCompany">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Erp</label>
                                <select class="form-control select2 hide" field-name="Erp" id="erp_id" name="erp_id"
                                       required style="width: 100%;">
                                    <option value="">Selecione...</option>
                                    <option v-for="erp in erps" :value="erp.id">{{ erp.description }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Código da empresa no ERP</label>
                                <input type="text" class="form-control" field-name="Código da empresa no ERP"
                                      required id="erp_company_code" name="erp_company_code" required/>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Dias para importação</label>
                                <input type="text" class="form-control" field-name="Dias para importação"
                                      required id="import_days" name="import_days" required/>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Hora para importação</label>
                                <input type="text" class="form-control" field-name="Hora para importação"
                                      required id="import_time" name="import_time" required/>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>URL Dashboard Power BI</label>
                                <input type="text" class="form-control" field-name="URL Dashboard Power BI"
                                       id="url_powerbi_dashboard" name="url_powerbi_dashboard"/>
                            </div>
                        </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>URL Dashboard Power BI modo dark</label>
                            <input type="text" class="form-control" field-name="URL Dashboard Power BI"
                                    id="url_powerbi_dashboard_darkmode" name="url_powerbi_dashboard_darkmode"/>
                        </div>
                    </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>URL Importação Dados</label>
                                        <input type="text" class="form-control" field-name="URL Importação Dados"
                                               required
                                               id="host_address" name="host_address"/>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>URL WebService ERP</label>
                                        <input type="text" class="form-control" field-name="URL WebService ERP"
                                               id="erpws_address" name="erpws_address" autocomplete="off"/>
                                    </div>
                                </div>
								<div class="col-md-4">
                                    <div class="form-group">
                                        <label>URL WebService REST</label>
                                        <input type="text" class="form-control" field-name="URL WebService REST"
                                               id="erpws_address_rest" name="erpws_address_rest" autocomplete="off"/>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Saldo Dividido em Contas</label>
                                        <select class="form-control select2" field-name="Saldo Dividido em Contas" id="balance_divided" name="balance_divided"
                                            required style="width: 100%;">
                                            <option selected value="">Selecione</option>
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Autenticação no ERP</label>
                                        <select class="form-control select2 hide" field-name="Autenticação" id="ws_use_auth" name="ws_use_auth"
                                            required style="width: 100%;">
                                            <option selected value="0">Não</option>
                                            <option value="1">Sim</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group auth-data">
                                        <label>URL Autenticação</label>
                                        <input required type="text" class="form-control" field-name="URL Autenticação" id="ws_auth_url" name="ws_auth_url"/>
                                    </div>
                                </div>
                                <div class="col-md-4 auth-data">
                                    <div class="form-group">
                                        <label>Usuário de Autenticação</label>
                                       <input required type="text" class="form-control" field-name="Usuário de Autenticação" id="ws_auth_username" name="ws_auth_username"/>
                                    </div>
                                </div>
                                <div class="col-md-4 auth-data">
                                    <div class="form-group">
                                        <label>Senha de Autenticação</label>
                                        <input required type="password" class="form-control" field-name="Senha de Autenticação" id="ws_auth_password" name="ws_auth_password"/>
                                        <div style="margin-top: 5px" class="m-checkbox-inline">
											<label class="m-checkbox m-checkbox--success">
												<input id="showpassword"  type="checkbox">
												<span></span>Mostrar senha
											</label>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="tab-pane" id="tab2" role="tabpanel">

                        <form novalidate @submit.prevent="addQuery('formIncQuery')"
                              class="row" data-vv-scope="formNewPosition" autocomplete="off">

                            <div class="row">

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Query</label>
                                        <textarea v-model="query.query" type="text"
                                                  class="form-control"
                                                  data-vv-as="'Query'"
                                                  v-validate="'required'"
                                                  name="query"
                                        ></textarea>
                                        <label v-show="errors.has('formIncQuery.query')">{{
                                            errors.first('formIncQuery.query') }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Campos</label>
                                        <textarea v-model="query.fields" type="text"
                                                  class="form-control"
                                                  data-vv-as="'Campos'"
                                                  v-validate="'required'"
                                                  name="fields"
                                        ></textarea>
                                        <label v-show="errors.has('formIncQuery.fields')">{{
                                            errors.first('formIncQuery.fields') }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Agrupamento</label>
                                        <textarea v-model="query.groupby_fields" type="text"
                                                  class="form-control"
                                        ></textarea>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Tabela ERP</label>
                                        <input type="text" v-model="query.table_erp" maxlength="3"
                                               class="form-control"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Tabela Innova</label>
                                        <input type="text" v-model="query.table_innova"
                                               class="form-control"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-4" style="display: flex; align-items: center;">
                                    <button type="submit" class="m-btn btn btn-success">Adicionar
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div class="col-md-12">
                            <table class="table table-striped m-table">
                                <thead>
                                <tr>
                                    <th>Query</th>
                                    <th>Campos (@{FIELDS})</th>
                                    <th>Agrupamento (@{GROUPBY})</th>
                                    <th>Tabela ERP</th>
                                    <th>Tabela Innova</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(query, index) in filterQueries(queries)" :key="query.id">
                                    <td style="vertical-align: middle;"><span>{{ query.query ? query.query.substring(0,query.query.length > 20 ? 20 : query.query.length) : '' }}</span>
                                        <popper-innova box-size="600"
                                                       :description="query.query"></popper-innova>
                                    </td>
                                    <td style="vertical-align: middle;"><span :title="query.fields">{{ query.fields ? query.fields.substring(0,query.fields.length > 20 ? 20 : query.fields.length) : ''}}</span>
                                        <popper-innova box-size="300"
                                                       :description="query.fields"></popper-innova>
                                    </td>
                                    <td style="vertical-align: middle;"><span :title="query.groupby_fields">{{ query.groupby_fields ? query.groupby_fields.substring(0,query.groupby_fields.length > 20 ? 20 : query.groupby_fields.length) : ''}}</span>
                                        <popper-innova v-if="query.groupby_fields != ''" box-size="300"
                                                       :description="query.groupby_fields"></popper-innova>
                                    </td>
                                    <td style="vertical-align: middle;"><span>{{ query.table_erp}}</span></td>
                                    <td style="vertical-align: middle;"><span>{{ query.table_innova}}</span>
                                    </td>
                                    <td class="m--align-right">
                                        <a @click="editQuery(query)"
                                           class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill">
                                            <i class="la la-edit"></i>
                                        </a>
                                        <a @click="removeQuery(query)"
                                           class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill">
                                            <i class="la la-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PopperInnova from '../../components/Popper-Innova.vue';
    import { API_BASE } from '@config/api';

    export default {
        components: {
            'popper-innova': PopperInnova
        },
        data() {
            return {
                erps: [],
                companyGroups: [],
                userType: "",
                companyGroupId: "",
                queries: [],
                query: {},
            }
        },
        mounted: function () {

            var self = this;

            self.userType = self.$session.get('user_type');
            self.companyGroupId = self.$session.get('company_group_id');

            self.loadData();
            self.loadMethod();

            self.loadQueries(self.companyGroupId);

            setTimeout(function () {
                self.loadConfiguration();
            }, 1000);
        },

        methods: {
            showPassword() {
                
                let input = $("#ws_auth_password");
                let type = input.prop('type');

                if (type === "password") {
                    input.prop('type', 'text');
                } else {
                    input.prop('type', 'password');
                }
            },
            loadData: function () {
                var self = this;
                blockPage('Carregando os dados...');

                $.ajax({
                    method: "GET",
                    url: API_BASE + 'erp/listAll',
                    assync: true,
                    headers: {
                        'token': self.$session.get('token')
                    }
                }).done(function (result) {
                    self.erps = result.data
                    unblockPage();
                }).fail(function (error) {
                    console.log(error.responseText);
                    unblockPage();
                });

                if (self.userType == 'O') {
                    $.ajax({
                        method: "GET",
                        url: API_BASE + 'companygroup/listAll',
                        assync: true,
                        headers: {
                            'token': self.$session.get('token')
                        }
                    }).done(function (result) {
                        self.companyGroups = result.data;

                        $(".select2").select2();
                            $("#company_group_id").on('change', () => {
                            self.fillFields($('#company_group_id').val());
                            self.loadQueries($('#company_group_id').val()
                        );
}
                        )
                        unblockPage();
                    }).fail(function (error) {
                        console.log(error.responseText);
                        unblockPage();
                    });
                } else {


                    $(".select2").select2();
                }
            },

            loadConfiguration: function () {
                var self = this;

                if (self.userType != 'O') {
                     self.fillFields(self.companyGroupId);
                    }



            },

            loadMethod: function () {
                var self = this;

                $("#import_days").mask("000");
                $("#import_time").mask("00:00");
                $("#ws_use_auth").on("change", function() {
                    if ($(this).val() == '0') {
                        $(".auth-data").hide();
                        $("#ws_auth_url").val('');
                        $("#ws_auth_username").val('');
                        $("#ws_auth_password").val('');
                        $("#ws_auth_url").attr("required", false);
                        $("#ws_auth_username").attr("required", false);
                        $("#ws_auth_password").attr("required", false);
                    } else {
                        $(".auth-data").show();
                    }
                }).trigger("change");
                
                $("#showpassword").on('click', function(ev) {
                    self.showPassword();
                });

                $("#select2-ws_use_auth-container").click(function () {

                    if($(this).val() == 0) {
                        $("#ws_auth_url").attr("required", false);
                        $("#ws_auth_username").attr("required", false);
                        $("#ws_auth_password").attr("required", false);
                    } else {
                        $("#ws_auth_url").attr("required", true);
                        $("#ws_auth_username").attr("required", true);
                        $("#ws_auth_password").attr("required", true);
                    }
                })

                $("#btn-save").click(function () {
                    if($("#erpws_address_rest").val().length != 0){
                        var url = $("#erpws_address_rest").val();
                        var valid = /^(ftp|http|https):\/\/[^ "]+$/.test(url);
                            if(valid == false) {
                                showNotification("Atenção", "A URL preenchida no campo URL WebService REST não é válida!","warning");
                                return false;
                            }
                    }

                    var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';
                    var btn = $(this);

                    btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

                    $.each($('input, select', '#frmCompany'), function () {
                        var $this = $(this);
                    if ($("#" + this.id).attr("required")) {    if ($.trim(this.value) == '') {
                            exitButton = true;
                            fieldName = $this.attr('field-name');

                            $(".form-group:has(#" + this.id + ")").addClass('has-danger');
                            $("#" + this.id).addClass('input-error');
                            $("#" + this.id).css('border-color', 'red');
                            return false;
                        }

                        if (this.id == 'import_time') {
                            if ($this.val().length != 5) {
                                exitButton = true;
                                fieldName = $this.attr('field-name');

                                $(".form-group:has(#" + this.id + ")").addClass('has-danger');
                                $("#" + this.id).addClass('input-error');
                                $("#" + this.id).css('border-color', 'red');
                                return false;
                            } else {
                                var hour = $this.val().split(':')[0];
                                var minute = $this.val().split(':')[1];

                                if ((hour < 0 || hour > 23) || (minute < 0 || minute > 59)) {
                                    exitButton = true;
                                    fieldName = $this.attr('field-name');

                                    $(".form-group:has(#" + this.id + ")").addClass('has-danger');
                                    $("#" + this.id).addClass('input-error');
                                    $("#" + this.id).css('border-color', 'red');
                                    return false;
                                }
                            }
                        }}

                        exitButton = false;
                        arrayData[i] = {};
                        arrayData[i][this.id] = this.value;

                        i++;
                    });

                    if (exitButton) {
                        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                        showNotification('Atenção', 'O campo <strong>' + fieldName + '</strong> não foi informado, ou é invalido!', 'warning');
                        return false;
                    }

                    if (self.userType == 'O') {
                        arrayData[arrayData.length - 1]['company_group_id'] = $('#company_group_id').val();
                    } else {
                        arrayData[arrayData.length - 1]['company_group_id'] = self.companyGroupId;
                    }$.ajax({
                        method: "POST",
                        url: API_BASE + 'companygroup/configuration',
                        headers: {
                            'token': self.$session.get('token')
                        },
                        data: {
                            arrayData: JSON.stringify(arrayData),
                        queriesData:JSON.stringify(self.queries)}
                    }).done(function (result) {
                        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

                        if (result.error == false) {
                            showNotification('Sucesso', 'Dados registrados com sucesso!', 'success');
                        } else {
                            showNotification('Atenção', result.message, 'danger');
                            return false;
                        }
                    }).fail(function (error) {
                        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                        showNotification('Atenção', 'Ocorreu um erro ao tentar salvar as permissões.', 'danger');
                        console.log(error.responseText);
                    });
                });

                $('input').keyup(function () {
                    if ($("#" + this.id).hasClass('input-error')) {
                        $("#" + this.id).removeClass('input-error');
                        $("#" + this.id).css('border-color', '#ebedf2');
                        $(".form-group:has(#" + this.id + ")").removeClass('has-danger');
                    }
                });
            },
            fillFields(companyGroupId) {
                let self = this;
                blockPage("Buscando dados do grupo de empresa.");
                $.ajax({
                    method: "GET",
                    url: API_BASE + 'companygroup/configuration/list/' + companyGroupId,
                    headers: {
                        'token': self.$session.get('token')
                    }
                }).done(function (result) {
                    if (result.data && result.data[0]) {
                        $('#erp_id').val(result.data[0].erp_id).trigger('change');
                        $('#erp_company_code').val(result.data[0].erp_company_code);
                        $('#import_days').val(result.data[0].import_days);
                        $('#import_time').val(result.data[0].import_time);
                        $('#balance_divided').val(result.data[0].balance_divided).trigger('change');
                        $('#url_powerbi_dashboard').val(result.data[0].url_powerbi_dashboard);
                        $('#url_powerbi_dashboard_darkmode').val(result.data[0].url_powerbi_dashboard_darkmode);
                        $('#host_address').val(result.data[0].host_address);
						$('#erpws_address').val(result.data[0].erpws_address);
                        $('#erpws_address_rest').val(result.data[0].erpws_address_rest);

                        if (result.data[0].ws_use_auth && result.data[0].ws_use_auth !== "0") {
                            $('#ws_use_auth').val(result.data[0].ws_use_auth).trigger('change');
                            $('#ws_auth_url').val(result.data[0].ws_auth_url);
                            $('#ws_auth_username').val(result.data[0].ws_auth_username);
                            $('#ws_auth_password').val(result.data[0].ws_auth_password);
                        }
                    }
                    unblockPage();
                }).fail(function (error) {
                    console.log(error.responseText);
                    unblockPage();
                });
            },
            loadQueries(companyGroupId) {
                let self = this;
                $.ajax({
                    method: "GET",
                    url: API_BASE + 'companygroup/configuration/query/list/' + companyGroupId,
                    headers: {
                        'token': self.$session.get('token')
                    }
                }).done(function (result) {
                    if (result.data) {
                        self.queries = result.data;
                    }
                    unblockPage();
                }).fail(function (error) {
                    console.log(error.responseText);
                    unblockPage();
                });
            },
            filterQueries: function (queries) {
                return queries.filter(query => query.deleted == 0);
            },
            removeQuery(query) {
                let self = this;
                query.deleted = 1;
                self.queries.splice(self.queries.indexOf(query), 1, query);
                self.queries.filter(query => query.deleted == 0);
            },
            editQuery(query) {
                let self = this;
                self.query = query;
            },
            addQuery(formName) {
                let self = this;

                if (self.query && self.query.id) {
                    self.queries.splice(self.queries.indexOf(self.query), 1, self.query);
                } else {
                    self.query.deleted = 0;
                    self.queries.push(self.query);
                }

                self.query = {};
            }

        }
    }
</script>