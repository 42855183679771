



















































































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { formatErrorForNotification } from '@/utils/error';
import IOptions from '@/domain/interfaces/ISelectOptions';
import TradingPolicyRepository from '@/repositories/TradingPolicyRepository';
import TradingPolicy from '@/domain/models/TradingPolicy';
import OperatorDistributionRepository from '@/repositories/OperatorDistributionRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import { getModule } from 'vuex-module-decorators';
import TradingPolicyPeriodicitiesEnum from '@/views/tradings/enums/TradingPolicyPeriodicitiesEnum';

@Component
export default class TradingPolicyView extends Vue {
  @Ref('form') readonly form!: VForm;

  public readonly tradingPolicyRepository: TradingPolicyRepository =
    new TradingPolicyRepository();

  public readonly operatorDistributionRepository: OperatorDistributionRepository =
    new OperatorDistributionRepository();

  private readonly authenticationModule: AuthenticationModule =
    getModule(AuthenticationModule);
  public loading: boolean = false;
  public policy: TradingPolicy = {} as TradingPolicy;

  public operators: Array<IOptions<number>> = [];

  public periodicities: Array<IOptions<TradingPolicyPeriodicitiesEnum>> = [
    {
      text: 'Diária',
      value: TradingPolicyPeriodicitiesEnum.DAILY,
    },
    {
      text: 'Semanal',
      value: TradingPolicyPeriodicitiesEnum.WEEKLY,
    },
    {
      text: 'Quinzenal',
      value: TradingPolicyPeriodicitiesEnum.FORTNIGHTLY,
    },
    {
      text: 'Mensal',
      value: TradingPolicyPeriodicitiesEnum.MONTHLY,
    },
    {
      text: 'Personalizada',
      value: TradingPolicyPeriodicitiesEnum.CUSTOM,
    },
  ];

  public rule: Record<string, any> = {
    required: (v: string | number) => !!v || 'Campo obrigatório.',
    requiredNumber: (v: number) => v >= 0 || 'Valor deve ser positivo.',
  };

  public suffix: Record<string, boolean> = {
    maximumDiscount: false,
    minimumInterest: false,
    standardInterest: false,
    minimumDeadline: false,
    standardDeadline: false,
    maximumDeadline: false,
  };

  public get isCreate(): boolean {
    return this.$route.name === 'CreateTradingPolicy';
  }

  public get tradingPolicyId(): number {
    return +this.$route.params.id;
  }

  public get title(): string {
    return this.isCreate
      ? 'Nova Política de Negociação'
      : 'Editar Política de Negociação';
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public get companyGroupId(): number {
    return this.$session.get('company_group_id');
  }

  public mounted(): void {
    this.loadData();
  }

  public toggleSuffixOf(event: FocusEvent, field: string, value: any) {
    if (event.type === 'focus' && this.suffix[field] !== true) {
      this.suffix[field] = true;
    }

    if (event.type === 'blur' && (value === null || value === '')) {
      this.suffix[field] = false;
    }
  }

  public save(): Promise<void> | null {
    if (this.form.validate()) {
      return this.isCreate
        ? this.createTradingPolicy()
        : this.updateTradingPolicy();
    }

    return null;
  }

  public cancel(): void {
    this.form.resetValidation();
    this.$router.push({ name: 'TradingPolicyList' });
  }

  public async getTradingPolicy(id: number): Promise<void> {
    this.policy = await this.tradingPolicyRepository.getTradingPolicy(
      this.companyGroupId,
      id,
    );

    this.suffix = {
      maximumDiscount: this.policy.maximumDiscount > 0,
      minimumInterest: this.policy.minimumInterest > 0,
      standardInterest: this.policy.standardInterest > 0,
      minimumDeadline: this.policy.minimumDeadline > 0,
      standardDeadline: this.policy.standardDeadline > 0,
      maximumDeadline: this.policy.maximumDeadline > 0,
    };
  }

  public async getOperators(): Promise<void> {
    const operators = await this.operatorDistributionRepository.getBillingManagerUsers(
        this.companyGroupId,
      );

    this.operators = operators.map(({ name, id }) => ({
      text: name,
      value: id,
    }));
  }

  public async loadData(): Promise<void> {
    try {
      this.loading = true;

      if (!this.isCreate) await this.getTradingPolicy(this.tradingPolicyId);

      await this.getOperators();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      this.cancel();
    } finally {
      this.loading = false;
    }
  }

  public async createTradingPolicy(): Promise<void> {
    try {
      this.loading = true;

      const data = {
        user_id: this.policy.userId,
        minimum_discount: this.policy.maximumDiscount,
        minimum_interest: this.policy.minimumInterest,
        standard_interest: this.policy.standardDeadline,
        delay: this.policy.delay,
        maximum_discount: this.policy.maximumDiscount,
        standard_deadline: this.policy.standardDeadline,
        minimum_deadline: this.policy.minimumDeadline,
        maximum_deadline: this.policy.maximumDeadline,
        amount_installments: this.policy.amountInstallments,
        different_installments: this.policy.differentInstallments,
        periodicity_installments: this.policy.periodicityInstallments,
      };

      await this.tradingPolicyRepository.sendTradingPolicy(
        this.companyGroupId,
        data,
        this.companyIds,
      );

      this.$notification.success('Política de Negociação criada com sucesso!');
      this.$router.push({ name: 'TradingPolicyList' });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async updateTradingPolicy(): Promise<void> {
    try {
      this.loading = true;

      const data = {
        user_id: this.policy.userId,
        minimum_discount: this.policy.maximumDiscount,
        minimum_interest: this.policy.minimumInterest,
        standard_interest: this.policy.standardDeadline,
        delay: this.policy.delay,
        maximum_discount: this.policy.maximumDiscount,
        standard_deadline: this.policy.standardDeadline,
        minimum_deadline: this.policy.minimumDeadline,
        maximum_deadline: this.policy.maximumDeadline,
        amount_installments: this.policy.amountInstallments,
        different_installments: this.policy.differentInstallments,
        periodicity_installments: this.policy.periodicityInstallments,
      };

      await this.tradingPolicyRepository.updateTradingPolicy(
        this.companyGroupId,
        this.tradingPolicyId,
        data,
      );

      this.$notification.success(
        'Política de Negociação alterada com sucesso!',
      );

      this.$router.push({ name: 'TradingPolicyList' });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
