import StatusType from '@/domain/enums/StatusType';

export default class OperatorDistributionList {
  constructor(
    public id: number,
    public operatorName: string,
    public status: StatusType,
  ) {}

  static make(data: any): OperatorDistributionList {
    return new OperatorDistributionList(
      data.id,
      data.operator_name,
      data.status,
    );
  }
}
