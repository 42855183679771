




















import { Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import { Route } from 'vue-router';
import { bus } from '@/main';
import Menu from '@/domain/models/Menu';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import DrawerLink from './DrawerLink.vue';
import DrawerGroup from './DrawerGroup.vue';
import IMenuStatusPair from './IMenuStatusPair';

@Component({
  components: {
    DrawerLink,
    DrawerGroup,
  },
})
export default class DrawerList extends mixins<ModelManagementMixin<IMenuStatusPair[]>>(
  ModelManagementMixin,
) {
  activeMenu: string = '';

  mounted() {
    bus.$on('update_drawer', this.$forceUpdate);
  }

  isAGrouper(menu: Menu): boolean {
    return menu.children.length > 0;
  }

  handleUpdateActive(menuId: number, newActive: boolean) {
    this.model.forEach((menuPair) => {
      if (menuPair.menu.id == menuId) {
        menuPair.active = newActive;
      }
    });
  }

  @Watch('$route')
  onRouteChanged(newRoute: Route) {
    this.activeMenu = newRoute.name ?? '';
    this.$forceUpdate();
  }

  verifyIfIsActive(menu: Menu): boolean {
    return (
      menu.routeName == this.activeMenu || menu.children.some((m) => m.routeName == this.activeMenu)
    );
  }
}
