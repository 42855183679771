























































































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import Status from '@/domain/enums/PaymentRemittancesStatusType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentMethodType from '@/domain/enums/PaymentMethodType';
import { OpenActionData } from '../utils/interfaces';
import { isStatusToApproveOrReprove, isStatusToShowPaymentEditOnSentCondition, isStatusToGenerateRemittance } from '../utils';

@Component({})
export default class PaymentRemittancesListMenuRow extends Vue {
  @Prop({
    type: Object as () => PaymentRemittance,
  }) item!: PaymentRemittance;

  @Emit()
  action(type: ActionType): OpenActionData {
    return { type, items: [this.item] };
  }

  readonly Status = Status;
  readonly Action = ActionType;

  get hasPermissionToApproveOrReprove(): boolean {
    return Boolean(Number(this.$session.get('user_access-approve_or_reprove_remittance')));
  }

  get status(): Status {
    return this.item.status;
  }

  get isBarcode() {
    const type = this.item && this.item.form_of_payment_type;

    return type === PaymentMethodType.BANK_SLIP
      || type === PaymentMethodType.CONCESSIONAIRE
      || type === PaymentMethodType.TRIBUTE;
  }

  get isInvoice(): boolean {
    return this.item.type === 'FT';
  }

  get isInvoiceOfInncash(): boolean {
    return this.item.origin === 'inncash'
      && this.isInvoice;
  }

  get hasBordero(): boolean {
    return !!this.item.cnab_remi_generated_id;
  }

  get canShow(): boolean {
    return isStatusToShowPaymentEditOnSentCondition(this.status);
  }

  get canEdit(): boolean {
    return isStatusToGenerateRemittance(this.status);
  }

  get canComment(): boolean {
    return this.status === Status.NOT_SENT
      || this.status === Status.PENDING
      || this.status === Status.DISAPPROVED;
  }

  get canApproveOrReprove(): boolean {
    return this.hasPermissionToApproveOrReprove
      && isStatusToApproveOrReprove(this.status)
      && !this.item.user_interaction;
  }
}
