export default class FilterParameterOrderAnalisys {
    public constructor(
        public rangeDate: string[],
        public dateToSearch: string,
    ) {
    }

    public static make(data: any): FilterParameterOrderAnalisys {
      const rangeDate = data.date_range_order_analisys_list
      ? JSON.parse(
        data.date_range_order_analisys_list,
      ) : [];

      return new FilterParameterOrderAnalisys(
        rangeDate,
        data.date_to_search_order_analisys ?? 'date_emission',
      );
    }
}
