<template>
    <v-row>
      <v-col>
        <breadcrumbs page-title="Retorno Bancário"/>
        <v-card class="elevation-0 mb-5">
          <v-card-title>
            <v-col lg="6" md="6" sm="12" xs="12" >
              <cnab-return-select-client-folder v-model="directory" @change="setDirectory" />
            </v-col>
            <v-spacer/>
            <v-col lg="3" md="4" sm="12"  xs="12">
              <inn-btn
              @click="toFiles()"
              color="primary"
              block
              large
              depressed
              >
                ARQUIVOS ENVIADOS
              </inn-btn>
            </v-col>
          </v-card-title>
        </v-card>
        <vue-dropzone
          ref="myVueDropzone"
          id="VueDropZone"
          :options="dropzoneOptions"
          :use-custom-slot="true"
          @vdropzone-error="onError"
        >
          <div class="dropzone-custom-content">
            <img src="/assets/img/1031498-128.png" />
            <br />
            <br />
            <h3 class="dropzone-custom-title">Solte os arquivos aqui</h3>
            <div class="subtitle">...ou clique para selecionar um arquivo.</div>
          </div>
        </vue-dropzone>
      </v-col>
    </v-row>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import CnabFiles from "@/services/cnab_files.service";
import CnabReturnSelectClientFolder from "./CnabReturnSelectClientFolder.vue";
import PageContainer from "@/components/defaults/PageContainer.vue";
import Breadcrumbs from "@/components/defaults/Breadcrumbs.vue";

export default {
  name: "CnabReturnSendFile",
  components: {
    vueDropzone: vue2Dropzone,
    CnabReturnSelectClientFolder,
  },
  data() {
    return {
      dropzoneOptions: {
        url: () => `${this.$store.state.API_BASE}company-groups/${this.$session.get('company_group_id')}/bank-returns/files`,
        headers: { token: this.$session.get("token") },
        dictRemoveFile: "Remover",
        dictInvalidFileType: "Tipo do arquivo inválido!",
        autoProcessQueue: true,
        duplicateCheck: true,
        acceptedFiles: ".RET,.TXT",
        parallelUploads: 1,
        addRemoveLinks: true,
        params: {
          directory: this.directory,
        },
      },

      directory: "",
    };
  },

  methods: {
    onError: function (file, message, xhr) {
      if (!this.directory) {
        showNotification(
          "Atenção",
          "Por favor selecione o diretório!",
          "danger"
        );
        return;
      }

      showNotification(
        "Atenção!",
        "Houve um problema ao salvar o arquivo",
        "danger"
      );
    },
    onSuccess: async function (file, request, xhr) {
      try {
        if (!request.data.length) {
          showNotification(
            "Atenção!",
            "Arquivo já importado",
            "danger"
          );

          return;
        }

        const cnabFiles = new CnabFiles();

        await cnabFiles.read().store(request.data);

        showNotification(
          "Sucesso",
          "Arquivo importado com sucesso!",
          "success"
        );
      } catch (error) {
        showNotification(
            "Atenção!",
            "Houve um problema ao importar o arquivo, provavelmente faltam configurações para leitura!",
            "danger"
          );

        }
      },

    setDirectory(directory) {
      this.directory = directory;
      this.dropzoneOptions.params.directory = directory;
    },

    toFiles() {
      this.$router.push({ name: "CnabReturns" });
    },
  },
};
</script>
