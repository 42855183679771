import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"add_form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('page-container',{attrs:{"no-column":""}},[_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"label":"Campo na API","items":_vm.onlyOptionalFilters,"item-value":"id","item-text":"field_name","return-object":"","rules":[].concat( _vm.rules.required )},model:{value:(_vm.toAddFieldDetail.field),callback:function ($$v) {_vm.$set(_vm.toAddFieldDetail, "field", $$v)},expression:"toAddFieldDetail.field"}})],1),_c(VCol,{staticClass:"d-flex flex-column pl-12 ml-n4",attrs:{"cols":"1"}},[_c('span',{staticClass:"mx-auto mb-n3 text-caption"},[_vm._v(" Ativo ")]),_c('status-checkbox',{attrs:{"centralize":""},model:{value:(_vm.toAddFieldDetail.status),callback:function ($$v) {_vm.$set(_vm.toAddFieldDetail, "status", $$v)},expression:"toAddFieldDetail.status"}})],1),_c(VCol,{staticClass:"d-flex flex-column pl-12 mr-4",attrs:{"cols":"3"}},[_c('value-type-select',{attrs:{"centralize":""},model:{value:(_vm.toAddFieldDetail.value_type),callback:function ($$v) {_vm.$set(_vm.toAddFieldDetail, "value_type", $$v)},expression:"toAddFieldDetail.value_type"}})],1),_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"label":"Campo no Sistema","rules":[].concat( _vm.rules.required )},model:{value:(_vm.toAddFieldDetail.data_field),callback:function ($$v) {_vm.$set(_vm.toAddFieldDetail, "data_field", $$v)},expression:"toAddFieldDetail.data_field"}})],1),_c(VCol,{staticClass:"d-flex flex-column mr-n8",attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"my-4 flex-item",attrs:{"disabled":!_vm.valid,"icon":""},on:{"click":_vm.add}},[_c(VIcon,[_vm._v(" mdi-plus ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }