import Repository from '@/repositories/base/Repository';
import CustomerOrdersListTableParameters from '@/views/orders/types/CustomerOrdersListTableParameters';
import Orders from '@/domain/models/Orders';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';
import CreditAnalisysOrderValues from '@/domain/models/CreditAnalisysOrderValues';
import HistoricalBillingsOrder from '@/domain/models/HistoricalBillingsOrder';
import IUpdateOrderOnERPParams from '@/views/orders/interfaces/IUpdateOrderOnERPParams';

export default class OrderRepository extends Repository {
  public async listAll(
    companies: number[],
    companyGroupId: string,
    filters: CustomerOrdersListTableParameters,
  ): Promise<IDataTableWithLength<Orders[]>> {
    const { status, data: response } = await this.datasource.get(`company-groups/${companyGroupId}/orders`, {
      params: filters,
      headers: { 'company-ids': JSON.stringify(companies) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return {
      data: response.data.map(Orders.make),
      total: response.totalRows,
    };
  }

  public async getCreditAnalisysOrderFinancialValuesByOrderInnId(
    companyGroupId: string,
    companyId: number,
    orderInnId: string,
  ): Promise<CreditAnalisysOrderValues> {
    const { status, data: response } = await this.datasource.get(`company-groups/${companyGroupId}/orders/credit-analisys/financial/${orderInnId}`, {
      headers: { 'company-id': companyId },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return CreditAnalisysOrderValues.make(response.data);
  }

  public async getHistoricalBillingFromOrder(
    companyId: number,
    customerId: string,
    moduleId: string,
    dateRange: string[],
  ): Promise<HistoricalBillingsOrder> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/orders/credit-analisys/${customerId}/historical-billing`, {
      params: { dateRange },
      headers: { 'company-id': companyId },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return HistoricalBillingsOrder.make(response.data);
  }

  public async releaseOrderOnERP(
    moduleId: string,
    companiesId: number[],
    orderId: string,
    customerId: string,
  ): Promise<void> {
    const { status, data: response } = await this.datasource.send(`company-groups/${moduleId}/orders/release/${customerId}`, {
      orderId,
    }, {
      headers: { 'company-ids': JSON.stringify(companiesId) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }
  }

  public async updateOrder(
    companyGroupId: number,
    idCustomerOrder: string,
    companyId: number,
    body: IUpdateOrderOnERPParams,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroupId}/orders/credit-analisys/${idCustomerOrder}`, body, {
      headers: { 'company-id': companyId },
    });
  }
}
