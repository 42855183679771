














































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import NewsRepository from '@/repositories/NewsRepository';
import LoginNews from '@/domain/models/LoginNews';

@Component
export default class LoginNewsList extends Vue {
  newsRepository: NewsRepository = new NewsRepository();

  loading: boolean = false;

  onboarding: number = 0;

  allNewsGroups: Array<LoginNews[]> = [];

  created(): void {
    this.getNewsList();
  }

  async getNewsList(): Promise<void> {
    try {
      this.loading = true;

      const news = await this.newsRepository.getNewsForLoginScreen();

      const newsGroups: Array<LoginNews[]> = [];

      for (let i = 0; i < news.length; i += 3) {
        const grupo = news.slice(i, i + 3);
        newsGroups.push(grupo);
      }

      this.allNewsGroups = newsGroups;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as noticias.');
    } finally {
      this.loading = false;
    }
  }

  getClassBasedOnIndex(newsGroup: LoginNews[], index: number): string {
    let classes = 'login-singular-news';

    if (index < newsGroup.length - 1) {
      classes += ' mb-3';
    }

    return classes;
  }

  redirectTo(link: string): void {
    window.open(link);
  }
}
