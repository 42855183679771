


















































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import MainGrouperOptionsHelper from '@/views/cashFlowGroupers/mainGrouper/helpers/MainGrouperOptionsHelper';
import CashFlowGrouperRepository from '@/repositories/CashFlowGrouperRepository';
import CompanyRepository from '@/repositories/CompanyRepository';
import MainGroupersScreensShownEnum from '@/views/cashFlowGroupers/mainGrouper/enums/MainGroupersScreensShownEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import { VForm } from '@/types/VForm';

export default defineComponent({
  name: 'MainGrouperCashFlowUpsert',
  computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id,
    }),
    pageAction(): 'create' | 'update' {
      if (this.$route.params.id) {
        return 'update';
      }

      return 'create';
    },
    pageTitle(): string {
      if (this.pageAction === 'update') {
        return 'Atualizar Grupo Principal';
      }

      return 'Cadastrar Grupo Principal';
    },
    buttonTextBasedOnScreen(): string {
      if (this.pageAction === 'update') {
        return 'ATUALIZAR';
      }

      return 'CADASTRAR';
    },
  },
  data() {
    const inputRules: InputRulesHelper = new InputRulesHelper();
    const companyRepository: CompanyRepository = new CompanyRepository();
    const cashFlowGrouperRepository: CashFlowGrouperRepository = new CashFlowGrouperRepository();

    const isLoading: boolean = false;

    const name: string = '';
    const description: string = '';

    const company: number|null = null;
    const status: number = 1;

    const table: CashFlowTablesEnum|null = null;

    const screensToShow: MainGroupersScreensShownEnum[] = [];

    const companies: ISelectOptions<number>[] = [];

    const tableOptions:
      ISelectOptions<CashFlowTablesEnum>[] = MainGrouperOptionsHelper.getTableOptions();
    const screensOptions:
      ISelectOptions<MainGroupersScreensShownEnum>[] = MainGrouperOptionsHelper.getScreensOptions();
    const statusOptions:
      ISelectOptions<number>[] = MainGrouperOptionsHelper.getStatusOptions();

    return {
      inputRules,
      companyRepository,
      cashFlowGrouperRepository,
      isLoading,
      name,
      description,
      company: company as number|null,
      status,
      table: table as CashFlowTablesEnum|null,
      screensToShow,
      companies,
      tableOptions,
      screensOptions,
      statusOptions,
    };
  },
  async created(): Promise<void> {
    await this.loadCompanies();

    if (this.pageAction === 'update') {
      this.getMainGrouperCashFlowById();
    }
  },
  methods: {
    async loadCompanies(): Promise<void> {
      try {
        this.$dialog.startLoading();

        const companies = await this.companyRepository.getCompaniesByLoggedUser(
          this.companyGroupId,
        );

        this.companies = companies.map(({ name, id }) => ({
          text: name,
          value: id,
        }));
      } catch (error) {
        this.$notification.error('Houve um problema ao carregar as empresas');
        this.backToList();
      } finally {
        this.$dialog.stopLoading();
      }
    },
    async createMainGrouperCashFlow(): Promise<void> {
      try {
        this.isLoading = true;

        await this.cashFlowGrouperRepository.createMainGrouperList(
          this.companyGroupId,
          {
            name: this.name,
            description: this.description,
            status: this.status,
            screens_shown: this.screensToShow,
            table: this.table!,
            company_id: this.company ?? undefined,
          },
        );

        this.$notification.success('Grupo principal criado com sucesso!');
        this.backToList();
      } catch (error) {
        this.$notification.error('Houve um problema ao criar um Grupo');
      } finally {
        this.isLoading = false;
      }
    },
    async updateMainGrouperCashFlow(mainGrouperId: number): Promise<void> {
      try {
        this.isLoading = true;

        await this.cashFlowGrouperRepository.updateMainGrouperList(
          this.companyGroupId,
          mainGrouperId,
          {
            name: this.name,
            description: this.description,
            status: this.status,
            screens_shown: this.screensToShow,
            table: this.table!,
            company_id: this.company ?? undefined,
          },
        );

        this.$notification.success('Grupo principal atualizado com sucesso!');
        this.backToList();
      } catch (error) {
        this.$notification.error('Houve um problema ao criar um Grupo');
      } finally {
        this.isLoading = false;
      }
    },
    async getMainGrouperCashFlowById(): Promise<void> {
      try {
        this.$dialog.startLoading();

        const mainGrouper = await this.cashFlowGrouperRepository.getMainGrouperById(
          this.companyGroupId,
          Number(this.$route.params.id),
        );

        this.name = mainGrouper.name;
        this.description = mainGrouper.description;
        this.company = mainGrouper.companyId;
        this.status = mainGrouper.status;
        this.table = mainGrouper.table;
        this.screensToShow = mainGrouper.screensToShown;
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar o grupo!');
        this.backToList();
      } finally {
        this.$dialog.stopLoading();
      }
    },
    backToList(): void {
      this.$router.push({ name: 'MainGrouperCashFlowList' });
    },
    handleUpsertMainGrouper(): void {
      const upsertMainGrouperCashFlowForm = this.$refs.UpsertMainGrouperCashFlowForm as VForm;

      if (!upsertMainGrouperCashFlowForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');

        return;
      }

      if (this.pageAction == 'update') {
        this.updateMainGrouperCashFlow(Number(this.$route.params.id));
      } else {
        this.createMainGrouperCashFlow();
      }
    },
  },
});
