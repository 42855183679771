




































































































import {
  Vue,
  Ref,
  Component,
} from 'vue-property-decorator';
import InputRules from '@/helpers/InputRulesHelper';
import PasswordManagementRepository from '@/repositories/PasswordManagementRepository';
import { VForm } from '@/types/VForm';

@Component
export default class ForgotPassword extends Vue {
  @Ref('ForgotPasswordForm') readonly forgotPasswordForm!: VForm;

  passwordManagementRepository: PasswordManagementRepository = new PasswordManagementRepository();

  inputRules: InputRules = new InputRules();

  loading: boolean = false;
  hasSendWithSuccess: boolean = false;

  email: string = '';

  async sendMailToRedifinePassword(): Promise<void> {
    try {
      this.loading = true;

      await this.passwordManagementRepository.sendMailToResetPassword(this.email);

      this.hasSendWithSuccess = true;
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao gerar a redefinição de senha.');
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
    }
  }

  handleBackLogin(): void {
    this.$router.push({ name: 'login' });
  }

  handleRedefinePassword(): void {
    if (!this.forgotPasswordForm.validate()) {
      this.$notification.error('O campo de E-Mail está inválido.');

      return;
    }

    this.sendMailToRedifinePassword();
  }
}
