import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { pt },
		current: 'pt',
	},
	theme: {
		themes: {
			light: {
				primary: '#5867dd',
				secondary: '#757575',
				error: '#FF1744',
				success: '#34bfa3',
				accent: '#a29edc',
				green: '#34bfa3',
				orange: '#ffb822',
			},
			dark: {
				primary: '#1E88E5',
				secondary: '#424242',
				error: '#FF1744',
				success: '#34bfa3',
				accent: '#a29edc',
				green: '#34bfa3',
				orange: '#ffb822',
			},
		},
	},
});
