import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"absolute":"","bottom":"","left":"","close-on-content-click":false},on:{"input":_vm.handleMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isMobile)?_c(VBtn,_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('span',{staticClass:"mr-2"},[_vm._v("Colunas")]),_c(VIcon,[_vm._v("mdi-filter-variant")])],1):_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":_vm.small}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dense":_vm.small}},[_vm._v(" mdi-filter-variant ")])],1)]}}])},[_c(VContainer,{staticClass:"px-6 pt-4 pb-10 dynamic-columns-selector",style:(("background: " + _vm.getInfoCardColor))},[_c('h3',{staticClass:"text-left mb-5"},[_vm._v("Dados visíveis:")]),_c(VRow,_vm._l((_vm.columns),function(item,i){return _c(VCol,{key:i,staticClass:"py-1",attrs:{"md":"3","sm":"12"}},[_c(VCheckbox,{attrs:{"label":item.text,"hide-details":"","dense":""},on:{"change":function($event){return _vm.changeSelecteds(item)}},model:{value:(item.show),callback:function ($$v) {_vm.$set(item, "show", $$v)},expression:"item.show"}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }