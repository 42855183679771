import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":_vm.maxWidth,"persistent":"","scrollable":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.isMultiple ? 'Transferir Pendências Selecionadas' : 'Transferir Pendências')+" ")]),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"mt-6",attrs:{"disabled":_vm.loading}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.operators,"loading":_vm.loadingOperators,"no-data-text":_vm.loadingOperators ? 'Carregando...' : 'Nenhum operador encontrado.',"label":"Operador","placeholder":"Selecione um operador","clearable":"","filled":"","dense":""},model:{value:(_vm.operator),callback:function ($$v) {_vm.operator=$$v},expression:"operator"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"scoped-button mr-4",attrs:{"large":"","color":"warning","disabled":_vm.loading},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{staticClass:"scoped-button",attrs:{"large":"","color":"success","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" SALVAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }