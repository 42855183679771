






















































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InvoiceFilter from '@/views/invoices/components/InvoiceFilter.vue';
import InvoiceDataTable from '@/views/invoices/components/InvoiceDataTable.vue';
import ListAccountsDialog from '@/views/invoices/components/dialogs/ListAccountsDialog.vue';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import RemittanceInvoiceRepository from '@/repositories/RemittanceInvoiceRepository';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import InvoiceList from '@/domain/models/InvoiceList';
import IListInvoiceFilters from '@/views/invoices/interfaces/IListInvoiceFilters';
import IDataTableOptions from '@/helpers/interfaces/IDataTableOptions';
import InvoiceListActionsEnum from '@/views/invoices/enums/InvoiceListActionsEnum';

export default defineComponent({
  name: 'InvoicesList',
  components: {
    InvoiceFilter,
    InvoiceDataTable,
    ListAccountsDialog,
  },
  data: () => {
    const loading: boolean = false;
    const showFilters: boolean = true;
    const hasFirstLoading: boolean = false;

    const totalItems: number = 0;

    const companyIds: number[] = [];

    const invoices: InvoiceList[] = [];

    const showDialogs = {
      show_accounts: false,
    };

    const selectedInvoiceId: number|undefined = undefined;

    const remittanceInvoiceRepository:
      RemittanceInvoiceRepository = new RemittanceInvoiceRepository();
    const filterParametersRepository: FilterParametersRepository = new FilterParametersRepository();

    return {
      loading,
      showFilters,
      hasFirstLoading,
      companyIds,
      totalItems,
      invoices,
      showDialogs,
      selectedInvoiceId: selectedInvoiceId as number|undefined,
      remittanceInvoiceRepository,
      filterParametersRepository,
    };
  },
  computed: {
    ...mapState({
      companyGroupId: (state: any) => Number(state.authentication.user.company_group_id),
    }),
    invoiceFilter(): { validate: () => IListInvoiceFilters|false } {
      const invoiceFilter = this.$refs.InvoiceFilter as any;

      return invoiceFilter;
    },
    invoiceDataTable(): { getDataTableOptions: () => IDataTableOptions } {
      const invoiceDataTable = this.$refs.InvoiceDataTable as any;

      return invoiceDataTable;
    },
    hasInvoices(): boolean {
      return this.invoices.length > 0;
    },
  },
  methods: {
    async getInvoicesByFilters(
      filters: IListInvoiceFilters,
      dataTableOptions: IDataTableOptions,
    ): Promise<void> {
      if (!this.hasFirstLoading) {
        this.hasFirstLoading = true;
      }

      try {
        this.loading = true;

        const { data, total } = await this.remittanceInvoiceRepository.getInvoicesByFilters(
          this.companyGroupId,
          filters.selectedCompanies,
          {
            filter: {
              start_emission_date: filters.startEmissionDate,
              final_emission_date: filters.finalEmissionDate,
              start_due_date: filters.startDueDate,
              final_due_date: filters.finalDueDate,
              invoice_value: filters.invoiceValue,
              invoice_link_number: filters.invoiceLinkNumber,
            },
            items_per_page: dataTableOptions.itemsPerPage,
            page: dataTableOptions.page,
            sort: dataTableOptions.formattedSort,
          },
        );
        this.setFilterParameter(filters);

        this.invoices = data;
        this.totalItems = total;

        if (total > 0) {
          this.showFilters = false;
        }
      } catch (error) {
        this.$notification.error('Houve um problema ao buscar as faturas.');
      } finally {
        this.loading = false;
      }
    },
    setFilterParameter(filters: IListInvoiceFilters) {
      this.filterParametersRepository.setFilter(
        GroupFilterParametersEnum.INVOICES_LIST,
        [
        {
            key: 'start_emission_date',
            value: filters.startEmissionDate,
          },
          {
            key: 'final_emission_date',
            value: filters.finalEmissionDate,
          },
          {
            key: 'start_due_date',
            value: filters.startDueDate,
          },
          {
            key: 'final_due_date',
            value: filters.finalDueDate,
          },
          {
            key: 'company_ids',
            value: JSON.stringify(filters.selectedCompanies),
          },
        ],
      );
    },
    handleGetInvoices() {
      const dataTableOptions = this.invoiceDataTable.getDataTableOptions();
      const filters = this.invoiceFilter.validate();

      if (!filters) {
        return;
      }

      this.getInvoicesByFilters(filters, dataTableOptions);
    },
    handleShowFilters() {
      this.showFilters = !this.showFilters;
    },
    handleDataTableChanges() {
      if (!this.hasFirstLoading) {
        return;
      }

      this.handleGetInvoices();
    },
    handleAction(
      { event, selectedInvoiceId }: { selectedInvoiceId: number; event: InvoiceListActionsEnum; },
    ): void {
      this.showDialogs[event] = true;
      this.selectedInvoiceId = selectedInvoiceId;
    },
    handleCloseDialog(event: InvoiceListActionsEnum) {
      this.showDialogs[event] = false;
      this.selectedInvoiceId = undefined;
    },
  },
});
