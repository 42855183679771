









































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

@Component
export default class PageCard extends Vue {
  @Prop(String) readonly title!: String;

  @Prop(Boolean) readonly dividerOn!: Boolean;

  @Prop(Boolean) readonly actionsDividerOn!: Boolean;

  @Prop(Boolean) readonly showList!: Boolean;
}
