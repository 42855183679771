export enum EInstructionRemittanceType {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  UPDATE_DUE = 'UPDATE_DUE',
  UPDATE_DESCREASE = 'UPDATE_DESCREASE',
  UPDATE_VALUE = 'UPDATE_VALUE',
  UPDATE_INCREASE = 'UPDATE_INCREASE'
}

export enum EInstructionRemittanceTypeTranslate {
  UPDATE = 'Alterar Título',
  DELETE = 'Baixar Título',
  UPDATE_DUE = 'Atualizar Vencimento',
  UPDATE_DESCREASE = 'Atualizar Decréscimo',
  UPDATE_VALUE = 'Baixar Título',
  UPDATE_INCREASE = 'Atualizar Acréscimo'
}

export default EInstructionRemittanceType;
