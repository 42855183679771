import Rest from './base/Rest';

export default class BankingService extends Rest {
	static resource = 'bankingservice';

	generate() {
		this.relationship = 'charge/generate';

		this.show = this.configuratedShow;
		return this;
	}
}
