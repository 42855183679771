import MovementType from '@/domain/enums/StatementConciliationMovementType';
import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import { formatOrigin, formatType, formatCurrency } from '@/views/statementConciliation/utils';
import { formatToSpecificFormat, ptBrDateFormat, defaultDateFormat } from '@/utils/date';

export default class StatementConciliationMovement {
    constructor(
        public id: number | string,
        public origin: string,
        public origin_text: string,
        public date: string,
        public formatted_date: string,
        public document: string,
        public title_number: string,
        public type: MovementType,
        public formatted_type: string,
        public financial_nature: string,
        public history: string,
        public value: string,
        public formatted_value: string,
        public agency: string,
        public account: string,
        public is_main: boolean,
        public selected?: boolean,
        public customer_document?: string,
        public customer_name?: string,
    ) {}

    static make(data: any): StatementConciliationMovement {
        const formattedCustomerDocument = data.customer_document ? DocumentFormattation.chooseTheValidDocumentFormattation(data.customer_document) : '';
        const originText = formatOrigin(data.origin);
        const formattedType = formatType(data.type);
        const formattedValue = formatCurrency(data.value);
        const formattedDateToRaw = formatToSpecificFormat(
            data.date, defaultDateFormat, ptBrDateFormat,
        );

        return new StatementConciliationMovement(
            data.id,
            data.origin,
            originText,
            formattedDateToRaw,
            data.date,
            data.document,
            data.title_number,
            data.type,
            formattedType,
            data.financial_nature,
            data.history,
            data.value,
            formattedValue,
            data.agency,
            data.account,
            data.is_main,
            data.selected,
            formattedCustomerDocument,
            data.customer_name,
        );
    }
}
