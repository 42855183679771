























































































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { formatErrorForNotification } from '@/utils/error';
import IOption from '@/domain/interfaces/ISelectOptions';
import StatusEnum from '@/domain/enums/StatusEnum';
import BillingSignatureGroupsRepository from '@/repositories/BillingSignatureGroupsRepository';
import ECompanySubscribersType from '@/domain/enums/CompanySubscribersType';
import CompanySubscribers from '@/domain/models/CompanySubscribers';
import BillingSignatureSubscriber from '@/domain/models/BillingSignatureSubscriber';

import SubscribersForm from './components/SubscribersForm.vue';

@Component({
  components: {
    SubscribersForm,
  },
})
export default class CompanySubscribersView extends Vue {
  @Ref('form') readonly form!: VForm;

  public readonly billingSignatureGroupsRepository
    : BillingSignatureGroupsRepository = new BillingSignatureGroupsRepository();

  public loading: boolean = false;
  public loadingItems: boolean = false;

  public data: CompanySubscribers = {
    subscribers: [] as Array<BillingSignatureSubscriber>,
  } as CompanySubscribers;

  public items: Array<IOption<number>> = [];

  public status: Array<IOption<StatusEnum>> = [
    {
      text: 'Ativo',
      value: StatusEnum.ACTIVE,
    },
    {
      text: 'Inativo',
      value: StatusEnum.INACTIVE,
    },
  ];

  public types: Array<IOption<ECompanySubscribersType>> = [
    {
      text: 'Empresa',
      value: ECompanySubscribersType.COMPANY,
    },
    {
      text: 'Grupo',
      value: ECompanySubscribersType.GROUP,
    },
  ];

  public rule: Record<string, any> = {
    required: (v: string | number) => !!v || 'Campo obrigatório.',
    requiredMultiple: (v: Array<string>) => !!v.length || 'Campo obrigatório.',
    requiredNumber: (v: number) => v > 0 || 'Valor deve maior que 0 (zero).',
  };

  public get isCreate(): boolean {
    return this.$route.name === 'CreateCompanySubscribers';
  }

  public get companySubscribersId(): number {
    return +this.$route.params.id;
  }

  public get title(): string {
    return this.isCreate
      ? 'Novas Assinaturas da Empresa'
      : 'Editar Assinaturas da Empresa';
  }

  public get customItemsNoDataText(): string {
    if (!this.data.type) return 'Selecione um tipo de vinculação primeiro';

    if (this.loadingItems) return 'Carregando...';

    return 'Não há dados disponíveis';
  }

  public get companyGroupId(): number {
    return this.$session.get('company_group_id');
  }

  public mounted(): void {
    this.loadData();
  }

  public onChangeType(value: ECompanySubscribersType): void {
    this.items = [];

    if (value) this.loadItemsPerType(value);
  }

  public save(): Promise<void> | null {
    if (!this.data.subscribers || this.data.subscribers.length < this.data.mandatories) {
      this.$notification
        .warn('O quantidade de assinantes cadastrados é menor que a quantidade obrigatória.');

      return null;
    }

    if (this.form.validate()) {
      return this.isCreate
        ? this.createCompanySubscribers()
        : this.updateCompanySubscribers();
    }

    return null;
  }

  public cancel(): void {
    this.form.resetValidation();
    this.$router.push({ name: 'CompanySubscribersList' });
  }

  public async getCompanySubscribers(id: number): Promise<void> {
    this.data = await this.billingSignatureGroupsRepository
      .getCompanySubscribers(this.companyGroupId, id);
  }

  public async getCompanies(): Promise<void> {
    const companies = await this.billingSignatureGroupsRepository
      .getCompanies(this.companyGroupId);

    this.items = companies.map((company) => {
      const { id, name } = company;
      return { value: id, text: name };
    });
  }

  public getGroups(): void {
    this.items.push({
      text: this.$session.get('company_group_name'),
      value: this.companyGroupId,
    });
  }

  public async loadItemsPerType(type: ECompanySubscribersType): Promise<void> {
    try {
      this.loadingItems = true;

      if (type === ECompanySubscribersType.COMPANY) await this.getCompanies();
      if (type === ECompanySubscribersType.GROUP) this.getGroups();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingItems = false;
    }
  }

  public async loadData(): Promise<void> {
    try {
      this.loading = true;

      if (!this.isCreate) await this.getCompanySubscribers(this.companySubscribersId);
      if (this.data.type) await this.loadItemsPerType(this.data.type);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      this.cancel();
    } finally {
      this.loading = false;
    }
  }

  public async createCompanySubscribers(): Promise<void> {
    try {
      this.loading = true;

      await this.billingSignatureGroupsRepository
        .sendCompanySubscribers(this.companyGroupId, this.data);

      this.$notification.success('Assinaturas da empresa criadas com sucesso!');
      this.$router.push({ name: 'CompanySubscribersList' });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async updateCompanySubscribers(): Promise<void> {
    try {
      this.loading = true;

      await this.billingSignatureGroupsRepository
        .updateCompanySubscribers(
          this.companyGroupId,
          this.companySubscribersId,
          this.data,
        );

      this.$notification.success('Assinaturas da empresa alterada com sucesso!');
      this.$router.push({ name: 'CompanySubscribersList' });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
