enum BillingRemittanceActionType {
  DETAILS = 'DETAILS',
  GENERATE = 'GENERATE',
  OCCURRENCES = 'OCCURRENCES',
  PAYMENT_SLIP = 'PAYMENT_SLIP',
  REMOVE = 'REMOVE',
  CLEAR_BANK_SLIP_DATA = 'CLEAR_BANK_SLIP_DATA',
}

export default BillingRemittanceActionType;
