enum ERPEventsEnum {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  INNCASH_VALUE = 'inncash_value',
  CASH_FLOW_DATE = 'cash_flow_date',
  PAYMENT_FORM = 'payment_form',
  BANK_DATA = 'bank_data',
  BANK_DATA_WITH_DIFFERENT_HOLDER = 'bank_data_with_different_holder'
}

export default ERPEventsEnum;
