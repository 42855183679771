<template>
   <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          icon
          small
          v-bind="attrs"
          v-on="on"
          @click="edit"
          elevation="0"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <span>Editar</span>
    </v-tooltip>
</template>
<script>

export default {
	name: 'InnEditButton',
	components: {},
	methods: {
		edit() {
			this.$emit('edit');
			this.$emit('click');
		},
	},
};
</script>

<style>

</style>
