

































































































































































































































































import {
  Component,
  Vue,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import CreditRuleBuilderSupport from '@/views/creditRules/support/CreditRuleBuilderSupport';
import { VForm } from '@/types/VForm';
import CreditInputValueType from '@/views/creditRules/types/CreditInputValueType';
import CreditOperatorType from '@/views/creditRules/types/CreditOperatorType';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IInputValueTypeOption from '@/views/creditRules/interfaces/IInputValueTypeOption';
import ICreditRuleBuilder from '@/views/creditRules/interfaces/ICreditRuleBuilder';

@Component({
  components: {
    Draggable,
  },
})
export default class SpecificCreditRuleBuilder extends Vue {
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();
  private readonly creditRuleBuilderSupport:
    CreditRuleBuilderSupport = new CreditRuleBuilderSupport();

  public isValuePercentage: boolean = false;

  public definedValue: string|number|null|ISelectOptions = null;

  public definedInputValueType: CreditInputValueType = 'text';

  public inputValueTypes: IInputValueTypeOption[] = [
    { text: 'TEXTO', value: 'text', isSelected: true },
    { text: 'NUMÉRICO', value: 'numeric', isSelected: false },
    { text: 'EXPRESSÃO EXECUTÁVEL', value: 'executable_expression', isSelected: false },
    { text: 'VARIÁVEIS', value: 'variable', isSelected: false },
    { text: 'TOTALIZADOR', value: 'totalizer', isSelected: false },
  ];

  public conditionOperators: ISelectOptions<string>[] = [];
  public mathOperators: ISelectOptions<string>[] = [];
  public comparisonOperators: ISelectOptions<string>[] = [];

  public specificRuleGroup: ICreditRuleBuilder[] = [];

  public get hasSpecificRulesInRuleBuilder(): boolean {
    return this.specificRuleGroup.length > 0;
  }

  public get isEditingErrorMessage(): boolean {
    return this.whatIsEditing === 'error-message';
  }

  public get getCreditRuleBuilderActionText(): string {
    if (this.whatIsEditing === null) {
      return '';
    }

    return {
      'error-message': 'Mensagem de erro da regra de crédito:',
      'credit-rule': 'Regra de crédito:',
      'credit-filter': 'Filtro de crédito:',
    }[this.whatIsEditing];
  }

  public constructor() {
    super();

    this.conditionOperators = this.creditRuleBuilderSupport.getConditionOperators;
    this.mathOperators = this.creditRuleBuilderSupport.getMathOperators;
    this.comparisonOperators = this.creditRuleBuilderSupport.getComparisonOperators;
  }

  @Ref('ErpCreditRuleForm') readonly erpCreditRuleForm!: VForm;

  @Prop({
    type: [String, Object],
    required: false,
    default: null,
  }) readonly whatIsEditing!: 'credit-rule' | 'credit-filter' | 'error-message' | null;

  @Prop({
    type: Array,
    required: false,
    default: () => ([]),
  }) readonly variableOptions!: ISelectOptions<number>[];

  @Prop({
    type: Array,
    required: false,
    default: () => ([]),
  }) readonly totalizerOptions!: ISelectOptions<string>[];

  @Prop({
    type: Array,
    required: false,
    default: null,
  }) readonly rulesGroupBuilderToEdit!: ICreditRuleBuilder[]|null;

  @Watch('specificRuleGroup')
  public onSpecificRuleGroupChange(specificRuleGroup: ICreditRuleBuilder[]): void {
    this.$emit('specific-rule-group', specificRuleGroup);
  }

  @Watch('rulesGroupBuilderToEdit')
  public onEditSpecificRuleGroup(rules: ICreditRuleBuilder[]): void {
    this.specificRuleGroup = rules;
  }

  public addNewValueRule(): void {
    if (!this.erpCreditRuleForm.validate()) {
      this.$notification.error('Um dos campos de valor de regra está inválido!');

      return;
    }

    const ruleBuilderObject = {
      type: this.definedInputValueType,
      value: this.definedValue as string|number,
      isPercentage: this.isValuePercentage,
    };

    this.specificRuleGroup.push({
        ...ruleBuilderObject,
        text: this.creditRuleBuilderSupport.getFormattedTextOfSpecificRuleValue(
        ruleBuilderObject,
        this.totalizerOptions,
        this.variableOptions,
      ),
    });

    this.resetValuesFromCreditValuesInput();
  }

  public addNewRuleOperator(
    operator: string,
    operatorType: CreditOperatorType,
    index: number,
  ): void {
    this.specificRuleGroup.push({
      type: operatorType,
      value: operator,
      text: this.getOperatorText(index, operatorType),
      isPercentage: false,
    });
  }

  public getOperatorText(index: number, operatorKey: CreditOperatorType): string {
    const correlationBetweenOperatorTypeAndOperatorObject = {
      conditional_operator: 'conditionOperators',
      math_operator: 'mathOperators',
      comparison_operator: 'comparisonOperators',
    } as const;

    return this[correlationBetweenOperatorTypeAndOperatorObject[operatorKey]][index].text!;
  }

  public setInputTypeValue(inputType: CreditInputValueType): void {
    this.definedInputValueType = inputType;

    for (let i = 0; i < this.inputValueTypes.length; i += 1) {
      const actualInputType = this.inputValueTypes[i];

      let isInputSelected = false;

      if (actualInputType.value === inputType) {
        isInputSelected = true;
      }

      actualInputType.isSelected = isInputSelected;
    }

    this.resetValuesFromCreditValuesInput();
  }

  public resetAll(): void {
    this.resetValuesFromCreditValuesInput();

    this.specificRuleGroup = [];
    this.isValuePercentage = false;
  }

  public resetValuesFromCreditValuesInput(): void {
    this.erpCreditRuleForm.resetValidation();
    this.definedValue = null;
    this.isValuePercentage = false;
  }

  public handlePercentageButtonClick(): void {
    this.isValuePercentage = !this.isValuePercentage;
  }

  public handleRemoveAllRules(): void {
    this.specificRuleGroup = [];
  }

  public handleSpecificRuleClear(index: number): void {
    this.specificRuleGroup.splice(index, 1);
  }
}
