<template>
  <v-container class="pix-billing-main-container" fluid v-if="!loading">
    <v-row>
      <v-col>
        <v-container class="content">
          <div
            v-if="!isLinkInvalid && logoPath"
            class="d-none d-print-block header-logo"
          >
            <img v-if="logoPath" :src="logoPath" style="max-width: 240px" />
          </div>
          <v-row
            v-if="isLinkInvalid"
            class="full-height d-flex justify-center align-content-center"
          >
            <v-col xs="12" sm="7">
              <h2 class="text-center">
                <v-icon size="34" class="timer-icon">
                  mdi-timer-off-outline
                </v-icon>
                Atenção! Este link é inválido ou já expirou!
              </h2>
            </v-col>
          </v-row>
          <v-row class="content-row" align="center" v-else>
            <v-col xs="12" md="6" class="pr-12 first-column">
              <v-row align="center">
                <v-col xs="12" sm="12" lg="12">
                  <h1 class="no-print">
                    Olá, aqui estão suas opções para realizar o pagamento de
                    R${{ value }} referente aos dados abaixo:
                  </h1>
                  <h1 class="print-only">
                    Este é o QR Code para realizar o pagamento de R${{ value }}
                    referente aos dados abaixo:
                  </h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="extras.length > 0"
                  class="extraInfoContainer"
                  xs="12"
                  sm="12"
                  lg="12"
                >
                  <v-row no-gutters v-for="extra in extras" :key="extra.label">
                    <v-col xs="3" sm="3" lg="3" xl="2" class="print-20">
                      <label class="extraInfo">{{ extra.label }}</label>
                    </v-col>
                    <v-col xs="9" sm="9" lg="9" xl="10" class="print-80">
                      <p class="extraInfo">{{ extra.value }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col xs="12" sm="12" lg="12" class="half-on-print">
                  <h2 class="no-print">
                    Escaneie o QR Code com seu app de pagamento ou utilize os
                    botões abaixo para copiar, imprimir ou baixar o código.
                  </h2>
                  <h2 class="print-only">
                    Para realizar o pagamento, escaneie o QR Code com seu app de
                    pagamento.
                  </h2>
                  <h2>Código válido até {{ this.expirationDate }}.</h2>
                </v-col>
                <v-col xs="12" sm="12" lg="12" class="no-print">
                  <v-btn class="button" :ripple="false" @click="copy">
                    Copiar Código
                    <v-icon color="white">mdi-content-copy</v-icon>
                  </v-btn>
                  <pdf-button
                    :timeToExpire="timeToExpire"
                    :requestId="requestId"
                    :name="name"
                    :qrcode="qrcode"
                    :value="value"
                    :logoPath="logoPath"
                    :expirationDate="expirationDate"
                    :extras="extras"
                  />
                  <v-btn class="button" :ripple="false" @click="print">
                    Imprimir Código
                    <v-icon color="white">mdi-printer</v-icon>
                  </v-btn>
                  <email-button :requestId="requestId" />
                </v-col>
              </v-row>
            </v-col>
            <v-col xs="12" sm="6" class="pl-12 second-column">
              <v-row justify="end" class="m-0">
                <img id="qrcode" class="qrcode" :src="qrcode" alt="QRCode" />
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <token-pages-footer :logoPath="logoPath" />
  </v-container>
</template>

<script>
/* eslint-disable no-undef */
import moment from 'moment';
import { API_BASE } from '@config/api';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PDFButton from './PDFButton.vue';
import EmailButton from './EmailButton.vue';
import TokenPagesFooter from '../../components/defaults/TokenPagesFooter.vue';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  components: { 'pdf-button': PDFButton, EmailButton, TokenPagesFooter },
  name: 'PixBilling',
  props: {
    token: null,
  },
  data() {
    return {
      requestId: 0,
      timeToExpire: 0,
      logoPath: '',
      name: '',
      value: '',
      qrcode: '',
      pixData: '',
      loading: true,
      expirationDate: '',
      extras: [],
    };
  },
  mounted() {
    this.loadBillingInfo();
  },
  computed: {
    isLinkInvalid() {
      return !this.requestId || this.timeToExpire == 0;
    },
  },
  methods: {
    loadBillingInfo() {
      this.$http.post(`${API_BASE}pix/active`, { token: this.token }).then(
        (result) => {
          const { data } = result.body;
          this.requestId = data.requestId;
          this.timeToExpire = data.timeToExpire;
          this.logoPath = `${API_BASE}pix/company/logo/${data.company.companyGroupId}`;
          this.name = data.company.name;
          this.value = data.value;
          this.qrcode = `${API_BASE}pix/qrcode/${this.token}`;
          this.pixData = data.pixData;
          this.expirationDate = moment(this.timeToExpire)
            .format('DD/MM/YYYY HH:mm');
          this.extras = data.extras || [];

          setTimeout(this.checkExpiration, this.timeToExpire * 1000);
          this.loading = false;
        },
        (error) => {
          this.$helpers.defaultCatchError(error);
          this.loading = false;
        },
      );
    },
    checkExpiration() {
      const expirationTime = moment(this.timeToExpire);

      if (moment().isAfter(expirationTime)) {
        this.timeToExpire = 0;
      }
    },
    copy() {
      navigator.clipboard.writeText(this.pixData).then(
        () => {
          showNotification('Atenção', 'Código copiado', 'success');
        },
        () => {
          showNotification('Atenção', 'Não foi possível copiar o código', 'warning');
        },
      );
    },
    print() {
      window.print();
    },
  },
};
</script>

<style>
@media screen {
  .pix-billing-main-container .content {
    height: calc(80vh);
  }
}
.pix-billing-main-container {
  height: 100vh;
  max-height: 100vh;
  padding: 8vh 0 0;
  font-size: 1.7vh;
}
.pix-billing-main-container h1 {
  font-size: 2em;
  margin: 0;
}
.pix-billing-main-container h2 {
  font-size: 1.6rem;
  margin: 0;
}
.extraInfoContainer {
  padding: 1.2vh 12px;
  margin: 0;
}
p.extraInfo,
label.extraInfo {
  font-size: 1em;
  margin: 0;
}
label.extraInfo {
  font-weight: bold;
}
.pix-billing-main-container .v-btn:not(.v-btn--round).v-size--default {
  width: 100%;
  margin-bottom: 0.8em;
  border-radius: 14px;
  padding: 0;
}
.pix-billing-main-container .button:focus {
  outline: none;
}
.pix-billing-main-container .button span {
  font-size: 1.4em !important;
  color: #fff;
  background: #2ca189;
  border-radius: 14px;
  text-align: left;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 16px;
}
.pix-billing-main-container .button span:hover {
  background: #248370;
}
.pix-billing-main-container .button:disabled span {
  background: #dcdcdc;
}
.pix-billing-main-container .qrcode {
  width: 100%;
  border: 3px solid #363636;
  padding: 32px;
  border-radius: 32px;
  max-height: 68vh;
  max-width: 470px;
}
.pix-billing-main-container .full-height {
  height: 100%;
}
.pix-billing-main-container .timer-icon {
  vertical-align: baseline !important;
}
.pix-billing-main-container .v-image__image--cover {
  background-size: contain;
}
.pix-billing-main-container .print-only {
  display: none;
}

@media print {
  .pix-billing-main-container .print-only {
    display: initial;
  }

  .pix-billing-main-container .no-print {
    display: none;
  }

  div {
    break-inside: avoid;
  }

  .pix-billing-main-container h1 {
    font-size: 1.5em;
  }

  .pix-billing-main-container h2 {
    font-size: 1.2em;
  }

  .pix-billing-main-container h1,
  .pix-billing-main-container h2 {
    font-weight: 400;
  }

  .content-row {
    flex-direction: column-reverse;
    height: auto;
    justify-content: flex-end;
  }
  .first-column,
  .second-column {
    max-width: 100%;
    width: 100%;
    flex: initial;
  }

  .second-column .row {
    justify-content: center !important;
  }

  .print-20 {
    max-width: 25%;
    flex-basis: 25%;
  }
  .print-80 {
    max-width: 75%;
    flex-basis: 75%;
  }
  .v-application .pr-12 {
    padding-right: 12px !important;
  }
  .v-application .pl-12 {
    padding-left: 12px !important;
  }
  .pix-billing-main-container .qrcode {
    width: 260px !important;
    border: none;
  }

  img {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  .pix-billing-main-container .header-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
  }
  .pix-billing-main-container .extraInfoContainer .row {
    line-height: 1.2em;
  }

  .pix-billing-main-container p.extraInfo,
  .pix-billing-main-container label.extraInfo {
    font-size: 0.8em;
  }
}
@media (max-height: 680px) {
  .pix-billing-main-container .button span {
    max-height: 36px;
  }
}
</style>
