<template>
	<v-checkbox
		:label="label"
		class="flex-item float-right mt-3"
		:input-value="model == 'A'"
		@change="(val) => model = val ? 'A' : 'I'"
		:class="centralize ? 'mx-auto' : 'mr-md-3 mb-n3'"
	/>
</template>

<script>
export default {
	name: 'StatusCheckbox',
	data() {
		return {
			model: this.value,
		};
	},
	props: {
		label: String,
		centralize: Boolean,
		value: [Object, String, Boolean],
	},
	watch: {
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
		model() {
			this.$emit('input', this.model);
		},
	},
};
</script>

<style>

</style>
