import VanList from '@/domain/models/vans/VanList';
import Repository from './base/Repository';

export default class VanRepository extends Repository {
  async getVans(isS3: boolean): Promise<VanList[]> {
    const { data: response } = await this.datasource.get('/vans', {
        params: {
            is_s3: Number(isS3),
        },
    });

    return response.data.map(VanList.make);
  }
}
