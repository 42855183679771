import { formatToSpecificFormat } from '@/utils/date';

export default class AccountReceivableChart {
    constructor(
      public totalExpired: number,
      public totalToExpire: number,
      public totalPaidOut: number,
      public averageMaturity: number,
      public averageDelay: number,
      public creditLimitBalance: number,
      public lastPayment: string,
      public securitiesBalance: number,
      public titleWithHighestValue: number,
      public biggestDelay: number,
      public currentDelay: number,
      public lastBilling: string,
    ) {}

    static make(data: any): AccountReceivableChart {
      const formattedLastPayment = data.last_payment ? formatToSpecificFormat(data.last_payment, 'dd/MM/yyyy') : '';
      const formattedLastBilling = data.last_billing ? formatToSpecificFormat(data.last_billing, 'dd/MM/yyyy HH:mm', 'yyyy-MM-dd HH:mm:ss') : '-';

        return new AccountReceivableChart(
            data.total_expired,
            data.total_to_expire,
            data.total_paid_out,
            data.average_maturity,
            data.average_delay,
            data.credit_limit_balance,
            formattedLastPayment,
            data.securities_balance,
            data.title_with_highest_value,
            data.biggest_delay,
            data.current_delay,
            formattedLastBilling,
        );
    }
}
