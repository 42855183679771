import Module from './Module';
import CompanyGroup from './CompanyGroup';

export default class UserGroup {
  constructor(
    public id : number,
    public status : boolean,
    public name: string,
    public companyGroup: CompanyGroup|null,
    public modules : Array<Module>|null,
  ) {}

  static make(data : any) : UserGroup {
    const companyGroup = data.company_group
      ? CompanyGroup.make(data.company_group)
      : null;

    const modules = data.modules
      ? data.modules.map(Module.make)
      : null;

    return new UserGroup(
      data.id,
      data.status,
      data.name,
      companyGroup,
      modules,
    );
  }
}
