import Repository from '@/repositories/base/Repository';
import FilterParameters from '@/domain/models/filter-parameters/FilterParameterBillingPendencyList';
import BillingPendency from '@/domain/models/BillingPendency';
import BillingPendencyHistory from '@/domain/models/BillingPendencyHistory';
import Company from '@/domain/models/Company';
import Operator from '@/domain/models/Operator';
import BillingPendencyTransfer from '@/domain/models/BillingPendencyTransfer';
import SaleGroup from '@/domain/models/SaleGroup';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import qs from 'qs';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';

export default class BillingPendencyRepository extends Repository {
  public async getPendencies(
    group: number,
    filters: ILaravelOrdenationTableParams<Omit<FilterParameters, 'columnsToShow'>>,
  ): Promise<IDataTableWithLength<BillingPendency[]>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${group}/billing-pendencies`,
      {
        params: filters,
        paramsSerializer: qs.stringify,
      },
    );

    if (this.isStatusOK(status)) {
      return {
        data: response.data.map(BillingPendency.make),
        total: response.total,
      };
    }

    throw new Error('Ops, algo inesperado ocorreu durante a listagem das pendências de cobrança!');
  }

  public async getPendencyHistory(
    group: number,
    id: string | number,
  ): Promise<Array<BillingPendencyHistory>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${group}/billing-pendencies/${id}/history`,
    );

    if (this.isStatusOK(status)) {
      return response.data.map(BillingPendencyHistory.make);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a listagem do histórico das pendências de cobrança!');
  }

  public async getPendencyTransfers(group: number): Promise<Array<BillingPendencyTransfer>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${group}/billing-pendencies/transfers`,
    );

    if (this.isStatusOK(status)) {
      return response.data.map(BillingPendencyTransfer.make);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a listagem das transferências de pendências de cobrança!');
  }

  public async sendPendenciesTransfer(
    group: number,
    economicGroupId: number|string,
    record: {
      costumers: Array<string | number>,
      operator: number,
    },
  ): Promise<void> {
    const { status } = await this.datasource
      .send(`company-groups/${group}/billing-pendencies/${economicGroupId}/transfers/${record.operator}`, record);

    if (this.isStatusOK(status)) return;

    throw new Error('Ops, algo inesperado ocorreu durante a transfêrencia das pendências de cobrança!');
  }

  public async approvePendencyTransfers(
    group: number,
    economicGroupId: number|string,
    id: number,
  ): Promise<void> {
    const { status } = await this.datasource
      .patch(`company-groups/${group}/billing-pendencies/${economicGroupId}/transfers/${id}/approvals`, {});

    if (this.isStatusOK(status)) return;

    throw new Error('Ops, algo inesperado ocorreu durante a aprovação das transfêrencias das pendências de cobrança!');
  }

  public async disapprovePendencyTransfers(
    group: number,
    economicGroupId: number|string,
    id: number,
  ): Promise<void> {
    const { status } = await this.datasource
      .patch(`company-groups/${group}/billing-pendencies/${economicGroupId}/transfers/${id}/disapprovals`, {});

    if (this.isStatusOK(status)) return;

    throw new Error('Ops, algo inesperado ocorreu durante a reprovação das transfêrencias das pendências de cobrança!');
  }

  public async getCompanies(group: number): Promise<Array<Company>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/companies`);

    if (this.isStatusOK(status)) {
      const companies: Array<Company> = response.data.map(Company.make);
      return companies;
    }

    throw new Error('Ops, algo inesperado ocorreu na listagem das Razão Socials.');
  }

  public async getOperators(groupId: number): Promise<Array<Operator>> {
    const { data: response, status } = await this.datasource.get(`company-groups/${groupId}/users/operator-distributions`);

    if (this.isStatusOK(status)) {
      return response.data.map(Operator.make);
    }

    throw new Error('Ops, algo inesperado ocorreu na listagem dos operadores.');
  }

  public async getSaleGroups(group: number): Promise<Array<SaleGroup>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/sales-groups`);

    if (this.isStatusOK(status)) {
      const saleGroups: Array<SaleGroup> = response.data.map(SaleGroup.make);
      return saleGroups;
    }

    throw new Error('Ops, algo inesperado ocorreu na listagem dos grupos de venda.');
  }
}
