<template>
    <v-select
      placeholder="Selecione a pasta da empresa"
      filled
      dense
      hide-details
      v-model="directory"
      item-text="type"
      item-value="id"
      :items="folders"
    >
      <template slot="selection" slot-scope="data">
      {{ `(${data.item.van_name})` }} - {{ data.item.type }} - {{ data.item.name }}
      </template>
      <template slot="item" slot-scope="data">
        {{ `(${data.item.van_name})` }} - {{ data.item.type }} - {{ data.item.name }}
      </template>
    </v-select>
</template>
<script>
import CnabFiles from "@/services/cnab_files.service";
export default {
  name: "CnabReturnSelectClientFolder",
  data: () => ({
    folders: [],

    directory: ""
  }),

  created() {
    this.getAllDirectories();
  },

  watch: {
    directory() {
      this.$emit('change', this.directory)
    }
  },

  methods: {
    async getAllDirectories() {
      try {
        const cnabDirectories = new CnabFiles();

        const response = await cnabDirectories.directories().index();

        this.folders = response.data;
      } catch (error) {
        this.$helpers.defaultCatchError(error);
      }
    },

  }
};
</script>
