import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px"},on:{"click:outside":_vm.closeModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" Atenção! ")]),_c(VCardText,[_vm._v(" Você deseja mesmo enviar a remessa para um banco diferente do portador dos títulos? ")]),_c(VCardActions,[_c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"warning","depressed":"","large":"","block":"","dark":""},on:{"click":_vm.closeModal}},[_vm._v(" CANCELAR ")])],1),_c(VCol,[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"success","depressed":"","large":"","block":"","dark":""},on:{"click":_vm.confirm}},[_vm._v(" CONTINUAR ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }