import Rest from './base/Rest';

export default class ApiBankingService extends Rest {
	static resource = 'apibanking';

	configurated() {
		this.relationship = 'configurated';

		this.show = this.configuratedShow;
		return this;
	}

	listAll() {
		this.relationship = 'listAllByBank';

		return this;
	}

	configuratedShow(service, type, company) {
		return this.get(`/${service}/type/${type}/company/${company}`);
	}

	service(service) {
		this.relationship = `${this.getId(service)}/services`;

		return this;
	}

	field(service, field) {
		this.relationship = `${this.getId(service)}/services/${this.getId(field)}/fields`;

		return this;
	}
}
