enum StatementConciliationStatusType {
  NOT_PROCESSED = 0,
  CONCILIATED = 1,
  DIVERGENCE = 2,
  NOT_FOUND_ERP = 3,
  IGNORED = 4,
  NOT_CONCILIATED = 5,
  NOT_CONCILIATED_ERP = 6,
  ERP_CONCILIATED = 7,
  PARTIALLY_CONCILIATED = 8,
  PROCESSING = 9,
  PENDING_WITH_ERP = 10,
  FAILED_WITH_ERP = 11,
}

export default StatementConciliationStatusType;
