import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":_vm.dialogWidth,"persistent":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_c(VRow,{attrs:{"justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" Rotina em uso pelo usuário "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.userName))])]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"icon":"","large":""},on:{"click":_vm.handleBack}},[_c(VIcon,[_vm._v(" mdi-close ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }