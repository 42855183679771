



































































































import {
  Component,
  Mixins,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { formateDate, validateDateRange } from '@/utils/date';
import { VForm } from '@/types/VForm';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import VDataTableHeader from '@/types/IVDataTableHeader';

import OperatorUser from '@/domain/models/OperatorUser';

import ActionButton from '@/components/tables/ActionButton.vue';

import IOperatorAbsences from '@/views/operatorDistribution/interfaces/IOperatorAbsences';

@Component({
  components: {
    ActionButton,
  },
})
export default class OperatorDistributionAbsences
  extends Mixins<ModelManagementMixin<IOperatorAbsences[]>>(ModelManagementMixin) {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Array as () => OperatorUser[],
    required: true,
    default: [],
  }) operators!: OperatorUser[];

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) upsertLoading?: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) getDataLoading?: boolean;

  public readonly formatDate = formateDate;
  public errorDateRange: boolean = false;
  public data: IOperatorAbsences = {};

  public headers: Array<VDataTableHeader> = [
    {
      text: 'Data Inicial',
      value: 'initialDate',
      sortable: false,
      align: 'start',
      width: '25%',
    },
    {
      text: 'Data Final (Opcional)',
      value: 'endDate',
      sortable: false,
      align: 'start',
      width: '25%',
    },
    {
      text: 'Operador Substituto',
      value: 'substituteId',
      sortable: false,
      align: 'start',
      width: '25%',
    },
    {
      text: '',
      value: 'id',
      sortable: false,
      align: 'end',
      width: '25%',
    },
  ];

  public rule: Record<string, any> = {
    required: (v: string | number) => !!v || 'Campo obrigatório.',
  };

  public validadeDate(): boolean {
    const {
      initialDate,
      endDate,
    } = this.data;

    if (!endDate || !initialDate) {
      this.errorDateRange = false;
      return true;
    }

    const isDateValid = validateDateRange(initialDate, endDate);

    if (!isDateValid) {
      this.errorDateRange = true;
    } else {
      this.errorDateRange = false;
    }

    return isDateValid;
  }

  public addAbsence() {
    if (this.validadeDate() && this.form.validate()) {
      const {
        initialDate,
        endDate,
        substituteId,
      } = this.data;

      this.model.push({
        initialDate,
        endDate,
        substituteId,
      });

      this.data = {};
      this.form.reset();
    }
  }

  public removeAbsence(index: number) {
    this.model.splice(index, 1);
  }

  public getSubstituteNameFromOperatorsById(substituteId: number): string {
    const foundedOperator = this.operators.find((operator) => operator.id === substituteId);

    if (!foundedOperator) {
      return '';
    }

    return foundedOperator.name;
  }
}
