<template>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                </div>
            </div>
            <div class="m-portlet__head-tools">
                <router-link :to="{ name: 'cnabremittance' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
                <button id="btn-save" type="button" class="m-btn btn btn-success" v-on:click="editCnab()">Salvar</button>
            </div>
        </div>
        <div class="m-portlet__body">
            <!--begin: Search Form -->
            <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                <div class="row" id="frmCnabRemittance">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Descrição</label>
                            <input type="text" class="form-control" field-name="Descrição" id="description"
                                   name="description" required>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Operação</label>
                            <select class="form-control select2 hide" field-name="Operação" id="operation" name="operation">
                                <option value="">Selecione...</option>
                                <option value="0">Remessa</option>
                                <option value="1">Retorno</option>
                            </select>
                        </div>
                    </div>                        
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>Tipo</label>
                            <select class="form-control select2 hide" field-name="Tipo" id="type" name="type">
                                <option value="0">Pagar</option>
                                <option value="1">Receber</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Tamanho (Posições)</label>
                            <input type="number" step="1" class="form-control" field-name="Tamanho (Posições)" id="size"
                                   name="size">
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-12" style="margin-top:20px;">
                        <h4>Segmentos</h4>
                    </div>

                    <div class="row col-md-12">
                        <div class="col-md-6">
                            <input type="hidden" name="editing" id="editing" value="">
                            <div class="form-group">
                                <label>Descrição</label>
                                <input type="text" class="form-control" field-name="Descrição" id="segmentDescription" name="segmentDescription">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Forma de Pagamento</label>
                                <select @change="changePaymentMethod($event)" class="form-control select2 hide" field-name="Forma de Pagamento" id="type_payment_method" name="type_payment_method" style="width: 100%;">
                                    <option value="">Selecione...</option>
                                    <option v-for="type in typePaymentMethods" :value="type.id" v-bind:key="type.id">{{ type.description }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Tipo de Registro</label>
                                <select class="form-control select2 hide" field-name="Tipo Registro" id="type_reg" name="type_reg">
                                    <option value="">Selecione...</option>
                                    <option value="0">Header de Arquivo</option>
                                    <option value="1">Header de Lote</option>
                                    <option value="3">Detalhe</option>
                                    <option value="5">Trailer de Lote</option>
                                    <option value="9">Trailer de Arquivo</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Tipo Conta</label>
                                <select class="form-control select2 hide"
                                        field-name="Filtra registros pelo mesmo tipo de conta"
                                        id="account_type"
                                        name="account_type">
                                    <option value="0">Conta Poupança</option>
                                    <option value="1">Conta Corrente</option>
                                    <option value="2">Ambos</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Tributo?</label>
                                <select :disabled="tributosDisabled" class="form-control select2 hide" field-name="Tributo?" id="is_tax" name="is_tax">
                                    <option value="2">Ambos</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>    
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Linhas Duplicadas?</label>
                                <select class="form-control select2 hide" field-name="Permite Linhas Duplicadas"
                                        id="allow_line"
                                        name="allow_line">
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Mesmo Banco?</label>
                                <select class="form-control select2 hide" field-name="Filtra registros pelo mesmo banco"
                                        id="same_bank"
                                        name="same_bank">
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                    <option value="2">Ambos</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Mesmo Titular?</label>
                                <select class="form-control select2 hide"
                                        field-name="Filtra registros pelo mesmo titular"
                                        id="same_owner"
                                        name="same_owner">
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                    <option value="2">Ambos</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Arquivo Identificado?</label>
                                <select class="form-control select2 hide" id="identified_file" name="identified_file">
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <input type="hidden" name="editing" id="editing" value="">
                            <div class="form-group">
                                <label>Validação do segmento</label>
                                <input type="text" class="form-control" field-name="Validação do segmento" id="segmentValidation" name="segmentValidation">
                            </div>
                        </div>
                        <div class="col-md-1" style="padding-top: 25px;text-align: right;">
                            <button type="button" id="addSegment" class="btn m-btn--pill btn-success" v-on:click="addSegment()">
                                <i class="la la-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <table class="table table-striped m-table mt-4">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Linha</th>
                                <th>Descrição</th>
                                <th>Forma de Pagamento</th>
                                <th>Tipo de Registro</th>
                                <th>Tipo Conta</th>
                                <th>Tributo?</th>
                                <th>Linhas Duplicadas?</th>
                                <th>Mesmo Banco?</th>
                                <th>Mesmo Titular?</th>
                                <th>Validação</th>
                                <th>Arquivo Identificado?</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="segment in addedSegments" :key="segment.id">
                                <td class="m--align-right">
                                    <i class="fa fa-sort-up" style="cursor:pointer; margin-right: 5px;" v-on:click="moveUp(segment)"></i>
                                    <i class="fa fa-sort-down" title="Duplicar" v-on:click="moveDown(segment)" style="cursor:pointer;"></i>
                                </td>
                                <td style="vertical-align: middle;">{{segment.position}}</td>
                                <td style="vertical-align: middle;">{{segment.description}}</td>
                                <td style="vertical-align: middle;">{{getTypePaymentMethod(segment.typePaymentMethod)}}</td>
                                <td style="vertical-align: middle;">{{getTypeDescription(segment.typeReg)}}</td>
                                <td style="vertical-align: middle;">
                                    <span v-if="segment.accountType == '0'">Conta Poupança</span>
                                    <span v-else-if="segment.accountType == '1'">Conta Corrente</span>
                                    <span v-else-if="segment.accountType == '2'">Ambos</span>
                                    <span v-else></span>
                                </td>
                                <td style="vertical-align: middle;">{{getTypeTax(segment.isTax)}}</td>
                                <td style="vertical-align: middle;">{{segment.allowDuplicateLine == "1" ? 'Sim' : 'Não'}}</td>
                                <td style="vertical-align: middle;">
                                    <span v-if="segment.sameBank == '0'">Não</span>
                                    <span v-else-if="segment.sameBank == '1'">Sim</span>
                                    <span v-else-if="segment.sameBank == '2'">Ambos</span>
                                    <span v-else></span>
                                </td>
                                <td style="vertical-align: middle;">
                                    <span v-if="segment.sameOwner == '0'">Não</span>
                                    <span v-else-if="segment.sameOwner == '1'">Sim</span>
                                    <span v-else-if="segment.sameOwner == '2'">Ambos</span>
                                    <span v-else></span>
                                </td>
                                <td style="vertical-align: middle;">{{segment.segmentValidation}}</td>
                                <td style="vertical-align: middle;">
                                    <span v-if="segment.identifiedFile == '0' || !segment.identifiedFile">Não</span>
                                    <span v-else-if="segment.identifiedFile == '1'">Sim</span>
                                </td>
                                <td class="m--align-right">
                                    <i class="fa edit fa-pencil" style="cursor:pointer; margin-right: 5px;" v-on:click="editSegment(segment)"></i>
                                    <i class="fa remove fa-copy" title="Duplicar" v-on:click="duplicateSegment(segment)" style="cursor:pointer;margin-right: 5px;"></i>
                                    <i class="fa remove fa-trash-o" v-on:click="removeSegment(segment)" style="cursor:pointer;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

    th { 
        line-height: 1.2;
        text-align: center;
        vertical-align: inherit !important;
    }
</style>

<script>
    import _ from 'lodash';

    export default {
        data() {
            return {
                cnab_remittance_id: this.$route.params.dda_config_id,
                typePaymentMethods: [],
                addedSegments: [],
                tributosDisabled: true
            }
        },

        mounted: function () {
            blockPage('Carregando os dados...');
            this.loadPayments();
        },

        methods: {
            getTypeDescription: function (type) {
                let description = '';
                switch (type) {
                    case "0":
                        description = 'Header de Arquivo';
                        break;
                    case "1":
                        description = 'Header de Lote';
                        break;
                    case "3":
                        description = 'Detalhes';
                        break;
                    case "5":
                        description = 'Trailer de Lote';
                        break;
                    case "9":
                        description = 'Trailer de Arquivo';
                        break;
                }
                return description;
            },
            /*
            *
            *   Funcao de Filtro para o tributo
            *   funciona atraves de um switch case onde 0: nao, 1: sim, 2: ambos
            *   Pega os valores atraves da funcao loadCnabRemittance
            */
            getTypeTax: function (tributes){
                
                let description = '';
                switch (tributes){

                    case 0:
                        description = "Não";
                        break;
                    case 1:
                        description = "Sim";
                        break;
                    case 2:
                        description = "Ambos";
                        break;

                }

                return description;
            },
            loadPayments: function () {
                this.typePaymentMethods = [
                    {
                        id: 1,
                        description: 'Boleto'
                    },
                    {
                        id: 2, 
                        description: 'TED / Transf.'
                    },
                    {
                        id: 3, 
                        description: 'DOC'
                    },                    
                    {
                        id: 7, 
                        description: 'PIX'
                    },
                    {
                        id: 8, 
                        description: 'GPS'
                    },
                    {
                        id: 9, 
                        description: 'DARF'
                    },
                    {
                        id: 10, 
                        description: 'Concessionária'
                    },
                    {
                        id: 11, 
                        description: 'Tributo'
                    },
                    {
                        id: 20,
                        description: 'Pix FGTS'
                    },
                    {
                        id: 21,
                        description: 'ISS'

                    },
                    {
                        id: 22,
                        description: 'Pix com outra títularidade'
                    }

                ];

                this.loadCnabRemittances();
            },

            removeSegment: function (segment) {
                //validar se o segmento já está sendo utilizado
                let self = this;
                
                this.$http.delete(this.$store.state.API_BASE + 'segment/remove/'+ segment.id, {headers: {'token': this.$session.get('token')}}).then(
                    (result) => {
                        showNotification('Sucesso', 'Sucesso ao alterar os dados', 'success');
                        self.addedSegments.splice(self.addedSegments.indexOf(segment), 1);
                    },
                    (error) => {
                        showNotification('Atenção', error.body.message, 'danger');
                    }
                )

            },
            duplicateSegment: function (segment) {
                let self = this;
                
                delete segment.id;
                self.addedSegments.splice(self.addedSegments.indexOf(segment) + 1, 0, Object.assign({}, segment));
                console.log(self.addedSegments)
                self.addedSegments
                    .filter(segment => segment.deleted == 0)
                    .map((segment, index) => {
                        segment.position = index + 1;
                    });

            },
            moveUp: function (segment) {
                let self = this;
                let oldIndex = self.addedSegments.indexOf(segment);
                if (oldIndex > 0) {
                    let oldIndex = self.addedSegments.indexOf(segment);
                    segment.position = segment.position - 1;
                    self.addedSegments.splice(oldIndex, 1);
                    self.addedSegments.splice(oldIndex - 1, 0, segment);
                    self.addedSegments
                        .filter(segment => segment.deleted == 0)
                        .map((segment, index) => {
                            segment.position = index + 1;
                        });
                }
            },
            moveDown: function (segment) {
                let self = this;
                let oldIndex = self.addedSegments.indexOf(segment);
                if (oldIndex < self.addedSegments.length - 1) {
                    segment.position = segment.position + 1;
                    self.addedSegments.splice(oldIndex, 1);
                    self.addedSegments.splice(oldIndex + 1, 0, segment);
                    self.addedSegments
                        .filter(segment => segment.deleted == 0)
                        .map((segment, index) => {
                            segment.position = index + 1;
                        });
                }
            },

            editSegment: function (segment) {
                let self = this;
                self.editingSegment = segment;

                $("#editing").val(self.addedSegments.indexOf(segment));
                $("#segmentValidation").val(segment.segmentValidation)
                $("#segmentDescription").val(segment.description);
                $("#type_reg").val(segment.typeReg);
                $("#allow_line").val(segment.allowDuplicateLine);
                $("#same_bank").val(segment.sameBank);
                $("#same_owner").val(segment.sameOwner);
                $("#identified_file").val(segment.identifiedFile);
                $("#account_type").val(segment.accountType);
                $("#is_tax").val(segment.isTax);

                if (segment.typePaymentMethod) {
                    $('#type_payment_method').val(segment.typePaymentMethod);
                    $('#type_payment_method').trigger('change');
                }

                if(segment.typePaymentMethod == 1){
                    this.tributosDisabled = false; 
                }
            },

            addSegment: function () {
                let self = this;
                let exit = false;

                if ($.trim($("#segmentDescription").val()) === '' || ($.trim($("#type_payment_method").val()) === '' && $('#type').val() == "0" && $("#type_reg").val() == "3") || $.trim($("#type_reg").val()) === '') {
                    exit = true;
                }

                if (exit) {
                    showNotification('Atenção', 'Todos os campos do segmento devem ser preenchidos!', 'warning');
                    return false;
                }

                if ($.trim($("#editing").val()) != '') {
                    var itemId = $("#editing").val();

                    self.addedSegments.splice(itemId, 1, {
                        id: self.editingSegment.id,
                        segmentValidation: $("#segmentValidation").val(),
                        description: $("#segmentDescription").val(),
                        deleted: 0,
                        typeReg: $("#type_reg").val(),
                        allowDuplicateLine: $("#allow_line").val(),
                        sameBank: $("#same_bank").val(),
                        sameOwner: $("#same_owner").val(),
                        identifiedFile: $("#identified_file").val(),
                        accountType: $("#account_type").val(),
                        isTax: parseInt($("#is_tax").val()),
                        typePaymentMethod: $("#type_payment_method").val(),
                        position: self.editingSegment.position
                    });
                    self.editingSegment = undefined;

                } else {
                    self.addedSegments.push(
                        {
                            description: $("#segmentDescription").val(),
                            segmentValidation: $("#segmentValidation").val(),
                            deleted: 0,
                            typeReg: $("#type_reg").val(),
                            allowDuplicateLine: $("#allow_line").val(),
                            sameBank: $("#same_bank").val(),
                            sameOwner: $("#same_owner").val(),
                            identifiedFile: $("#identified_file").val(),
                            accountType: $("#account_type").val(),
                            isTax: parseInt($("#is_tax").val()),
                            typePaymentMethod: $("#type_payment_method").val(),
                            position: self.addedSegments.length + 1
                        }
                    )
                }

                console.log(self.addedSegments);

                $("#segmentDescription").val("");
                $(".select2-selection__choice").remove();
                $("#type_payment_method").val("");
                $("#type_reg").val("");
                $("#allow_line").val("0");
                $("#same_bank").val("0");
                $("#same_owner").val("0");
                $("#identified_file").val("0");
                $("#account_type").val("0");
                $("#segmentValidation").val("");
                $("#is_tax").val("2");
                $("#editing").val("");
                $("#type_payment_method").select2({
                    placeholder: "Selecione as formas de pagamento."
                });

            },

            loadCnabRemittances: function () {
                let self = this;

                $.ajax({
                    method: "GET",
                    url: self.$store.state.API_BASE + 'cnabremittance/listOne/' + self.$route.params.cnab_remittance_id,
                    headers: {
                        'token': self.$session.get('token')
                    },
                }).done(function (result) {
                    let typeDesc = "";
                    let operationDesc = "";

                    self.addedSegments = result.data[0].segments;

                    $('#description').val(result.data[0].description)
                    $('#size').val(result.data[0].size)
                    $('#type').val(result.data[0].type)

                    if (result.data[0].type == 0) {
                        typeDesc = "Pagar";
                    } else {
                        typeDesc = "Receber";
                    }
                    $('#select2-type-container').attr('title', typeDesc);
                    $('#select2-type-container').html(typeDesc);

                    $('#operation').val(result.data[0].operation);

                    if (result.data[0].operation == 0) {
                        operationDesc = "Remessa";
                    } else {
                        operationDesc = "Receber";
                    }
                    $('#select2-operation-container').attr('title', operationDesc);
                    $('#select2-operation-container').html(typeDesc);

                    self.loadMethod();
                }).fail(function (error) {
                    unblockPage();
                });
            },

            editCnab: function () {
                var self = this;
                var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';
                blockPage('Salvando alterações...');

                $.each($('input, select', '#frmCnabRemittance'), function () {
                    var $this = $(this);

                    if ($.trim(this.value) == '') {
                        exitButton = true;
                        fieldName = $this.attr('field-name');

                        $(".form-group:has(#" + this.id + ")").addClass('has-danger');
                        $("#" + this.id).addClass('input-error');
                        $("#" + this.id).css('border-color', 'red');
                        return false;
                    } else {
                        exitButton = false;
                        arrayData[i] = {};
                        arrayData[i][this.id] = this.value;
                    }

                    i++;
                });

                if (exitButton) {
                    showNotification('Atenção', 'O campo <strong>' + fieldName + '</strong> não foi informado!', 'warning');
                    return false;
                }

                arrayData.push({
                    id: self.$route.params.cnab_remittance_id
                });

                $.ajax({
                    method: "POST",
                    url: self.$store.state.API_BASE + 'cnabremittance/edit',
                    headers: {
                        'token': self.$session.get('token')
                    },
                    data: {
                        arrayData: JSON.stringify(arrayData),
                        segments: JSON.stringify(self.addedSegments)
                    }
                }).done(function (result) {
                    if (result.error == false) {
                        showNotification('Sucesso', 'Remessa CNAB alterada!', 'success');
                        self.$router.replace({name: 'cnabremittance'});
                    } else {
                        showNotification('Atenção', result.error, 'danger');
                        return false;
                    }
                }).fail(function (error) {
                    unblockPage();
                });
            },

            loadMethod: function () {
                let self = this;

                $('input').keyup(function () {
                    if (this.id) {
                        if ($("#" + this.id).hasClass('input-error')) {
                            $("#" + this.id).removeClass('input-error');
                            $("#" + this.id).css('border-color', '#ebedf2');
                            $(".form-group:has(#" + this.id + ")").removeClass('has-danger');
                        }
                    }
                });

                $('select').change(function () {
                    if (this.id) {
                        if ($("#" + this.id).hasClass('input-error')) {
                            $("#" + this.id).removeClass('input-error');
                            $("#" + this.id).css('border-color', '#ebedf2');
                            $(".form-group:has(#" + this.id + ")").removeClass('has-danger');
                        }
                    }
                });

                unblockPage();
            },
            getTypePaymentMethod: function (typePaymentMethod) {

                let found = _.find(this.typePaymentMethods, (o) => o.id == typePaymentMethod);
                
                return found ? found.description : typePaymentMethod;
            },
            changePaymentMethod: function (event){

                event.target.value == 1 ? this.tributosDisabled = false : this.tributosDisabled = true

            }
        }
    }
</script>