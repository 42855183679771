<template>
	<div class="mx-auto pa-12 d-flex flex-column w-100 text-center">
		<span class="flex-item text-h5 font-weight-bold text--secondary">
			{{ title }}
		</span>
		<span class="flex-item text-subtitle-2 text--secondary">
			{{ subtitle }}
		</span>
	</div>
</template>

<script>
export default {
	name: 'Disclaimer',
	props: {
		title: String,
		subtitle: String,
	},
};
</script>

<style>

</style>
