import Repository from '@/repositories/base/Repository';
import CompanySubscribers from '@/domain/models/CompanySubscribers';
import Company from '@/domain/models/Company';
import CompanySubscribersList from '@/domain/models/CompanySubscribersList';
import CompanySubscriberPreNegotiation from '@/domain/models/CompanySubscriberPreNegotiation';

export default class BillingSignatureGroupsRepository extends Repository {
  public async getBillingSignatureGroupList(
    companyGroupId: number,
    companyIds: number[],
  ): Promise<CompanySubscribersList[]> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/billing-signature-groups`,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    if (this.isStatusOK(status)) {
      return response.data.map(CompanySubscribersList.make);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a listagem dos assinantes por empresa!');
  }

  public async getCompanySubscribers(
    group: number,
    id: number,
  ): Promise<CompanySubscribers> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${group}/billing-signature-groups/${id}`,
    );

    if (this.isStatusOK(status)) {
      return CompanySubscribers.make(response.data);
    }

    throw new Error('Ops, algo inesperado ocorreu na obtenção dos assinantes da empresa!');
  }

  public async getSignaturesByPreNegotiationId(
    companyGroupId: number,
    companyIds: number[],
    preNegotiationId: number,
  ): Promise<CompanySubscriberPreNegotiation[]> {
    const { data: request } = await this.datasource.get(`company-groups/${companyGroupId}/billing-signature-groups/pre-negotiations/${preNegotiationId}`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return request.data.map(CompanySubscriberPreNegotiation.make);
  }

  public async sendCompanySubscribers(
    group: number,
    record: Omit<CompanySubscribers, 'id'> & Omit<CompanySubscribers, 'name'>,
  ): Promise<void> {
    const { status } = await this.datasource.send(
      `company-groups/${group}/billing-signature-groups`,
      record,
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a criação das assinuturas da empresa!');
    }
  }

  public async updateCompanySubscribers(
    group: number,
    id: number,
    record: Omit<CompanySubscribers, 'id'> & Omit<CompanySubscribers, 'name'>,
  ): Promise<void> {
    const { status } = await this.datasource.update(
      `company-groups/${group}/billing-signature-groups/${id}`,
      record,
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a atualização das assinuturas da empresa!');
    }
  }

  public async destroyCompanySubscribers(
    group: number,
    id: number,
  ): Promise<void> {
    const { status } = await this.datasource
      .destroy(`company-groups/${group}/billing-signature-groups/${id}`);

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a exclusão dos assinantes da empresa!');
    }
  }

  public async getCompanies(group: number): Promise<Array<Company>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/companies`);

    if (this.isStatusOK(status)) {
      const companies: Array<Company> = response.data.map(Company.make);
      return companies;
    }

    throw new Error('Ops, algo inesperado ocorreu na listagem das empresas.');
  }
}
