









































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import LoginInformation from '@/views/login/components/LoginInformation.vue';
import LoginNewsList from '@/views/login/components/LoginNewsList.vue';
import LoginForm from '@/views/login/components/LoginForm.vue';

@Component({
  components: {
    LoginInformation,
    LoginNewsList,
    LoginForm,
  },
})
export default class Login extends Vue {
  get isMobileDeviceResolution(): boolean {
    return this.$vuetify.breakpoint.width < 960;
  }

  created(): void {
    this.$vuetify.theme.dark = false;
  }
}
