import EconomicGroupGroupingTypesEnum from '@/views/economicGroups/enums/EconomicGroupGroupingTypesEnum';
import StatusType from '@/domain/enums/StatusType';

export default class EconomicGroupList {
  constructor(
    public id: number,
    public status: StatusType,
    public name: string,
    public groupingType: EconomicGroupGroupingTypesEnum,
    public entityNames: string[],
  ) {}

  static make(data: any): EconomicGroupList {
    return new EconomicGroupList(
      data.id,
      data.status,
      data.name,
      data.grouping_type,
      data.entity_names,
    );
  }
}
