<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'grouper' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
	        </div>
	    </div>
	    <div class="m-portlet__body">
	        <!--begin: Search Form -->
	        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
	            <div class="row" id="frmGrouper">
	            	<input type="hidden" name="company_group_id" id="company_group_id" v-model="company_group_id">
	                <div class="col-md-12">
	                    <div class="form-group">
	                        <label>Empresa</label>
	                        <select class="form-control select2 hide" field-name="Empresa" id="company_id" name="company_id" style="width: 100%;">
	                            <option value="">Todas</option>
	                            <option v-for="(company, index) in companys" :value="company.id" :key="index">{{ company.name }}</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Tipo</label>
	                        <select class="form-control select2 hide" field-name="Tipo" id="type" name="type" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
	                            <option value="I">Saldo inicial</option>
	                            <option value="E">Entrada</option>
	                            <option value="S">Saída</option>
	                        </select>
	                    </div>
	                </div>
					<div class="col-md-2">
	                    <div class="form-group">
	                        <label>Código ERP</label>
	                        <input
								id="erp_code"
								class="form-control"
								name="erpCode"
								type="text"
								field-name="Código ERP"
								maxlength="15"	
							>
	                    </div>
					</div>
	                <div class="col-md-6">
	                    <div class="form-group">
	                        <label>Descrição</label>
	                        <input type="text" class="form-control" field-name="Descrição" id="description" name="description" required>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Tabela</label>
	                        <select class="form-control select2 hide" field-name="Tabela" id="table_type_id" name="table_type_id" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
								<option v-for="(table, index) in tables" :value="table.id" :key="index">{{ table.description }}</option>
	                        </select>
	                    </div>
	                </div>
					<div class="col-md-3">
						<div class="form_group">
							<label>Onde Mostrar?</label>
							<select class="form-control select2 hide" field-name="Onde Mostrar" id="where_show" required
									name="where_show" style="width: 100%;" multiple="multiple">
								<option value="1">Fluxo Previsto</option>
								<option value="2">Fluxo Realizado</option>
								<option value="4">Fluxo Projetado</option>
								<option value="3">Faturamento</option>
							</select>
						</div>
					</div>

	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
		import { API_BASE } from '@config/api'

    export default {
        data () {
            return {
            	companys: [],
				tables:[],
            	company_group_id: this.$session.get('company_group_id')
            }
        },

        mounted: function () {
            this.loadCompanys();
            this.loadMethod();
            this.loadTables();
        },

        methods: {
            loadCompanys: function () {
            	blockPage('Carregando os dados...');

                this.$http.get(API_BASE +'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	function (result) {
	                    this.companys = result.body.data
		            	unblockPage();
                	},
                	function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                ).bind(this)
            },

            loadMethod: function () {
            	var self = this;

				$(".select2").select2();
				$("#cnpj").mask("00.000.000/0000-00");
				$("#phone").mask("(00) 00000-0000");
				$("#zipcode").mask("0000-000");

				$("#addFilter").click(function(){
					var totFilters = 0, exit = false;

					if ($.trim($("#erp_field option:selected").text()) == 'Selecione...' || $.trim($("#erp_operator option:selected").text()) == 'Selecione...' || $.trim($("#expression").val()) == '') {
						exit = true;
					}

					if (exit) {
					    showNotification('Atenção', 'Todos os campos do filtro devem ser preenchidos!', 'warning');
					    return false;
					}

					$.each($('div', '#filters-iten'),function(){
					    var $this = $(this);

					    if($this.hasClass('divIten')){
					    	totFilters++;
					    }
					});

					totFilters = totFilters + 1;

					$("#filters-iten").append(
						'<div id="iten_'+totFilters+'" class="divIten row col-sm-12">'+
							'<div class="col-sm-4">'+$("#erp_field option:selected").text()+'</div>'+
							'<div class="col-sm-3" style="padding-left: 25px;">'+$("#erp_operator option:selected").text()+'</div>'+
							'<div class="col-sm-3" style="padding-left: 33px;">'+$("#expression").val()+'</div>'+
							'<div class="col-sm-2" style="text-align:right;"><i class="la la-times-circle" itemId="'+totFilters+'" style="cursor:pointer;"></i></div>'+
						'</div>'
					);
				});

				$(document).on('click', "i.la", function() {
					var $this = $(this);
					$("#iten_"+$this.attr('itemId')).remove();
				});

				$("#btn-save").click(function(){
					var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';
		            var btn = $(this);

		            btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

					$.each($('input, select', '#frmGrouper'),function(){
					    var $this = $(this);
                        var name = $this.attr('name');
                        fieldName = $this.attr('field-name');
					    if($.trim(this.value) == '' && $this.attr('required') == 'required'){
					        exitButton = true;
					        $(".form-group:has(#"+this.id+")").addClass('has-danger');
					        $("#"+this.id).addClass('input-error');
					        $("#"+this.id).css('border-color','red');
					        return false;
					    }else{
                            exitButton = false;
                            arrayData[i] = {};
                            if(name != "where_show"){
                                arrayData[i][this.id] = this.value;
                            }else{
                                arrayData[i][this.id] = $this.val().join(";");
                            }
					    }

					    i++;
					});

					if(exitButton){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
					    showNotification('Atenção', 'O campo <strong>'+fieldName+'</strong> não foi informado!', 'warning');
					    return false;
					}

					$.ajax({
						method: "POST",
						url: API_BASE+'grouper/new',
                        headers: {
                            'token': self.$session.get('token')
                        },
						data: {
							arrayData: JSON.stringify(arrayData)
						}
					}).done(function(result) {
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

						if(result.error == false){
							showNotification('Sucesso', 'Novo agrupador registrado!','success');

							self.$router.replace({name: 'grouper'});
							if (activeTab == 'tab1') {
								$.each($('input', '#frmGrouperN1'),function(){
									this.value = '';
								});
							} else {
								$.each($('input', '#frmGrouperN2'),function(){
									this.value = '';
								});
							}
						}else{
							showNotification('Atenção',result.error, 'danger');
							return false;
						}
					}).fail(function(error){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						console.log(error.responseText);
					});
				});

				$('input').keyup(function(){
					if ($("#"+this.id).hasClass('input-error')) {
						$("#"+this.id).removeClass('input-error');
						$("#"+this.id).css('border-color', '#ebedf2');
						$(".form-group:has(#"+this.id+")").removeClass('has-danger');
					}
				});
            },
            loadTables: function () {
                blockPage('Carregando os dados...');

                this.$http.get(API_BASE +'grouper/listTables', {headers: {'token': this.$session.get('token')}}).then(
                    function (result) {
                        this.tables = result.body.data
                        unblockPage();
                    },
                    function (error) {
                        showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                ).bind(this)
            },
        }
    }
</script>
