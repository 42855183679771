import StringHelper from '@/helpers/StringHelper';

export default class InputRules {
  public required(message: string = '') {
    return (v: unknown) => !!v || (message || 'Esse campo é obrigatório');
  }

  public requiredBoolean(message: string = '') {
    return (v: boolean) => typeof v === 'boolean' || (message || 'Esse campo é obrigatório');
  }

  public higherThen(size: number, isNumber: boolean = false, message: string = '') {
    return (v: string) => this.getCorrectValueLengthBasedOnType(v, isNumber) > size || (message || `O valor deve ser maior que ${size}`);
  }

  public higherOrEqualThen(size: number, isNumber: boolean = false, message: string = '') {
    return (v: string) => this.getCorrectValueLengthBasedOnType(v, isNumber) >= size || (message || `O valor deve ser maior ou igual que ${size}`);
  }

  public lowerThen(size: number, isNumber: boolean = false, message: string = '') {
    return (v: string) => this.getCorrectValueLengthBasedOnType(v, isNumber) < size || (message || `O valor deve ser menor que ${size}`);
  }

  public lowerOrEqualThen(size: number, isNumber: boolean = false, message: string = '') {
    return (v: string) => this.getCorrectValueLengthBasedOnType(v, isNumber) <= size || (message || `O valor deve ser menor ou igual que ${size}`);
  }

  public sizeEqualThen(size: number, message: string = '') {
    return (v: string) => v.length === size || (message || `O valor deve ter o tamanho igual a ${size}`);
  }

  public betweenThen(initialRange: number, finalRange: number, message: string = '') {
    return (v: number) => (v >= initialRange && v <= finalRange) || (message || `O valor deve estar entre ${initialRange} e ${finalRange}`);
  }

  public isEmail(hasMoreThenOneEmail: boolean = false, message: string = '') {
    const errorMessage = (message || 'Este campo não segue as regras de um email!');

    return (v: string | undefined | null) => {
      if (typeof v === 'undefined' || v === null) {
        return true;
      }

      if (hasMoreThenOneEmail) {
        const splitedMails = v.replaceAll(' ', '').split(';');

        return splitedMails
          .every((mail) => StringHelper.isEmail(mail)) || errorMessage;
      }

      return StringHelper.isEmail(v) || errorMessage;
    };
  }

  public hasArrayOfFilesHasSpecificType(type: string, message: string = '') {
    return (v: File[]) => {
      const invalidFiles = v.filter(({ type: fileType }) => fileType !== type);

      if (invalidFiles.length > 0) {
        return message || 'Um dos arquivos desse campo é de um tipo inválido.';
      }

      return true;
    };
  }

  public isNumber(message: string = '') {
    return (v: string) => {
      if (typeof v === 'undefined' || v === null) {
        return true;
      }

      const parsedNumber = Number(v);

      return (!Number.isNaN(parsedNumber) && typeof parsedNumber === 'number') || (message || 'O valor deve ser numérico!');
    };
  }

  public positive(message: string = '') {
    return (v: string) => {
      if (typeof v === 'undefined' || v === null) {
        return true;
      }

      return parseFloat(v) >= 0 || (message || 'O valor deve ser positivo!');
    };
  }

  private getCorrectValueLengthBasedOnType(
    value: string | undefined | null,
    isNumber: boolean,
  ): number {
    if (value === undefined || value === null) {
      return 0;
    }

    if (isNumber) {
      return parseInt(value, 10);
    }

    return value.length;
  }
}
