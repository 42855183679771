import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';

export default class FilterParameterWriteOffMovementList {
  public constructor(
    public initialDueDate: string,
    public endDueDate: string,
    public initialEmissionDate: string,
    public endEmissionDate: string,
    public number: string,
    public prefix: string,
    public customers: ISelectOptions<string>[],
    public isOpen: number,
    public types: string[],
    public value: string,
    public sort: string,
    public direction: boolean,
    public rawSort: string,
  ) {
  }

  public static make(data: any): FilterParameterWriteOffMovementList {
    const { direction, sort } = FilterParameterHelper
      .defineOrdenation(data.sort_write_off_movement);

    return new FilterParameterWriteOffMovementList(
      data.initial_due_date_write_off_movement ?? '',
      data.end_due_date_write_off_movement ?? '',
      data.initial_emission_date_write_off_movement ?? '',
      data.end_emission_date_write_off_movement ?? '',
      data.number_write_off_movement ?? '',
      data.prefix_write_off_movement ?? '',
      data.customers_write_off_movement ? JSON.parse(data.customers_write_off_movement) : [],
      data.is_open_write_off_movement ? parseFloat(data.is_open_write_off_movement) : 1,
      data.types_write_off_movement ? JSON.parse(data.types_write_off_movement) : [],
      data.value_write_off_movement ?? '',
      sort,
      direction,
      data.sort_write_off_movement,
    );
  }
}
