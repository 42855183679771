<template>
  <v-container class="token-handler-container">
    <v-container v-if="!component" class="full-height token-content">
      <v-row v-if="loading" justify="center" align="center" class="content-row">
        <v-progress-circular
          indeterminate
          class="mx-auto"
          size="48"
        ></v-progress-circular>
      </v-row>
      <v-row v-if="error" justify="center" align="center" class="content-row">
        <v-col xs="12" sm="6">
          <h2 class="text-center">
            <v-icon size="34" class="timer-icon">
              mdi-timer-off-outline
            </v-icon>
            {{ error }}
          </h2>
        </v-col>
      </v-row>
      <token-pages-footer />
    </v-container>
    <component :is="component" :token="token" />
  </v-container>
</template>

<script>
import TokenPagesFooter from '../../components/defaults/TokenPagesFooter.vue';
import PixBilling from '../pixBilling/PixBilling.vue';
import TicketDownload from '../ticketDownload/TicketDownload.vue';

const COMPONENT_TYPE_MAPPING = {
  1: 'PixBilling',
  2: 'TicketDownload',
};

export default {
  components: { PixBilling, TicketDownload, TokenPagesFooter },
  name: 'TokenHandler',
  data() {
    return {
      token: this.$route.params.token,
      component: null,
      error: false,
      loading: true,
    };
  },
  mounted() {
    this.validateToken();
  },
  methods: {
    validateToken() {
      this.$http.post(`${this.$store.state.API_BASE}token/check`, { token: this.token }).then(
        (result) => {
          const data = result.body;

          if (!data.type) {
            this.component = 'PixBilling';
            this.loading = false;
            return;
          }
          if (!Object.keys(COMPONENT_TYPE_MAPPING).indexOf(data.type)) {
            this.error = 'Esta URL é inválida, ';
            this.loading = false;
            return;
          }

          this.component = COMPONENT_TYPE_MAPPING[data.type];
          this.loading = false;
        },
        (error) => {
          const message = error.body?.data || 'Atenção! Este link é inválido ou já expirou!';

          this.error = message;
          this.loading = false;
        },
      );
    },
  },
};
</script>

<style>
.token-handler-container.container {
  min-height: 100vh;
  max-height: 100%;
  min-width: 100vw;
  max-width: 100%;
  padding: 0;
  margin: 0;
}
.token-handler-container .full-height {
  height: 100%;
}
.token-handler-container .token-content {
  min-width: 100%;
  margin: 0;
  padding: 0;
}
.token-handler-container .content-row {
  height: calc(100% - 80px);
  padding: 0;
  margin: 0;
}
</style>
