import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.files.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'T')?_c(VChip,{attrs:{"dense":"","small":"","color":"green","text-color":"white"}},[_vm._v(" Ativo ")]):_c(VChip,{attrs:{"dense":"","small":""}},[_vm._v(" Inativo ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'RECEBER')?_c(VChip,{attrs:{"dense":"","small":"","color":"green","text-color":"white"}},[_vm._v(" Receber ")]):_c(VChip,{attrs:{"dense":"","small":"","color":"error"}},[_vm._v(" Pagar ")])]}},{key:"item.date_record",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.date_record)))])]}},{key:"item.situation",fn:function(ref){
var item = ref.item;
return [_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.situation == 0),expression:"item.situation == 0"}],attrs:{"dense":"","small":""}},[_vm._v(" Recém importado ")]),_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.situation == 1),expression:"item.situation == 1"}],attrs:{"dense":"","small":"","color":"warning"}},[_vm._v(" Em importação ")]),_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.situation == 2),expression:"item.situation == 2"}],attrs:{"dense":"","small":"","color":"success"}},[_vm._v(" Enviado para o ERP ")]),_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.situation == 3),expression:"item.situation == 3"}],attrs:{"dense":"","small":"","color":"error"}},[_vm._v(" Problema ao enviar para o ERP ")])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('inn-icon-btn',{attrs:{"icon":"mdi-download"},on:{"click":function($event){return _vm.handleDownload(item.id)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }