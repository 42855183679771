


























import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ModuleCard from './components/ModuleCard.vue';

@Component({
  components: {
    ModuleCard,
  },
})
export default class ModuleList extends Vue {
  authStore?: AuthenticationModule;

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);
  }

  get companyGroupImage(): String {
    return (this.authStore && this.authStore.user.company_group_image) || '';
  }
}
