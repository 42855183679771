<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    persistent
  >
    <v-card>
      <v-card-title class="justify-center">
        Carregando
        <v-progress-circular indeterminate class="ml-4"></v-progress-circular>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InnLoadingDialog',
  data() {
    return {
      dialog: false,
      options: {
        width: 240,
        zIndex: 200,
      },
    };
  },
  methods: {
    start() {
      this.dialog = true;
    },
    stop() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
