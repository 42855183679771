import InstallmentChange from '@/domain/models/InstallmentChange';
import Repository from '@/repositories/base/Repository';

export default class InstallmentChangesRepository extends Repository {
  public async getInstallmentChangesByPreNegotiationId(
    companyGroupId: number,
    companyIds: number[],
    preNegotiationId: number,
  ): Promise<InstallmentChange[]> {
    const { data: request } = await this.datasource.get(`company-groups/${companyGroupId}/billings/pre-negotiations/installment-changes/${preNegotiationId}`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return request.data.map(InstallmentChange.make);
  }
}
