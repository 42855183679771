<style scoped>

</style>

<template>

    <div
            :class="classValue"
            style="width:100%;height:100%;padding-right:0;padding-left:0;" @mouseenter="showDrop = true" @mouseleave="showDrop = false"
            v-show="verifyShowDate(dataValue.date)">
        <i class="fa fa-comment-o" style="float: left;cursor: pointer;margin-left: 5px;" v-if="dataValue.have_comment"
           @click="showCommentModal(dataValue)"></i>
        <div style="padding-left: 20px;" class="dropdown" >
            <i class="fa fa-bars" id="dropdownMenuButton" data-toggle="dropdown"
               v-if="dataValue.value != 0 && identifyValue != 'BI' && showDrop"
               style="float: left;cursor: pointer;margin-left: 5px;"></i>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" v-if="dataValue.value != 0">
                <a class="dropdown-item" href="javascript:" style="padding: .3rem 1.2rem"
                   @click="showInformation(dataValue)"
                   v-if="dataValue.value != 0">Visualizar</a>
            </div>
        </div>

        <span>{{dataValue.value | formatNumber}}</span>


        <root-modal ref="commentModal" v-if="showModal" @close="showModal = false">
            <template slot="header">
                <h5 class="modal-title" id="title_data_view_information">Comentário</h5>
            </template>
            <template slot="body">
                <div class="modal-body">
                    <form class="m-form m-form--fit m-form--label-align-right">

                        <div class="row">
                            <div class="col-sm-2">&nbsp;</div>
                            <div class="col-sm-8">
                                <div class="form-group" style="text-align: left; font-size: 14px;">
                                    <label>Comentário:</label>
                                    <div class="input-group">
                                <textarea class="form-control m-input" rows="5" readonly
                                          style="margin-top: 0px; margin-bottom: 0px; height: 200px;">
                                {{comment}}
                                </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">&nbsp;</div>
                        </div>

                    </form>
                </div>
            </template>
            <template slot="footer">
                <div>
                    <button type="button" class="btn m-btn--air btn-primary" @click="$refs.commentModal.hide()"> Fechar
                    </button>
                </div>
            </template>
        </root-modal>


        <information-modal ref="informationModal" :information-data="informationData" v-if="showModalInformation"
                           @close="showModalInformation = false; showDrop = false" :tittle-modal-info="tittleModalInfo">

            <div slot="footer">
                <button type="button" class="btn m-btn--air btn-primary" @click="$refs.informationModal.hide()"> Fechar
                </button>
            </div>
        </information-modal>


    </div>


</template>

<script>
    import CommentModal from './CommentModal.vue';
    import InformationModal from './InformationModal.vue';
    import RootModal from './RootModal.vue'
    import {API_BASE} from '@config/api';
    
    export default {
        name: "DateValue",
        components: {CommentModal, InformationModal, RootModal},
        props: ['dataValue', 'classValue', 'cashFlowHiddenDates', 'identifyValue'],
        data() {
            return {
                showDrop: false,
                showModal: false,
                showModalInformation: false,
                comment: "",
                tittleModalInfo: "",
                informationData: undefined,
            }
        },
        created() {
            $(document).click(function (e) {
                var container = $(".dropdown-menu");

                //check if the clicked area is dropDown or not
                if (container.has(e.target).length === 0) {
                    $('.dropdown-menu').removeClass('show');
                }
            })
        },
        methods: {
            verifyShowDate(dateParam) {
                let self = this;
                let lShow = true;
                for (var i = 0; i < self.cashFlowHiddenDates.length; i++) {
                    if (self.cashFlowHiddenDates[i].dateHidden == dateParam) {
                        lShow = false;
                        break;
                    }
                }

                return lShow;

            },
            showCommentModal(dataValue) {
                let self = this;

                blockPage('Carregando Comentário...');
                $.ajax({
                    method: "POST",
                    url: API_BASE + 'internaltable/getcomment',
                    headers: {
                        'token': self.$session.get('token')
                    },
                    data: {
                        _identifyValue: self.identifyValue,
                        _date: dataValue.date,
                        _keyId: dataValue.key_id,
                    }
                }).done(function (result) {

                    self.comment = result.data.comment;
                    self.showModal = true;

                }).fail(function (error) {
                    showNotification('Atenção', 'Oops! Ocorreu um erro ao consultar o comentário.', 'danger');
                    console.log(error);
                }).always(function () {
                    unblockPage();
                    self.showDrop = false;
                });
            },
            showInformation(dataValue) {
                let self = this;
                let url = "";
                if (dataValue.value) {
                    blockPage('Carregando Dados do Título...');
                    switch (self.identifyValue) {
                        case 'CR':
                            self.tittleModalInfo = "Dados do Título a Receber";
                            url = API_BASE + 'view/tituloreceber/' + dataValue.internalTableId + '/' + dataValue.date + '/' + dataValue.levelTwoGrouperId + '/' + dataValue.internalTableIdMov;
                            break;
                        case 'CP':
                            self.tittleModalInfo = "Dados do Título a Pagar";
                            url = API_BASE + 'view/titulopagar/' + dataValue.internalTableId + '/' + dataValue.date + '/' + dataValue.levelTwoGrouperId + '/' + dataValue.internalTableIdMov;
                            break;
                        case 'MR':
                            self.tittleModalInfo = "Dados do Movimento a Receber";
                            url = API_BASE + 'view/movimentosemtitulo/' + dataValue.internalTableIdMov + '/' + dataValue.date + '/' + dataValue.levelTwoGrouperId;
                            break;
                        case 'MP':
                            self.tittleModalInfo = "Dados do Movimento a Pagar";
                            url = API_BASE + 'view/movimentosemtitulo/' + dataValue.internalTableIdMov + '/' + dataValue.date + '/' + dataValue.levelTwoGrouperId;
                            break;
                        default:
                    }

                    $.ajax({
                        method: 'GET',
                        url: url,
                        headers: {
                            'token': self.$session.get('token')
                        }
                    }).done(function (result) {
                        self.informationData = result.data;

                        self.showModalInformation = true;

                    }).fail(function (error) {
                        showNotification('Atenção', 'Oops! Ocorreu um erro ao consultar os dados.', 'danger');
                        console.log(error);
                    }).always(function () {
                        unblockPage();
                        self.showDrop = false;
                    });
                } else {
                    showNotification("Sem dados", "Selecione uma data com valor para visualização do título.", "info")
                }

            }
        }
    }
</script>

