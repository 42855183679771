





















































































































































import {
  Component,
  Mixins,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { API_BASE } from '@config/api';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import FileHelper from '@/helpers/FileHelper';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import { VForm } from '@/types/VForm';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import DateInputHelper from '@/helpers/DateInputHelper';
import RangeDate from '@/types/RangeDate';
import SectorRepository from '@/repositories/SectorRepository';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import ClientRepository from '@/repositories/ClientRepository';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import DialogHelper from '@/helpers/DialogHelper';

@Component
export default class GenerateTitlesReleasedByRangeDateDialog extends Mixins<
  ModelManagementMixin<boolean>
>(ModelManagementMixin) {
  public definedDate: string[] = [];
  public formattedDate: Partial<RangeDate> = {};

  public filterNumber: string|null = null;

  public selectedCompanies: Array<number> = [];

  public selectedCustomer: number|null = null;
  public customers: Array<ISelectOptions> = [];
  public searchCustomer: string = '';
  public loadingCustomerField: Boolean = false;

  public selectedSectors: Array<number> = [];
  public sectors: Array<ISelectOptions> = [];
  public searchSector: string = '';
  public loadingSectorField: Boolean = false;

  public selectedDistricts: Array<number> = [];
  public districts: Array<ISelectOptions> = [];
  public searchDistrict: string = '';
  public loadingDistrictField: Boolean = false;

  public fee: number|null = null;

  public loading: boolean = false;

  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly sectorRepository: SectorRepository = new SectorRepository();
  private readonly clientRepository: ClientRepository = new ClientRepository();
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get dialogWidth(): string {
    return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
      smAndDown: '100%',
      md: '60%',
      default: '40%',
    });
  }

  public get companyIds(): Array<number> {
    return this.companyOptions.map((company) => company.value);
  }
  @Prop({
    type: String,
    required: true,
  }) readonly idCustomer!: string;

  @Prop({
    type: Array,
    required: false,
    default: [],
  }) readonly companyOptions!: ISelectOptions<number>[];

  @Ref('form') readonly form!: VForm;

  requeridMultipleSelect = (value: Array<any>) => value.length > 0 || 'Esse campo é obrigatório';

  public getSpreadsheetDownloadPath(): string {
    const { initialDate, finalDate } = this.formattedDate;

    let spreadsheetDownloadPath = API_BASE;
    spreadsheetDownloadPath += `company-groups/${this.companyGroupId}/`;
    spreadsheetDownloadPath += `orders/download/released/customer/${this.selectedCustomer}`;
    spreadsheetDownloadPath += `?token=${this.$session.get('token')}`;
    spreadsheetDownloadPath += `&company_id=${this.companyIds[0]}`;
    spreadsheetDownloadPath += `&fee=${this.fee ?? 0}`;
    spreadsheetDownloadPath += `&initial_date=${initialDate}&final_date=${finalDate}`;

    if (this.selectedCompanies) {
      this.selectedCompanies.forEach((selectedCompanie) => {
        spreadsheetDownloadPath += `&companies[]=${selectedCompanie}`;
      });
    }

    if (this.selectedSectors) {
      this.selectedSectors.forEach((selectedSector) => {
        spreadsheetDownloadPath += `&sectors[]=${selectedSector}`;
      });
    }

    if (this.selectedDistricts) {
      this.selectedDistricts.forEach((selectedDistrict) => {
        spreadsheetDownloadPath += `&districts[]=${selectedDistrict}`;
      });
    }

    if (this.filterNumber) {
      spreadsheetDownloadPath += `&number=${this.filterNumber}`;
    }

    return spreadsheetDownloadPath;
  }

  public formatCompanyIdsToQueryStringParameter(companyIds: number[]): string {
    let formattedString = '';

    for (let i = 0; i < companyIds.length; i += 1) {
      formattedString += `&company_id=${companyIds[i]}`;
    }

    return formattedString;
  }

  public async handleSelectDateRange(): Promise<void> {
    await this.$nextTick();

    const formattedDate = DateInputHelper.formatRangeDate(this.definedDate);

    if (formattedDate === null) {
      this.$notification.warn('O range de data está inválido!');

      return;
    }

    this.formattedDate = formattedDate;
  }

  public closeDialog(reloadScreen: boolean = false): void {
    this.$emit('on-close', reloadScreen);
  }

  public handleCancel(): void {
    this.closeDialog();
  }

  public async handleConfim(): Promise<void> {
    if (!this.form.validate()) return;

    if (!this.formattedDate.initialDate
      && !this.formattedDate.finalDate
    ) {
      this.$notification.error('O campo de data está inválido.');

      return;
    }

    this.$dialog.startLoading();

    try {
      FileHelper.downloadFileByObjectUrl(this.getSpreadsheetDownloadPath(), `${this.selectedCustomer}.xslx`);

      this.handleCancel();
    } catch (error) {
      this.$notification.error('Houve um problema ao realizar o download da planilia.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async handleOnFinishedDebounceClient(search: string) {
    try {
      if (this.loadingCustomerField || !search) {
        return;
      }

      this.loadingCustomerField = true;

      const tableConfigParams: ILaravelOrdenationTableParams = {
        filter: { search: search.replace(/[^\w\s]+/g, '') },
        sort: 'companyName',
        page: 1,
        items_per_page: 25,
      };
      const { data } = await this.clientRepository
        .getAllClients(this.companyGroupId, this.companyIds, tableConfigParams);

      this.customers = data.map(({ id, companyName, cnpj }) => ({
        value: id,
        text: `${companyName} - ${cnpj}`,
      }));
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar os clientes.');

      this.$notification.error(errorMessage);
    } finally {
      this.loadingCustomerField = false;
    }
  }

  public async handleOnFinishedDebounceSector(search: string) {
    try {
      if (this.loadingSectorField || search === '') {
        return;
      }

      this.loadingSectorField = true;

      this.sectors = await this.sectorRepository.getSectors(
        this.companyGroupId,
        this.companyIds,
        search,
      );
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar os setores.');

      this.$notification.error(errorMessage);
    } finally {
      this.loadingSectorField = false;
    }
  }

  public async handleOnFinishedDebounceDistrict(search: string) {
    try {
      if (this.loadingDistrictField || search === '') {
        return;
      }

      this.loadingDistrictField = true;

      this.districts = await this.sectorRepository.getDistricts(
        this.companyGroupId,
        this.companyIds,
        search,
      );
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar os distritos.');

      this.$notification.error(errorMessage);
    } finally {
      this.loadingDistrictField = false;
    }
  }
}
