

































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { kebabCase } from '@/utils';

@Component
export default class PageTabsContent extends Vue {
  public tab: any = null;
  public kebabCase: any = kebabCase;

  @Prop(Array) readonly tabs !: Array<any> | null;
}

