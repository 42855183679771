




















































































































import {
  Component,
  Ref,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RuleGroupBlock from '@/views/creditRules/components/RuleGroupBlock.vue';
import SpecificCreditRuleBuilder from '@/views/creditRules/components/SpecificCreditRuleBuilder.vue';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import CreditRuleRepository from '@/repositories/CreditRuleRepository';
import CreditTotalizersRepository from '@/repositories/CreditTotalizersRepository';
import CreditRuleBuilderSupport from '@/views/creditRules/support/CreditRuleBuilderSupport';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import { VForm } from '@/types/VForm';
import CreditRuleActionType from '@/views/creditRules/types/CreditRuleActionType';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import ICreditRuleBuilder from '@/views/creditRules/interfaces/ICreditRuleBuilder';
import ICreditRuleGroup from '@/views/creditRules/interfaces/ICreditRuleGroup';
import ICreditRuleGroupWithOptionsInString from '@/views/creditRules/interfaces/ICreditRuleGroupWithOptionsInString';
import IErpCreditRuleAndFilterGroup from '@/views/creditRules/interfaces/IErpCreditRuleAndFilterGroup';
import ISelectOptionsWithCompany from '@/views/creditRules/interfaces/ISelectOptionsWithCompany';

@Component({
  components: {
    SpecificCreditRuleBuilder,
    RuleGroupBlock,
  },
})
export default class ErpCreditRuleBuilder extends Vue {
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();
  private readonly creditRuleRepository: CreditRuleRepository = new CreditRuleRepository();
  private readonly creditTotalizersRepository:
    CreditTotalizersRepository = new CreditTotalizersRepository();
  private readonly creditRuleBuilderSupport:
    CreditRuleBuilderSupport = new CreditRuleBuilderSupport();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public typeOfVerification: ISelectOptions<string>;
  public authorizationOfRule: ISelectOptions<string>;
  public active: ISelectOptions<string>;

  public variableOptions: ISelectOptionsWithCompany<number>[] = [];
  public totalizerOptions: ISelectOptionsWithCompany<string>[] = [];

  public specificRuleGroupBuilder: ICreditRuleBuilder[] = [];
  public rulesGroupBuilderToEdit: ICreditRuleBuilder[] = [];

  public actualActionDefinedOnRuleGroupBlock: 'credit-rule' | 'credit-filter' | 'edit-error-message' | null = null;

  public creditRule: ICreditRuleGroup|null = null;
  public creditFilter: Omit<ICreditRuleGroup, 'errorMessageObject'>|null = null;

  private readonly correlationBetweenRuleTypeAndActualRuleToEdit = {
    'credit-rule': 'creditRule',
    'credit-filter': 'creditFilter',
  } as const;

  public typeOfVerificationOptions: ISelectOptions<string>[] = [
    { value: 'order', text: 'Pedido' },
    { value: 'orderItems', text: 'Itens do pedido' },
  ];
  public authorizationOfRuleOptions: ISelectOptions<string>[] = [
    { value: 'permissive', text: 'Permissiva' },
    { value: 'restricted', text: 'Restritiva' },
  ];
  public statusOptions: ISelectOptions<string>[] = [
    { value: 'active', text: 'Ativo' },
    { value: 'inactive', text: 'Desabilitado' },
  ];

  public get showUpsertCreditRuleBuilderButton(): boolean {
    return this.getActualActionToDoInCreditRuleBuilder !== null;
  }

  public get getUpsertCreditRuleButtonText(): string {
    if (!this.showUpsertCreditRuleBuilderButton) {
      return '';
    }

    const definedTypeText = {
      'credit-rule': 'REGRA DE CRÉDITO',
      'credit-filter': 'FILTRO DE CRÉDITO',
      'insert-error-message': 'MENSAGEM DE ERRO',
      'update-error-message': 'MENSAGEM DE ERRO',
    }[this.getActualActionToDoInCreditRuleBuilder!];

    let definedTypeOfAction = 'ADICIONAR';

    if (this.actualActionDefinedOnRuleGroupBlock !== null) {
      definedTypeOfAction = 'ATUALIZAR';
    }

    return `${definedTypeOfAction} ${definedTypeText}`;
  }

  public get getWhatIsEditingToSpecificCreditRuleBuilder(): 'credit-rule' | 'credit-filter' | 'error-message' | null {
    if (
      this.getActualActionToDoInCreditRuleBuilder === 'insert-error-message'
      || this.getActualActionToDoInCreditRuleBuilder === 'update-error-message'
    ) {
      return 'error-message';
    }

    return this.getActualActionToDoInCreditRuleBuilder;
  }

  public get getActualActionToDoInCreditRuleBuilder(): CreditRuleActionType|null {
    if (
      this.creditRule === null
      || (
        this.creditRule !== null
        && this.actualActionDefinedOnRuleGroupBlock === 'credit-rule'
      )
    ) {
      return 'credit-rule';
    }

    if (
      this.creditRule.authorization.value === 'restricted'
      && this.creditRule.errorMessageObject === undefined
    ) {
      return 'insert-error-message';
    }

    if (
      (
        this.creditFilter === null
        && this.actualActionDefinedOnRuleGroupBlock !== 'edit-error-message'
      ) || (
        this.creditFilter !== null
        && this.actualActionDefinedOnRuleGroupBlock === 'credit-filter'
      )
    ) {
      return 'credit-filter';
    }

    if (this.actualActionDefinedOnRuleGroupBlock === 'edit-error-message') {
      return 'update-error-message';
    }

    return null;
  }

  public constructor() {
    super();

    ({ 0: this.typeOfVerification } = this.typeOfVerificationOptions);
    ({ 0: this.authorizationOfRule } = this.authorizationOfRuleOptions);
    ({ 0: this.active } = this.statusOptions);
  }

  @Ref('ErpCreditRuleHeader') readonly erpCreditRuleHeader!: VForm;

  @Ref('SpecificCreditRuleBuilder') readonly specificCreditRuleBuilder!: { resetAll: () => void };

  @Prop({
    type: Array,
    required: false,
    default: () => ([]),
  }) readonly selectedCompanies!: number[];

  @Prop({
    type: Object,
    required: false,
    default: () => null,
  }) readonly definedRulesJson!: IErpCreditRuleAndFilterGroup|null;

  @Watch('selectedCompanies')
  public async handleCompanyChange(companyIds: number[]): Promise<void> {
    if (companyIds.length < 1) {
      this.variableOptions = [];
      this.totalizerOptions = [];

      return;
    }

    await Promise.all([
      this.getTotalizers(companyIds),
      this.getVariables(companyIds),
    ]);
  }

  public async created(): Promise<void> {
    if (this.selectedCompanies.length > 0) {
      await Promise.all([
        this.getTotalizers(this.selectedCompanies),
        this.getVariables(this.selectedCompanies),
      ]);
    }

    if (this.definedRulesJson !== null) {
       this.creditRule = this.formatReceivedRuleGroups(this.definedRulesJson.rule, 'credit-rule')!;
       this.creditFilter = this.formatReceivedRuleGroups(this.definedRulesJson.filter, 'credit-filter');
    }
  }

  public formatReceivedRuleGroups(
    creditRule: ICreditRuleGroupWithOptionsInString|null,
    type: CreditRuleActionType,
  ): ICreditRuleGroup|null {
    if (creditRule === null) {
      return null;
    }

    const formattedCreditRuleGroup: ICreditRuleGroup = {
      ...this.getCompleteOptionsFromValue(
        creditRule.typeOfVerification,
        creditRule.authorization,
        creditRule.active,
      ),
      rules: creditRule.rules.map((rule) => this.formatRuleBuilderWithPresentableText(rule)),
    };

    if (type === 'credit-rule' && creditRule.errorMessageObject) {
      formattedCreditRuleGroup.errorMessageObject = creditRule.errorMessageObject
        .map((error) => this.formatRuleBuilderWithPresentableText(error));
    }

    return formattedCreditRuleGroup;
  }

  public formatRuleBuilderWithPresentableText(
    creditRule: ICreditRuleBuilder,
  ): ICreditRuleBuilder {
    const formattedCreditRule = this.creditRuleBuilderSupport
      .adjustValuesToTotalizerPatern(creditRule, this.totalizerOptions);

    const text = this.creditRuleBuilderSupport.getFormattedTextOfSpecificRuleValue(
      formattedCreditRule,
      this.totalizerOptions,
      this.variableOptions,
    );

    return {
      ...creditRule,
      text,
    };
  }

  public async getVariables(companyIds: number[]): Promise<void> {
    this.$dialog.startLoading();

    try {
      const variables = await this.creditRuleRepository.getVariablesFromCompanyId(
        parseInt(this.authenticationModule.user.company_group_id, 10),
        companyIds,
      );

      this.variableOptions = variables.map((variable) => ({
        value: variable.id,
        text: `${variable.companyId} - ${variable.name}`,
        companyId: variable.companyId,
      }));
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as variáveis!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getTotalizers(companyIds: number[]): Promise<void> {
    this.$dialog.startLoading();

    try {
      const totalizers = await this.creditTotalizersRepository.getTotalizersFromCompanyIds(
        parseInt(this.authenticationModule.user.company_group_id, 10),
        companyIds,
        'options',
      );

      this.totalizerOptions = totalizers.map((totalizer) => ({
        value: `${totalizer.companyId}-${totalizer.id}`,
        text: `${totalizer.companyId} - ${totalizer.name}`,
        companyId: totalizer.companyId,
      }));
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os totalizadores!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getCreditRules(): IErpCreditRuleAndFilterGroup|boolean {
    if (this.creditRule === null) {
      this.$notification.error('É obrigatório inserir uma regra de crédito!');

      return false;
    }

    if (
      this.creditRule.authorization.value === 'restricted'
      && (
        this.creditRule.errorMessageObject === undefined
        || this.creditRule.errorMessageObject.length < 1
      )
    ) {
      this.$notification.error('Em caso de a regra de crédito ser \'Restritiva\' é obrigatório ter mensagem de erro definida!');

      return false;
    }

    return {
      rule: this.getFormattedCreditRuleGroup(this.creditRule, 'credit-rule')!,
      filter: this.getFormattedCreditRuleGroup(this.creditFilter, 'credit-filter'),
    };
  }

  public getFormattedCreditRuleGroup(
    creditRule: ICreditRuleGroup|null,
    type: CreditRuleActionType,
  ): ICreditRuleGroupWithOptionsInString|null {
    if (creditRule === null) {
      return null;
    }

    const { typeOfVerification, authorization, active } = this.getOptionsValueFromObject(
      creditRule.typeOfVerification,
      creditRule.authorization,
      creditRule.active,
    );

    const rules = this.creditRuleBuilderSupport
        .getCreditRuleBuilderFormattedWithoutText(creditRule.rules);

    const definedCreditRuleGroupObject: ICreditRuleGroupWithOptionsInString = {
      typeOfVerification,
      authorization,
      active,
      rules,
    };

    if (type === 'credit-rule' && creditRule.errorMessageObject !== undefined) {
      definedCreditRuleGroupObject.errorMessageObject = this.creditRuleBuilderSupport
        .getCreditRuleBuilderFormattedWithoutText(creditRule.errorMessageObject);
    }

    return definedCreditRuleGroupObject;
  }

  public getOptionsValueFromObject(
    typeOfVerification: ISelectOptions<string>,
    authorization: ISelectOptions<string>,
    active: ISelectOptions<string>,
  ): {
    typeOfVerification: string;
    authorization: string;
    active: string;
  } {
    const findedTypeOfVerification = this.typeOfVerificationOptions
      .find(({ value }) => value === typeOfVerification.value)!;

    const findedAuthorization = this.authorizationOfRuleOptions
      .find(({ value }) => value === authorization.value)!;

    const findedActive = this.statusOptions.find(({ value }) => value === active.value)!;

    return {
      typeOfVerification: findedTypeOfVerification.value,
      authorization: findedAuthorization.value,
      active: findedActive.value,
    };
  }

  public getCompleteOptionsFromValue(
    typeOfVerification: string,
    authorization: string,
    active: string,
  ): {
    typeOfVerification: ISelectOptions<string>,
    authorization: ISelectOptions<string>,
    active: ISelectOptions<string>,
  } {
    const formattedTypeOfVerification = this.typeOfVerificationOptions
      .find(({ value }) => value === typeOfVerification)!;

    const formattedAuthorization = this.authorizationOfRuleOptions
      .find(({ value }) => value === authorization)!;

    const formattedActive = this.statusOptions.find(({ value }) => value === active)!;

    return {
      typeOfVerification: formattedTypeOfVerification,
      authorization: formattedAuthorization,
      active: formattedActive,
    };
  }

  public getFormattedActionToRuleGroupBlock(type: 'credit-rule' | 'credit-filter'): 'update-credit-rules-group' | 'insert-error-message' | 'update-error-message' | null {
    if (type === 'credit-rule' && this.actualActionDefinedOnRuleGroupBlock === 'credit-rule') {
      return 'update-credit-rules-group';
    }

    if (type === 'credit-filter' && this.actualActionDefinedOnRuleGroupBlock === 'credit-filter') {
      return 'update-credit-rules-group';
    }

    if (
      type === 'credit-rule'
      && (
        this.getActualActionToDoInCreditRuleBuilder === 'insert-error-message'
        || this.getActualActionToDoInCreditRuleBuilder === 'update-error-message'
      )
    ) {
      return this.getActualActionToDoInCreditRuleBuilder;
    }

    return null;
  }

  public handleUpsertCreditRuleGroupOrErrorMessage(): void {
    if (!this.erpCreditRuleHeader.validate()) {
      this.$notification.error('Um dos campos cabeçalho de regra estão invalidos!');

      return;
    }

    if (
      this.specificRuleGroupBuilder.length < 1
      && (
        this.getActualActionToDoInCreditRuleBuilder !== 'update-error-message'
        || (
          this.getActualActionToDoInCreditRuleBuilder === 'update-error-message'
          && this.creditRule
          && this.creditRule.authorization.value !== 'permissive'
          )
      )
    ) {
      this.$notification.error('No mínimo uma regra deve ser cadastrada para salvar esse grupo de regra de crédito!');

      return;
    }

    if (this.getActualActionToDoInCreditRuleBuilder === null) {
      this.$notification.error('Nenhuma ação foi definida!');

      return;
    }

    if (
      this.getActualActionToDoInCreditRuleBuilder === 'credit-rule'
      || this.getActualActionToDoInCreditRuleBuilder === 'credit-filter'
    ) {
      this.setValuesOnCreditRuleGroupOrFilter(this.getActualActionToDoInCreditRuleBuilder);

      return;
    }

    if (['insert-error-message', 'update-error-message'].includes(this.getActualActionToDoInCreditRuleBuilder)) {
      this.setValuesOnErrorMessage();
    }
  }

  public setValuesOnCreditRuleGroupOrFilter(type: 'credit-rule' | 'credit-filter'): void {
    const typeKeyActionToFindCreditRuleField = this.actualActionDefinedOnRuleGroupBlock !== null && this.actualActionDefinedOnRuleGroupBlock !== 'edit-error-message'
      ? this.actualActionDefinedOnRuleGroupBlock : type;

    const creditRuleTypeKeyToUpsert = this
      .correlationBetweenRuleTypeAndActualRuleToEdit[typeKeyActionToFindCreditRuleField];

    const creditRuleGroupObject: ICreditRuleGroup = {
      typeOfVerification: this.typeOfVerification,
      authorization: this.authorizationOfRule,
      active: this.active,
      rules: this.specificRuleGroupBuilder,
    };

    if (type === 'credit-rule' && this.creditRule !== null) {
      creditRuleGroupObject.errorMessageObject = this.creditRule.errorMessageObject;
    }

    this[creditRuleTypeKeyToUpsert] = creditRuleGroupObject;

    this.resetFields();
  }

  public setValuesOnErrorMessage(): void {
    if (this.creditRule === null) {
      this.$notification.error('Não há nenhuma regra de crédito cadastrada para inserir a mensagem de erro!');

      return;
    }

    this.creditRule = {
      ...this.creditRule,
      errorMessageObject: this.specificRuleGroupBuilder,
    };

    this.resetFields();
  }

  public resetFields(): void {
    this.actualActionDefinedOnRuleGroupBlock = null;

    this.specificRuleGroupBuilder = [];
    this.rulesGroupBuilderToEdit = [];

    this.specificCreditRuleBuilder.resetAll();
    this.erpCreditRuleHeader.resetValidation();
  }

  public handleUpdateCreditRuleOrFilter(action: 'credit-rule' | 'credit-filter') {
    if (this.actualActionDefinedOnRuleGroupBlock === action) {
      this.resetFields();

      return;
    }

    const creditRuleTypeKeyToUpsert = this
      .correlationBetweenRuleTypeAndActualRuleToEdit[action];

    this.actualActionDefinedOnRuleGroupBlock = action;
    this.typeOfVerification = this[creditRuleTypeKeyToUpsert]!.typeOfVerification;
    this.authorizationOfRule = this[creditRuleTypeKeyToUpsert]!.authorization;
    this.active = this[creditRuleTypeKeyToUpsert]!.active;
    this.rulesGroupBuilderToEdit = this[creditRuleTypeKeyToUpsert]!.rules
      .map((rule) => ({ ...rule }));
  }

  public handleRemoveCreditRuleOrFilter(type: 'credit-rule' | 'credit-filter') {
    if (type === 'credit-rule' && this.actualActionDefinedOnRuleGroupBlock === 'edit-error-message') {
      this.$notification.error('Não é possível remover a regra de crédito (Edição da mensagem de erro deve ser finalizada).');

      return;
    }

    if (this.actualActionDefinedOnRuleGroupBlock === type) {
      this.actualActionDefinedOnRuleGroupBlock = null;
    }

    const creditRuleTypeKeyToUpsert = this
      .correlationBetweenRuleTypeAndActualRuleToEdit[type];

    this[creditRuleTypeKeyToUpsert] = null;
  }

  public handleSpecificRuleGroupBuilderChange(
    specificRuleGroupBuilder: ICreditRuleBuilder[],
  ): void {
    this.specificRuleGroupBuilder = specificRuleGroupBuilder;
  }

  public handleUpdateErrorMessage(): void {
    if (this.actualActionDefinedOnRuleGroupBlock === 'edit-error-message') {
      this.resetFields();

      return;
    }

    this.resetFields();
    this.actualActionDefinedOnRuleGroupBlock = 'edit-error-message';

    if (this.creditRule!.errorMessageObject !== undefined) {
      this.rulesGroupBuilderToEdit = this.creditRule!.errorMessageObject
        .map((rule) => ({ ...rule }));
    }
  }
}
