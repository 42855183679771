































































































































import {
  Component,
  Prop,
  Mixins,
} from 'vue-property-decorator';
import { toCurrency } from '@/utils/';

import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import PayrollSelectItem from '@/domain/models/PayrollSelectItem';
import IPayrollSelectFilter from '@/domain/interfaces/IPayrollSelectFilter';

@Component
export default class PayrollSelectList
  extends Mixins<ModelManagementMixin<Array<PayrollSelectItem>>>(ModelManagementMixin) {
  @Prop({
    type: Array as () => Array<PayrollSelectItem>,
    required: true,
    default: [],
  }) readonly items!: Array<PayrollSelectItem>;

  @Prop({
    type: Number,
    required: true,
    default: 0,
  }) readonly serverItemsLength!: number;

  @Prop({
    type: Object as () => IPayrollSelectFilter,
    required: true,
  }) readonly data!: IPayrollSelectFilter;

  @Prop({
    type: Function as () => void,
    required: true,
  }) readonly onChangePage!: () => {};

  @Prop({
    type: Function as () => void,
    required: true,
  }) readonly onChangeItemsPerPage!: () => {};

  @Prop({
    type: Function as () => void,
    required: true,
  }) readonly onChangeSortBy!: () => {};

  @Prop({
    type: Function as () => void,
    required: true,
  }) readonly onChangeSortDesc!: () => {};

  headers: Array<IVDataTableHeader> = [
    { text: 'Título', value: 'title', sortable: false },
    { text: 'Fornecedor', value: 'supplier', sortable: false },
    { text: 'Dt. Emissão', value: 'emissionDate', sortable: true },
    { text: 'Dt. Vencimento', value: 'dueDate', sortable: true },
    { text: 'Valor', value: 'value', sortable: true },
  ];

  options = {
    page: this.data.page ?? 1,
    itemsPerPage: this.data.itemsPerPage ?? 0,
    sortBy: [this.data.sortBy],
    sortDesc: [this.data.sortDesc],
  };

  footerProps = {
    'items-per-page-options': [50, 100, 150],
  }

  get getSelectedLength() : number {
    return this.model.length;
  }

  get getSelectedTotalValue() : string {
    return toCurrency(this.model
      .reduce((previous, current) => previous + current.value, 0), {}, true);
  }

  get getItemsTotalValue() : string {
    return toCurrency(this.items
      .reduce((previous, current) => previous + current.value, 0), {}, true);
  }

  handleSelecteds(): void {
    if (this.model.length < 1) {
      this.$notification.warn('Nenhum título selecionado!');
      return;
    }

    this.$router.push({
      name: 'PayrollUpload',
      // @ts-ignore
      params: { accountsPayable: this.model },
    });
  }

  formatTitle(item: PayrollSelectItem): string {
    let title: string = '';

    title += item.branch ? item.branch : '';
    title += item.name ? `${title.length ? ' - ' : ''}${item.name}` : '';
    title += item.prefix ? `${title.length ? ' - ' : ''}${item.prefix}` : '';
    title += item.number ? `${title.length ? ' - ' : ''}${item.number}` : '';
    title += item.installment ? `${title.length ? ' - ' : ''}${item.installment}` : '';
    title += item.type ? `${title.length ? ' - ' : ''}${item.type}` : '';

    return title;
  }

  formatSupplier(item: PayrollSelectItem): string {
    let supplier: string = '';

    supplier += item.supplierCode ? item.supplierCode : '';
    supplier += item.supplierName ? `${supplier.length ? ' - ' : ''}${item.supplierName}` : '';
    supplier += item.suppplierDocument ? `${supplier.length ? ' - ' : ''}${item.suppplierDocument}` : '';

    return supplier;
  }

  formatDate(value: string): string {
    return value.split('-').reverse().join('/');
  }

  formatCurrency(value: number): string {
    return toCurrency(value, {}, true);
  }
}
