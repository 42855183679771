
































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { pluralize, toCurrency } from '@/utils';
import { formatToSpecificFormat } from '@/utils/date';
import { API_BASE } from '@config/api';
import InclusionSecurityDialog from '@/views/clients/components/InclusionSecurityDialog.vue';
import ColorsHelper from '@/helpers/ColorsHelper';
import DataTableHelper from '@/helpers/DataTableHelper';
import DateInputHelper from '@/helpers/DateInputHelper';
import FileHelper from '@/helpers/FileHelper';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import RequestExceptionHelper from '@/helpers/RequestExceptionHelper';
import ChangeDueDateDialog from '@/views/clients/components/ChangeDueDateDialog.vue';
import InvoiceChart from '@/components/charts/InvoiceChart.vue';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import TotalCalculator from '@/views/clients/components/TotalCalculator.vue';
import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';
import GenerateBilletsEmailFieldDialog from '@/views/clients/components/GenerateBilletsEmailFieldDialog.vue';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import AccountReceivableBankSlipRepository from '@/repositories/AccountReceivableBankSlipRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientModule from '@/stores/modules/ClientModule';
import ClientFinancialChartValues from '@/views/clients/types/ClientFinancialChartValues';
import LooseObjectType from '@/types/LooseObjectType';
import OnRequestTableOptions from '@/types/OnRequestTableOptions';
import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import FilterParameterAccountReceivableClient from '@/domain/models/filter-parameters/FilterParameterAccountReceivableClient';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import AccountReceivable from '@/domain/models/AccountReceivable';
import VMenuOptions from '@/types/VMenuOptions';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import PrepareFileToDownloadException from '@/helpers/exceptions/PrepareFileToDownloadException';
import InvalidResponseException from '@/repositories/exceptions/InvalidResponseException';
import IChartLabels from '@/views/clients/interfaces/IChartLabels';
import IButtonOption from '@/plugins/inn-design/components/buttons/interfaces/IButtonOption';
import IFiltersToConsiderOnFinancialStatement from '@/views/clients/interfaces/IFiltersToConsiderOnFinancialStatement';
import ISortAndDirectionDataTable from '@/helpers/interfaces/ISortAndDirectionDataTable';
import AccountReceivableStatusEnum from '@/views/clients/enums/AccountReceivableStatusEnum';
import AccountsReceivableHistoryDialog from '@/views/clients/components/AccountsReceivableHistoryDialog.vue';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import AccountReceivableChart from '@/domain/models/AccountReceivableChart';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import AccountsReceivableDetailsDialog from '@/views/clients/components/AccountsReceivableDetailsDialog.vue';
import ConfirmSendingBillingEmailDialog from '@/views/clients/components/ConfirmSendingBillingEmailDialog.vue';

@Component({
  components: {
    InvoiceChart,
    ButtonFilterMenuOptions,
    TotalCalculator,
    DynamicColumnMenu,
    InclusionSecurityDialog,
    ChangeDueDateDialog,
    GenerateBilletsEmailFieldDialog,
    AccountsReceivableHistoryDialog,
    AccountsReceivableDetailsDialog,
    ConfirmSendingBillingEmailDialog,
  },
})
export default class FinancialStatement extends Vue {
  private readonly filterParametersRepository: FilterParametersRepository =
    new FilterParametersRepository();
  private readonly accountReceivableBankSlipRepository: AccountReceivableBankSlipRepository =
    new AccountReceivableBankSlipRepository();
  private readonly accountsReceivableRepository: AccountsReceivableRepository =
    new AccountsReceivableRepository();
  private readonly authenticationModule: AuthenticationModule =
    getModule(AuthenticationModule);
  private readonly preNegotiationModule: PreNegotiationModule =
    getModule(PreNegotiationModule);
  private readonly clientModule: ClientModule = getModule(ClientModule);
  public readonly documentFormattation = DocumentFormattation;

  public totalItems: number = 0;
  public averageTime: number = 0;
  public averageDelay: number = 0;
  public definedFee: number = 0;
  public lastBilling: string = '-';

  public groupFiltersHasBeenSet: boolean = false;
  public loadingTable: boolean = false;
  public loadingChart: boolean = false;
  public isInclusionSecurityDialogOpen: boolean = false;
  public isChangeDueDateDialogOpen: boolean = false;
  public isAccountsReceivableHistoryDialogOpen: boolean = false;
  public isSendBilletsByMailDialogOpen: boolean = false;
  public isGenerateTitlesReleasedByDateDialogOpen: boolean = false;
  public isAccountsReceivableDetailsDialogOpen: boolean = false;
  public confirmSendingBillingEmail: boolean = false;
  public isSelectingAllSecurities: boolean = false;
  public selectedAccountReceivableToShowHistory: AccountReceivable|null = null;
  public selectedAccountReceivableToShowDetails: AccountReceivable|null = null;

  public search: string = '';
  public definedDateFilterOption: string = '';

  public defaultDateFilterOption: VMenuOptions | null = null;
  public dataTableOptions: DataOptions =
    DataTableHelper.getDefaultDataTableOptions();
  public footerOptions: DataTableFooterOptions =
    DataTableHelper.getDefaultFooterProps();
  public filtersToConsider: IFiltersToConsiderOnFinancialStatement = {};
  public chartValues: ClientFinancialChartValues = {
    totalExpired: 0,
    totalToExpire: 0,
    totalPaidOut: 0,
  };
  public headerKeysObject: LooseObjectType<string> = {
    number: 'data_table_account_receivable_show_column_number',
    order: 'data_table_account_receivable_show_column_order',
    emissionDate: 'data_table_account_receivable_show_column_emission_date',
    issueDate: 'data_table_account_receivable_show_column_issue_date',
    document: 'data_table_account_receivable_show_column_document',
    officialDueDate:
      'data_table_account_receivable_show_column_official_due_date',
    delayDays: 'data_table_account_receivable_show_column_delay_days',
    value: 'data_table_account_receivable_show_column_value',
    balance: 'data_table_account_receivable_show_column_balance',
    status: 'data_table_account_receivable_show_column_status',
    titleDecreaseDate:
      'data_table_account_receivable_show_column_title_decrease_date',
    fees: 'data_table_account_receivable_show_column_fees',
    paidValue: 'data_table_account_receivable_show_column_paid_value',
  };

  public rangeDate: string[] = [];
  public items: AccountReceivable[] = [];
  public selectedSecurities: AccountReceivable[] = [];
  public dateFilterOptions: VMenuOptions[] = [
    { value: 'date_emission', text: 'Data de Emissão' },
    { value: 'date_actual_expiration', text: 'Vencimento Real' },
  ];
  public titlesActionOptions: IButtonOption[] = [
    { value: 'download_duplicate_billet', text: 'Baixar 2º via do boleto' },
    { value: 'print_duplicate_billet', text: 'Imprimir 2º via do boleto' },
    { value: 'send_billet_by_mail', text: 'Enviar 2º via do boleto por e-mail' },
    { value: 'increase_decrease', text: 'Acréscimo/Decréscimo' },
    { value: 'change_due_date', text: 'Alterar data de vencimento' },
    { value: 'accounts_by_email', text: 'Enviar títulos por e-mail' },
    { value: 'download_spreadsheet', text: 'Gerar planilha' },
  ];

  public labels: IChartLabels = {
    paidOut: true,
    toExpired: true,
    expired: true,
  };

  public availableHeaders: IVDataTableHeader[] = [
    { text: 'Número', value: 'number', show: true },
    { text: 'Pedido', value: 'order', show: true },
    { text: 'Dt. Emissão', value: 'emissionDate', show: true },
    { text: 'Vencimento', value: 'issueDate', show: true },
    { text: 'CNPJ', value: 'document', show: true },
    { text: 'Vencimento Real', value: 'officialDueDate', show: true },
    { text: 'Código do Banco', value: 'bankCode', show: true },
    {
      text: 'Dias Atraso',
      value: 'delayDays',
      show: true,
      sortable: false,
    },
    { text: 'Valor', value: 'value', show: true },
    { text: 'Saldo', value: 'balance', show: true },
    { text: 'Juros', value: 'fees', show: true },
    { text: 'Multa', value: 'penalty', show: true },
    { text: 'Valor Pago', value: 'paidValue', show: true },
    {
      text: 'Status',
      value: 'status',
      show: true,
      sortable: false,
    },
    { text: 'Data Pagto', value: 'titleDecreaseDate', show: true },
  ];

  public get formattedChartValues(): Object {
    const { totalExpired, totalToExpire, totalPaidOut } = this.chartValues;

    return {
      labels: [
        `Vencido: R$ ${toCurrency(totalExpired, {}, true)}`,
        `A vencer: R$ ${toCurrency(totalToExpire, {}, true)}`,
        `Pago: R$ ${toCurrency(totalPaidOut, {}, true)}`,
      ],
      datasets: [
        {
          label: 'Valor',
          data: [totalExpired, totalToExpire, totalPaidOut],
          backgroundColor: ['#E9637B', '#9994D5', '#0EB799'],
          borderWidth: 0,
        },
      ],
    };
  }

  public get getInfoBlockColorBasedOnTheme(): string {
    return ColorsHelper.getBaseBlockColorBasedOnTheme(this.$vuetify.theme.dark);
  }

  public get getTextPriceValuesColor(): string {
    return ColorsHelper.getTextValuesColorBasedOnTheme(
      this.$vuetify.theme.dark,
    );
  }

  public get origin(): 'document' | 'customer' | 'credit' {
    return (this.$route.params.type ?? 'credit') as 'document' | 'customer' | 'credit';
  }

  public get idCustomerOrDocument(): string {
    if (this.origin === 'credit') {
      return this.$route.params.idCustomer;
    }

    return this.$route.params.idCustomerOrDocument;
  }

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get companyId(): number {
    return this.clientModule.client.companyId!;
  }

  public get headers(): IVDataTableHeader[] {
    const headersToShow = this.availableHeaders.filter((h) => h.show);

    if (this.isOnLowWidthSizeDataTable) {
      return headersToShow;
    }

    return headersToShow.concat(
      {
        text: '',
        align: 'center',
        value: 'actions',
        sortable: false,
      },
      {
        text: '',
        align: 'center',
        value: 'headerManagement',
        sortable: false,
      },
    );
  }

  public get hasSelectedSecurities(): boolean {
    return this.selectedSecurities.length > 0 || this.isSelectingAllSecurities;
  }

  public get selectedSecuritiesIdsCustomer(): string[] {
    return this.selectedSecurities.map(
      ({ accountReceivableCustomerId }) => accountReceivableCustomerId,
    );
  }

  public get isOnLowWidthSizeDataTable(): boolean {
    return this.$vuetify.breakpoint.width < 1466;
  }

  public get getTitlesActionOptionsWithDisabledProperty(): IButtonOption[] {
    return this.titlesActionOptions
      .map((titleActionOption) => ({
        text: titleActionOption.text,
        value: titleActionOption.value,
        disabled: !this.hasSelectedSecurities,
      }));
  }

  public get actualSortAndDirection(): ISortAndDirectionDataTable {
    return {
      sort: this.dataTableOptions.sortBy[0],
      direction: this.dataTableOptions.sortDesc[0],
    };
  }

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    if (!this.groupFiltersHasBeenSet) {
      return;
    }

    this.getFinancialValues();
  }

  public async created(): Promise<void> {
    await this.getGroupFilterParameters();
  }

  public async getGroupFilterParameters(): Promise<void> {
    try {
      this.loadingTable = true;
      this.loadingChart = true;
      this.$dialog.startLoading();

      const filterParameters = await this.filterParametersRepository.getFilterByGroup(
          GroupFilterParametersEnum.ACCOUNT_RECEIVABLE_CLIENT_LIST,
        );
      const formattedFilters = FilterParameterAccountReceivableClient.make(filterParameters);

      this.$dialog.stopLoading();
      await this.applyFiltersOnActualPage(formattedFilters);
    } catch (error) {
      this.$dialog.stopLoading();
      this.$notification.error(
        'Houve um problema ao requisitar os filtros dessa tela!',
      );
    }
  }

  public getAverageTime(): boolean | string {
    return pluralize(this.averageTime, 'dia', 'dias');
  }

  public getAverageDelay(): boolean | string {
    return pluralize(this.averageDelay, 'dia', 'dias');
  }

  public async getFinancialValues(
    shouldUpdateChart: boolean = false,
    onRequestTableOptions: OnRequestTableOptions = {},
  ): Promise<void> {
    if (this.groupFiltersHasBeenSet && (this.loadingTable || this.loadingChart)) {
      return;
    }

    const requests = [
      this.getPaginatedAccountsReceivableListByFilters(onRequestTableOptions),
    ];

    if (shouldUpdateChart) {
      requests.push(this.getAccountsReceivableChartValuesByFilters());
    }

    await Promise.all(requests);

    this.groupFiltersHasBeenSet = true;
  }

  public async getPaginatedAccountsReceivableListByFilters(
    onRequestTableOptions: OnRequestTableOptions = {},
  ): Promise<void> {
    try {
      this.loadingTable = true;

      if (onRequestTableOptions.resetToFirst) {
        this.dataTableOptions.page = 1;
      }

      const { sort, direction } = this.actualSortAndDirection;
      const tableConfigParams = {
        filter: this.filtersToConsider,
        sort: DataTableHelper.formatTableOrdenationColumn(direction, sort),
        page: this.dataTableOptions.page,
        items_per_page: this.dataTableOptions.itemsPerPage,
      };

      if (this.origin === 'credit') {
        this.filterParametersRepository.setFilter(
          GroupFilterParametersEnum.ACCOUNT_RECEIVABLE_CLIENT_LIST,
          [
            {
              key: 'date_type_to_search_account_receivable_list',
              value: this.definedDateFilterOption,
            },
            {
              key: 'date_to_search_account_receivable_list',
              value: JSON.stringify(this.rangeDate),
            },
            {
              key: 'actual_page_account_receivable_list',
              value: this.dataTableOptions.page,
            },
            {
              key: 'rows_per_page_account_receivable_list',
              value: this.dataTableOptions.itemsPerPage,
            },
            {
              key: 'sort_table_account_receivable_list',
              value: tableConfigParams.sort,
            },
          ],
        );
      }

      const { data, meta } = await this
        .getAccountsReceivableListFromCustomer(tableConfigParams);

      this.items = data;
      this.totalItems = meta.total;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os títulos do cliente.');
    } finally {
      this.loadingTable = false;
    }
  }

  public async getAccountsReceivableChartValuesByFilters(): Promise<void> {
    try {
      this.loadingChart = true;

      const data = await this.getAccountsReceivableChartFromCustomer();

      this.chartValues = {
        totalExpired: data.totalExpired,
        totalToExpire: data.totalToExpire,
        totalPaidOut: data.totalPaidOut,
      };
      this.averageTime = data.averageMaturity;
      this.averageDelay = data.averageDelay;
      this.lastBilling = data.lastBilling;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as informações de gráfico dos títulos');
    } finally {
      this.loadingChart = false;
    }
  }

  public async getAccountsReceivableListFromCustomer(
    params: ILaravelOrdenationTableParams<IFiltersToConsiderOnFinancialStatement>,
  ): Promise<DataWithPaginationInfo<AccountReceivable[]>> {
    console.log(this.idCustomerOrDocument);
    return this.accountsReceivableRepository
      .getAccountsReceivablePaginatedListByFilters(
        this.companyGroupId,
        this.authenticationModule.companyIds,
        this.idCustomerOrDocument,
        params,
      );
  }

  public async getAccountsReceivableChartFromCustomer(): Promise<AccountReceivableChart> {
    return this.accountsReceivableRepository.getAccountsReceivableChartsByFilters(
        this.companyGroupId,
        this.authenticationModule.companyIds,
        this.idCustomerOrDocument,
        this.filtersToConsider,
      );
  }

  public async getBilletFromSelectedSecurities(
    action: 'download_duplicate_billet' | 'print_duplicate_billet',
  ): Promise<void> {
    this.$dialog.startLoading();

    try {
      const blobFile = await this.accountReceivableBankSlipRepository
        .downloadAccountReceivableBankSlip(
            this.companyGroupId,
            this.companyId,
            {
              securities_ids_customer: this.selectedSecuritiesIdsCustomer,
              is_selecting_all_securities: Number(this.isSelectingAllSecurities) as 0 | 1,
              id_customer: this.idCustomerOrDocument,
            },
          );

      const base64File = await FileHelper.blobToBase64(blobFile);

      if (action === 'download_duplicate_billet') {
        FileHelper.downloadFileByObjectUrl(base64File, 'segunda_via_boleto');

        return;
      }

      await FileHelper.openPrintScreenOfPdf(blobFile);
    } catch (error) {
      let errorMessage = '';

      if (
        error instanceof InvalidResponseException
        || error instanceof PrepareFileToDownloadException
      ) {
        errorMessage = error.message;
      } else {
        const errorObject = await RequestExceptionHelper
          .extractBodyFromRequestException(error, true);
        errorMessage = errorObject.message;
      }

      this.$notification.error(errorMessage);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getSpreadsheetDownloadPath(): string {
    let spreadsheetDownloadPath = API_BASE;
    spreadsheetDownloadPath += 'company-groups/';
    spreadsheetDownloadPath += `${this.companyGroupId}/`;
    spreadsheetDownloadPath += 'accounts-receivable/spreadsheet/download/';
    spreadsheetDownloadPath += `${this.idCustomerOrDocument}/`;
    spreadsheetDownloadPath += `?token=${this.$session.get('token')}`;
    spreadsheetDownloadPath += `&company_id=${this.companyId}`;
    spreadsheetDownloadPath += `&fee=${this.definedFee}`;

    if (this.filtersToConsider.number) {
      spreadsheetDownloadPath += `&number=${this.filtersToConsider.number}`;
    }

    if (
      this.filtersToConsider.start_date_actual_expiration
      && this.filtersToConsider.end_date_actual_expiration
    ) {
      spreadsheetDownloadPath += `&start_date_actual_expiration=${this.filtersToConsider.start_date_actual_expiration}`;
      spreadsheetDownloadPath += `&end_date_actual_expiration=${this.filtersToConsider.end_date_actual_expiration}`;
    }

    if (
      this.filtersToConsider.start_date_emission
      && this.filtersToConsider.end_date_emission
    ) {
      spreadsheetDownloadPath += `&start_date_emission=${this.filtersToConsider.start_date_emission}`;
      spreadsheetDownloadPath += `&end_date_emission=${this.filtersToConsider.end_date_emission}`;
    }

    if (this.filtersToConsider.consider_only) {
      spreadsheetDownloadPath += `&consider_only[expired]=${this.filtersToConsider.consider_only.expired}`;
      spreadsheetDownloadPath += `&consider_only[paid_out]=${this.filtersToConsider.consider_only.paid_out}`;
      spreadsheetDownloadPath += `&consider_only[to_expired]=${this.filtersToConsider.consider_only.to_expired}`;
    }

    const { sort, direction } = this.actualSortAndDirection;
    spreadsheetDownloadPath += `&sort=${DataTableHelper.formatTableOrdenationColumn(direction, sort)}`;

    if (this.hasSelectedSecurities) {
      for (let i = 0; i < this.selectedSecuritiesIdsCustomer.length; i += 1) {
        const securitieIdCustomer = this.selectedSecuritiesIdsCustomer[i];

        spreadsheetDownloadPath += `&securitiesIdsCustomer[]=${securitieIdCustomer}`;
      }
    }

    return spreadsheetDownloadPath;
  }

  public getDelayDaysColorBasedOnStatus(accountReceivable: AccountReceivable): string {
    if (accountReceivable.status === AccountReceivableStatusEnum.due) {
      return 'red--text text--lighten-1';
    }

    if (
      accountReceivable.status === AccountReceivableStatusEnum.paid
      && accountReceivable.delayDays > 0
    ) {
      return 'blue--text text--lighten-1';
    }

    return '';
  }

  public async downloadSpreadsheetFromSelectedAccounts(): Promise<void> {
    this.$dialog.startLoading();

    try {
      FileHelper.downloadFileByObjectUrl(this.getSpreadsheetDownloadPath(), `${this.idCustomerOrDocument}.xslx`);
    } catch (error) {
      this.$notification.error('Houve um problema ao realizar o download da planilia.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async sendAccountsReceivableToCustomerEmail(): Promise<void> {
    try {
      this.$dialog.startLoading();

      await this.accountsReceivableRepository.sendAccountsReceivableToCustomerMails(
        this.companyGroupId,
        this.companyId,
        {
          is_selecting_all_securities: this.isSelectingAllSecurities,
          securities_ids_customer: this.selectedSecuritiesIdsCustomer,
          id_customer: this.idCustomerOrDocument,
        },
      );

      this.$notification.success('Email enviado a fila!');
      this.unselectSecurities();
    } catch (error) {
      const errorMessage = 'Houve um problema ao fazer download do boleto bancário!';
      this.$notification.error(errorMessage);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getChipColor(status: AccountReceivableStatusEnum): string {
    switch (status) {
      case AccountReceivableStatusEnum.expire: return 'purple';
      case AccountReceivableStatusEnum.due: return 'red';
      case AccountReceivableStatusEnum.paid: return 'success';
      default: return 'green';
    }
  }

  public getStatusText(status: AccountReceivableStatusEnum): string {
    switch (status) {
      case AccountReceivableStatusEnum.expire: return 'À vencer';
      case AccountReceivableStatusEnum.due: return 'Vencido';
      case AccountReceivableStatusEnum.paid: return 'Pago';
      default: return 'Nenhum';
    }
  }

  public getMoneyFormat(
    num: number,
    dashIfIsZero: boolean = true,
  ): string | number {
    if (num > 0) {
      return toCurrency(num, {}, true);
    }

    return dashIfIsZero ? '-' : 0;
  }

  public applyFiltersOnActualPage(
    filters: FilterParameterAccountReceivableClient,
  ): void {
    this.dataTableOptions.sortBy = filters.tableSort;
    this.dataTableOptions.sortDesc = filters.tableDirection;
    this.dataTableOptions.itemsPerPage = filters.rowsPerPage;
    this.dataTableOptions.page = filters.actualPage;

    let definedOption = FilterParameterHelper.getFormattedColumnObjectOfOptionsButton(
        this.dateFilterOptions,
        filters.dateTypeToSearch,
      );

    if (definedOption === null) {
      [definedOption] = this.dateFilterOptions;
    }

    this.defaultDateFilterOption = definedOption;
    this.definedDateFilterOption = definedOption.value;
    this.rangeDate = filters.dateToSearch;

    this.availableHeaders = FilterParameterHelper.defineColumnsToShowOnSpecificHeader(
        this.availableHeaders,
        filters.columnsToShow,
      );

    this.defineFiltersToFinancialTable();
  }

  public setDateToBrazilianFormat(date: string | null): string {
    if (date === null) {
      return '//';
    }

    return formatToSpecificFormat(date, 'dd/MM/yyyy');
  }

  public async defineFiltersToFinancialTable(): Promise<void> {
    await this.$nextTick();

    const definedFilters: LooseObjectType = {};
    const formattedRangeDate = DateInputHelper.formatRangeDate(this.rangeDate);

    if (formattedRangeDate === null) {
      this.$notification.warn('O range de data está inválido!');

      return;
    }

    if (this.search) {
      definedFilters.number = this.search;
    }

    if (formattedRangeDate.initialDate) {
      definedFilters[`start_${this.definedDateFilterOption}`] = formattedRangeDate.initialDate;
    }

    if (formattedRangeDate.finalDate) {
      definedFilters[`end_${this.definedDateFilterOption}`] = formattedRangeDate.finalDate;
    }

    if (this.labels) {
      definedFilters.consider_only = {
        to_expired: Number(this.labels.toExpired),
        expired: Number(this.labels.expired),
        paid_out: Number(this.labels.paidOut),
      };
    }

    this.filtersToConsider = definedFilters;

    this.getFinancialValues(true, { resetToFirst: true });
  }

  public showSecurityInterationDialog(
    selectedOption: 'increase_decrease' | 'change_due_date',
  ): void {
    if (selectedOption === 'increase_decrease') {
      this.isInclusionSecurityDialogOpen = true;

      return;
    }

    this.isChangeDueDateDialogOpen = true;
  }

  public showAccountsReceivableHistoryDialog(accountsReceivable: AccountReceivable) {
    this.selectedAccountReceivableToShowHistory = accountsReceivable;

    this.isAccountsReceivableHistoryDialogOpen = true;
  }

  public showAccountsReceivableDetailsDialog(accountsReceivable: AccountReceivable) {
    this.isAccountsReceivableDetailsDialogOpen = true;

    this.selectedAccountReceivableToShowDetails = accountsReceivable;
  }

  public unselectSecurities(): void {
    this.selectedSecurities = [];
    this.isSelectingAllSecurities = false;
  }

  public handleSearch(search: string): void {
    this.search = search;

    this.defineFiltersToFinancialTable();
  }

  public async handleSelectDateRange(): Promise<void> {
    this.defineFiltersToFinancialTable();
  }

  public handleSelectDateFilterOption(
    selectedDateFilter: VMenuOptions | null,
  ): void {
    let definedDateOption = '';

    if (selectedDateFilter !== null) {
      definedDateOption = selectedDateFilter.value;
    }

    this.definedDateFilterOption = definedDateOption;
    this.defineFiltersToFinancialTable();
  }

  public handleDialogClose(reloadScreen: boolean): void {
    if (reloadScreen) {
      this.getFinancialValues(true, { resetToFirst: true });
      this.unselectSecurities();
    }
    this.selectedAccountReceivableToShowHistory = null;

    this.isInclusionSecurityDialogOpen = false;
    this.isChangeDueDateDialogOpen = false;
    this.isSendBilletsByMailDialogOpen = false;
    this.isGenerateTitlesReleasedByDateDialogOpen = false;
    this.isAccountsReceivableHistoryDialogOpen = false;
    this.isAccountsReceivableDetailsDialogOpen = false;
  }

  public handleTitleActionOptions(
    action:
      | 'download_duplicate_billet'
      | 'print_duplicate_billet'
      | 'send_billet_by_mail'
      | 'increase_decrease'
      | 'change_due_date'
      | 'accounts_by_email'
      | 'download_spreadsheet',
  ): void {
    if (action === 'accounts_by_email') {
      this.sendAccountsReceivableToCustomerEmail();

      return;
    }

    if (action === 'send_billet_by_mail') {
      this.isSendBilletsByMailDialogOpen = true;

      return;
    }

    if (action === 'download_duplicate_billet' || action === 'print_duplicate_billet') {
      this.getBilletFromSelectedSecurities(action);

      return;
    }

    if (action === 'download_spreadsheet') {
      this.downloadSpreadsheetFromSelectedAccounts();

      return;
    }

    this.showSecurityInterationDialog(action);
  }

  public handleSelectAll(): void {
    this.isSelectingAllSecurities = !this.isSelectingAllSecurities;
    this.selectedSecurities = [];
  }

  public handleWithExcludedLabelsFromChart(labels: IChartLabels) {
    this.labels = labels;

    this.defineFiltersToFinancialTable();
  }

  public handleFeesChange(fee: number): void {
    this.definedFee = fee;
  }
}
