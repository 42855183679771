











import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import IStatusIcon from '@/types/IStatusIcon';

@Component
export default class TableStatusIcon extends Vue {
  @Prop({
    required: true,
  }) readonly code!: string | number;

  @Prop({ type: Array, required: true }) list!: Array<IStatusIcon>;

  get status(): IStatusIcon {
    if (!this.list || !this.list.length) {
      return {
        code: 0,
        text: 'Lista de status não informada!',
        icon: 'mdi-alert-circle-outline',
        color: 'error',
      };
    }

    if (this.code === undefined) {
      return {
        code: 0,
        text: 'Status não informado!',
        icon: 'mdi-alert-circle-outline',
        color: 'error',
      };
    }

    return this.list.find((item) => (item.code == this.code)) || {} as IStatusIcon;
  }
}
