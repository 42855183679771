
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ICompanyActivePair from '../types/ICompanyActivePair';

@Component
export default class CompanyItem extends Vue {
  active: boolean = false;

  @Prop() companyPair!: ICompanyActivePair;

  public handleActiveChange(isActive: boolean): void {
    this.$emit('update:active', this.companyPair.company.id, isActive);
  }

  created() {
    this.active = this.companyPair.active;
  }
}
