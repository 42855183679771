import IFilter from '@/domain/interfaces/IInstructionsRemittanceFilter';
import IOption from '@/domain/interfaces/ISelectOptions';
import AccountReceivableDetail from '@/domain/models/AccountReceivableDetail';
import Bank from '@/domain/models/Bank';
import BillingMethod from '@/domain/models/BillingMethod';
import Company from '@/domain/models/Company';
import Customer from '@/domain/models/Customer';
import FinancialDocumentSituation from '@/domain/models/FinancialDocumentSituation';
import InstructionRemittance from '@/domain/models/InstructionRemittance';
import Occurrence from '@/domain/models/Occurrence';

import Repository from '@/repositories/base/Repository';

export default class InstructionsRemittanceRepository extends Repository {
  async getCompanies(): Promise<Array<IOption<number>>> {
    const { status, data: response } = await this.datasource.get('company/listAllActv');

    if (status == 200) {
      if (response.error) throw new Error(`Empresas: ${response.message}`);

      const companies: Array<Company> = response.data.map(Company.make);

      const selectOptions = companies.map((company) => {
        const { id, name } = company;
        return { value: id, text: name };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das companhias do grupo.');
  }

  async getBanks(groupId: number): Promise<Array<IOption<string>>> {
    const { status, data: response } = await this.datasource.get(`bank/listAll/${groupId}`);

    if (status == 200) {
      if (response.error) throw new Error(`Bancos: ${response.message}`);

      const banks: Array<Bank> = response.data.map(Bank.make);

      const options: Array<IOption<string>> = banks
        .map(({
          code,
          agency,
          account,
          name,
          idCustomer,
        }) => ({
          text: `${code} - ${agency} - ${account} - ${name}`,
          value: idCustomer,
        }));

      return options;
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem dos bancos.');
  }

  async getWallets(groupId: number): Promise<Array<IOption<string>>> {
    const { status, data: response } = await this.datasource.get(
      `financialdocumentsituation/listActives/${groupId}/0`,
    );

    if (status == 200) {
      if (response.error) throw new Error(`Tipos de Carteira: ${response.message}`);

      const wallets: Array<FinancialDocumentSituation> = response.data.map(
        FinancialDocumentSituation.make,
      );

      const options = wallets
        .map(({ code, description }) => ({ value: code, text: `${code} - ${description}` }));

      return options;
     }

    throw new Error('Ops, algo inesperado aconteceu na listagem dos tipos de carteira.');
  }

  async getMethods(groupId: number): Promise<Array<IOption<string>>> {
    const { status, data: response } = await this.datasource.get(
      `billingmethod/listAll/${groupId}`,
    );

    if (status == 200) {
      if (response.error) throw new Error(`Formas de Recebimento: ${response.message}`);

      const methods: Array<BillingMethod> = response.data.map(BillingMethod.make);

      const options = methods
        .map(({ code, description }) => ({ value: code, text: `${code} - ${description}` }));

      return options;
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem das forma de recebimento.');
  }

  async getOccurrences(
    groupId: number,
    company: Array<number>,
    search: string,
  ): Promise<Array<IOption<string>>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/accounts-receivable/instructions/occurrences`,
      {
        headers: {
          'company-ids': JSON.stringify(company),
        },
        params: {
          search,
          limit: 10,
        },
      },
    );

    if (status == 200) {
      const occurrences: Array<Occurrence> = response.data.map(Occurrence.make);

      const options = occurrences
        .map(({ code, description }) => ({ value: code, text: `${code} - ${description}` }));

      return options;
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem das ocorrências.');
  }

  async getCustomers(
    groupId: number,
    company: Array<number>,
    search: string,
  ): Promise<Array<IOption<string>>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/customers/search/like`,
      {
        headers: {
          'company-ids': JSON.stringify(company),
        },
        params: {
          search,
          limit: 10,
        },
      },
    );

    if (status == 200) {
      const customers: Array<Customer> = response.data.map(Customer.make);

      const selectOptions = customers.map((customer) => {
        const {
          id, name, code, store,
        } = customer;
        return { value: id, text: `${code} ${store} - ${name}` };
      });

      return selectOptions;
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem dos clientes.');
  }

  async list(groupId: number, filters: IFilter):
    Promise<Record<string, Array<InstructionRemittance>>> {
    const { company_id, ...params } = filters;

    const { status, data: response } = await this.datasource
      .get(`company-groups/${groupId}/accounts-receivable/instructions`, {
        headers: {
          'company-ids': JSON.stringify(company_id),
        },
        params,
      });

    if (status == 200) {
      const instructions = Object.keys(response.data)
        .reduce((acc, key) => ({
          ...acc,
          [key]: response.data[key].map(InstructionRemittance.make),
        }), {} as Record<string, Array<InstructionRemittance>>);

      return instructions;
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem das instruções.');
  }

  async getOccurrence(groupId: number, id: number, companyId: number): Promise<Occurrence[]> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/accounts-receivable/instructions/${id}/logs`,
      {
        headers: {
          'company-ids': JSON.stringify([companyId]),
        },
      },
    );

    if (status == 200) {
      return (response.data as []).map(Occurrence.make);
    }

    throw new Error('Ops, algo inesperado aconteceu na obtenção da ocorrência.');
  }

  async getDetails(groupId: number, id: number, companyId: number):
  Promise<AccountReceivableDetail> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${groupId}/accounts-receivable/instructions/${id}`,
      {
        headers: {
          'company-ids': JSON.stringify([companyId]),
        },
      },
    );

    if (status == 200) {
      const details = AccountReceivableDetail.make(response.data);

      return details;
    }

    throw new Error('Ops, algo inesperado aconteceu na obtenção dos detalhes.');
  }

  async send(groupId: number, company: Array<number>, data: object): Promise<any> {
    const { status, data: response } = await this.datasource.send(
      `company-groups/${groupId}/accounts-receivable/instructions/send`,
      data,
      {
        headers: {
          'company-ids': JSON.stringify(company),
        },
      },
    );

    if (status >= 200 && status < 300) {
      return response;
    }

    throw new Error('Ops, algo inesperado ocorreu no envio das instruções.');
  }

  async changeType(
    groupId: number,
    idCustomer: string,
    id: number,
    company: Array<number>,
    data: object,
  ): Promise<any> {
    const { status, data: response } = await this.datasource.patch(
      `company-groups/${groupId}/accounts-receivable/instructions/${id}`,
      data,
      {
        headers: {
          'company-ids': JSON.stringify(company),
        },
      },
    );

    if (status >= 200 && status < 300) {
      return response;
    }

    throw new Error('Ops, algo inesperado ocorreu na alteração do tipo de instrução.');
  }
}
