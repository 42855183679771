














































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { validateDateRange } from '@/utils/date';
import { formatErrorForNotification } from '@/utils/error';

import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import IOption from '@/domain/interfaces/ISelectOptions';

import EGroupFilterParameters from '@/domain/enums/GroupFilterParametersEnum';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import FilterParameterPayrollSelectList from '@/domain/models/filter-parameters/FilterParameterPayrollSelectList';

import IPayrollSelectFilter from '@/domain/interfaces/IPayrollSelectFilter';
import PayrollRepository from '@/repositories/PayrollRepository';

interface DateRangeError {
  emission: boolean;
  due: boolean;
  return: boolean;
  messageInitial: string;
  messageEnd: string;
}

@Component
export default class PayrollSelectFilter extends Vue {
  @Ref('filter') readonly filter!: VForm;

  readonly filterParametersRepository: FilterParametersRepository =
    new FilterParametersRepository();
  readonly payrollRepository: PayrollRepository = new PayrollRepository();
  readonly paymentRemittanceRepository: PaymentRemittanceRepository =
    new PaymentRemittanceRepository();

  valuePrefix: boolean = false;

  searchSuppliers: string = '';
  searchPrefixes: string = '';
  loadingSuppliers: boolean = false;
  loadingPrefixes: boolean = false;
  timerSuppliers!: ReturnType<typeof setTimeout>;
  timerPrefixes!: ReturnType<typeof setTimeout>;
  setSuppliers = new Set<string>();

  allCompanies: Array<number> = [];

  prefixes: Array<IOption<string>> = [];
  companies: Array<IOption<number>> = [];
  suppliers: Array<IOption<string>> = [];

  data: IPayrollSelectFilter = {
    initialIssueDate: '',
    endIssueDate: '',
    initialDueDate: '',
    endDueDate: '',
    companies: [],
    suppliers: [],
    number: null,
    value: null,
    page: 1,
    itemsPerPage: 100,
    prefixes: [],
    sortBy: undefined,
    sortDesc: undefined,
  };

  errorDateRange: DateRangeError = {
    emission: false,
    due: false,
    return: false,
    messageInitial: 'Data inicial deve ser menor ou igual a data final.',
    messageEnd: 'Data final deve ser menor ou igual a data inicial.',
  };

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  get suppliersNoDataText(): string {
    if (!this.searchSuppliers) return 'Digite para buscar fornecedores.';

    return !this.loadingSuppliers
      ? 'Nenhum resultado encontrado.'
      : 'Carregando...';
  }

  get prefixesNoDataText(): string {
    if (!this.searchPrefixes) return 'Digite para buscar prefixos.';

    return !this.loadingSuppliers
      ? 'Nenhum resultado encontrado.'
      : 'Carregando...';
  }

  mounted() {
    this.loadOptions();
  }

  togglePrefix(event: FocusEvent, prefix: boolean, value: number | null) {
    if (event.type === 'focus' && prefix !== true) {
      return true;
    }

    if (event.type === 'blur' && value === null) {
      return false;
    }

    return prefix;
  }

  togglePrefixOfValue(event: FocusEvent) {
    this.valuePrefix = this.togglePrefix(
      event,
      this.valuePrefix,
      this.data.value,
    );
  }

  validateAllDateRanges(): boolean {
    const {
      initialIssueDate: emissionInitial,
      endIssueDate: emissionEnd,
      initialDueDate: dueInitial,
      endDueDate: dueEnd,
    } = this.data;

    const emissionDateIsValid = validateDateRange(emissionInitial, emissionEnd);
    const dueDateIsValid = validateDateRange(dueInitial, dueEnd);

    if (!emissionDateIsValid) {
      this.errorDateRange.emission = true;
      this.$notification.error('Intevalo de emissão inválido!');
    } else {
      this.errorDateRange.emission = false;
    }

    if (!dueDateIsValid) {
      this.errorDateRange.due = true;
      this.$notification.error('Intevalo de vencimento inválido!');
    } else {
      this.errorDateRange.due = false;
    }

    if (!emissionDateIsValid && !dueDateIsValid) {
      this.$notification.error('Intevalos inválidos!');
    }

    return emissionDateIsValid && dueDateIsValid;
  }

  validate(): { valid: boolean; data: IPayrollSelectFilter } {
    const isValidDateRange = this.validateAllDateRanges();
    const isValidFilter = this.filter.validate();

    if (isValidDateRange && isValidFilter) {
      const relationship = 'payroll_select_list';

      this.filterParametersRepository.setFilter(
        EGroupFilterParameters.PAYROLL_SELECT_LIST,
        [
          {
            key: `initial_issue_date_${relationship}`,
            value: this.data.initialIssueDate,
          },
          {
            key: `end_issue_date_${relationship}`,
            value: this.data.endIssueDate,
          },
          {
            key: `initial_due_date_${relationship}`,
            value: this.data.initialDueDate,
          },
          { key: `end_due_date_${relationship}`, value: this.data.endDueDate },
          {
            key: `companies_${relationship}`,
            value: JSON.stringify(this.data.companies),
          },
          {
            key: `suppliers_${relationship}`,
            value: JSON.stringify(this.data.suppliers),
          },
          { key: `number_${relationship}`, value: this.data.number },
          { key: `value_${relationship}`, value: this.data.value },
          { key: `page_${relationship}`, value: this.data.page },
          {
            key: `items_per_page_${relationship}`,
            value: this.data.itemsPerPage,
          },
          { key: `sort_by_${relationship}`, value: this.data.sortBy },
          { key: `sort_desc_${relationship}`, value: this.data.sortDesc },
        ],
      );

      return {
        valid: true,
        data: {
          ...this.data,
          companies: this.data.companies.length
            ? this.data.companies
            : this.allCompanies,
        },
      };
    }

    return {
      valid: false,
      data: {} as IPayrollSelectFilter,
    };
  }

  onChangeCompanies() {
    this.setSuppliers.clear();
    this.data.suppliers = [];
    this.suppliers = [];
  }

  onChangeSearchSuppliers(search: string) {
    if (search && search.length) {
      this.handleLoadSuppliersDebounce(search);
    } else {
      clearTimeout(this.timerSuppliers);
    }
  }

  handleLoadSuppliersDebounce(search: string): void {
    clearTimeout(this.timerPrefixes);
    const { companies } = this.data;
    this.timerSuppliers = setTimeout(() => {
      this.handleLoadSuppliers(
        companies.length ? companies : this.allCompanies,
        search,
      );
    }, 500);
  }

  async handleLoadSuppliers(
    companies: Array<number>,
    search: string,
  ): Promise<void> {
    try {
      this.loadingSuppliers = true;

      await this.loadSuppliers(companies, search);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingSuppliers = false;
    }
  }

  async handleLoadPrefixes(companies: Array<number>) {
    try {
      this.loadingPrefixes = true;
      await this.loadRemittanceAccountPayablePrefixes(companies);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingPrefixes = false;
    }
  }

  async loadRemittanceAccountPayablePrefixes(
    companyIds: Array<number>,
  ): Promise<void> {
    this.prefixes = await this.paymentRemittanceRepository.getPrefixes(
      this.groupId,
      companyIds.length ? companyIds : this.allCompanies,
    );
  }

  async loadOptions(): Promise<void> {
    try {
      this.$dialog.startLoading();

      await this.loadCompanies();
      await this.loadFilterParameters();

      if (this.data.suppliers.length) {
        const companies = this.data.companies.length
        ? this.data.companies
        : this.allCompanies;

        await Promise.all(
          this.data.suppliers.map((supplier) => this.loadSuppliers(companies, supplier)),
        );
      }
      await this.handleLoadPrefixes(this.allCompanies);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  async loadCompanies(): Promise<void> {
    this.companies = await this.payrollRepository.getCompanies();

    this.allCompanies = this.companies.map((company) => company.value);
  }

  async loadSuppliers(companies: Array<number>, search: string): Promise<void> {
    const suppliers = await this.payrollRepository.getSuppliers(
      this.groupId,
      companies,
      search,
    );

    const concat = [...this.suppliers, ...suppliers];

    this.suppliers.push(
      ...concat.filter((supplier) => {
        const duplicated = this.setSuppliers.has(supplier.value);
        this.setSuppliers.add(supplier.value);
        return !duplicated;
      }),
    );
  }

  async loadFilterParameters(): Promise<void> {
    try {
      const filterParameters = await this.filterParametersRepository.getFilterByGroup(
          EGroupFilterParameters.PAYROLL_SELECT_LIST,
        );

      const params = FilterParameterPayrollSelectList.make(filterParameters);

      this.data = { ...params };
    } catch (error) {
      this.$notification.error(
        'Houve um problema ao requisitar os filtros dessa tela!',
      );
    }
  }
}
