<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Agrupador Nível 2"/>
			<page-card dividerOn>
				<template #title>
					<v-row>
						<v-col cols="12" lg="5" md="5" sm="12" xs="12">
							<v-select
							label="Selecione o agrupador"
							v-model="grouperSelected"
							filled
							item-text='description'
							hide-details
							item-value='id'
							:items="filterGroupers(groupers)"/>
						</v-col>
						<v-col cols="12" lg="5" md="4" sm="12" xs="12">
							<v-select 
							v-model="filterStatus"
							filled
							label="Filtrar por status"
							hide-details
							:items="filterStatusItems"/>
						</v-col>
						<v-spacer/>
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<v-btn
								@click="add"
								class="font-weight-bold"
								x-large
								block
								depressed
								color="primary"
							>
								ADICIONAR
							</v-btn>
						</v-col>
					</v-row>					
				</template>
				<template #body>
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Descrição</th>
								<th>Onde Aparece</th>
								<th></th>
							</tr>
						</thead>
						<draggable id="list_grouper_ordem" v-model="filteredList" @update="saveGroupersItenOrder()" tag="tbody">
							<tr v-for="(grouper, idx) in filteredList" :data-grouper-id="grouper.id" :key="idx">
								<td style="vertical-align: middle;">
									<v-chip
										v-if="grouper.status == 0"
										dense
										small>
										Inativo
									</v-chip>
									<v-chip
										v-else
										dense
										small
										color="green"
										text-color="white">
										Ativo
									</v-chip>
								</td>
								<td class="ui-draggable-handle" style="vertical-align: middle;">{{ grouper.description }}</td>
								<td class="ui-draggable-handle" style="vertical-align: middle;">{{ getWhereShowDescription(grouper.where_show) }}</td>
								<td class="m--align-right">
									<button-tooltip
										fab
										icon
										small
										buttonIcon="mdi-pencil"
										tooltipText="Editar"
										@click="edit(grouper)"
									/>
								</td>
							</tr>
						</draggable>
					</table>
				</template>
			</page-card>
		</v-col>
	</v-row>	
</template>

<script>
	import _ from 'lodash';
	import draggable from 'vuedraggable';
	import { API_BASE } from '@config/api';

	export default {
		data () {
			return {
				groupersIten: [],
				groupers: [],
				filteredList: [],
				grouperSelected: "",
				filterStatus: '',
				groupersItems:[],
				filterStatusItems: [
					{
						text: "Todos os status",
						value: "",
					},
					{
						text: "Inativos",
						value: "0",
					},
					{
						text: "Ativos",
						value: "1",
					},
				],
			}
		},
		components: {
			draggable,
		},
   	mounted: function () {
			this.loadBlocks();
		},

		methods: {
			loadBlocks: function () {
				self = this;
				blockPage('Carregando os dados...');

				this.$http.get(API_BASE+'grouper/listAll', {headers: {'token': this.$session.get('token')}}).then(
					function (result) {
						this.groupers = result.body.data;
						unblockPage();
					},
					function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				).bind(this)
			},

			loadGroupersItenByGrouper: function(grouper) {
				blockPage('Carregando os dados...');

				this.$http.get(API_BASE+'grouperiten/listAllByGrouper/' + grouper, {headers: {'token': this.$session.get('token')}}).then(
					function (result) {
						self.groupersIten = result.body.data;
						this.filterData();
						unblockPage();
					},
					function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				).bind(this)
			},

			filterData() {
				const filterStatus = this.filterStatus;
				var dataRet = this.groupersIten;

				if (!_.isEmpty(filterStatus)) {
					dataRet = _.filter(dataRet, function(data) { return data.status == filterStatus; })
				}

				this.filteredList = dataRet;
			},

			saveGroupersItenOrder: function() {
				blockPage('Salvando dados...');

				$.ajax({
						method: "POST",
						url: API_BASE + 'grouperiten/updateOrder',
						headers: {
							'token': self.$session.get('token')
						},
						data: {
							arrayData: JSON.stringify(this.filteredList.map((group, index) => ({order: index + 1, grouper_id: group.id})))
						}
					}).done(function(result) {
							showNotification('Sucesso', 'Ordem gravada com sucesso!!!', 'success');
							unblockPage();
					}).fail(function(error){
							showNotification('Atenção','Oops! Ocorreu um erro ao gravar a ordem.', 'danger');
							unblockPage();
							console.log(error);
					});
			},

			//Filtra os dados para apenas ativos
			filterGroupers(groupers) {
				return groupers.filter(grouper => grouper.status == 1);
			},

      getWhereShowDescription(whereShow){
				let description = '';
				if(whereShow.indexOf('1') >= 0){
					description+='Fluxo Previsto; '
				}
				if(whereShow.indexOf('2') >= 0){
					description+='Fluxo Realizado; '
				}
				if(whereShow.indexOf('3') >= 0){
					description+='Faturamento;'
				}
				if(whereShow.indexOf('4') >= 0){
					description+='Fluxo projetado;'
				}
				return description;
      },
			add() {
				this.$router.push('/grouperiten/new');
			},	
			edit(grouper) {
				const params = {
					grouper_iten_id: grouper.id,
				};
				this.$router.push({name: 'editgrouperiten',params});
			},
		},
		watch:{
			filterStatus: function() {
				this.filterData();
			},
			grouperSelected: function(val) {
					if (val) {
						this.loadGroupersItenByGrouper(val);
					}
				}

		}
	}
</script>
