


























































































import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import {
  Vue,
  Component,
  Prop,
  Emit,
  // Watch,
} from 'vue-property-decorator';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { formatErrorForNotification } from '@/utils/error';
import { toCurrency } from '@/utils/';
import {
  formatTitle,
  groupByRemittance,
  hideDuplicateOverflow,
  extractErrorMessageFromApproveOrDisapproveRejectedRequests,
} from '@/views/paymentRemittances/utils';
import IApproveOrDisapproveRequestResponse from '@/views/paymentRemittances/interfaces/IApproveOrDisapproveRequestResponse';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionApprove extends Vue {
  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<PaymentRemittance>,
  }) readonly items!: Array<PaymentRemittance>;

  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  @Prop({
    type: String,
  }) name!: string;

  @Emit()
  close(reload: boolean = false): CloseActionData {
    return {
      type: ActionType.APPROVE,
      reload,
    };
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly formatTitle = formatTitle;
  readonly formatCurrency = toCurrency;

  get total(): number {
    return this.items
      .reduce((acc, item) => acc + item.total, 0);
  }

  async save() {
    try {
      this.$dialog.startLoading();

      const groups = groupByRemittance(this.items);
      const responses: IApproveOrDisapproveRequestResponse<number>[] = [];

        // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(groups)) {
        // eslint-disable-next-line no-await-in-loop
        responses.push(await this.handleApprovals(key, value));
      }

      const allRejected = responses
        .filter(({ status }) => status === 'rejected')
        .map(({ value }) => value);

      if (allRejected.length > 0) {
        const errorMessage = extractErrorMessageFromApproveOrDisapproveRejectedRequests(
          allRejected,
        );

        this.$notification.error(errorMessage);
      }

      this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      hideDuplicateOverflow(this);
    }
  }

  async handleApprovals(
    remi: string,
    titles: Array<string>,
  ): Promise<IApproveOrDisapproveRequestResponse<number>> {
    const numberRemittanceId = Number(remi);
    const group = this.$session.get('company_group_id');

    try {
      await this.paymentRemittanceRepository
        .approvals(group, numberRemittanceId, this.companies, titles);

      return {
        status: 'fulfilled',
        value: numberRemittanceId,
      };
    } catch (error) {
      return {
        status: 'rejected',
        value: numberRemittanceId,
      };
    }
  }
}
