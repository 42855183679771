


























































































































































































import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClientModule from '@/stores/modules/ClientModule';
import Client from '@/domain/models/Client';
import InputRulesHelper from '@/helpers/InputRulesHelper';

@Component
export default class OrderCustomer extends Vue {
  private readonly clientModule: ClientModule = getModule(ClientModule);
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  public get customerLoading(): boolean {
    return this.clientModule.clientLoading;
  }

  public get customer(): Client {
    return this.clientModule.client;
  }

  public handleInput(value: string | number, type: string): void {
    this.clientModule.setClient({
      ...this.clientModule.client,
      [type]: value,
    });
  }
}
