













import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Module from '@/domain/models/Module';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';

@Component
export default class ModuleCard extends Vue {
  @Prop() readonly moduleInfo!: Module;
  authStore?: AuthenticationModule;

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);
  }

  async handleClick() {
    this.$dialog.startLoading();
    this.authStore!.setCurrentModule(this.moduleInfo);
    await this.authStore!.loadModulePermissions(this.moduleInfo);
    this.$dialog.stopLoading();
    this.$router.push({ name: this.moduleInfo.initialRoute });
  }
}
