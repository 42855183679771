import ColumnToShow from '@/types/ColumnToShow';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';

export default class FilterParameterFinancialStatementBilling {
  public constructor(
    public tableDirection: boolean[],
    public tableSort: string[],
    public dateTypeToSearch: string,
    public dateToSearch: string[],
    public accountTypes: string[],
    public rowsPerPage: number,
    public columnsToShow: ColumnToShow[],
  ) {
  }

  public static make(data: any): FilterParameterFinancialStatementBilling {
    const dataColumn = {
      data_table_account_receivable_show_column_number: 'number',
      data_table_account_receivable_show_column_order: 'order',
      data_table_account_receivable_show_column_emission_date: 'emissionDate',
      data_table_account_receivable_show_column_issue_date: 'issueDate',
      data_table_account_receivable_show_column_document: 'document',
      data_table_account_receivable_show_column_official_due_date: 'officialDueDate',
      data_table_account_receivable_show_column_delay_days: 'delayDays',
      data_table_account_receivable_show_column_value: 'value',
      data_table_account_receivable_show_column_balance: 'balance',
      data_table_account_receivable_show_column_status: 'status',
      data_table_account_receivable_show_column_title_decrease_date: 'titleDecreaseDate',
      data_table_account_receivable_show_column_fees: 'fees',
      data_table_account_receivable_show_column_paid_value: 'paidValue',
    };

    let arrayDirection: boolean[] = [];
    let arraySort: string[] = [];

    if (data.sort_table_account_receivable_list) {
      const { direction, sort } = FilterParameterHelper
      .defineOrdenation(data.sort_table_account_receivable_list);

      arrayDirection = [direction];
      arraySort = [sort];
    }

    const parsedRowsPerPage = parseInt(data.rows_per_page_account_receivable_list, 10);
    const parsedDateToSearch = data.date_to_search_account_receivable_list ? JSON.parse(
      data.date_to_search_account_receivable_list,
    ) : [];

    return new FilterParameterFinancialStatementBilling(
      arrayDirection,
      arraySort,
      data.date_type_to_search_account_receivable_list ?? '',
      parsedDateToSearch,
      data.account_types_account_receivable_list
        ? JSON.parse(data.account_types_account_receivable_list) : [],
      !Number.isNaN(parsedRowsPerPage) ? parsedRowsPerPage : 10,
      FilterParameterHelper.mountColumnToShowArray(data, dataColumn),
    );
  }
}
