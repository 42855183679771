








































































































































































































import {
  Component,
  Ref,
  Vue,
Watch,
} from 'vue-property-decorator';
import { toCurrency } from '@/utils';
import { getModule } from 'vuex-module-decorators';
import ContentEditableComponent from '@/components/fields/ContentEditableComponent.vue';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import PreNegotiationHelper from '@/views/preNegotiation/helpers/PreNegotiationHelper';
import { formatToSpecificFormat } from '@/utils/date';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import IInstallmentTableItem from '@/views/preNegotiation/interfaces/IInstallmentTableItem';
import { VForm } from '@/types/VForm';
import IInstallmentsTotalTable from '@/views/preNegotiation/interfaces/IInstallmentsTotalTable';
import TAvailableChangedFieldsOnInstallment from '@/views/preNegotiation/types/TAvailableChangedFieldsOnInstallment';

@Component({
  components: {
    ContentEditableComponent,
  },
})
export default class PreNegotiationInstallmentsTable extends Vue {
  public installmentQuantity: number|null = null;
  public installmentCustomPeriodicity: number|null = null;

  public installmentPeriodicity: string|null = null;

  public installmentItems: IInstallmentTableItem[] = [];
  public installmentsPeriodicityOptions: ISelectOptions[] = [
    { value: 'daily', text: 'Diária' },
    { value: 'weekly', text: 'Semanal' },
    { value: 'fortnightly', text: 'Quinzenal' },
    { value: 'monthly', text: 'Mensal' },
    { value: 'custom', text: 'Personalizada' },
  ];
  public headers: IVDataTableHeader[] = [
    { text: 'Parcela', value: 'installment' },
    { text: 'Vencimento', value: 'dueDate' },
    { text: 'Valor (R$)', value: 'financialValue' },
    { text: 'Decrésimo (R$)', value: 'financialDecrease' },
    { text: 'Acrésimo (R$)', value: 'financialAddition' },
    { text: 'Valor Total (R$)', value: 'financialTotalValue' },
    { text: '', value: 'actions', width: '64px' },
  ];

  public installmentsTotalTable: IInstallmentsTotalTable = {
    totalValue: 0,
    totalDecrease: 0,
    totalAddition: 0,
    totalValueWithChanges: 0,
  };

  public inputRules: InputRulesHelper = new InputRulesHelper();
  public readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);

  @Watch('preNegotiationModule.accountsTotal.totalBalanceWithFees')
  public onAccountsTotalChange(): void{
    if (
      this.preNegotiationModule.installmentConfigs.periodicity
      && this.preNegotiationModule.installmentConfigs.quantity
    ) {
      this.handleInstallmentAddition();
    }
  }

  @Watch('installmentItems')
  public onInstallmentItemsChange(): void {
    this.preNegotiationModule.setHasInstallmentsDefined(this.installmentItems.length > 0);
  }

  @Ref('PreNegotiationInstallmentsForm') readonly preNegotiationInstallmentsForm!: VForm;

  public get hasInstallmentItems(): boolean {
    return this.installmentItems.length > 0;
  }

  public get isCustomPeriodicity(): boolean {
    return this.installmentPeriodicity === 'custom';
  }

  public get canEdit(): boolean {
    if (this.$route.params.preNegotiationId) {
      return false;
    }

    return true;
  }

  public get availableHeaders(): IVDataTableHeader[] {
    if (this.canEdit) {
      return this.headers;
    }

    return this.headers.filter((header) => header.value !== 'actions');
  }

  public get hasDefinedPeriodicityOnPolitics(): boolean {
    return !!(
        this.preNegotiationModule.tradingPolicy
      && this.preNegotiationModule.tradingPolicy.periodicityInstallments
    );
  }

  public get installmentQuantityRules(): Function[] {
    const defaultRules = [
      this.inputRules.required(),
      this.inputRules.isNumber(),
    ];

    if (
      this.preNegotiationModule.tradingPolicy
      && this.preNegotiationModule.tradingPolicy.amountInstallments
    ) {
      const { amountInstallments } = this.preNegotiationModule.tradingPolicy;

      defaultRules.push(this.inputRules.lowerOrEqualThen(
        amountInstallments,
        true,
        `A quantidade de parcelas deve ser menor que ${amountInstallments} de acordo com a politica de negociação para esse operador.`,
      ));
    }

    return defaultRules;
  }

  public async mounted(): Promise<void> {
    this.installmentQuantity = this.preNegotiationModule.installmentConfigs.quantity;
    this.installmentPeriodicity = this.preNegotiationModule.installmentConfigs.periodicity;

    await this.$forceUpdate();

    if (this.installmentQuantity && this.installmentPeriodicity) {
      this.handleInstallmentAddition();
    }
  }

  public getMoneyFormat(num: number): string {
      return toCurrency(num, {}, true);
  }

  public setDateToBrazilianFormat(date: string): string {
    return formatToSpecificFormat(date, 'dd/MM/yyyy');
  }

  public syncInstallmentChanges(): void {
    for (let i = 0; i < this.installmentItems.length; i += 1) {
      const installmentItem = this.installmentItems[i];
      const changes = this.preNegotiationModule.installmentChanges
        .filter(({ index }) => installmentItem.installment === index);

      changes.forEach((change) => {
        let parsedValueChange: string|number = change.value;

        if (['value', 'increase', 'decrease'].includes(change.changedField)) {
          parsedValueChange = Number(change.value);
        }

        switch (change.changedField) {
          case 'due_date': installmentItem.dueDate = parsedValueChange as string; break;
          case 'value': installmentItem.financialValue = parsedValueChange as number; break;
          case 'increase': installmentItem.financialAddition = parsedValueChange as number; break;
          case 'decrease': installmentItem.financialDecrease = parsedValueChange as number; break;
          default: break;
        }
      });

      installmentItem.financialTotalValue = (
        installmentItem.financialValue + installmentItem.financialAddition
      ) - installmentItem.financialDecrease;
    }
  }

  public syncInstallmentTotalsFromList(): void {
    let totalValue = 0;
    let totalDecrease = 0;
    let totalAddition = 0;
    let totalValueWithChanges = 0;

    for (let i = 0; i < this.installmentItems.length; i += 1) {
      const {
        financialValue,
        financialAddition,
        financialDecrease,
      } = this.installmentItems[i];
      const newTotalValue = (financialValue + financialAddition) - financialDecrease;

      totalValue += financialValue;
      totalDecrease += financialDecrease;
      totalAddition += financialAddition;
      totalValueWithChanges += newTotalValue;
    }

    this.installmentsTotalTable = {
      totalValue,
      totalDecrease,
      totalAddition,
      totalValueWithChanges,
    };

    this.preNegotiationModule.setTotalFromInstallments(totalValueWithChanges);
  }

  public validateIfAllInstallmentValuesAreEquals(): void {
    const valueFromEachInstallment = (
      this.preNegotiationModule.accountsTotal.totalBalanceWithFees / this.installmentQuantity!
    );

    const installmentWithDiferentTotalValue = this.installmentItems
      .find((installmentItem) => installmentItem.financialTotalValue !== valueFromEachInstallment);

    this.preNegotiationModule.setHasEqualValuesInstallments(!installmentWithDiferentTotalValue);
  }

  public handleInstallmentAddition(): void {
    if (!this.preNegotiationInstallmentsForm.validate()) {
      this.$notification.error('Os campos devem ser preenchidos corretamente.');

      return;
    }

    if (this.preNegotiationModule.installmentConfigs.quantity !== this.installmentQuantity!) {
      this.preNegotiationModule.resetInstallmentChanges();
    }

    this.preNegotiationModule.setInstallmentConfigs({
      quantity: this.installmentQuantity!,
      periodicity: this.installmentPeriodicity!,
      customPeriodicity: this.installmentCustomPeriodicity,
    });

    this.installmentItems = PreNegotiationHelper.getInstallmentsFromSpecificValue(
      this.preNegotiationModule.accountsTotal.totalBalanceWithFees,
      this.preNegotiationModule.installmentConfigs,
    );

    this.syncInstallmentChanges();
    this.syncInstallmentTotalsFromList();
    this.validateIfAllInstallmentValuesAreEquals();
  }

  public handleInstallmentValueChange(
    newValue: number|string,
    installment: IInstallmentTableItem,
    changedField: TAvailableChangedFieldsOnInstallment,
  ): void {
    this.preNegotiationModule.setInstallmentChanges({
      index: installment.installment,
      value: newValue,
      changedField,
    });

    this.handleInstallmentAddition();
  }

  public handleExcludeInstallment(): void {
    this.preNegotiationModule.resetInstallmentChanges();

    const newQuantity = (
      this.installmentQuantity == null || this.installmentQuantity === 1
        ? null : this.installmentQuantity - 1
    );

    this.preNegotiationInstallmentsForm.resetValidation();

    this.installmentQuantity = newQuantity;
    this.preNegotiationModule.setInstallmentConfigs({
      customPeriodicity: this.preNegotiationModule.installmentConfigs.customPeriodicity,
      periodicity: this.preNegotiationModule.installmentConfigs.periodicity,
      quantity: newQuantity,
    });

    this.handleInstallmentAddition();
  }

  public handlePeriodicityChange(): void {
    if (this.installmentPeriodicity !== 'custom') {
      this.installmentCustomPeriodicity = null;
    }
  }
}
