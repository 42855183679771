import AccountReceivablePreNegotiationAccountsDeadline from '@/domain/models/AccountReceivablePreNegotiationAccountsDeadline';

export default class AccountReceivablePreNegotiationDeadline {
    constructor(
        public customerName: string,
        public customerIdCustomer: string,
        public economicGroupName: string,
        public economicGroupId: number,
        public companyName: string,
        public companyId: number,
        public accounts: AccountReceivablePreNegotiationAccountsDeadline[],
    ) {}

    static make(data: any): AccountReceivablePreNegotiationDeadline {
        return new AccountReceivablePreNegotiationDeadline(
			data.customer_name,
			data.customer_id_customer,
			data.economic_group_name,
			data.economic_group_id,
            data.company_name,
            data.company_id,
            data.accounts.map(AccountReceivablePreNegotiationAccountsDeadline.make),
        );
    }
}
