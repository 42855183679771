import EPayrollLogType from '../enums/PayrollLogType';

export default class PayrollLog {
  constructor(
    public id: number,
    public type: EPayrollLogType,
    public date: string,
    public totalValue: string,
    public user: string,
    public file: string,
    public customerFileName: string,
    public canGenerateReceipt: boolean,
    public accountNumber: string,
    public branchCode: string,
  ) {}

  static make(data: any): PayrollLog {
    const totalValueNumberFormat = Number(data.total_value);

    return new PayrollLog(
      data.id,
      data.type ?? '',
      data.date ?? '',
      (totalValueNumberFormat / 100).toFixed(2) ?? '',
      data.user ?? '',
      data.file ?? '',
      data.customer_file_name ?? '',
      data.can_generate_receipt,
      data.account_number,
      data.erp_branch_code,
    );
  }
}
