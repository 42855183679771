




































import {
  Component,
  Vue,
  Watch,
  Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  dateNow,
  formatToSpecificFormat,
  getIncreasedDate,
  getDecreasedDate,
  dateGreaterOrEqualThanOtherDate,
  dateLowerOrEqualThanOtherDate,
  ptBrDateFormat,
  defaultDateFormat,
} from '@/utils/date';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import PreNegotiationModule from '@/stores/modules/PreNegotiationModule';
import TradingPolicy from '@/domain/models/TradingPolicy';
import PreNegotiationHelper from '@/views/preNegotiation/helpers/PreNegotiationHelper';
import AccountsReceivablePreNegotiationsRepository from '@/repositories/AccountsReceivablePreNegotiationsRepository';
import PreNegotiationCompanyAccounts from '@/views/preNegotiation/deadline/PreNegotiationCompanyAccounts.vue';
import IGetAccountsReceivablePreNegotiationDeadlineRequest from '@/repositories/parameters/IGetAccountsReceivablePreNegotiationDeadlineRequest';
import { VForm } from '@/types/VForm';

@Component({
  components: {
    PreNegotiationCompanyAccounts,
  },
})
export default class PreNegotiationDeadline extends Vue {
  private readonly accountsReceivablePreNegotiationsRepository
  : AccountsReceivablePreNegotiationsRepository = new AccountsReceivablePreNegotiationsRepository();
  public readonly preNegotiationModule: PreNegotiationModule = getModule(PreNegotiationModule);
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public expirationDate: string|null = null;
  public stringDateNow: string = dateNow();

  public customRules: Function[] = [];

  private get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  private get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  private get isSelectingAllSecurities(): 0 | 1 {
    return this.preNegotiationModule.selectedSecuritiesParams.isSelectingAllSecurities as 0 | 1;
  }

  private get preNegotiationId(): number|null {
    if (this.$route.params.preNegotiationId) {
      return Number(this.$route.params.preNegotiationId);
    }

    return null;
  }

  public get origin(): 'create' | 'show' {
    return this.preNegotiationId ? 'show' : 'create';
  }

  @Ref('PreNegotiationDeadlineExpirationDateForm') readonly preNegotiationDeadlineExpirationDateForm!: VForm;

  @Watch('expirationDate')
  public async onExpirationDateChange(): Promise<void> {
    this.preNegotiationModule.setExpirationDate(this.expirationDate);

    await this.$forceUpdate();

    this.preNegotiationModule.setHasValidExpirationDate(
      this.preNegotiationDeadlineExpirationDateForm.validate(),
    );
  }

  public created(): void {
    this.setExpirationDate();

    if (this.preNegotiationModule.tradingPolicy) {
      this.setCustomRulesIfHasTradingPolicy(this.preNegotiationModule.tradingPolicy);
    }

    if (this.origin === 'create') {
      this.getAccountsInstallmentsGroupedByCompany();

      return;
    }

    this.getGeneratedAccountsInstallmentsGroupedByCompanyByPreNegotiationId();
  }

  public setExpirationDate(): void {
    if (
      this.preNegotiationModule.tradingPolicy
      && this.preNegotiationModule.tradingPolicy.standardDeadline
    ) {
      this.expirationDate = getIncreasedDate(
        this.stringDateNow,
        { days: this.preNegotiationModule.tradingPolicy.standardDeadline },
      );

      return;
    }

    this.expirationDate = this.preNegotiationModule.expirationDate;
  }

  public setCustomRulesIfHasTradingPolicy(tradingPolicy: TradingPolicy): void {
    const { minimumDeadline, maximumDeadline } = tradingPolicy;

    if (minimumDeadline) {
      const decreasedDate = getDecreasedDate(this.stringDateNow, { days: minimumDeadline });

      this.customRules.push((v: string) => {
        const formattedEntryDate = formatToSpecificFormat(v, defaultDateFormat, ptBrDateFormat);
        const isDateGreaterOrEqual = dateGreaterOrEqualThanOtherDate(
          formattedEntryDate,
          decreasedDate,
        );

        return isDateGreaterOrEqual || `A data não pode ter ${minimumDeadline} dias a menos que o dia atual.`;
      });
    }

    if (maximumDeadline) {
      const increasedDate = getIncreasedDate(this.stringDateNow, { days: maximumDeadline });
      this.customRules.push((v: string) => {
        const formattedEntryDate = formatToSpecificFormat(v, defaultDateFormat, ptBrDateFormat);
        const isDateLowerOrEqual = dateLowerOrEqualThanOtherDate(
          formattedEntryDate,
          increasedDate,
        );

        return isDateLowerOrEqual || `A data não pode ter ${maximumDeadline} dias a mais que o dia atual.`;
      });
    }
  }

  public async getAccountsInstallmentsGroupedByCompany(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const accountsReceivableToConsider = PreNegotiationHelper.getAvailableAccounts(
        this.preNegotiationModule.selectedSecuritiesParams.securitiesIdsCustomer,
        this.preNegotiationModule.excludedAccounts,
      );
      const formattedAccountsFees = this.preNegotiationModule.accountFees.map((accountFee) => ({
        fee: accountFee.fee,
        fee_type: accountFee.feeType,
        id_customer: accountFee.idCustomer,
      }));
      const formattedInstallmentChanges = this.preNegotiationModule.installmentChanges
        .map((installmentChange) => ({
          changed_field: installmentChange.changedField,
          index: installmentChange.index,
          value: installmentChange.value,
        }));

      const params: IGetAccountsReceivablePreNegotiationDeadlineRequest = {
        accounts_fees: formattedAccountsFees,
        accounts_ids_customer: accountsReceivableToConsider,
        is_selecting_all: this.isSelectingAllSecurities,
        installment_custom_periodicity: this.preNegotiationModule
          .installmentConfigs.customPeriodicity,
        installment_periodicity: this.preNegotiationModule.installmentConfigs.periodicity!,
        installment_quantity: this.preNegotiationModule.installmentConfigs.quantity!,
        installments_changes: formattedInstallmentChanges,
        total_fee: this.preNegotiationModule.totalFee,
        total_fee_type: this.preNegotiationModule.totalFeeType!,
      };

      const accounts = await this.accountsReceivablePreNegotiationsRepository
        .getAccountsReceivablePreNegotiationDeadline(
          this.companyGroupId,
          this.companyIds,
          params,
        );

      this.preNegotiationModule.setDeadlineRedefinedAccounts(accounts);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os títulos de prazo.');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getGeneratedAccountsInstallmentsGroupedByCompanyByPreNegotiationId(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const accounts = await this.accountsReceivablePreNegotiationsRepository
        .getAccountsReceivablePreNegotiationDeadlineByPreNegotiationId(
          this.companyGroupId,
          this.companyIds,
          this.preNegotiationId!,
        );

      this.preNegotiationModule.setDeadlineRedefinedAccounts(accounts);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os títulos de prazo.');
    } finally {
      this.$dialog.stopLoading();
    }
  }
}
