import ICreditRuleGroupWithOptionsInString from '@/views/creditRules/interfaces/ICreditRuleGroupWithOptionsInString';

export default class CreditRule {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public active: 'active' | 'inactive',
    public companies: number[],
    public ruleIndex: number,
    public rule: ICreditRuleGroupWithOptionsInString,
    public filter: ICreditRuleGroupWithOptionsInString|null,
  ) {}

  public static make(data: any): CreditRule {
    return new CreditRule(
      data.id,
      data.name,
      data.description,
      data.active,
      data.companies,
      data.ruleIndex,
      data.rule,
      data.filter,
    );
  }
}
