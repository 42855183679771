




































































































































































































































import { defineComponent } from '@vue/composition-api';

import AccountPayable from '@/domain/models/AccountPayable';
import AccountPayableRepository from '@/repositories/AccountPayableRepository';
import AccountReceivableRepository from '@/repositories/AccountReceivableRepository';
import AccountReceivableList from '@/domain/models/AccountReceivableList';
import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import CurrencyHelper from '@/helpers/CurrencyHelper';
import { formateDate } from '@/utils/date';
import PredictedFlowActionsEnum from '../../enums/PredictedFlowActionsEnum';
import IPredictedFlowGrouperList from '../../interfaces/groupers/IPredictedFlowGrouperList';

export default defineComponent({
  name: 'ShowAccountDetailsDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    register: {
      required: false,
      type: Object as () => IPredictedFlowGrouperList,
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const details = {} as AccountPayable | AccountReceivableList;
    const accountPayableRepository = new AccountPayableRepository();
    const accountReceivableRepository = new AccountReceivableRepository();
    const isOpen: boolean = this.value;
    const currencyHelper = CurrencyHelper;
    const formatDate = formateDate;
    const tabs: any = null;
    const documentFormattation = DocumentFormattation;
    const loading = false;

    return {
      details,
      loading,
      accountPayableRepository,
      accountReceivableRepository,
      isOpen,
      formatDate,
      documentFormattation,
      currencyHelper,
      tabs,
    };
  },

  created() {
    if (this.register) {
      this.getDetails(this.register);
    }
  },

  methods: {
    async getDetails(register: IPredictedFlowGrouperList) {
      try {
        this.loading = true;

        if (register.grouperType === 'accounts_payable') {
          this.details = await this.accountPayableRepository.find(
            register.itemData?.idCustomer as string,
            this.companyGroupId,
            register.itemData?.companyId as number,
          );
        } else {
          this.details = await this.accountReceivableRepository.find(
            register.itemData?.idCustomer ?? '',
            this.companyGroupId,
            register.itemData?.companyId ?? 0,
          );
        }
      } catch (error) {
        this.$notification.error('Houve um problema ao detalhar o título.');
      } finally {
        this.loading = false;
      }
    },

    formatDocument(value: string | undefined): string | undefined {
      return value;
    },

    close(reload: boolean): void {
      this.$emit('close', {
        action: PredictedFlowActionsEnum.show_account,
        reload,
      });
    },
  },
});
