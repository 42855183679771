









































































































































import {
  Vue,
  Ref,
  Component,
} from 'vue-property-decorator';
import ContactSupportRepository from '@/repositories/ContactSupportRepository';
import MaskHelper from '@/helpers/MaskHelper';
import InputRules from '@/helpers/InputRulesHelper';
import { VForm } from '@/types/VForm';

@Component
export default class ContactSupport extends Vue {
  @Ref('ContactSupportForm') readonly contactSupportForm!: VForm;

  contactSupportRepository: ContactSupportRepository = new ContactSupportRepository();

  inputRules: InputRules = new InputRules();

  hasSendWithSuccess: boolean = false;
  loading: boolean = false;

  name: string = '';
  phone: string = '';
  mail: string = '';
  text: string = '';

  get phoneMask(): string | null {
    return MaskHelper.phoneMask(this.phone);
  }

  async sendMailToSupport(): Promise<void> {
    try {
      this.loading = true;

      await this.contactSupportRepository.getCompanyBySearch({
        mail: this.mail,
        name: this.name,
        phone: this.phone,
        text: this.text,
      });
      this.hasSendWithSuccess = true;
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao enviar o E-Mail ao suporte.');
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
    }
  }

  handleBackLogin(): void {
    this.$router.push({ name: 'login' });
  }

  handleSendMailToSupport(): void {
    if (!this.contactSupportForm.validate()) {
      this.$notification.error('Os campos estão inválidos');

      return;
    }

    this.sendMailToSupport();
  }
}
