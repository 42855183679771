<template>
  <div>
    <!--begin::Modal-->
    <div
      class="modal fade"
      id="m_generate_cnab"
      role="dialog"
      aria-labelledby="m_generate_cnab1"
      aria-hidden="false"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="">Geração de Remessa Bancária</h5>
          </div>
          <form class="m-form m-form--fit m-form--label-align-right">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Banco</label>
                    <select
                      class="form-control hide select2Bank"
                      id="bank_cnab_field"
                      name="bank_cnab_field"
                      style="width: 100%"
                    >
                      <option :value="undefined">Selecione...</option>
                      <option
                        v-for="bank in banks"
                        :value="bank.id"
                        :key="bank.name"
                      >
                        {{ bank.banco }} {{ bank.agencia }} {{ bank.conta }} -
                        {{ bank.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Remessa</label>
                    <select
                      class="form-control hide select2CnabRemittance"
                      v-model="sendType"
                      id="cnab_remittance_field"
                      name="cnab_remittance_field"
                      style="width: 100%"
                    >
                      <option :value="undefined">Selecione...</option>
                      <!-- LISTA TODOS AS CONFIG DE REMESSA A PAGAR -->
                      <option
                        v-for="sendType in bank.send_types"
                        :key="sendType.id"
                        :value="sendType.id"
                      >
                        {{ sendType.description }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-md-6"
                  v-show="typeCnab == 'CP' && showPaymentDate == 'Y'"
                >
                  <div class="form-group">
                    <label>Data de Pagamento</label>
                    <div class="input-group date" id="payment_date">
                      <input
                        type="text"
                        class="form-control m-input text-center date-container"
                        readonly=""
                        placeholder="Selecione a data"
                      />
                      <span class="input-group-addon">
                        <i class="la la-calendar-check-o"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="height: 300px; overflow-y: scroll">
                <div class="col-md-12">
                  <table class="table table-striped m-table">
                    <thead>
                      <tr>
                        <th>Títulos Selecionados</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="dataDesc in dataDescriptions"
                        :key="dataDesc.id"
                      >
                        <td>
                          <span
                            style="margin-left: 10px; margin-right: 10px"
                            v-if="dataDesc.error"
                            v-popover:tooltip="dataDesc.textError"
                          >
                            <i class="la la-warning text-gold"></i>
                          </span>
                          {{ dataDesc.levelThreeDescription }}
                        </td>
                        <td>
                          {{ dataDesc.description }}
                        </td>
                        <td style="text-align: right">
                          {{ dataDesc.value }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row" style="margin-top: 10px">
                <div class="col-md-4">
                  <h6>Selecionados</h6>
                </div>
                <div class="col-md-4">
                  <span>{{ countSelected }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <h6>Total</h6>
                </div>
                <div class="col-md-4">
                  <span>{{ totalSelected }}</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn m-btn--air btn-success"
                @click="checkToGenerateCnab()"
              >
                Gerar
              </button>
              <button
                type="button"
                class="btn m-btn--air btn-primary"
                @click="cancelGenerateCnab()"
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end::Modal-->

    <tooltip></tooltip>
  </div>
</template>

<script>
import ApiBankingService from "@/services/apibanking.service";
import BankingService from "@/services/banking.service";
import CnabRemittanceService from "@/services/cnabremittance.service";
import _ from "lodash";
import { API_BASE } from "@config/api";

const initialState = function () {
  return {
    config: {},
    countSelected: 0,
    totalSelected: 0,
    totalSaldoDiff: 0,
    companyId: null,
    banks: [],
    bank: { send_types: [] },
    sendType: null,
    dataDescriptions: [],
    cnabRemittancesConfig: [],
    typeCnab: "",
    dataKeys: null,
    dataIds: null,
    apibankingconfigs: [],
    bordero: "",
    showPaymentDate: "N",
    initialReferenceDate: "",
    finalReferenceDate: "",
  };
};

export default {
  data() {
    return {
      ...initialState(),
      initialRoute: "",
    };
  },
  methods: {
    openModal(config) {
      blockPage("Carregando os dados...");
      $(".select2CnabRemittance").select2({
        placeholder: "Selecione a forma de envio...",
      });
      $(".select2Bank").select2({ placeholder: "Selecione o banco..." });

      this.addCnabFieldController();
      this.getRemittanceConfig(this.$session.get("user_id"), config.companyId);

      this.config = config;
      this.companyId = config.companyId;
      this.totalSelected = numeral(config.totalSelected).format("0,0.00");
      this.dataDescriptions = config.dataDescriptions;
      this.countSelected = config.countSelected || 0;
      this.totalSaldoDiff = config.totalSaldoDiff || 0;
      this.typeCnab = config.typeCnab;
      this.dataKeys = config.dataKeys;
      this.dataIds = config.dataIds;
      this.paymentDate = null;
      this.showPaymentDate = null;
      this.initialRoute = this.$route.name;
      this.initialReferenceDate = config.initialReferenceDate;
      this.finalReferenceDate = config.finalReferenceDate;

      $("#payment_date > input")
        .datepicker({
          container: "#payment_date",
          autoclose: true,
          format: "dd/mm/yyyy",
          todayHighlight: !0,
          orientation: "bottom left",
          templates: {
            leftArrow: '<i class="la la-angle-left"></i>',
            rightArrow: '<i class="la la-angle-right"></i>',
          },
        })
        .keyup(function (e) {
          if (e.keyCode == 8 || e.keyCode == 46) {
            this.value = "";
          }
        });

      this.$http
        .get(API_BASE + "bankconfig/listAllBanks/", {
          headers: {
            token: this.$session.get("token"),
            "company-ids": JSON.stringify([config.companyId]),
          },
        })
        .then(
          (result) => {
            if (result && result.data && result.data.error == false) {
              if (result.data.total_records == 0) {
                unblockPage();
                showNotification(
                  "Atenção",
                  "Nenhuma configuração bancária encontrada",
                  "danger"
                );
                return;
              }
              this.banks = result.data.data.filter((bankConfig) => {
                if (this.config.typeCnab == "CP") {
                  return (bankConfig.tipo == "PAGAR" && bankConfig.send_types.length > 0);
                }
                return bankConfig.tipo == "RECEBER";
              });
            } else {
              unblockPage();
              showNotification("Atenção", result.error, "danger");
              return;
            }

            this.validacaoDadosDePagamento();

            unblockPage();
            $("#m_generate_cnab").modal("show");
          },
          (error) => {
            unblockPage();
            showNotification(
              "Atenção",
              "Oops! Ocorreu um erro ao carregar os bancos.",
              "danger"
            );
            console.error(error);
          }
        );
    },

    async addCnabFieldController() {
      $("#bank_cnab_field").change(async () => {
        let bankField = $("#bank_cnab_field");

        this.cnabRemittancesConfig = [];

        if (!bankField.val()) {
          this.bank = { send_types: [] };
          return;
        }

        this.bank = this.banks.find((current) => current.id == bankField.val());
      });
    },

    checkToGenerateCnab() {
      let self = this;

      checkImportBeforeSearch(self).then(
        function (result) {
          if (!result.body.payload) {
            self.generateCnab();
          } else {
            showNotification(
              "Atenção",
              "Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.",
              "warning"
            );
            unblockPage();
          }
        },
        function (error) {
          showNotification(
            "Atenção",
            "Opa! Parece que algo saiu errado, tente recarregar a página",
            "danger"
          );
          unblockPage();
        }
      );
    },
    generateCnab() {
      const sendTypeId = $("#cnab_remittance_field").val();
      const selectConfig = this.bank.send_types.find(
        (current) => current.id == sendTypeId
      );

      if (this.config.typeCnab == "CP") {
        this.paymentDate = $("#payment_date input").val();
      }

      if (!selectConfig) {
        showNotification(
          "Atenção",
          "A remessa não foi selecionada.",
          "warning"
        );
        return;
      }

      if (selectConfig.send_type == 3) {
        this.generateRemittanceByAPI(selectConfig);
      } else {
        this.generateRemittanceByCnab(selectConfig);
      }
    },
    generateRemittanceByAPI(config) {
      this.generateBordero(config);
    },
    async generateBordero(config) {
      blockPage("Gerando Remessa...");

      try {
        const data = {
          dataKeys: JSON.stringify(this.dataKeys),
          dataDescriptions: JSON.stringify(this.dataDescriptions),
          cnabConfigId: config.client_bank_id,
          bankCnabId: this.bank.id,
          companyId: this.config.companyId,
          sendType: JSON.stringify({
            id: config.id,
            send_type: config.send_type,
            send_type_id: config.send_type_id,
            cnab_id: config.cnab_id,
          }),
          initialReferenceDate: this.initialReferenceDate,
          finalReferenceDate: this.finalReferenceDate,
        };

        const cnabRemittanceService = new CnabRemittanceService();

        const response = await cnabRemittanceService
          .generateBordero()
          .send(data, "", { timeout: 1800000 });

        if (
          response &&
          !response.error &&
          response.bordero_number &&
          response.bordero_number.bordero_number
        ) {
          await this.dispatchFile(
            result.created_file_id,
            this.config.companyId
          );

          const message = `
                  <p>
                      Gerado com sucesso!<br /> 
                      Número do Borderô  ${response.bordero_number.bordero_number}
                  </p>`;

          this.conditionalFeedback(
            "Sucesso",
            "Gerado com sucesso",
            message,
            "success"
          );

          this.resetData();

          $("#m_generate_cnab").modal("hide");

          this.$emit("success", 'generate-remittance');

          return;
        }

        let errorMessage =
          "Não foi possível efetuar a geração. Verifique os títulos selecionados, talvez eles já estejam baixados, estão em borderô ou não estão liberados.";

        if (response && response.error && response.message) {
          errorMessage = response.message;
        }

        this.conditionalFeedback("Atenção", errorMessage, null, "warning");
      } catch (error) {
        console.error(error);

        if (error.message) {
          this.conditionalFeedback(
            "Atenção",
            error.message,
            null,
            "warning"
          );
          return;
        }
        this.conditionalFeedback(
          "Atenção",
          "Ocorreu um problema ao gerar a remessa no banco!",
          null,
          "warning"
        );
      } finally {
        unblockPage();
      }
    },
    conditionalFeedback(subject, message, richTextMessage, type) {
      if (
        this.$session.get("user_config_autotoggle_remittance_confirm_message")
      ) {
        return this.showAlert(
          subject,
          richTextMessage ? richTextMessage : message,
          type
        );
      }

      return showNotification(subject, message, type);
    },
    prepareRemittanceAPIData(config) {
      const companyGroupId = this.$session.get("company_group_id");
      const companies = this.config.companyId;
      const documents = this.serializeDocumentsToRemittance();

      return {
        companyGroupId,
        companies,
        APIBankingId: config.send_type_id,
        documents,
        sendType: JSON.stringify({
          id: config.id,
          send_type: config.send_type,
          send_type_id: config.send_type_id,
          cnab_id: config.cnab_id,
        }),
      };
    },
    serializeDocumentsToRemittance() {
      const { bordero } = this;

      const documents = this.dataKeys.map((document) => {
        const objDocument = JSON.parse(document);

        return {
          borderoNumber: bordero,
          clientCode: objDocument.E1_CLIENTE,
          branchCode: objDocument.E1_FILIAL,
          storeCode: objDocument.E1_LOJA,
          documentNumber: objDocument.E1_NUM,
          documentParcel: objDocument.E1_PARCELA,
          documentPrefix: objDocument.E1_PREFIXO,
          tableCompany: objDocument.TABLE_COMPANY_CODE,
          sequentialNumber: objDocument.ID_INNCASH,
        };
      });

      return documents;
    },
    async getRemittanceConfig(userId, companyId) {
      await $.ajax({
        method: "POST",
        url: API_BASE + "remittanceconfig/getConfig",
        timeout: 1800000, // timeout de 5 minutos!
        headers: {
          token: this.$session.get("token"),
        },
        data: {
          userId: userId,
          companyId: companyId,
        },
      }).done((result) => {
        if (result.error == true) {
          this.showPaymentDate = "N";
        } else {
          this.showPaymentDate = result.data.showPaymentDate;
        }
      });

      return true;
    },
    generateRemittanceByCnab(config) {
      blockPage("Gerando Remessa...");
      let data = {
        dataKeys: JSON.stringify(this.dataKeys),
        dataDescriptions: JSON.stringify(this.dataDescriptions),
        cnabConfigId: config.client_bank_id,
        bankCnabId: this.bank.id,
        companyId: this.config.companyId,
        sendType: JSON.stringify({
          id: config.id,
          send_type: config.send_type,
          send_type_id: config.send_type_id,
          cnab_id: config.cnab_id,
        }),
        paymentDate:
          this.paymentDate === ""
            ? this.initialReferenceDate
            : this.paymentDate,
        initialReferenceDate: this.initialReferenceDate,
        finalReferenceDate: this.finalReferenceDate,
      };

      $.ajax({
        method: "POST",
        url: API_BASE + "cnabremittancegenerated/generate",
        timeout: 1800000, // timeout de 5 minutos!
        headers: {
          token: this.$session.get("token"),
        },
        data,
      })
        .done((result) => {
          unblockPage();

          if (
            this.config &&
            this.config.self &&
            this.config.self.listRemittancePayable
          ) {
            //Percorrendo os títulos gerados CNAB
            this.dataDescriptions.forEach((cpCnab) => {
              //Tem que percorrer a lista da tela. Cada linha é uma forma de pgto
              this.config.self.listRemittancePayable.forEach(
                (remittancePayable) => {
                  //Dentro de cada forma, tem os títulos
                  if (!remittancePayable.elements) return;

                  let cp = remittancePayable.elements.find(
                    (cp) => cp.ID == cpCnab.ID
                  );
                  if (cp) {
                    if (cp.origin !== "inncash") {
                      cp.E2_PORTADO = this.bank.banco;
                      cp.E2_NUMBOR = "0";
                    } else {
                      cp.E2_PORTADO = this.bank.banco;
                      cp.E2_NUMBOR = "0";
                    }
                  }
                }
              );
            });
          }

          const companyId = this.companyId;
          this.resetData();

          if (result.created_file_id) {
            this.dispatchFile(result.created_file_id, companyId).then();
          }
          if ((result && result.file) || !result.error) {
            if (result.van) {
              if (
                this.$session.get(
                  "user_config_autotoggle_remittance_confirm_message"
                )
              ) {
                let message = `<p>Arquivo gerado com sucesso!<br /> Número do Borderô  ${
                  result.bordero_number.bordero_number ||
                  result.bordero_number.bordero_invoice
                }</p>`;

                this.showAlert("Sucesso!", message, "success");
              } else {
                showNotification(
                  "Sucesso",
                  "Arquivo enviado para VAN com sucesso!",
                  "success"
                );
              }
            } else {
              var blob = new Blob([result.file], {
                type: "application/octet-stream",
              });
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = result.name;
              link.click();

              if (
                this.$session.get(
                  "user_config_autotoggle_remittance_confirm_message"
                )
              ) {
                let message = `<p>Arquivo gerado com sucesso!<br /> Número do Borderô  ${
                  result.bordero_number.bordero_number ||
                  result.bordero_number.bordero_invoice
                }</p>`;
                this.showAlert("Sucesso!", message, "success");
              } else {
                showNotification(
                  "Sucesso",
                  "Arquivo gerado com  sucesso!",
                  "success"
                );
              }
            }

            if (this.dataIds) {
              if (this.cnabGroupId) {
                this.dataIds.forEach(function (dataId) {
                  $("#" + this.cnabGroupId)
                    .find('fields_to_edit[data-key-id="' + dataId + '"]')
                    .closest(".row.levels.level-four")
                    .remove();
                  $("#" + this.cnabGroupId)
                    .find('fields_to_edit[data-key-id="' + dataId + '"]')
                    .remove();
                });
              }
            }

            $("#bank_cnab_field").val(null).trigger("change");
            $("#cnab_remittance_field").val(null).trigger("change");

            //Se tem config é pq está vindo da tela de remessa de cnab a pagar
            if (
              this.config &&
              this.config.self &&
              this.config.self.listRemittancePayable
            ) {
              //Percorrendo os títulos gerados CNAB
              this.dataDescriptions.forEach((cpCnab) => {
                //Tem que percorrer a lista da tela. Cada linha é uma forma de pgto
                this.config.self.listRemittancePayable.forEach(
                  (remittancePayable) => {
                    //Dentro de cada forma, tem os títulos
                    if (!remittancePayable.elements) return;

                    let cp = remittancePayable.elements.find(
                      (cp) => cp.ID == cpCnab.ID
                    );
                    if (cp) {
                      if (cp.origin !== "inncash") {
                        cp.E2_PORTADO = result.code_bank;
                        cp.E2_NUMBOR = result.bordero_number.bordero_number;
                      } else {
                        cp.E2_PORTADO = result.code_bank;
                        cp.E2_NUMBOR = result.bordero_number.bordero_invoice;
                      }
                    }
                  }
                );
              });

              this.config.self.$forceUpdate();
              $("#m_generate_cnab").modal("hide");
            }
            this.$emit("success", 'generate-remittance');
            unblockPage();
            $("#m_generate_cnab").modal("hide");
          } else {
            if (result.message) {
              showNotification("Atenção", result.message, "warning");

              return;
            }
            if (
              this.$session.get(
                "user_config_autotoggle_remittance_confirm_message"
              )
            ) {
              this.showAlert(
                "Atenção!",
                "<p>Nenhum arquivo gerado. Verifique os títulos selecionados, talvez eles já estejam baixados, estão em borderô ou não estão liberados.</p>",
                "warning"
              );
            } else {
              showNotification(
                "Atenção",
                "Nenhum arquivo gerado. Verifique os títulos selecionados, talvez eles já estejam baixados, estão em borderô ou não estão liberados.",
                "warning"
              );
            }
            unblockPage();
          }
        })
        .fail((error) => {
          unblockPage();
          if (error.status == 500) {
            if (!error || !error.responseJSON || !error.responseJSON.message) {
              if (
                this.$session.get(
                  "user_config_autotoggle_remittance_confirm_message"
                )
              ) {
                this.showAlert(
                  "Atenção!",
                  "<p>Nenhum arquivo gerado. Verifique os títulos selecionados, talvez eles já estejam baixados, estão em borderô ou não estão liberados.</p>",
                  "warning"
                );
              } else {
                showNotification(
                  "Atenção",
                  "Nenhum arquivo gerado. Verifique os títulos selecionados, talvez eles já estejam baixados, estão em borderô ou não estão liberados.",
                  "warning"
                );
              }
              return;
            }

            console.log(error);

            showNotification("Atenção", error.responseJSON.message, "danger");
          } else if (error.status === 409) {
            showNotification(
              "Atenção",
              "Títulos selecionados podem estar presentes em outras remessas, favor recarregue a página",
              "danger"
            );
          } else {
            showNotification(
              "Atenção",
              "Oops! Ocorreu um erro ao gerar o cnab.",
              "danger"
            );
          }

          unblockPage();
        })

        .always(this.resetRouteName);
    },

    async dispatchFile(createdFileId, companyId) {
      try {
        await this.$http.post(
          API_BASE +
            `company-groups/${this.$session.get(
              "company_group_id"
            )}/remittances/upload/${createdFileId}`,
          {},
          {
            headers: {
              token: this.$session.get("token"),
              "company-ids": JSON.stringify([companyId]),
            },
          }
        );
      } catch (err) {
        console.log(err);
        showNotification(
          "Atenção",
          "Oops! Ocorreu um erro ao enviar o arquivo para NEOGRID.",
          "danger"
        );
      }
    },

    cancelGenerateCnab() {
      this.resetData();
      $("#m_generate_cnab").modal("hide");
      unblockPage();
    },
    validacaoDadosDePagamento() {
      let mensagemFormaDePagementoNaoInformada;
      let mensagemBoletosSemCodigoDeBarras;
      let mensagemSemDadosBancariosFornecedor;

      if (
        !this.dataDescriptions ||
        !this.config ||
        this.config.typeCnab != "CP"
      )
        return;

      this.dataDescriptions.forEach((data) => {
        data.error = false;
        data.textError = "";

        if (
          !data.payment_method ||
          (data.payment_method && !data.payment_method.code)
        ) {
          data.error = true;
          data.textError = "Forma de Pagamento não informada.";
          mensagemFormaDePagementoNaoInformada = true;
        }

        //Validação Boleto
        if (
          data.payment_method &&
          data.payment_method.type == "1" &&
          (!data.cod_bar ||
            data.cod_bar == "" ||
            data.cod_bar == "undefined" ||
            data.cod_bar == "null")
        ) {
          data.error = true;
          data.textError = "Sem código de barras.";
          mensagemBoletosSemCodigoDeBarras = true;
        }

        //Validação TED ou DOC
        if (
          data.payment_method &&
          (data.payment_method.type == "2" || data.payment_method.type == "3")
        ) {
          if (!data.bank) {
            data.error = true;
            data.textError = "Sem dados bancários do fornecedor.";
            mensagemSemDadosBancariosFornecedor = true;
          }

          if (
            data.bank &&
            (!data.bank.A2_BANCO ||
              !data.bank.A2_AGENCIA ||
              !data.bank.A2_NUMCON)
          ) {
            data.error = true;
            data.textError = "Dados bancários do fornecedor incompletos.";
            mensagemSemDadosBancariosFornecedor = true;
          }
        }
      });

      if (mensagemFormaDePagementoNaoInformada) {
        showNotification(
          "Forma de Pagamento",
          "Existem títulos sem forma de pagamento!",
          "warning"
        );
      }

      if (mensagemBoletosSemCodigoDeBarras) {
        showNotification(
          "Código de Barras",
          "Existem boletos sem o código de barras!",
          "warning"
        );
      }

      if (mensagemSemDadosBancariosFornecedor) {
        showNotification(
          "Dados Bancários",
          "Existem títulos sem os dados bancários do fornecedor!",
          "warning"
        );
      }

      //Ordena para mostrar os registros com erro primeiro.
      this.dataDescriptions = _.sortBy(
        this.dataDescriptions,
        (x) => x.error
      ).reverse();
    },
    resetData() {
      $(".select2CnabRemittance").select2("destroy");
      $(".select2Bank").select2("destroy");
      Object.assign(this.$data, initialState());
    },
    resetRouteName() {
      this.initialRoute = "";
    },
    showAlert(title, html, icon) {
      if (this.initialRoute == this.$route.name) {
        Swal.fire({
          title,
          html,
          icon,
          customClass: {
            confirmButton: "receipt-swal-btn",
          },
          allowEscapeKey: true,
          confirmButtonColor: "#34BFA3",
        });
      }
    },
  },
};
</script>

<style scoped>
.swal2-modal {
  width: 700px !important;
  min-width: 400px !important;
}
</style>
