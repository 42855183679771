export default class FilterParameterDDAConciliationList {
  public constructor(
    public initial_due_date: string,
    public end_due_date: string,
    public initial_processing_date: string,
    public end_processing_date: string,
    public companies: Array<number>,
    public status: Array<number>,
    public sort: string,
  ) {
  }

  public static make(data: any): FilterParameterDDAConciliationList {
    return new FilterParameterDDAConciliationList(
      data.initial_due_date_dda_conciliation_list ?? '',
      data.end_due_date_dda_conciliation_list ?? '',
      data.initial_processing_date_dda_conciliation_list ?? '',
      data.end_processing_date_dda_conciliation_list ?? '',
      data.companies_dda_conciliation_list ? JSON.parse(data.companies_dda_conciliation_list) : [],
      data.status_dda_conciliation_list ? JSON.parse(data.status_dda_conciliation_list) : [],
      data.sort_dda_conciliation_list,
    );
  }
}
