import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"900","persistent":""},model:{value:(_vm.showProviderSelect),callback:function ($$v) {_vm.showProviderSelect=$$v},expression:"showProviderSelect"}},[_c(VCard,[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c(VIcon,{attrs:{"color":"black"}},[_vm._v("mdi-close")])],1),_c(VContainer,[_c(VForm,{ref:"form"},[_c(VCombobox,{attrs:{"label":"Selecione o fornecedor","rules":[_vm.required],"no-filter":true,"loading":_vm.loading,"item-text":function (item) { return ((item.a2_nome) + " - (" + (_vm.formatCnpj(item.a2_cgc)) + ")"); },"items":_vm.providers,"item-value":"id","return-object":""},on:{"input":_vm.update,"update:search-input":_vm.loadProviders}}),_c(VRow,{attrs:{"align":"end","justify":"end"}},[_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.select}},[_vm._v("Selecionar")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }