<template>
	<v-select
		label="Tipo de cadastro"
		placeholder="Selecione o tipo de cadastro"
		v-model="model"
		:items="items"
		:readonly="disabled && model"
		item-text="text"
		item-value="value"
	/>
</template>

<script>
export default {
	name: 'SharingTypeSelect',
	//  two-way data-binding
	props: {
		value: [Object, String, Number],
		disabled: Boolean,
	},
	data() {
		return {
			loading: false,
			model: this.value,
			items: [
				{ text: 'Grupo de Empresa', value: 'G' },
				{ text: 'Empresa', value: 'C' },
			],
		};
	},
	watch: {
		model() {
			this.$emit('input', this.model);
		},
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
	},
};
</script>

<style>

</style>
