



























import {
  Component,
  Mixins,
  Prop,
  Watch,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';

@Component
export default class InnAutoCompleteFieldDebounce extends Mixins(ModelManagementMixin) {
  public timer: null|number = null;

  public modelSearch: string|number|null = null;

  @Prop({
    type: [String, Number],
    required: false,
    default: '',
  }) readonly search!: string|number|null;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) readonly customSelectionSlot!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) readonly customItemSlot!: boolean;

  @Watch('search')
  public changeModelSearch(search: string|number|null): void {
    this.modelSearch = search;

    if (search !== null || search !== '') {
      this.handleActions('keydown');
    }
  }

  public debounce(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {
      if (typeof this.modelSearch !== 'undefined') {
        this.$emit('on-finished-debounce', this.modelSearch);
      }
    }, 1000);
  }

  public handleActions(action: string): void {
    let modelValue: unknown = '';

    if (action === 'keydown') {
      modelValue = this.modelSearch;
    }

    this.$emit('on-finished-debounce', modelValue);
  }
}
