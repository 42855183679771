














































































































































































































































































































































































  import {
    Vue,
    Component,
    Prop,
    Emit,
    Ref,
    Watch,
  } from 'vue-property-decorator';

  import { VForm } from '@/types/VForm';
  import { DataOptions } from 'vuetify';

  import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';
  import FilterParametersRepository from '@/repositories/FilterParametersRepository';

  import StatementConciliation from '@/domain/models/StatementConciliation';
  import AccountReceivableType from '@/domain/models/AccountsReceivableType';
  import AcquittanceReasonOption from '@/domain/models/AcquittanceReasonOptions';
  import SettleAccount from '@/domain/models/SettleAccount';
  import FilterParameterWriteOffMovementList from '@/domain/models/filter-parameters/FilterParameterWriteOffMovementList';

  import IVDataTableHeader from '@/types/IVDataTableHeader';
  import ISelectOptions from '@/domain/interfaces/ISelectOptions';
  import ISettleAccountsReceivable from '@/domain/interfaces/ISettleAccountsReceivable';
  import IStatementConciliationWriteOff from '@/domain/interfaces/IStatementConciliationWriteOff';

  import OriginType from '@/domain/enums/StatementConciliationMovementOriginType';
  import ActionType from '@/domain/enums/StatementConciliationActionType';
  import SettleType from '@/domain/enums/SettleType';
  import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';

  import { formateDate, validateDateRange } from '@/utils/date';

  import CurrencyInput from './WriteOffMovementCurrencyInput.vue';

  import { DateRangeError } from '../utils/interfaces';
  import { formatCurrency, formatErrorForNotification } from '../utils';

  interface Rules {
    valueRequerid: any,
    arrayRequerid: any,
  }

  @Component({
    components: {
      CurrencyInput,
    },
  })
  export default class StatementConciliationWriteOffMovement extends Vue {
    @Ref('formFilter')
    readonly formFilter!: VForm;

    @Ref('formSave')
    readonly formSave!: VForm;

    @Prop(Boolean)
    readonly open!: boolean;

    @Prop(Number)
    readonly company!: number;

    @Prop(String)
    readonly bankId!: string;

    @Prop({
      type: Array,
    }) readonly records!: Array<StatementConciliation>;

    @Emit()
    close(): ActionType {
      return ActionType.WRITE_OFF;
    }

    @Emit()
    reload(): boolean {
      this.close();
      return true;
    }

    @Watch('open')
    changedOpen(open: boolean) {
      if (open) {
         this.data = {
          ...this.data,
          is_open: 1,
          per_page: this.dataTableOptions.itemsPerPage,
          page: this.dataTableOptions.page,
        };

        this.loadtypes();
        this.getGroupFilterParameters();
        this.loadReasons();
      } else {
        this.items = [];
        this.selectedItems = [];
        this.customers = [];
        this.reasons = [];
        this.data = {} as IStatementConciliationWriteOff;
        this.currentData = {} as IStatementConciliationWriteOff;
        this.serverItemsLength = 0;
        this.dataTableOptions.page = 1;
        this.dataTableOptions.itemsPerPage = 10;
        this.formFilter.resetValidation();
        this.formSave.resetValidation();
        this.reason = '';
        this.history = '';
        this.sortBy = '';
        this.sortDesc = false;
      }
    }

    @Watch('customersSearch')
    changecustomers(search: string):void {
      if (this.customersLoading || search === null || search.length < 3) return;
      this.loadcustomers(search);
    }

    @Watch('dataTableOptions')
    changedOptions(newValue: DataOptions, oldValue: DataOptions) {
      const { sortBy: sortByNew, sortDesc: sortDescNew } = newValue;
      const { sortBy: sortByOld, sortDesc: sortDescOld } = oldValue;
      if (!(sortByNew === sortByOld && sortDescNew === sortDescOld)) {
        this.updateSort(newValue);
      } else if (!this.loading && !this.loadingParameters && this.open) {
        this.currentData = {
          ...this.currentData,
          per_page: this.dataTableOptions.itemsPerPage,
          page: this.dataTableOptions.page,
        };

        this.persistFiltersOnDatabase(this.currentData, true);
        this.handlerLoadMovements(this.currentData);
        this.scrollToTableTop();
      }
    }

    readonly StatementConciliationRepository:
      StatementConciliationRepository = new StatementConciliationRepository();

    readonly filterParametersRepository:
      FilterParametersRepository = new FilterParametersRepository();

    readonly formateDate = formateDate;
    readonly formatCurrency = formatCurrency;

    customersLoading: boolean = false;
    customersSearch: string = '';
    customers: ISelectOptions<string>[] = [];

    typeLoading: boolean = false;
    types: Array<ISelectOptions> = [];

    reasonLoading: boolean = false;
    reasons: Array<ISelectOptions> = [];

    reason: string = '';
    history: string = '';

    selectedItems: Array<SettleAccount> = [];
    items: Array<SettleAccount> = [];
    data: IStatementConciliationWriteOff = {} as IStatementConciliationWriteOff;
    currentData: IStatementConciliationWriteOff = {} as IStatementConciliationWriteOff;
    hasOtherCurrency: boolean = false;
    loading: boolean = false;
    loadingParameters: boolean = false;

    disabledRow: boolean = false;

    serverItemsLength: number = 0;

    sortBy: string = '';
    sortDesc: boolean = false;

    dataTableOptions: DataOptions = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [this.sortBy],
      sortDesc: [this.sortDesc],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    };

    dataTableFooterOptions: Object = {
      'items-per-page-options': [10, 50, 100],
      'disable-items-per-page': false,
      'disable-pagination': false,
    }

    rules: Rules = {
      valueRequerid: (value: string | number) => !!value || 'Campo obrigatório!',
      arrayRequerid: (value: Array<any>) => value.length > 0 || 'Campo obrigatório!',
    };

    dateRangeError: DateRangeError = {
      movement: false,
      process: false,
      messageStart: 'Data inicial deve ser menor ou igual a data final',
      messageEnd: 'Data final deve ser menor ou igual a data inicial',
    };

    status: Array<ISelectOptions> = [
      { text: 'Em Aberto', value: 1 },
      { text: 'Baixado', value: 0 },
    ];

    headers: Array<IVDataTableHeader> = [
      { text: 'Emissão', value: 'emission_date', sortable: true },
      { text: 'Vencimento', value: 'due_date', sortable: false },
      { text: 'Título', value: 'title', sortable: false },
      { text: 'Razão Social', value: 'name', sortable: true },
      { text: 'Valor', value: 'value', sortable: false },
      { text: 'Saldo', value: 'balance', sortable: true },
      { text: 'Desconto', value: 'discount', sortable: false },
      { text: 'Juros', value: 'increase', sortable: false },
      { text: 'Recebido', value: 'received', sortable: false },
    ];

    headersWithTax: Array<IVDataTableHeader> = [
      { text: 'Emissão', value: 'emission_date', sortable: false },
      { text: 'Vencimento', value: 'due_date', sortable: false },
      { text: 'Título', value: 'title', sortable: false },
      { text: 'Razão Social', value: 'name', sortable: false },
      { text: 'Valor', value: 'value', sortable: false },
      { text: 'Saldo', value: 'balance', sortable: false },
      { text: 'Desconto', value: 'discount', sortable: false },
      { text: 'Juros', value: 'increase', sortable: false },
      { text: 'Taxa Contratada (%)', value: 'tax', sortable: false },
      { text: 'Recebido', value: 'received', sortable: false },
    ];

    get total(): number {
      let total = 0;

      if (this.records.length) {
        if (this.isConciliation) {
          const record = this.records[0];
          const bankMovement = record.movements.find((mov) => mov.origin === OriginType.BANK);
          const erpMovements = record.movements.filter((mov) => mov.origin === OriginType.ERP);

          const bankValue = parseFloat(`${bankMovement?.value}`);
          const erpsValue = erpMovements.reduce((value, erp) => {
            value += parseFloat(erp.value);
            return value;
          }, 0);

          total = bankValue - erpsValue;
        } else {
          total = this.records.reduce((value, item) => {
            value += parseFloat(item.movements[0].value);
            return value;
          }, 0);
        }
      }

      return total;
    }

    get selectedItemsTotal(): number {
      return this.selectedItems
        .reduce((total, item: any) => total + parseFloat(item.received), 0);
    }

    get diff(): number {
			return Number((this.total - this.selectedItemsTotal).toFixed(2));
    }

    get group(): number {
      return this.$session.get('company_group_id');
    }

    get isConciliation(): boolean {
      return this.records.length === 1
        && this.records[0].movements.some((item) => item.origin === OriginType.ERP);
    }

    validateAllDateRanges(data: IStatementConciliationWriteOff): boolean {
      const initialMovement = data.initial_emission_date;
      const endMovement = data.end_emission_date;
      const initialProcessing = data.initial_due_date;
      const endProcessing = data.end_due_date;

      const areValidMovementDates = validateDateRange(initialMovement, endMovement);
      const areValidProcessingDates = validateDateRange(initialProcessing, endProcessing);

      if (!areValidMovementDates) {
        this.dateRangeError.movement = true;
        this.$notification.error('Intevalo de emissão inválido!');
        this.hideDuplicateOverflow();
      } else {
        this.dateRangeError.movement = false;
      }

       if (!areValidProcessingDates) {
        this.dateRangeError.process = true;
        this.$notification.error('Intevalo de processamento inválido!');
        this.hideDuplicateOverflow();
      } else {
        this.dateRangeError.process = false;
      }

      if (!areValidMovementDates && !areValidProcessingDates) {
        this.$notification.error('Intevalo de emissão e de processamento inválidos!');
        this.hideDuplicateOverflow();
      }

      return areValidMovementDates && areValidProcessingDates;
    }

    onValueChange(item: SettleAccount): void {
      let { balance } = item;

      if (this.hasOtherCurrency) {
        const tx = typeof item.tax === 'string' ? parseFloat(item.tax) : item.tax;
        balance *= tx;
      }
      const increase = typeof item.increase === 'string' ? parseFloat(item.increase) : item.increase;
      const discount = typeof item.discount === 'string' ? parseFloat(item.discount) : item.discount;

      item.received = balance + increase - discount;
    }

    getTitleSelectedItem(item: SettleAccount): string {
      let title = '';

      title = `
        ${item.title || ''} - 
        ${formateDate(item.due_date)} - 
        ${formatCurrency(item.received)}
      `;

      return title;
    }

    removeSelectedItem(item: SettleAccount): void {
      const index = this.selectedItems.indexOf(item);
      this.selectedItems.splice(index, 1);
    }

    scrollToTableTop(): void {
      const doc = document.getElementById('writeoff-table') || {} as HTMLElement;
      doc.scrollIntoView({ behavior: 'smooth' });
    }

    async loadcustomers(search: string): Promise<void> {
      try {
        this.customersLoading = true;
        const limit = 15;
        const customers = await this.StatementConciliationRepository
            .getCustomers(this.group, this.company, search, limit);

        this.customers = this.customers.concat(customers);
      } catch (error: any) {
        const message = formatErrorForNotification(error);
        this.$notification.error(message);
        this.hideDuplicateOverflow();
      } finally {
        this.customersLoading = false;
      }
    }

    async loadtypes(): Promise<void> {
      try {
        this.typeLoading = true;
        const types = await this.StatementConciliationRepository
            .getTypes();

        this.types = types
          .map((types : AccountReceivableType) => {
              const {
                e1_tipo: value,
                e1_tipo: text,
              } = types;
              return { text, value };
          });
      } catch (error: any) {
        console.error(error);
        this.$notification.error('Não foi possível carregar os tipos de conta a receber.');
        this.hideDuplicateOverflow();
      } finally {
        this.typeLoading = false;
      }
    }

    async loadReasons(): Promise<void> {
      try {
        this.reasonLoading = true;
        const reasons = await this.StatementConciliationRepository
          .getReasons(this.group, this.company);

        this.reasons = reasons.map((reason: AcquittanceReasonOption) => {
          const {
            description: text,
            erpCode: value,
          } = reason;
          return { text, value };
        });
      } catch (error: any) {
        console.error(error);
        this.$notification.error('Não foi possível carregar os motivos de baixa.');
        this.hideDuplicateOverflow();
      } finally {
        this.reasonLoading = false;
      }
    }

    async loadSettleAccounts(filters: IStatementConciliationWriteOff): Promise<void> {
      const { data, meta } = await this.StatementConciliationRepository
        .getSettleAccounts(this.group, this.company, filters);

      data.forEach((item: SettleAccount) => {
        const index = this.selectedItems
          .findIndex((it: SettleAccount) => it.id === item.id);
        if (index !== -1) {
          item.increase = this.selectedItems[index].increase;
          item.discount = this.selectedItems[index].discount;
          item.received = this.selectedItems[index].received;
          item.tax = this.selectedItems[index].tax;
        } else {
          item.discount = typeof item.discount === 'string' ? parseFloat(item.discount) : item.discount;
          item.increase = typeof item.increase === 'string' ? parseFloat(item.increase) : item.increase;
          item.tax = typeof item.tax === 'string' ? parseFloat(item.tax) : item.tax;
        }
      });

      this.hasOtherCurrency = data.some((item) => item.currency != 1);

      this.items = data;
      this.serverItemsLength = meta;
      this.disabledRow = filters.is_open === 0;
    }

    public setFilters(filters: FilterParameterWriteOffMovementList): void {
      this.sortDesc = filters.direction;
      this.sortBy = filters.sort;

      const customers = filters.customers.map(({ value }) => `${value}`);

      this.data.initial_due_date = filters.initialDueDate;
      this.data.end_due_date = filters.endDueDate;
      this.data.initial_emission_date = filters.initialEmissionDate;
      this.data.end_emission_date = filters.endEmissionDate;
      this.data.number = filters.number;
      this.data.prefix = filters.prefix;
      this.data.is_open = filters.isOpen;
      this.data.types = filters.types;
      this.data.value = filters.value;
      this.data.customers = customers;
      this.data.sort = filters.rawSort;

      this.customers = filters.customers;

      this.currentData.initial_due_date = filters.initialDueDate;
      this.currentData.end_due_date = filters.endDueDate;
      this.currentData.initial_emission_date = filters.initialEmissionDate;
      this.currentData.end_emission_date = filters.endEmissionDate;
      this.currentData.number = filters.number;
      this.currentData.prefix = filters.prefix;
      this.currentData.is_open = filters.isOpen;
      this.currentData.types = filters.types;
      this.currentData.value = filters.value;
      this.currentData.customers = customers;
      this.currentData.sort = filters.rawSort;
    }

    public async getGroupFilterParameters(): Promise<void> {
      this.loading = true;

      try {
        const filterParameters = await this.filterParametersRepository
          .getFilterByGroup(GroupFilterParametersEnum.WRITE_OFF_MOVEMENT);
        const definedFilters = FilterParameterWriteOffMovementList.make(filterParameters);

        this.setFilters(definedFilters);
      } catch (error) {
        console.error(error);
        this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
      } finally {
        this.loading = false;
      }
    }

    async loadParameters(): Promise<void> {
      try {
        this.loadingParameters = true;
        const parameters = await this.StatementConciliationRepository
          .getFilterParametersSettleAccounts(this.group, this.company);

        const {
          initial_due_date: initialDueDate,
          end_due_date: endDueDate,
          initial_emission_date: initialEmissionDate,
          end_emission_date: endEmissionDate,
          number,
          prefix,
          customers,
          is_open: open,
          types,
          value,
          sort,
        } = parameters;

        this.data.initial_emission_date = initialEmissionDate.includes('/')
          ? initialEmissionDate
            .split('/')
            .reverse()
            .join('-')
          : initialEmissionDate;

        this.data.end_emission_date = endEmissionDate.includes('/')
          ? endEmissionDate
            .split('/')
            .reverse()
            .join('-')
          : endEmissionDate;

        this.data.initial_due_date = initialDueDate.includes('/')
          ? initialDueDate
            .split('/')
            .reverse()
            .join('-')
          : initialDueDate;

        this.data.end_due_date = endDueDate.includes('/')
          ? endDueDate
            .split('/')
            .reverse()
            .join('-')
          : endDueDate;

        this.data.is_open = open === '1' ? 1 : 0;
        this.data.number = number || undefined;
        this.data.prefix = prefix || undefined;
        this.data.value = value || undefined;
        this.data.types = types || undefined;
        this.data.sort = sort || undefined;

        if (sort) {
          this.dataTableOptions.sortBy = [sort.replace('-', '')];
          this.dataTableOptions.sortDesc = [sort.includes('-')];
        }

        if (customers?.length) {
          const customersOptions = customers.map((customer) => {
            const { code, id } = customer;
            return { text: code, value: id };
          });
          this.customers = customersOptions;

          const customersSelected = customersOptions.map(({ value }) => `${value}`);
          this.data.customers = customersSelected;
        }
      } catch (error) {
        console.error(error);
        this.$notification.error('Não foi possível carregar os parâmetros de busca.');
        this.hideDuplicateOverflow();
      } finally {
        this.loadingParameters = false;
      }
    }

    persistFiltersOnDatabase(
      parameters: IStatementConciliationWriteOff,
      onlyTableFilters: boolean = false,
    ): void {
      try {
        const formattedCustomers = JSON.stringify(
          this.getFullInfoFromSelectedCustomers(parameters.customers ?? [], this.customers),
        );

        let filtersArray = [
          { key: 'initial_due_date_write_off_movement', value: parameters.initial_due_date },
          { key: 'end_due_date_write_off_movement', value: parameters.end_due_date },
          { key: 'initial_emission_date_write_off_movement', value: parameters.initial_emission_date },
          { key: 'end_emission_date_write_off_movement', value: parameters.end_emission_date },
          { key: 'number_write_off_movement', value: parameters.number },
          { key: 'prefix_write_off_movement', value: parameters.prefix },
          { key: 'customers_write_off_movement', value: formattedCustomers },
          { key: 'is_open_write_off_movement', value: parameters.is_open },
          { key: 'types_write_off_movement', value: JSON.stringify(parameters.types) },
          { key: 'value_write_off_movement', value: parameters.value },
        ];

        if (onlyTableFilters) {
          filtersArray = [
            { key: 'sort_write_off_movement', value: parameters.sort },
          ];
        }

        this.filterParametersRepository
          .setFilter(GroupFilterParametersEnum.WRITE_OFF_MOVEMENT, filtersArray);
      } catch (error) {
        console.error(error);
      }
    }

    async handlerLoadMovements(parameters: IStatementConciliationWriteOff): Promise<void> {
      try {
        this.loading = true;
        this.dataTableFooterOptions = {
          ...this.dataTableFooterOptions,
          'disable-items-per-page': true,
          'disable-pagination': true,
        };

        await this.loadSettleAccounts(parameters);
      } catch (error: any) {
        console.error(error);
        this.$notification.error('Não foi possível carregar os registros.');
        this.hideDuplicateOverflow();
      } finally {
        this.dataTableFooterOptions = {
          ...this.dataTableFooterOptions,
          'disable-items-per-page': false,
          'disable-pagination': false,
        };
        this.loading = false;
      }
    }

    handlerFilter(): void {
      const validated = this.validateAllDateRanges(this.data)
        ? this.formFilter.validate()
        : false;

      if (validated) {
        const { value } = this.data;
        this.currentData = {
          ...this.data,
          value: value == '0' ? undefined : value,
          per_page: this.dataTableOptions.itemsPerPage,
          page: 1,
        };

        this.persistFiltersOnDatabase(this.currentData);
        this.handlerLoadMovements(this.currentData);
      }
    }

    handlerSave(): void {
      if (this.selectedItems.length <= 0) {
				this.$notification.error('Nenhuma movimentação selecionada!');
        this.hideDuplicateOverflow();
        return;
			}

      const validated = this.formSave.validate();

      if (validated) {
        this.save();
      }
    }

    async save() {
      try {
        this.$dialog.startLoading();

        let data: ISettleAccountsReceivable = {} as ISettleAccountsReceivable;

        const { history, reason } = this;

        const receivables = this.selectedItems.map((item) => {
          const {
            id,
            tax,
            increase: assessment,
            discount,
            received: receivedValue,
          } = item;
          return {
            id,
            tax,
            assessment,
            discount,
            received_value: receivedValue,
          };
        });

        const { bankId } = this;

        if (this.isConciliation) {
          const {
            id: conciliationId,
            date: dispositionDate,
          } = this.records[0];

          data = {
            conciliation_id: +conciliationId,
            disposition_date: dispositionDate,
            receivables,
            type: SettleType.CONCILIATION,
            bank_id: bankId,
            history,
            reason,
          };
        } else {
          const {
            date: dispositionDate,
          } = this.records[0];

          const bankMovements = this.records.map((item) => {
            const { id, date: dispositionDate } = item;
            return {
              dispositionDate,
              id: +id,
            };
          });

          data = {
            disposition_date: dispositionDate,
            bank_movements: bankMovements,
            receivables,
            type: SettleType.BANK_MOVEMENTS,
            bank_id: bankId,
            history,
            reason,
          };
        }

        const success = await this.StatementConciliationRepository
          .writeOffMovement(this.group, this.company, data);

        if (success) {
          this.$notification.success('Títulos baixados com sucesso!');
          this.reload();
        }
      } catch (error: any) {
        this.$dialog.stopLoading();
        const message = formatErrorForNotification(error);
        this.$notification.error(message);
        this.hideDuplicateOverflow();
      }
    }

    hideDuplicateOverflow() {
      this.$nextTick(() => {
        const doc = document.querySelector('html') || {} as HTMLElement;
        doc.classList.add('overflow-y-hidden');
      });
    }

    updateSort(value: DataOptions) {
      const { sortBy, sortDesc } = value;
      this.sortBy = sortBy[0] || '';
      this.sortDesc = sortDesc[0] || false;
      if (!this.loading && !this.loadingParameters) this.handlerSort(this.sortBy, this.sortDesc);
    }

    handlerSort(by: string, desc: boolean): void {
      const order = desc ? '-' : '';
      this.data.sort = by ? `${order}${by}` : undefined;

      this.currentData = {
        ...this.currentData,
        sort: this.data.sort,
        per_page: this.dataTableOptions.itemsPerPage,
        page: this.dataTableOptions.page,
      };

      this.persistFiltersOnDatabase(this.currentData, true);
      this.handlerLoadMovements(this.currentData);
    }

    public getFullInfoFromSelectedCustomers(
      selectedCustomers: string[],
      customers: ISelectOptions<string>[],
    ): ISelectOptions<string>[] {
      return customers.filter(({ value }) => selectedCustomers.includes(value));
    }
  }
