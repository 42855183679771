import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import ICreditRuleBuilder from '@/views/creditRules/interfaces/ICreditRuleBuilder';
import CreditOperatorType from '@/views/creditRules/types/CreditOperatorType';
import StringHelper from '@/helpers/StringHelper';

export default class CreditRuleBuilderSupport {
  private correlationBetweenOperatorsAndType = {
    conditional_operator: 'conditionOperators',
    math_operator: 'mathOperators',
    comparison_operator: 'comparisonOperators',
  } as const;

  private conditionOperators: ISelectOptions<string>[] = [
    { text: '(', value: 'left_parenthesis' },
    { text: ')', value: 'right_parenthesis' },
    { text: 'E', value: 'and' },
    { text: 'OU', value: 'or' },
    { text: 'IF', value: 'if' },
    { text: ',', value: ',' },
  ];
  private mathOperators: ISelectOptions<string>[] = [
    { text: '+', value: 'plus' },
    { text: '-', value: 'sub' },
    { text: '/', value: 'division' },
    { text: '*', value: 'multiplication' },
  ];
  private comparisonOperators: ISelectOptions<string>[] = [
    { text: '=', value: 'equal' },
    { text: '!=', value: 'not_equal' },
    { text: '>', value: 'greather' },
    { text: '>=', value: 'greather_or_equal' },
    { text: '<', value: 'lower' },
    { text: '<=', value: 'lower_or_equal' },
  ];

  public adjustValuesToTotalizerPatern(
    creditRuleBuilder: ICreditRuleBuilder,
    totalizerOptions: ISelectOptions<string>[],
  ): ICreditRuleBuilder {
    if (creditRuleBuilder.type !== 'totalizer') {
      return creditRuleBuilder;
    }

    const findedTotalizer = totalizerOptions
      .find(({ value }) => parseInt(value.split('-')[1], 10) === creditRuleBuilder.value as number)!;

    return {
      ...creditRuleBuilder,
      value: findedTotalizer.value,
    };
  }

  public getFormattedTextOfSpecificRuleValue(
    rule: ICreditRuleBuilder,
    totalizerOptions: ISelectOptions<string>[],
    variableOptions: ISelectOptions<number>[],
  ): string {
    let textValue = rule.value;

    if (rule.type === 'variable' || rule.type === 'totalizer') {
      const presentableTypeName = rule.type === 'variable' ? 'Variável' : 'Totalizador';

      let optionText = '';

      if (rule.type === 'variable') {
        const findedVariable = variableOptions
          .find(({ value }) => value === rule.value as number)!;

        optionText = findedVariable.text!;
      } else if (rule.type === 'totalizer') {
        const findedTotalizer = totalizerOptions
          .find(({ value }) => value === rule.value as string)!;

        optionText = findedTotalizer.text!;
      }

      textValue = `${presentableTypeName}: ${optionText}`;
    }

    if (rule.type === 'executable_expression') {
      textValue = `Expressão executável: ${rule.value}`;
    }

    if (rule.type === 'text') {
      textValue = `"${rule.value}"`;
    }

    if (rule.type === 'numeric' && rule.isPercentage) {
      textValue = `${rule.value}%`;
    }

    if (['conditional_operator', 'math_operator', 'comparison_operator'].includes(rule.type)) {
      const typeVariableName = this
        .correlationBetweenOperatorsAndType[rule.type as CreditOperatorType];
      const operatorObject = this[typeVariableName].find(({ value }) => value === rule.value)!;

      textValue = operatorObject.text!;
    }

    return textValue.toString();
  }

  public getCreditRuleBuilderFormattedWithoutText(
    creditRuleBuilder: ICreditRuleBuilder[],
  ): ICreditRuleBuilder[] {
    return creditRuleBuilder
      .map(({ type, value, isPercentage }) => {
        let definedValue = value;

        if (type === 'totalizer' && typeof value === 'string') {
          definedValue = parseInt(value.split('-')[1], 10);
        } else {
          definedValue = StringHelper.setStringWithSubQuoteValid(value);
        }

        return {
          type,
          value: definedValue,
          isPercentage: !!isPercentage,
        };
      });
  }

  public get getConditionOperators(): ISelectOptions<string>[] {
    return this.conditionOperators;
  }

  public get getMathOperators(): ISelectOptions<string>[] {
    return this.mathOperators;
  }

  public get getComparisonOperators(): ISelectOptions<string>[] {
    return this.comparisonOperators;
  }
}
