import ColumnToShow from '@/types/ColumnToShow';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import LooseObjectType from '@/types/LooseObjectType';
import VMenuOptions from '@/types/VMenuOptions';
import ISortAndDirectionDataTable from '@/helpers/interfaces/ISortAndDirectionDataTable';

export default class FilterParameterService {
  public static mountColumnToShowArray(
    data: any,
    consideredColumns: LooseObjectType<string>,
  ): ColumnToShow[] {
    const columnToShow = [];
    const objectKeys = Object.keys(consideredColumns);

    for (let i = 0; i < objectKeys.length; i += 1) {
      const actualKey = objectKeys[i];
      let willShowColumn = true;

      if (data[actualKey] !== undefined) {
        willShowColumn = parseInt(data[actualKey], 10) === 1;
      }

      columnToShow.push({
        value: consideredColumns[actualKey],
        show: willShowColumn,
      });
    }

    return columnToShow;
  }

  public static defineOrdenation(string: string): ISortAndDirectionDataTable {
    let sort = string;
    let direction = false;

    if (string && string.charAt(0) === '-') {
      direction = true;
      sort = string.substring(1);
    }

    return {
      sort,
      direction,
    };
  }

  public static defineColumnsToShowOnSpecificHeader(
    baseHeaders: IVDataTableHeader[],
    columnsToShow: ColumnToShow[],
  ): IVDataTableHeader[] {
    return baseHeaders.map((header) => {
      const columnToShow = columnsToShow.find(({ value }) => value === header.value);
      let willShowColumn = true;

      if (columnToShow !== undefined) {
        willShowColumn = columnToShow.show;
      }

      return {
        ...header,
        show: willShowColumn,
      };
    });
  }

  public static getFormattedColumnObjectOfOptionsButton(
    options: VMenuOptions[],
    selectedOption: string,
  ): VMenuOptions | null {
    const findedOption = options.find((actualOption) => actualOption.value === selectedOption);

    if (findedOption === undefined) {
      return null;
    }

    return findedOption;
  }
}
