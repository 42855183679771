






































































import {
  Component,
  Prop,
  Mixins,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import SecuritiesRepository from '@/repositories/SecuritiesRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientModule from '@/stores/modules/ClientModule';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import IChangeSecurityDueDateParams from '@/repositories/parameters/IChangeSecurityDueDateParams';
import IFiltersToConsiderOnFinancialStatement from '@/views/clients/interfaces/IFiltersToConsiderOnFinancialStatement';

@Component
export default class ChangeDueDateDialog extends Mixins<ModelManagementMixin<boolean>>(
  ModelManagementMixin,
) {
  @Prop({
    type: Array,
    required: true,
    default: () => ([]),
  }) readonly selectedSecuritiesIdCustomer!: string[];

  @Prop({
    type: Boolean,
    required: true,
    default: false,
  }) readonly isSelectingAllSecurities!: boolean;

  @Prop({
    type: Object,
    required: true,
    default: () => ({}),
  }) readonly filters!: IFiltersToConsiderOnFinancialStatement;

  @Prop({
    type: Number,
    required: true,
  }) readonly companyId!: number;

  public dueDate: string = '';
  public message: string = '';

  public loading: boolean = false;

  private readonly securitiesRepository: SecuritiesRepository = new SecuritiesRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly clientModule: ClientModule = getModule(ClientModule);

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get dialogWidth(): string {
    if (this.$vuetify.breakpoint.smAndDown) {
      return '100%';
    }

    if (this.$vuetify.breakpoint.md) {
      return '60%';
    }

    return '35%';
  }

  public get idCustomerFromRoute(): string {
    return this.$route.params.idCustomer;
  }

  public closeDialog(reloadScreen: boolean = false): void {
    this.$emit('on-close', reloadScreen);
  }

  public async changeSecurityDueDate(): Promise<void> {
    this.loading = true;

    const params: IChangeSecurityDueDateParams = {
      due_date: this.dueDate,
      message: this.message,
      type: 'account_receivable',
      securities_ids_customer: this.selectedSecuritiesIdCustomer,
      is_selecting_all_securities: this.isSelectingAllSecurities,
      number: this.filters.number,
      start_date_emission: this.filters.start_date_emission,
      end_date_emission: this.filters.end_date_emission,
      start_date_actual_expiration: this.filters.start_date_actual_expiration,
      end_date_actual_expiration: this.filters.end_date_actual_expiration,
      consider_to_expired: Boolean(this.filters.consider_only?.expired),
      consider_expired: Boolean(this.filters.consider_only?.to_expired),
      consider_paid_out: Boolean(this.filters.consider_only?.paid_out),
    };

    if (this.idCustomerFromRoute) {
      params.id_customer = this.idCustomerFromRoute;
    }

    try {
      await this.securitiesRepository.changeDueDateSecurities(
        this.companyGroupId,
        this.companyId,
        params,
      );

      this.$notification.info('A alteração de data de vencimento foi mandada para a fila de execução!');

      this.closeDialog(true);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao alterar a data de vencimento!');

      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public handleCancel(): void {
    this.closeDialog();
  }

  public async handleConfim(): Promise<void> {
    if (!this.dueDate) {
      this.$notification.error('O campo data de vencimento é obrigatório');

      return;
    }

    if (!this.message) {
      this.$notification.error('O campo de motivo é obrigatório');

      return;
    }

    await this.changeSecurityDueDate();
  }
}
