




























































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import { getModule } from 'vuex-module-decorators';

import VMenuOptions from '@/types/VMenuOptions';
import VDataTableHeader from '@/types/IVDataTableHeader';
import StatusEnum from '@/domain/enums/StatusEnum';

import BillingSignatureGroupsRepository from '@/repositories/BillingSignatureGroupsRepository';
import CompanySubscribersListModel from '@/domain/models/CompanySubscribersList';

import AuthenticationModule from '@/stores/modules/AuthenticationModule';

import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import StatusChip from '@/components/tables/StatusChip.vue';
import ActionButton from '@/components/tables/ActionButton.vue';
import DeleteDialog from './components/CompanySubscribersDeleteDialog.vue';

@Component({
  components: {
    ButtonFilterMenuOptions,
    StatusChip,
    ActionButton,
    DeleteDialog,
  },
})
export default class CompanySubscribersList extends Vue {
  public readonly repository
    : BillingSignatureGroupsRepository = new BillingSignatureGroupsRepository();
  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);

  public loading: boolean = false;

  public search: string = '';
  public defaultColumnToSearch: VMenuOptions|null = null;

  public open: boolean = false;
  public companySubscribers: CompanySubscribersListModel = {} as CompanySubscribersListModel;

  public companySubscribersList: CompanySubscribersListModel[] = [];

  public filterOptions: Array<VMenuOptions> = [
    { text: 'Status', value: 'status' },
    { text: 'Empresa/Grupo', value: 'name' },
    { text: 'Assinantes', value: 'subscribers' },
  ];

  public headers: Array<VDataTableHeader> = [
    {
      text: 'Status',
      value: 'status',
      sortable: true,
    },
    {
      text: 'Empresa/Grupo',
      value: 'name',
      sortable: true,
    },
    {
      text: 'Assinantes',
      value: 'subscribers',
      sortable: false,
    },
    {
      text: '',
      value: 'id',
      sortable: false,
      align: 'end',
    },
  ];

  public status = [
    {
      value: StatusEnum.ACTIVE,
      label: 'Ativo',
      color: 'success',
    },
    {
      value: StatusEnum.INACTIVE,
      label: 'Inativo',
      color: 'error',
    },
  ];

  public get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public mounted(): void {
    this.defaultColumnToSearch = { ...this.filterOptions[1] };

    this.loadCompanySubscribers();
  }

  public formatSubscribers(subscribers: string[]): string {
    return subscribers.toString();
  }

  public handleSearch(search: string): void {
    this.search = search;
  }

  public handleFilterChange(selectedOption: VMenuOptions): void {
    this.defaultColumnToSearch = { ...selectedOption };
  }

  public handleCreate(): void {
    this.$router.push({ name: 'CreateCompanySubscribers' });
  }

  public handleEdit(id: string): void {
    this.$router.push({ name: 'EditCompanySubscribers', params: { id } });
  }

  public handleDelete(companySubscribers: CompanySubscribersListModel): void {
    this.companySubscribers = companySubscribers;
    this.open = true;
  }

  public handleClose(reload: boolean): void {
    if (reload) {
      this.loadCompanySubscribers();
    }
  }

  public async loadCompanySubscribers(): Promise<void> {
    try {
      this.loading = true;

      this.companySubscribersList = await this.repository.getBillingSignatureGroupList(
          this.companyGroupId,
          this.companyIds,
        );

      if (!this.companySubscribersList.length) {
        this.$notification.warn('Nenhm assinante por empresa encontrado!');
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public filterByColumn(value: any, search: string, item: CompanySubscribersListModel): boolean {
    const valueToSearch = this.getValueFromSelectedColumnToSearch(item);

    if (!valueToSearch) {
      return false;
    }

    return valueToSearch.toLowerCase().search(search.toLowerCase()) !== -1;
  }

  public getValueFromSelectedColumnToSearch(
    companySubscriber: CompanySubscribersListModel,
  ): string {
    switch (this.defaultColumnToSearch!.value) {
      case 'status': return companySubscriber.status === StatusEnum.ACTIVE ? 'ativo' : 'inativo';
      case 'name': return companySubscriber.name;
      case 'subscribers': return companySubscriber.subscribers.toString();
      default: return '';
    }
  }
}
