










































































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import StatusType from '@/domain/enums/StatusType';
import ClientContact from '@/domain/models/ClientContact';
import ClientRepository from '@/repositories/ClientRepository';
import StatusChip from '@/components/tables/StatusChip.vue';
import ActionButton from '@/components/tables/ActionButton.vue';
import OtherContactDialog from './OtherContactsDialog.vue';

@Component({
  components: {
    OtherContactDialog,
    StatusChip,
    ActionButton,
  },
})
export default class OtherContants extends Vue {
  @Prop({
    type: String,
    default: '',
    required: true,
  }) customer!: string;

  @Prop({
    type: Number,
    default: 0,
    required: false,
  }) company!: number;

  public readonly clientRepository: ClientRepository = new ClientRepository();

  public type: string = '';
  public open: boolean = false;
  public loading: boolean = false;
  public contacts: Array<ClientContact> = [];
  public contact: ClientContact = {} as ClientContact;

  public headers: Array<IVDataTableHeader> = [
    {
      text: 'Status',
      value: 'status',
      sortable: true,
    },
    {
      text: 'Nome',
      value: 'name',
      sortable: true,
    },
    {
      text: 'Departamento',
      value: 'department',
      sortable: true,
    },
    {
      text: 'Telefone',
      value: 'phone',
      sortable: true,
    },
    {
      text: 'E-mail',
      value: 'email',
      sortable: true,
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'end',
    },
  ];

  public status = [
    {
      value: StatusType.ACTIVE,
      label: 'Ativo',
      color: 'success',
    },
    {
      value: StatusType.INACTIVE,
      label: 'Inativo',
      color: 'error',
    },
  ];

  public mounted() {
    this.loadContact();
  }

  public formatPhone(value: string): string {
    return value.length > 10
      ? value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
      : value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  public handleOpen(type: 'CREATE' | 'UPDATE' | 'DELETE', contact?: ClientContact) {
    this.type = type;

    this.contact = contact ?? {} as ClientContact;

    this.open = true;
  }

  public handleclose(reload: boolean) {
    if (reload) {
      this.contacts = [];
      this.loadContact();
    }
  }

  public async loadContact(): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      this.contacts = await this.clientRepository
        .getCustomerContacts(group, this.company, this.customer);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
