export default class GeneratePaymentRemittanceResponse {
  constructor(
    public url: string|null,
    public borderoNumber: string,
  ) {}

  static make(data: any): GeneratePaymentRemittanceResponse {
      return new GeneratePaymentRemittanceResponse(
        data.url,
        data.generated_bordero_number,
      );
  }
}
