






































import { defineComponent } from '@vue/composition-api';
import { DataOptions } from 'vuetify';
import DataTableHelper from '@/helpers/DataTableHelper';
import MainGrouperList from '@/domain/models/groupers/MainGrouperList';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import IDataTableOptions from '@/helpers/interfaces/IDataTableOptions';
import IStatusObject from '@/components/tables/interfaces/StatusObject';
import MainGroupersScreensShownEnum from '@/views/cashFlowGroupers/mainGrouper/enums/MainGroupersScreensShownEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';

export default defineComponent({
  name: 'MainGrouperDataTable',
  props: {
    mainGroupers: Array,
    totalItems: Number,
    isLoading: Boolean,
  },
  watch: {
    dataTableOptions() {
      this.$emit('data-table-changes');
    },
  },
  data() {
    const headers: IVDataTableHeader[] = [
      {
        text: 'Status',
        value: 'status',
        sortable: false,
        width: '10%',
      },
      {
        text: 'Nome',
        value: 'name',
      },
      {
        text: 'Telas Exibidas',
        value: 'screensToShown',
        sortable: false,
        width: '15%',
      },
      {
        text: 'Tabela utilizada',
        value: 'table',
        sortable: false,
        width: '15%',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '5%',
      },
    ];
    const availableStatus: IStatusObject[] = [
      {
        color: 'success',
        label: 'Ativo',
        value: 1,
      },
      {
        color: 'error',
        label: 'Desativado',
        value: 0,
      },
    ];

    const dataTableOptions: DataOptions = DataTableHelper.getDefaultDataTableOptions();
    const footerOptions: DataTableFooterOptions = DataTableHelper.getDefaultFooterProps();

    return {
      headers,
      availableStatus,
      dataTableOptions,
      footerOptions,
    };
  },
  methods: {
    getDataTableOptions(): IDataTableOptions {
      const { sortDesc, sortBy } = this.dataTableOptions;

      return {
        page: this.dataTableOptions.page,
        itemsPerPage: this.dataTableOptions.itemsPerPage,
        formattedSort: DataTableHelper.formatTableOrdenationColumn(sortDesc[0], sortBy[0]),
      };
    },
    goToSingularMainGrouper({ id }: MainGrouperList): void {
      this.$router.push({
        name: 'MainGrouperCashFlowUpdate',
        params: { id: String(id) },
      });
    },
    setScreensToShownLegibleString({ screensToShown }: MainGrouperList): string {
      const translatedScreensToShown = screensToShown.map((screen) => {
        switch (screen) {
          case MainGroupersScreensShownEnum.PREDICTED_FLOW: return 'Fluxo Previsto';
          case MainGroupersScreensShownEnum.REALIZED_FLOW: return 'Fluxo Realizado';
          default: return '';
        }
      });

      return translatedScreensToShown.join(', ');
    },
    setTableLegibleString({ table }: MainGrouperList): string {
      switch (table) {
          case CashFlowTablesEnum.ACCOUNTS_PAYABLE: return 'Contas a Pagar';
          case CashFlowTablesEnum.ACCOUNTS_RECEIVABLE: return 'Contas a Receber';
          case CashFlowTablesEnum.BANKS: return 'Bancos';
          default: return '';
        }
    },
    handleRemoveMainGrouper(item: MainGrouperList): void {
      this.$emit('delete', item.id);
    },
  },
});
