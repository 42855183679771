import Vue from 'vue';

interface State {
  filter: boolean;
  manager: boolean;
  onFilter: boolean;
  approve: boolean;
  disapprove: boolean;
}

// eslint-disable-next-line
let instance: BillingPendencyStore;

class BillingPendencyStore {
  state: State = Vue.observable({
    filter: true,
    manager: false,
    onFilter: false,
    approve: false,
    disapprove: false,
  });

  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }
}

export default BillingPendencyStore;
