import EBillingPendencyPeriod from '@/domain/enums/BillingPendencyPeriod';
import ColumnToShow from '@/types/ColumnToShow';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';

export default class FilterParameterBillingPendencyList {
  public constructor(
    public search: string,
    public companies: Array<number>,
    public accountTypes: Array<string>,
    public saleGroups: Array<string>,
    public paymentConditions: Array<string>,
    public periodies: Array<EBillingPendencyPeriod>,
    public days: number | undefined,
    public columnsToShow: Array<ColumnToShow>,
  ) {
  }

  public static make(data: any): FilterParameterBillingPendencyList {
    const relationship = 'billing_pendency_list';

    const dataColumn = {
      data_table_billing_pendency_list_show_column_name: 'name',
      data_table_billing_pendency_list_show_column_overdues: 'overdues',
      data_table_billing_pendency_list_show_column_days_overdue: 'daysOverdue',
      data_table_billing_pendency_list_show_column_total_overdue: 'totalOverdue',
      data_table_billing_pendency_list_show_column_total_due: 'totalDue',
      data_table_billing_pendency_list_show_column_total_overdue_overall: 'totalOverdueOverall',
      data_table_billing_pendency_list_show_column_total_due_overall: 'totalDueOverall',
      data_table_billing_pendency_list_show_column_operator: 'operator',
      data_table_billing_pendency_list_show_column_date: 'date',
    };

    return new FilterParameterBillingPendencyList(
      data[`search_${relationship}`] ?? '',
      data[`companies_${relationship}`] ? JSON.parse(data[`companies_${relationship}`]) : [],
      data[`account_types_${relationship}`] ? JSON.parse(data[`account_types_${relationship}`]) : [],
      data[`sale_groups_${relationship}`] ? JSON.parse(data[`sale_groups_${relationship}`]) : [],
      data[`payment_conditions_${relationship}`] ? JSON.parse(data[`payment_conditions_${relationship}`]) : [],
      data[`periodies_${relationship}`] ? JSON.parse(data[`periodies_${relationship}`]) : [],
      data[`days_${relationship}`] ? +data[`days_${relationship}`] : undefined,
      FilterParameterHelper.mountColumnToShowArray(data, dataColumn),
    );
  }
}
