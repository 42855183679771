import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.files.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 1)?_c(VChip,{attrs:{"dense":"","small":"","color":"green","text-color":"white"}},[_vm._v(" Ativo ")]):_c(VChip,{attrs:{"dense":"","small":""}},[_vm._v(" Inativo ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at)))])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('inn-icon-btn',{attrs:{"icon":"mdi-download"},on:{"click":function($event){return _vm.handleDownload(item)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }