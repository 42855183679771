





































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';
import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';

import ArtificialInvoice from '@/domain/models/ArtificialInvoice';
import ActionType from '@/domain/enums/DDAConciliationActionType';

import { formateDate } from '@/utils/date';
import { formateCNPJ, formateCurrency, formateErrorForNotification } from '../utils';

@Component
export default class DDAConciliationInvoiceView extends Vue {
  @Prop(Boolean) readonly open!: boolean;

  @Prop({
    type: Object as () => DDAConciliationMovement,
  }) readonly item!: DDAConciliationMovement;

  @Emit()
  close(): ActionType {
    return ActionType.VIEW_INVOICE;
  }

  @Watch('open')
  opened(value: boolean) {
    if (value) {
      this.loadInvoice();
    }
  }

  readonly DDAConciliationRepository:
    DDAConciliationRepository = new DDAConciliationRepository();

  readonly formateDate = formateDate;
  readonly formateCurrency = formateCurrency;
  readonly formateCNPJ = formateCNPJ;

  loading: boolean = false;
  invoice: ArtificialInvoice = {} as ArtificialInvoice;

  get invoiceTitle() {
    return this.item.title;
  }

  get invoiceValue() {
    return this.item.value;
  }

  get invoiceEmission() {
    return this.item.emission_date;
  }

  get invoiceDue() {
    return this.item.due_date;
  }

  async loadInvoice() {
    try {
      this.loading = true;

      const group = this.$session.get('company_group_id');
      const { artificial_invoice_id: id, company_id: company } = this.item;

      const response = await this.DDAConciliationRepository.getInvoice(group, company, `${id}`);

      this.invoice = response;
    } catch (error: any) {
      const message = formateErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
