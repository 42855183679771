import Api from './Api';

/**
 * REST
 * abstracao para os metodos mais comuns de Rest
 * as funcoes usadas sao as mesmas do padrao CRUD
 * pode-se criar funcoes com qualquer um dos metodos HTTP
 */

/**
 * @typedef {Rest}
 */
export default class Rest extends Api {
	/**
	 * @type {String}
	 */
	id = 'id'

	/**
	 * @param {String} resource
	 * @param {Object} options
	 * @param {Object} http
	 */
	constructor(options = {}, http = null) {
		super(new.target.resource, options, http);
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	store(record, url = '') {
		return this.post(url, record);
	}

	/**
	 * Apenas mudança de nomenclatura para o metodo post
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	send(record, url = '', options = {}) {
		return this.post(url, record, options);
	}

	/**
	 * @param {String|Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	index(specific = '', filter = null) {
		return this.get(`/${specific}`, filter);
	}

	/**
	 * @param {String|Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	show(record, filters = null) {
		return this.get(`/${this.getId(record)}`, filters);
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	update(record) {
		return this.patch(`/${this.getId(record)}`, record);
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	destroy(record, options = {}) {
		return this.delete(`/${this.getId(record)}`, options);
	}

	/**
	 * @param {String|Object} record
	 * @returns {String}
	 */
	getId(record) {
		if (typeof record === 'object') {
			return record[this.id];
		}
		return String(record);
	}
}
