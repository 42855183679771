import ColumnToShow from '@/types/ColumnToShow';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';

export default class FilterParameterPaymentRemittancesList {
  public constructor(
    public prefixes: Array<string>,
    public dateInitial: string,
    public dateEnd: string,
    public companies: Array<number>,
    public paymentMethods: Array<string>,
    public paymentData: Array<string>,
    public bankTitles: Array<string>,
    public bankSuppliers: Array<string>,
    public search: string,
    public valueMin: number | null,
    public valueMax: number | null,
    public status: Array<number>,
    public bankSummaries: Array<string>,
    public groupBy: Array<string>,
    public type: string,
    public supplierName: string,
    public supplierIds: Array<string>,
    public inclusionDateInitial: string,
    public inclusionDateFinal: string,
    public riskTaken: string[],
    public columnsToShow: ColumnToShow[],
    public columnsToSummary: ColumnToShow[],
  ) {
  }

  public static make(data: any): FilterParameterPaymentRemittancesList {
    const dataColumn = {
      data_table_payment_remittances_show_column_title: 'title',
      data_table_payment_remittances_show_due_date: 'due_date',
      data_table_payment_remittances_show_column_ported: 'ported',
      data_table_payment_remittances_show_column_form_of_payment_description: 'form_of_payment_description',
      data_table_payment_remittances_show_column_data_of_payment: 'data_of_payment',
      data_table_payment_remittances_show_column_user: 'user',
      data_table_payment_remittances_show_column_fees: 'fees',
      data_table_payment_remittances_show_column_discount: 'discount',
      data_table_payment_remittances_show_column_increase: 'increase',
      data_table_payment_remittances_show_column_decrease: 'decrease',
      data_table_payment_remittances_show_column_total: 'total',
      data_table_payment_remittances_show_column_sent: 'sent',
      data_table_payment_remittances_show_column_approved: 'approved',
      data_table_payment_remittances_show_column_status: 'status',
    };

    const dataColumnSummary = {
      data_table_payment_remittances_summary_show_column_title: 'title',
      data_table_payment_remittances_summary_show_column_sent: 'sent',
      data_table_payment_remittances_summary_show_column_approved: 'approved',
      data_table_payment_remittances_summary_show_column_fees: 'fees',
      data_table_payment_remittances_summary_show_column_discount: 'discount',
      data_table_payment_remittances_summary_show_column_addition: 'addition',
      data_table_payment_remittances_summary_show_column_decrease: 'decrease',
    };

    return new FilterParameterPaymentRemittancesList(
      data.prefixes_remittances_list
        ? JSON.parse(data.prefixes_remittances_list)
        : [],
      data.date_initial_payment_remittances_list ?? '',
      data.date_end_payment_remittances_list ?? '',
      data.companies_payment_remittances_list
        ? JSON.parse(data.companies_payment_remittances_list)
        : [],
      data.payment_methods_payment_remittances_list
        ? JSON.parse(data.payment_methods_payment_remittances_list)
        : [],
      data.payment_data_payment_remittances_list
        ? JSON.parse(data.payment_data_payment_remittances_list)
        : [],
      data.bank_titles_payment_remittances_list
        ? JSON.parse(data.bank_titles_payment_remittances_list)
        : [],
      data.bank_suppliers_payment_remittances_list
        ? JSON.parse(data.bank_suppliers_payment_remittances_list)
        : [],
      data.search_payment_remittances_list ?? '',
      data.value_min_payment_remittances_list != 0
        ? +data.value_min_payment_remittances_list
        : null,
      data.value_max_payment_remittances_list != 0
        ? +data.value_max_payment_remittances_list
        : null,
      data.status_payment_remittances_list
        ? JSON.parse(data.status_payment_remittances_list)
        : [],
      data.bank_summaries_payment_remittances_list
        ? JSON.parse(data.bank_summaries_payment_remittances_list)
        : [],
      data.group_by_payment_remittances_list
        ? JSON.parse(data.group_by_payment_remittances_list)
        : [],
      data.type_payment_remittances_list ?? '',
      data.supplier_name_payment_remittances_list ?? '',
      [],
      data.inclusion_date_initial_payment_remittances_list ?? '',
      data.inclusion_date_final_payment_remittances_list ?? '',
      data.risk_taken_payment_remittances_list
        ? JSON.parse(data.risk_taken_payment_remittances_list)
        : [],
      FilterParameterHelper.mountColumnToShowArray(data, dataColumn),
      FilterParameterHelper.mountColumnToShowArray(data, dataColumnSummary),
    );
  }
}
