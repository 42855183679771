export default class BillingPendencyTransfer {
  constructor(
    public id: number,
    public idCustomer: string,
    public requester: string,
    public oldOperator: string,
    public newOperator: string,
    public pendencies: number,
    public economicGroupId: number|null,
    public customerId: string|null,
  ) {}

  static make(data: any): BillingPendencyTransfer {
    return new BillingPendencyTransfer(
      data.id ?? 0,
      data.id_customer ?? '',
      data.origin_operator ?? '',
      data.origin_operator ?? '',
      data.destiny_operator ?? '',
      data.total_pendencies ?? 0,
      data.economic_group_id ?? null,
      data.customer_id ?? null,
    );
  }
}
