export default class ArtificialInvoiceLink {
  constructor(
    public document: string,
    public name: string,
    public value: number,
    public due_date: string,
    public emission_date: string,
    public title: string,
  ) { }

  static make(data: any): ArtificialInvoiceLink {
    const value = parseFloat(data.value);
    return new ArtificialInvoiceLink(
      data.document,
      data.name,
      value,
      data.due_date,
      data.emission_date,
      data.title,
    );
  }
}
