import {
 formatToSpecificFormat,
 defaultDateFormat,
 timeFormat,
 dateTimeFormatWithSeconds,
} from '@/utils/date';

export default class SingularNews {
  constructor(
    public id: number,
    public status: string,
    public title: string,
    public text: string,
    public startDate: string,
    public startTime: string,
    public finalDate: string,
    public finalTime: string,
    public icon: string,
    public link: string,
  ) {}

  static make(data: any): SingularNews {
    const startDate = formatToSpecificFormat(
      data.start_date_time, defaultDateFormat, dateTimeFormatWithSeconds,
    );
    const startTime = formatToSpecificFormat(
      data.start_date_time, timeFormat, dateTimeFormatWithSeconds,
    );
    const finalDate = formatToSpecificFormat(
      data.final_date_time, defaultDateFormat, dateTimeFormatWithSeconds,
    );
    const finalTime = formatToSpecificFormat(
      data.final_date_time, timeFormat, dateTimeFormatWithSeconds,
    );

    return new SingularNews(
      data.id,
      data.status === 1 ? 'active' : 'disabled',
      data.title,
      data.text,
      startDate,
      startTime,
      finalDate,
      finalTime,
      data.icon,
      data.link,
    );
  }
}
