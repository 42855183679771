import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import store from '@/stores';

interface IUserGroupInformation {
  userGroupName : string,
  moduleName : string,
}

@Module({
  dynamic: true,
  namespaced: true,
  name: 'userGroup',
  store,
})
export default class UserGroupModule extends VuexModule {
  userGroupName : string = '';
  moduleName: string = '';

  @Mutation
  persist(information : IUserGroupInformation) {
    this.userGroupName = information.userGroupName;
    this.moduleName = information.moduleName;
  }

  @Mutation
  clear() {
    this.userGroupName = '';
    this.moduleName = '';
  }
}
