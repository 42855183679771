<template>
  <v-tabs
    v-model="model"
    :centered="$vuetify.breakpoint.mdAndDown"
    show-arrows
  >
    <v-tab v-for="(item, i) in tabs" :key="i" @click="handleClick(item)">
      {{ item.name }}
    </v-tab>
  </v-tabs>
</template>

<script>
import ModelManagementMixin from '@/mixins/ModelManagementMixin';

export default {
  mixins: [ModelManagementMixin],
  name: 'NavigationTabs',
  props: {
    tabs: {
      type: Array,
      validator: (prop) => prop.every((p) => 'name' in p && 'path' in p),
      required: true,
    },
  },
  methods: {
    handleClick(item) {
      this.$router.replace({ path: item.path });
    },
  },
};
</script>

<style></style>
