import InstructionRemittance from '@/domain/models/InstructionRemittance';
import { EInstructionRemittanceField, EInstructionRemittanceFieldTranslate } from '@/domain/enums/InstructionRemittanceField';
import { EInstructionRemittanceType, EInstructionRemittanceTypeTranslate } from '@/domain/enums/InstructionRemittanceType';
import { toCurrency } from '@/utils/';

export function formatTitle(item: InstructionRemittance): string {
  let title: string = '';

  title += item.branch ? item.branch : '';
  title += item.name ? `${title.length ? ' - ' : ''}${item.name}` : '';
  title += item.prefix ? `${title.length ? ' - ' : ''}${item.prefix}` : '';
  title += item.number ? `${title.length ? ' - ' : ''}${item.number}` : '';
  title += item.installment ? `${title.length ? ' - ' : ''}${item.installment}` : '';
  title += item.type ? `${title.length ? ' - ' : ''}${item.type}` : '';

  return title;
}

export function translateField(value: EInstructionRemittanceField): string {
  return EInstructionRemittanceFieldTranslate[value];
}

export function translateInstruction(value: EInstructionRemittanceType): string {
  return EInstructionRemittanceTypeTranslate[value];
}

export function formatFieldValue(value: string, item: InstructionRemittance): string {
  const fieldsOfCurrency = [
    EInstructionRemittanceField.e1_acresc,
    EInstructionRemittanceField.e1_decresc,
    EInstructionRemittanceField.e1_saldo,
  ];

  let newValue = value;

  if (item.field === EInstructionRemittanceField.e1_zdtflux || value.includes('-')) {
    newValue = value.split('-').reverse().join('/');
  }

  if (fieldsOfCurrency.includes(item.field) || value.includes('.')) {
    newValue = toCurrency(value, {}, true);
  }

  return newValue;
}

export function formatCurrency(value: any): any {
  return toCurrency(value, {}, true);
}
