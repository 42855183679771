<template>
	<div class="m-portlet m-portlet--mobile">
	    <div class="m-portlet__head">
	        <div class="m-portlet__head-caption">
	            <div class="m-portlet__head-title">
	            </div>
	        </div>
	        <div class="m-portlet__head-tools">
							<router-link :to="{ name: 'block' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
							<button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
	        </div>
	    </div>
	    <div class="m-portlet__body">
	        <!--begin: Search Form -->
	        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
	            <div class="row" id="frmBlock">
	            	<input type="hidden" name="block_id" id="block_id" v-model="block_id">
	            	<input type="hidden" name="company_group_id" id="company_group_id" value="">
	                <div class="col-md-12">
	                    <div class="form-group">
	                        <label>Empresa</label>
	                        <select class="form-control select2 hide" field-name="Empresa" id="company_id" name="company_id" style="width: 100%;">
	                            <option value="">Todas</option>
	                            <option v-for="company in companys" :value="company.id" :key="company.id">{{ company.name }}</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-6">
	                    <div class="form-group">
	                        <label>Grupo de usuário</label>
	                        <select class="form-control select2 hide" field-name="Groupo de usuário" id="user_group_id" name="user_group_id" style="width: 100%;" required>
	                            <option v-for="userGroup in userGroups" :value="userGroup.id" :key="userGroup.id">{{ userGroup.description }}</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-6">
	                    <div class="form-group">
	                        <label>Tipo</label>
	                        <select class="form-control select2 hide" field-name="Tipo" id="type" name="type" style="width: 100%;" required>
	                            <option value="A">Acrescimo</option>
	                            <option value="D">Decrescimo</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Limite máximo</label>
	                        <input type="text" class="form-control" field-name="Limite máximo" id="max_limit" name="max_limit" required>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Operador</label>
	                        <select class="form-control select2 hide" field-name="Operador" id="operator" name="operator" style="width: 100%;" required>
	                            <option value="%">% (Percentual a.m.)</option>
	                            <option value="$">$ (Valor)</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Tipo de calculo</label>
	                        <select class="form-control select2 hide" field-name="Tipo de calculo" id="calculate_type" name="calculate_type" style="width: 100%;" required>
	                            <option value="M">Ao mes</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Data (qtde)</label>
	                        <input type="text" class="form-control" field-name="Data (qtde)" id="date_quantity" name="date_quantity" required>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Operador</label>
	                        <select class="form-control select2 hide" field-name="Operador" id="date_operator" name="date_operator" style="width: 100%;" required>
	                            <option value="D">Dias</option>
	                            <option value="M">Meses</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-4">
	                    <div class="form-group">
	                        <label>Tipo de bloqueio</label>
	                        <select class="form-control select2 hide" field-name="Tipo de bloqueio" id="block_type" name="block_type" style="width: 100%;" required>
	                            <option value="">Selecione...</option>
	                            <option value="B">Bloqueia</option>
	                            <option value="C">Cancela liberação</option>
	                        </select>
	                    </div>
	                </div>
	                <div class="col-md-3">
	                    <div class="form-group">
	                        <label>Status</label>
	                        <select class="form-control select2 hide" field-name="Status" id="status" name="status" style="width: 100%;" required>
	                            <option value="0">Inativo</option>
	                            <option value="1">Ativo</option>
	                        </select>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</template>

<script>
		import {API_BASE} from '@config/api';

    export default {
        data () {
            return {
            	companys: [],
            	userGroups: [],
            	block_id: this.$route.params.block_id
            }
        },

        mounted: function () {
            this.loadCompanys();
            this.loadUserGroups();
            this.loadMethod();
        	this.loadBlock();
        },

        methods: {
            loadCompanys: function () {
            	blockPage('Carregando os dados...');

                this.$http.get(API_BASE+'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	function (result) {
	                    this.companys = result.body.data
		            	unblockPage();
                	},
                	function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                ).bind(this)
            },

            loadUserGroups: function () {
                this.$http.get(API_BASE+'usergroup/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	function (result) {
	                    this.userGroups = result.body.data
		            	unblockPage();
                	},
                	function (error) {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                ).bind(this)
            },

        	loadBlock: function () {
        		var self = this;

				$.ajax({
					method: "GET",
					url: API_BASE+'block/listOne/'+self.$route.params.block_id,
                    headers: {
                        'token': self.$session.get('token')
                    }
				}).done(function(result) {
					var statusDesc = "", typeDesc = "", operatorDesc = "", calculateDesc = "", dateOperatorDesc = "", blockDesc = "";

					$('#company_group_id').val(result.data[0].company_group_id);
					$('#company_id').val(result.data[0].company_id);
					$('#user_group_id').val(result.data[0].user_group_id);
					$('#type').val(result.data[0].type);
					$('#max_limit').val(result.data[0].max_limit);
					$('#operator').val(result.data[0].operator);
					$('#calculate_type').val(result.data[0].calculate_type);
					$('#date_quantity').val(result.data[0].date_quantity);
					$('#date_operator').val(result.data[0].date_operator);
					$('#status').val(result.data[0].status);
					$('#block_type').val(result.data[0].block_type).trigger('change');

					if ($.trim(result.data[0].company_name) != '') {
						$('#select2-company_id-container').attr('title', result.data[0].company_name);
						$('#select2-company_id-container').html(result.data[0].company_name);
					}

					$('#select2-user_group_id-container').attr('title', result.data[0].user_group_name);
					$('#select2-user_group_id-container').html(result.data[0].user_group_name);

					if (result.data[0].type == "A") {
						typeDesc = "Acréscimo";
					} else if (result.data[0].type == "D") {
						typeDesc = "Decréscimo";
					}

					$('#select2-type-container').attr('title', typeDesc);
					$('#select2-type-container').html(typeDesc);

					if (result.data[0].operator == "%") {
						operatorDesc = "% (Percentual)";
					} else if (result.data[0].operator == "$") {
						operatorDesc = "$ (Valor)";
					}
					
					$('#select2-operator-container').attr('title', operatorDesc);
					$('#select2-operator-container').html(operatorDesc);

					if (result.data[0].calculate_type == "M") {
						calculateDesc = "Ao mes";
					} else if (result.data[0].calculate_type == "P") {
						calculateDesc = "S/Valor (prazo médio)";
					}
					
					$('#select2-calculate_type-container').attr('title', calculateDesc);
					$('#select2-calculate_type-container').html(calculateDesc);

					if (result.data[0].date_operator == "D") {
						dateOperatorDesc = "Dias";
					} else if (result.data[0].date_operator == "M") {
						dateOperatorDesc = "Meses";
					}
					
					$('#select2-date_operator-container').attr('title', dateOperatorDesc);
					$('#select2-date_operator-container').html(dateOperatorDesc);

					if (result.data[0].status == 1) {
						statusDesc = "Ativo";
					} else {
						statusDesc = "Inativo";
					}
					$('#select2-status-container').attr('title', statusDesc);
					$('#select2-status-container').html(statusDesc);
				}).fail(function(error){
					console.log(error.responseText);
				});
        	},

            loadMethod: function () {
            	var self = this;

				$(".select2").select2();
				$("#cnpj").mask("00.000.000/0000-00");
				$("#phone").mask("(00) 00000-0000");
				$("#zipcode").mask("0000-000");

				$("#btn-save").click(function(){
					var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';
		            var btn = $(this);

		            btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

					$.each($('input, select', '#frmBlock'),function(){
					    var $this = $(this);

					    if($.trim(this.value) == '' && $this.attr('required') == 'required'){
					        exitButton = true;
					        fieldName = $this.attr('field-name');

					        $(".form-group:has(#"+this.id+")").addClass('has-danger');
					        $("#"+this.id).addClass('input-error');
					        $("#"+this.id).css('border-color','red');
					        return false;
					    }else{
					        exitButton = false;
					        arrayData[i] = {};
					        arrayData[i][this.id] = this.value;
					    }

					    i++;
					});

					if(exitButton){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
					    showNotification('Atenção', 'O campo <strong>'+fieldName+'</strong> não foi informado!', 'warning');
					    return false;
					}

					$.ajax({
						method: "POST",
						url: API_BASE+'block/edit',
	                    headers: {
	                        'token': self.$session.get('token')
	                    },
						data: {
							arrayData: JSON.stringify(arrayData)
						}
					}).done(function(result) {
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

						if(result.error == false){
							showNotification('Sucesso', 'Bloqueio alterado!','success');

							$.each($('input', '#frmBlock'),function(){
								this.value = '';
							});
						}else{
							showNotification('Atenção',result.error, 'danger');
							return false;
						}
					}).fail(function(error){
	                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						console.log(error.responseText);
					});
				});

				$('input').keyup(function(){
					if ($("#"+this.id).hasClass('input-error')) {
						$("#"+this.id).removeClass('input-error');
						$("#"+this.id).css('border-color', '#ebedf2');
						$(".form-group:has(#"+this.id+")").removeClass('has-danger');
					}
				});
            }
        }
    }
</script>
