import ConciliationJobIntegrationError from '@/domain/models/ConciliationJobIntegrationError';
import Repository from '@/repositories/base/Repository';

export default class JobsConciliationRepository extends Repository {
  async getIntegrationFromConciliationId(
    companyGroupId: number,
    companyId: number,
    conciliationId: number,
  ): Promise<ConciliationJobIntegrationError|null> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/conciliations/jobs/integrations/${conciliationId}`,
      { headers: { 'company-id': companyId } },
    );

    if (!response.data) {
      return null;
    }

    return ConciliationJobIntegrationError.make(response.data);
  }

  async rerunFailedJobFromConciliationIntegration(
    companyGroupId: number,
    companyId: number,
    jobId: string,
  ): Promise<string> {
    const { status, data: response } = await this.datasource.send(
      `company-groups/${companyGroupId}/conciliations/jobs/${jobId}/retry`,
      {},
      { headers: { 'company-id': companyId } },
    );

    if (this.isStatusOK(status)) {
      return response.data.message;
    }

    throw new Error(response.message ?? '');
  }
}
