import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"MainGrouperForm"},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Pesquisar","filled":"","clearable":"","rules":[_vm.inputRules.lowerOrEqualThen(64)]},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,[_c(VAutocomplete,{attrs:{"items":_vm.companies,"disabled":_vm.isLoading,"label":"Empresas","placeholder":"Deixe em branco para todos","height":"56px","auto-select-first":"","chips":"","deletable-chips":"","small-chips":"","clearable":"","multiple":"","filled":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"subtitle-1 mr-1"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedCompanies.length - 1)+" outros) ")]):_vm._e()]}}]),model:{value:(_vm.selectedCompanies),callback:function ($$v) {_vm.selectedCompanies=$$v},expression:"selectedCompanies"}})],1)],1),_c(VRow,[_c(VCol,[_c('select-limited-height',{attrs:{"items":_vm.tableOptions,"label":"Tabelas","rules":[_vm.inputRules.required()],"multiple":"","filled":""},model:{value:(_vm.selectedTables),callback:function ($$v) {_vm.selectedTables=$$v},expression:"selectedTables"}})],1),_c(VCol,[_c(VSelect,{attrs:{"items":_vm.screensOptions,"label":"Telas Exibidas","rules":[_vm.inputRules.required()],"multiple":"","filled":""},model:{value:(_vm.selectedScreens),callback:function ($$v) {_vm.selectedScreens=$$v},expression:"selectedScreens"}})],1),_c(VCol,[_c(VSelect,{attrs:{"items":_vm.statusOptions,"label":"Status","multiple":"","filled":""},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }