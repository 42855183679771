import VuexPersistence from 'vuex-persist';

const authorizationStorage = new VuexPersistence({
    key: 'authentication',
    storage: window.sessionStorage,
    modules: ['authentication'],
});

const paymentRemittanceStorage = new VuexPersistence({
    key: 'payment-remittance',
    storage: window.localStorage,
    modules: ['payment-remittance'],
});

export default [
    authorizationStorage.plugin,
    paymentRemittanceStorage.plugin,
];
