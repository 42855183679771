

























































































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { validateDateRange } from '@/utils/date';
import { formatErrorForNotification } from '@/utils/error';

import IOption from '@/domain/interfaces/ISelectOptions';

import PayrollRepository from '@/repositories/PayrollRepository';
import IPayrollHistoryFilter from '@/domain/interfaces/IPayrollHistoryFilter';
import EPayrollLogType from '@/domain/enums/PayrollLogType';

interface DateRangeError {
  date: boolean,
  messageInitial: string,
  messageEnd: string,
}

@Component
export default class PayrollSelectFilter extends Vue {
  @Ref('filter') readonly filter!: VForm;

  readonly payrollRepository: PayrollRepository = new PayrollRepository();

  allCompanies: Array<number> = [];

  companies: Array<IOption<number>> = [];
  types: Array<IOption<EPayrollLogType>> = [
    {
      text: 'Envio',
      value: EPayrollLogType.SEND,
    },
    {
      text: 'Retorno',
      value: EPayrollLogType.RETURN,
    },
  ];

  data: IPayrollHistoryFilter = {
    initialDate: '',
    finalDate: '',
    companies: [],
    types: [],
    search: null,
    page: 1,
    itemsPerPage: 50,
    sortBy: undefined,
    sortDesc: undefined,
  };

  errorDateRange: DateRangeError = {
    date: false,
    messageInitial: 'Data inicial deve ser menor ou igual a data final.',
    messageEnd: 'Data final deve ser menor ou igual a data inicial.',
  };

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  mounted() {
    this.loadOptions();
  }

  validateAllDateRanges(): boolean {
    const {
      initialDate,
      finalDate,
    } = this.data;

    const dateIsValid = validateDateRange(initialDate, finalDate);

    if (!dateIsValid) {
      this.errorDateRange.date = true;
      this.$notification.error('Intevalo de data inválido!');
    } else {
      this.errorDateRange.date = false;
    }

    return dateIsValid;
  }

  validate(): { valid: boolean, data: IPayrollHistoryFilter } {
    const isValidDateRange = this.validateAllDateRanges();
    const isValidFilter = this.filter.validate();

    if (isValidDateRange && isValidFilter) {
      return {
        valid: true,
        data: {
          ...this.data,
          companies: this.data.companies.length
            ? this.data.companies
            : this.allCompanies,
        },
      };
    }

    return {
      valid: false,
      data: {} as IPayrollHistoryFilter,
    };
  }

  async loadOptions(): Promise<void> {
    try {
      this.$dialog.startLoading();

      await this.loadCompanies();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  async loadCompanies(): Promise<void> {
    this.companies = await this.payrollRepository
      .getCompanies();

    this.allCompanies = this.companies.map((company) => company.value);
  }
}
