<template>
	<v-row>
		<v-col cols="12" lg="12" md="12" sm="12" xs="12">
			<breadcrumbs page-title="Arquivos de Extrato Bancário" returnPage/>
			<v-card :loading="loading" class="elevation-0">
				<v-card-title>
					<v-col cols="12" lg="3" md="6" sm="12" xs="12">
						<v-select
							v-model="status"
							:items="statusItems"
							filled
							dense
							hide-details
							placeholder="Selecione o status"/>
					</v-col>

					<v-col cols="12" lg="3" md="6" sm="12" xs="12">
						<v-select
							v-model="companies"
							:items="companiesItems"
							filled
							dense
							hide-details
							placeholder="Selecione a empresa"
							item-text='name'
							item-value='id'/>
					</v-col>

					<v-col cols="12" lg="3" md="6" sm="12" xs="12">
						<inn-date-field
							v-model="initialDate"
							dense
							hide-details
							label="Data inicial"
							>
						</inn-date-field>
					</v-col>

					<v-col cols="12" lg="3" md="6" sm="12" xs="12">
						<inn-date-field
							v-model="finalDate"
							hide-details
							dense label="Data final">
						</inn-date-field>
					</v-col>

					<v-col cols="12" lg="3" md="6" sm="12" xs="12">
						<v-select
							v-model="users"
							:items="usersItems"
							filled
							dense
							hide-details
							placeholder="Selecione um usuário"
							item-text='name'
							item-value='id'/>
					</v-col>

					<v-col cols="12" lg="2" md="6" sm="12" xs="12">
						<v-btn
							class="font-weight-bold"
							@click="onSubmit"
							large
							block
							depressed
							:loading="loading"
							color="success">
								VISUALIZAR
						</v-btn>
					</v-col>

					<v-col cols="12" lg="3" md="6" sm="12" xs="12"></v-col>
				</v-card-title>

				<v-card-text v-show="hasItens">
					<conciliation-upload-list-table :items="items" @downloadFile="downloadFile"/>
				</v-card-text>
			</v-card>
    	</v-col>
	</v-row>
</template>

<script>
	import ConciliationUploadListTable from "./table.vue";
	import ConciliationFilesImport from "@/services/conciliation_files_import.service";
	import CompanyService from "@/services/company.service";

    export default {
		components: {
			ConciliationUploadListTable
		},

        data() {
			return {
				statusItems: [{
					text: "Todos os status",
					value: "",
				}, {
					text: "Ativo",
					value: 1,
				}, {
					text: "Inativo",
					value: 0,
				}],
				companiesItems: [{
					name: 'Todas as empresas',
					id: "",
				}],
				usersItems: [{
					name: 'Todos os usuários',
					id: "",
				}],
				companies: "",
				users: "",
				hasItens: false,
				status: null,
				loading: false,
				items: [],
				initialDate: this.$moment().format('YYYY-MM-DD'),
				finalDate: this.$moment().format('YYYY-MM-DD'),
			};
		},

        mounted: function () {},

		created() {
			this.loadCompanies();
			this.loadUsers();
		},

        methods: {
			async loadCompanies() {
				try{
					const companyService = new CompanyService();
					const response = await companyService.index();
					const companiesData = response.data;
					this.companiesItems = this.companiesItems.concat(companiesData);
				} catch(error){
					this.$notification.error('Erro ao carregar empresas');
				}
			},

			async loadUsers() {
				const groupCompanyId = this.$session.get('company_group_id');
					const conciliationFilesImport = new ConciliationFilesImport();
					const companiesIds = await this.getCompaniesIds();
					const response = await conciliationFilesImport.list(groupCompanyId).index('users', {
						headers: {
                  			'token': this.$session.get('token'),
							'company-ids': JSON.stringify(companiesIds),
						},
					});

					response.data.forEach(item => {
						this.usersItems.push({name: item.user, id: item.user});
					});
			},

			async onSubmit() {
				try {
					this.loading = true;
					const groupCompanyId = this.$session.get('company_group_id');
					const conciliationFilesImport = new ConciliationFilesImport();
					const params = {
						initialDate: this.initialDate,
						finalDate: this.finalDate,
						status: this.status,
						user: this.users
					};
					const headers = {
						'token': this.$session.get('token'),
						'company-ids': JSON.stringify(this.companies ? [this.companies] : await this.getCompaniesIds()),
					};

					this.status == null ? delete params.status : null;

					const result = await conciliationFilesImport.list(groupCompanyId).index('', {
						headers: headers,
						params: params,
					});

					if (!result.data.length) {
						this.$notification.warn('Nenhum registro localizado');
						this.hasItens = false;
					}

					if (result.error) {
						this.$notification.error(`${result.message}`);
					}

					else {
						this.items = result.data;
						this.hasItens = true;
					}

				} catch (error) {
					this.$helpers.defaultCatchError(error);
				} finally {
					this.loading = false;
				}
			},

			async downloadFile(item) {
				const conciliationFilesImport = new ConciliationFilesImport();
				const groupCompanyId = this.$session.get('company_group_id');
				const result = await conciliationFilesImport.list(groupCompanyId).index(item.id, {
					headers: {
						'token': this.$session.get('token'),
						'company-ids': JSON.stringify([item.company_id]),
					},
				});

				if (result.error == true) {
					this.$notification.error(`${result.message}`);
					return false;
				}

				var blob = new Blob([result.data.file_content], { type: 'application/octet-stream' });
				var link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = result.data.file_name;
				link.click();
				return true;
			},

			async getCompaniesIds() {
				try{
					const companyService = new CompanyService();
					const response = await companyService.index();
					const companiesData = response.data;
					const companiesIds = companiesData.map(company => company.id);

					return companiesIds;
				} catch(error){
					this.$notification.error('Erro ao carregar empresas');
				}
			},
		},
	};
</script>