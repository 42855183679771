<template>
  <v-btn
	color="grey"
	class="float-left"
	outlined
	@click="returnToPreviousRoute"
  >
	Cancelar
  </v-btn>
</template>

<script>
export default {
	name: 'FormCancelButton',
	props: {
		backRoute: String,
	},
	methods: {
		returnToPreviousRoute() {
			const hasHistory = window.history.length > 1;

			if (hasHistory) {
				this.$router.go(-1);
			} else {
				this.$router.push(this.backRoute || '/');
			}
		},
	},
};
</script>

<style>

</style>
