<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Forma de Pagamento"/>
			<page-card dividerOn>
				<template #title>
					<v-row>
						<v-col cols="12" lg="10" md="9" sm="12" xs="12">
							<v-text-field
								filled
								hide-details
								prepend-inner-icon="mdi-magnify"
								label="Pesquisar"
								v-model="configs.filterTerm"
							/>
						</v-col>
						<v-spacer />
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<v-btn
								@click="add"
								class="font-weight-bold"
								x-large
								block
								depressed
								color="primary"
							>
								ADICIONAR
							</v-btn>
						</v-col>
					</v-row>
				</template>
				<template #body>
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Código ERP</th>
								<th>Descrição</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="paymentmethod in listData" v-bind:key="paymentmethod.id">
								<td style="vertical-align: middle;">
								<span class="m-badge m-badge--wide m-badge--success">Ativo</span></td>
								<td style="vertical-align: middle;">{{ paymentmethod.code }}</td>
								<td style="vertical-align: middle;">{{ paymentmethod.description }}</td>
								<td class="m--align-right">
									<button-tooltip
										fab
										icon
										small
										buttonIcon="mdi-pencil"
										tooltipText="Editar"
										@click="edit(paymentmethod)"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</page-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash';
	import { API_BASE } from '@config/api';

	export default {
		data () {
			return {
				paymentmethods: [],
				configs: {
					filterTerm: ''
				}
			}
		},
		computed: {
			listData() {
				let dataRet = this.paymentmethods;

				if (this.configs.filterTerm) {
					dataRet = _.filter(dataRet, list => list.description.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0  || list.code.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 )
				}

				return dataRet
			}
		},
		mounted: function () {
			this.load();
		},
		methods: {
			load: function () {
				blockPage('Carregando os dados...');

				this.$http.get(API_BASE + 'paymentmethod/listAll', {headers: {'token': this.$session.get('token')}}).then(
					(result) => {
							this.paymentmethods = result.body.data
					unblockPage();
					},
					(error) => {
							showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
							unblockPage();
					}
				)
			},
			add() {
				this.$router.push('/paymentmethod/new');
			},	
			edit(paymentmethod) {
				const params = {
					id: paymentmethod.id,
				};
				this.$router.push({name: 'newpaymentmethod',params});
			},
		}
	}
</script>
