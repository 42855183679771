






import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import StatusObject from '@/components/tables/interfaces/StatusObject';

@Component
export default class TableStatusChip extends Vue {
  @Prop({
    type: [String, Number],
    required: true,
  }) readonly value!: string|number;

  @Prop({ type: Array, required: true }) list!: Array<StatusObject>;

  get status(): StatusObject {
    return this.list
      .find((item) => (item.value == this.value)) || {} as StatusObject;
  }
}
