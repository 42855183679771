






























































import {
  Vue,
  Prop,
  Component,
  Emit,
} from 'vue-property-decorator';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';

@Component
export default class PaymentRemittancesListMenuHeader extends Vue {
  @Prop(Boolean) generate!: boolean;
  @Prop(Boolean) invoice!: boolean;
  @Prop(Boolean) approve!: boolean;

  @Emit()
  click(type: ActionType): ActionType {
    return type;
  }

  readonly action = ActionType
}
