




























































































































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/VForm';
import { formatErrorForNotification } from '@/utils/error';

import StringHelper from '@/helpers/StringHelper';
import EconomicGroupGroupingTypesEnum from '@/views/economicGroups/enums/EconomicGroupGroupingTypesEnum';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import StatusType from '@/domain/enums/StatusType';
import EconomicGroupRepository from '@/repositories/EconomicGroupRepository';
import CustomerRepository from '@/repositories/CustomerRepository';
import CompanyRepository from '@/repositories/CompanyRepository';
import EconomicGroup from '@/domain/models/EconomicGroup';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import IUpsertEconomicGroupData from '@/views/economicGroups/interfaces/IUpsertEconomicGroupData';
import ICustomerAndCompanySelectionOptions from '@/views/economicGroups/interfaces/ICustomerAndCompanySelectionOptions';

@Component
export default class EconomicGroupView extends Vue {
  @Ref('form') readonly form!: VForm;

  public readonly economicGroupRepository
    : EconomicGroupRepository = new EconomicGroupRepository();
  private readonly customerRepository
    : CustomerRepository = new CustomerRepository();
  private readonly companyRepository
    : CompanyRepository = new CompanyRepository();
  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);

  public loading: boolean = false;
  public autocompleteLoading: boolean = false;

  public autocompleteSearch: string = '';

  public data: IUpsertEconomicGroupData = {
    name: '',
    groupingType: EconomicGroupGroupingTypesEnum.CUSTOMER,
    status: StatusType.ACTIVE,
    companies: [],
    customers: [],
  };
  public rule: Record<string, any> = {
    required: (v: string) => !!v || 'Campo obrigatório.',
    requiredMultiple: (v: Array<string>) => !!v.length || 'Campo obrigatório.',
  };

  public customers: ISelectOptions<string>[] = [];
  public companies: ISelectOptions<number>[] = [];
  public completeSelectedCustomers: ISelectOptions<string>[] = [];
  public completeSelectedCompanies: ISelectOptions<string>[] = [];

  public status: ISelectOptions<string>[] = [
    {
      value: StatusType.ACTIVE,
      text: 'Ativo',
    },
    {
      value: StatusType.INACTIVE,
      text: 'Inativo',
    },
  ];
  public economicGroupGroupingTypeItems: ISelectOptions[] = [
    { text: 'Cliente', value: 'customer' },
    { text: 'Empresa', value: 'company' },
  ];

  public get isCreate(): boolean {
    return this.$route.name === 'CreateEconomicGroup';
  }

  public get economicGroupId(): number {
    return +this.$route.params.id;
  }

  public get title(): string {
    return this.isCreate
      ? 'Novo Grupo Econômico'
      : 'Editar Grupo Econômico';
  }

  public get companyGroupId(): number {
    return this.$session.get('company_group_id');
  }

  public mounted(): void {
    this.loadData();
  }

  public save(): Promise<void> | null {
    if (this.form.validate()) {
      return this.isCreate
        ? this.createGroup()
        : this.updateGroup();
    }

    return null;
  }

  public cancel(): void {
    this.form.resetValidation();
    this.$router.push({ name: 'EconomicGroupList' });
  }

  public getAutocompleteNoDataText(type: 'customer' | 'company'): string {
    const correlationBetweenTypeAndText = {
      defaultCustomer: 'Digite para buscar cliente.',
      loadingCustomer: 'Carregando clientes...',
      notFoundCustomer: 'Nenhum cliente correspondente encontrado.',
      defaultCompany: 'Digite para buscar empresa.',
      loadingCompany: 'Carregando empresas...',
      notFoundCompany: 'Nenhuma empresa correspondente encontrada.',
    } as const;

    const capitelizedType = StringHelper.capitalize(type);
    let correlationKey = 'default';

    if (this.autocompleteSearch) {
      correlationKey = this.autocompleteLoading ? 'loading' : 'noFound';
    }

    const completeCorrelationKey = `${capitelizedType}${correlationKey}` as
      'defaultCustomer' |
      'loadingCustomer' |
      'notFoundCustomer' |
      'defaultCompany' |
      'loadingCompany' |
      'notFoundCompany';

    return correlationBetweenTypeAndText[completeCorrelationKey];
  }

  public async getGroup(id: number): Promise<void> {
    const response = await this.economicGroupRepository
      .getEconomicGroup(this.companyGroupId, this.authenticationModule.companyIds, id);

    const {
      companies,
      customers,
    } = await this.getCompleteValuesFromCustomerAndCompany(response);

    this.customers = customers;
    this.companies = companies;

    this.data = {
      name: response.name,
      status: response.status,
      groupingType: response.groupingType,
      customers,
      companies,
    };
  }

  public async getCompleteValuesFromCustomerAndCompany(
    economicGroup: EconomicGroup,
  ): Promise<ICustomerAndCompanySelectionOptions> {
    const definedOptions: ICustomerAndCompanySelectionOptions = {
      companies: [],
      customers: [],
    };

    if (economicGroup.groupingType === EconomicGroupGroupingTypesEnum.CUSTOMER) {
      const customerValues = await this.customerRepository.getCustomersByIdsCustomer(
        this.companyGroupId,
        this.authenticationModule.companyIds,
        economicGroup.idCustomers,
      );

      definedOptions.customers = customerValues.map((customer) => ({
        value: customer.id,
        text: customer.label,
      }));
    } else if (economicGroup.groupingType === EconomicGroupGroupingTypesEnum.COMPANY) {
      const companyValues = await this.companyRepository.getCompanyByIds(
        this.companyGroupId,
        economicGroup.companyIds,
      );

      definedOptions.companies = companyValues.map((company) => ({
        value: company.id,
        text: company.fantasyName,
      }));
    }

    return definedOptions;
  }

  public async getCustomersData(search: string): Promise<void> {
    const customers = await this.economicGroupRepository.getCustomersThatAreNotInUse(
      this.companyGroupId,
      this.authenticationModule.companyIds,
      search,
    );

    this.customers = customers.map((customer) => ({
      text: `${customer.code} ${customer.store} ${customer.name}`,
      value: customer.idCustomer,
    }));

    const selectedCustomersIdsFromData = this.data.customers.map(({ value }) => value);
    const selectedCustomersIdsFromAutocompleteList = this.customers.map(({ value }) => value);

    const idsToUnshift = selectedCustomersIdsFromData
      .filter((id) => !selectedCustomersIdsFromAutocompleteList.includes(id));
    const customersToUnshift = this.data.customers
      .filter(({ value }) => idsToUnshift.includes(value));

    this.customers.unshift(...customersToUnshift);
  }

  public async getCompaniesData(search: string): Promise<void> {
    const companies = await this.economicGroupRepository.getCompaniesThatAreNotInUse(
      this.companyGroupId,
      this.authenticationModule.companyIds,
      search,
    );

    const companiesSelectOptions: ISelectOptions<number>[] = companies
      .map(({ id, fantasyName }) => ({ value: id, text: fantasyName }));

    this.companies = companiesSelectOptions;

    const selectedCompaniesIdsFromData = this.data.companies.map(({ value }) => value);
    const selectedCompaniesIdsFromAutocompleteList = this.companies.map(({ value }) => value);

    const idsToUnshift = selectedCompaniesIdsFromData
      .filter((id) => !selectedCompaniesIdsFromAutocompleteList.includes(id));
    const companiesToUnshift = this.data.companies
      .filter(({ value }) => idsToUnshift.includes(value));

    this.companies.unshift(...companiesToUnshift);
  }

  public async loadData(): Promise<void> {
    try {
      this.loading = true;

      if (!this.isCreate) await this.getGroup(this.economicGroupId);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      this.cancel();
    } finally {
      this.loading = false;
    }
  }

  public async handleLoadCustomersOrCompanies(search: string, type: 'customer' | 'company'): Promise<void> {
    if (!search) {
      return;
    }

    try {
      this.autocompleteLoading = true;

      if (type === 'customer') {
        await this.getCustomersData(search);
      } else {
        await this.getCompaniesData(search);
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.autocompleteLoading = false;
    }
  }

  public async createGroup(): Promise<void> {
    try {
      this.loading = true;

      await this.economicGroupRepository.sendEconomicGroup(
        this.companyGroupId,
        this.authenticationModule.companyIds,
        {
          customers: this.data.customers.map(({ value }) => value),
          companies: this.data.companies.map(({ value }) => value),
          grouping_type: this.data.groupingType,
          name: this.data.name,
          status: this.data.status,
        },
      );

      this.$notification.success('Grupo econômico criado com sucesso!');
      this.$router.push({ name: 'EconomicGroupList' });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async updateGroup(): Promise<void> {
    try {
      this.loading = true;

      await this.economicGroupRepository.updateEconomicGroup(
        this.companyGroupId,
        this.authenticationModule.companyIds,
        this.economicGroupId,
        {
          customers: this.data.customers.map(({ value }) => value),
          companies: this.data.companies.map(({ value }) => value),
          grouping_type: this.data.groupingType,
          name: this.data.name,
          status: this.data.status,
        },
      );

      this.$notification.success('Grupo econômico alterado com sucesso!');
      this.$router.push({ name: 'EconomicGroupList' });
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public handleGroupingTypeChange(): void {
    this.data.customers = [];
    this.data.companies = [];
    this.customers = [];
    this.companies = [];
    this.autocompleteSearch = '';
    this.autocompleteLoading = false;
  }
}
