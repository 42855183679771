import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-click":"","bottom":"","nudge-bottom":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"fa fa-bars dropdown-menu-icon ml-n1"},'i',attrs,false),on))]}}])},[_c(VList,[_c(VListItem,[_c(VListItemTitle,{on:{"click":function($event){return _vm.showOccurrencesModal(_vm.item)}}},[_vm._v(" Ver Ocorrências ")])],1),_c(VListItem,[_c(VListItemTitle,{on:{"click":function($event){return _vm.emit('showDetailsModal')}}},[_vm._v(" Visualizar ")])],1),(_vm.item.borderoNumber)?_c(VListItem,[_c(VListItemTitle,{on:{"click":function($event){return _vm.emit('removeAccount')}}},[_vm._v(" Remover do borderô ")])],1):_vm._e(),(_vm.item.documentUrl != null)?_c(VListItem,[_c(VListItemTitle,{on:{"click":function($event){return _vm.showBoleto(_vm.item)}}},[_vm._v(" Visualizar Boleto ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }