import User from '@/domain/models/User';
import UserPartial from '@/domain/models/UserPartial';
import ExistentUserException from '@/views/users/exceptions/ExistentUserException';
import Repository from './base/Repository';
import IUserCreateParams from './parameters/IUserCreateParams';
import IUserCompaniesParams from './parameters/IUserCompaniesParams';

export default class UserRepository extends Repository {
  async listAll() : Promise<UserPartial[]> {
    const { status, data: response } = await this.datasource.get('/user/listAll');
    if (status == 200) {
      return response.data.map(User.make);
    }
    throw new Error('Aconteceu algum erro inesperado');
  }

  async listOne(userId : number) : Promise<User> {
    const { status, data: response } = await this.datasource.get(`/user/listOne/${userId}`);
    if (status == 200) {
      return User.make(response.data);
    }
    throw new Error('Aconteceu algum erro inesperado');
  }

  async checkIfExists(email : string) : Promise<boolean> {
    const { status, data: response } = await this.datasource.get(`/user/userExists/${email}`);
    if (status == 200) {
      if (!response.data.exists) {
        return true;
      }

      throw new ExistentUserException();
    }
    throw new Error();
  }

  async save(user : IUserCreateParams, companies : IUserCompaniesParams[]) :Promise<boolean> {
    const { status, data: response } = await this.datasource.send('/user/new', {
      arrayData: this.transformUserData(user),
      arrayDataCompany: JSON.stringify(companies),
    });
    if (status == 200) {
      if (!response.error) {
        return true;
      }
      throw new Error(response.message);
    }
    throw new Error('Aconteceu algum erro inesperado');
  }

  async update(user : IUserCreateParams, companies : IUserCompaniesParams[]) :Promise<boolean> {
    const { status, data: response } = await this.datasource.send('/user/edit', {
      arrayData: this.transformUserData(user),
      arrayDataCompany: JSON.stringify(companies),
    });
    if (status == 200) {
      if (!response.error) {
        return true;
      }
      throw new Error(response.message);
    }
    throw new Error('Aconteceu algum erro inesperado');
  }

  public async resetPassword(
    password: string,
    token: string,
    mail: string,
  ): Promise<void> {
    await this.datasource.update('user/reset-password', { password, mail, token });
  }

  // TODO review how the user is created
  // IN: { a: 1, b: 2 }
  // OUT: [{ a: 1 },{ b: 2 }]
  private transformUserData(user : IUserCreateParams) : string {
    const entries = Object.entries(user);
    const keyValuePairs = entries.reduce((arr : Object[], i : [string, any]) => arr.concat({
      [i[0]]: i[1],
    }), []);
    return JSON.stringify(keyValuePairs);
  }
}
