export default class ColorsHelper {
  public static getBaseBlockColorBasedOnTheme(isOnDarkMode: boolean = false): string {
    if (isOnDarkMode) {
      return '#565656';
    }

    return '#F2F3F7';
  }

  public static getTextValuesColorBasedOnTheme(isOnDarkMode: boolean): string {
    if (isOnDarkMode) {
      return '';
    }

    return '#007BDD';
  }
}
