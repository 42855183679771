import { render, staticRenderFns } from "./PredictedFlowList.vue?vue&type=template&id=54670f2a&scoped=true"
import script from "./PredictedFlowList.vue?vue&type=script&lang=ts"
export * from "./PredictedFlowList.vue?vue&type=script&lang=ts"
import style0 from "./PredictedFlowList.vue?vue&type=style&index=0&id=54670f2a&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54670f2a",
  null
  
)

export default component.exports