












































































































































































































































import {
  Component,
  Emit,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import IVDataTableHeader from '@/types/IVDataTableHeader';
import IStatusIcon from '@/types/IStatusIcon';

import InstructionRemittance from '@/domain/models/InstructionRemittance';

import IFilter from '@/domain/interfaces/IInstructionsRemittanceFilter';

import EAction from '@/domain/enums/InstructionRemittanceAction';
import EStatus from '@/domain/enums/InstructionRemittanceStatus';

import StatusIcon from '@/components/tables/StatusIcon.vue';
import ListMenu from './ListMenu.vue';
import ChangeInstruction from './ChangeInstruction.vue';
import ViewOccurrence from './ViewOccurrence.vue';
import ViewDetails from './ViewDetails.vue';
import Send from './Send.vue';
import {
  formatCurrency,
  formatFieldValue,
  formatTitle,
  translateField,
  translateInstruction,
} from '../utils';

@Component({
  components: {
    StatusIcon,
    ListMenu,
    ChangeInstruction,
    ViewOccurrence,
    ViewDetails,
    Send,
  },
})
export default class InstructionsRemittanceList extends Vue {
  @Prop({
    type: Object as () => Record<string, Array<InstructionRemittance>>,
    default: {} as Record<string, Array<InstructionRemittance>>,
    required: true,
  }) items!: Record<string, Array<InstructionRemittance>>;

  @Prop({
    type: Object as () => IFilter,
    default: {} as IFilter,
    required: true,
  }) data!: IFilter;

  @Watch('items', { deep: true, immediate: true })
  onChangeItems() {
    this.selecteds = [];
    this.itemsSummary = Object
      .keys(this.items)
      .reduce(
        (previus, key) => {
          const teste = this.items[key].reduce((prev, item) => {
            const disabled = item.status !== EStatus.PENDING;
            return {
              total: prev.total + item.balance,
              disableds: prev.disableds + (disabled ? 1 : 0),
            };
          }, { total: 0, disableds: 0 });

          previus[key] = teste;

          return previus;
        },
        {} as Record<string, { total: number, disableds: number }>,
      );
  }

  @Emit('reload')
  reload(): boolean {
    return true;
  }

  readonly formatCurrency = formatCurrency;
  readonly formatTitle = formatTitle;
  readonly formatFieldValue = formatFieldValue;
  readonly translateField = translateField;
  readonly translateInstruction = translateInstruction;

  readonly Action = EAction;

  itemsSummary: Record<string, { total: number, disableds: number }> = {};
  selecteds: Array<InstructionRemittance> = [];

  itemsToAction: Array<InstructionRemittance> = [];
  open: Record<EAction, boolean> = {} as Record<EAction, boolean>;

  headers: Array<IVDataTableHeader> = [
    {
      text: '',
      value: 'data-table-select',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Título',
      value: 'title',
      sortable: false,
    },
    {
      text: 'Valor',
      value: 'balance',
      align: 'start',
      sortable: true,
    },
    {
      text: 'Portador',
      value: 'bank',
      align: 'center',
      sortable: true,
    },
    {
      text: 'Campo Alterado',
      value: 'field',
      align: 'center',
      sortable: true,
    },
    {
      text: 'Conteúdo Anterior',
      value: 'oldValue',
      align: 'center',
      sortable: true,
    },
    {
      text: 'Conteúdo Novo',
      value: 'newValue',
      align: 'center',
      sortable: true,
    },
    {
      text: 'Instrução',
      value: 'action',
      align: 'center',
      sortable: true,
    },
    {
      text: 'Status',
      value: 'status',
      align: 'center',
      sortable: true,
    },
    {
      text: '',
      value: 'options',
      align: 'center',
      sortable: false,
    },
  ];

  statusList: Array<IStatusIcon> = [
    {
      code: EStatus.PENDING,
      text: 'Pendente',
      icon: 'mdi-minus-circle-outline',
      color: 'warning',
    },
    {
      code: EStatus.SENT,
      text: 'Enviado',
      icon: 'mdi-arrow-top-right-thin-circle-outline',
      color: 'info',
    },
    {
      code: EStatus.APPROVED,
      text: 'Alteração confirmada',
      icon: 'mdi-check-circle-outline',
      color: 'success',
    },
    {
      code: EStatus.REJECTED,
      text: 'Alteração rejeitada',
      icon: 'mdi-close-circle-outline',
      color: 'error',
    },
  ];

  get itemsTotal(): number {
    return Object
      .keys(this.items)
      .reduce((prev, key) => prev + this.items[key].length, 0);
  }

  get itemsTotalValue(): number {
    return Object
      .keys(this.items)
      .reduce(
        (previus, key) => {
          const balances = this.items[key]
            .reduce((prev, item) => prev + item.balance, 0);

          return previus + balances;
        },
        0,
      );
  }

  get selectedTotal(): number {
    return this.selecteds.length;
  }

  get selectedTotalValue(): number {
    return this.selecteds
      .reduce((previus, item) => previus + item.balance, 0);
  }

  isItemDisabled(item: InstructionRemittance): boolean {
    return item.status !== EStatus.PENDING && item.status !== EStatus.REJECTED;
  }

  toggleSelecteds(key: string): void {
    const selecteds = this.selecteds.filter((selected) => selected.description == key);
    if (
      selecteds.length !== this.items[key].length - this.itemsSummary[key].disableds
    ) {
      const newSelecteds = this.items[key]
        .filter((item) => (!this.isItemDisabled(item)));

      this.selecteds = [...this.selecteds, ...newSelecteds];
    } else {
      this.selecteds = this.selecteds
        .filter((item) => item.description != key);
    }
  }

  handleSendSelecteds(key: string): void {
    const selecteds = this.selecteds
      .filter((selected) => selected.description == key);

    if (!selecteds.length) {
      this.$notification.warn('Nenhuma instrução selecionada!');
      return;
    }

    this.action({ type: EAction.SEND, items: selecteds });
  }

  handleSendAllSelecteds(): void {
    if (!this.selecteds.length) {
      this.$notification.warn('Nenhuma instrução selecionada!');
      return;
    }

    this.action({ type: EAction.SEND, items: this.selecteds });
  }

  action(data: { type: EAction, items: Array<InstructionRemittance> }): void {
    const { type, items } = data;

    this.itemsToAction = items;
    this.open[type] = true;
  }

  close(data: { type: EAction, reload: boolean }): void {
    const { type, reload } = data;

    this.open[type] = false;
    this.itemsToAction = [];

    if (reload) {
      this.selecteds = [];
      this.reload();
    }
  }
}
