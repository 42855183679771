<template>
  <custom-modal
    :show="show"
    @close="closeModal"
  >
    <template v-slot:title>
      Ocorrências do Título {{ item.prefixCode }} -
        {{ item.documentNumber }} - {{ item.documentType }}
    </template>

    <template v-slot:content v-if="item">
      <label v-if="!item || !item.occurrencesTotal || item.occurrencesTotal <= 0">
        Nenhuma Ocorrência encontrada!
      </label>
      <template v-else>
        <v-data-table
          :headers="headers"
          :items="item.occurrences"
          hide-default-footer
        >
          <template v-slot:item.date="{ item }">
            <span>{{dateFormat(item.date)}} - {{hourFormat(item.date)}}</span>
          </template>
        </v-data-table>
      </template>
    </template >
  </custom-modal>
</template>

<script>
/* eslint-disable no-undef */
import CustomModal from '../../../components/defaults/CustomModal.vue';

export default {
	components: { CustomModal },
  name: 'BillingRemittanceOccurrencesModal',
  props: {
    show: Boolean,
    item: Object,
  },
   data() {
      return {
        headers: [
          {
            text: 'Data e Hora',
            value: 'date',
            align: 'start',
            width: '20%',
            sortable: false,
          },
          {
            text: 'Código',
            value: 'code',
            width: '10%',
            sortable: false,
          },
          {
            text: 'Descrição',
            value: 'description',
            width: '60%',
            sortable: false,
          },
        ],
      };
    },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    dateFormat(value) {
      const time = value;
      const date = time.substring(0, 10);
      return date.split('-').reverse().join('/');
    },
    hourFormat(value) {
      const time = value;
      const hour = time.substring(11, 16);
      return hour;
    },
  },
};
</script>

<style></style>
