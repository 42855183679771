













import {
  Component,
  Mixins,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';

@Component
export default class InnTextFieldDebounce extends Mixins(ModelManagementMixin) {
  public timer: null|number = null;

  public debounce(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(async () => {
      if (typeof this.model !== 'undefined') {
        await this.$emit('on-finished-debounce', this.model);
      }
    }, 1000);
  }

  public handleActions(action: string): void {
    let modelValue: unknown = '';

    if (action === 'keydown') {
      modelValue = this.model;
    }

    this.$emit('on-finished-debounce', modelValue);
  }
}
