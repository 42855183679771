import Vue from 'vue';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import AuthenticationRepository from '@/repositories/AuthenticationRepository';
import Authentication from '@/domain/models/Authentication';
import LoginHelper from '@/views/login/helpers/LoginHelper';

const authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
const authenticationRepository: AuthenticationRepository = new AuthenticationRepository();

const resetSession = () => {
  authenticationModule.resetUser();
  Vue.prototype.$session.destroy();
};

const verifyIfTokenExistsOnHttpCookie = async (): Promise<Authentication|null> => {
  try {
    return authenticationRepository.verifyHttpCookieToken();
  } catch (error) {
    return null;
  }
};

const loadAvailableModulesAndReturnRoute = async (): Promise<string|null> => {
  try {
    return LoginHelper.loadAvailableModules();
  } catch (error) {
    return null;
  }
};

export const moduleGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
  const isModuleSelected = authenticationModule.currentModule != null;

  if (isModuleSelected || to.name == 'Modules') {
    next();
  } else {
    next({ name: 'Modules' });
  }
};

export const redirectAuthGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
): Promise<void> => {
  const routesToNotRedirectWhenNotLogged = [
    'ForgotPassword',
    'ResetPasswordToken',
    'ResetPassword',
    'login',
    'logintoken',
    'ContactSupport',
  ];
  const hasTokenDefinedOnSession = (
    authenticationModule.user.token && Vue.prototype.$session.exists()
  );
  const isActualRouteRequiredToBeLogged = !routesToNotRedirectWhenNotLogged
    .includes(to.name!);
  const isTokenHandlerRoute = to.params.token && to.params.token.startsWith('ey');

  if (isTokenHandlerRoute) {
    next();
    return;
  }

  if (to.fullPath === '/') {
    next({ name: 'login' });
    return;
  }

  if (!isActualRouteRequiredToBeLogged && hasTokenDefinedOnSession) {
    next({ name: 'Modules' });
    return;
  }

  if (
    !hasTokenDefinedOnSession
    && authenticationModule.hasResolvedAuthenticationToken
    && isActualRouteRequiredToBeLogged
  ) {
    Vue.prototype.$notification.error('Você precisa estar autenticado para acessar essa página, por favor realize o login.');
    next({ name: 'login' });
    return;
  }

  if (!hasTokenDefinedOnSession && !authenticationModule.hasResolvedAuthenticationToken) {
    const authData = await verifyIfTokenExistsOnHttpCookie();

    authenticationModule.setHasResolvedAuthenticationToken(true);

    if (authData === null && isActualRouteRequiredToBeLogged) {
      Vue.prototype.$notification.error('Não existe token de autenticação definido nessa sessão, por favor realize o login.');
      resetSession();
      next({ name: 'login' });
      return;
    }

    if (authData !== null) {
      Vue.prototype.$session.start();

      if (authData.forceChangePassword) {
        authenticationModule.setUserMailToRedefinePassword(authData.email);
        Vue.prototype.$session.set('userMailToRedefinePassword', authData.email);

        next({ name: 'ResetPassword' });

        return;
      }

      LoginHelper.setSessionPermissions(authData);

      const routeToGo = await loadAvailableModulesAndReturnRoute();

      if (routeToGo === null) {
        Vue.prototype.$notification.error('Ouve um problema na definição dos módulos de usuário, por favor realize o login novamente.');
        resetSession();
        next({ name: 'login' });
        return;
      }

      if (!isActualRouteRequiredToBeLogged) {
        next({ name: routeToGo });
        return;
      }
    }
  }

  next();
};
