































































































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import EAction from '@/domain/enums/InstructionRemittanceAction';
import InstructionRemittance from '@/domain/models/InstructionRemittance';
import InstructionsRemittanceRepository from '@/repositories/InstructionsRemittanceRepository';
import { EInstructionRemittanceType } from '@/domain/enums/InstructionRemittanceType';
import {
  formatCurrency,
  formatFieldValue,
  formatTitle,
  translateField,
  translateInstruction,
} from '../utils';

@Component
export default class InstructionsRemittanceSend extends Vue {
  @Prop({
    type: Boolean,
    default: false,
    required: true,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<InstructionRemittance>,
    default: [] as Array<InstructionRemittance>,
    required: true,
  }) readonly items!: Array<InstructionRemittance>;

  @Prop({
    type: Array as () => Array<number>,
    default: [] as Array<number>,
    required: true,
  }) readonly company!: Array<number>;

  @Emit()
  close(reload: boolean = false): { type: EAction, reload: boolean } {
    return {
      type: EAction.SEND,
      reload,
    };
  }

  readonly instructionsRemittanceRepository
    : InstructionsRemittanceRepository = new InstructionsRemittanceRepository();

  readonly formatCurrency = formatCurrency;
  readonly formatTitle = formatTitle;
  readonly formatFieldValue = formatFieldValue;

  readonly translateField = translateField;
  readonly translateInstruction = translateInstruction;

  get itemsByInstruction(): Record<string, Array<InstructionRemittance>> {
    return this.items.reduce((prev, item) => {
      if (prev[item.action]) {
        prev[item.action].push(item);
      } else {
        prev[item.action] = [item];
      }

      return prev;
    }, {} as Record<string, Array<InstructionRemittance>>);
  }

  formatInstruction(key: string) {
    return this.translateInstruction(key as EInstructionRemittanceType);
  }

  async send(): Promise<void> {
    try {
      this.$dialog.startLoading();

      const groupId = this.$session.get('company_group_id');

      const { company } = this;

      const instructions = this.items.map(({ id }) => (id));

      await this.instructionsRemittanceRepository
        .send(groupId, company, { instructions });

      this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
