import axios, { AxiosError } from 'axios';

export function formatErrorForNotification(error: Error | AxiosError): string {
  let returnMessage = '';
  const errorMessageOrName = error.message ? error.message : error.name;

  if (axios.isAxiosError(error)) {
    const { message, errors } = error.response?.data
      || { message: errorMessageOrName, erros: null };

    if (typeof errors === 'object' && errors !== null) {
      const errorsStringify = Object.entries(errors)
        .map(([key, value]) => (value == message ? '' : value)).join('\n');
      returnMessage = `${message} \n\n ${errorsStringify}`;
    } else {
      returnMessage = message;
    }
  } else {
    returnMessage = errorMessageOrName;
  }
  console.error(error);
  return returnMessage;
}

export function formatCurrency(value: number): string {
  if (typeof value !== 'number') return 'Valor Inválido';

  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  });
}
