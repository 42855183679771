













































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import TradingPolicyRepository from '@/repositories/TradingPolicyRepository';
import VDataTableHeader from '@/types/IVDataTableHeader';
import Policy from '@/domain/models/TradingPolicy';

import StatusChip from '@/components/tables/StatusChip.vue';
import ActionButton from '@/components/tables/ActionButton.vue';
import DeleteDialog from './components/TradingPolicyDeleteDialog.vue';

@Component({
  components: {
    ActionButton,
    StatusChip,
    DeleteDialog,
  },
})
export default class TradingPolicies extends Vue {
  public readonly tradingPolicyRepository
    : TradingPolicyRepository = new TradingPolicyRepository();

  public search: string = '';
  public open: boolean = false;
  public loading: boolean = false;

  public policy: Policy = {} as Policy;
  public policies: Array<Policy> = [];

  public headers: Array<VDataTableHeader> = [
    {
      text: 'Tipo',
      value: 'type',
      sortable: false,
    },
    {
      text: 'Usuário',
      value: 'name',
      sortable: false,
    },
    {
      text: '',
      value: 'id',
      sortable: false,
      align: 'end',
    },
  ];

  public status = [
    {
      value: 'general',
      label: 'Geral',
      color: 'default',
    },
    {
      value: 'customer',
      label: 'Cliente',
      color: 'default',
    },
    {
      value: 'operator',
      label: 'Operador',
      color: 'default',
    },
  ];

  public mounted(): void {
    this.loadPolicies();
  }

  public formatType(policy: Policy): string {
    if (policy.userId) return 'operator';
    return 'general';
  }

  public handleSearch(search: string): void {
    this.search = search;
    this.loadPolicies();
  }

  public handleCreate(): void {
    this.$router.push({ name: 'CreateTradingPolicy' });
  }

  public handleEdit(id: string): void {
    this.$router.push({ name: 'EditTradingPolicy', params: { id } });
  }

  public handleDelete(policy: Policy): void {
    this.policy = policy;
    this.open = true;
  }

  public handleClose(reload: boolean): void {
    if (reload) {
      this.loadPolicies();
    }
  }

  public async loadPolicies(): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      this.policies = await this.tradingPolicyRepository
        .getTradingPolicies(group, this.search || undefined);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
