export default class EconomicGroupCustomer {
  constructor(
    public idCustomer: string,
    public code: string,
    public store: string,
    public name: string,
  ) {}

  static make(data: any): EconomicGroupCustomer {
    return new EconomicGroupCustomer(
      data.id_customer,
      data.code,
      data.store,
      data.name,
    );
  }
}
