<template>
	<v-select
		label="Empresa"
		placeholder="Selecione a empresa"
        v-model="model"
		:items="items"
		:loading="loading"
		:readonly="disabled && model"
		item-text="fantasy_name"
		item-value="id"
		:rules="rules"
		return-object
	/>
</template>

<script>
import CompanyService from '@/services/company.service';

export default {
	name: 'CompanySelect',
	//  two-way data-binding
	props: {
		value: [Object, String, Number],
		disabled: [Boolean, String, Number],
		rules: [Array, Object],
	},
	data() {
		return {
			loading: false,
			model: this.value,
			items: [],
		};
	},
	watch: {
		model() {
			this.$emit('input', this.model);
		},
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
	},
	created() {
		this.loadCompanies();
	},
	methods: {
		async loadCompanies() {
			try {
				this.loading = true;

				const companyService = new CompanyService();

				const response = await companyService.index();

				this.items = response.data || [];
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style>

</style>
