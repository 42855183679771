import Repository from '@/repositories/base/Repository';
import Authentication from '@/domain/models/Authentication';
import ActiveDirectoryCredentials from '@/domain/models/ActiveDirectoryCredentials';
import IUpsertUserFromActiveDirectoryParams from '@/repositories/parameters/IUpsertUserFromActiveDirectoryParams';

export default class AuthenticationRepository extends Repository {
  public async authenticate(
    email: string,
    password: string,
  ): Promise<Authentication> {
    const { data: response } = await this.datasource.send(
      'auth',
      {
        email,
        password,
        origin: 'inncash',
      },
      { withCredentials: true },
    );

    return Authentication.make(response.data);
  }

  public async verifyHttpCookieToken(): Promise<Authentication|null> {
    const { data: response } = await this.datasource.get('auth/http-cookie-token', { withCredentials: true });

    if (Array.isArray(response) && response.length < 1) {
      return null;
    }

    return Authentication.make(response.data);
  }

  public async removeHttpCookie(): Promise<void> {
    await this.datasource.destroy('auth/remove-http-cookie', { withCredentials: true });
  }

  public async getActiveDirectory(mail: string): Promise<ActiveDirectoryCredentials|null> {
    const { data: response } = await this.datasource.get(
      'auth/active-directories/domain',
      { params: { mail } },
    );

    if (Array.isArray(response)) {
      return null;
    }

    return ActiveDirectoryCredentials.make(response.data);
  }

  public async upsertUserFromActiveDirectory(data: IUpsertUserFromActiveDirectoryParams) {
    await this.datasource.send(
      'auth/active-directories/users',
      data,
    );
  }
}
