



























































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import InstructionRemittance from '@/domain/models/InstructionRemittance';
import IFilter from '@/domain/interfaces/IInstructionsRemittanceFilter';

import InstructionsRemittanceRepository from '@/repositories/InstructionsRemittanceRepository';

import InstructionsRemittanceFilter from './components/Filter.vue';
import InstructionsRemittanceList from './components/List.vue';

@Component({
  components: {
    InstructionsRemittanceFilter,
    InstructionsRemittanceList,
  },
})
export default class InstructionsRemittance extends Vue {
  @Ref('filter') filterComponent!: InstructionsRemittanceFilter;

  readonly instructionsRemittanceRepository
    : InstructionsRemittanceRepository = new InstructionsRemittanceRepository();

  showFilter: boolean = true;
  showList: boolean = false;

  data: IFilter = {} as IFilter;

  items: Record<string, Array<InstructionRemittance>> = {};

  toggle(): void {
    this.showFilter = !this.showFilter;
  }

  filter(): void {
    const { valid, data } = this.filterComponent.validate();

    if (valid) {
      this.load(data);
    }
  }

  async load(data: IFilter): Promise<void> {
    try {
      this.$dialog.startLoading();

      const groupId: number = this.$session.get('company_group_id');

      const response = await this.instructionsRemittanceRepository
        .list(groupId, data);

      const hasItems = Object.keys(response).length > 0;

      if (!hasItems) {
        this.$notification.warn('Nenhum resultado encontrado!');
        return;
      }

      this.items = response;
      this.data = data;
      this.showFilter = false;
      this.showList = true;
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  reload(): void {
    this.load(this.data);
  }
}
