
































































































































































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import DetailsLog from '@/views/billingRemittance/components/DetailsLog.vue';
import BillingRemittanceActionType from '@/domain/enums/BillingRemittanceActionType';
import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';
import BillingRemittanceAccountReceivableLog from '@/domain/models/BillingRemittanceAccountReceivableLog';
import BillingRemittanceAccountReceivableDetail from '@/domain/models/BillingRemittanceAccountReceivableDetail';

import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';

import { formatErrorForNotification } from '../utils';

@Component({
  components: {
    DetailsLog,
  },
})
export default class BillingRemittanceDetails extends Vue {
  @Prop(Boolean) open!: boolean;

  @Prop({
    type: Object as () => BillingRemittanceAccountReceivable,
  }) item!: BillingRemittanceAccountReceivable;

  @Emit()
  close() {
    return BillingRemittanceActionType.DETAILS;
  }

  @Watch('open')
  changedOpen() {
    if (this.open) {
      this.loadDetails();
    } else {
      this.tabs = null;
      this.loading = false;
      this.details = [];
    }
  }

  readonly billingRemittanceRepository = new BillingRemittanceRepository();

  tabs: any = null;
  loading: boolean = false;
  details: Array<BillingRemittanceAccountReceivableDetail> = [];
  logs: Array<BillingRemittanceAccountReceivableLog> = [];

  async loadDetails(): Promise<void> {
    try {
      this.loading = true;

      const {
        sequentialNumber: id,
        fluxDate: date,
      } = this.item;

      this.details = await this.billingRemittanceRepository.getDetails(id, date);
    } catch (error: any) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
    }
  }
}
