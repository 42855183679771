















































import {
    Vue,
    Component,
    Prop,
    Emit,
  } from 'vue-property-decorator';

  @Component({})
  export default class DifferentBankModal extends Vue {
    @Prop(Boolean) readonly show!: boolean;
    @Prop(Function) onConfirm!: () => {};

    @Emit()
    closeModal() {
      this.$emit('close');
    }

    confirm(): void {
      this.closeModal();
      this.onConfirm();
    }

    get showModal() {
      return this.show;
    }
  }
