








































































































import {
  Component,
  Prop,
  Watch,
  Mixins,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import { formatErrorForNotification } from '@/utils/error';
import { formateDate } from '@/utils/date';
import { toCurrency } from '@/utils/';

import PayrollRepository from '@/repositories/PayrollRepository';
import PayrollLogItem from '@/domain/models/PayrollLogItem';

interface IView {
  open: boolean,
  id: number,
}

@Component
export default class PayrollHistoryView
  extends Mixins<ModelManagementMixin<IView>>(ModelManagementMixin) {
  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  close() {
    this.model.open = false;
    this.model.id = 0;
  }

  @Watch('model.open')
  onChangeOpen() {
    if (this.model.open && this.model.id) {
      this.load();
    } else {
      this.items = [];
    }
  }

  readonly repository = new PayrollRepository();

  readonly formatDate = formateDate;
  readonly formatCurrency = toCurrency;

  loading: boolean = false;
  items: Array<PayrollLogItem> = [];

  async download(id: number) {
    try {
      const group = this.$session.get('company_group_id');

      const response = await this.repository.downloadReceiptFromItem(
        group,
        this.companies,
        this.model.id,
        id,
      );

      const blob = new Blob([response], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', '');
      link.click();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }

  async load(): Promise<void> {
    try {
      this.loading = true;

      const group = this.$session.get('company_group_id');
      const { id } = this.model;

      this.items = await this.repository.getLog(group, this.companies, id);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
