import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c(VRow,{attrs:{"widht":"100%","justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_vm._v(" Remover Bloqueio ")]),_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c(VCardText,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,[_vm._v(" Deseja realmente remover o bloqueio do título enviado? ")])],1)],1),_c(VCardActions,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"6","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"warning","depressed":"","large":"","block":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" NÃO ")])],1),_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"6","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"success","depressed":"","large":"","block":"","dark":""},on:{"click":function($event){return _vm.action()}}},[_vm._v(" SIM ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }