
































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { formatErrorForNotification } from '@/utils/error';

import VMenuOptions from '@/types/VMenuOptions';
import VDataTableHeader from '@/types/IVDataTableHeader';
import EconomicGroup from '@/domain/models/EconomicGroup';
import EconomicGroupListModel from '@/domain/models/EconomicGroupList';
import StatusType from '@/domain/enums/StatusType';

import EconomicGroupGroupingTypesEnum from '@/views/economicGroups/enums/EconomicGroupGroupingTypesEnum';

import EconomicGroupRepository from '@/repositories/EconomicGroupRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';

import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import StatusChip from '@/components/tables/StatusChip.vue';
import ActionButton from '@/components/tables/ActionButton.vue';
import DeleteDialog from './components/DeleteDialog.vue';

@Component({
  components: {
    ButtonFilterMenuOptions,
    StatusChip,
    ActionButton,
    DeleteDialog,
  },
})
export default class EconomicGroupList extends Vue {
  public readonly economicGroupRepository
    : EconomicGroupRepository = new EconomicGroupRepository();
  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);

  public loading: boolean = false;

  public search: string = '';
  public columnToSearch: string = 'name';
  public defaultColumnToSearch: VMenuOptions | null = null;

  public openDelete: boolean = false;
  public groupDelete: EconomicGroup = {} as EconomicGroup;

  public groups: EconomicGroupListModel[] = [];

  public filterOptions: Array<VMenuOptions> = [
    { text: 'Status', value: 'status' },
    { text: 'Grupo', value: 'name' },
    { text: 'Clientes', value: 'customers' },
  ];

  public headers: Array<VDataTableHeader> = [
    {
      text: 'Status',
      value: 'status',
      sortable: true,
    },
    {
      text: 'Grupo',
      value: 'name',
      sortable: true,
    },
    {
      text: 'Tipo de agrupamento',
      value: 'groupingType',
      sortable: true,
    },
    {
      text: 'Clientes/Empresas',
      value: 'entityNames',
      sortable: false,
    },
    {
      text: '',
      value: 'id',
      sortable: false,
      align: 'end',
    },
  ];

  public status = [
    {
      value: StatusType.ACTIVE,
      label: 'Ativo',
      color: 'success',
    },
    {
      value: StatusType.INACTIVE,
      label: 'Inativo',
      color: 'error',
    },
  ];

  public mounted(): void {
    this.defaultColumnToSearch = this.filterOptions
      .find(({ value }) => value === this.columnToSearch) ?? null;

    this.loadGroups();
  }

  public handleSearch(search: string): void {
    this.search = search;
    this.loadGroups();
  }

  public handleFilterChange(selectedOption: VMenuOptions | null): void {
    if (selectedOption !== null) {
      this.columnToSearch = selectedOption.value;
    }

    if (!this.search) return;

    this.loadGroups();
  }

  public handleCreate(): void {
    this.$router.push({ name: 'CreateEconomicGroup' });
  }

  public handleEdit(id: string): void {
    this.$router.push({ name: 'EditEconomicGroup', params: { id } });
  }

  public handleDelete(group: EconomicGroup): void {
    this.groupDelete = group;
    this.openDelete = true;
  }

  public handleClose(reload: boolean): void {
    if (reload) {
      this.loadGroups();
    }
  }

  public async loadGroups(): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      this.groups = await this.economicGroupRepository.getEconomicGroups(
        group,
        this.authenticationModule.companyIds,
      );

      if (!this.groups.length) {
        this.$notification.warn('Nenhum grupo econômico encontrado!');
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public getGroupingTypeTextName(value: EconomicGroupGroupingTypesEnum): string {
    switch (value) {
      case EconomicGroupGroupingTypesEnum.COMPANY: return 'Empresa';
      case EconomicGroupGroupingTypesEnum.CUSTOMER: return 'Cliente';
      default: return '';
    }
  }
}
