






















































import {
  Component,
  Mixins,
  Emit,
  Prop,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import DialogHelper from '@/helpers/DialogHelper';
import NewsRepository from '@/repositories/NewsRepository';

@Component
export default class ConfirmDeleteNewsDialog extends
Mixins<ModelManagementMixin<boolean>>(
  ModelManagementMixin,
) {
  @Prop({
    type: Number,
    required: true,
  }) newsId!: number;

  loading: boolean = false;

  newsRepository: NewsRepository = new NewsRepository();

  get dialogWidth(): string {
    return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
      smAndDown: '100%',
      md: '60%',
      default: '40%',
    });
  }

  @Emit('on-close')
  close(reload: boolean = false): boolean {
    return reload;
  }

  async removeNews(): Promise<void> {
    try {
      this.loading = true;

      await this.newsRepository.delete(
        this.newsId,
      );
    } catch (error) {
      this.$notification.error('Houve um problema ao remover notícia selecionada.');
    } finally {
      this.loading = false;
      this.close(true);
    }
  }
}
