


















import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { loadScript } from 'vue-plugin-load-script';
import { MOVIDESK_CLIENT_VARIABLE, MOVIDESK_CHAT_URL } from '@config/movidesk.ts';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import Module from '@/domain/models/Module';
import Drawer from './drawer/Drawer.vue';
import Toolbar from './toolbar/Toolbar.vue';

interface Window {
  movideskChatWidgetChangeWindowState: any;
  onload: any;
  movideskLogin: any;
  postMessage: any;
}

declare var window: Window;

@Component({
  components: {
    Drawer,
    Toolbar,
  },
})
export default class AppView extends Vue {
  companyVisible: boolean = false;
  authStore?: AuthenticationModule;

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);

    this.importMovideskCdi();
  }

  get currentModule(): Module | null {
    return (this.authStore && this.authStore!.currentModule) ?? null;
  }

  get hideAll(): boolean {
    return (
      this.$route.path === '/login'
      || this.$route.path === '/register'
      || this.$route.path == '/logout'
      || this.$route.path == '/reset-password'
    );
  }

  get hideDrawer(): boolean {
    return this.hideAll || this.currentModule == null;
  }

  actionCompany(value: boolean) {
    this.companyVisible = value;
  }

  public importMovideskCdi(): void {
    if (!MOVIDESK_CLIENT_VARIABLE || !MOVIDESK_CHAT_URL || !this.$session.get('user_access-chat_movidesk')) {
      return;
    }

    if (!this.authStore) {
      return;
    }

    if (!this.authStore.user.movidesk_id || this.authStore.user.movidesk_id === 'null') {
      return;
    }

    const movideskCode = document.createElement('script');

    movideskCode.setAttribute('type', 'text/javascript');

    movideskCode.innerHTML = `var mdChatClient="${MOVIDESK_CLIENT_VARIABLE}";`;
    movideskCode.id = 'mdChatClient';

    document.head.appendChild(movideskCode);

    loadScript(MOVIDESK_CHAT_URL)
      .then(() => {
        this.startMovideskChatAuthenticated();
      });
  }

  public startMovideskChatAuthenticated(): void {
    // eslint-disable-next-line no-undef
    window.postMessage({
        action: 'chatWidgetChangeWindowState',
        data: 'minimized',
    }, '*');

    window.movideskLogin({
        name: this.authStore!.user.name,
        email: this.authStore!.user.email,
        codeReference: this.authStore!.user.movidesk_id,
        stayConnected: false,
        emptySubject: false,
        startChat: false,
    });

    const classElement = document.getElementById('md-app-widget');

    if (classElement !== null) {
      classElement.style.visibility = 'visible';
    }
  }
}
