import EconomicGroupGroupingTypesEnum from '@/views/economicGroups/enums/EconomicGroupGroupingTypesEnum';
import StatusType from '@/domain/enums/StatusType';

export default class EconomicGroup {
  constructor(
    public id: number,
    public companyId: number,
    public name: string,
    public status: StatusType,
    public groupingType: EconomicGroupGroupingTypesEnum,
    public idCustomers: string[],
    public companyIds: number[],
  ) {}

  static make(data: any): EconomicGroup {
    return new EconomicGroup(
      data.id,
      data.company_id,
      data.name,
      data.status,
      data.grouping_type,
      data.id_customers,
      data.company_ids,
    );
  }
}
