import Repository from '@/repositories/base/Repository';
import AccountReceivablePreNegotiation from '@/domain/models/AccountReceivablePreNegotiation';
import IGetAccountsReceivableListFromPreNegotiationParams from '@/repositories/parameters/IGetAccountsReceivableListFromPreNegotiationParams';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';
import IGetAccountsReceivablePreNegotiationParams from '@/repositories/parameters/IGetAccountsReceivablePreNegotiationParams';
import AccountsReceivableTotalPreNegotiation from '@/domain/models/AccountsReceivableTotalPreNegotiation';
import AccountReceivablePreNegotiationDeadline from '@/domain/models/AccountReceivablePreNegotiationDeadline';
import IGetAccountsReceivablePreNegotiationDeadlineRequest from '@/repositories/parameters/IGetAccountsReceivablePreNegotiationDeadlineRequest';
import IGetSelectedAccountsReceivableByPreNegotiationParams from '@/repositories/parameters/IGetSelectedAccountsReceivableByPreNegotiationParams';

export default class AccountsReceivablePreNegotiationsRepository extends Repository {
  public async getAccountsReceivableListFromSpecificAccounts(
    companyGroupId: number,
    companyIds: number[],
    params: IGetAccountsReceivableListFromPreNegotiationParams,
  ): Promise<IDataTableWithLength<AccountReceivablePreNegotiation[]>> {
    const { data: response } = await this.datasource.send(
      `company-groups/${companyGroupId}/billings/pre-negotiations/accounts-receivable`,
      params,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    return {
      data: response.data.map(AccountReceivablePreNegotiation.make),
      total: response.total,
    };
  }

  public async getAccountsReceivableTotalFromSpecificAccounts(
    companyGroupId: number,
    companyIds: number[],
    params: IGetAccountsReceivablePreNegotiationParams,
  ): Promise<AccountsReceivableTotalPreNegotiation> {
    const { data: response } = await this.datasource.send(
      `company-groups/${companyGroupId}/billings/pre-negotiations/accounts-receivable/totals`,
      params,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    return AccountsReceivableTotalPreNegotiation.make(response.data);
  }

  public async getAccountsReceivablePreNegotiationDeadline(
    companyGroupId: number,
    companyIds: number[],
    params: IGetAccountsReceivablePreNegotiationDeadlineRequest,
  ): Promise<AccountReceivablePreNegotiationDeadline[]> {
    const { data: response } = await this.datasource.send(
      `company-groups/${companyGroupId}/billings/pre-negotiations/accounts-receivable/generate-deadlines`,
      params,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    return response.data.map(AccountReceivablePreNegotiationDeadline.make);
  }

  public async getAccountsReceivablePreNegotiationDeadlineByPreNegotiationId(
    companyGroupId: number,
    companyIds: number[],
    preNegotiationId: number,
  ): Promise<AccountReceivablePreNegotiationDeadline[]> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/billings/pre-negotiations/accounts-receivable/deadlines/${preNegotiationId}`,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    return response.data.map(AccountReceivablePreNegotiationDeadline.make);
  }

  public async getSelectedAccountsReceivableListFromPreNegotiation(
    companyGroupId: number,
    companyIds: number[],
    preNegotiationId: number,
    params: IGetSelectedAccountsReceivableByPreNegotiationParams,
  ): Promise<IDataTableWithLength<AccountReceivablePreNegotiation[]>> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/billings/pre-negotiations/accounts-receivable/selected/${preNegotiationId}`,
      {
        params,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return {
      data: response.data.map(AccountReceivablePreNegotiation.make),
      total: response.total,
    };
  }

  public async getSelectedAccountsReceivableTotalsFromPreNegotiation(
    companyGroupId: number,
    companyIds: number[],
    preNegotiationId: number,
    params: Omit<IGetSelectedAccountsReceivableByPreNegotiationParams, 'items_per_page' | 'page'>,
  ): Promise<AccountsReceivableTotalPreNegotiation> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/billings/pre-negotiations/accounts-receivable/selected/totals/${preNegotiationId}`,
      {
        params,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return AccountsReceivableTotalPreNegotiation.make(response.data);
  }
}
