import StickyDirective from './StickyDirective';
import StickyCell from './StickyCell.vue';
import './stickythead.scss';

export default {
  install(Vue) {
    Vue.directive('sticky', StickyDirective);
    Vue.component('sticky-cell', StickyCell);
  },
};
