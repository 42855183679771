<template>
	<div class="d-flex w-100 flex-column">
		<v-data-table
			:items="details"
			:headers="headers"
			hide-default-footer
			:items-per-page="-1"
			disable-pagination
			v-show="!reloading"
		>
			<template v-slot:item.fixed_value="{ item }">
				<value-type-select v-model="item.value_type" single-line/>
			</template>

			<template v-slot:item.status="{ item }">
				<status-checkbox v-model="item.status"/>
			</template>

			<template v-slot:item.data_field="{ item }">
				<v-text-field
					outlined
					dense
					class="mb-n5 mt-1"
					v-model="item.data_field"
				/>
			</template>
		</v-data-table>

		<v-divider class="flex-item mt-0"/>

		<fields-table-add
			:banking-fields="bankingFields"
			:banking-field-details="details"
			@add="insertFieldDetail"
		/>
	</div>
</template>

<script>
import FieldsTableAdd from './components/FieldsTableAdd.vue';
import StatusCheckbox from './components/StatusCheckbox.vue';
import ValueTypeSelect from './components/ValueTypeSelect.vue';

export default {
	name: 'ApiBankingConfigSaveFieldsTable',
	components: {
		FieldsTableAdd,
		StatusCheckbox,
		ValueTypeSelect,
	},
	props: {
		value: [Array, Object],
		bankingFields: [Object, Array],
	},
	data() {
		return {
			details: this.value,
			headers: [
				{
					text: 'Campo da API', value: 'field.field_name', width: '30%',
				},
				{
					text: 'Ativo', value: 'status', align: 'right', width: '10%',
				},
				{
					text: 'Tipo de Campo', value: 'fixed_value', width: '25%',
				},
				{
					text: 'Campo do Sistema', value: 'data_field', width: '35%', sortable: false,
				},
			],
			rules: {
				required: [(value) => !!value || 'Campo obrigatório.'],
			},
			value_types: [
				{ text: 'Campo do sistema', value: 'TF' },
				{ text: 'Valor Fixo', value: 'FV' },
				{ text: 'Valor Personalizado', value: 'MV' },
				{ text: 'Múltiplos Campos', value: 'CF' },
			],
			field_details: [],
			timeout: null,
			reloading: false,
		};
	},
	watch: {
		details: {
			deep: true,
			handler() {
				this.$emit('input', this.details);

				this.debounce(this.validateRequired);
			},
		},
		value() {
			if (this.details == this.value) return;

			this.details = this.value;
		},
		bankingFields() {
			this.reloadFields();
		},
	},
	mounted() {
		this.reloadFields();
	},
	methods: {
		getValueTypeDescription(valueType) {
			if (typeof valueType == 'object') {
				return `${valueType.text}`;
			}

			if (!valueType) {
				return '';
			}

			const toReturnValueType = this.value_types.find((type) => type.value == valueType);

			return `${toReturnValueType.text}`;
		},
		reloadFields() {
			if (!this.bankingFields) {
				return;
			}

			if (!this.details.length) {
				this.details = [];
			}

			this.reloading = true;

			const insertedFieldsIDs = this.getIdOfInsertedFields();

			const requiredFields = this.getToRequireFields(insertedFieldsIDs);

			this.details = [...this.details, ...requiredFields];

			this.$nextTick(() => {
				this.reloading = false;
			});

			this.validateRequired();
		},
		validateRequired() {
			const isInvalid = this.details
				.some((detail) => {
					const valid = detail.field.required == 'Y'
						&& (!detail.data_field || detail.data_field == '' || !detail.value_type);

					return valid;
				});

			this.$emit('validate', !isInvalid);
			return !isInvalid;
		},
		getToRequireFields(insertedFieldsIDs) {
			const mustRequire = this.bankingFields
				.filter((field) => this.isRequiredAndNotInserted(field, insertedFieldsIDs));

			const serializedToRequire = mustRequire.map((field) => {
				const toRequire = {
					field,
					data_field: '',
					status: 'A',
					fixed_value: 'N',
				};

				return toRequire;
			});

			return serializedToRequire;
		},
		getIdOfInsertedFields() {
			const insertedFieldsIDs = [];

			if (!this.details) {
				return insertedFieldsIDs;
			}

			this.details.forEach((detail) => {
				insertedFieldsIDs.push(detail.field.id);
			});

			return insertedFieldsIDs;
		},
		isRequiredAndNotInserted(field, insertedIds) {
			return field.required == 'Y' && !insertedIds.includes(field.id);
		},
		insertFieldDetail(toInsert) {
			this.details.push(toInsert);
		},
		debounce(func, wait = 500) {
			clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				func.apply(this);
			}, wait);
		},
	},
};
</script>

<style>

</style>
