import { getMilis } from '@/utils/date';
import ValidationHelper from '@/helpers/ValidationHelper';
import SortDataType from '@/helpers/types/SortDataType';
import IGenerateSortConfig from '@/helpers/interfaces/IGenerateSortConfig';

export default class SortHelper {
  public static getSortResultBasedOnValueType(
    type: SortDataType,
    firstValue: string|number,
    lastValue: string|number,
    configs: IGenerateSortConfig = {},
  ): number {
    if (type === 'date') {
      return this.getSortDate(configs.dateFormat, firstValue as string, lastValue as string);
    }

    if (type === 'number') {
      return this.getSortNumberValue(firstValue as number, lastValue as number);
    }

    if (type === 'financial') {
      return this.getSortFinancialValue(firstValue as string, lastValue as string);
    }

    return this.getSortText(firstValue as string, lastValue as string);
  }

  public static getSortDate(
    format: string|undefined = 'dd/MM/yyyy',
    first: string,
    second: string,
  ): number {
    return getMilis(first, format) - getMilis(second, format);
  }

  public static getSortText(first: string, second: string): number {
    return first
      .toLowerCase()
      .localeCompare(second.toLowerCase());
  }

  public static getSortFinancialValue(first: string, second: string): number {
    const formattedFirstValue = parseFloat(first.replaceAll('R$', ''));
    const formattedSecondValue = parseFloat(second.replaceAll('R$', ''));

    return formattedFirstValue - formattedSecondValue;
  }

  public static getSortNumberValue(
    first: number,
    second: number,
  ): number {
     return first - second;
  }

  public static onInvalidValues(
    isDescending: boolean,
    first: string|undefined|number|null,
    second: string|undefined|number|null,
  ): number {
    const isFirstValueValid = ValidationHelper.isValidValue(first);
    const isSecondValueValid = ValidationHelper.isValidValue(second);

    if (isDescending) {
      if (!isFirstValueValid && isSecondValueValid) {
        return -1;
      }

      if (isFirstValueValid && !isSecondValueValid) {
        return 1;
      }
    }

    if (!isDescending) {
      if (!isFirstValueValid && isSecondValueValid) {
        return 1;
      }

      if (isFirstValueValid && !isSecondValueValid) {
        return -1;
      }
    }

    return 0;
  }
}
