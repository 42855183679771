<template>
  <div>
    <div id="params" class="m-portlet m-portlet--mobile" v-show="!showWriteOff">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <h3 id="teste" class="m-portlet__head-text">
              Parâmetros
            </h3>
          </div>
        </div>
        <div class="m-portlet__head-tools">
          <button id="btn-generate" type="button" v-on:click="toHistory()" class="btn mr-2 m-btn--air btn-info">Histórico</button>
          <button id="btn-generate" type="button" v-on:click="loadConciliationRecord()" class="btn m-btn--air btn-success">Visualizar</button>
          <v-btn icon depressed class="ml-4" @click="toggleParams()">
            <v-icon x-large class="text--disabled chevron" :class="!paramsVisibility ? 'toggle-chevron' : ''" >mdi-chevron-up</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="m-portlet__body" v-show="paramsVisibility">
        <!--begin: Search Form -->
        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div class="row align-items-center">
            <div class="col-sm-3">
              <div class="form-group">
                <label>Dt. Movimento Inicial</label>
                <div class="input-group date" id="dateStart">
                  <input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
                  <span class="input-group-addon">
                    <i class="la la-calendar-check-o"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label>Dt. Movimento Final</label>
                <div class="input-group date" id="dateEnd">
                  <input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
                  <span class="input-group-addon">
                    <i class="la la-calendar-check-o"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label>Processamento Inicial</label>
                <div class="input-group date" id="dateProStart">
                  <input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
                  <span class="input-group-addon">
                    <i class="la la-calendar-check-o"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label>Processamento Final</label>
                <div class="input-group date" id="dateProEnd">
                  <input type="text" class="form-control m-input text-center" readonly="" placeholder="Selecione a data">
                  <span class="input-group-addon">
                    <i class="la la-calendar-check-o"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Empresa</label>
                <select class="form-control select2 hide" field-name="Empresa" id="company_id" name="company_id" style="width: 100%;">
                  <option value="">Selecione...</option>
                  <option v-for="company in companys" :value="company.id" v-bind:key="company.id">{{ company.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Banco</label>
                <select class="form-control select2 hide" field-name="Banco" id="bank" name="bank" style="width: 100%;">
                  <option value="">Selecione...</option>
                  <option v-for="bank in banks" :value="JSON.stringify(bank)" :key="bank.id">
                    {{bank.code}} {{ bank.agency}} {{ bank.account}} - {{ bank.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Status</label>
                <select class="form-control select2 hide" field-name="Status" id="status_id" name="status_id" style="width: 100%;" multiple="multiple">
                  <option v-for="status in listStatus" :value="status.id" v-bind:key="status.id">{{ status.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Ordenar Por</label>
                <select class="form-control select2 hide" field-name="Ordenar Por" name="assortment" id="assortment" style="width: 100%;">
                  <option v-for="sort in listSort" :value="sort.value" v-bind:key="sort.id">{{ sort.name }}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <template v-if="bankbalance" >
      <div id="bankbalance" class="m-portlet m-portlet--mobile" v-show="!showWriteOff">
        <div class="m-portlet__body">
          <h5>
            Extrato resumido dos movimentos conciliados
          </h5>
          <div class="table-responsive" :class="{'loading-new-balance': loadingNewBankBalance}">
            <div class="loading-container" v-if="loadingNewBankBalance">
              <v-progress-circular indeterminate color="primary" :size="64"></v-progress-circular>
            </div>
            <table id="table-conciliation" class="table table-conciliation">
              <thead class="table-head-resume">
                <tr>
                  <th class="text-rigth"></th>
                  <th class="text-center" width="20%">ERP</th>
                  <th class="text-center" width="20%">Banco</th>
                  <th class="text-center" width="20%">Diferença</th>
                </tr>
              </thead>
              <tbody>
                <tr class="zebra">
                  <td class="padding-zero text-rigth">
                    <span>
                      Saldo Inicial - {{bankbalance.dateStart}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(saldoBancarioInicialErp())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(saldoBancarioInicialBanco())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(difSaldoInicial())}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="padding-zero text-rigth">
                    <span>
                      Créditos
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(totalCreditoErp())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(totalCreditoBanco())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(difTotalCredito())}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="padding-zero text-rigth">
                    <span>
                      Débitos
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(totalDebitoErp())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(totalDebitoBanco())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(difTotalDebito())}}
                    </span>
                  </td>
                </tr>
                <tr class="zebra">
                  <td class="padding-zero text-rigth">
                    <span>
                      Saldo Final - {{bankbalance.dateEnd}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(this.saldoBancarioFinalErp())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(this.saldoBancarioFinalBanco())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center">
                    <span>
                      {{formatNumber(this.difSaldoFinal())}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <table v-if="futureBankbalance" id="table-conciliation-future" class="table table-conciliation">
              <tbody>
                <tr class="zebra">
                  <td class="padding-zero text-rigth">
                    <span>
                      Lançamentos futuros - {{bankbalance.dateStart}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(saldoBancarioInicialFutureErp())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(saldoBancarioInicialFutureBanco())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(difSaldoInicialFuture())}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="padding-zero text-rigth">
                    <span>
                      Créditos
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span> -->
                      <!--{{formatNumber(totalCreditoFutureErp())}}-->
                      <!-- 0,00
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(totalCreditoFutureBanco())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(difTotalFutureCredito())}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="padding-zero text-rigth">
                    <span>
                      Débitos
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(totalDebitoFutureErp())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(totalDebitoFutureBanco())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(difTotalFutureDebito())}}
                    </span>
                  </td>
                </tr>
                <tr class="zebra">
                  <td class="padding-zero text-rigth">
                    <span>
                      Saldo Final - {{bankbalance.dateEnd}} com lançamentos futuros - {{weekDayFutureBalance}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(this.saldoBancarioFinalFutureErp())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber(this.saldoBancarioFinalFutureBanco())}}
                    </span>
                  </td>
                  <td class="padding-zero text-center" width="20%">
                    <span>
                      {{formatNumber((this.difSaldoFinal() + this.difSaldoFinalFuture()))}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>
      </div>
    </template>
    <template v-if="listConciliationRecords">
      <div id="listConciliationRecords" class="m-portlet m-portlet--mobile" v-show="!showWriteOff">
        <div class="m-portlet__head">
          <div class="m-portlet__head-tools">
            <button id="btn-generate" type="button" v-on:click="saveConciliation()" class="btn m-btn--air btn-success">Salvar</button>
          </div>
        </div>
        <div class="m-portlet__body">
          <div class="table-responsive">
            <table id="table-conciliation" class="table table-conciliation">
              <thead class="table-head-resume">
                <tr>
                  <th class="text-center"></th>
                  <th class="text-center"></th>
                  <th class="text-center">Data Movimento</th>
                  <th class="text-center">Documento</th>
                  <th class="text-center">Titulo</th>
                  <th class="text-center">Tipo Lançamento</th>
                  <th class="text-center">Natureza Lançamento</th>
                  <th class="text-center">Histórico</th>
                  <th class="text-center">Valor</th>
                  <th width="110px" class="text-center">
                    <v-menu close-on-click bottom nudge-bottom="20">
                      <template v-slot:activator="{ on, attrs }">
                        Ação <i class="fa fa-bars dropdown-menu-icon ml-1" color="#575962" v-bind="attrs" v-on="on" v-if="writeOffReceivablesPermission == 1" />
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title @click="openAllWriteOff()">
                            Baixar Título a Receber
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </th>
                  <th></th>
                  </tr>
                </thead>
                <tbody 
                  v-for="(conciliationrecord, index) in listConciliationRecords" v-bind:key="JSON.stringify(conciliationrecord)" 
                  :class="index % 2 == 0 ? 'zebra' : ''" 
                  :data-flag="conciliationrecord.conciliation_record_movimento_bancario ? '0' : '1'"
                  :data-index="index"
                >
                  <tr v-for="(record, idx) in conciliationrecord.record" :key="idx" v-bind:class="conciliationrecord.conciliation_record_movimento_bancario ? '' : (idx % 2 == 0 ? '' : 'zebra')">
                    <td v-if="(!conciliationrecord.sequencial_conciliation_id) || (conciliationrecord.sequencial_conciliation_id && idx === 0)" class="padding-zero text-center" style="vertical-align: middle;" :rowspan="conciliationrecord.conciliation_record_movimento_bancario ? (conciliationrecord.record.length + conciliationrecord.conciliation_record_movimento_bancario.length) : 1">
                    <span v-if="record.status == 0" v-popover:tooltip="'Registro bancário não processado.'">
                      <i class="la la-history text-gold"></i>
                    </span>
                    <span v-if="record.status == 1" v-popover:tooltip="'Registro bancário conciliado com sucesso.'">
                      <i class="la la-check-circle-o text-green"></i>
                    </span>
                    <span v-if="record.status == 2" v-popover:tooltip="'Registro bancário com divergências no ERP.'">
                      <i class="la la-warning text-gold"></i>
                    </span>
                    <span v-if="record.status == 3" v-popover:tooltip="'Registro bancário não encontrado no ERP.'">
                      <i class="la la-times-circle text-red"></i>
                    </span>
                    <span v-if="record.status == 4" v-popover:tooltip="'Registro bancário ignorado.'">
                      <i class="la la-eye-slash"></i>
                    </span>
                    <span v-if="record.status == 5" v-popover:tooltip="'Registro bancário não conciliado.'">
                      <i class="la la-minus-circle"></i>
                    </span>
                    <span v-if="record.status == 6" v-popover:tooltip="'Registro ERP não conciliado.'">
                      <i class="la la-ban text-red"></i>
                    </span>
                    <span v-if="record.status == 7" v-popover:tooltip="'Registro ERP conciliado.'">
                      <i class="la la-check-circle-o text-green"></i>
                    </span>
                    <span v-if="record.status == 8" v-popover:tooltip="'Registro parcialmente conciliado.'">
                      <i class="la la-check-circle-o text-orange"></i>
                    </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                    {{'origin' in record ? record.origin : record.status == 6 || record.status == 7 ? 'ERP' : 'Banco'}}
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                    {{record.data_movimento | moment("DD/MM/YYYY")}}
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                    {{(record.numero_documento ? record.numero_documento : record.e5_documen)}}
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                    {{getNumberDocument(record)}}
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                    {{ (record.tp_lancamento == 'C' ? 'Crédito' : record.tp_lancamento == 'D' ? 'Débito' : getDescriptionOperation(record.tp_lancamento, record.e5_numero)) }}
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                    {{record.natureza_lancamento}}
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                    {{record.historico}}
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                    {{formatNumber(record.valor)}}
                    </td>
                    <td v-if="(!conciliationrecord.sequencial_conciliation_id) || (conciliationrecord.sequencial_conciliation_id && idx === 0)" style="vertical-align: middle;" class="text-center" :rowspan="record.status !== 2 && conciliationrecord.conciliation_record_movimento_bancario ? (conciliationrecord.record.length + conciliationrecord.conciliation_record_movimento_bancario.length) : 1">
                    <div class="m-checkbox-inline" v-if="record.status == 3 && record.tp_lancamento == 'C' && writeOffReceivablesPermission == 1">
                      <label class="m-checkbox m-checkbox--success">
                      <input type="checkbox" v-model="record.checkedWriteOff" v-on:click="checkRecordForWriteOff(record)" :disabled="record.checkboxDisabled">
                      <span></span>
                      </label>
                    </div>
                    <div class="m-checkbox-inline" v-if="record.status == 6 && reconcilePermission == 1">
                      <label class="m-checkbox m-checkbox--success">
                      <input type="checkbox" v-model="record.checked" v-on:click="checkRecord(record)">
                      <span></span>
                      </label>
                    </div>
                    <!-- <div class="m-checkbox-inline" style="margin-top: 5px" v-if="record.status == 6 && reconcilePermission == 1">
                      <span></span>
                      <span class="cursor-pointer" v-popover:tooltip.left="'Procurar movimentação bancária.'"  v-on:click="searchStatement(record)">
                      <i class="la la-search" style="color: #74788d"></i>
                      </span>
                    </div> -->
                    <!--Permissão-->
                    <v-menu
                      :disabled="record.checkboxDisabled"
                      close-on-click
                      bottom
                      nudge-bottom="20"
                      v-if="(record.status == 0 || record.status == 1 || record.status == 2 || record.status == 3 || record.status == 5 || record.status == 6 || record.status == 7 || record.status == 8)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                      <i
                        class="fa fa-bars dropdown-menu-icon ml-n1"
                        color="#575962"
                        v-bind="attrs"
                        v-on="on"
                      />
                      </template>
                      <v-list>
                      <v-list-item v-if="(record.status == 1 || record.status == 7 || record.status == 8)">
                        <v-list-item-title @click="removeConciliation(conciliationrecord, record)">
                        Desfazer Conciliação
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="(record.status == 0 || record.status == 2 || record.status == 3 || record.status == 5)">
                        <v-list-item-title @click="generateStatement(record, conciliationrecord)">
                        Nova Movimentação Bancária no ERP
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="(record.status == 0 || record.status == 2 || record.status == 3 || record.status == 5 || record.status == 6)">
                        <v-list-item-title @click="searchStatement(record)">
                        Procurar Movimentação Bancária
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item  v-if="(record.status != 1 && record.status != 6 && record.status != 7) && writeOffReceivablesPermission == 1 && record.tp_lancamento == 'C'">
                        <v-list-item-title @click="openWriteOff(conciliationrecord)">
                        Baixar Título a Receber
                        </v-list-item-title>
                      </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- <div v-if="record.tp_lancamento == 'D' && includePayMovementPermission == 1">
                      <span class="cursor-pointer" v-if="record.status == 0 || record.status == 2 || record.status == 3 || record.status == 5" v-popover:tooltip.left="'Nova movimentação bancária no ERP.'"  v-on:click="generateStatement(record)">
                      <i class="la la-plus-circle " style="color: #74788d"></i>
                      </span>
                      <span class="cursor-pointer" v-if="record.status == 0 || record.status == 2 || record.status == 3 || record.status == 5" v-popover:tooltip.left="'Procurar movimentação bancária.'"  v-on:click="searchStatement(record)">
                      <i class="la la-search" style="color: #74788d"></i>
                      </span>
                    </div> -->
                    <!-- <span class="cursor-pointer" v-if="(record.status == 1 || record.status == 7) && reconcilePermission == 1" v-popover:tooltip.left="'Desfazer Conciliação'" v-on:click="removeConciliation(record)" >
                      <i class="la la-remove" style="color: #74788d"></i>
                    </span> -->
                    </td>
                    <td style="vertical-align: middle;" class="text-center"></td>
                  </tr>
                  <tr v-for="(conciliationrecordmovimentobancario, idx) in conciliationrecord.conciliation_record_movimento_bancario" :key="conciliationrecordmovimentobancario.id">
                    <td style="vertical-align: middle;" class="text-center">
                      {{'origin' in conciliationrecordmovimentobancario ? conciliationrecordmovimentobancario['origin'] : 'ERP'}}
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                      <span v-bind:class="[conciliationrecord.record[0].status == 2 && conciliationrecord.data_movimento != conciliationrecordmovimentobancario.e5_data ? 'text-red' : '']">
                        {{conciliationrecordmovimentobancario.e5_data | moment("DD/MM/YYYY")}}
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                      <span>
                        {{(conciliationrecordmovimentobancario.numero_documento ? conciliationrecordmovimentobancario.numero_documento : conciliationrecordmovimentobancario.e5_documen)}}
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                      <span>
                        {{getNumberDocument(conciliationrecordmovimentobancario)}}
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                      <span>
                        {{(conciliationrecordmovimentobancario.tp_lancamento == 'C' || conciliationrecordmovimentobancario.e5_recpag == 'C' ? 'Crédito' : conciliationrecordmovimentobancario.tp_lancamento == 'D' || conciliationrecordmovimentobancario.e5_recpag == 'D' ? 'Débito' : getDescriptionOperation(conciliationrecordmovimentobancario.e5_recpag ,conciliationrecordmovimentobancario.e5_numero))}}
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                      <span>
                        {{conciliationrecordmovimentobancario.ed_descric}}
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                      <span>
                        {{conciliationrecordmovimentobancario.e5_histor}}
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                      <span v-bind:class="[conciliationrecord.record[0].status == 2 && conciliationrecord.valor != conciliationrecordmovimentobancario.e5_valor ? 'text-red' : '']">
                        {{formatNumber(conciliationrecordmovimentobancario.e5_valor)}}
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-center">
                      <div class="m-checkbox-inline" v-if="[2].includes(conciliationrecord.record[0].status)">
                        <label class="m-checkbox m-checkbox--success">
                          <input type="checkbox" v-model="conciliationrecordmovimentobancario.checked" v-on:click="checkConciliation(conciliationrecord, conciliationrecordmovimentobancario)">
                          <span></span>
                        </label>
                      </div>
                    </td>
                    <td v-if="[2].includes(conciliationrecord.record[0].status)" style="vertical-align: middle;" class="text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          <tooltip/>
        </div>
      </div>
    </template>

    <generate-statement ref="GenerateStatement"></generate-statement>
    <search-statement ref="SearchStatement"></search-statement>
    <scrolling-hide-component :show="showWriteOff" :scrollTo="scrollTo">
      <search-income-record :record="selectedConciliation" @close="onCLoseWriteOff" @conciliation:search="sendAcquitanceRequest" />
    </scrolling-hide-component>
  </div>
</template>

<script>
import _ from 'lodash';
import GenerateStatement from '../../components/generate-statement.vue';
import SearchStatement from '../../components/search-statement.vue';
import Swal from 'sweetalert2';
import ScrollingHideComponent from '../../../src/components/defaults/ScrollingHideComponent.vue';
import SearchIncomeRecord from './searchIncomeRecord/SearchIncomeRecord.vue';

export default {
  components: {
    'generate-statement': GenerateStatement,
    'search-statement': SearchStatement,
    ScrollingHideComponent,
    SearchIncomeRecord
  },
  data () {
    return {
      paramsVisibility: true,
      companys: [],
      banks: [],
      listStatus: [
        {id: 0, name: 'Não processado'},
        {id: 1, name: 'Conciliado'},
        {id: 2, name: 'Divergência'},
        {id: 3, name: 'Não Encontrado ERP'},
        {id: 6, name: 'Não Encontrado BANCO'},
        {id: 4, name: 'Ignorado'},
        {id: 5, name: 'Não conciliado'},
        {id: 7, name: 'ERP Conciliado'}
      ],
      listSort: [
        {id: 0, name: '', value: ''},
        {id: 1, name: 'Em Branco', value: 'empty'},
        {id: 2, name: 'Data do Movimento', value: 'date'},
        {id: 3, name: 'Documento', value: 'document'},
        {id: 4, name: 'Histórico', value: 'historic'},
        {id: 5, name: 'Valor', value: 'value'},
      ],
      listConciliationRecords: null,
      bank: null,
      bankbalance: null,
      futureBankbalance: null,
      company_id: null,
      REGISTRO_BANCARIO_AGUARDANDO_PROCESSAMENTO: 0,
      REGISTRO_BANCARIO_CONCILIADO_COM_SUCESSO: 1,
      REGISTRO_BANCARIO_COM_DIVERGENCIA: 2,
      REGISTRO_BANCARIO_NAO_ENCONTRADO_ERP: 3,
      REGISTRO_BANCARIO_IGNORADO: 4,
      REGISTRO_BANCARIO_NAO_CONCILIADO: 5,
      REGISTRO_ERP_NAO_CONCILIADO: 6,
      REGISTRO_ERP_CONCILIADO: 7,
      REGISTRO_AGLUTINADO: 9,
      reconcilePermission: this.$session.get('user_access-reconcile'),
      includePayMovementPermission: this.$session.get('user_access-include_pay_movement'),
      includeReceiveMovementPermission: this.$session.get('user_access-include_receive_movement'),
      writeOffReceivablesPermission: this.$session.get('user_access-write_off_receivables'),
      weekDayFutureBalance: '',
      selectedConciliation: null,
      scrollTo: 0,
      showWriteOff: false,
      loadingNewBankBalance: true,
    }
  },
  mounted: async function () {
      blockPage('Carregando os dados...');

      numeral.language('pt-br');

      $("#dateStart, #dateEnd, #dateProStart, #dateProEnd").datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy',
        todayHighlight:!0,
        orientation:"bottom left",
        templates:{
          leftArrow:'<i class="la la-angle-left"></i>',
          rightArrow:'<i class="la la-angle-right"></i>'
        }
      });

      $("#company_id").select2({placeholder: "Selecione uma Empresa"});

      $("#bank").select2({placeholder: "Selecione"});

      $("#status_id").select2({placeholder: "Selecione"});

      $("#assortment").select2({placeholder: "Selecione"});

      $("#company_id").change(async () => {
        $("#bank").val(null);
        $("#bank").trigger('change');
        await this.loadBanks();
      });

      await this.loadCompanys();

      await this.loadBanks();

      await this.loadParameter();

      const self = this;
      this.$emitter.on('conciliation_finish', function(data) {
        if( !data.error ) {
          self.loadBankbalance();
        }
      });

      unblockPage();
    },
  methods: {
      toggleParams() {
        this.paramsVisibility = !this.paramsVisibility;
      },
      hiddenParams() {
        this.paramsVisibility = false;
      },

      loadCompanys: async function () {
          await this.$http.get(this.$store.state.API_BASE + 'company/listAllActv' , {headers: {'token': this.$session.get('token')}}).then(
            (result) => {
      this.companys = result.body.data && result.body && result.body.data ? result.body.data : [];
            },
            (error) => {
      showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
            unblockPage();
            }
          )
      },
      loadBanks: async function () {
        let company_id = $("#company_id").val();

        if(!company_id) return

        await this.$http.get(this.$store.state.API_BASE + 'bank/listAllByCompany/' + company_id, {headers: {'token': this.$session.get('token')}}).then(
          (result) => {
            this.banks = result.body.data && result.body && result.body.data ? result.body.data : [];
          },
          (error) => {
            showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
            unblockPage();
          }
        );
      },
      loadParameter: async function () {
        await this.$http.get(this.$store.state.API_BASE + 'fieldparameter/getDataStatementConciliation', {headers: {'token': this.$session.get('token')}}).then(
          async (result) => {
            let date_initial = result.body.data.date_initial || moment().format("DD/MM/YYYY");

            $("#dateStart > input").val(date_initial);
            $("#dateStart > input").datepicker({
              autoclose: true,
              format: 'dd/mm/yyyy',
              todayHighlight:!0,
              orientation:"bottom left",
              defaultDate: date_initial,
              templates:{
                leftArrow:'<i class="la la-angle-left"></i>',
                rightArrow:'<i class="la la-angle-right"></i>'
              }
            });

            let date_finish = result.body.data.date_finish || moment().format("DD/MM/YYYY");

            $("#dateEnd > input").val(date_finish);
            $("#dateEnd > input").datepicker({
              autoclose: true,
              format: 'dd/mm/yyyy',
              todayHighlight:!0,
              orientation:"bottom left",
              defaultDate: date_finish,
              templates:{
                leftArrow:'<i class="la la-angle-left"></i>',
                rightArrow:'<i class="la la-angle-right"></i>'
              }
            });

            let date_pro_initial = result.body.data.date_pro_initial || moment().format("DD/MM/YYYY");

            $("#dateProStart > input").val(date_pro_initial);
            $("#dateProStart > input").datepicker({
              autoclose: true,
              format: 'dd/mm/yyyy',
              todayHighlight:!0,
              orientation:"bottom left",
              defaultDate: date_pro_initial,
              templates:{
                leftArrow:'<i class="la la-angle-left"></i>',
                rightArrow:'<i class="la la-angle-right"></i>'
              }
            });

            let date_pro_finish = result.body.data.date_pro_finish || moment().format("DD/MM/YYYY");

            $("#dateProEnd > input").val(date_pro_finish);
            $("#dateProEnd > input").datepicker({
              autoclose: true,
              format: 'dd/mm/yyyy',
              todayHighlight:!0,
              orientation:"bottom left",
              defaultDate: date_pro_finish,
              templates:{
                leftArrow:'<i class="la la-angle-left"></i>',
                rightArrow:'<i class="la la-angle-right"></i>'
              }
            });

            if(result.body.data.company_id){
              $("#company_id").val(result.body.data.company_id);
              $("#company_id").trigger('change');

              await this.loadBanks();
            }

            if(result.body.data.bank){
              $("#bank").val(JSON.stringify(result.body.data.bank));
              $("#bank").trigger('change');
            }

            let status_id = result.body.data.status_id || this.listStatus;

            $('#status_id').val(status_id);
            $('#status_id').trigger('change');

            let sort_value = result.body.data.assortment || this.listSort;

            $('#assortment').val(sort_value);
            $('#assortment').trigger('change');
          },
          (error) => {
            showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
            unblockPage();
          }
        );
      },
      loadConciliationRecord: function () {
        let self = this;

        checkImportBeforeSearch(self).then(
          function (result) {
            if (!result.body.payload) {
              self.loadConciliation();
            } else {
              showNotification('Atenção', 'Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.', 'warning')
              unblockPage();
            }

          },
          function (error) {
            showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
            unblockPage();
          });
      },
      loadConciliation: function () {

        this.listConciliationRecords = null;
        this.bank = null;
        this.company_id = null;
        this.bankbalance = null;
        this.conciliationIsRunninng = null;

        this.$forceUpdate();

        let dateStart = $("#dateStart > input").val();
        let dateEnd = $("#dateEnd > input").val();
        let dateProStart = $("#dateProStart > input").val();
        let dateProEnd = $("#dateProEnd > input").val();
        let company_id = $("#company_id").val();
        let bank = $("#bank").val();
        let sort = $("#assortment").val();

        if (sort == 'empty') {
          sort = '';
        }

        if (!company_id) {
          showNotification('Atenção','A empresa deve ser informada', 'warning');
          return false;
        }

        if (!bank) {
          showNotification('Atenção','O banco deve ser informado', 'warning');
          return false;
        }

        if ($.trim(dateStart) == '') {
          showNotification('Atenção','A data inicial deve ser informada', 'warning');
          return false;
        }

        if ($.trim(dateEnd) == '') {
          showNotification('Atenção','A data final deve ser informada', 'warning');
          return false;
        }

        if ($.trim(dateProStart) == '') {
          showNotification('Atenção','A data de processamento inicial deve ser informada', 'warning');
          return false;
        }

        if ($.trim(dateProEnd) == '') {
          showNotification('Atenção','A data de processamento final deve ser informada', 'warning');
          return false;
        }

        blockPage('Carregando os dados...');

        let status = ($("#status_id").val().length > 0) ? $("#status_id").val() :  'ALL';

        this.loadBankbalance();
        this.loadFutureBankbalance();

        $.ajax({
          method: "GET",
          url: this.$store.state.API_BASE + 'conciliation/record',
          headers: {
            'token': this.$session.get('token')
          },
          data: {
            dateStart: dateStart,
            dateEnd: dateEnd,
            dateProStart: dateProStart,
            dateProEnd: dateProEnd,
            company_id: company_id,
            status_id: status,
            bank: bank,
            assortment: sort
          }
        }).done((result) => {
          if (result.error) {
            showNotification('Atenção', result.message, 'danger');
          } else {
            if (result.total_records > 0){

              this.listConciliationRecords = [].concat(result.data.conciliacao).concat(result.data.movimento_bancario).concat(result.data.erp_multiplos_conciliados).concat(result.data.agglutine_banks) || [];
              // this.listConciliationRecords = _.sortBy(this.listConciliationRecords, ['data_movimento', 'status']);
              this.bank = bank;
              this.company_id = company_id;
              this.conciliationIsRunninng  = result.data.process_running;

              if (this.$session.get('user_config_autotoggle_paramns')) {
                this.hiddenParams();
              }

              this.$forceUpdate();

              setTimeout(()=>{
                $('table').floatThead({
                  position: 'absolute',
                   top: 48
                });

                const flag = document.querySelector('[data-flag="1"]');
                const flagIndex = flag?.dataset.index;

                if (flag && flag.childElementCount > 0) {
                  const isZebra = flag?.classList.contains('zebra');
                  const lastRowIsZebra = document.querySelector('[data-flag="1"] tr:last-of-type')?.classList.contains('zebra');

                  document
                    .querySelectorAll('[data-index]')
                    .forEach((item, i) => {
                      if (i > flagIndex) {
                        if ((isZebra && lastRowIsZebra) || (!isZebra && lastRowIsZebra)) 
                          item.classList.toggle('zebra');
                      }                  
                    });
                }

                if (flag && flag.childElementCount === 0) {
                  document
                    .querySelectorAll('[data-index]')
                    .forEach((item, i) => {
                      if (i > flagIndex)
                        item.classList.toggle('zebra');    
                    });
                }

              }, 1000)
            } else {
              showNotification('Atenção', 'Sem dados para listar', 'warning');
            }
          }

          unblockPage();
        }).fail((error) => {
          showNotification('Atenção', 'Opss!!! Não foi possível carregar os dados', 'danger');
          unblockPage();
        });
      },
      loadBankbalance(){
        this.bankbalance = null;
        this.loadingNewBankBalance = true;

        this.$forceUpdate();

        let dateStart = $("#dateStart > input").val();
        let dateEnd = $("#dateEnd > input").val();
        let dateProStart = $("#dateProStart > input").val();
        let dateProEnd = $("#dateProEnd > input").val();
        let company_id = $("#company_id").val();
        let bank = $("#bank").val();

        this.weekDayFutureBalance = this.formateFutureDateBank(dateStart);

        $.ajax({
          method: "GET",
          url: this.$store.state.API_BASE + 'conciliation/bankBalance',
          headers: {
            'token': this.$session.get('token')
          },
          data: {
            dateStart: dateStart,
            dateEnd: dateEnd,
            dateProStart: dateProStart,
            dateProEnd: dateProEnd,
            company_id: company_id,
            status_id: status,
            bank: bank,
          }
        }).done((result) => {
          if (result.error) {
            showNotification('Atenção', result.message, 'danger');
          } else {
            if (result && result.total_records > 0 && result.data){
              this.bankbalance = {
                dateStart: dateStart,
                dateEnd: dateEnd,
                saldoBancarioErp: parseFloat(result.data.saldoBancarioErp),
                totalDebitoErp: parseFloat(result.data.totalDebitoErp),
                totalCreditoErp: parseFloat(result.data.totalCreditoErp),
                saldoBancarioBanco: parseFloat(result.data.saldoBancarioBanco),
                totalDebitoBanco: parseFloat(result.data.totalDebitoBanco),
                totalCreditoBanco: parseFloat(result.data.totalCreditoBanco)
              };

              this.loadingNewBankBalance = false;
              this.$forceUpdate();
            }
          }
        }).fail((error) => {
          this.loadingNewBankBalance = false;
          showNotification('Atenção', 'Opss!!! Não foi possível carregar os dados dos saldos bancários!', 'danger');
          unblockPage();
        });
      },
      formateFutureDateBank (param) {
        const date = `${param.split('/')[2]}-${param.split('/')[1]}-${param.split('/')[0]}`;
        let newDate = moment(date).add(1, 'day').format('YYYY-MM-DD');
        let numberDay = moment(newDate).day();

        if (numberDay === 6 || numberDay === 0) {
          while (numberDay === 6 || numberDay === 0) {
            newDate = moment(newDate).add(1, 'day').format('YYYY-MM-DD');
            numberDay = moment(newDate).day();
          }
        }

        return moment(newDate).format('DD/MM/YYYY');
      },
      loadFutureBankbalance(){
        this.futureBankbalance = null;

        this.$forceUpdate();

        let dateStart = $("#dateStart > input").val();
        let dateEnd = $("#dateEnd > input").val();
        let dateProStart = $("#dateProStart > input").val();
        let dateProEnd = $("#dateProEnd > input").val();
        let company_id = $("#company_id").val();
        let bank = $("#bank").val();

        $.ajax({
          method: "GET",
          url: this.$store.state.API_BASE + 'conciliation/futureBankBalance',
          headers: {
            'token': this.$session.get('token')
          },
          data: {
            dateStart: dateStart,
            dateEnd: dateEnd,
            dateProStart: dateProStart,
            dateProEnd: dateProEnd,
            company_id: company_id,
            status_id: status,
            bank: bank,
          }
        }).done((result) => {
          if (result.error) {
            showNotification('Atenção', result.message, 'danger');
          } else {
            if (result && result.total_records > 0 && result.data && (result.data.totalDebitoBanco != "0.00" || result.data.totalCreditoBanco != "0.00")){
              this.futureBankbalance = {
                dateStart: dateStart,
                dateEnd: dateEnd,
                saldoBancarioErp: parseFloat(result.data.saldoBancarioErp),
                totalDebitoErp: parseFloat(result.data.totalDebitoErp),
                totalCreditoErp: parseFloat(result.data.totalCreditoErp),
                saldoBancarioBanco: parseFloat(result.data.saldoBancarioBanco),
                totalDebitoBanco: parseFloat(result.data.totalDebitoBanco),
                totalCreditoBanco: parseFloat(result.data.totalCreditoBanco)
              };

              this.$forceUpdate();
            }
          }
        }).fail((error) => {
          showNotification('Atenção', 'Opss!!! Não foi possível carregar os dados dos saldos bancários!', 'danger');
          unblockPage();
        });
      },
      totalCreditoErpSelecionado() {
        let total = 0;

        if (!this.listConciliationRecords) return 0;

        //Percorre todos os registros
        this.listConciliationRecords.forEach((record) => {

          if (record.status != this.REGISTRO_BANCARIO_COM_DIVERGENCIA && record.status != this.REGISTRO_ERP_NAO_CONCILIADO) return

          if (record.status == this.REGISTRO_BANCARIO_COM_DIVERGENCIA){
            //Caso o banco não tenha nenhum titulo do ERP relacionado, já retorna
            if (!record.conciliation_record_movimento_bancario) return

            //Buscar o movimento do erp marcado para conciliar
            let record_conta_pagar_checked = record.conciliation_record_movimento_bancario.find((erp) => erp.checked);

            if (record_conta_pagar_checked && record_conta_pagar_checked.e5_recpag == 'R') total += parseFloat(record_conta_pagar_checked.e5_valor);
          }

          if (record.status == this.REGISTRO_ERP_NAO_CONCILIADO){
            if (record.checked && record.tp_lancamento == 'R') total += parseFloat(record.valor);
          }
        });

        return total;
      },
      totalDebitoErpSelecionado() {
        let total = 0;

        if (!this.listConciliationRecords) return 0;

        //Percorre todos os registros
        this.listConciliationRecords.forEach((record) => {

          if (record.status != this.REGISTRO_BANCARIO_COM_DIVERGENCIA && record.status != this.REGISTRO_ERP_NAO_CONCILIADO) return

          if (record.status == this.REGISTRO_BANCARIO_COM_DIVERGENCIA){
            //Caso o banco não tenha nenhum titulo do ERP relacionado, já retorna
            if (!record.conciliation_record_movimento_bancario) return

            //Buscar o movimento do erp marcado para conciliar
            let record_conta_pagar_checked = record.conciliation_record_movimento_bancario.find((erp) => erp.checked);

            if (record_conta_pagar_checked && record_conta_pagar_checked.e5_recpag == 'P') total += parseFloat(record_conta_pagar_checked.e5_valor);
          }

          if (record.status == this.REGISTRO_ERP_NAO_CONCILIADO){
            if (record.checked && record.tp_lancamento == 'P') total += parseFloat(record.valor);
          }
        });

        return total;
      },
      //SALDO BANCARIO ERP
      saldoBancarioInicialErp(){
        return this.bankbalance.saldoBancarioErp;
      },
      saldoBancarioInicialFutureErp(){
        return this.futureBankbalance.saldoBancarioErp;
      },
      totalCreditoErp(){
        return this.bankbalance.totalCreditoErp + this.totalCreditoErpSelecionado();
      },
      totalCreditoFutureErp(){
        return this.futureBankbalance.totalCreditoErp + this.totalCreditoErpSelecionado();
      },
      totalDebitoErp(){
        return this.bankbalance.totalDebitoErp;
      },
      totalDebitoFutureErp(){
        return this.futureBankbalance.totalDebitoErp;
      },
      saldoBancarioFinalErp(){
        return (this.saldoBancarioInicialErp() ? this.saldoBancarioInicialErp() : 0) + this.totalCreditoErp() - this.totalDebitoErp();
      },
      saldoBancarioFinalFutureErp(){
        return this.saldoBancarioInicialFutureErp() + this.totalCreditoFutureErp() - this.totalDebitoFutureErp();
      },
      //SALDO BANCARIO NO BANCO
      saldoBancarioInicialBanco(){
        return this.bankbalance.saldoBancarioBanco;
      },
      saldoBancarioInicialFutureBanco(){
        return this.futureBankbalance.saldoBancarioBanco;
      },
      totalCreditoBanco(){
        return this.bankbalance.totalCreditoBanco;
      },
      totalCreditoFutureBanco(){
        return this.futureBankbalance.totalCreditoBanco;
      },
      totalDebitoBanco(){
        return this.bankbalance.totalDebitoBanco;
      },
      totalDebitoFutureBanco(){
        return this.futureBankbalance.totalDebitoBanco;
      },
      saldoBancarioFinalBanco(){
        return this.saldoBancarioInicialBanco() + this.totalCreditoBanco() - this.totalDebitoBanco();
      },
      saldoBancarioFinalFutureBanco(){
        return this.saldoBancarioInicialFutureBanco() + this.totalCreditoFutureBanco() - this.totalDebitoFutureBanco();
      },
      //TOTAIS SALDOS BANCARIOS
      difTotalCredito(){
        return this.totalCreditoErp() - this.totalCreditoBanco();
      },
      difTotalFutureCredito(){
        return this.totalCreditoFutureErp() - this.totalCreditoFutureBanco();
      },
      difTotalDebito(){
        return this.totalDebitoErp() - this.totalDebitoBanco();
      },
      difTotalFutureDebito(){
        return this.totalDebitoFutureErp() - this.totalDebitoFutureBanco();
      },
      difSaldoInicial(){
        return this.saldoBancarioInicialErp() - this.saldoBancarioInicialBanco();
      },
      difSaldoInicialFuture(){
        return this.saldoBancarioInicialFutureErp() - this.saldoBancarioInicialFutureBanco();
      },
      difSaldoFinal(){
        return this.difSaldoInicial() + this.difTotalCredito() - this.difTotalDebito();
      },
      difSaldoFinalFuture(){
        return this.difSaldoInicialFuture() + this.difTotalFutureCredito() - this.difTotalFutureDebito();
      },
      formatNumber(value){
        value = value || 0;
        return numeral(value).format('0,0.00');
      },
      getNumberDocument(key){
        let num = '';

        // if (key.e5_numero) {
          if(key.e5_filial) num += key.e5_filial + ' - ';
          if(key.e5_prefixo) num += key.e5_prefixo + ' - ';
          if(key.e5_numero) num += key.e5_numero + ' - ';
          if(key.e5_parcela) num += key.e5_parcela + ' - ';
          if(key.e5_tipo) num += key.e5_tipo;
        // } else if (key.numero_documento){
          // num = key.numero_documento;
        // }

        // if (key.e5_documen){
          // num = key.e5_documen;
        // };

        return num;
      },
      getDescriptionOperation(e5_recpag, e5_numero){
        let description = '';

        if (e5_recpag == 'P' && e5_numero ){
          description = 'Pagamento';
        }

        if (e5_recpag == 'R' && e5_numero ){
          description = 'Recebimento';
        }

        if (e5_recpag == 'P' && !e5_numero ){
          description = 'Mov. Bancário Pagar';
        }

        if (e5_recpag == 'R' && !e5_numero ){
          description = 'Mov. Bancário Receber';
        }

        if (e5_recpag == 'C' && e5_numero) {
          return 'Crédito';
        }

        if (e5_recpag == 'D' && e5_numero) {
          return 'Débito';
        }

        return description;
      },
      checkConciliation(conciliationrecord, conciliationrecordmovimentobancario){
        let newValue = !conciliationrecordmovimentobancario.checked;

        //Desmarcando todos
        conciliationrecord.conciliation_record_movimento_bancario.forEach((item)=> item.checked = false);

        //Marcando apenas o clicado agora
        conciliationrecordmovimentobancario.checked = newValue;

        //Para atualizar a tela
        this.$forceUpdate();
      },
      checkRecord(conciliationrecord){
        conciliationrecord.checked = !conciliationrecord.checked;
        this.$forceUpdate();
      },
    checkRecordForWriteOff(conciliationrecord){

    conciliationrecord.checkedWriteOff = !conciliationrecord.checkedWriteOff;

    //Para atualizar a tela
    this.$forceUpdate();
    },
      saveConciliation () {
        let self = this;

        checkImportBeforeSearch(self).then(
          function (result) {
            if (!result.body.payload) {
              self.save();
            } else {
              showNotification('Atenção', 'Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.', 'warning')
              unblockPage();
            }

          },
          function (error) {
            showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
            unblockPage();
          });
      },
      save(){
        let arraySave = [];

        //Percorre todos os registros
        this.listConciliationRecords.forEach((record) => {
          //Apenas tratado para conciliar os status 2 e 6.
          if (record.record.length == 1 && record.record[0].status != this.REGISTRO_BANCARIO_COM_DIVERGENCIA && record.record[0].status != this.REGISTRO_ERP_NAO_CONCILIADO) return

          if (record.record.length == 1 && record.record[0].status == this.REGISTRO_BANCARIO_COM_DIVERGENCIA){
            //Aqui vai tratar os casos que veio registro no arquivo do banco, e que ficou com divergencia com o ERP.
            //A API vai marcar o registro do banco como conciliado e marcar no ERP o e5_reconc = 'x'

            //Caso o banco não tenha nenhum titulo do ERP relacionado, já retorna
            if (!record.conciliation_record_movimento_bancario) return

            //Buscar o movimento do erp marcado para conciliar
            let record_conta_pagar_checked = record.conciliation_record_movimento_bancario.find((erp) => erp.checked);

            //Se não tem movimento marcado, já retorna.
            if (!record_conta_pagar_checked) return


            //Prepara objeto para enviar para a API.
            arraySave.push({
              id: record.record[0].id,
              type: 'C', // C para conciliar , N para inserir novo registro na SE5
              data_movimento: record.record[0].data_movimento,
              data_contabil: record.record[0].data_contabil,
              action_id: record.record[0].action_id,
              financials: [{
                e5_data: record_conta_pagar_checked.e5_data,
                e5_filial: record_conta_pagar_checked.e5_filial,
                e5_filorig: record_conta_pagar_checked.e5_filorig,
                e5_banco: record_conta_pagar_checked.e5_banco,
                e5_agencia: record_conta_pagar_checked.e5_agencia,
                e5_conta: record_conta_pagar_checked.e5_conta,
                e5_documen: record_conta_pagar_checked.e5_documen,
                e5_recpag: record_conta_pagar_checked.e5_recpag,
                e5_valor: record_conta_pagar_checked.e5_valor,
                e5_idorig: record_conta_pagar_checked.e5_idorig,
                e5_tipo: record_conta_pagar_checked.e5_tipo,
                e5_prefixo: record_conta_pagar_checked.e5_prefixo,
                e5_numero: record_conta_pagar_checked.e5_numero,
                e5_parcela: record_conta_pagar_checked.e5_parcela,
                e5_clifor: record_conta_pagar_checked.e5_clifor,
                e5_loja: record_conta_pagar_checked.e5_loja,
                e5_situaca: record_conta_pagar_checked.e5_situaca,
                e5_tipodoc: record_conta_pagar_checked.e5_tipodoc,
                e5_seq: record_conta_pagar_checked.e5_seq,
                ed_descric: record_conta_pagar_checked.ed_descric,
                e5_histor: record_conta_pagar_checked.e5_histor,
                e5_naturez: '',
                e5_ccd: '',
                e5_ccc: '',
                e5_clvldb: '',
                e5_clvlcr: '',
                e5_itemd: '',
                e5_itemc: '',
                TABLE_COMPANY_CODE: record_conta_pagar_checked.TABLE_COMPANY_CODE,
                recno: record_conta_pagar_checked.recno
              }]
            })
          }

          if (record.record.length == 1 && record.record[0].status == this.REGISTRO_ERP_NAO_CONCILIADO){
            //Aqui vai tratar os casos que tem registros no ERP que não estão conciliados, a API somente vai marcar o e5_reconc = 'x'

            //Se o movimento não está marcado para conciliar, já retorna.
            if (!record.record[0].checked) return


            //Prepara objeto para enviar para a API.
            arraySave.push({
              id: 0, // -> Como não tem registro do Banco, enviar 0 aqui.
              company_id: this.company_id,
              type: 'C', // C para conciliar,
              action_id: record.record[0].action_id,
              data_movimento: record.record[0].data_movimento,
              data_contabil: record.record[0].data_contabil,
              financials: [{
                e5_data: '',
                e5_filial: '',
                e5_filorig: '',
                e5_banco: '',
                e5_agencia: '',
                e5_conta: '',
                e5_documen: '',
                e5_recpag: '',
                e5_valor: '',
                e5_idorig: '',
                e5_tipo: '',
                e5_prefixo: '',
                e5_numero: '',
                e5_parcela: '',
                e5_clifor: '',
                e5_loja: '',
                e5_situaca: '',
                e5_tipodoc: '',
                e5_seq: '',
                ed_descric: '',
                e5_histor: '',
                e5_naturez: '',
                e5_ccd: '',
                e5_ccc: '',
                e5_clvldb: '',
                e5_clvlcr: '',
                e5_itemd: '',
                e5_itemc: '',
                TABLE_COMPANY_CODE: record.record[0].TABLE_COMPANY_CODE,
                recno: record.record[0].recno
              }]
            })
          }

          if (record.record.length > 1) {
            record.record.forEach((item) => {
              if (item.status == this.REGISTRO_ERP_NAO_CONCILIADO){
                //Aqui vai tratar os casos que tem registros no ERP que não estão conciliados, a API somente vai marcar o e5_reconc = 'x'

                //Se o movimento não está marcado para conciliar, já retorna.
                if (!item.checked) return

                //Prepara objeto para enviar para a API.
                arraySave.push({
                  id: 0, // -> Como não tem registro do Banco, enviar 0 aqui.
                  company_id: this.company_id,
                  type: 'C', // C para conciliar,
                  action_id: item.action_id,
                  data_movimento: item.data_movimento,
                  data_contabil: item.data_contabil,
                  financials: [{
                    e5_data: '',
                    e5_filial: '',
                    e5_filorig: '',
                    e5_banco: '',
                    e5_agencia: '',
                    e5_conta: '',
                    e5_documen: '',
                    e5_recpag: '',
                    e5_valor: '',
                    e5_idorig: '',
                    e5_tipo: '',
                    e5_prefixo: '',
                    e5_numero: '',
                    e5_parcela: '',
                    e5_clifor: '',
                    e5_loja: '',
                    e5_situaca: '',
                    e5_tipodoc: '',
                    e5_seq: '',
                    ed_descric: '',
                    e5_histor: '',
                    e5_naturez: '',
                    e5_ccd: '',
                    e5_ccc: '',
                    e5_clvldb: '',
                    e5_clvlcr: '',
                    e5_itemd: '',
                    e5_itemc: '',
                    TABLE_COMPANY_CODE: item.TABLE_COMPANY_CODE,
                    recno: item.recno
                  }]
                })
              }
            });
          }
        });

        if(!arraySave){
          showNotification('Atenção', 'Sem registros marcado para conciliar!', 'warning');
          return false;
        }

        blockPage('Aguarde...');

        $.ajax({
          method: "POST",
          url: this.$store.state.API_BASE + 'conciliation/conciliation',
          crossDomain: true,
          headers: {
            'token': this.$session.get('token')
          },
          data: {
            arrayData: JSON.stringify(arraySave)
          }
        }).done((result) => {
          unblockPage();
          if (result.error == false) {
            showNotification('Sucesso', 'Dados conciliados com sucesso!', 'success');
            this.loadConciliation();
          } else {
            showNotification('Atenção', 'Não foi possível salvar dados!', 'danger');
            return false;
          }
        }).fail((error) => {
          unblockPage();
          console.log(error);
          showNotification('Opss!', 'Não foi possível salvar dados!', 'danger');
        });
      },
      toHistory(){
        this.$router.push({ path: 'statementconciliationhistory' });
      },
      removeConciliation (conciliationrecord, record) {
        let self = this;
        
        checkImportBeforeSearch(self).then(
          function (result) {
            if (!result.body.payload) {
              self.desfazerConciliacao(conciliationrecord, record);
            } else {
              showNotification('Atenção', 'Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.', 'warning')
              unblockPage();
            }

          },
          function (error) {
            showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
            unblockPage();
          });
      },
      desfazerConciliacao(conciliationrecord, record){
        let arraySave = [];
        let arraySaveNew = [];

        Swal.fire({
          title: 'Deseja realmente desfazer a conciliação desse registro?',
          text: '',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {          
          if (result.value) {

            if (record.status == "7") {
              //Registro do ERP conciliado, sem registro do banco.
              arraySave = [{
                id: 0,
                company_id: this.company_id,
                TABLE_COMPANY_CODE: record.TABLE_COMPANY_CODE,
                recno: String(record.recno),
                data_movimento: record.data_movimento,
                action_id: record.action_id,
                sequencial_conciliation_id: 0
              }];
            } else {
              if (conciliationrecord.record.length == 1) {

                if (conciliationrecord.record[0].status == 1 || conciliationrecord.record[0].status == 8){

                  if (record.origin == 'ERP'){

                    conciliationrecord.conciliation_record_movimento_bancario.forEach(element => {
                      arraySaveNew.push({
                        id: element.id,
                        company_id: element.company_id,
                        company_group_id: element.company_group_id,
                        TABLE_COMPANY_CODE: 'TABLE_COMPANY_CODE' in element ? element.TABLE_COMPANY_CODE : element.table_company_code,
                        recno: String(element.recno),
                        data_movimento: element.data_movimento,
                        action_id: element.action_id,
                        sequencial_conciliation_id: 0
                      });
                    });                   
                  
                  }
                 //Tem registro do banco relacionado com o ERP
                  if (conciliationrecord.conciliation_record_movimento_bancario && conciliationrecord.conciliation_record_movimento_bancario.length > 0){
                    conciliationrecord.conciliation_record_movimento_bancario.forEach(element => {
                      arraySave.push({
                        id: conciliationrecord.record[0].id,
                        company_id: conciliationrecord.record[0].company_id,
                        company_group_id: conciliationrecord.record[0].company_group_id,
                        TABLE_COMPANY_CODE: 'TABLE_COMPANY_CODE' in element ? element.TABLE_COMPANY_CODE : element.table_company_code,
                        recno: String(element.recno),
                        data_movimento: conciliationrecord.record[0].data_movimento,
                        action_id: conciliationrecord.record[0].action_id,
                        sequencial_conciliation_id: 0
                      });
                    });
                  } else {
                    arraySave.push({
                      id: conciliationrecord.record[0].id,
                      company_id: conciliationrecord.record[0].company_id,
                      company_group_id: conciliationrecord.record[0].company_group_id,
                      TABLE_COMPANY_CODE: conciliationrecord.record[0].TABLE_COMPANY_CODE,
                      recno: String(conciliationrecord.record[0].recno),
                      data_movimento: conciliationrecord.record[0].data_movimento,
                      action_id: conciliationrecord.record[0].action_id,
                      sequencial_conciliation_id: 0
                    });
                  }
                } else {
                  showNotification('Opss!', 'Status não preparado para desconciliação!', 'danger');
                  return;
                }
              } else {
                //Registro com multiplos bancos
                conciliationrecord.conciliation_record_movimento_bancario.forEach(element => {
                  arraySave.push({
                    id: 0,
                    company_id: this.company_id,
                    TABLE_COMPANY_CODE: 'TABLE_COMPANY_CODE' in element ? element.TABLE_COMPANY_CODE : element.table_company_code,
                    recno: String(element.recno),
                    data_movimento: '',
                    action_id: '',
                    sequencial_conciliation_id: conciliationrecord.sequencial_conciliation_id
                  });
                });
              }
            }

            blockPage('Aguarde...');
            
            arraySave = (arraySaveNew.length > 0) ? arraySaveNew : arraySave;

            $.ajax({
              method: "POST",
              url: this.$store.state.API_BASE + 'conciliation/removeConciliation',
              headers: {
                'token': this.$session.get('token')
              },
              data: {
                arrayData: JSON.stringify(arraySave)
              }
            }).done((result) => {
              unblockPage();
              if (result.error == false) {
                showNotification('Sucesso', 'Dados desconciliados com sucesso!', 'success');

                if (conciliationrecord.origin == "ERP") {
                  //A linha do ERP irá ficar já, mas tem que adicionar as linhas do registros bancarios que foram desconciliados.
                  conciliationrecord.conciliation_record_movimento_bancario.forEach(element => {
                    this.listConciliationRecords.push({
                      id: element.id,
                      company_id: element.company_id,
                      company_group_id: element.company_group_id,
                      banco: element.banco,
                      agencia: element.agencia,
                      dvage: element.dvage,
                      numcon: element.numcon,
                      dvcta: element.dvcta,
                      numero_documento: element.numero_documento,
                      data_movimento: element.data_movimento,
                      data_contabil: element.data_contabil,
                      tp_lancamento: element.tp_lancamento,
                      natureza_lancamento: element.natureza_lancamento,
                      historico: element.historico,
                      valor: element.valor,
                      data_processamento: element.data_processamento,
                      status: this.REGISTRO_BANCARIO_NAO_CONCILIADO,
                      action_id: element.action_id,
                      conciliation_record_movimento_bancario:  null
                    });
                  });

                  conciliationrecord.status = this.REGISTRO_ERP_NAO_CONCILIADO;

                  this.listConciliationRecords = _.sortBy(this.listConciliationRecords, ['data_movimento', 'status']);
                } else {
                  if (conciliationrecord.status == 1){
                    //Tinha registro do banco relacionado com o ERP
                    conciliationrecord.status = this.REGISTRO_BANCARIO_NAO_CONCILIADO;

                    //Se tem movimentação do ERP, tem que adicionar as movtos na lista como não conciliados
                    if (conciliationrecord.conciliation_record_movimento_bancario.length > 0){

                      conciliationrecord.conciliation_record_movimento_bancario.forEach(element => {
                        this.listConciliationRecords.push({
                          id: element.id,
                          e5_banco :element.e5_banco,
                          e5_agencia: element.e5_agencia,
                          dvage: '',
                          e5_conta: element.e5_conta,
                          dvcta: '',
                          data_movimento: element.e5_data,
                          data_contabil: element.e5_data,
                          numero_documento: element.e5_documen,
                          tp_lancamento: element.e5_recpag,
                          natureza_lancamento: element.ed_descric,
                          historico: element.e5_histor,
                          valor: element.e5_valor,
                          status: this.REGISTRO_ERP_NAO_CONCILIADO,
                          e5_filial: element.e5_filial,
                          e5_prefixo: element.e5_prefixo,
                          e5_numero: element.e5_numero,
                          e5_parcela: element.e5_parcela,
                          e5_tipo: element.e5_tipo,
                          recno: element.recno,
                          TABLE_COMPANY_CODE: element.table_company_code
                        });
                      });

                      this.listConciliationRecords = _.sortBy(this.listConciliationRecords, ['data_movimento', 'status']);
                    }
                  }else if (conciliationrecord.status == 7){
                    //Registro do ERP conciliado, sem registro do banco.
                    conciliationrecord.status = this.REGISTRO_ERP_NAO_CONCILIADO;
                  }
                }

                conciliationrecord.conciliation_record_movimento_bancario = null;

                this.$forceUpdate();
                this.loadBankbalance();
                this.loadFutureBankbalance();
                this.loadConciliation()
              } else {
                showNotification('Atenção', 'Não foi possível salvar dados', 'danger');
                return false;
              }
            }).fail((error) => {
              unblockPage();
              console.log(error);
              showNotification('Opss!', 'Não foi possível salvar dados!', 'danger');
            });
          }
        })
      },
      generateStatement(record, conciliationrecord){
        this.$refs.GenerateStatement.openModal(record, this.bank, this, conciliationrecord);
      },
      searchStatement(conciliationrecord) {
          this.$refs.SearchStatement.openModal(conciliationrecord, this.bank, this);
      },
      openAllWriteOff() {
        const records = [];

        this.listConciliationRecords.forEach((record) => {
          const itens = [];

          record.record.forEach((item) => {
            if (!item.checkedWriteOff) {
              return false;
            }

            itens.push(item);
          });

          if (itens.length > 0) {
            records.push({
              record: itens
            });
          }
        });

        if (records.length <= 0) {
          showNotification('Atenção', 'Nenhum registro foi selecionado para baixar!', 'warning');
          return;
        }

        const newRecord = {};
        const itens = [];
        if (records.length > 0) {
          records.forEach((iten) => {
            itens.push(iten.record[0]);
          });

          newRecord.record = itens;
          newRecord.conciliation_record_movimento_bancario = [];
        }

        this.openWriteOff(newRecord);
      },
      openWriteOff(conciliationrecord) {
        this.scrollTo = window.scrollY;
        this.selectedConciliation = {
          sequencial_conciliation_id: conciliationrecord.sequencial_conciliation_id,
          values: conciliationrecord.record,
          conciliation_record_movimento_bancario: conciliationrecord.conciliation_record_movimento_bancario
        };
        this.showWriteOff = true;
      },
      onCLoseWriteOff(data) {
        this.selectedConciliation = {...this.selectedConciliation, ...data};

        this.showWriteOff = false;
      },
      sendAcquitanceRequest(data) {
        this.handlerDisabledCheckbox(data, true);
        this.onCLoseWriteOff();
        this.$http.post(
          `${this.$store.state.API_BASE}conciliation/acquittance/`,
          data,
          {
            headers: { token: this.$session.get('token') },
          }
        ).then(
          (result) => {
            this.handleConciliationFinish(result.body.data);
          },
          (err) => console.error(err)
        ).finally(
          () => this.handlerDisabledCheckbox(data, false)
        );
      },
      handleConciliationFinish(conciliated) {
        const ids = conciliated.ids;
        const newRecord = [];
        const indexToRemove = [];
        this.listConciliationRecords.forEach((record, idx) => {
          if (conciliated.sequencial && conciliated.first) {
            const index = record.record.findIndex(item => ids.includes(item.id));

            if (index !== -1) {
              record.record[index].checkedWriteOff = false;
              record.record[index].status = conciliated.recordStatus;
              newRecord.push(record.record[index]);

              record.record.splice(index, 1);
              indexToRemove.push(idx);
            }
          } else if (conciliated.sequencial && !conciliated.first) {
            if([conciliated.sequencial].includes(record.sequencial_conciliation_id)) {
              const previous = record.conciliation_record_movimento_bancario ?? [];
              record.record[0].checkedWriteOff = false;
              record.record[0].status = conciliated.recordStatus;
              record.conciliation_record_movimento_bancario = previous.concat(conciliated.records);
            }
          } else {
            if(Array.isArray(ids) && ids.includes(record.record[0].id)) {
              const previous = record.conciliation_record_movimento_bancario ?? [];
              record.record[0].checkedWriteOff = false;
              record.record[0].status = conciliated.recordStatus;
              record.conciliation_record_movimento_bancario = previous.concat(conciliated.records);
            }
          }
        });

        if (conciliated.sequencial && newRecord.length > 0) {
          // indexToRemove.forEach(item => {
          //   this.listConciliationRecords.splice(item, 1);
          // });

          this.listConciliationRecords = this.listConciliationRecords.filter(
            (item, index) => !indexToRemove.includes(index)
          );

          this.listConciliationRecords.push({
            record: newRecord,
            conciliation_record_movimento_bancario: conciliated.records,
            sequencial_conciliation_id: conciliated.sequencial
          });
        }
      },
      handlerDisabledCheckbox(data, bool) {
        const conciliationRecordsId = data.conciliationRecordsId;

        this.listConciliationRecords.forEach((item) => {
          item.record.forEach((rec) => {
            if (Array.isArray(conciliationRecordsId) && conciliationRecordsId.includes(rec.id)) {
              rec.checkedWriteOff = false;
              rec.checkboxDisabled = bool;
            }
          });
        });

        if(!bool) {
          this.listConciliationRecords = this.listConciliationRecords.slice();
        }
      },
    }
  }
</script>

<style scoped>
  .floatThead-container {
    top: 80px !important
  }

  .zebra {
    background: #f0f4f7;
  }

  .zebra[data-flag='1'] .zebra{
    background: #ffffff;
  }

  .vue-popover {
    font-size: 14px;
  }

  .padding-zero {
    padding-right:0px;
    padding-left:0;
  }

  .header-listConciliationRecords{
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 4px;
    background-color: #498cc5;
    color: white;
    font-weight: bold;
  }

  [class*=" la-"], [class^=la-] {
  font-size: 21px;
  }

  .m-checkbox>span, .m-radio>span {
    top: 0px;
    left: 5px;
  }

  .m-checkbox-inline .m-checkbox, .m-checkbox-inline .m-radio, .m-radio-inline .m-checkbox, .m-radio-inline .m-radio {
    margin-bottom: 13px;
  }

  .table td, .table th {
    border-top: transparent;
  }

  .cursor-pointer{
    cursor: pointer;
  }

  .v-menu__content .v-list-item {
  cursor: pointer;
    min-height: auto;
  padding: 0.6rem 1.2rem;
  }
  .v-menu__content .v-list-item:hover {
  background: #f4f5f8;
  }

  #bankbalance .table-responsive {
    position: relative;
  }

  #bankbalance .table-responsive.loading-new-balance table {
    opacity: 0.4
  }

  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

</style>