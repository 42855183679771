import StatusEnum from '@/domain/enums/StatusEnum';

export default class CompanySubscribersList {
  constructor(
    public id: number,
    public status: StatusEnum,
    public name: string,
    public subscribers: string[],
  ) {}

  static make(data: any): CompanySubscribersList {
    return new CompanySubscribersList(
        data.id,
        data.status === 0 ? StatusEnum.INACTIVE : StatusEnum.ACTIVE,
        data.name,
        data.subscribers,
    );
  }
}
