
























import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Watch } from 'vue-property-decorator';
import MovideskHelper from '@/helpers/MovideskHelper';
import MenuRepository from '@/repositories/MenuRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import Menu from '@/domain/models/Menu';
import DrawerList from './DrawerList.vue';
import IMenuStatusPair from './IMenuStatusPair';

@Component({
  components: {
    DrawerList,
  },
})
export default class Drawer extends Vue {
  mini: boolean = true;
  loading: boolean = true;
  menuStatusPair: IMenuStatusPair[] = [];
  authStore: AuthenticationModule | null = null;
  readonly menuRepository: MenuRepository = new MenuRepository();

  get moduleId(): number {
    return (this.authStore && this.authStore.currentModule && this.authStore.currentModule.id) ?? 0;
  }

  get companyGroupImage(): string {
    return this.authStore!.user.company_group_image ?? '';
  }

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);
  }

  mounted() {
    if (this.moduleId > 0) {
      this.loadMenus();
    }
  }

  loadMenus() {
    this.loading = true;
    this.menuRepository
      .getByModuleId(this.moduleId)
      .then((menus) => {
        this.loading = false;
        this.menuStatusPair = menus.filter(this.filterEmptyMenus).map((menu) => ({
          menu,
          active: false,
        }));
      })
      .catch(() => {
        // Critical error, retry login
        this.loading = false;
        this.$notification.error(
          'Ops, estamos tendo problemas com o sistema. Tente novamente mais tarde.',
        );

        MovideskHelper.setVisibilityOfChat(document.getElementById('md-app-widget'), 'hidden');
        MovideskHelper.removeMovideskScript(document.getElementById('mdChatClient'), document);

        this.authStore!.resetUser();
        this.$router.push({ name: 'login' });
      });
  }

  filterEmptyMenus(menu: Menu) {
    return menu.routeName != null || (menu.routeName == null && menu.children.length > 0);
  }

  closeAllMenus() {
    this.menuStatusPair.forEach((pair) => {
      pair.active = false;
    });
  }

  @Watch('mini')
  onMiniChanged(newValue: boolean) {
    if (newValue) {
      this.closeAllMenus();
    }
  }

  @Watch('moduleId')
  onModuleChanged(newModuleId: number) {
    if (newModuleId > 0) {
      this.loadMenus();
    }
  }
}
