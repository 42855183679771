


































































































import {
  Component,
  Mixins,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import VDataTableHeader from '@/types/IVDataTableHeader';
import IOption from '@/domain/interfaces/ISelectOptions';
import ESubscriberType from '@/domain/enums/SubscriberType';
import ActionButton from '@/components/tables/ActionButton.vue';
import StatusChip from '@/components/tables/StatusChip.vue';
import BillingSignatureSubscriber from '@/domain/models/BillingSignatureSubscriber';

@Component({
  components: {
    ActionButton,
    StatusChip,
  },
})
export default class SubscribersForm
  extends Mixins<ModelManagementMixin<Array<BillingSignatureSubscriber>>>(ModelManagementMixin) {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) loading?: boolean;

  public data: BillingSignatureSubscriber = {} as BillingSignatureSubscriber;

  public headers: Array<VDataTableHeader> = [
    {
      text: 'Nome',
      value: 'name',
      sortable: false,
      align: 'start',
    },
    {
      text: 'CNPJ',
      value: 'document',
      sortable: false,
      align: 'start',
    },
    {
      text: 'E-mail',
      value: 'mail',
      sortable: false,
      align: 'start',
    },
    {
      text: 'Tipo',
      value: 'type',
      sortable: false,
      align: 'start',
    },
    {
      text: '',
      value: 'id',
      sortable: false,
      align: 'end',
    },
  ];

  public typesForChip = [
    {
      value: ESubscriberType.PART,
      label: 'Parte',
      color: 'success',
    },
    {
      value: ESubscriberType.WITNESS,
      label: 'Testemunha',
      color: 'info',
    },
  ];

  public rule: Record<string, any> = {
    required: (v: string | number) => !!v || 'Campo obrigatório.',
    email: (v: string) => /.+@.+\..+/.test(v) || 'E-mail inválido.',
    cpf: (v: string) => /^\d{3}.\d{3}.\d{3}-\d{2}$/.test(v) || 'CPF inválido',
  };

  public types: Array<IOption<ESubscriberType>> = [
    {
      text: 'Parte',
      value: ESubscriberType.PART,
    },
    {
      text: 'Testemunha',
      value: ESubscriberType.WITNESS,
    },
  ];

  public formatCPF(value: string): string {
    return value
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  public addSubscriber(): void {
    if (this.form.validate()) {
      const {
        name,
        document,
        mail,
        type,
      } = this.data;

      this.model.push({
        name,
        document,
        mail,
        type,
      });

      this.data = {} as BillingSignatureSubscriber;
      this.form.reset();
    }
  }

  public removeSubscriber(index: number): void {
    this.model.splice(index, 1);
  }
}
