import StatementConciliationStatusType from '@/domain/enums/StatementConciliationStatusType';
import IIconStatusObject from '@/views/statementConciliation/interfaces/IIconStatusObject';

export default class StatementConciliationListHelpers {
  public static getStatusObject(
    statusCode: StatementConciliationStatusType,
  ): IIconStatusObject {
    switch (statusCode) {
      case StatementConciliationStatusType.NOT_PROCESSED:
        return {
          code: StatementConciliationStatusType.NOT_PROCESSED,
          text: 'Registro bancário não processado.',
          icon: 'mdi-history',
          color: 'orange',
        };
      case StatementConciliationStatusType.CONCILIATED:
        return {
          code: StatementConciliationStatusType.CONCILIATED,
          text: 'Registro bancário conciliado com sucesso.',
          icon: 'mdi-check-circle-outline',
          color: 'green',
        };
      case StatementConciliationStatusType.DIVERGENCE:
        return {
          code: StatementConciliationStatusType.DIVERGENCE,
          text: 'Registro bancário com divergências no ERP.',
          icon: 'mdi-alert-circle-outline',
          color: 'orange',
        };
      case StatementConciliationStatusType.NOT_FOUND_ERP:
        return {
          code: StatementConciliationStatusType.NOT_FOUND_ERP,
          text: 'Registro bancário não encontrado no ERP.',
          icon: 'mdi-close-circle-outline',
          color: 'red',
        };
      case StatementConciliationStatusType.IGNORED:
        return {
          code: StatementConciliationStatusType.IGNORED,
          text: 'Registro bancário ignorado.',
          icon: 'mdi-eye-off-outline',
          color: 'grey',
        };
      case StatementConciliationStatusType.NOT_CONCILIATED:
        return {
          code: StatementConciliationStatusType.NOT_CONCILIATED,
          text: 'Registro bancário não conciliado.',
          icon: 'mdi-minus-circle-outline',
          color: 'red',
        };
      case StatementConciliationStatusType.NOT_CONCILIATED_ERP:
        return {
          code: StatementConciliationStatusType.NOT_CONCILIATED_ERP,
          text: 'Registro ERP não conciliado.',
          icon: 'mdi-cancel',
          color: 'red',
        };
      case StatementConciliationStatusType.ERP_CONCILIATED:
        return {
          code: StatementConciliationStatusType.ERP_CONCILIATED,
          text: 'Registro ERP conciliado.',
          icon: 'mdi-checkbox-marked-circle-outline',
          color: 'green',
        };
      case StatementConciliationStatusType.PARTIALLY_CONCILIATED:
        return {
          code: StatementConciliationStatusType.PARTIALLY_CONCILIATED,
          text: 'Registro parcialmente conciliado.',
          icon: 'mdi-check-circle-outline',
          color: 'orange',
        };
      case StatementConciliationStatusType.PROCESSING:
        return {
          code: StatementConciliationStatusType.PROCESSING,
          text: 'Registro em processamento.',
          icon: 'mdi-progress-alert',
          color: 'orange',
        };
      case StatementConciliationStatusType.PENDING_WITH_ERP:
        return {
          code: StatementConciliationStatusType.PENDING_WITH_ERP,
          text: 'Integração do registro pendente.',
          icon: 'mdi-progress-alert',
          color: 'orange',
        };
      case StatementConciliationStatusType.FAILED_WITH_ERP:
        return {
          code: StatementConciliationStatusType.FAILED_WITH_ERP,
          text: 'Falha na integração do registro.',
          icon: 'mdi-alert-circle-outline',
          color: 'red',
        };
      default: throw new Error('Status Not Found');
    }
  }
}
