export default class BankAccount {
  constructor(
    public account: string,
    public agency: string,
    public code: string,
    public id: string,
    public name: string,
    public description: string,
  ) { }

  static make(data: any): BankAccount {
    return new BankAccount(
      data.account ?? '',
      data.agency ?? '',
      data.code ?? '',
      data.id ?? '',
      data.name ?? '',
      data.description ?? '',
    );
  }
}
