<template>
  <v-data-table :headers="files.headers" :items="items">
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        v-if="item.status == 1"
        dense
        small
        color="green"
        text-color="white">
        Ativo
      </v-chip>
      <v-chip
        v-else
        dense
        small>
        Inativo
      </v-chip>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      <td>{{ item.created_at | formatDate }}</td>
    </template>
    <template #[`item.download`]="{item}">
      <inn-icon-btn icon="mdi-download" @click="handleDownload(item)"/>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "ConciliationUploadListTable",
  data() {
    return {
      files: {
        headers: [
          {
            text: "Status",
            value: "status",
            sortable: false,
          },
          {
            text: "Data da Importação",
            value: "created_at",
            sortable: false,
          },
          {
            text: "Empresa",
            value: "company_name",
            sortable: false,
          },
          {
            text: "Usuário",
            value: "user",
            sortable: false,
          },
          {
            text: "Arquivo",
            value: "file_name",
            sortable: false,
          },
          {
            text:"Download",
            value:"download",
            sortable: false,
          }
        ]
      }
    };
  },

  props: {
    items: { type: Array, require: true }
  },
  methods: {
    handleDownload(value) {
      this.$emit('downloadFile', value);
    },
  }
};
</script>
