<template>
  <v-row class="pa-12">
		<v-col class="pa-0">
			<v-card class="elevation-0">
				<v-card-title>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								icon
								fab
								small
								v-bind="attrs"
								v-on="on"
								@click="$router.push('/dashboard')"
								class="mx-2">
									<v-icon>
										mdi-home
									</v-icon>
							</v-btn>
						</template>
						<span>Voltar para Inicial</span>
					</v-tooltip>
					<h4 class="text-h6 font-weight-bold ml-4 mt-2">
            Acesso PIX
          </h4>
					<v-spacer />
					<v-btn
						class="elevation-0"
						color="info"
						@click="$router.push('/apibankingcredential/save')">
						<v-icon class="mr-2">
							mdi-plus
						</v-icon>
						Adicionar
					</v-btn>
				</v-card-title>
				<v-divider class="mt-0" />
				<v-card-text>
					<v-data-table
						hide-default-footer
						:headers="headers"
            :loading="loading"
            :items="apiBankingCredentialTable"
						group-by="companyGroupName">
						<!-- eslint-disable-next-line -->
						<template v-slot:group.header="{ group, isOpen, toggle }">
              <th colspan="12" class="">
                <v-icon @click="toggle">
                  {{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>

                <span> {{ group }}</span>
              </th>
            </template>
						<template v-slot:[`item.status`]="{ item }">
              <v-chip
								v-if="item.status === 'I'"
								class="ma-1">
								Inativo
							</v-chip>
              <v-chip
								v-else
								class="ma-2"
								color="green"
								text-color="white">
                Ativo
							</v-chip>
            </template>
						<template v-slot:[`item.actions`]="{ item }">
              <div class="text-right d-flex">
                <v-tooltip bottom class="text-right">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push(`apibankingcredential/save/${item.userId}`)">
                        <v-icon>
													mdi-square-edit-outline
												</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
// eslint-disable-next-line
import ApiBankingCredentialService from "@/services/apibankingcredential.service";

export default {
	name: 'ApiBankingCredential',

  data() {
    return {
			headers: [
				{
					text: 'Status',
					width: '10%',
					align: 'center',
					sortable: false,
					value: 'status',
				},
				{
					text: 'Empresa',
					width: '25%',
					align: 'start',
					sortable: false,
					value: 'companyName',
				},
				{
					text: 'Usuário',
					align: 'start',
					sortable: false,
					value: 'userName',
				},
				{
					text: 'Login',
					align: 'start',
					sortable: false,
					value: 'userLogin',
				},
				{
					text: '',
					width: '5%',
					sortable: false,
					value: 'actions',
				},
			],
			apiBankingCredentialTable: [],
			loading: null,
		};
  },

	created() {
    this.loadTable();
  },
  methods: {
		async loadTable() {
			try {
        this.loading = true;
        const apiBankingCredentialService = new ApiBankingCredentialService();
        const result = await apiBankingCredentialService.listAll().index();
        this.apiBankingCredentialTable = result.data;
      } catch (error) {
				console.error(error);
        this.$helpers.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
		},
  },
};
</script>
