export default class HistoricalBillingsOrder {
  constructor(
    public months: string[],
      public values: number[],
  ) {}

  public static make(data: any[]): HistoricalBillingsOrder {
    const definedMonths = [];
    const definedValues = [];

    for (let i = 0; i < data.length; i += 1) {
      const { emission, value } = data[i];

      definedMonths.push(emission);
      definedValues.push(parseFloat(value.toFixed(2)));
    }

    return new HistoricalBillingsOrder(
      definedMonths,
      definedValues,
    );
  }
}
