import Repository from '@/repositories/base/Repository';
import CashFlowList from '@/domain/models/cashFlow/CashFlowList';
import IPredictedFlowFiltersRequest from '@/views/cashFlow/predictedFlow/interfaces/IPredictedFlowFiltersRequest';
import PredictedFlowDownloadReportTypesEnum from '@/views/cashFlow/predictedFlow/enums/PredictedFlowDownloadReportTypesEnum';

export default class CashFlowRepository extends Repository {
  public async getCashFlowPredictedByFilters(
    companyGroupId: number,
    companyIds: number[],
    filters: IPredictedFlowFiltersRequest,
  ): Promise<CashFlowList> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/cash-flow/predicted`,
      {
        params: filters,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return CashFlowList.make(response.data);
  }

  public async downloadReportPredictedFlow(
    companyGroupId: number,
    companyIds: number[],
    type: PredictedFlowDownloadReportTypesEnum,
    filters: IPredictedFlowFiltersRequest,
  ): Promise<Blob> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/cash-flow/predicted/download/${type}`,
      {
        params: filters,
        responseType: 'blob',
        headers: {
          'company-ids': JSON.stringify(companyIds),
        },
      },
    );

    return response;
  }
}
