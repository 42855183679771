





















































































































































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { formateDate } from '@/utils/date';
import { formatErrorForNotification } from '@/utils/error';

import EAction from '@/domain/enums/InstructionRemittanceAction';
import InstructionRemittance from '@/domain/models/InstructionRemittance';
import AccountReceivableDetail from '@/domain/models/AccountReceivableDetail';
import InstructionsRemittanceRepository from '@/repositories/InstructionsRemittanceRepository';

import { formatTitle, formatCurrency } from '../utils';

@Component
export default class InstructionsRemittanceViewDetails extends Vue {
  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<InstructionRemittance>,
  }) readonly items!: Array<InstructionRemittance>;

  @Emit()
  close(reload: boolean = false): { type: EAction, reload: boolean } {
    return {
      type: EAction.VIEW_DETAILS,
      reload,
    };
  }

  @Watch('open')
  onChangeOpen(open: boolean) {
    if (open) {
      this.loadDetails();
    } else {
      this.loading = false;
      this.details = {} as AccountReceivableDetail;
    }
  }

  readonly instructionsRemittanceRepository
    : InstructionsRemittanceRepository = new InstructionsRemittanceRepository();

  readonly formatDate = formateDate;
  readonly formatTitle = formatTitle;
  readonly formatCurrency = formatCurrency;

  tabs: any = null;
  loading: boolean = false;

  details: AccountReceivableDetail = {} as AccountReceivableDetail;

  get item(): InstructionRemittance {
    return this.items.length
      ? this.items[0]
      : {} as InstructionRemittance;
  }

  formatDateAndHour(value: string): string {
    const date = DateTime.fromISO(value).toLocaleString();
    const hour = DateTime.fromISO(value).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    return `${date} ${hour}`;
  }

  async loadDetails(): Promise<void> {
    try {
      this.loading = true;

      const groupId = this.$session.get('company_group_id');
      const { id, companyId } = this.item;

      this.details = await this.instructionsRemittanceRepository
        .getDetails(groupId, id, companyId);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
