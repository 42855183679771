import Repository from './base/Repository';

export default class SectorRepository extends Repository {
  public async getSectors(
    companyGroupId: number,
    companyIds: Array<number>,
    search: string,
  ) {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/erps/sectors/${search}`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return response.data.map((item: {sector: string}) => ({
      value: item.sector,
      text: item.sector,
    }));
  }

  public async getDistricts(
    companyGroupId: number,
    companyIds: Array<number>,
    search: string,
  ) {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/erps/districts/${search}`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return response.data.map((item: {district: string}) => ({
      value: item.district,
      text: item.district,
    }));
  }
}
