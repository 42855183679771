<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4 no-border">{{ message }}</v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn class="uppercase" color="primary darken-1" text @click.native="agree">Sim</v-btn>
        <v-btn class="uppercase" color="grey" text @click.native="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InnConfirmDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        width: 290,
        zIndex: 200,
      },
    };
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = { ...this.options, ...options };

      const self = this;
      return new Promise((resolve, reject) => {
        self.resolve = resolve;
        self.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style></style>
