enum PreNegotiationStatusOptionsEnum {
  PENDING = 'pending',
  SENT_TO_ERP = 'sent_to_erp',
  MADE_EFFECTIVE = 'made_effective',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  PENDING_TO_REMOVE_ON_ERP = 'pending_to_remove_on_erp',
  ERROR_ON_TRY_TO_REMOVE_ON_ERP = 'error_on_try_to_remove_on_erp',
}

export default PreNegotiationStatusOptionsEnum;
