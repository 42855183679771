import StatusType from '@/domain/enums/DDAConciliationStatusType';
import DDAConciliationMovement from './DDAConciliationMovement';

export default class DDAConciliation {
  constructor(
    public status: StatusType,
    public movements: Array<DDAConciliationMovement>,
    public uid?: string,
  ) {}

  static make(data: any): DDAConciliation {
    const movements = data.movements.map(DDAConciliationMovement.make);

    return new DDAConciliation(
      data.status,
      movements,
      data.uid,
    );
  }
}
