



































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClientModule from '@/stores/modules/ClientModule';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import OtherContants from '../components/OtherContacts.vue';

@Component({
  components: {
    OtherContants,
  },
})
export default class ClientContacts extends Vue {
  public clientModule: ClientModule = getModule(ClientModule);

  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  public phoneFieldMask: string = DocumentFormattation
    .getMaskBasedOnPhoneNumber(this.clientModule.client.phone);

  public handleInput(value: string | number, type: string): void {
    this.clientModule.setClient({
      ...this.clientModule.client,
      [type]: value,
    });
  }
}
