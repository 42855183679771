




























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import DrawerLink from './DrawerLink.vue';
import IMenuStatusPair from './IMenuStatusPair';

@Component({
  components: {
    DrawerLink,
  },
})
export default class DrawerGroup extends Vue {
  @Prop() readonly menuPair!: IMenuStatusPair;
  @Prop() readonly currentMenu!: string;
  active: boolean = this.menuPair.active;

  @Watch('menuPair.active')
  onMenuPairActiveChanged(newActive: boolean) {
    this.active = newActive;
  }

  @Watch('active')
  onActiveChange(newActive: boolean) {
    this.$emit('update:active', this.menuPair.menu.id, newActive);
  }
}
