enum StatementConciliationMovementType {
  CREDIT = 'C',
  DEBIT = 'D',
  PAYMENT = 'P',
  RECEIPT = 'R',
  BANKING_PAYABLE = 'BP',
  BANKING_RECEIVABLE = 'BR',
}

export default StatementConciliationMovementType;
