





























import { defineComponent } from '@vue/composition-api';
import RemittanceInvoiceRepository from '@/repositories/RemittanceInvoiceRepository';
import InvoiceListActionsEnum from '@/views/invoices/enums/InvoiceListActionsEnum';
import InvoiceAccountsList from '@/domain/models/invoices/InvoiceAccountsList';
import IVDataTableHeader from '@/types/IVDataTableHeader';

export default defineComponent({
  name: 'ListAccountsDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    invoiceId: {
      required: false,
      type: Number,
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const remittanceInvoiceRepository:
      RemittanceInvoiceRepository = new RemittanceInvoiceRepository();

    const isOpen: boolean = this.value;

    const invoiceAccounts: InvoiceAccountsList[] = [];

    const headers: IVDataTableHeader[] = [
      { text: 'Número', value: 'number' },
      { text: 'Parcela', value: 'installment' },
      { text: 'Prefixo', value: 'prefix' },
      { text: 'Fornecedor', value: 'providerCode' },
      { text: 'Loja', value: 'storeCode' },
      { text: 'Valor', value: 'value' },
      { text: 'Data de Emissão', value: 'emissionDate' },
      { text: 'Data de Vencimento Real', value: 'dueDate' },
    ];

    return {
      remittanceInvoiceRepository,
      isOpen,
      invoiceAccounts,
      headers,
    };
  },
  created() {
    this.getAccountFromBilling();
  },
  methods: {
    async getAccountFromBilling(): Promise<void> {
      try {
        this.$dialog.startLoading();

        const invoiceAccounts = await this.remittanceInvoiceRepository
          .getAccountsFromInvoice(this.companyGroupId, this.invoiceId!);

        this.invoiceAccounts = invoiceAccounts;
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os títulos da fatura.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    close(): void {
      this.$emit('close', InvoiceListActionsEnum.show_accounts);
    },
  },
});
