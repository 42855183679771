import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"pageTitle":"Distribuição de Operadores"}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VForm,{staticClass:"width-100"},[_c(VRow,{attrs:{"align":"center","justify":"space-between"}},[_c(VCol,{attrs:{"xs":"12","sm":"7","md":"6","cols":"12"}},[_c('inn-text-field-debounce',{staticClass:"search-field",attrs:{"label":"Digite algum valor para a pesquisa","icon":"mdi-magnify","hide-details":"","dense":""},on:{"on-finished-debounce":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"xs":"12","sm":"5","md":"3","cols":"12"}},[_c(VBtn,{attrs:{"block":"","large":"","color":"info"},on:{"click":_vm.handleCreate}},[_vm._v(" ADICIONAR ")])],1)],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.operators,"loading":_vm.loading,"loading-text":"Carregando distribuições de operadores...","no-data-text":"Nenhuma distribuição de operador encontrada.","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.operatorName",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('StatusChip',{attrs:{"value":value,"list":_vm.status}})]}},{key:"item.id",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('action-button',{attrs:{"label":"Editar","icon":"mdi-pencil","disabled":_vm.loading},on:{"click":function($event){return _vm.handleEdit(value)}}}),_c('action-button',{attrs:{"label":"Excluir","icon":"mdi-delete","disabled":_vm.loading},on:{"click":function($event){return _vm.handleDelete(item)}}})]}}])}),(_vm.openDelete)?_c('delete-dialog',{attrs:{"distribution":_vm.operatorDelete},on:{"close":_vm.handleClose},model:{value:(_vm.openDelete),callback:function ($$v) {_vm.openDelete=$$v},expression:"openDelete"}}):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }