












































import {
  Component,
  Emit,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import TradingPolicyRepository from '@/repositories/TradingPolicyRepository';
import TradingPolicy from '@/domain/models/TradingPolicy';

@Component
export default class TradingPolicyDeleteDialog
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  @Prop({
    type: Object as () => TradingPolicy,
    required: true,
    default: {} as TradingPolicy,
  }) policy!: TradingPolicy;

  @Emit('close')
  close(reload: boolean = false) {
    this.model = false;
    this.loading = false;

    return reload;
  }

  public readonly tradingPolicyRepository
    : TradingPolicyRepository = new TradingPolicyRepository();

  public loading: boolean = false;

  public get name(): string {
    return 'Nome do Usuário Aqui';
  }

  public get maxWidth(): string {
    if (this.$vuetify.breakpoint.xs) return '100%';

    if (this.$vuetify.breakpoint.smAndDown) return '80%';

    if (this.$vuetify.breakpoint.mdAndDown) return '60%';

    return '40%';
  }

  public save(): void {
    this.deleteTradingPolicy(this.policy.id);
  }

  public async deleteTradingPolicy(id: number): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      await this.tradingPolicyRepository
        .destroyTradingPolicy(group, id);

      this.$notification
        .success('Política de negociação excluida com sucesso!');
      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
