







































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import BillingRemittanceActionType from '@/domain/enums/BillingRemittanceActionType';
import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';
import BillingRemittanceAccountReceivableOccurrence from '@/domain/models/BillingRemittanceAccountReceivableOccurrence';

import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';

import { formatErrorForNotification } from '../utils';

@Component({
})
export default class BillingRemittanceOccurrences extends Vue {
  @Prop(Boolean) open!: boolean;

  @Prop({
    type: Object as () => BillingRemittanceAccountReceivable,
  }) item!: BillingRemittanceAccountReceivable;

  @Emit()
  close() {
    return BillingRemittanceActionType.OCCURRENCES;
  }

  @Watch('open')
  changedOpen() {
    if (this.open) {
      this.loadOcurrences();
    } else {
      this.tabs = null;
      this.loading = false;
      this.occurrences = [];
    }
  }

  readonly billingRemittanceRepository = new BillingRemittanceRepository();

  tabs: any = null;
  loading: boolean = false;
  occurrences: Array<BillingRemittanceAccountReceivableOccurrence> = [];

  async loadOcurrences(): Promise<void> {
    try {
      this.loading = true;

      const data = {
        companyId: this.item.companyId,
        clientCode: this.item.clientCode,
        branchCode: this.item.branchCode,
        storeCode: this.item.storeCode,
        bankCode: this.item.bankCode,
        documentNumber: this.item.documentNumber,
        documentParcel: this.item.quoteNumber,
        billingMethodType: this.item.billingMethodType,
        sequentialNumber: this.item.sequentialNumber,
        idCustomer: this.item.idCustomer,
      };

      this.occurrences = await this.billingRemittanceRepository
        .getOccurrences(data);
    } catch (error: any) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
    }
  }

  formatDateHour(value: string): string {
    const valueSplitted = value.split(' ');
    const date = valueSplitted[0].split('-').reverse().join('/');
    const hourSplitted = valueSplitted[1].split(':');
    const hour = `${hourSplitted[0]}:${hourSplitted[1]}`;
    return `${date} - ${hour}`;
  }
}
