export default class FinancialDocumentSituation {
  constructor(
    public id: number,
    public code: string,
    public status: string,
    public company_id: number,
    public description: string,
    public company_group_id: number,
  ) {}

  static make(data: any): FinancialDocumentSituation {
    return new FinancialDocumentSituation(
      data.id,
      data.code,
      data.status,
      data.company_id,
      data.description,
      data.company_group_id,
    );
  }
}
