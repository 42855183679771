import MovementType from '@/domain/enums/StatementConciliationMovementType';
import MovementOriginType from '@/domain/enums/StatementConciliationMovementOriginType';
import axios, { AxiosError } from 'axios';

export function formatCurrency(value: string | number): string {
  if (!value || value == '') return '0,00';
  if (typeof value == 'number') value = value.toFixed(2);
  const signal = parseFloat(value) < 0 ? '- ' : '';
  const numbers = value.replace(/[^0-9]/g, '');
  const floatValue = +numbers.replace(/(\d{2})$/, '.$1');
  const splitedValue = floatValue.toFixed(2).split('.');
  const formatedValue = `${signal}${splitedValue[0].replace(/(?=(\d{3})+(?!\d))/g, '.').replace(/^\./g, '')},${splitedValue[1]}`;
  return formatedValue;
}

export function formatType(type: string): string {
  if (!type || type == '') return '';
  switch (type) {
    case MovementType.DEBIT:
      return 'Débito';
    case MovementType.CREDIT:
      return 'Crédito';
    case MovementType.PAYMENT:
      return 'Pagamento';
    case MovementType.RECEIPT:
      return 'Recebimento';
    case MovementType.BANKING_PAYABLE:
      return 'Mov. Bancário a Pagar';
    case MovementType.BANKING_RECEIVABLE:
      return 'Mov. Bancário a Receber';
    default:
      return type;
  }
}

export function formatOrigin(origin: string): string {
  return origin === MovementOriginType.BANK ? 'Banco' : 'ERP';
}

export function formatErrorForNotification(error: Error | AxiosError): string {
  let returnMessage = '';

  if (axios.isAxiosError(error)) {
    const { message, errors } = error.response?.data;
    if (typeof errors === 'object' && errors !== null) {
      const errorsStringify = Object.entries(errors)
        .map(([key, value]) => (value == message ? '' : value)).join('\n');
      returnMessage = `${message} \n\n ${errorsStringify}`;
    } else {
      returnMessage = message;
    }
  } else {
    returnMessage = error.message;
  }

  return returnMessage;
}
