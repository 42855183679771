import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VImg,{staticClass:"background-login-image",attrs:{"max-height":"1217","max-width":"2165","src":require("@/assets/background/inncash-background-2023.webp")}}),_c(VRow,{staticStyle:{"height":"100vh"},attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"12","xl":"7","lg":"7","md":"7","sm":"12","xs":"12"}},[_c(VCard,[_c(VCardTitle,{staticClass:"py-5 px-5"},[_c('span',{staticClass:"title"},[_vm._v(" Redefinir a senha ")])]),_c(VDivider,{staticClass:"my-0"}),_c(VCardText,{staticClass:"mt-3 px-5"},[(_vm.checkResetPasswordMode === 'manual')?_c('p',{staticClass:"mb-7"},[_vm._v(" Seu usuário foi definido por um Administrador para redefinir a senha. ")]):_vm._e(),_c(VForm,{ref:"ResetPasswordForm"},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Nova senha","outlined":"","type":"password","disabled":_vm.loading,"rules":[
                      _vm.inputRules.required(),
                      _vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Repetir senha","outlined":"","type":"password","disabled":_vm.loading,"rules":[
                      _vm.inputRules.required(),
                      _vm.inputRules.lowerOrEqualThen(64) ]},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"pb-5 px-5 mt-6"},[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"large":"","color":"info","loading":_vm.loading},on:{"click":_vm.handleResetPassword}},[_vm._v(" REDEFINIR A SENHA ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }