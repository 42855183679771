<template>
  <div>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <label>QR Code</label>
        <v-text-field
          id="code"
          type="text"
          dense
          outlined
          hint="Para inserir o valor do QR Code, siga as instruções abaixo."
          persistent-hint
          v-model="code"
          @input="emitChange($event)"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="12" md="12">
        <v-card class="my-2" outlined>
            <v-card-title> Instruções: </v-card-title>
            <v-card-text>
              <ol class="pl-4">
                <li>
                  Para fazer a leitura do QR Code, clique no botão "Selecionar
                  Janela" abaixo.
                </li>
                <li>
                  Irá aparecer uma mensagem solicitando permissão para compartilhar a tela,
                  basta aceitar para continuar.
                </li>
                <li>
                  Escolha a janela que contém o QR Code e clique em "Compartilhar".
                </li>
                <li>
                  Após isso, a aba selecionada aparecerá e você pode selecionar a área do
                  QR Code para fazer a leitura.
                </li>
              </ol>
              <v-btn
                color="green"
                outlined
                class="white--text"
                @click="openScreenshot"
                >Selecionar Janela</v-btn
              >
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>

    <v-row>
      <div
        id="screenshot"
        class="container"
        @mousedown="mouseDown"
        @mousemove="move"
        @mouseup="mouseUp"
        v-if="imageUrl"
      >
        <div
          class="overlay"
          :class="{ highlighting: isMouseDown }"
          :style="{ borderWidth: borderWidth }"
        ></div>
        <div
          class="crosshairs"
          :class="{ hidden: isDraggingMouse }"
          :style="{ left: crossHairsLeft + 'px', top: crossHairsTop + 'px' }"
        ></div>
        <img id="qrcodeImage" :src="imageUrl" v-if="imageUrl" />
      </div>
      <video
        style="visibility: hidden"
        id="qrcodeVideo"
        width="60vw"
        :srcObject.prop="video"
        autoplay
        v-if="video"
        @play="onVideoLoad"
      />
    </v-row>
  </div>
</template>

<script>
import jsQR from 'jsqr';
import html2canvas from 'html2canvas';

export default {
  name: 'QRCodeReader',
  props: {
    qrcode: String,
  },
  data() {
    return {
      video: null,
      crossHairsLeft: 0,
      crossHairsTop: 0,
      startX: 0,
      startY: 0,
      cropStartX: 0,
      cropStartY: 0,
      cropEndX: 0,
      cropEndY: 0,
      isMouseDown: false,
      isDraggingMouse: false,
      borderWidth: '',
      imageUrl: null,
      code: this.qrcode || null,
    };
  },
  methods: {
    openScreenshot() {
      const capture = async () => {
        try {
          const captureStream = await navigator.mediaDevices.getDisplayMedia();
          this.video = captureStream;
        } catch (err) {
          console.error(`Error: ${err}`);
        }
      };
      capture();
    },
    onVideoLoad() {
      const video = document.getElementById('qrcodeVideo');
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0);
      this.imageUrl = canvas.toDataURL('image/png');
      this.video.getTracks().forEach((track) => track.stop());
      this.video = null;
    },
    hasNegativeNumber(list) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < list.length; index++) {
        if (list[index] < 0) {
          return true;
        }
      }
      return false;
    },
    move(event) {
      const bounds = document
        .getElementById('screenshot')
        .getBoundingClientRect();
      const endX = event.clientX - bounds.left;
      const endY = event.clientY - bounds.top;
      const crossHairsTop = event.clientY - bounds.top;
      const crossHairsLeft = event.clientX - bounds.left;
      if (this.hasNegativeNumber([endX, endY, crossHairsTop, crossHairsLeft])) {
        this.startX = 0;
        this.startY = 0;
        this.cropStartX = 0;
        this.cropStartY = 0;
        this.isMouseDown = false;
        this.isDraggingMouse = false;
        this.borderWidth = '';
        return;
      }
      this.crossHairsTop = crossHairsTop;
      this.crossHairsLeft = crossHairsLeft;
      if (!this.isMouseDown) {
        this.isDraggingMouse = false;
        this.borderWidth = '';
        return;
      }
      this.isDraggingMouse = true;
      /* start top left: sx=10 sy = 10 ex = 20 ey = 20
       * start top right: sx=10 sy = 10 ex = 5 ey = 20
       * start bottom left: sx=10 sy = 20 ex = 20 ey = 10
       * start bottom right: sx=20 sy = 20 ex = 10 ey = 10
       */
      // default start top left
      let top = this.startY;
      let right = bounds.width - endX;
      let bottom = bounds.height - endY;
      let left = this.startX;

      // start top right
      if (endX <= this.startX && endY >= this.startY) {
        right = bounds.width - this.startX;
        left = endX;
        // start bottom left
      } else if (endX >= this.startX && endY <= this.startY) {
        top = endY;
        bottom = bounds.height - this.startY;
        // start bottom right
      } else if (endX <= this.startX && endY <= this.startY) {
        top = endY;
        right = bounds.width - this.startX;
        bottom = bounds.height - this.startY;
        left = endX;
      }
      // top right bottom left
      this.borderWidth = `${top}px ${right}px ${bottom}px ${left}px`;
    },
    mouseDown(event) {
      const bounds = document
        .getElementById('screenshot')
        .getBoundingClientRect();
      const startX = event.clientX - bounds.left;
      const startY = event.clientY - bounds.top;

      if (this.hasNegativeNumber([startX, startY])) {
        return;
      }

      this.startX = startX;
      this.startY = startY;
      this.isMouseDown = true;
      this.cropStartX = startX;
      this.cropStartY = startY;
    },
    mouseUp(e) {
      this.borderWidth = 0; // resetting the overlay

      if (this.isDraggingMouse) {
        // Don't take the screenshot unless the mouse moved somehow.
        this.tookScreenShot = true;
      }

      this.isDraggingMouse = false;
      this.isMouseDown = false;
      this.borderWidth = '';

      const bounds = document
        .getElementById('screenshot')
        .getBoundingClientRect();
      const cropEndX = e.clientX - bounds.left;
      const cropEndY = e.clientY - bounds.top;
      if (this.hasNegativeNumber([cropEndX, cropEndY])) {
        return;
      }
      this.cropEndX = cropEndX;
      this.cropEndY = cropEndY;

      this.takeScreenshot();
    },
    takeScreenshot() {
      const options = {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
      };
      html2canvas(document.getElementById('qrcodeImage'), options).then(
        (canvas) => {
          const context = canvas.getContext('2d');
          const imageData = context.getImageData(
            this.cropStartX,
            this.cropStartY,
            this.cropEndX - this.cropStartX,
            this.cropEndY - this.cropStartY,
          );
          if (!imageData) {
            // eslint-disable-next-line no-undef
            showNotification(
              'Atenção',
              'QR Code não encontrado. Por favor, selecione uma área maior!',
              'warning',
            );
            return;
          }

          const code = jsQR(imageData.data, imageData.width, imageData.height);

          if (!code || !code.data) {
            // eslint-disable-next-line no-undef
            showNotification(
              'Atenção',
              'Erro ao ler o QR Code. Por favor, selecione uma área maior!',
              'warning',
            );
            return;
          }

          this.imageUrl = null;
          this.crossHairsLeft = 0;
          this.crossHairsTop = 0;
          this.startX = 0;
          this.startY = 0;
          this.cropStartX = 0;
          this.cropStartY = 0;
          this.cropEndX = 0;
          this.cropEndY = 0;
          this.code = code.data;
          this.emitChange(code.data);
        },
      );
    },
    emitChange(data) {
      this.$emit('input', data);
    },
  },
};
</script>

<style>
.qr-input {
  padding: 0;
}

.qr-input:focus-within {
  border-color: #716aca !important;
}

.qr-input .v-input__slot {
  margin: 0;
  padding: 0.65rem 1.25rem;
}

.qr-input input {
  margin: 0 !important;
  padding: 0 !important;
}

.qr-input .v-text-field__details {
  display: none;
}

.qr-input .v-input__slot::after {
  content: none !important;
  bottom: 0 !important;
  display: none !important;
}
</style>

<style scoped>
.overlay,
.crosshairs,
.tooltip,
.borderedBox {
  user-select: none;
}

#qrcodeImage {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 23123456789;
}

.overlay.highlighting {
  background: none;
  border-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
}

.crosshairs {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2147483645;
}

.crosshairs.hidden {
  display: none;
}

.crosshairs::before,
.crosshairs::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  border: none !important;
  border-image: none !important;
}

.crosshairs::before {
  left: -100%;
  top: -100%;
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.crosshairs::after {
  left: 0px;
  top: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.container {
  position: relative;
  clear: both;
  overflow: hidden;
  width: auto;
  height: auto;
  margin: 32px 0 0 0 !important;
  padding: 0;
}

.borderedBox {
  border: 1px solid #fff;
  position: absolute;
}

.borderedBox.hidden {
  display: none;
}

.tooltip {
  display: inline-block;
  position: absolute;

  background-color: grey;
  color: #fff;

  border-radius: 4px;

  font-size: 12px;
  font-family: monospace;

  padding: 6px;
  margin: 6px;
  white-space: nowrap;
}

.tooltip.hidden {
  display: none;
}

.Flash {
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  background-color: #fff;
  z-index: 2147483646;

  opacity: 1;

  animation-delay: 0.2s;
  animation-name: fade-out;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.screenshot-enter-active,
.screenshot-leave-active {
  transition: opacity 0.2s;
}

.screenshot-enter, .screenshot-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
