enum PixKeyType {
  CPF = '03',
  CNPJ = '03',
  PHONE = '01',
  EMAIL = '02',
  RANDOM = '04',
  BANK_DATA = '05',
}

export default PixKeyType;
