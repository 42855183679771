<template>
	<v-select
		label="Tipo de Campo"
		placeholder="Selecione o tipo de campo"
		v-model="model"
		:items="items"
		:readonly="disabled && model"
		item-text="text"
		item-value="value"
		single-line
	/>
</template>

<script>
export default {
	name: 'SharingTypeSelect',
	//  two-way data-binding
	props: {
		value: [Object, String, Number],
		disabled: Boolean,
		singleLine: Boolean,
	},
	data() {
		return {
			loading: false,
			model: this.value,
			items: [
				{ text: 'Campo do sistema', value: 'TF' },
				{ text: 'Valor Fixo', value: 'FV' },
				{ text: 'Valor Personalizado', value: 'MV' },
				{ text: 'Múltiplos Campos', value: 'CF' },
			],
		};
	},
	watch: {
		model() {
			this.$emit('input', this.model);
		},
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
	},
};
</script>

<style>

</style>
