



































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
  Ref,
} from 'vue-property-decorator';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import PaymentMethodType from '@/domain/enums/PaymentMethodType';
import { VForm } from '@/types/VForm';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';

interface Data {
  'supplier_bank': string;
  'supplier_agency': string;
  'supplier_agency_digit': string;
  'supplier_account_number': string;
  'supplier_account_digit': string;
  'supplier_account_type': string;
  'supplier_z_document': string;
  'supplier_z_name': string;
}

@Component
export default class PaymentRemittancesActionEditBankData extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Prop({
    type: Object as () => CompanyGroupConfig,
  }) groupConfig!: CompanyGroupConfig;

  @Emit()
  close(reload: boolean = false) {
    return reload;
  }

  @Watch('item', { immediate: true })
  onChangeItem() {
    if (this.item) {
      this.data = { ...this.item };
      this.isOther = this.item.form_of_payment_type === PaymentMethodType.TED_OTHER
        || this.item.form_of_payment_type === PaymentMethodType.DOC_OTHER;
    }
  }

  readonly paymentRemittanceRepository: PaymentRemittanceRepository =
    new PaymentRemittanceRepository();

  readonly required = (value: string | number) => !!value || 'Campo obrigatório!';
  readonly requiredMinDocument = (value: string) => {
    const { length } = this.justNumbers(value);
    return (length === 11 || length === 14) || 'Valor inválido!';
  };

  data: Data = {
    supplier_bank: '',
    supplier_agency: '',
    supplier_agency_digit: '',
    supplier_account_number: '',
    supplier_account_digit: '',
    supplier_account_type: '',
    supplier_z_document: '',
    supplier_z_name: '',
  };

  isOther: boolean = false;

  accountTypes: Array<ISelectOptions<string>> = [
    {
      text: 'Conta Corrente',
      value: '1',
    },
    {
      text: 'Conta Poupança',
      value: '2',
    },
  ];

  get documentMask(): string {
    let mask: string = '##################';

    if (!this.data.supplier_z_document) return mask;

    const value = this.justNumbers(this.data.supplier_z_document);

    if (value.length <= 11) {
      mask = '###.###.###-##';
    } else if (value.length >= 12) {
      mask = '##.###.###/####-##';
    }

    return mask;
  }

  justNumbers(value: string): string {
    return value.replace(/[^\d]/g, '');
  }

  save(): void {
    if (this.form.validate()) {
      this.handleUpdateData(this.data, this.item);
    }
  }

  reset(): void {
    this.data = {
      supplier_bank: '',
      supplier_agency: '',
      supplier_agency_digit: '',
      supplier_account_number: '',
      supplier_account_digit: '',
      supplier_account_type: '',
      supplier_z_document: '',
      supplier_z_name: '',
    };

    this.form.resetValidation();
  }

  async handleUpdateData(data: Data, item: PaymentRemittance): Promise<void> {
    try {
      this.$dialog.startLoading();

      const success = await this.updateTitleAndSupplier(data, item);

      if (success) {
        this.close(true);

        this.$notification.success('Dados bancários alterados com sucesso!');
      } else {
        throw new Error('Ocorreu um erro na alteração dos dados bancários!');
      }
    } catch (error: any) {
      this.$notification
        .error(error.message ?? 'Ocorreu um erro na alteração dos dados bancários!');

      this.$dialog.stopLoading();
    }
  }

  async updateTitleAndSupplier(data: Data, item: PaymentRemittance): Promise<boolean> {
    const group = this.groupConfig.id;
    const useNCC = Boolean(this.groupConfig.use_ncc) && item.type === 'NCC';

    const formatData: Record<string, string> = {
      type: useNCC ? 'customer' : 'provider',
      bank: data.supplier_bank,
      agency: data.supplier_agency,
      account_number: data.supplier_account_number,
      account_type: data.supplier_account_type,
    };

    if (this.groupConfig.use_digag) {
      formatData.agency_digit = data.supplier_agency_digit;
    }

    if (this.groupConfig.use_digcta) {
      formatData.account_digit = data.supplier_account_digit;
    }

    if (data.supplier_z_document && data.supplier_z_name) {
      formatData.general_code = this.justNumbers(data.supplier_z_document);
      formatData.provider_name = data.supplier_z_name;
    }

    const hasPayerData = Object.prototype.hasOwnProperty.call(formatData, 'general_code')
      && Object.prototype.hasOwnProperty.call(formatData, 'provider_name');

    const event = hasPayerData ? 'bank_data_with_different_holder' : 'bank_data';

    const params = {
      id: item.supplier_id_customer,
      data: formatData,
      event,
    };

    const response = await this.paymentRemittanceRepository
      .updateERP(group, item.supplier_company_id, params);

    if (response.error) throw new Error('Ocorreu um erro na alteração do dados do bancários!');

    const supplierFields = [
      {
        field: 'A2_BANCO',
        value: data.supplier_bank,
      },
      {
        field: 'A2_AGENCIA',
        value: data.supplier_agency,
      },
      {
        field: 'A2_NUMCON',
        value: data.supplier_account_number,
      },
      {
        field: 'A2_TIPCTA',
        value: data.supplier_account_type,
      },
    ];

    if (this.groupConfig.use_digag) {
      supplierFields.push({
        field: 'A2_DVAGE',
        value: data.supplier_agency_digit,
      });
    }

    if (this.groupConfig.use_digcta) {
      supplierFields.push({
        field: 'A2_DVCTA',
        value: data.supplier_account_digit,
      });
    }

    if (data.supplier_z_document && data.supplier_z_name) {
      supplierFields.push(
        {
          field: useNCC ? 'A1_ZCGC' : 'A2_ZCGC',
          value: this.justNumbers(data.supplier_z_document),
        },
        {
          field: useNCC ? 'A1_ZNOME' : 'A2_ZNOME',
          value: data.supplier_z_name,
        },
      );
    }

    await Promise.all(
      supplierFields.map((fields) => this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'fornecedor',
          id: item.supplier_id,
          ...fields,
        },
      )),
    );

    return true;
  }
}
