












































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Ref,
} from 'vue-property-decorator';

import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import PaymentRemittance from '@/domain/models/PaymentRemittance';

import PaymentMethodType from '@/domain/enums/PaymentMethodType';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';

import { CloseActionData } from '../../utils/interfaces';

import Barcode from './editBarcode.vue';
import FGTS from './editFgts.vue';
import BankData from './editBankData.vue';
import Pix from './editPix.vue';
import Others from './editOthers.vue';
import { isStatusToGenerateRemittance } from '../../utils';

@Component({
  components: {
    Barcode,
    fgts: FGTS,
    BankData,
    Pix,
    Others,
  },
})
export default class PaymentRemittancesActionEdit extends Vue {
  @Ref('barcode') barcode!: Barcode;
  @Ref('fgts') fgts!: Barcode;
  @Ref('bank-data') bankData!: BankData;
  @Ref('pix') pix!: Pix;
  @Ref('other') other!: Others;

  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Object as () => CompanyGroupConfig,
  }) groupConfig!: CompanyGroupConfig;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Emit()
  close(reload = false): CloseActionData {
    if (this.isBarcode) this.barcode.reset();
    if (this.isFGTS) this.fgts.reset();
    if (this.isBankData) this.bankData.reset();
    if (this.isPix) this.pix.reset();
    if (this.isOther) this.other.reset();

    return { type: ActionType.EDIT, reload };
  }

  get type() {
    return this.item && this.item.form_of_payment_type;
  }

  get isBarcode() {
    return this.type === PaymentMethodType.BANK_SLIP
      || this.type === PaymentMethodType.CONCESSIONAIRE
      || this.type === PaymentMethodType.TRIBUTE;
  }

  get isFGTS() {
    return this.type === PaymentMethodType.FGTS;
  }

  get isBankData() {
    return this.type === PaymentMethodType.TED
      || this.type === PaymentMethodType.DOC
      || this.type === PaymentMethodType.TED_OTHER
      || this.type === PaymentMethodType.DOC_OTHER;
  }

  get isPix() {
    return this.type === PaymentMethodType.PIX;
  }

  get isOther() {
    return this.type === PaymentMethodType.DARF
      || this.type === PaymentMethodType.GPS;
  }

  get isNone() {
    return !this.isBarcode
      && !this.isFGTS
      && !this.isBankData
      && !this.isPix
      && !this.isOther;
  }

  get canEdit(): boolean {
    if (this.item) {
      return isStatusToGenerateRemittance(this.item.status);
    }

    return false;
  }

  getTitle() {
    switch (this.type) {
      case PaymentMethodType.BANK_SLIP:
        if (this.item.form_of_payment === '008') return 'Boleto - Outra Titularidade';
        return 'Boleto';
      case PaymentMethodType.TED:
        return 'TED/Transferência';
      case PaymentMethodType.DOC:
        return 'DOC';
      case PaymentMethodType.MONEY:
        return 'Dinheiro';
      case PaymentMethodType.TED_OTHER:
        return 'TED/Transferência - Outra Titularidade';
      case PaymentMethodType.DOC_OTHER:
        return 'DOC - Outra Titularidade';
      case PaymentMethodType.PIX:
        return 'PIX';
      case PaymentMethodType.GPS:
        return 'GPS';
      case PaymentMethodType.DARF:
        return 'DARF';
      case PaymentMethodType.CONCESSIONAIRE:
        return 'Concessionária';
      case PaymentMethodType.TRIBUTE:
        return 'Tributo';
      case PaymentMethodType.FGTS:
        return 'FGTS';
      default:
        return '';
    }
  }

  save(): void {
    if (this.isBarcode) this.barcode.save();
    if (this.isFGTS) this.fgts.save();
    if (this.isBankData) this.bankData.save();
    if (this.isPix) this.pix.save();
    if (this.isOther) this.other.save();
  }
}
