<script>
import { Line, mixins } from 'vue-chartjs';
import { arrayMax } from '@/utils';

const { reactiveProp } = mixins;

export default {
  name: 'SalesChart',
  extends: Line,
  mixins: [reactiveProp],
  data() {
    return {
    };
  },
  mounted() {
    const chartValues = this.chartData.datasets[0].data;
    const max = chartValues.length > 0 ? arrayMax(chartValues) : 0;
    const step = (Math.ceil(max / 1000) * 1000) / 4;
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: '#F0F0F0',
            },
            ticks: {
              stepSize: step,
              fontColor: '#A3A3A3',
            },
          },
        ],
        yAxes: [
          {
            type: 'linear',
            ticks: {
              stepSize: step,
              fontColor: '#A3A3A3',
            },
            gridLines: {
              color: '#F0F0F0',
            },
          },
        ],
      },
    };

    this.renderChart(this.chartData, options);
  },
};
</script>

<style></style>
