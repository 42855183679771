<template>
  <page-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-col cols="12" lg="9" md="9" sm="12" xs="12"
              :class="$vuetify.breakpoint.sm ? 'text-center' : 'text-left'">
              Relatório Fluxo de Caixa Realizado
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" lg="3" md="3" sm="12" xs="12">
              <v-btn class="font-weight-bold" @click="sendData()" large block depressed color="success">
                VISUALIZAR
              </v-btn>
            </v-col>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                <inn-date-field v-model="initialDate" dense hide-details label="Data inicial" required />
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                <inn-date-field v-model="finalDate" hide-details dense label="Data final" required />
              </v-col>
              <v-col cols="12" lg="6" md="7" sm="12">
                <v-select v-model="companies" :items="companiesItems" :item-text="'name'" :item-value="'id'"
                  label="Empresa" filled block multiple chips required clearable @change="onCompanyChange" />
              </v-col>
              <v-col cols="12" lg="6" md="5" sm="12">
                <v-select v-model="banks" :items="banksItems" :item-text="(item) =>
                  '(' +
                  item.a6_cod +
                  ')' +
                  ' ' +
                  item.a6_agencia +
                  ' ' +
                  item.a6_conta +
                  ' - ' +
                  item.a6_nome" :item-value="'id_customer'" label="Banco" filled :loading="loadingBanks" block
                  multiple chips required clearable>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-container>
</template>

<script>
// eslint-disable-next-line
import CompanyService from "@/services/company.service";

export default {
  data() {
    return {
      loadingBanks: false,
      companyByGroupId: this.$session.get("company_group_id"),
      initialDate: this.$moment().format("YYYY-MM-01"),
      finalDate: this.$moment().format("YYYY-MM-DD"),
      companies: [],
      companiesItems: [],
      banks: [],
      banksItems: [],
    };
  },

  async created() {
    try {
      this.loading = true;
      await this.loadCompanies();
      await this.loadBanks();
    } catch (error) {
      this.$notification.error(error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async loadCompanies() {
      const companyService = new CompanyService();
      const response = await companyService.listAll().index();
      this.companiesItems = response.data;
    },

    async loadBanks() {
      try {
        const params = {
          companys: JSON.stringify(this.companies),
          fluxcai: "S",
          realizedreport: true,
        };
        const headers = { token: this.$session.get("token") };
        this.loadingBanks = true;
        // eslint-disable-next-line
        this.$http
          .get(this.$store.state.API_BASE + "bank/listAllByGroup", {
            headers: headers,
            params: params,
          })
          .then(
            (result) => {
              this.banksItems = result.body.data;
            },
            (error) => {
              this.$notification.error(error);
            }
          );
      } catch (error) {
        this.$notification.error(error);
      } finally {
        this.loadingBanks = false;
      }
    },

    onCompanyChange() {
      this.loadBanks();
    },

    async sendData() {
      if (this.validateForm()) {
        const data = {
          initial_date: this.initialDate,
          end_date: this.finalDate,
          companies_id: this.getCompanys(),
          bank_customer_ids: this.getBanks(),
        };
        try {
          // eslint-disable-next-line
          blockPage("Gerando arquivo");
          const url = `${this.$store.state.API_BASE}company-groups/${this.companyByGroupId}/portonave/reports/cash-flow`;
          const result = await this.$http.post(url, data, {
            headers: {
              token: this.$session.get("token"),
              responseType: "blob",
            },
          });

          this.clickToDownload(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.body.file_content}`, result.body.file_name);
        } catch (error) {
          // eslint-disable-next-line
          unblockPage();
          this.$notification.error(error.body.message);
        } finally {
          // eslint-disable-next-line
          unblockPage();
        }
      }
    },
    getBanks() {
      if (this.banks.length == 0) {
        return this.banksItems.map((obj) => obj.id_customer);
      }
      return this.banks;
    },
    getCompanys() {
      if (this.companies.length == 0) {
        const companiesId = this.companiesItems.map((obj) => obj.id);
        return companiesId;
      }
      return this.companies;
    },
    validateForm() {
      var start = new Date(this.initialDate);
      var end = new Date(this.finalDate);
      if (start > end) {
        this.$notification.error("A data final deve ser maior que a inicial");
        return false;
      }
      return true;
    },
    clickToDownload(file, filename) {
      const link = document.createElement("a");
      link.href = file;
      link.setAttribute("download", filename);
      link.click();
    },
  },
};
</script>
