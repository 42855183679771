<template>
	<v-select
		label="Grupo de Empresas"
		placeholder="Selecione o grupo de empresas"
		v-model="model"
		:items="items"
		:loading="loading"
		:readonly="disabled && model"
		item-text="description"
		item-value="id"
		:rules="rules"
		return-object
	/>
</template>

<script>
import CompanyGroupService from '@/services/company_group.service';

export default {
	name: 'CompanyGroupSelect',
	//  two-way data-binding
	props: {
		value: [Object, Array, String, Number],
		disabled: [Boolean, String, Number],
		rules: [Array, Object],
	},
	data() {
		return {
			loading: false,
			model: this.value,
			items: [],
		};
	},
	watch: {
		model() {
			this.$emit('input', this.model);
		},
		value() {
			if (this.model == this.value) return;

			this.model = this.value;
		},
	},
	created() {
		this.loadCompanyGroup();
	},
	methods: {
		async loadCompanyGroup() {
			try {
				this.loading = true;

				const companyGroupService = new CompanyGroupService();

				const response = await companyGroupService.index();

				this.items = [response.data];
			} catch (error) {
				this.$helpers.defaultCatchError(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style>

</style>
