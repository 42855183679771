































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import StatusType from '@/domain/enums/StatusType';
import tableEditButton from '@/components/buttons/tableEditButton.vue';

interface TableHeader {
  text: String,
  value: String,
  sortable?: Boolean,
  class?: String,
  align?: String,
}

@Component({
  components: {
    tableEditButton,
  },
  props: {
    items: Array,
    search: String,
    loading: Boolean,
  },
})
export default class ListTable extends Vue {
  public headers: Array<TableHeader> = [
    {
      text: 'Status',
      value: 'status',
      sortable: false,
      class: 'subtitle-2',
    },
    {
      text: 'Código',
      value: 'code',
      sortable: false,
      class: 'subtitle-2',
    },
    {
      text: 'Descrição',
      value: 'description',
      sortable: false,
      class: 'subtitle-2',
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'end',
    },
  ];

  getStatusColor(status: string): string {
    return status == StatusType.ACTIVE ? 'success' : 'default';
  }
  getStatusText(status: string): string {
    return status == StatusType.ACTIVE ? 'Ativo' : 'Inativo';
  }
  getStatusTextColor(status: string): string {
    return status == StatusType.ACTIVE ? 'white' : 'default';
  }
  handlerUpdateBillingMethod(id: number): void {
    this
      .$router
      .push({
        name: 'BillingMethodUpdate',
        params: { id: id.toString() },
      });
  }
}
