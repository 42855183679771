













import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import StatusType from '@/domain/enums/DDAConciliationStatusType';

interface StatusObject {
  code: StatusType,
  text: string,
  icon: string,
  color: string,
}

@Component({})
export default class DDAConciliationListStatus extends Vue {
  @Prop() readonly code!: StatusType;

  statusList: Array<StatusObject> = [
    {
      code: StatusType.PENDING,
      text: 'Registro DDA aguardando sincronização com ERP.',
      icon: 'mdi-history',
      color: 'orange',
    },
    {
      code: StatusType.CONCILIATED,
      text: 'Registro DDA já conciliado.',
      icon: 'mdi-check-circle-outline',
      color: 'green',
    },
    {
      code: StatusType.DIVERGENCE,
      text: 'Registro DDA com divergências no ERP.',
      icon: 'mdi-alert-circle-outline',
      color: 'orange',
    },
    {
      code: StatusType.NOT_FOUND_ERP,
      text: 'Registro DDA não encontrado no ERP.',
      icon: 'mdi-close-circle-outline',
      color: 'red',
    },
    {
      code: StatusType.ERP_WITH_BARCODE,
      text: 'Título no ERP com código de barras já informado.',
      icon: 'mdi-barcode',
      color: 'red',
    },
    {
      code: StatusType.NOT_FOUND_DDA,
      text: 'Título no ERP sem registro DDA.',
      icon: 'mdi-minus-circle-outline',
      color: 'red',
    },
    {
      code: StatusType.CANCELED,
      text: 'Registro cancelado.',
      icon: 'mdi-cancel',
      color: 'red',
    },
    {
      code: StatusType.PENDING_CONCILIATION_ERP,
      text: 'Registro aguardando conciliação no ERP.',
      icon: 'mdi-history',
      color: 'orange',
    },
    {
      code: StatusType.ERP_CONCILIATION_FAILURE,
      text: 'Conciliação do registro falhou no ERP.',
      icon: 'mdi-alert-circle-outline',
      color: 'red',
    },
    {
      code: StatusType.UNDONE,
      text: 'Registro DDA desconciliado manualmente.',
      icon: 'mdi-close-circle-outline',
      color: 'orange',
    },
    {
      code: StatusType.WITHOUT_ACCOUNT_PAYABLE,
      text: 'Sem título a pagar.',
      icon: 'mdi-minus-circle-outline',
      color: 'orange',
    },
  ];

  get status(): StatusObject {
    return this.statusList.find((item) => (item.code == this.code)) || {} as StatusObject;
  }
}
