





















































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import InputRules from '@/helpers/InputRulesHelper';
import MainGrouperOptionsHelper from '@/views/cashFlowGroupers/mainGrouper/helpers/MainGrouperOptionsHelper';
import CompanyRepository from '@/repositories/CompanyRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import MainGroupersScreensShownEnum from '@/views/cashFlowGroupers/mainGrouper/enums/MainGroupersScreensShownEnum';
import { VForm } from '@/types/VForm';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IMainGroupersListFilter from '@/views/cashFlowGroupers/mainGrouper/interfaces/IMainGroupersListFilter';
import FilterParameterMainGrouperList from '@/domain/models/filter-parameters/FilterParameterMainGrouperList';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';

export default defineComponent({
  name: 'MainGrouperFilter',
  props: {
    isLoading: Boolean,
  },
  data() {
    const inputRules: InputRules = new InputRules();
    const companyRepository: CompanyRepository = new CompanyRepository();
    const filterParametersRepository:
        FilterParametersRepository = new FilterParametersRepository();

    const search: string|null = null;

    const selectedCompanies: number[] = [];
    const selectedStatus: number[] = [];
    const selectedTables: CashFlowTablesEnum[] = [];
    const selectedScreens: MainGroupersScreensShownEnum[] = [];

    const companies: ISelectOptions<number>[] = [];
    const tableOptions:
      ISelectOptions<CashFlowTablesEnum>[] = MainGrouperOptionsHelper.getTableOptions();
    const screensOptions:
      ISelectOptions<MainGroupersScreensShownEnum>[] = MainGrouperOptionsHelper.getScreensOptions();
    const statusOptions:
      ISelectOptions<number>[] = MainGrouperOptionsHelper.getStatusOptions();

    return {
      inputRules,
      companyRepository,
      filterParametersRepository,
      search,
      selectedCompanies,
      companies,
      selectedStatus,
      selectedScreens,
      selectedTables,
      tableOptions,
      screensOptions,
      statusOptions,
    };
  },
  computed: {
    ...mapState({
      companyGroupId: (state: any) => state.authentication.user.company_group_id,
    }),
  },
  mounted() {
    Promise.all([
      this.loadCompanies(),
      this.getGroupFilterParameters(),
    ]);
  },
  methods: {
    async loadCompanies(): Promise<void> {
      const companies = await this.companyRepository.getCompaniesByLoggedUser(
        this.companyGroupId,
      );

      this.companies = companies.map(({ name, id }) => ({
        text: name,
        value: id,
      }));
    },
    async getGroupFilterParameters(): Promise<void> {
      this.loading = true;

      try {
        const filterParameters = await this.filterParametersRepository
          .getFilterByGroup(GroupFilterParametersEnum.MAIN_GROUPER_LIST);
        const formattedFilters = FilterParameterMainGrouperList.make(filterParameters);

        this.applyFiltersOnActualPage(formattedFilters);
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
      } finally {
        this.loading = false;
      }
    },
    applyFiltersOnActualPage(filters: FilterParameterMainGrouperList) {
      this.selectedTables = filters.tables;
      this.selectedScreens = filters.screensToShown;
      this.selectedStatus = filters.status;
      this.selectedCompanies = filters.companyIds;

      if (
        this.selectedTables.length > 0
        || this.selectedScreens.length > 0
        || this.selectedStatus.length > 0
        || this.selectedCompanies.length > 0
      ) {
        this.$emit('has-filter-parameters');
      }
    },
    validate(): IMainGroupersListFilter|false {
      const mainGrouperForm = this.$refs.MainGrouperForm as VForm;

      if (!mainGrouperForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');

        return false;
      }

      return {
        search: this.search,
        screensShown: this.selectedScreens,
        tables: this.selectedTables,
        status: this.selectedStatus,
        selectedCompanies: this.selectedCompanies,
      };
    },
  },
});
