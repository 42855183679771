import { formatToSpecificFormat, ptBrDateFormat } from '@/utils/date';
import CurrencyHelper from '@/helpers/CurrencyHelper';

export default class InvoiceAccountsList {
  constructor(
    public number: string,
    public prefix: string,
    public providerCode: string,
    public storeCode: string,
    public value: string,
    public emissionDate: string,
    public dueDate: string,
    public installment?: string,
  ) {}

  static make(data: any): InvoiceAccountsList {
    return new InvoiceAccountsList(
      data.number,
      data.prefix,
      data.provider_code,
      data.store_code,
      CurrencyHelper.toCurrency(data.value, { showCurrencySymbol: true }),
      data.emission_date ? formatToSpecificFormat(data.emission_date, ptBrDateFormat) : '',
      data.due_date ? formatToSpecificFormat(data.due_date, ptBrDateFormat) : '',
      data.installment,
    );
  }
}
