export default class DownloadBlobWithFile {
  constructor(
    public blob: Blob,
    public fileName: string,
  ) {}

  static make(blob: Blob, headers: any): DownloadBlobWithFile {
    const contentDisposition = headers['content-disposition'];
    const filenameMatch = contentDisposition.match(/filename=(.+)/)[1].replace(/^"|"$/g, '');

    return new DownloadBlobWithFile(
        blob,
        filenameMatch,
    );
  }
}
