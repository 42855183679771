




































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import BillingPendency from '@/domain/models/BillingPendency';

@Component({})
export default class BillingPendencyListMenu extends Vue {
  @Prop({
    type: Object as () => BillingPendency,
    required: false,
    default: undefined,
  }) public item?: BillingPendency;

  @Emit('click')
  public action(type: string): { type: string, item?: BillingPendency } {
    return { type, item: this.item };
  }

  public get isRow(): boolean {
    return !!this.item;
  }
}
