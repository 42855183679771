import { dateNow, getIncreasedDate } from '@/utils/date';
import { DurationObjectUnits } from 'luxon';
import IInstallmentConfigs from '@/views/preNegotiation/interfaces/IInstallmentConfigs';
import IInstallmentTableItem from '@/views/preNegotiation/interfaces/IInstallmentTableItem';
import IInstallmentChange from '@/views/preNegotiation/interfaces/IInstallmentChange';
import IPreNegotiationFees from '../interfaces/IPreNegotiationFees';

export default class PreNegotiationHelper {
  public static getAvailableAccounts(
    accountsReceivablesIdsCustomer: string[],
    excludedAccounts: string[],
  ): string[] {
    const accountsToNotConsider: string[] = [
      ...excludedAccounts,
    ];

    return accountsReceivablesIdsCustomer
      .filter((idCustomer) => !accountsToNotConsider.includes(idCustomer));
  }

  public static getInstallmentsFromSpecificValue(
    totalValue: number,
    installmentConfigs: IInstallmentConfigs,
  ): IInstallmentTableItem[] {
    const dividedTotal = (totalValue / installmentConfigs.quantity!);

    const instalmentTableItems: IInstallmentTableItem[] = [];

    let actualInstallmentDate = dateNow();

    for (let i = 0; i < installmentConfigs.quantity!; i += 1) {
      const interval = PreNegotiationHelper.getInstallmentIntervalBasedOnPeriodicity(
        installmentConfigs.periodicity!,
        installmentConfigs.customPeriodicity,
      );

      if (!interval) {
        return [];
      }

      instalmentTableItems.push({
        installment: i + 1,
        dueDate: actualInstallmentDate,
        financialValue: dividedTotal,
        financialDecrease: 0,
        financialAddition: 0,
        financialTotalValue: dividedTotal,
      });
      actualInstallmentDate = getIncreasedDate(actualInstallmentDate, interval);
    }

    return instalmentTableItems;
  }

  private static getInstallmentIntervalBasedOnPeriodicity(
    periodicity: string,
    customPeriodicity: number|null,
  ): DurationObjectUnits|false {
    switch (periodicity) {
      case 'daily':
        return { days: 1 };
      case 'weekly':
        return { weeks: 1 };
      case 'fortnightly':
        return { days: 15 };
      case 'monthly':
        return { months: 1 };
      case 'custom':
        return { days: customPeriodicity! };
      default:
        return false;
    }
  }
}
