import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"page-title":"Notícias"}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,{attrs:{"align":"center","justify":"space-between"}},[_c(VCol,{attrs:{"xs":"12","sm":"7","md":"6","cols":"12"}},[_c('inn-text-field-debounce',{staticClass:"search-field",attrs:{"label":"Digite para buscar pelo usuário","icon":"mdi-magnify","hide-details":"","dense":""},on:{"on-finished-debounce":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"xs":"12","sm":"5","md":"3","cols":"12"}},[_c(VBtn,{attrs:{"block":"","large":"","color":"info"},on:{"click":_vm.handleCreate}},[_vm._v(" ADICIONAR ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.newsList,"loading":_vm.loading,"server-items-length":_vm.totalItems,"options":_vm.dataTableOptions,"footer-props":_vm.footerOptions,"item-class":function () { return 'cursor-pointer'; },"loading-text":"Carregando Notícias...","no-data-text":"Nenhuma notícia encontrada."},on:{"update:options":function($event){_vm.dataTableOptions=$event},"click:row":_vm.goToSingularNews},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status,"list":_vm.availableStatus}})]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDateToBrFormat(item.startDate))+" ")]}},{key:"item.finalDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDateToBrFormat(item.finalDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-delete","tooltip-text":"Remover notícia"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveNewsClick(item)}}})]}}])})]},proxy:true}])}),(_vm.showRemoveNewsConfirmationDialog)?_c('confirm-delete-news-dialog',{attrs:{"news-id":_vm.selectedNewsToRemove},on:{"on-close":_vm.handleCloseConfirmDeleteNewsDialog},model:{value:(_vm.showRemoveNewsConfirmationDialog),callback:function ($$v) {_vm.showRemoveNewsConfirmationDialog=$$v},expression:"showRemoveNewsConfirmationDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }