<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <vs-select
      class="vs-custom-select"
      :class="{'vs-not-multiple': !multiple}"
      :id="id"
      :multiple="multiple"
      :searchable="multiple"
      :clearable="false"
      :value="value"
      :options="options"
      :placeholder="placeholder"
      @search="searchFn"
      @input="emit($event)"
      autoscroll
    >
      <template slot="no-options">Nenhuma opção encontrada</template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <v-icon>mdi-chevron-down</v-icon>
        </span>
      </template>
    </vs-select>
  </div>
</template>
<script>
import vSelect from 'vue-select';

export default {
  name: 'CustomSelect',
  components: {
      'vs-select': vSelect,
  },
  props: {
    label: String,
    placeholder: String,
    id: String,
    value: [Array, Object],
    options: Array,
    search: Function,
    multiple: Boolean,
  },
  methods: {
    searchFn(search, loading) {
      if (typeof this.search === 'function') return this.search(search, loading);

      return null;
    },
    emit(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style>
.vs-custom-select {
  position: relative;
  display: block;
}
.vs-custom-select .vs__dropdown-toggle {
  display: flex;
  border: 1px solid #ebedf2;
  cursor: pointer;
}

.vs-custom-select .vs__dropdown-toggle .vs__selected-options,
.vs__open-indicator i {
  flex: 1 1 auto;
  padding: 0.55rem;
}
.vs-custom-select .vs__dropdown-toggle .vs__selected-options input {
  width: 100%;
}
.vs__open-indicator i {
  padding: 0.55rem 10px;
}
.vs-custom-select .vs__search {
  flex: 1 1 auto;
}
.vs--open .vs__open-indicator i {
  transform: rotate(180deg) scale(1);
}
.vs-custom-select .vs__search,
.vs-custom-select .vs__search:active,
.vs-custom-select .vs__search:focus {
  border: none;
  outline: none;
}
.vs-custom-select .vs__dropdown-menu {
  border: 1px solid #ebedf2;
  -webkit-box-shadow: 0 0 15px 1px rgb(113 106 202 / 20%);
  -moz-box-shadow: 0 0 15px 1px rgba(113, 106, 202, 0.2);
  box-shadow: 0 0 15px 1px rgb(113 106 202 / 20%);
  border-radius: 4px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: 450px;
  overflow-y: scroll;
  position: absolute;
  background: #fff;
  width: 100%;
  display: block;
  z-index: 1600;
}
.vs-custom-select .vs__dropdown-menu ul {
  padding: 0 !important;
}
.vs-custom-select .vs__dropdown-menu li {
  padding: 13px 15px;
}
.vs-custom-select .vs__dropdown-menu li:hover {
  background: #f4f5f8;
  color: #3f4047;
}
.vs-custom-select .vs__selected {
  color: #575962;
  background: #ebedf2;
  border: 1px solid #ebedf2;
  padding: 0.05rem 0.4rem 0.05rem 0.4rem;
  font-size: 1rem;
  margin: 0.1rem 0.4rem 0.1rem 0;
  float: left;
  border-radius: 4px;
}
.vs-custom-select .vs__selected button {
  float: left;
  margin-right: 4px;
}
.vs-custom-select .vs__selected button svg {
  fill: #575962;
}
.vs-custom-select.vs-not-multiple .vs__search {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: -1px !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
}
.vs-custom-select.vs-not-multiple .vs__selected {
  background: none;
  border: none;
}
</style>
