





























































import { defineComponent } from '@vue/composition-api';
import ActionType from '@/domain/enums/DDAConciliationActionType';
import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';
import DDAConciliation from '@/domain/models/DDAConciliation';

export default defineComponent({
  props: {
    open: {
      required: true,
      type: Boolean,
    },
    item: {
      required: true,
      type: Object as () => DDAConciliation,
    },
  },
  data() {
    const ddaConciliationRepository: DDAConciliationRepository = new DDAConciliationRepository();
    const loading = false;

    return {
      ddaConciliationRepository,
      loading,
    };
  },
  methods: {
    close() {
      this.$emit('close', ActionType.REMOVE_DDA);
    },
    async handleDelete(): Promise<void> {
      try {
        this.loading = true;

        await this.ddaConciliationRepository.removeDDA(
          this.$store.state.authentication.user.company_group_id,
          Number(this.item.movements[0].id),
        );

        this.$notification.success('Registro excluido com sucesso');
      } catch (error) {
        this.$notification.error('Houve um problema ao excluir o DDA');
      } finally {
        this.loading = false;
        this.close();
        this.$emit('reload', ActionType.REMOVE_DDA);
      }
    },
  },
});
