
































































import { defineComponent } from '@vue/composition-api';
import ERPRepository from '@/repositories/ERPRepository';
import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';
import DialogHelper from '@/helpers/DialogHelper';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import PredictedFlowHelper from '@/views/cashFlow/predictedFlow/helpers/PredictedFlowHelper';
import { VForm } from '@/types/VForm';
import PredictedFlowActionsEnum from '@/views/cashFlow/predictedFlow/enums/PredictedFlowActionsEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';
import IPredictedFlowGrouperItemData from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperItemData';

export default defineComponent({
  name: 'ChangeReceiveFormDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    register: {
      required: false,
      type: Object as () => IPredictedFlowGrouperList,
    },
  },
  computed: {
    dialogWidth(): string {
      return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
        smAndDown: '100%',
        md: '60%',
        default: '40%',
      });
    },
    itemData(): IPredictedFlowGrouperItemData {
      return this.register!.itemData!;
    },
    grouperType(): CashFlowTablesEnum {
      return this.register!.grouperType;
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const inputRules: InputRulesHelper = new InputRulesHelper();
    const erpRepository: ERPRepository = new ERPRepository();
    const billingRemittanceRepository:
      BillingRemittanceRepository = new BillingRemittanceRepository();

    const receiveForm: string = '';

    const loading: boolean = false;
    const isOpen: boolean = this.value;

    const receiveFormOptions: ISelectOptions[] = [];

    return {
      inputRules,
      erpRepository,
      billingRemittanceRepository,
      receiveForm,
      loading,
      isOpen,
      receiveFormOptions,
    };
  },
  created() {
    this.getReceiveFormsList();
  },
  methods: {
    async getReceiveFormsList(): Promise<void> {
      try {
        this.loading = true;

        const receiveForms = await this.billingRemittanceRepository.getMethods(this.companyGroupId);

        this.receiveFormOptions = receiveForms;
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar as formas de recebimento.');
      } finally {
        this.loading = false;
      }
    },
    async changeReceiveForm(): Promise<void> {
      try {
        this.loading = true;

        const response = await Promise.allSettled([
          this.updateInternalCustomerERP(),
        ]);

        if (!response.every(({ status }) => status === 'fulfilled')) {
          throw new Error();
        }

        this.$notification.success('Forma de Recebimento alterado com sucesso!');
      } catch (error) {
        this.$notification.error('Houve um problema ao alterar a forma de recebimento.');
      } finally {
        this.loading = false;
      }
    },
    async updateInternalCustomerERP(): Promise<void> {
      const fieldBasedOnTable = this.grouperType === CashFlowTablesEnum.ACCOUNTS_RECEIVABLE ? 'e1_zforrc' : 'e2_zforrc';

      await this.erpRepository.updateInternalERP({
        id: this.itemData.id!,
        field: fieldBasedOnTable,
        identify_values: PredictedFlowHelper.getTableAliasByGrouperType(this.grouperType),
        value: this.receiveForm,
      });
    },
    close(): void {
      this.$emit('close', PredictedFlowActionsEnum.change_receive_form);
    },
    handleChangeReceiveForm(): void {
      const changeReceiveForm = this.$refs.ChangeReceiveForm as VForm;

      if (!changeReceiveForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');
        return;
      }

      this.changeReceiveForm();
    },
  },
});
