import Vue from 'vue';
import Vuex from 'vuex';
import StorePlugins from '@/stores/plugins/StorePlugins';
import PredictedFlowModule from '@/stores/modules/PredictedFlowModule';
import { API_BASE } from '../../config/api';

Vue.use(Vuex);

const vuexInstance = new Vuex.Store({
  plugins: StorePlugins,
  state: { API_BASE },
});

vuexInstance.registerModule('predicted_flow', PredictedFlowModule);

export default vuexInstance;
