import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import { formatToSpecificFormat } from '@/utils/date';
import StatusEnum from '../enums/StatusEnum';

export default class Client {
    constructor(
        public id: string,
        public companyName: string,
        public tradeName: string,
        public cnpj: string,
        public address: string,
        public complement: string,
        public city: string,
        public neighbourhood: string,
        public state: string,
        public phone: string,
        public ddd: string|undefined,
        public invoiceMail: string,
        public financialMail1: string,
        // public financialMail2: string,
        public financialName1: string,
        public financialName2: string,
        public sector: string,
        public district: string,
        public group: string,
        public subGroup: string,
        public deadline: string,
        public limit: number|string|null,
        public balanceDue: string|null|undefined,
        public risk: string,
        public clientClass: string,
        public firstPurchase: string,
        public lastPurchase: string,
        public notes: string,
        public companyId: number | null,
        public completeBankName: string | null,
        public deadlineForBlocking: string | null,
        public creditBlockingSituation: 1|2|3|4|null,
        public store: string | null,
        public stateRegistration: string | null,
        public situation: StatusEnum,
    ) {}

    static make(data: any, withCurrencySymbol: boolean = false): Client {
        const formattedFirstPurchase = data.firstPurchase ? formatToSpecificFormat(data.firstPurchase, 'dd/MM/yyyy') : '';
        const formattedLastPurchase = data.lastPurchase ? formatToSpecificFormat(data.lastPurchase, 'dd/MM/yyyy') : '';

        let completeBankName = '';

        if (data.bank && data.bank_name) {
            completeBankName = `${data.bank} - ${data.bank_name}`;
        }

        return new Client(
            data.id_customer,
            data.name,
            data.trade_name,
            DocumentFormattation.chooseTheValidDocumentFormattation(data.document),
            data.address,
            data.complement,
            data.city,
            data.neighbourhood,
            data.state,
            data.phone?.replaceAll('-', '') ?? '',
            data.ddd,
            data.invoiceMail,
            data.financialMail1,
            // data.financialMail2,
            data.financialName1,
            data.financialName2,
            data.sector,
            data.district,
            data.group,
            data.subGroup,
            data.deadline,
            data.limit,
            data.balanceDue,
            data.risk,
            data.clientClass,
            formattedFirstPurchase,
            formattedLastPurchase,
            data.notes,
            data.companyId,
            completeBankName,
            data.deadlineForBlocking,
            data.creditBlockingSituation,
            data.store,
            data.stateRegistration,
            data.situation,
        );
    }
}
