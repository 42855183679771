import { formatToSpecificFormat, ptBrDateFormat } from '@/utils/date';
import { toCurrency } from '@/utils';

export default class InvoiceList {
  constructor(
    public id: string,
    public invoiceNumber: number,
    public issueDate: string,
    public dueDate: string,
    public amount: string,
  ) {}

  static make(data: any): InvoiceList {
    return new InvoiceList(
      data.id,
      data.invoice_number,
      formatToSpecificFormat(data.issue_date, ptBrDateFormat),
      formatToSpecificFormat(data.due_date, ptBrDateFormat),
      toCurrency(data.amount, {}, true),
    );
  }
}
