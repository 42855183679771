

















































































































































































































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { validateDateRange } from '@/utils/date';
import { formatErrorForNotification } from '@/utils/error';

import IFilter from '@/domain/interfaces/IInstructionsRemittanceFilter';
import IOption from '@/domain/interfaces/ISelectOptions';

import EStatus from '@/domain/enums/InstructionRemittanceStatus';
import EGroupFilterParameters from '@/domain/enums/GroupFilterParametersEnum';

import InstructionsRemittanceRepository from '@/repositories/InstructionsRemittanceRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';

import FilterParameterInstructionsRemittanceList from '@/domain/models/filter-parameters/FilterParameterInstructionsRemittanceList';

import {
  EInstructionRemittanceType as EType,
  EInstructionRemittanceTypeTranslate as ETypeTranslate,
} from '@/domain/enums/InstructionRemittanceType';

interface DateRangeError {
  emission: boolean,
  due: boolean,
  return: boolean,
  messageInitial: string,
  messageEnd: string,
}

@Component
export default class InstructionsRemittanceFilter extends Vue {
  @Ref('filter') readonly filter!: VForm;

  readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();

  readonly instructionsRemittanceRepository
    : InstructionsRemittanceRepository = new InstructionsRemittanceRepository();

  data: IFilter = {
    initial_issue_date: '',
    end_issue_date: '',
    initial_due_date: '',
    end_due_date: '',
    company_id: [],
    bank_code: '',
    wallet_type: '',
    payment_method: '',
    occurrence_return: [],
    type: [],
    status: [],
    number: '',
    customer: [],
  };

  errorDateRange: DateRangeError = {
    emission: false,
    due: false,
    return: false,
    messageInitial: 'Data inicial deve ser menor ou igual a data final.',
    messageEnd: 'Data final deve ser menor ou igual a data inicial.',
  };

  status: Array<IOption<EStatus>> = [
    {
      text: 'Pendente',
      value: EStatus.PENDING,
    },
    {
      text: 'Enviada',
      value: EStatus.SENT,
    },
    {
      text: 'Confirmada',
      value: EStatus.APPROVED,
    },
    {
      text: 'Rejeitada',
      value: EStatus.REJECTED,
    },
  ];

  types: Array<IOption<EType>> = [
    {
      text: ETypeTranslate.UPDATE,
      value: EType.UPDATE,
    },
    {
      text: ETypeTranslate.DELETE,
      value: EType.DELETE,
    },
  ];

  allCompanies: Array<number> = [];

  companies: Array<IOption<number>> = [];
  banks: Array<IOption<string>> = [];
  wallets: Array<IOption<string>> = [];
  methods: Array<IOption<string>> = [];
  occurrences: Array<IOption<string>> = [];
  customers: Array<IOption<string>> = [];

  loadingOccurrences: boolean = false;
  searchOccurrences: string = '';
  timerOccurrences!: ReturnType<typeof setTimeout>;
  setOccurrences = new Set<string>();

  loadingCustomers: boolean = false;
  searchCustomers: string = '';
  timerCustomers!: ReturnType<typeof setTimeout>;
  setCustomers = new Set<string>();

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  get occurrencesNoDataText(): string {
    if (!this.searchOccurrences) return 'Digite para buscar ocorrências.';

    return !this.loadingOccurrences
      ? 'Nenhum resultado encontrado.'
      : 'Carregando...';
  }

  get customersNoDataText(): string {
    if (!this.searchCustomers) return 'Digite para buscar clientes.';

    return !this.loadingCustomers
      ? 'Nenhum resultado encontrado.'
      : 'Carregando...';
  }

  readonly required = (value: string) => !!value || 'Campo obrigatório!';

  mounted() {
    this.loadOptions();
  }

  validateAllDateRanges(): boolean {
    const {
      initial_issue_date: emissionInitial,
      end_issue_date: emissionEnd,
      initial_due_date: dueInitial,
      end_due_date: dueEnd,
    } = this.data;

    const emissionDateIsValid = validateDateRange(emissionInitial, emissionEnd);
    const dueDateIsValid = validateDateRange(dueInitial, dueEnd);

    if (!emissionDateIsValid) {
      this.errorDateRange.emission = true;
      this.$notification.error('Intevalo de emissão inválido!');
    } else {
      this.errorDateRange.emission = false;
    }

    if (!dueDateIsValid) {
      this.errorDateRange.due = true;
      this.$notification.error('Intevalo de vencimento inválido!');
    } else {
      this.errorDateRange.due = false;
    }

    if (!emissionDateIsValid && !dueDateIsValid) {
      this.$notification.error('Intevalos inválidos!');
    }

    return emissionDateIsValid && dueDateIsValid;
  }

  validate(): { valid: boolean, data: IFilter } {
    const isValidDateRange = this.validateAllDateRanges();
    const isValidFilter = this.filter.validate();

    if (isValidDateRange && isValidFilter) {
      const relationship = 'instructions_remittance_list';

      this.filterParametersRepository.setFilter(EGroupFilterParameters.INSTRUCTIONS_REMITTANCE, [
        { key: `initial_issue_date_${relationship}`, value: this.data.initial_issue_date },
        { key: `end_issue_date_${relationship}`, value: this.data.end_issue_date },
        { key: `initial_due_date_${relationship}`, value: this.data.initial_due_date },
        { key: `end_due_date_${relationship}`, value: this.data.end_due_date },
        { key: `company_id_${relationship}`, value: JSON.stringify(this.data.company_id) },
        { key: `bank_code_${relationship}`, value: this.data.bank_code },
        { key: `wallet_type_${relationship}`, value: this.data.wallet_type },
        { key: `payment_method_${relationship}`, value: this.data.payment_method },
        { key: `occurrence_return_${relationship}`, value: JSON.stringify(this.data.occurrence_return) },
        { key: `type_${relationship}`, value: JSON.stringify(this.data.type) },
        { key: `status_${relationship}`, value: JSON.stringify(this.data.status) },
        { key: `number_${relationship}`, value: this.data.number },
        { key: `customer_${relationship}`, value: JSON.stringify(this.data.customer) },
      ]);

      return {
        valid: true,
        data: {
          ...this.data,
          company_id: this.data.company_id.length
            ? this.data.company_id
            : this.allCompanies,
        },
      };
    }

    return {
      valid: false,
      data: {} as IFilter,
    };
  }

  onChangeCompany(value: Array<number> | null) {
    this.setOccurrences.clear();
    this.occurrences = [];
    this.data.occurrence_return = [];
    if (value && value.length) this.handleLoadOccurrences(value, ' ');
  }

  onChangeSearchOccurrences(search: string) {
    if (search && search.length) {
      this.handleLoadOccurrencesDebounce(search);
    } else {
      clearTimeout(this.timerOccurrences);
    }
  }

  handleLoadOccurrencesDebounce(search: string): void {
    clearTimeout(this.timerOccurrences);
    const { company_id: company } = this.data;
    this.timerOccurrences = setTimeout(() => {
      this.handleLoadOccurrences(company.length ? company : this.allCompanies, search);
    }, 500);
  }

  onChangeSearchCustomers(search: string) {
    if (search && search.length) {
      this.handleLoadCustomersDebounce(search);
    } else {
      clearTimeout(this.timerCustomers);
    }
  }

  handleLoadCustomersDebounce(search: string): void {
    clearTimeout(this.timerOccurrences);
    const { company_id: company } = this.data;
    this.timerCustomers = setTimeout(() => {
      this.handleLoadCustomers(company.length ? company : this.allCompanies, search);
    }, 500);
  }

  async loadOptions(): Promise<void> {
    try {
      this.$dialog.startLoading();

      await this.loadCompanies();
      await this.loadBanks();
      await this.loadWallets();
      await this.loadMethods();
      await this.loadFilterParameters();

      const companies = this.data.company_id.length
        ? this.data.company_id
        : this.allCompanies;

      if (this.data.occurrence_return.length) {
        await Promise.all(
          this.data.occurrence_return
            .map((occurrence) => this.loadOccurrences(
              companies,
              occurrence,
            )),
        );
      }

      if (this.data.customer.length) {
        await Promise.all(
          this.data.customer
            .map((customer) => this.loadCustomers(
              companies,
              customer,
            )),
        );
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  async loadFilterParameters(): Promise<void> {
    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(EGroupFilterParameters.INSTRUCTIONS_REMITTANCE);

      const params = FilterParameterInstructionsRemittanceList
        .make(filterParameters);

      this.data = { ...params };
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    }
  }

  async loadCompanies(): Promise<void> {
    this.companies = await this.instructionsRemittanceRepository
      .getCompanies();

    this.allCompanies = this.companies.map((company) => company.value);
  }

  async loadBanks(): Promise<void> {
    this.banks = await this.instructionsRemittanceRepository
      .getBanks(this.groupId);
  }

  async loadWallets(): Promise<void> {
    this.wallets = await this.instructionsRemittanceRepository
      .getWallets(this.groupId);
  }

  async loadMethods(): Promise<void> {
    this.methods = await this.instructionsRemittanceRepository
      .getMethods(this.groupId);
  }

  async loadOccurrences(company: Array<number>, search: string): Promise<void> {
    const occurrences = await this.instructionsRemittanceRepository
      .getOccurrences(this.groupId, company, search);

    const concat = [...this.occurrences, ...occurrences];

    this.occurrences.push(...concat.filter((occurrence) => {
      const duplicated = this.setOccurrences.has(occurrence.value);
      this.setOccurrences.add(occurrence.value);
      return !duplicated;
    }));
  }

  async loadCustomers(company: Array<number>, search: string): Promise<void> {
    const customers = await this.instructionsRemittanceRepository
      .getCustomers(this.groupId, company, search);

    const concat = [...this.customers, ...customers];

    this.customers.push(...concat.filter((customer) => {
      const duplicated = this.setCustomers.has(customer.value);
      this.setCustomers.add(customer.value);
      return !duplicated;
    }));
  }

  async handleLoadOccurrences(company: Array<number>, search: string): Promise<void> {
    try {
      this.loadingOccurrences = true;

      await this.loadOccurrences(company, search);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingOccurrences = false;
    }
  }

  async handleLoadCustomers(company: Array<number>, search: string): Promise<void> {
    try {
      this.loadingCustomers = true;

      await this.loadCustomers(company, search);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingCustomers = false;
    }
  }
}
