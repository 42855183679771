export default class BillingHistorySupport {
  public static getColorByStatus(status: string): string {
    switch (status) {
      case 'success':
        return 'green';
      case 'failure':
        return 'red';
      default:
        return 'blue';
    }
  }
}
