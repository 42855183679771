







































import {
  Component,
  Emit,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import { getModule } from 'vuex-module-decorators';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import DialogHelper from '@/helpers/DialogHelper';
import OperatorDistributionRepository from '@/repositories/OperatorDistributionRepository';
import OperatorDistributionList from '@/domain/models/OperatorDistributionList';

@Component
export default class OperartorDistributionDeleteDialog
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  @Prop({
    type: Object as () => OperatorDistributionList,
    required: true,
    default: {} as OperatorDistributionList,
  }) distribution!: OperatorDistributionList;

  @Emit('close')
  close(reload: boolean = false) {
    this.model = false;
    this.loading = false;

    return reload;
  }

  public readonly operatorDistributionRepository
    : OperatorDistributionRepository = new OperatorDistributionRepository();
  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);

  public loading: boolean = false;

  public get name(): string {
    return this.distribution.operatorName;
  }

  public get maxWidth(): string {
    return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
      xs: '100%',
      smAndDown: '80%',
      mdAndDown: '60%',
      default: '40%',
    });
  }

  public get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  public save(): void {
    this.deleteOperatorDistribution(this.distribution.id);
  }

  public async deleteOperatorDistribution(id: number): Promise<void> {
    try {
      this.loading = true;

      await this.operatorDistributionRepository.destroyOperatorDistribution(
        this.companyGroupId,
        this.companyIds,
        id,
      );

      this.$notification.success('Distribuição de operador excluida com sucesso!');
      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
