export default class CompanyGroupConfig {
  constructor(
    public billing_approval: string,
    public company_group_dictionary: Array<unknown>,
    public created_at: string,
    public description: string,
    public digag: any,
    public digcta: any,
    public erp_company_group_code: string,
    public id: number,
    public inncash_version: number,
    public invoice_generator: string,
    public name_table: string,
    public prefix_table: string,
    public remittance_limit: number,
    public status: number,
    public updated_at: string,
    public url_image: string,
    public use_digag: number,
    public use_digcta: number,
    public use_ncc: number,
    public use_rest: number,
    public visible_filter_branch_erp: number,
    public visible_filter_business_unit_erp: number,
    public visible_filter_company_erp: number,
    public visible_filter_company_group_erp: number,
  ) { }

  static make(data: any): CompanyGroupConfig {
    return new CompanyGroupConfig(
      data.billing_approval,
      data.company_group_dictionary,
      data.created_at,
      data.description,
      data.digag,
      data.digcta,
      data.erp_company_group_code,
      data.id,
      data.inncash_version,
      data.invoice_generator,
      data.name_table,
      data.prefix_table,
      data.remittance_limit,
      data.status,
      data.updated_at,
      data.url_image,
      data.use_digag,
      data.use_digcta,
      data.use_ncc,
      data.use_rest,
      data.visible_filter_branch_erp,
      data.visible_filter_business_unit_erp,
      data.visible_filter_company_erp,
      data.visible_filter_company_group_erp,
    );
  }
}
