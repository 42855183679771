import Rest from './base/Rest';

export default class ConciliationFilesImport extends Rest {
	static resource = 'company-groups';

	list(groupCompanyId) {
		this.relationship = `${groupCompanyId}/conciliations/files`;
		return this;
	}
}
