import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 flex-column"},[_c(VDataTable,{directives:[{name:"show",rawName:"v-show",value:(!_vm.reloading),expression:"!reloading"}],attrs:{"items":_vm.details,"headers":_vm.headers,"hide-default-footer":"","items-per-page":-1,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.fixed_value",fn:function(ref){
var item = ref.item;
return [_c('value-type-select',{attrs:{"single-line":""},model:{value:(item.value_type),callback:function ($$v) {_vm.$set(item, "value_type", $$v)},expression:"item.value_type"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-checkbox',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.data_field",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{staticClass:"mb-n5 mt-1",attrs:{"outlined":"","dense":""},model:{value:(item.data_field),callback:function ($$v) {_vm.$set(item, "data_field", $$v)},expression:"item.data_field"}})]}}])}),_c(VDivider,{staticClass:"flex-item mt-0"}),_c('fields-table-add',{attrs:{"banking-fields":_vm.bankingFields,"banking-field-details":_vm.details},on:{"add":_vm.insertFieldDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }