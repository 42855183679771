import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';

export default class PredictedFlowHelper {
  public static getTableAliasByGrouperType(grouperType: CashFlowTablesEnum): string {
    switch (grouperType) {
        case CashFlowTablesEnum.ACCOUNTS_PAYABLE: return 'CP';
        case CashFlowTablesEnum.ACCOUNTS_RECEIVABLE: return 'CR';
        case CashFlowTablesEnum.BANKS: return 'BC';
        default: return '';
    }
  }

  public static getTableBasedOnNcc(
    register: IPredictedFlowGrouperList,
    useNcc: boolean,
  ): string {
    if (
      register.grouperType === CashFlowTablesEnum.ACCOUNTS_PAYABLE
      && useNcc
      && register.itemData?.type === 'NCC'
    ) {
      return 'account_receivable';
    }

    return register.grouperType === CashFlowTablesEnum.ACCOUNTS_PAYABLE ? 'account_payable' : 'account_receivable';
  }
}
