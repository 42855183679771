





















import { defineComponent } from '@vue/composition-api';
import { DataOptions } from 'vuetify';
import DataTableHelper from '@/helpers/DataTableHelper';
import IDataTableOptions from '@/helpers/interfaces/IDataTableOptions';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import IButtonOption from '@/plugins/inn-design/components/buttons/interfaces/IButtonOption';
import InvoiceListActionsEnum from '@/views/invoices/enums/InvoiceListActionsEnum';
import InvoiceList from '@/domain/models/InvoiceList';

export default defineComponent({
  props: {
    invoices: Array,
    totalItems: Number,
    isLoading: Boolean,
  },
  watch: {
    dataTableOptions() {
      this.$emit('data-table-changes');
    },
  },
  data() {
    const headers: IVDataTableHeader[] = [
      {
        text: 'Número da fatura',
        value: 'invoiceNumber',
      },
      {
        text: 'Data de Emissão',
        value: 'issueDate',
      },
      {
        text: 'Data de Vencimento',
        value: 'dueDate',
      },
      {
        text: 'Valor',
        value: 'amount',
      },
      {
        text: '',
        width: '5%',
        value: 'actions',
        sortable: false,
      },
    ];
    const actionOptions: IButtonOption[] = [
      { text: 'Listar títulos', value: 'show_accounts' },
    ];

    const dataTableOptions: DataOptions = DataTableHelper.getDefaultDataTableOptions();
    const footerOptions: DataTableFooterOptions = DataTableHelper.getDefaultFooterProps();

    return {
      headers,
      actionOptions,
      dataTableOptions,
      footerOptions,
    };
  },
  methods: {
    getDataTableOptions(): IDataTableOptions {
      const { sortDesc, sortBy } = this.dataTableOptions;

      return {
        page: this.dataTableOptions.page,
        itemsPerPage: this.dataTableOptions.itemsPerPage,
        formattedSort: DataTableHelper.formatTableOrdenationColumn(sortDesc[0], sortBy[0]),
      };
    },
    handleClickOption(event: InvoiceListActionsEnum, item: InvoiceList): void {
      this.$emit('action', {
        selectedInvoiceId: item.id,
        event,
      });
    },
  },
});
