






























































































import {
  Vue,
  Ref,
  Component,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import AuthenticationRepository from '@/repositories/AuthenticationRepository';
import PasswordManagementRepository from '@/repositories/PasswordManagementRepository';
import LoginHelper from '@/views/login/helpers/LoginHelper';
import UserRepository from '@/repositories/UserRepository';
import InputRules from '@/helpers/InputRulesHelper';
import { VForm } from '@/types/VForm';

@Component
export default class ResetPassword extends Vue {
  @Ref('ResetPasswordForm') readonly resetPasswordForm!: VForm;

  authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  authenticationRepository: AuthenticationRepository = new AuthenticationRepository();
  passwordManagementRepository: PasswordManagementRepository = new PasswordManagementRepository();
  userRepository: UserRepository = new UserRepository();

  inputRules: InputRules = new InputRules();

  loading: boolean = false;

  password: string = '';
  repeatPassword: string = '';

  checkResetPasswordMode: 'token' | 'manual' = 'token';

  created(): void {
    this.checkResetPasswordMode = this.$route.params.token ? 'token' : 'manual';

    if (this.checkResetPasswordMode === 'token') {
      this.validateToken();
    }

    if (this.checkResetPasswordMode === 'manual' && !this.authenticationModule.userMailToRedefinePassword) {
      this.$notification.error('Não existe nenhum dado de sessão definido para essa tela.');
      this.$router.push({ name: 'login' });
    }
  }

  async validateToken(): Promise<void> {
    try {
      this.loading = true;

      await this.passwordManagementRepository
        .validateToken(this.$route.params.token);
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao validar o token');
      this.$notification.error(errorMessage);
      this.$router.push({ name: 'login' });
    } finally {
      this.loading = false;
    }
  }

  async resetPassword(): Promise<void> {
    let itWasSuccess = false;

    try {
      this.loading = true;

      await this.userRepository.resetPassword(
        this.password,
        this.$route.params.token,
        this.authenticationModule.userMailToRedefinePassword,
      );

      itWasSuccess = true;

      this.$notification.success('Senha alterada com sucesso.');
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao redefinir a senha.');

      this.$notification.error(errorMessage);
    } finally {
      if (this.checkResetPasswordMode === 'token') {
        this.$router.push({ name: 'login' });
      }

      if (itWasSuccess && this.checkResetPasswordMode === 'manual') {
        this.reAuthenticateUser(this.authenticationModule.userMailToRedefinePassword);
      }
    }
  }

  async reAuthenticateUser(email: string): Promise<void> {
    try {
      const authData = await this.authenticationRepository.authenticate(
        email,
        this.password,
      );

      LoginHelper.setSessionPermissions(authData);

      this.authenticationModule.setUserMailToRedefinePassword('');
      this.$session.set('userMailToRedefinePassword', '');

      await this.loadAvailableModules();
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao tentar fazer login.');

      this.$notification.error(errorMessage);
      this.loading = false;
    }
  }

  async loadAvailableModules(): Promise<void> {
    try {
      const routeName = await LoginHelper.loadAvailableModules();

      this.$router.replace({ name: routeName });
    } catch (error) {
      const typedError = error as Error;
      let errorMessage = 'Houve um problema ao definir os módulos no usuário!';

      if (typedError.name === 'InvalidResponseException') {
        errorMessage = typedError.message;
      }

      this.$notification.error(errorMessage);
      this.loading = false;
    }
  }

  handleResetPassword(): void {
    if (!this.resetPasswordForm.validate()) {
      this.$notification.error('Os campos de senhas estão inválidos.');

      return;
    }

    if (this.password !== this.repeatPassword) {
      this.$notification.error('Os campos de senhas não estão iguais.');

      return;
    }

    this.resetPassword();
  }
}
