<script>
export default {
  methods: {
    filterMixin_company(item) {
      const isAGroup = item.companyType == 'G';

      if (isAGroup) {
        const foundGroup = this.enterprisesGroupSelect
          .find((group) => group.id == item.group);

        return foundGroup ? foundGroup.description : '';
      }

            const foundCompanies = this.enterprisesSelect.filter((enterprise) => {
                const result = item.group.some((itemId) => itemId == enterprise.id);

                return result;
            });

      const companyNames = foundCompanies.map((company) => company.name);

      return companyNames.join(', ');
    },
  },
};
</script>
