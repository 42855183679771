



































































































import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import {
  Vue,
  Component,
  Prop,
  Emit,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { toCurrency } from '@/utils/';
import { formatErrorForNotification } from '@/utils/error';

import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import {
  formatTitle,
  groupByRemittance,
  hideDuplicateOverflow,
  extractErrorMessageFromApproveOrDisapproveRejectedRequests,
} from '@/views/paymentRemittances/utils';
import IApproveOrDisapproveRequestResponse from '@/views/paymentRemittances/interfaces/IApproveOrDisapproveRequestResponse';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionDisapprove extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<PaymentRemittance>,
  }) readonly items!: Array<PaymentRemittance>;

  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  @Prop({
    type: String,
  }) name!: string;

  @Emit()
  close(reload: boolean = false): CloseActionData {
    return {
      type: ActionType.DISAPPROVE,
      reload,
    };
  }

  @Watch('open')
  onChangeOpen(value: boolean) {
    if (!value) {
      this.comment = '';
      this.form.resetValidation();
    }
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly formatCurrency = toCurrency;
  readonly formatTitle = formatTitle;
  readonly required = (v: string) => !!v || 'Campo obrigatório!';
  comment: string = '';

  get total(): number {
    return this.items
      .reduce((acc, item) => acc + item.total, 0);
  }

  async save() {
    const isValidform = this.form.validate();
    if (!isValidform) return;

    try {
      this.$dialog.startLoading();

      const groups = groupByRemittance(this.items);
      const responses: IApproveOrDisapproveRequestResponse<number>[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(groups)) {
        // eslint-disable-next-line no-await-in-loop
        responses.push(await this.handleDisapprovals(key, value));
      }

        const allRejected = responses
          .filter(({ status }) => status === 'rejected')
          .map(({ value }) => value);

        if (allRejected.length > 0) {
          const errorMessage = extractErrorMessageFromApproveOrDisapproveRejectedRequests(
            allRejected,
          );

          this.$notification.error(errorMessage);
        }

        this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      hideDuplicateOverflow(this);
    }
  }

  async handleDisapprovals(
      remi: string,
      titles: Array<string>,
    ): Promise<IApproveOrDisapproveRequestResponse<number>> {
      const group = this.$session.get('company_group_id');
      const numberRemittanceId = Number(remi);

      try {
        await this.paymentRemittanceRepository
          .disapprovals(group, numberRemittanceId, this.companies, titles);

        return {
          status: 'fulfilled',
          value: numberRemittanceId,
        };
      } catch (error) {
        return {
          status: 'rejected',
          value: numberRemittanceId,
        };
      }
    }
}
