import AccountReceivableStatusEnum from '@/views/clients/enums/AccountReceivableStatusEnum';

export default class AccountReceivable {
    constructor(
        public id: string,
        public number: string,
        public order: string,
        public emissionDate: string|null,
        public issueDate: string|null,
        public document: string,
        public officialDueDate: string|null,
        public delayDays: number,
        public value: number,
        public balance: number,
        public status: AccountReceivableStatusEnum,
        public accountReceivableCustomerId: string,
        public titleDecreaseDate: string|null,
        public type: string,
        public prefix: string,
        public installment: string,
        public fees: number,
        public paidValue: number,
        public penalty: number,
        public bankCode: number,
    ) {}

    static make(data: any): AccountReceivable {
        return new AccountReceivable(
            data.id,
            data.number,
            data.order_number,
            data.emission_date,
            data.due_date,
            data.document,
            data.real_due_date,
            data.days_late,
            data.value,
            data.balance,
            data.status,
            data.account_receivable_customer_id,
            data.title_decrease_date,
            data.type ?? '',
            data.prefix ?? '',
            data.installment ?? '',
            data.fees,
            data.paid_value,
            data.penalty,
            data.bank_code,
        );
    }
}
