





























































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import NavigationTabsType from '@/types/NavigationTabsType';
import NavigationTabs from '@/components/navigation/tabs/NavigationTabs.vue';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import BillingPendencyStore from './store/BillingPendencyStore';

@Component({
  components: {
    NavigationTabs,
  },
})
export default class BillingPendency extends Vue {
  public selectedTab: number = 0;

  public store = new BillingPendencyStore();

  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public tabs: Array<NavigationTabsType> = [
    {
      name: 'Operadores',
      path: '/pendencias-de-cobranca',
    },
    {
      name: 'Transferência de Pendências',
      path: '/pendencias-de-cobranca/transferencias',
    },
  ];

  public get isTransferList(): boolean {
    return this.selectedTab !== 0;
  }

  public get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  public created(): void {
    this.selectedTab = this.defineTabByRoute(this.$route.name);
    this.setManager();
  }

  public defineTabByRoute(routeName: string | null | undefined): number {
    switch (routeName) {
      case 'BillingPendencyList':
        return 0;
      case 'BillingPendencyTransfers':
        return 1;
      default:
        return 0;
    }
  }

  public toggle(): void {
    this.store.state.filter = !this.store.state.filter;
  }

  public filter(): void {
    this.store.state.onFilter = true;
  }

  public disapprove(): void {
    this.store.state.disapprove = true;
  }

  public approve(): void {
    this.store.state.approve = true;
  }

  public setManager() {
    const isBillingManagerPermisstion = this.authenticationModule.permissions.find((permisstion) => permisstion.reference === 'is_billing_manager' && permisstion.isActive);

    if (isBillingManagerPermisstion) {
      this.store.state.manager = true;
    } else {
      this.store.state.manager = false;
    }
  }
}
