import StatusType from '../enums/StatusType';
import Operator from './Operator';
import OperatorAbsence from './OperatorAbsence';

export default class OperatorDistribution {
  constructor(
    public id: number,
    public operatorId: number,
    public status: StatusType,
    public delay: number,
    public value: number,
    public balance: number,
    public recurrence: boolean,
    public absences: OperatorAbsence[],
    public companies: number[],
  ) {}

  static make(data: any): OperatorDistribution {
    const absences = data.absences
      ? data.absences.map(OperatorAbsence.make)
      : [] as Array<OperatorAbsence>;

    return new OperatorDistribution(
      data.id,
      data.operator_id,
      data.status,
      data.delay,
      data.value,
      data.balance,
      data.recurrence,
      absences,
      data.companies,
    );
  }
}
