<template>
  <v-dialog v-model="showModal" @click:outside="closeModal" max-width="800">
    <v-card>
      <v-card-title>
        <slot name="title"></slot>
      </v-card-title>

      <v-card-text>
        <slot name="content"></slot>
      </v-card-text>

      <slot name="actions">
        <v-card-actions>
            <v-btn
              color="primary darken-1"
              @click="closeModal"
            >
              Fechar
            </v-btn>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'CustomModal',
  props: {
    show: Boolean,
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('close', value);
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.v-card__text label {
  margin-bottom: .3rem;
}
.v-card__actions {
  justify-content: flex-end;
}
</style>
