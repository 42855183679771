<template>
  <div class="dialogis-wrapper">
    <inn-confirm-dialog ref="confirm"></inn-confirm-dialog>
    <inn-loading-dialog ref="loading"></inn-loading-dialog>
  </div>
</template>

<script>
import InnConfirmDialog from './InnConfirmDialog.vue';
import InnLoadingDialog from './InnLoadingDialog.vue';

export default {
  name: 'InnDialogsWrapper',
  methods: {
    confirm(title, message, options) {
      return this.$refs.confirm.open(title, message, options);
    },
    startLoading() {
      this.$refs.loading.start();
    },
    stopLoading() {
      this.$refs.loading.stop();
    },
  },
  components: {
    InnConfirmDialog,
    InnLoadingDialog,
  },
};
</script>

<style></style>
