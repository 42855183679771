






































































































































































































































































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { toCurrency } from '@/utils';
import { monthsDistanceBetweenTwoDates } from '@/utils/date';
import CurrencyHelper from '@/helpers/CurrencyHelper';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import DateInputHelper from '@/helpers/DateInputHelper';
import OrderSupport from '@/views/orders/support/OrderSupport';
import InvoiceChart from '@/components/charts/InvoiceChart.vue';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import SalesChart from '@/views/orders/charts/SalesChart.vue';
import OrderRepository from '@/repositories/OrderRepository';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import PaymentConditionRepository from '@/repositories/PaymentConditionRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientModule from '@/stores/modules/ClientModule';
import OrderModule from '@/stores/modules/OrderModule';
import FilterParameterOrderAnalisys from '@/domain/models/filter-parameters/FilterParameterOrderAnalisys';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import HistoricalBillingsOrder from '@/domain/models/HistoricalBillingsOrder';
import CustomerInformationCreditAnalisysInputs from '@/views/orders/types/CustomerInformationCreditAnalisysInputs';
import CreditAnalisysOrderValues from '@/views/orders/types/CreditAnalisysOrderValues';
import ClientFinancialChartValues from '@/views/clients/types/ClientFinancialChartValues';
import VMenuOptions from '@/types/VMenuOptions';
import LooseObjectType from '@/types/LooseObjectType';
import RangeDate from '@/types/RangeDate';
import IOrderBlockGroup from '@/views/orders/interfaces/IOrderBlockGroup';

@Component({
  components: {
    InvoiceChart,
    SalesChart,
    ButtonFilterMenuOptions,
  },
})
export default class OrderAnalisys extends Vue {
  public readonly orderSupport: OrderSupport = OrderSupport;
  public readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();
  private readonly accountsReceivableRepository:
    AccountsReceivableRepository = new AccountsReceivableRepository();
  private readonly orderRepository: OrderRepository = new OrderRepository();
  private readonly paymentConditionRepository:
    PaymentConditionRepository = new PaymentConditionRepository();
  private readonly clientModule: ClientModule = getModule(ClientModule);
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly orderModule: OrderModule = getModule(OrderModule);

  public loadingClientInformation: boolean = false;
  public loadingHistoricalBilling: boolean = false;
  public loadingBillingTitle: boolean = false;
  public loadingPaymentConditionField: boolean = false;

  public definedDateFilterOption: string = '';
  public searchPaymentCondition: string = '';

  public defaultDateFilterOption: VMenuOptions | null = null;
  public customerInformationInput: CustomerInformationCreditAnalisysInputs = {
    companyId: null,
    companyName: '',
    risk: '',
    clientClass: '',
    orderStatus: '',
    notes: '',
  };
  public historicalBillingChartValues: HistoricalBillingsOrder = {
    months: [],
    values: [],
  };
  public invoiceChartValue: ClientFinancialChartValues = {
    totalExpired: 0,
    totalToExpire: 0,
    totalPaidOut: 0,
  };
  public creditAnalisysOrderValues: CreditAnalisysOrderValues = {
    orderValue: null,
    higherOrderValue: null,
    creditLimit: null,
    creditLimitBalance: null,
    titleBalance: null,
    titleWithHighestValue: null,
    lastPayment: null,
    firstPurchase: null,
    lastPurchase: null,
    higherDelay: null,
    averageDelay: null,
    actualDelay: null,
    orderPaymentCondition: '',
    orderObservationMessage: '',
  }

  public risksOptions: string[] = ['A', 'B', 'C', 'D', 'E', 'X'];
  public classOptions: string[] = ['A', 'B', 'C', 'X'];

  public paymentConditions: VMenuOptions[] = [];
  public dateFilterOptions: VMenuOptions[] = [
    { value: 'date_emission', text: 'Data de Emissão' },
    { value: 'date_actual_expiration', text: 'Vencimento Real' },
  ];
  public rangeDate: Array<string> = []
  public firstBlockGroups: IOrderBlockGroup[] = [
    { name: 'Valor do Pedido', identifier: 'orderValue' },
    { name: 'Maior Compra', identifier: 'higherOrderValue' },
    { name: 'Limite de Crédito', identifier: 'creditLimit' },
    { name: 'Saldo do Limite de Crédito', identifier: 'creditLimitBalance' },
    { name: 'Saldo de Títulos', identifier: 'titleBalance' },
    { name: 'Título com Maior Valor', identifier: 'titleWithHighestValue' },
  ];
  public secondBlockGroups: IOrderBlockGroup[] = [
    { name: 'Último Pagamento', identifier: 'lastPayment' },
    { name: 'Primeira Compra', identifier: 'firstPurchase' },
    { name: 'Última Compra', identifier: 'lastPurchase' },
    { name: 'Maior Atraso', identifier: 'higherDelay' },
    { name: 'Média de Atraso', identifier: 'averageDelay' },
    { name: 'Atraso Atual', identifier: 'actualDelay' },
  ];

  public formattedRangeDate: Partial<RangeDate> = {};

  public get getInfoCardColor(): string {
    if (this.$vuetify.theme.dark) {
      return '#565656';
    }

    return '#F2F3F7';
  }

  public get getTextPriceValuesColor(): string {
    if (this.$vuetify.theme.dark) {
      return '';
    }

    return '#007BDD';
  }

  public get buttonHeight(): string {
    return this.$vuetify.breakpoint.smAndDown ? '40px' : '48px';
  }

  public get formattedInvoiceChartValue(): Object {
    const {
      totalExpired,
      totalToExpire,
      totalPaidOut,
    } = this.invoiceChartValue;

    return {
      labels: [
        `Vencido: R$ ${toCurrency(totalExpired, {}, true)}`,
        `A vencer: R$ ${toCurrency(totalToExpire, {}, true)}`,
        `Pago: R$ ${toCurrency(totalPaidOut, {}, true)}`,
      ],
      datasets: [
        {
          label: 'Valor',
          data: [
            totalExpired,
            totalToExpire,
            totalPaidOut,
          ],
          backgroundColor: ['#E9637B', '#9994D5', '#0EB799'],
          borderWidth: 0,
        },
      ],
    };
  }

  public get formattedHistoricalBillingChartValues(): Object {
    const { months, values } = this.historicalBillingChartValues;

    return {
      labels: months,
      datasets: [
        {
          label: 'Faturamento',
          data: values,
          fill: true,
          borderWidth: 0,
          backgroundColor: '#0EB799',
          cubicInterpolationMode: 'monotone',
          pointRadius: 4,
        },
      ],
    };
  }

  public get defaultStyleCharts(): any {
    return {
      height: '250px',
    };
  }

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get companyId(): number {
    return parseInt(this.$route.params.companyId, 10);
  }

  public async created(): Promise<void> {
    await this.getGroupFilterParameters();

    this.setOrderInformations();
    this.setCustomerValues();

    await Promise.all([
      this.getHistoricalBillingFromOrder(),
      this.getCustomerFinancialValues(),
    ]);
  }

  public async getGroupFilterParameters(): Promise<void> {
    this.$dialog.startLoading();

    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.ORDER_ANALISYS);
      const formattedFilters = FilterParameterOrderAnalisys.make(filterParameters);

      this.applyFiltersOnActualPage(formattedFilters);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getCreditAnalisysOrderValuesBasedOnIdentifier(
    identifier: string,
  ): string|number|null|undefined {
    switch (identifier) {
      case 'orderValue': return this.creditAnalisysOrderValues.orderValue;
      case 'higherOrderValue': return this.creditAnalisysOrderValues.higherOrderValue;
      case 'creditLimit': return this.creditAnalisysOrderValues.creditLimit;
      case 'creditLimitBalance': return this.creditAnalisysOrderValues.creditLimitBalance;
      case 'titleBalance': return this.creditAnalisysOrderValues.titleBalance;
      case 'titleWithHighestValue': return this.creditAnalisysOrderValues.titleWithHighestValue;
      case 'lastPayment': return this.creditAnalisysOrderValues.lastPayment;
      case 'firstPurchase': return this.creditAnalisysOrderValues.firstPurchase;
      case 'lastPurchase': return this.creditAnalisysOrderValues.lastPurchase;
      case 'higherDelay': return this.creditAnalisysOrderValues.higherDelay;
      case 'averageDelay': return this.creditAnalisysOrderValues.averageDelay;
      case 'actualDelay': return this.creditAnalisysOrderValues.actualDelay;
      default: return '';
    }
  }

  public applyFiltersOnActualPage(filters: FilterParameterOrderAnalisys): void {
    this.rangeDate = filters.rangeDate;

    const formattedRangeDate = DateInputHelper
      .formatRangeDate(filters.rangeDate);

    if (formattedRangeDate) {
      this.formattedRangeDate = formattedRangeDate;
    }

    this.defaultDateFilterOption = FilterParameterHelper
      .getFormattedColumnObjectOfOptionsButton(this.dateFilterOptions, filters.dateToSearch);

    if (this.defaultDateFilterOption !== null) {
      this.definedDateFilterOption = this.defaultDateFilterOption.value;
    }
  }

  public async setOrderInformations(): Promise<void> {
      this.creditAnalisysOrderValues.orderValue = toCurrency(this.orderModule.orderValue, {}, true);
      this.creditAnalisysOrderValues.orderPaymentCondition = this.orderModule.orderPaymentCondition;
      this.creditAnalisysOrderValues
        .orderObservationMessage = this.orderModule.orderObservationMessage;
      this.creditAnalisysOrderValues.higherOrderValue = toCurrency(
        this.orderModule.higherOrderValue,
        {},
        true,
      );

      this.customerInformationInput.orderStatus = this.orderModule.orderStatus;

      if (this.creditAnalisysOrderValues.orderPaymentCondition) {
        this.searchPaymentCondition = this.creditAnalisysOrderValues.orderPaymentCondition;
      }
  }

  public async getHistoricalBillingFromOrder(): Promise<void> {
    this.loadingHistoricalBilling = true;

    try {
      const response = await this.orderRepository.getHistoricalBillingFromOrder(
        parseInt(this.$route.params.companyId, 10),
        this.clientModule.client.id,
        this.authenticationModule.user.company_group_id,
        this.rangeDate,
      );

      this.historicalBillingChartValues.months = response.months;
      this.historicalBillingChartValues.values = response.values;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o histórico financeiro!');
    } finally {
      this.loadingHistoricalBilling = false;
    }
  }

  public async getCustomerFinancialValues(): Promise<void> {
    this.loadingBillingTitle = true;

    const filterDates: LooseObjectType = {};

    filterDates[`start_${this.definedDateFilterOption}`] = this.formattedRangeDate.initialDate;
    filterDates[`end_${this.definedDateFilterOption}`] = this.formattedRangeDate.finalDate;

    try {
      const chartData = await this.accountsReceivableRepository
        .getAccountsReceivableChartsByFilters(
          this.companyGroupId,
          this.authenticationModule.companyIds,
          this.clientModule.client.id,
          filterDates,
        );

      this.invoiceChartValue.totalExpired = chartData.totalExpired;
      this.invoiceChartValue.totalToExpire = chartData.totalToExpire;
      this.invoiceChartValue.totalPaidOut = chartData.totalPaidOut;

      this.creditAnalisysOrderValues.lastPayment = chartData.lastPayment;

      this.creditAnalisysOrderValues.creditLimitBalance = CurrencyHelper
        .toCurrency(chartData.creditLimitBalance, { showCurrencySymbol: true });
      this.creditAnalisysOrderValues.titleBalance = CurrencyHelper
        .toCurrency(chartData.securitiesBalance, { showCurrencySymbol: true });
      this.creditAnalisysOrderValues.titleWithHighestValue = CurrencyHelper
        .toCurrency(chartData.titleWithHighestValue, { showCurrencySymbol: true });
      this.creditAnalisysOrderValues.higherDelay = chartData.biggestDelay;
      this.creditAnalisysOrderValues.averageDelay = chartData.averageDelay;
      this.creditAnalisysOrderValues.actualDelay = chartData.currentDelay;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as informações financeiras');
    } finally {
      this.loadingBillingTitle = false;
    }
  }

  public setCustomerValues(): void {
    const {
      companyName,
      risk,
      clientClass,
      notes,
      companyId,
      limit,
      firstPurchase,
      lastPurchase,
    } = this.clientModule.client;

    this.customerInformationInput.companyName = companyName;
    this.customerInformationInput.risk = risk;
    this.customerInformationInput.clientClass = clientClass;
    this.customerInformationInput.notes = notes;
    this.customerInformationInput.companyId = companyId;
    this.creditAnalisysOrderValues.creditLimit = limit;
    this.creditAnalisysOrderValues.firstPurchase = firstPurchase;
    this.creditAnalisysOrderValues.lastPurchase = lastPurchase;
  }

  public handleInput(value: string | number, type: string|null, module: 'client' | 'order'): void {
    if (module === 'client') {
      this.clientModule.setClient({
        ...this.clientModule.client,
        [type as string]: value,
      });

      return;
    }

    this.orderModule.setOrderObservationMessage(value as string);
  }

  public async handleSelectDateRange(): Promise<void> {
    await this.$nextTick();

    const formattedRangeDate = DateInputHelper.formatRangeDate(this.rangeDate);

    if (formattedRangeDate === null) {
      this.$notification.warn('O range de data está inválido!');

      return;
    }

    if (
      formattedRangeDate.initialDate
      && formattedRangeDate.finalDate
      && monthsDistanceBetweenTwoDates(
        formattedRangeDate.initialDate,
        formattedRangeDate.finalDate,
      ) >= 6
    ) {
      this.$notification.warn('A diferença em meses entre as duas datas deve ser no máximo até 6!');

      return;
    }

    this.formattedRangeDate = formattedRangeDate;
    this.getChartsValues();
  }

  public async getChartsValues(): Promise<void> {
    try {
      this.filterParametersRepository
        .setFilter(GroupFilterParametersEnum.ORDER_ANALISYS, [
          { key: 'date_range_order_analisys_list', value: JSON.stringify(this.rangeDate) },
          { key: 'date_to_search_order_analisys', value: this.definedDateFilterOption },
      ]);
    } catch (error) {
      console.error(error);
    }

    await Promise.all([
      this.getHistoricalBillingFromOrder(),
      this.getCustomerFinancialValues(),
    ]);
  }

  public handleSelectDateFilterOption(selectedDateFilter: VMenuOptions | null): void {
    let definedDateOption = '';

    if (selectedDateFilter !== null) {
      definedDateOption = selectedDateFilter.value;
    }

    this.definedDateFilterOption = definedDateOption;

    this.handleSelectDateRange();
  }

  public async handleOnFinishedDebouncePaymentCondition(search: string): Promise<void> {
    if (this.loadingPaymentConditionField || search === '') {
      return;
    }

    this.loadingPaymentConditionField = true;

    try {
      const paymentConditions = await this.paymentConditionRepository.getPaymentConditionsByCode(
        this.companyGroupId,
        this.companyId,
        search,
      );

      this.paymentConditions = paymentConditions
        .map(({ code, description }) => ({ text: description, value: code }));
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as condições de pagamento.');

      this.$notification.error(errorMessage);
    } finally {
      this.loadingPaymentConditionField = false;
    }
  }

  public handleOrderPaymentChange(orderPayment: string): void {
    this.orderModule.setOrderPaymentCondition(orderPayment);
  }
}
