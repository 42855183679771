import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c('span',{staticClass:"credit-rules-title"},[_vm._v(" Dados Gerais ")])])],1),_c(VForm,{ref:"GeneralCreditRuleForm"},[_c(VRow,{staticClass:"py-3"},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"12","xs":"12"}},[_c(VTextField,{attrs:{"label":"Nome","filled":"","rules":[_vm.inputRules.required(), _vm.inputRules.lowerOrEqualThen(256)]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c(VAutocomplete,{attrs:{"label":"Empresa/Filial","placeholder":"Deixe em branco para todos","auto-select-first":"","deletable-chips":"","small-chips":"","clearable":"","multiple":"","filled":"","dense":"","items":_vm.companyItems,"rules":[_vm.inputRules.required()]},on:{"change":_vm.handleSelectCompany,"click:clear":_vm.handleCompleteCompaniesClear},model:{value:(_vm.selectedCompanies),callback:function ($$v) {_vm.selectedCompanies=$$v},expression:"selectedCompanies"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12","xs":"12"}},[_c(VSelect,{attrs:{"label":"Status","filled":"","items":_vm.statusOptions,"rules":[_vm.inputRules.required()]},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","xl":"9","lg":"9","md":"9","sm":"12","xs":"12"}},[_c(VTextField,{attrs:{"label":"Descrição","filled":"","rules":[_vm.inputRules.required(), _vm.inputRules.lowerOrEqualThen(384)]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }