export default class AccountReceivablePreNegotiationAccountsDeadline {
  constructor(
    public idCustomer: string,
    public number: string,
    public dueDate: string,
    public value: number,
  ) {}

  static make(data: any): AccountReceivablePreNegotiationAccountsDeadline {
    return new AccountReceivablePreNegotiationAccountsDeadline(
      data.id_customer,
      data.number,
      data.due_date,
      data.value,
    );
  }
}
