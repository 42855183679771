import IBinding from '@/views/creditTotalizers/interfaces/IBinding';

export default class CreditTotalizer {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public query: string|null,
    public bindings: IBinding[],
    public active: 'active' | 'inactive',
    public executionType: 'query' | 'code',
    public companyIds: number[],
  ) {}

  public static make(data: any): CreditTotalizer {
    return new CreditTotalizer(
      data.id,
      data.name,
      data.description,
      data.query ?? null,
      data.bindings ? JSON.parse(data.bindings) : [],
      data.active,
      data.executionType,
      data.companyIds ?? [],
    );
  }
}
