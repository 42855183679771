






















































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import { API_BASE } from '@config/api';
import FileHelper from '@/helpers/FileHelper';
import IntegratorJobsEnum from '@/views/healthCheck/enums/IntegratorJobsEnum';
import HealthCheckStatusEnum from '@/views/healthCheck/enums/HealthCheckStatusEnum';
import HealthCheckRepository from '@/repositories/HealthCheckRepository';
import HealthCheck from '@/domain/models/healthCheck/HealthCheck';

export default defineComponent({
  name: 'HealthCheckIntegrator',
  data() {
    const healthCheckData: HealthCheck|null = null;

    const healthCheckRepository: HealthCheckRepository = new HealthCheckRepository();

    return {
      healthCheckData: healthCheckData as HealthCheck|null,
      healthCheckRepository,
    };
  },
  computed: {
    ...mapState({
        companyGroupId: (state: any) => Number(state.authentication.user.company_group_id),
    }),
    companyId(): number {
      return this.$store.getters['authentication/companyIds'][0];
    },
  },
  created() {
    this.getHealthCheckInnMerge();
  },
  methods: {
    async getHealthCheckInnMerge(): Promise<void> {
      try {
        this.$dialog.startLoading();

        this.healthCheckData = await this.healthCheckRepository.getHealthCheckIntegrator(
          this.companyGroupId,
          this.companyId,
        );
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar o health check do integrador.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    async getLogsFile(): Promise<void> {
      try {
        let downloadPath = API_BASE;
          downloadPath += `company-groups/${this.companyGroupId}/health-check/integrator/logs`;
          downloadPath += `?token=${this.$session.get('token')}`;
          downloadPath += `&company_id=${this.companyId}`;

        FileHelper.downloadFileByObjectUrl(downloadPath, 'logs');
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os logs do integrador.');
      }
    },
    getStatusText(status: HealthCheckStatusEnum): string {
      switch (status) {
        case HealthCheckStatusEnum.working: return 'Em funcionamento';
        case HealthCheckStatusEnum.unavailable: return 'Erro';
        default: return '';
      }
    },
    getStatusIcon(status: HealthCheckStatusEnum): string {
      switch (status) {
        case HealthCheckStatusEnum.working: return 'mdi-check-circle-outline';
        case HealthCheckStatusEnum.unavailable: return 'mdi-alert-circle-outline';
        default: return '';
      }
    },
    getStatusIconColor(status: HealthCheckStatusEnum): string {
      switch (status) {
        case HealthCheckStatusEnum.working: return 'green';
        case HealthCheckStatusEnum.unavailable: return 'red';
        default: return '';
      }
    },
    getJobName(jobName: IntegratorJobsEnum): string {
      switch (jobName) {
        case IntegratorJobsEnum.synchronizer_supplier: return 'Sincronizador Fornecedor';
        case IntegratorJobsEnum.synchronizer_financial_nature: return 'Sincronizador Natureza Financeira';
        case IntegratorJobsEnum.synchronizer_customer: return 'Sincronizador Cliente';
        case IntegratorJobsEnum.synchronizer_currency: return 'Sincronizador Moeda';
        case IntegratorJobsEnum.synchronizer_cost_center: return 'Sincronizador Centro de Custo';
        case IntegratorJobsEnum.synchronizer_bank_transaction: return 'Sincronizador Movimentos Bancários';
        case IntegratorJobsEnum.synchronizer_bank_balance: return 'Sincronizador Saldo Bancário';
        case IntegratorJobsEnum.synchronizer_bank: return 'Sincronizador Banco';
        case IntegratorJobsEnum.synchronizer_accounting_item: return 'Sincronizador Item de Contabilidade';
        case IntegratorJobsEnum.synchronizer_account_receivable: return 'Sincronizador Conta Receber';
        case IntegratorJobsEnum.synchronizer_account_payable: return 'Sincronizador Conta Pagar';
        case IntegratorJobsEnum.synchronizer_value_class: return 'Sincronizador Classe Valor';
        case IntegratorJobsEnum.update_integrations_in_inncash: return 'Atualizador de intrgrações do InnCash';
        default: return '';
      }
    },
  },
});
