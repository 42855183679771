


































































































































































































































































































































































































import {
  Component,
  Mixins,
Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientModule from '@/stores/modules/ClientModule';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import { DateTime } from 'luxon';
import AccountReceivable from '@/domain/models/AccountReceivable';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import { formatErrorForNotification } from '@/utils/error';

@Component
export default class AccountsReceivableDetailsDialog extends Mixins<ModelManagementMixin<boolean>>(
  ModelManagementMixin,
) {
  @Prop({
    type: Object,
    required: true,
    default: () => ({}),
  }) readonly accountReceivable!: AccountReceivable;

  public loading: boolean = false;

  public accountReceivableDetails: any = {};

  private readonly accountsReceivableRepository: AccountsReceivableRepository =
    new AccountsReceivableRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly clientModule: ClientModule = getModule(ClientModule);

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get companyId(): number {
    return this.clientModule.client.companyId!;
  }

  public get dialogWidth(): string {
    if (this.$vuetify.breakpoint.smAndDown) {
      return '95%';
    }

    if (this.$vuetify.breakpoint.md) {
      return '80%';
    }

    return '70%';
  }

  public async created(): Promise<void> {
    this.getDetails();
  }

  public async getDetails(): Promise<void> {
    try {
      this.loading = true;

      const { data } = await this.accountsReceivableRepository
        .getAccountsReceivableDetails(
          this.companyGroupId,
          this.companyId,
          this.accountReceivable.accountReceivableCustomerId,
        );

      this.accountReceivableDetails = data;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os detalhes do título!');
    } finally {
      this.loading = false;
    }
  }

  formatAmount(value: number): string {
    if (!value) value = 0;

    const val = (value / 1).toFixed(2).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  formatDate(value: string): string {
    if (!value) return '';

    const date = DateTime.fromISO(value).toLocaleString();

    return date;
  }

  public closeDialog(): void {
    this.$emit('on-close', false);
  }

  public async update(): Promise<void> {
    try {
      this.loading = true;

      const params = {
        observation: this.accountReceivableDetails.observation,
      };

      await this.accountsReceivableRepository
        .updateAccountsReceivableObservation(
          this.companyGroupId,
          this.companyId,
          this.accountReceivable.accountReceivableCustomerId,
          params,
        );

      this.$notification.info('A alteração da observação foi mandada para a fila de execução!');
      this.closeDialog();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
