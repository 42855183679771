<template>
  <div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title"></div>
      </div>
      <div class="m-portlet__head-tools">
        <v-btn
          :to="{ name: 'Occurrences' }"
          class="m-btn btn btn-warning mr-2"
          color="orange accent-3"
          >Cancelar</v-btn
        >
        <v-btn
          :loading="loading"
          @click="save"
          color="blue"
          class="m-btn btn btn-success"
          >Salvar</v-btn
        >
      </div>
    </div>
    <div class="m-portlet__body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Ação</label>
            <select
              class="form-control select2 hide"
              style="width: 100%"
              v-model="form.action"
            >
              <option value="1">Baixar título</option>
              <option value="2">Retirar do borderô</option>
              <option value="3">Inclusão de tarifa bancária</option>
              <option value="4">Sem ação</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Descrição</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="form.description"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Código</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="form.code"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Motivo de Baixa</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="form.reason"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Status</label>
            <select
              class="form-control select2 hide"
              style="width: 100%"
              v-model="form.status"
            >
              <option value="F">Inativo</option>
              <option value="T">Ativo</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Tipo</label>
            <select
              class="form-control select2 hide"
              style="width: 100%"
              v-model="form.type"
            >
              <option value="PAGAR">Pagar</option>
              <option value="RECEBER">Receber</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Tipo de envio</label>
            <select
              class="form-control select2 hide"
              style="width: 100%"
              v-model="form.action_type"
            >
              <option value="SENT">Envio</option>
              <option value="KEPT">Retorno</option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Evento</label>
            <select
              class="form-control select2 hide"
              style="width: 100%"
              v-model="form.event"
            >
              <option value="update_e1_saldo">Baixa</option>
              <option value="update_e1_acresc">Acréscimo</option>
              <option value="update_e1_decresc">Decréscimo</option>
              <option value="update_e1_zdtflux">Data Fluxo</option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Código do Banco</label>
            <select
              class="form-control hide select2"
              v-model="form.bank_code"
              required
            >
              <option :value="undefined">Selecione...</option>
              <option
                v-for="bank in banks"
                :value="bank.a6_cod"
                v-bind:key="bank.id"
              >
                {{ bank.a6_cod }} - {{ bank.a6_nome }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Opções de empresa</label>
            <select
              class="form-control select2 hide"
              style="width: 100%"
              v-model="form.company_option"
            >
              <option value="all">Todas as empresas</option>
              <option value="group">Por grupo</option>
              <option value="company">Por empresa</option>
            </select>
          </div>
        </div>
        <div class="col-md-4" v-if="form.company_option === 'company'">
          <div class="form-group">
            <label>Empresa</label>
            <select
              class="form-control select2"
              field-name="Empresa"
              v-model="form.company_id"
            >
              <option
                v-for="company in companys"
                :value="company.id"
                v-bind:key="company.id"
              >
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OccurrencesService from "@/services/occurrences.service";
export default {
  data() {
    return {
      form: {
        id: "new",
        action: "",
        description: "",
        status: "",
        type: "",
        bank_code: "",
        company_group_id: this.$session.get("company_group_id"),
        company_id: "",
        company_option: "",
        event: "",
        action_type: ""
      },

      banks: [],
      companys: [],
      loading: false,
    };
  },

  created() {
    const isANewOccurrence = this.validNewOccurrence();

    if (!isANewOccurrence) {
      this.showOccurrence();
    }

    this.loadBanks();
  },
  mounted() {
    this.loadCompanys();
  },
  methods: {
    validNewOccurrence() {
      const { params } = this.$route;

      return params.id && params.id === "new";
    },

    loadCompanys: function () {
      var self = this;
      blockPage("Carregando os dados...");

      this.$http
        .get(this.$store.state.API_BASE + "company/listAll", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          (result) => {
            self.companys = result.body.data;
            unblockPage();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },

    async showOccurrence() {
      try {
        const { id } = this.$route.params;

        const occurrencesService = new OccurrencesService();

        const response = await occurrencesService.show(id);

        Object.assign(this.form, response);

        this.form.company_option = "all";
        if (response.company_id > 0) {
          this.form.company_option = "company";
          this.form.company_id = response.company_id;
        } else if (response.company_grouo_id > 0) {
          this.form.company_option = "group";
        }

        this.$forceUpdate();
      } catch (err) {
        this.$helpers.defaultCatchError(error);
      }
    },

    async save() {
      try {
        this.loading = true;
        const occurrencesService = new OccurrencesService();

        await occurrencesService.store(this.form);

        showNotification(
          "Sucesso",
          "Processo concluído com sucesso!",
          "success"
        );
        this.$router.push({ name: "Occurrences" });
      } catch (err) {
        this.$helpers.defaultCatchError(err);
      } finally {
        this.loading = false;
      }
    },

    async loadBanks() {
      try {
        let params = {
          companys: null,
          fluxcai: null,
        };

        const response = await this.$http.get(
          this.$store.state.API_BASE + "bank/listAllByGroup",
          {
            headers: { token: this.$session.get("token") },
            params: params,
          }
        );

        this.banks = response.data.data;
      } catch (err) {
        this.$helpers.defaultCatchError(err);
      }
    },
  },
};
</script>
