











































































































































































































































































































import {
  Component,
  Mixins,
  Ref,
  Emit,
  Prop,
  Watch,
} from 'vue-property-decorator';
import DialogHelper from '@/helpers/DialogHelper';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';
import CompanyConfigurationsRepository from '@/repositories/CompanyConfigurationsRepository';
import ClientRepository from '@/repositories/ClientRepository';
import { formatCurrency, formatType } from '@/views/statementConciliation/utils';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import ActionType from '@/domain/enums/StatementConciliationActionType';
import { VForm } from '@/types/VForm';
import { ICreateMovementAdvanceData, CreateMovementData } from '@/views/statementConciliation/utils/interfaces';
import Bank from '@/domain/models/Bank';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import ConciliationConfig from '@/domain/models/ConciliationConfig';
import SearchCustomer from '@/domain/models/SearchCustomer';

@Component
export default class StatementConciliationGenerateAdvanceDialog extends
Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  private readonly statementConciliationRepository:
    StatementConciliationRepository = new StatementConciliationRepository();
  private readonly clientRepository:
    ClientRepository = new ClientRepository();
  private readonly companyConfigurationsRepository:
    CompanyConfigurationsRepository = new CompanyConfigurationsRepository();

  public financialNatureSearch: string = '';
  public customerSearch: string = '';
  public costCenterSearch: string = '';
  public accountingItemSearch: string = '';
  public valueClassSearch: string = '';

  public model: boolean = this.value;
  public loading: boolean = false;
  public hasLoadedMovementData: boolean = false;
  public financialNatureLoading: boolean = false;
  public loadingCustomerField: boolean = false;
  public costCenterLoading: boolean = false;
  public accountingItemLoading: boolean = false;
  public valueClassLoading: boolean = false;

  public selectedCustomer: SearchCustomer|null = null;
  public conciliationConfig: ConciliationConfig|null = null;
  public movementDialogData: ICreateMovementAdvanceData|null = null;
  public bank: Bank|null = null;

  public banks: ISelectOptions[] = [];
  public financialNatures: ISelectOptions[] = [];
  public costCenters: ISelectOptions[] = [];
  public accountItems: ISelectOptions[] = [];
  public valueClasses: ISelectOptions[] = [];
  public customers: SearchCustomer[] = [];

  public readonly formatType: Function = formatType;
  public readonly formatCurrency: Function = formatCurrency;
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  public get dialogWidth(): string {
    return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, { md: '80vw', default: '80vw' });
  }

  public get companyId(): number {
    return this.createMovementData.company;
  }

  public get companyGroupId(): number {
    return this.$session.get('company_group_id');
  }

  @Prop({
    type: Object as () => CreateMovementData,
    required: true,
  }) readonly createMovementData!: CreateMovementData;

  @Ref('GenerateAdvanceForm') readonly generateAdvanceForm!: VForm;

  @Emit('close')
  handleClose() {
    return ActionType.GENERATE_ADVANCE;
  }

  @Emit()
  reload(): boolean {
    return true;
  }

  @Watch('financialNatureSearch')
  async changeFinancialNatures(search: string) {
    if (this.financialNatureLoading || this.movementDialogData!.financial_nature) return;

    try {
      this.financialNatureLoading = true;
      await this.loadFinancialNatures(search);
    } catch (error: any) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problçema ao requisitar as naturezas financeiras.');

      this.$notification.error(message);
    } finally {
      this.financialNatureLoading = false;
    }
  }

  @Watch('costCenterSearch')
  async changeCostCenters(search: string) {
    if (this.costCenterLoading || this.movementDialogData!.cost_center) return;

    try {
      this.costCenterLoading = true;
      await this.loadCostCenters(search);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problçema ao requisitar os centros de custo.');

      this.$notification.error(message);
    } finally {
      this.costCenterLoading = false;
    }
  }

  @Watch('accountingItemSearch')
  async changeAccountingItems(search: string) {
    if (this.accountingItemLoading || this.movementDialogData!.account_item) return;

    try {
      this.accountingItemLoading = true;
      await this.loadAccountingItems(search);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problçema ao requisitar os itens contáveis.');

      this.$notification.error(message);
    } finally {
      this.accountingItemLoading = false;
    }
  }

  @Watch('valueClassSearch')
  async changeValueClasss(search: string) {
    if (this.valueClassLoading || this.movementDialogData!.value_class) return;

    try {
      this.valueClassLoading = true;
      await this.loadValueClasses(search);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problçema ao requisitar as classes de valor.');

      this.$notification.error(message);
    } finally {
      this.valueClassLoading = false;
    }
  }

  public created(): void {
    this.loadBankAndConfig();
  }

  public async loadBankAndConfig(): Promise<void> {
    try {
      this.loading = true;
      this.movementDialogData = {
        id: this.createMovementData.id,
        history: this.createMovementData.history,
        company: this.createMovementData.company,
        financial_nature: this.createMovementData.financial_nature,
        cost_center: this.createMovementData.cost_center,
        account_item: this.createMovementData.account_item,
        value_class: this.createMovementData.value_class,
        value: this.createMovementData.value,
        movement_date: this.createMovementData.date,
        advance_currency_type: '',
        advance_prefix: '',
        advance_type: '',
        company_id: null,
        customer_code: '',
        customer_store: '',
        title_number: `${this.createMovementData.id}`,
        bank_id_customer: '',
        installment: '',
        currency: '',
        phase: '',
        project: '',
        accounting_account: '',
      };

      await this.loadBanks();
      await this.loadConciliationConfigs();
      await this.loadCompanyConfigs();
    } catch (error: any) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema requisitar os dados de configuração da conciliação.');

      this.$notification.error(message);
    } finally {
      this.hasLoadedMovementData = true;
      this.loading = false;
    }
  }

  public async loadBanks(): Promise<void> {
    const banks = await this.statementConciliationRepository
      .getBanksByCompany(this.companyId);

    this.banks = banks.map(({
      code,
      agency,
      account,
      name,
      idCustomer: value,
    }) => ({
      text: `${code} ${agency} ${account} ${name}`,
      value,
    }));

    const { bank: idCustomer } = this.createMovementData;
    const currentBank = banks.find((b: Bank) => b.idCustomer === idCustomer) || {} as Bank;

    this.bank = currentBank;
  }

  public async loadConciliationConfigs(): Promise<void> {
    const conciliationConfig = await this.statementConciliationRepository
      .getConciliationConfigs(this.companyId, this.bank!.code);

    if (conciliationConfig.length > 0) {
      const { 0: config } = conciliationConfig;
      this.conciliationConfig = config;
    } else {
      this.handleClose();
      throw new Error('Verifique as configurações de conciliação bancária para esta empresa.');
    }
  }

  public async loadCompanyConfigs(): Promise<void> {
    const companyConfig = await this.companyConfigurationsRepository
      .getCompanyConfigurationByCompanyId(this.companyGroupId, this.companyId);

    this.movementDialogData!.advance_currency_type = companyConfig.advanceCurrencyType;
    this.movementDialogData!.advance_prefix = companyConfig.advancePrefix;
    this.movementDialogData!.advance_type = companyConfig.advanceType;
  }

  public async loadFinancialNatures(search?: string): Promise<void> {
    const financialNatures = await this.statementConciliationRepository
      .getFinancialNatures(this.companyId, search);

    const moreFinancialNatures = financialNatures
      .map(({ ed_codigo: value, ed_descric: text }) => ({ text: `${value} - ${text}`, value }));

    this.financialNatures.push(...moreFinancialNatures);
  }

  public async loadCostCenters(search?: string): Promise<void> {
    const costCenters = await this.statementConciliationRepository
      .getCostCenters(this.companyId, search);

    const moreCostCenters = costCenters.map(({
      ctt_custo: value,
      ctt_desc01: text,
    }) => ({ text: `${value} - ${text}`, value }));

    this.costCenters.push(...moreCostCenters);
  }

  public async loadAccountingItems(search?: string): Promise<void> {
    const accountItems = await this.statementConciliationRepository
      .getAccountingItems(this.companyId, search);

    const moreAccountItems = accountItems.map(({
      ctd_item: value,
      ctd_desc01: text,
    }) => ({ text: `${value} - ${text}`, value }));

    this.accountItems.push(...moreAccountItems);
  }

  public async loadValueClasses(search?: string): Promise<void> {
    const valueClasses = await this.statementConciliationRepository
      .getValueClasses(this.companyId, search);

    const moreValueClasses = valueClasses.map(({
        cth_clvl: value,
        cth_desc01: text,
      }) => ({ text: `${value} - ${text}`, value }));

    this.valueClasses.push(...moreValueClasses);
  }

  public async getCustomersByCompanyId(): Promise<void> {
    if (this.loadingCustomerField) {
      return;
    }

    this.loadingCustomerField = true;

    try {
      const customers = await this.clientRepository.getAllCustomersBySearch(
        this.companyGroupId,
        this.companyId,
        this.customerSearch,
      );

      this.customers = customers;
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar os clientes.');

      this.$notification.error(errorMessage);
    } finally {
      this.loadingCustomerField = false;
    }
  }

  public async handleSave(): Promise<void> {
    if (!this.generateAdvanceForm.validate()) {
      this.$notification.error('Existem campos com valores inválidos.');

      return;
    }

    if (this.movementDialogData?.advance_currency_type === null) {
      this.$notification.error('A configuração de empresa do campo Tipo de Moeda não foi definida.');

      return;
    }

    this.$dialog.startLoading();

    try {
      await this.statementConciliationRepository.generateAdvance(
        this.companyGroupId,
        this.companyId,
        {
          ...this.movementDialogData!,
          customer_code: this.selectedCustomer!.code,
          customer_store: this.selectedCustomer!.store,
          bank_id_customer: this.bank!.idCustomer,
        },
      );

      this.$notification.success('Geração de adiantamento efetivada com sucesso.');
      this.handleClose();
      this.reload();
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao realizar a geração de adiantamento.');
      this.$notification.error(errorMessage);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public handleCustomerSearch(search: string): void {
    this.customerSearch = search;

    if (!this.customerSearch) {
      this.customers = [];

      return;
    }

    this.getCustomersByCompanyId();
  }
}
