export default class BillingRemittanceGenerate {
  constructor(
    public bordero_number: any,
    public code_bank: string,
    public error: boolean,
    public file: string,
    public message: string,
    public name: string,
    public van: boolean,
    public created_file_id: number,
  ) {}

  static make(data: any): BillingRemittanceGenerate {
    return new BillingRemittanceGenerate(
      data.bordero_number,
      data.code_bank,
      data.error,
      data.file,
      data.message,
      data.name,
      data.van,
      data.created_file_id,
    );
  }
}
