import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, onMenu, onTooltip)),[_c(VIcon,{attrs:{"dense":""}},[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}])},[_c(VList,[_c(VListItem,{attrs:{"disabled":!_vm.invoice},on:{"click":function($event){return _vm.click(_vm.action.GENERATE_INVOICE)}}},[_c(VListItemTitle,[_vm._v(" Gerar Fatura ")])],1),_c(VListItem,{attrs:{"disabled":!_vm.generate},on:{"click":function($event){return _vm.click(_vm.action.GENERATE_REMITTANCE)}}},[_c(VListItemTitle,[_vm._v(" Gerar Remessa ")])],1),(_vm.approve)?_c(VListItem,{on:{"click":function($event){return _vm.click(_vm.action.APPROVE)}}},[_c(VListItemTitle,[_vm._v(" Aprovar Selecionados ")])],1):_vm._e(),(_vm.approve)?_c(VListItem,{on:{"click":function($event){return _vm.click(_vm.action.DISAPPROVE)}}},[_c(VListItemTitle,[_vm._v(" Reprovar Selecionados ")])],1):_vm._e(),_c(VListItem,{on:{"click":function($event){return _vm.click(_vm.action.CHANGE_METHOD)}}},[_c(VListItemTitle,[_vm._v(" Alterar Formas de Pagamento ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }