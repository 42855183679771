export default class RoutineLock {
  public constructor(
    public isLocked: boolean,
    public userName: string,
  ) {}

  public static make(data : any): RoutineLock {
    return new RoutineLock(
      data.is_locked,
      data.user_name,
    );
  }
}
