








































































































































































































































import {
  Component,
  Mixins,
  Ref,
  Emit,
  Prop,
  Watch,
} from 'vue-property-decorator';
import DialogHelper from '@/helpers/DialogHelper';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';
import CompanyConfigurationsRepository from '@/repositories/CompanyConfigurationsRepository';
import ClientRepository from '@/repositories/ClientRepository';
import {
  formatCurrency,
  formatType,
} from '@/views/statementConciliation/utils';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import ActionType from '@/domain/enums/DDAConciliationActionType';
import { VForm } from '@/types/VForm';
import Bank from '@/domain/models/Bank';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import SearchCustomer from '@/domain/models/SearchCustomer';
import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';
import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';
import { ICreateMovementInvoiceData } from '../utils/interfaces';

@Component
export default class DdaConciliationGenerateInvoiceDialog extends Mixins<
  ModelManagementMixin<boolean>
>(ModelManagementMixin) {
  private readonly statementConciliationRepository: StatementConciliationRepository =
    new StatementConciliationRepository();
  private readonly clientRepository: ClientRepository = new ClientRepository();
  private readonly companyConfigurationsRepository: CompanyConfigurationsRepository =
    new CompanyConfigurationsRepository();

  private readonly DDAConciliationRepository: DDAConciliationRepository =
    new DDAConciliationRepository();

  public financialNatureSearch: string = '';
  public supplierSearch: string = '';
  public costCenterSearch: string = '';
  public accountingItemSearch: string = '';
  public valueClassSearch: string = '';

  public model: boolean = this.value;
  public loading: boolean = false;
  public hasLoadedMovementData: boolean = false;
  public financialNatureLoading: boolean = false;
  public loadingCustomerField: boolean = false;
  public costCenterLoading: boolean = false;
  public accountingItemLoading: boolean = false;
  public valueClassLoading: boolean = false;

  public selectedSupplier: SearchCustomer | null = null;
  public movementDialogData: ICreateMovementInvoiceData | null = null;
  public bank: Bank | null = null;

  public banks: ISelectOptions[] = [];
  public financialNatures: ISelectOptions[] = [];
  public costCenters: ISelectOptions[] = [];
  public accountItems: ISelectOptions[] = [];
  public valueClasses: ISelectOptions[] = [];
  public companies: ISelectOptions[] = [];
  public suppliers: ISelectOptions[] = [];

  public readonly formatType: Function = formatType;
  public readonly formatCurrency: Function = formatCurrency;
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  public get dialogWidth(): string {
    return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
      md: '80vw',
      default: '80vw',
    });
  }

  public get companyId(): number {
    return this.createMovementData.company_id;
  }

  public get companyGroupId(): number {
    return this.$session.get('company_group_id');
  }

  @Prop({
    type: Object as () => DDAConciliationMovement,
    required: true,
  })
  readonly createMovementData!: DDAConciliationMovement;

  @Ref('GenerateAccountPayableForm') readonly generateAccountPayable!: VForm;

  @Emit('close')
  handleClose() {
    return ActionType.GENERATE_INVOICE;
  }

  @Emit()
  reload(): boolean {
    return true;
  }

  @Watch('financialNatureSearch')
  async changeFinancialNatures(search: string) {
    if (
      this.financialNatureLoading
      || this.movementDialogData!.financial_nature
    ) {
      return;
    }
    try {
      this.financialNatureLoading = true;
      await this.loadFinancialNatures(search);
    } catch (error: any) {
      const message = this.$helpers.extractAxiosErrorMessage(
        error,
        'Houve um problema ao requisitar as naturezas financeiras.',
      );

      this.$notification.error(message);
    } finally {
      this.financialNatureLoading = false;
    }
  }

  @Watch('costCenterSearch')
  async changeCostCenters(search: string) {
    if (this.costCenterLoading || this.movementDialogData!.cost_center) return;

    try {
      this.costCenterLoading = true;
      await this.loadCostCenters(search);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(
        error,
        'Houve um problema ao requisitar os centros de custo.',
      );

      this.$notification.error(message);
    } finally {
      this.costCenterLoading = false;
    }
  }

  @Watch('accountingItemSearch')
  async changeAccountingItems(search: string) {
    if (this.accountingItemLoading || this.movementDialogData!.account_item) {
      return;
    }

    try {
      this.accountingItemLoading = true;
      await this.loadAccountingItems(search);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(
        error,
        'Houve um problema ao requisitar os itens contáveis.',
      );

      this.$notification.error(message);
    } finally {
      this.accountingItemLoading = false;
    }
  }

  @Watch('valueClassSearch')
  async changeValueClass(search: string) {
    if (this.valueClassLoading || this.movementDialogData!.value_class) return;

    try {
      this.valueClassLoading = true;
      await this.loadValueClasses(search);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(
        error,
        'Houve um problema ao requisitar as classes de valor.',
      );

      this.$notification.error(message);
    } finally {
      this.valueClassLoading = false;
    }
  }

  public async created(): Promise<void> {
    await this.loadBankAndConfig();
    await this.loadCompanies();
  }

  public async loadBankAndConfig(): Promise<void> {
    try {
      this.loading = true;
      this.movementDialogData = {
        id: this.createMovementData.id,
        financial_nature: '',
        cost_center: '',
        account_item: '',
        value_class: '',
        value: this.createMovementData.value,
        date: this.createMovementData.due_date,
        history: '',
        dda_currency_type: '',
        dda_prefix: '',
        dda_type: '',
        company_id: this.createMovementData.company_id,
        supplier_id: '',
      };

      // await this.loadBanks();
      await this.loadCompanyConfigs();
    } catch (error: any) {
      const message = this.$helpers.extractAxiosErrorMessage(
        error,
        'Houve um problema requisitar os dados de configuração das empresas.',
      );
      this.$notification.error(message);
    } finally {
      this.hasLoadedMovementData = true;
      this.loading = false;
    }
  }

  public async loadCompanyConfigs(): Promise<void> {
    const companyConfig = await this.companyConfigurationsRepository
    .getCompanyConfigurationByCompanyId(
        this.companyGroupId,
        this.createMovementData.company_id,
      );

    this.movementDialogData!.dda_currency_type = companyConfig.ddaCurrencyType;
    this.movementDialogData!.dda_prefix = companyConfig.ddaPrefix;
    this.movementDialogData!.dda_type = companyConfig.ddaType;
  }

  public async loadFinancialNatures(search?: string): Promise<void> {
    const financialNatures = await this.statementConciliationRepository.getFinancialNatures(
        this.companyId,
        search,
      );

    const moreFinancialNatures = financialNatures.map(
      ({ ed_codigo: value, ed_descric: text }) => ({
        text: `${value} - ${text}`,
        value,
      }),
    );

    this.financialNatures.push(...moreFinancialNatures);
  }

  public async loadCostCenters(search?: string): Promise<void> {
    const costCenters = await this.statementConciliationRepository.getCostCenters(
        this.companyId,
        search,
      );

    const moreCostCenters = costCenters.map(
      ({ ctt_custo: value, ctt_desc01: text }) => ({
        text: `${value} - ${text}`,
        value,
      }),
    );

    this.costCenters.push(...moreCostCenters);
  }

  public async loadAccountingItems(search?: string): Promise<void> {
    const accountItems = await this.statementConciliationRepository.getAccountingItems(
        this.companyId,
        search,
      );

    const moreAccountItems = accountItems.map(
      ({ ctd_item: value, ctd_desc01: text }) => ({
        text: `${value} - ${text}`,
        value,
      }),
    );

    this.accountItems.push(...moreAccountItems);
  }

  public async loadValueClasses(search?: string): Promise<void> {
    const valueClasses = await this.statementConciliationRepository.getValueClasses(
        this.companyId,
        search,
      );

    const moreValueClasses = valueClasses.map(
      ({ cth_clvl: value, cth_desc01: text }) => ({
        text: `${value} - ${text}`,
        value,
      }),
    );

    this.valueClasses.push(...moreValueClasses);
  }

  public async getSuppliersByCompanyId(): Promise<void> {
    if (this.loadingCustomerField) {
      return;
    }

    this.loadingCustomerField = true;

    try {
      const suppliers = await this.DDAConciliationRepository.getSuppliers(
        this.companyGroupId,
        [this.companyId],
        this.supplierSearch,
      );

      this.suppliers.push(...suppliers);
    } catch (error) {
      const errorMessage = this.$helpers.extractAxiosErrorMessage(
        error,
        'Houve um problema ao requisitar os clientes.',
      );

      this.$notification.error(errorMessage);
    } finally {
      this.loadingCustomerField = false;
    }
  }

  public async handleSave(): Promise<void> {
    if (!this.generateAccountPayable.validate()) {
      this.$notification.error('Existem campos com valores inválidos.');

      return;
    }

    this.$dialog.startLoading();

    try {
      await this.DDAConciliationRepository.generateAccountPayable(
        this.companyGroupId,
        this.companyId,
        this.movementDialogData!,
      );

      this.$notification.success('Geração do título realizada com sucesso.');
      this.handleClose();
      this.reload();
    } catch (error) {
      console.log(error);
      const errorMessage = this.$helpers.extractAxiosErrorMessage(
        error,
        'Houve um problema ao realizar a geração de adiantamento.',
      );
      this.$notification.error(errorMessage);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public handleSupplierSearch(search: string): void {
    this.supplierSearch = search;

    if (!this.supplierSearch) {
      this.suppliers = [];

      return;
    }

    this.getSuppliersByCompanyId();
  }

  async loadCompanies(): Promise<void> {
    const companies = await this.DDAConciliationRepository.getCompanies();
    this.companies = companies;
  }
}
