import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.header,"items":_vm.items,"group-by":"bank_code"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","buttonIcon":"mdi-pencil","tooltipText":"Editar"},on:{"click":function($event){return _vm.edit(item)}}})]}},{key:"group.header",fn:function(ref){
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"12"}},[_c(VIcon,{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }