




































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import { formatErrorForNotification } from '@/utils/error';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionRemove extends Vue {
  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Emit()
  close(reload = false): CloseActionData {
    return { type: ActionType.REMOVE_INVOICE, reload };
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  save(): void {
    this.handleRemoveInvoice();
  }

  async handleRemoveInvoice(): Promise<void> {
    if (this.item.origin !== 'inncash' || this.item.type !== 'FT') {
      this.$notification.warn('Essa fatura não pode ser removido pelo InnCash!');
      return;
    }

    try {
      this.$dialog.startLoading();

      const companyGroupId = this.$session.get('company_group_id');
      const { id_customer: idCustomer, company_id: company } = this.item;

      const success = await this.paymentRemittanceRepository
        .removeInvoice(companyGroupId, company, idCustomer);

      if (success) {
        this.$notification.success('Fatura removida com sucesso!');
        this.close(true);
      }
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
