
























import { defineComponent } from '@vue/composition-api';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import VMenuOptions from '@/types/VMenuOptions';
import PredictedFlowActionsEnum from '../../enums/PredictedFlowActionsEnum';
import IPredictedFlowGrouperList from '../../interfaces/groupers/IPredictedFlowGrouperList';

export default defineComponent({
  name: 'DropdownOptionsOptmized',
  props: {
    item: {
      type: Object as () => IPredictedFlowGrouperList,
      required: true,
    },
  },
  data() {
    const isOpen: boolean = false;

    return {
      isOpen,
    };
  },
  computed: {
    formattedItemsOptions(): VMenuOptions[] {
      const editableValueRegister = [];

      if (this.item.grouperConfigs?.editableValue) {
        editableValueRegister.push({
          text: 'Alterar Valor',
          value: PredictedFlowActionsEnum.change_value,
        });
      }

      if (this.item.grouperType === CashFlowTablesEnum.BANKS) {
        return [
          {
            text: 'Incluir Comentário',
            value: PredictedFlowActionsEnum.include_comment,
          },
          ...editableValueRegister,
        ];
      }

      if (this.item.level === 1) {
        return [
          {
            text: 'Gerar Remessa Bancária',
            value: PredictedFlowActionsEnum.generate_billing_remittance,
          },
        ];
      }

      if (this.item.level === 2) {
        return [
          {
            text: 'Gerar Remessa Bancária',
            value: PredictedFlowActionsEnum.generate_billing_remittance,
          },
          { text: 'Alterar Data', value: PredictedFlowActionsEnum.change_date },
        ];
      }

      const optionsBasedOnAccountType = [];

      if (this.item.grouperType === CashFlowTablesEnum.ACCOUNTS_PAYABLE) {
        optionsBasedOnAccountType.push(...[
          {
            text: 'Alterar Forma de Pagamento',
            value: PredictedFlowActionsEnum.change_payment_form,
          },
          {
          text: 'Alterar Dados Bancários',
          value: PredictedFlowActionsEnum.update_bank_data,
          },
        ]);
      } else if (this.item.grouperType === CashFlowTablesEnum.ACCOUNTS_RECEIVABLE) {
        optionsBasedOnAccountType.push({
          text: 'Alterar Forma de Recebimento',
          value: PredictedFlowActionsEnum.change_receive_form,
        });
      }

      return [
        {
          text: 'Incluir Acréscimo/Decréscimo',
          value: PredictedFlowActionsEnum.include_increase_decrease,
        },
        {
          text: 'Incluir Comentário',
          value: PredictedFlowActionsEnum.include_comment,
        },
        { text: 'Alterar Data', value: PredictedFlowActionsEnum.change_date },
        ...optionsBasedOnAccountType,
        ...editableValueRegister,
      ];
    },
    isDarkModeClass(): string {
      return this.$vuetify.theme.dark ? 'dark' : '';
    },
  },
  mounted() {
    window.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropdown(event: MouseEvent) {
      const dropdown = this.$refs.dropdown as HTMLDivElement;

      if (dropdown && !dropdown.contains(event.target as Node)) {
        this.isOpen = false;
      }
    },
    handleOptionSelected(option: string): void {
      this.$emit('selected', option);
    },
  },
});
