<template>
    <v-container>
        <v-card>
        <v-row class="mb-12">
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12" class="d-flex flex-row my-5">
                <v-img :src=companyGroup.url_image max-width="400" class="mx-auto"></v-img>		
            </v-col> 
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12" 
                class="dashboard-shortcut"
                :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
            >
                <p>Atalhos</p>
                <v-divider></v-divider>
            </v-col>
            <template v-if="!loading && items.length > 0">
                <v-col cols="12" xl="2" lg="3" md="4" sm="6" xs="6" v-for="item in items" :key="item.id">
                    <div class="d-flex flex-column ma-1 dashboard-widget" 
                        :style="changeColor(item)"
                        @click="changeRoute(item)"
                        >
                        <v-icon class="dashboard-widget__icon">{{item.icon}}</v-icon>
                        <p class="dashboard-widget__text">{{item.title}}</p>
                    </div>
                </v-col>
            </template>
        </v-row>
        <v-card-text v-show="!loading && items.length == 0" class="py-12">
            <v-row>
                <v-col class="flex-row d-flex align-center justify-center">
                    <h5 class="text-center">Não há atalhos disponíveis</h5>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text v-show="loading" class="py-12">
            <v-row>
                <v-col class="flex-row d-flex align-center">
                    <v-progress-circular indeterminate color="grey" class="mx-auto"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { bus } from '@/main';
import MenuRepository from '@/repositories/MenuRepository';
import { mapState } from 'vuex';

export default {
    name: "Docker",
    components: {},
    props: [],
    data: () => ({
        items: [],
        notAllowedItens: [1],
        companyGroup: [],
        loading: true,
        colors: {
            dark: {
            },
            light: {
            }
        }
    }),
    created () {
        this.loadAccess()
    },
    computed: {
        ...mapState('authentication', ['currentModule']),
    },
    methods: {
        changeRoute(item) {
            this.$router.replace({ name: item.routeName });
            bus.$emit('update_drawer');
        },
        async loadAccess() {
			let session = this.$session.getAll()

    		this.companyGroup.id = session.company_group_id;
            this.companyGroup.url_image = session.company_group_image;

			try {
                const menus = await new MenuRepository().getByModuleId(this.currentModule.id);
				this.items = menus.filter(
                    (menu) => menu.routeName != 'dashboard'
                    && menu.children.length == 0 
                    && menu.routeName != null
                );
			} catch (error) {
				console.error(error);
			} finally {
                this.loading = false;
            }
        },
		serializeData(data) {
			const arrayOfData = Object.values(data);
            const alreadyDefinedId = [];

            const serializedData = arrayOfData.filter((access) => {
                if (alreadyDefinedId.includes(access.id)
                    || (access.children && access.children.length)
                    || this.notAllowedItens.includes(access.id)
                ) {
                    return false;
                } else {
                    alreadyDefinedId.push(access.id);
                    return true;
                }
            });

			return serializedData;
		},
        changeColor: function (value) {
            let isDarkTheme = this.$vuetify.theme.dark;

            if(isDarkTheme) {
                return `color: ${this.colors.dark[value.id]}`;
            } else {
                return `color: ${this.colors.light[value.id]}`;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboard-widget{
    margin: -7px;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;

    &__icon {
        font-size: 4rem !important;
        color: inherit !important;
        padding-top: 5%;
    }

    &__text{
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: inherit !important;
        padding-top: 5%;
        margin-bottom: 2% !important;
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 14px 14px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
}

.dashboard-shortcut {
    padding: 0 6.5%;

    p {
        text-transform: uppercase;
        font-size: 12px;
        margin: 0 !important;
        color: #00000087;
    }

}

.theme--dark .dashboard-shortcut{
    p { 
        color: #FFFFFF87 !important;
    }
}
</style>