import { render, staticRenderFns } from "./ddafiles.vue?vue&type=template&id=db3a0970&scoped=true"
import script from "./ddafiles.vue?vue&type=script&lang=js"
export * from "./ddafiles.vue?vue&type=script&lang=js"
import style0 from "./ddafiles.vue?vue&type=style&index=0&id=db3a0970&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db3a0970",
  null
  
)

export default component.exports