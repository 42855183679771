export default class BillingRemittanceAccountReceivableLog {
  constructor(
    public user_name: string,
    public date_time: string,
    public description: string,
    public time_stamp: string,
  ) {}

  static make(data: any): BillingRemittanceAccountReceivableLog {
    return new BillingRemittanceAccountReceivableLog(
      data.user_name,
      data.date_time,
      data.description,
      data.time_stamp,
    );
  }
}
