







































import Vue from 'vue';
import Component from 'vue-class-component';
import BillingMethod from '@/domain/models/BillingMethod';
import ListAddButton from '@/components/buttons/ListAddButton.vue';
import BillingMethodRepository from '@/repositories/BillingMethodRepository';
import ListTable from './components/ListTable.vue';

@Component({
  components: {
    ListAddButton,
    ListTable,
  },
})
export default class BillingMethodList extends Vue {
  public loading: boolean = false;
  public search: string = '';
  public items: BillingMethod[] = [];
  readonly billingMethodRepository: BillingMethodRepository = new BillingMethodRepository();

  mounted() {
    this.loadBillingMethodList();
  }

  async loadBillingMethodList() {
    try {
      this.loading = true;

      const groupCompanyId = this.$session.get('company_group_id');
      const result = await this.billingMethodRepository.getAll(groupCompanyId);

      this.items = this.items.concat(result);
    } catch (error) {
      this.$notification.error(error);
    } finally {
      this.loading = false;
    }
  }

  handlerCreateBillingMethod() {
    this.$router.push({ name: 'BillingMethodCreate' });
  }
}
