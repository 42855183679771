enum PredictedFlowActionsEnum {
  include_increase_decrease = 'include_increase_decrease',
  include_comment = 'include_comment',
  change_value = 'change_value',
  change_date = 'change_date',
  change_payment_form = 'change_payment_form',
  change_receive_form = 'change_receive_form',
  generate_billing_remittance = 'generate_billing_remittance',
  show_account = 'show_account',
  update_bank_data = 'update_bank_data'
}

export default PredictedFlowActionsEnum;
