import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":"","close-on-click":"","bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, onMenu, onTooltip)),[_c(VIcon,{attrs:{"dense":""}},[_vm._v(" mdi-menu ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opções")])])]}}])},[_c(VList,[(_vm.canEdit || (_vm.canShow && _vm.isBarcode))?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.EDIT)}}},[_c(VListItemTitle,[_vm._v(" Editar ")])],1):_vm._e(),(_vm.canEdit && _vm.item.origin === 'ERP')?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.ADD_INCREASE_OR_DECREASE)}}},[_c(VListItemTitle,[_vm._v(" Incluir Acréscimo/Decréscimo ")])],1):_vm._e(),(_vm.canEdit)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.ADD_COMMENT)}}},[_c(VListItemTitle,[_vm._v(" Incluir Comentário ")])],1):_vm._e(),_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.VIEW_DETAILS)}}},[_c(VListItemTitle,[_vm._v(" Visualizar Detalhes ")])],1),(_vm.canEdit)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.CHANGE_METHOD)}}},[_c(VListItemTitle,[_vm._v(" Alterar Forma de Pagamento ")])],1):_vm._e(),(_vm.canApproveOrReprove)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.APPROVE)}}},[_c(VListItemTitle,[_vm._v(" Aprovar ")])],1):_vm._e(),(_vm.canApproveOrReprove)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.DISAPPROVE)}}},[_c(VListItemTitle,[_vm._v(" Reprovar ")])],1):_vm._e(),(_vm.hasBordero)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.REMOVE)}}},[_c(VListItemTitle,[_vm._v(" Remover do Borderô ")])],1):_vm._e(),(_vm.isInvoice)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.REMOVE_INVOICE)}}},[_c(VListItemTitle,[_vm._v(" Excluir Fatura ")])],1):_vm._e(),(_vm.isInvoiceOfInncash)?_c(VListItem,{on:{"click":function($event){return _vm.action(_vm.Action.VIEW_INVOICE)}}},[_c(VListItemTitle,[_vm._v(" Visualizar Fatura ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }