















































































































































































































































































































import {
  Vue,
  Component,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { toCurrency } from '@/utils/index';
import SortHelper from '@/helpers/SortHelper';
import StringHelper from '@/helpers/StringHelper';
import NumberHelper from '@/helpers/NumberHelper';
import SettleBankReturnResumeTableRowTemplate from '@/views/CnabRemittanceGenerated/components/SettleBankReturnResumeTableRowTemplate.vue';
import SettledBankRepository from '@/repositories/SettledBankRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import SettleBankAccount from '@/domain/models/SettleBankAccount';
import ISettledBankAccountTransaction from '@/views/CnabRemittanceGenerated/interfaces/ISettledBankAccountTransaction';
import ITransactionBankAccountInfo from '@/views/CnabRemittanceGenerated/interfaces/ITransactionBankAccountInfo';
import AvailableSortTypesOnSettledBankReturnResumeDataTable from '@/views/CnabRemittanceGenerated/types/AvailableSortTypesOnSettledBankReturnResumeDataTable';
import SortDataType from '@/helpers/types/SortDataType';
import ISingularTransaction from '@/views/CnabRemittanceGenerated/interfaces/ISingularTransaction';
import ICustomHeaderTable from '@/views/CnabRemittanceGenerated/interfaces/ICustomHeaderTable';

@Component({
  components: { SettleBankReturnResumeTableRowTemplate },
})
export default class SettledBankReturnScreen extends Vue {
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly settledBankRepository: SettledBankRepository = new SettledBankRepository();

  public settledBanksReturn: SettleBankAccount|null = null;

  public hasTriedToRequestTheReturns: boolean = false;

  public headers: ICustomHeaderTable[] = [
    {
      text: '',
      value: 'action',
      sortable: false,
    },
    {
      text: 'Status',
      value: 'transactionStatus',
      sortable: true,
    },
    {
      text: 'Título',
      value: 'title',
      sortable: false,
    },
    {
      text: 'Valor Título',
      value: 'titlePaymentReceiptValue',
      sortable: true,
    },
    {
      text: 'Juros',
      value: 'titlePaymentReceiptInterest',
      sortable: true,
    },
    {
      text: 'Desconto',
      value: 'titlePaymentReceiptDiscount',
      sortable: true,
    },
    {
      text: 'Valor Pago',
      value: 'amountPaidValue',
      sortable: true,
    },
    {
      text: 'Data Inclusão',
      value: 'inclusionDate',
      sortable: true,
    },
    {
      text: 'Data Baixa',
      value: 'lowDate',
      sortable: true,
    },
    {
      text: 'Ocorrência',
      value: 'occurrence',
      sortable: true,
    },
    {
      text: 'Observação',
      value: 'observation',
      sortable: true,
    },
  ];

  public get getTypeText(): string {
    if (this.settledBanksReturn === null) {
      return '';
    }

    return {
      0: 'Retorno Bancário de Pagamento',
      1: 'Retorno Bancário de Cobrança',
    }[this.settledBanksReturn.type];
  }

  public get getTransactionBankAccountInfo(): ITransactionBankAccountInfo {
    const defaultBankData = {
      bankCode: '',
      bankAgency: '',
      bankAccount: '',
    };

    if (this.settledBanksReturn === null || this.settledBanksReturn.transactions.length < 1) {
      return defaultBankData;
    }

    const {
      bankCode,
      bankAgency,
      bankAgencyDigit,
      bankAccount,
      bankAccountDigit,
    } = this.settledBanksReturn.transactions[0].inclusion.transactionData;

    const definedBankAgencyDigit = StringHelper.isAlphanumeric(bankAgencyDigit ?? '') ? ` - ${bankAgencyDigit}` : '';
    const definedBankAccountDigit = StringHelper.isAlphanumeric(bankAccountDigit ?? '') ? ` - ${bankAccountDigit}` : '';

    return {
      bankCode,
      bankAgency: `${bankAgency}${definedBankAgencyDigit}`,
      bankAccount: `${bankAccount}${definedBankAccountDigit}`,
    };
  }

  public get getGeneralTransactions(): ISettledBankAccountTransaction[] {
    return [
      ...this.getAllFailedTransactions,
      ...this.getTotalScheduled,
    ];
  }

  public get getAllFailedTransactions(): ISettledBankAccountTransaction[] {
    const failedTransactions: ISettledBankAccountTransaction[] = [];

    for (let i = 0; i < this.settledBanksReturn!.transactions.length; i += 1) {
      const { inclusion, occurrence } = this.settledBanksReturn!.transactions[i];

      const inclusionFailed = (
        inclusion.transactionData.transactionStatus === 'fail'
        && !['BD', '00'].includes(inclusion.transactionData.bankOccurrence)
      );

      if (inclusionFailed) {
        failedTransactions.push(inclusion.transactionData);
      }

      const occurrenceFailed = (
        occurrence
        && occurrence.transactionData.transactionStatus === 'fail'
        && !['BD', '00'].includes(occurrence.transactionData.bankOccurrence)
      );

      if (occurrenceFailed) {
        failedTransactions.push(occurrence!.transactionData);
      }
    }

    return failedTransactions;
  }

  public get getTotalScheduled(): ISettledBankAccountTransaction[] {
    return this.settledBanksReturn!.transactions
      .filter(({ inclusion }) => inclusion.transactionData.bankOccurrence === 'BD')
      .map(({ inclusion }) => inclusion.transactionData);
  }

  public get getTotalSettled(): ISettledBankAccountTransaction[] {
    return this.settledBanksReturn!.transactions
      .filter(({ occurrence }) => occurrence && occurrence.transactionData.bankOccurrence === '00')
      .map(({ occurrence }) => occurrence!.transactionData);
  }

  public get settleBankReturnRowClass(): string {
    if (this.$vuetify.theme.dark) {
      return 'settle-bank-return-row-dark';
    }

    return 'settle-bank-return-row';
  }

  public async created(): Promise<void> {
    await this.getSettledBankReturn();
  }

  public async getSettledBankReturn(): Promise<void> {
    this.$dialog.startLoading();

    try {
      this.settledBanksReturn = await this.settledBankRepository.getSettledBankReturnByRemittanceId(
        parseInt(this.authenticationModule.user.company_group_id, 10),
        parseInt(this.$route.params.remittanceId, 10),
      );
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar o retorno bancário!');

      this.$notification.error(message);
    } finally {
      this.hasTriedToRequestTheReturns = true;
      this.$dialog.stopLoading();
    }
  }

  public sumAllValuesFromTransactions(
    transactions: ISettledBankAccountTransaction[],
    applyCurrencyFormatter: boolean = true,
  ): number|string {
    const allValues: number[] = transactions
      .map(({ titlePaymentReceiptValue }) => {
        let definedValue = 0;

        if (NumberHelper.isNumeric(titlePaymentReceiptValue)) {
          definedValue = parseFloat(titlePaymentReceiptValue);
        }

        return definedValue;
      });

    if (allValues.length < 1) {
      return this.toCurrencyValues(0);
    }

    const summedValues = allValues
      .reduce((previousValue, actualValue) => previousValue + actualValue);

      if (applyCurrencyFormatter) {
      return this.toCurrencyValues(summedValues);
    }

    return summedValues;
  }

  public toCurrencyValues(value: string|number): string {
    return toCurrency(value, {}, true);
  }

  public handleBackToCnabList(): void {
    this.$router.push({ name: 'cnabremittancegenerated' });
  }

  public getIconBasedOnSort(sort: 'desc' | 'asc' | undefined): string {
    if (sort === 'desc') {
      return 'mdi-chevron-down';
    }

    if (sort === 'asc') {
      return 'mdi-chevron-up';
    }

    return 'mdi-chevron-up';
  }

  public customSort(header: ICustomHeaderTable) {
    const fieldToSort = header.value as AvailableSortTypesOnSettledBankReturnResumeDataTable;
    const newSortDirection = this.getSortDirection(header.sort);
    const isDescending = newSortDirection === 'desc';

    for (let i = 0; i < this.headers.length; i += 1) {
      this.headers[i].sort = undefined;
    }

    header.sort = newSortDirection;

    const items = this.settledBanksReturn!.transactions
      .sort(({ inclusion }, { inclusion: secondInclusion }) => {
        if (fieldToSort === undefined && isDescending === undefined) {
          return 0;
        }

        const sortMethodName = this.getSortMethodBasedOnColumnName(fieldToSort);

        if (
          !inclusion.transactionData[fieldToSort]
          || !secondInclusion.transactionData[fieldToSort]
        ) {
          return SortHelper.onInvalidValues(
            isDescending,
            inclusion.transactionData[fieldToSort],
            secondInclusion.transactionData[fieldToSort],
          );
        }

        if (isDescending) {
          return SortHelper.getSortResultBasedOnValueType(
            sortMethodName,
            secondInclusion.transactionData[fieldToSort],
            inclusion.transactionData[fieldToSort],
          );
        }

        return SortHelper.getSortResultBasedOnValueType(
          sortMethodName,
          inclusion.transactionData[fieldToSort],
          secondInclusion.transactionData[fieldToSort],
        );
      });

    return items;
  }

  public getSortDirection(direction: 'asc' | 'desc' | undefined): 'asc' | 'desc' | undefined {
    if (direction === 'asc') {
      return 'desc';
    }

    if (direction === 'desc') {
      return undefined;
    }

    return 'asc';
  }

  public getSortMethodBasedOnColumnName(fieldName: string): SortDataType {
    if (['inclusionDate', 'lowDate'].includes(fieldName)) {
      return 'date';
    }

    if (
      [
        'titlePaymentReceiptValue',
        'titlePaymentReceiptInterest',
        'titlePaymentReceiptDiscount',
        'amountPaidValue',
      ].includes(fieldName)
    ) {
      return 'financial';
    }

    return 'string';
  }

  handleShowChilds(mustShow: boolean, item: ISingularTransaction) {
    item.isOpen = mustShow;
  }

  getRowClass(index: number): string {
    const stripeStringBasedOnMode = this.$vuetify.theme.dark ? 'stripe-dark' : 'stripe';
    const stripeClass = index % 2 === 0 ? stripeStringBasedOnMode : '';

    return `${stripeClass} ${this.settleBankReturnRowClass}`;
  }

  mustShowArrowExibitionButton(item: ISingularTransaction): boolean {
    return !!(item.isInvoice && item.childs.length > 0);
  }
}
