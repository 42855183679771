import HelperMethods from './helper.methods';

const helpers = {};

// eslint-disable-next-line
helpers.install = function (Vue, options) {
	HelperMethods.$vue = Vue;

	Vue.prototype.$helpers = HelperMethods;
};

export default helpers;
