import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"pageTitle":_vm.moduleName,"routes":[
      { path: '/grupos-de-usuario', name: 'Grupos de Usuário' },
      { path: ("/grupos-de-usuario/" + _vm.groupId), name: _vm.userGroupName } ]}}),_c('page-card',{attrs:{"divider-on":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"6","lg":"2","md":"3","sm":"4","xs":"6"}},[_c(VBtn,{attrs:{"large":"","block":""},on:{"click":_vm.handleCancel}},[_vm._v(" CANCELAR ")])],1),_c(VCol,{attrs:{"cols":"6","lg":"2","md":"3","sm":"4","xs":"6"}},[_c(VBtn,{attrs:{"color":"success","large":"","block":""},on:{"click":_vm.handleSave}},[_vm._v(" SALVAR ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('page-tabs-content',{attrs:{"title":"Grupo de Usuário","tabs":_vm.tabs},scopedSlots:_vm._u([{key:"tabs",fn:function(){return [_c('menu-permissions-tab',{model:{value:(_vm.menuPermissions),callback:function ($$v) {_vm.menuPermissions=$$v},expression:"menuPermissions"}}),_c('action-permissions-tab',{model:{value:(_vm.actionPermissions),callback:function ($$v) {_vm.actionPermissions=$$v},expression:"actionPermissions"}})]},proxy:true}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }