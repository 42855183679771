import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import store from '@/stores';
import IOrderStatusOptions from '@/views/orders/interfaces/IOrderStatusOptions';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'order',
  store,
})
export default class OrderModule extends VuexModule {
  public orderNumber: string = '';
  public orderValue: number = 0;
  public higherOrderValue: number = 0;
  public orderStatus: string = '';
  public orderPaymentCondition: string = '';
  public orderObservationMessage: string = '';
  public hasOrderObservationMessageDefined: boolean = false;

  @Mutation
  public setOrderNumber(value: string): void {
    this.orderNumber = value;
  }

  @Mutation
  public setOrderValue(value: number): void {
    this.orderValue = value;
  }

  @Mutation
  public setHigherOrderValue(value: number): void {
    this.higherOrderValue = value;
  }

  @Mutation
  public setOrderStatus(status: string): void {
    this.orderStatus = status;
  }

  @Mutation
  public setOrderPaymentCondition(value: string): void {
    this.orderPaymentCondition = value;
  }

  @Mutation
  public setOrderObservationMessage(value: string): void {
    this.orderObservationMessage = value;
  }

  @Mutation
  public setHasOrderObservationMessageDefined(value: boolean): void {
    this.hasOrderObservationMessageDefined = value;
  }
}
