export default class AccountsReceivableTotalPreNegotiation {
    constructor(
        public totalValue: number,
        public totalBalance: number,
        public financialFee: number,
        public percentageFee: number,
        public totalBalanceWithFees: number,
    ) {}

    static make(data: any): AccountsReceivableTotalPreNegotiation {
        return new AccountsReceivableTotalPreNegotiation(
            data.total_value,
            data.total_balance,
            data.financial_fee,
            data.percentage_fee,
            data.total_balance_with_fees,
        );
    }
}
