import Rest from './base/Rest';

export default class CnabRemittanceService extends Rest {
	static resource = 'cnabremittancegenerated';

	generateBordero() {
		this.relationship = '/generateBorderoNumber';

		return this;
	}
}
