
































































import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { VForm } from '@/types/VForm';
import { formatErrorForNotification } from '@/utils/error';
import {
 Vue, Component, Prop, Emit, Ref, Watch,
} from 'vue-property-decorator';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionAddIncreaseOrDecrease extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Object as () => CompanyGroupConfig,
  })
  groupConfig!: CompanyGroupConfig;

  @Prop({
    type: Boolean,
  })
  readonly open!: boolean;

  @Prop({
    type: Object as () => PaymentRemittance,
  })
  readonly item!: PaymentRemittance;

  @Emit()
  close(reload = false): CloseActionData {
    return { type: ActionType.ADD_INCREASE_OR_DECREASE, reload };
  }

  @Watch('type')
  onChangeType(value: 'increase' | 'decrease') {
    if (value === 'increase') {
      this.value = this.item.increase;
    } else {
      this.value = this.item.decrease;
    }
  }
  @Watch('open')
  onChangeOpen(value: boolean) {
    if (value) {
      if (this.type === 'increase') {
        this.value = this.item.increase;
      } else {
        this.value = this.item.decrease;
      }
    } else {
      this.value = 0;
      this.loading = false;
      this.form.resetValidation();
    }
  }

  readonly paymentRemittanceRepository: PaymentRemittanceRepository =
    new PaymentRemittanceRepository();

  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  readonly required = (v: number) => v != null || 'Campo obrigatório!';
  value: number = 0;
  loading: boolean = false;
  type: 'increase' | 'decrease' | null = null;

  public options: ISelectOptions<string>[] = [
    { value: 'increase', text: 'Acréscimo' },
    { value: 'decrease', text: 'Decréscimo' },
  ];

  get isInvoice(): boolean {
    return (
      this.item && this.item.origin === 'inncash' && this.item.type === 'FT'
    );
  }

  save(): void {
    if (this.loading || !this.type) return;

    const isValidform = this.form.validate();
    if (isValidform) {
      if (this.isInvoice) {
        this.handleSaveInvoice(this.value);
      } else {
        this.handleSave(this.value);
      }
    }
  }

  async handleSave(value: number) {
    try {
      const group = this.groupConfig.id;
      this.loading = true;
      const useNCC = Boolean(this.groupConfig.use_ncc) && this.item.type === 'NCC';
      const data: Record<string, number | string> = {
        type: useNCC ? 'account_receivable' : 'account_payable',
      };

      const internalData: Record<string, number | string> = {};

      if (this.type === 'increase') {
        data.added_value = value;
        data.addition_balance = value;
        internalData.field = 'e2_acresc';
        internalData.value = value;
      } else {
        data.decrease_value = value;
        data.decrease_balance = value;
        internalData.field = 'e2_decresc';
        internalData.value = value;
      }

      const params = {
        id: this.item.id_customer,
        data,
        event: this.type,
      };

      const response = await this.paymentRemittanceRepository.updateERP(
        group,
        this.item.company_id,
        params,
      );

      if (response.error) {
        throw new Error('Ocorreu um erro na inclusão do acréscimo/decréscimo!');
      }

      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'CP',
          id: this.item.id,
          ...internalData,
        },
      );

      this.$notification.success(
        'Atualização de acréscimo/decréscimo realizada com sucesso!',
      );
      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
  async handleSaveInvoice(value: number) {
    try {
      const groupId = this.groupConfig.id;
      const companyId = this.item.company_id;
      const isIncrease = this.type === 'increase';
      this.loading = true;

      const data: Record<string, number | string | null> = {};

      data.type = this.type;

      if (isIncrease) {
        data.invoice_value_addition = value;
      } else {
        data.invoice_decrease_value = value;
      }

      await this.paymentRemittanceRepository.changeInvoice(
        groupId,
        companyId,
        this.item.id_customer,
        data,
      );

      this.$notification.success('Dados alterados com sucesso!');
      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
