

































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import CreditTotalizersRepository from '@/repositories/CreditTotalizersRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import CreditTotalizerList from '@/domain/models/CreditTotalizerList';
import CompanyRepository from '@/repositories/CompanyRepository';

@Component
export default class CreditTotalizersList extends Vue {
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly creditTotalizersRepository:
    CreditTotalizersRepository = new CreditTotalizersRepository();
  private readonly companyRepository:
    CompanyRepository = new CompanyRepository();

  public loadingTable: boolean = false;

  public search: string = '';
  public selectedStatus: string = 'all';

  public selectedCompany: number|null = null;

  public companyOptions: ISelectOptions[] = [];
  public statusOptions: ISelectOptions[] = [
    { text: 'Todos', value: 'all' },
    { text: 'Ativos', value: 'active' },
    { text: 'Inativos', value: 'inactive' },
  ];
  public headers: IVDataTableHeader[] = [
    {
      text: 'Status',
      value: 'active',
      width: '5%',
    },
    {
      text: 'Tipo de execução',
      value: 'executionTypeText',
      width: '7%',
    },
    {
      text: 'Nome',
      value: 'name',
      width: '20%',
    },
    {
      text: 'Descrição',
      value: 'description',
      width: '30%',
    },
    {
      text: '',
      value: 'actions',
      width: '2%',
    },
  ];
  public totalizers: CreditTotalizerList[] = [];

  public get getFilteredTotalizers(): CreditTotalizerList[] {
    if (this.selectedStatus !== 'all') {
      return this.totalizers.filter(({ active }) => active === this.selectedStatus);
    }

    return this.totalizers;
  }

  public created(): void {
    this.getCompanies();
  }

  public async getCompanies(): Promise<void> {
    this.$dialog.startLoading();

    try {
      const companies = await this.companyRepository.getCompaniesByLoggedUser(
        parseInt(this.authenticationModule.user.company_group_id, 10),
      );

      this.companyOptions.push(...companies.map(({ fantasyName, id }) => ({
        text: fantasyName,
        value: id,
      })));
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as empresas.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getTotalizersByCompanyId(companyId: number): Promise<void> {
    this.$dialog.startLoading();

    try {
      const data = await this.creditTotalizersRepository
        .getTotalizersFromCompanyIds(
          parseInt(this.authenticationModule.user.company_group_id, 10),
          [companyId],
          'list',
        );

      this.totalizers = data.map((totalizer) => ({
        ...totalizer,
        executionTypeText: totalizer.executionType === 'query'
          ? 'Query' : 'Código',
      }));
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar os totalizadores.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getChipColor(status: 'active' | 'inactive'): string {
    return {
      active: 'success',
      inactive: 'default',
    }[status]!;
  }

  public getStatusText(status: 'active' | 'inactive'): string {
    return {
      active: 'Ativo',
      inactive: 'Inativo',
    }[status];
  }

  public async removeTotalizer({ id }: CreditTotalizerList): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.creditTotalizersRepository.removeTotalizer(
          this.authenticationModule.user.company_group_id,
          this.selectedCompany!,
          id,
      );

      this.$notification.success('Totalizador de crédito removido com sucesso!');
      this.getTotalizersByCompanyId(this.selectedCompany!);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao remover o totalizador de crédito.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public handleSearch(search: string): void {
    this.search = search;
  }

  public handleAddTotalizer(): void {
    this.$router.push('totalizadores-de-credito/salvar');
  }

  public handleRowClick({ id }: CreditTotalizerList): void {
    this.$router.push(`totalizadores-de-credito/salvar/${id}/${this.selectedCompany!}`);
  }

  public async handleSelectCompany(selectedCompany: number): Promise<void> {
    this.getTotalizersByCompanyId(selectedCompany);
  }
}
