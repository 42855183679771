
























































































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import ConfirmDeleteNewsDialog from '@/views/news/components/ConfirmDeleteNewsDialog.vue';
import { DataOptions } from 'vuetify';
import NewsRepository from '@/repositories/NewsRepository';
import StatusChip from '@/components/tables/StatusChip.vue';
import DataTableHelper from '@/helpers/DataTableHelper';
import { formatToSpecificFormat, dateTimeFormat, ptBrDateTimeFormat } from '@/utils/date';
import NewsListModel from '@/domain/models/NewsList';
import ISortAndDirectionDataTable from '@/helpers/interfaces/ISortAndDirectionDataTable';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import IStatusObject from '@/components/tables/interfaces/StatusObject';

@Component({
  components: {
    StatusChip,
    ConfirmDeleteNewsDialog,
  },
})
export default class NewsList extends Vue {
  private readonly newsRepository: NewsRepository = new NewsRepository();

  loading: boolean = false;
  showRemoveNewsConfirmationDialog: boolean = false;

  search: string = '';

  totalItems: number = 0;
  selectedNewsToRemove: number|null = null;

  readonly dataTableOptions: DataOptions = DataTableHelper.getDefaultDataTableOptions();
  readonly footerOptions: DataTableFooterOptions = DataTableHelper.getDefaultFooterProps();

  newsList: NewsListModel[] = [];
  headers: IVDataTableHeader[] = [
    {
      text: 'Status',
      value: 'status',
      sortable: false,
      width: '10%',
    },
    {
      text: 'Título',
      value: 'title',
      width: '20%',
    },
    {
      text: 'Texto',
      value: 'text',
      width: '34%',
    },
    {
      text: 'Data Inicial',
      value: 'startDateTime',
      width: '13%',
    },
    {
      text: 'Data Final',
      value: 'finalDateTime',
      width: '13%',
    },
    {
      text: '',
      value: 'actions',
      width: '5%',
      sortable: false,
    },
  ];
  availableStatus: IStatusObject[] = [
    {
      color: 'success',
      label: 'Ativo',
      value: 1,
    },
    {
      color: 'error',
      label: 'Desativado',
      value: 0,
    },
  ];

  public get actualSortAndDirection(): ISortAndDirectionDataTable {
    return {
      sort: this.dataTableOptions.sortBy[0],
      direction: this.dataTableOptions.sortDesc[0],
    };
  }

  @Watch('dataTableOptions')
  onDataTableChange() {
    this.getPaginatedNews();
  }

  async getPaginatedNews(): Promise<void> {
    try {
      this.loading = true;

      const { sort, direction } = this.actualSortAndDirection;
      const { data, total } = await this.newsRepository.getPaginatedNews({
        filter: { search: this.search },
        sort: DataTableHelper.formatTableOrdenationColumn(direction, sort),
        page: this.dataTableOptions.page,
        items_per_page: this.dataTableOptions.itemsPerPage,
      });

      this.newsList = data;
      this.totalItems = total;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as notícias');
    } finally {
      this.loading = false;
    }
  }

  parseDateToBrFormat(date: string): string {
    return formatToSpecificFormat(date, ptBrDateTimeFormat, dateTimeFormat);
  }

  handleRemoveNewsClick({ id }: NewsListModel): void {
    this.showRemoveNewsConfirmationDialog = true;
    this.selectedNewsToRemove = id;
  }

  public handleSearch(search: string): void {
      this.search = search;
      this.getPaginatedNews();
    }

  handleCreate(): void {
    this.$router.push({ name: 'InsertNews' });
  }

  handleCloseConfirmDeleteNewsDialog(shouldList: boolean): void {
    this.showRemoveNewsConfirmationDialog = false;
    this.selectedNewsToRemove = null;

    if (shouldList) {
      this.getPaginatedNews();
    }
  }

  goToSingularNews({ id }: NewsListModel): void {
    this.$router.push({ name: 'UpdateNews', params: { id: String(id) } });
  }
}
