<template>
  <v-slide-y-reverse-transition>
    <v-container fluid>
      <v-row class="mx-n6">
        <v-col xs="12" md="3">
          <date-select
            :disabledDates="{ from: parseDate(searchData.issueDate.final) }"
            :value="searchData.issueDate.initial"
            label="Dt. Emissão Inicial"
            id="emissionStartDate"
            @change="setSearchData({ issueDate: { ...searchData.issueDate, initial: $event } })"
          />
        </v-col>
        <v-col xs="12" md="3">
          <date-select
            :disabledDates="{ to: parseDate(searchData.issueDate.initial) }"
            :value="searchData.issueDate.final"
            label="Dt. Emissão Final"
            id="emissionEndDate"
            @change="setSearchData({ issueDate: { ...searchData.issueDate, final: $event } })"
          />
        </v-col>
        <v-col xs="12" md="3">
          <date-select
            :disabledDates="{ from: parseDate(searchData.dueDate.final) }"
            :value="searchData.dueDate.initial"
            label="Dt. Vencimento Inicial"
            id="dueDateStartDate"
            @change="setSearchData({ dueDate: { ...searchData.dueDate, initial: $event } })"
          />
        </v-col>
        <v-col xs="12" md="3">
          <date-select
            :disabledDates="{ to: parseDate(searchData.dueDate.initial) }"
            :value="searchData.dueDate.final"
            label="Dt. Vencimento Final"
            id="dueDateEndDate"
            @change="setSearchData({ dueDate: { ...searchData.dueDate, final: $event } })"
          />
        </v-col>
      </v-row>
      <v-row class="mx-n6">
        <v-col xs="12" md="6">
          <div class="form-group">
            <label>Empresa</label>
            <select
              class="form-control select2 hide"
              field-name="Empresa"
              id="company_id"
              name="company_id"
              style="width: 100%;"
            >
              <option value="">Selecione...</option>
              <option v-for="company in companys" :value="company.id" v-bind:key="company.id">{{
                company.name
              }}</option>
            </select>
          </div>
        </v-col>

        <v-col xs="12" md="6">
          <div class="form-group">
            <label>Valor</label>
            <input
              id="value"
              type="text"
              class="form-control m-input text-right"
              placeholder="Valor"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="mx-n6">
        <v-col xs="12">
          <div class="form-group">
            <label>Fornecedor</label>
            <select
              class="form-control select2 hide"
              field-name="Fornecedor"
              id="registration_number"
              name="registration_number"
              style="width: 100%;"
              multiple="multiple"
            >
            </select>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-slide-y-reverse-transition>
</template>

<script>
import DateSelect from "../../../src/components/fields/DateSelect.vue";
import { API_BASE } from '@config/api'
/* eslint-disable no-undef */

export default {
  name: "DDAFilesSearch",
  components: {
    DateSelect
  },
  data() {
    return {
      loading: true,
      companys: []
    };
  },
  props: {
    paramsVisibility: Boolean,
    searchData: Object,
    setSearchData: Function
  },
  created() {
    this.loadConfigs();
  },
  mounted() {
    $("#value").mask("00.000.000.000,00", {
      reverse: true,
      watchDataMask: true
    });

    $("#company_id").select2({ placeholder: "Selecione..." });
    $("#company_id").on("change", e => {
      this.setSearchData({ companyId: e.target.value });
    });

    $("#registration_number").select2({
      placeholder: "Selecione os fornecedores ou deixe em branco para todos",
      language: "pt-BR",
      ajax: {
        url: API_BASE + "provider/listAll",
        dataType: "json",
        width: "resolve",
        type: "GET",
        delay: 1500,
        headers: {
          token: this.$session.get("token")
        },
        data: function(param) {
          return {
            search: param.term || "",
            company_id: $("#company_id").val() || "",
            limit: 100
          };
        },
        processResults: result => {
          let resultado = [];
          for (let i = 0; i < result.data.length; i++) {
            resultado.push({
              id: result.data[i].a2_cgc,
              text: result.data[i].a2_nome + " (" + this.formatCnpj(result.data[i].a2_cgc) + ")"
            });
          }
          return {
            results: resultado
          };
        },
        cache: true
      }
    });
  },
  methods: {
    parseDate(date) {
      if (!date) {
        return false;
      }
      return moment(date, "DD/MM/YYYY").toDate();
    },
    loadConfigs() {
      blockPage("Carregando os dados...");
      try {
        this.loadCompanys();
      } catch (error) {
        this.$helpers.defaultCatchError(error);
      } finally {
        unblockPage();
      }
    },
    loadCompanys() {
      this.$http
        .get(`${API_BASE}company/listAllActv`, {
          headers: { token: this.$session.get("token") }
        })
        .then(result => {
          this.companys = result.body.data;
        });
    },
    loadParameter() {
      if (this.companys.length <= 0) {
        return;
      }

      this.$http
        .get(`${API_BASE}fieldparameter/getDDAFile`, {
          headers: { token: this.$session.get("token") }
        })
        .then(
          result => {
            const { data } = result.body;

            const date = moment().format('DD/MM/YYYY');

            this.setSearchData({
              issueDate: {
                initial: data.issue_date_initial ? moment(data.issue_date_initial, "YYYY-MM-DD").format("DD/MM/YYYY") : date,
                final: data.issue_date_final ? moment(data.issue_date_final, "YYYY-MM-DD").format("DD/MM/YYYY") : date
              },
              dueDate: {
                initial: data.due_date_initial ? moment(data.due_date_initial, "YYYY-MM-DD").format("DD/MM/YYYY") : date,
                final: data.due_date_final ? moment(data.due_date_final, "YYYY-MM-DD").format("DD/MM/YYYY") : date
              },
              provider: data.registration_number.map(item => item.id)  || [],
              value: data.value || '',
              company: data.company_id || null
            });

            if (data.registration_number) {
              data.registration_number.forEach(number => {
                const $option = $("<option selected></option>")
                  .val(number.id)
                  .text(number.text);
                $("#registration_number").append($option);
              });
              $("#registration_number").trigger("change");
            }

            const fields = ["value", "company_id"];

            fields.forEach(field => {
              if (data[field]) {
                $(`#${field}`)
                  .val(data[field])
                  .trigger("change");
              }
            });

            unblockPage();

            this.$emit("companies-loaded");
          },
          () => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    formatCnpj(value) {
      value = value || "";
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    }
  },
  watch: {
    companys() {
      this.$nextTick(function() {
        if (this.companys.length > 0) {
          $("#company_id")
            .val(this.companys[0].id)
            .trigger("change");
          this.loadParameter();
        }
      });
    }
  }
};
</script>
