


























































import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import AccountReceivableHistory from '@/domain/models/AccountReceivableHistory';
import ClientModule from '@/stores/modules/ClientModule';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import AccountReceivable from '@/domain/models/AccountReceivable';
import AccountsReceivableHistoryRepository from '@/repositories/AccountsReceivableHistoryRepository';
import AccountReceivableHistoryOperationTypeEnum from '@/views/clients/enums/AccountReceivableHistoryOperationTypeEnum';
import { DateTime } from 'luxon';

@Component
export default class AccountsReceivableHistoryDialog extends Mixins<ModelManagementMixin<boolean>>(
  ModelManagementMixin,
) {
  @Prop({
    type: Object,
    required: true,
    default: () => ({}),
  }) readonly accountReceivable!: AccountReceivable;

  public loading: boolean = false;

  public accountReceivableHistorys: Array<AccountReceivableHistory> = [];

  private readonly accountsReceivableHistoryRepository: AccountsReceivableHistoryRepository =
    new AccountsReceivableHistoryRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly clientModule: ClientModule = getModule(ClientModule);

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get companyId(): number {
    return this.clientModule.client.companyId!;
  }

  public get dialogWidth(): string {
    if (this.$vuetify.breakpoint.smAndDown) {
      return '95%';
    }

    if (this.$vuetify.breakpoint.md) {
      return '80%';
    }

    return '45%';
  }

  public async created(): Promise<void> {
    this.getHistory();
  }

  public async getHistory() {
    try {
      this.loading = true;

      this.accountReceivableHistorys = await this.accountsReceivableHistoryRepository
        .getByAccountReceivableId(
          this.companyGroupId,
          this.companyId,
          this.accountReceivable.accountReceivableCustomerId,
        );
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o historico do título!');
    } finally {
      this.loading = false;
    }
  }

  formatAmount(value: number) {
    const val = (value / 1).toFixed(2).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  formatDateAndHour(value: string): string {
    const date = DateTime.fromISO(value).toLocaleString();
    const hour = DateTime.fromISO(value).setZone('UTC').toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    return `${date} ${hour}`;
  }

  formatDate(value: string): string {
    const date = DateTime.fromISO(value).setZone('UTC').toLocaleString();
    return date;
  }

  handleOperationType(operationType: AccountReceivableHistoryOperationTypeEnum): string {
    let operation = '';

    // eslint-disable-next-line default-case
    switch (operationType) {
      case AccountReceivableHistoryOperationTypeEnum.INCREASE:
        operation = 'Acréscimo';
        break;
      case AccountReceivableHistoryOperationTypeEnum.DECREASE:
        operation = 'Decréscimo';
        break;
      case AccountReceivableHistoryOperationTypeEnum.CHANGE_EXPIRATION:
        operation = 'Alteração vencimento';
        break;
    }

    return operation;
  }

  public closeDialog(): void {
    this.$emit('on-close', false);
  }
}
