<template>
  <v-sheet class="mb-6 pa-2 py-md-6 px-md-8" elevation="1">
    <slot name="header">
      <div class="d-flex align-center justify-space-between">
        <div class="page-title">
          <h3 class="page-header-title">{{ title }}</h3>
        </div>
        <div class="page-buttons">
          <slot name="buttons"></slot>
        </div>
      </div>
    </slot>
  </v-sheet>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: String,
  },
};
</script>

<style>
.page-header-title {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Roboto';
}
</style>
