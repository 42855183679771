






















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ArrowExibitionButton',
  props: {
    value: Boolean,
    texts: {
      type: Object as () => { show: string; ocult: string },
      required: false,
    },
    mustShowTexts: {
      type: Boolean,
      required: false,
      default: true,
    },
    left: Boolean,
    right: Boolean,
  },
  computed: {
    getTexts(): { show: string; ocult: string } {
      if (!this.texts) {
        return {
          show: 'Ocultar Parâmetros',
          ocult: 'Exibir Parâmetros',
        };
      }

      return this.texts;
    },
  },
});
