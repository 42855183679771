











































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';

import InstructionRemittance from '@/domain/models/InstructionRemittance';
import Occurrence from '@/domain/models/Occurrence';
import EAction from '@/domain/enums/InstructionRemittanceAction';

import InstructionsRemittanceRepository from '@/repositories/InstructionsRemittanceRepository';
import { formatTitle } from '../utils';

@Component
export default class InstructionsRemittanceViewOccurrence extends Vue {
  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<InstructionRemittance>,
  }) readonly items!: Array<InstructionRemittance>;

  @Watch('open')
  async onChangeOpen(open: boolean) {
    if (open) {
      this.loadOccurrence();
    } else {
      this.loading = false;
      this.occurrences = [] as Occurrence[];
    }
  }

  @Emit()
  close(reload = false): { type: EAction, reload: boolean } {
    return {
      type: EAction.VIEW_OCCURRENCE,
      reload,
    };
  }

  readonly instructionsRemittanceRepository
    : InstructionsRemittanceRepository = new InstructionsRemittanceRepository();

  loading: boolean = false;
  occurrences: Occurrence[] = [] as Occurrence[];

  readonly formatTitle = formatTitle;

  get item(): InstructionRemittance {
    return this.items.length
      ? this.items[0]
      : {} as InstructionRemittance;
  }

  async loadOccurrence() {
    try {
      this.loading = true;

      const groupId = this.$session.get('company_group_id');
      const { id, companyId } = this.item;

      this.occurrences = await this.instructionsRemittanceRepository
        .getOccurrence(groupId, id, companyId);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
