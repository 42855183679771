


























































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Draggable from 'vuedraggable';
import CreditRulesRepository from '@/repositories/CreditRuleRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import CompanyRepository from '@/repositories/CompanyRepository';
import IDraggableChange from '@/interfaces/vdraggable/IDraggableChange';
import CreditRuleList from '@/domain/models/CreditRuleList';

@Component({
  components: {
    Draggable,
  },
})
export default class CreditRulesList extends Vue {
  private readonly creditRulesRepository:
    CreditRulesRepository = new CreditRulesRepository();
  private readonly authenticationModule:
    AuthenticationModule = getModule(AuthenticationModule);
  private readonly companyRepository:
    CompanyRepository = new CompanyRepository();

  public loadingTable: boolean = false;

  public search: string = '';
  public selectedStatus: string = 'all';

  public selectedCompany: number|null = null;

  public companyOptions: SelectOptions[] = [];
  public statusOptions: SelectOptions[] = [
    { text: 'Todos', value: 'all' },
    { text: 'Ativos', value: 'active' },
    { text: 'Inativos', value: 'inactive' },
  ];
  public headers: IVDataTableHeader[] = [
    {
      text: 'Index',
      value: 'ruleIndex',
      width: '2%',
      align: 'center',
    },
    {
      text: 'Status',
      value: 'active',
      width: '3%',
    },
    {
      text: 'Autorização',
      value: 'ruleAuthorization',
      width: '5%',
    },
    {
      text: 'Nome',
      value: 'name',
      width: '20%',
    },
    {
      text: 'Descrição',
      value: 'description',
      width: '40%',
    },
    {
      text: '',
      value: 'actions',
      width: '2%',
    },
  ];
  public creditRules: CreditRuleList[] = [];

  public get getFilteredCreditRules(): CreditRuleList[] {
    if (this.selectedStatus !== 'all') {
      return this.creditRules.filter(({ active }) => active === this.selectedStatus);
    }

    return this.creditRules;
  }

  public created(): void {
    this.getCompanies();
  }

  public async updateCreditRuleIndexOrdenation() {
    this.$dialog.startLoading();
    this.loadingTable = true;

    const correlationBerweenCreditRulesAndIndex = this.creditRules.map((creditRule) => ({
      id: creditRule.id,
      index: creditRule.ruleIndex,
    }));

    try {
      await this.creditRulesRepository.adjustCreditRulesIndexesFromCompanyId(
        this.authenticationModule.user.company_group_id,
        this.selectedCompany as number,
        correlationBerweenCreditRulesAndIndex,
      );
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao atualizar a indexação das regras de crédito.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
      this.loadingTable = false;
    }
  }

  public async getCompanies(): Promise<void> {
    this.$dialog.startLoading();

    try {
      const companies = await this.companyRepository.getCompaniesByLoggedUser(
        parseInt(this.authenticationModule.user.company_group_id, 10),
      );

      this.companyOptions.push(...companies.map(({ fantasyName, id }) => ({
        text: fantasyName,
        value: id,
      })));
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as empresas.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async getCreditRulesByCompanyId(companyId: number): Promise<void> {
    this.$dialog.startLoading();
    this.loadingTable = true;

    try {
      const creditRules = await this.creditRulesRepository.getCreditRulesByCompanyId(
        this.authenticationModule.user.company_group_id,
        companyId,
      );

      this.creditRules = creditRules.map((creditRule) => ({
        ...creditRule,
        ruleAuthorization: creditRule.ruleAuthorization === 'restricted' ? 'Restritiva' : 'Permissiva',
      }));
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as regras de crédito.');

      this.$notification.error(message);
    } finally {
      this.loadingTable = false;
      this.$dialog.stopLoading();
    }
  }

  public async removeCreditRule({ id }: CreditRuleList): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.creditRulesRepository.removeCreditRule(
          this.authenticationModule.user.company_group_id,
          this.selectedCompany!,
          id,
      );

      this.$notification.success('Regra de crédito removida com sucesso!');
      this.getCreditRulesByCompanyId(this.selectedCompany as number);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao remover a regra de crédito.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getChipColor(status: 'active' | 'inactive'): string {
    return {
      active: 'success',
      inactive: 'default',
    }[status]!;
  }

  public getStatusText(status: 'active' | 'inactive'): string {
    return {
      active: 'Ativo',
      inactive: 'Inativo',
    }[status];
  }

  public async handleSelectCompany(selectedCompany: number): Promise<void> {
    this.getCreditRulesByCompanyId(selectedCompany);
  }

  public async handleCreditRuleIndexChange(
    change: IDraggableChange<CreditRuleList>,
  ): Promise<void> {
    const { element, newIndex, oldIndex } = change.moved;

    const creditRules: CreditRuleList[] = this.creditRules.map((creditRule) => ({ ...creditRule }));

    creditRules[oldIndex] = creditRules[newIndex];
    creditRules[oldIndex].ruleIndex = oldIndex;

    creditRules[newIndex] = element;
    creditRules[newIndex].ruleIndex = newIndex;

    this.creditRules = creditRules;

    this.updateCreditRuleIndexOrdenation();
  }

  public handleSearch(search: string): void {
    this.search = search;
  }

  public handleRowClick({ id }: CreditRuleList): void {
    this.$router.push(`/regras-de-credito/salvar/${id}/${this.selectedCompany!}`);
  }
}
