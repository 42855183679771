<template>
	<div class="row">
		<div class="col-lg-3">
			<div class="m-portlet m-portlet--full-height  ">
				<div class="m-portlet__body">
					<div class="m-card-profile">
						<div class="m-card-profile__title m--hide">
							Profile
						</div>
						<div class="m-card-profile__pic">
							<div class="buttonsImage m-btn-group m-btn-group--pill btn-group btn-group-sm" role="group" aria-label="..." style="z-index: 10;position: absolute;right: 0px;top: 0px;margin-right: 40px;margin-top: 45px;display: none;">
								<button id="openModalUpload" type="button" class="m-btn btn btn-secondary" data-toggle="modal" data-target="#modal_upload_image">
									<i class="la la-pencil"></i>
								</button>
								<button type="button" class="m-btn btn btn-secondary" @click="remove">
									<i class="la la-times-circle-o"></i>
								</button>
							</div>

							<div class="m-card-profile__pic-wrapper">
								<img style="width: 130px !important;height: 130px !important;" :src="userGravatar" alt="">
							</div>
						</div>
						<div class="m-card-profile__details">
							<span class="m-card-profile__name">
								{{ userName }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-9">
			<div class="m-portlet m-portlet--full-height m-portlet--tabs  ">
				<div class="m-portlet__head">
					<div class="m-portlet__head-tools">
						<ul class="nav nav-tabs m-tabs m-tabs-line   m-tabs-line--left m-tabs-line--primary" role="tablist">
							<li class="nav-item m-tabs__item">
								<a class="nav-link m-tabs__link active" data-toggle="tab" href="#user_data" role="tab" aria-expanded="true">
									<i class="flaticon-share m--hide"></i>
									Dados cadastrais
								</a>
							</li>
							<li class="nav-item m-tabs__item">
								<a class="nav-link m-tabs__link" data-toggle="tab" href="#user_password" role="tab" aria-expanded="false">
									Senha
								</a>
							</li>
							<li class="nav-item m-tabs__item">
								<a class="nav-link m-tabs__link" data-toggle="tab" href="#user_settings" role="tab" aria-expanded="false">
									Ajustes
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="tab-content">
					<div class="tab-pane active" id="user_data" aria-expanded="true">
						<div class="m-portlet__body" id="frmUserData">
							<div class="form-group m-form__group row">
								<label for="example-text-input" class="col-3 col-form-label">
									Nome Completo
								</label>
								<div class="col-7">
									<input class="form-control m-input" id="name" name="name" type="text" :value="userName">
								</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="example-text-input" class="col-3 col-form-label">
									Email
								</label>
								<div class="col-7">
									<input class="form-control m-input" id="email" name="email" type="email" :value="userEmail" disabled>
								</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="example-text-input" class="col-3 col-form-label">
									Tipo de Usuário
								</label>
								<div class="col-7">
									<input id="user_group_id" name="user_group_id" type="hidden" :value="userGroupId">
									<input class="form-control m-input" id="user_group_name" name="user_group_name" type="text" :value="userGroupName" disabled>
								</div>
							</div>
						</div>
						<div class="m-portlet__foot m-portlet__foot--fit" style="padding: 1.2rem;">
							<div class="m-form__actions">
								<div class="row">
									<div class="col-12" style="text-align: center;">
										<button id="saveUserData" type="button" class="btn btn-success m-btn m-btn--air m-btn--custom">
											Salvar Alterações
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="user_password" aria-expanded="false">
						<div class="m-portlet__body" id="frmUserPassword">
							<div class="form-group m-form__group row">
								<label for="example-text-input" class="col-3 col-form-label">
									Senha Atual
								</label>
								<div class="col-7">
									<input class="form-control m-input" id="old_password" name="old_password" type="password">
								</div>
							</div>
							<div class="form-group m-form__group row" style="margin-top: 50px;">
								<label for="example-text-input" class="col-3 col-form-label">
									Nova Senha
								</label>
								<div class="col-7">
									<input class="form-control m-input" id="new_password" name="new_password" type="password">
								</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="example-text-input" class="col-3 col-form-label">
									Repita a Nova Senha
								</label>
								<div class="col-7">
									<input class="form-control m-input" id="repeat_new_password" name="repeat_new_password" type="password">
								</div>
							</div>
						</div>
						<div class="m-portlet__foot m-portlet__foot--fit" style="padding: 1.2rem;">
							<div class="m-form__actions">
								<div class="row">
									<div class="col-12" style="text-align: center;">
										<button id="savePassword" type="button" class="btn btn-success m-btn m-btn--air m-btn--custom">
											Alterar Senha
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="user_settings" aria-expanded="false">
						<user-config />
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal_upload_image" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">
							Alteração de Avatar
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">
								×
							</span>
						</button>
					</div>
					<div class="modal-body">
						<form class="m-form m-form--fit m-form--label-align-right">
							<div class="m-dropzone dropzone dz-clickable" id="upload-avatar">
								<div class="m-dropzone__msg dz-message needsclick">
									<h3 class="m-dropzone__msg-title">
										Arraste a imagem ou clique aqui para escolher.
									</h3>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">
							Fechar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import UserConfig from './UserConfig.vue';
	import { API_BASE } from '@config/api'
	import { mapMutations } from "vuex";
	import { getModule } from 'vuex-module-decorators';
	import AuthenticationModule from '@/stores/modules/AuthenticationModule';

	export default {
		components: { 'user-config': UserConfig },
		data () {
			return {
				userName: this.$session.get('name'),
				userEmail: this.$session.get('email'),
				userGravatar: this.$session.get('user_gravatar'),
				userGroupId: this.$session.get('user_group_id'),
				userGroupName: this.$session.get('user_group_name'),
				modalShow: true,

				authenticationModule: getModule(AuthenticationModule),
			}
		},

		mounted: function () {
			this.loadMethods();
			this.userDataMethods();
			this.userPasswordMethods();
		},

        methods: {
			...mapMutations('authentication', ['changeUser']),
        	loadMethods: function () {
        		var self = this;

				$(document).on('mouseenter', '.m-card-profile__pic', function () {
					$(this).find(".buttonsImage").show();
				}).on('mouseleave', '.m-card-profile__pic', function () {
					$(this).find(".buttonsImage").hide();
				});

				Dropzone.autoDiscover = false;
				$("#upload-avatar").dropzone({
					url: API_BASE+'user/changeAvatar/'+self.$session.get('user_id'),
					method: "POST",
					paramName: "file", // The name that will be used to transfer the file
					maxFiles: 1,
					maxFilesize: 2, // MB
					clickable: true,
					uploadMultiple: false,
					acceptedFiles: "image/*",
					dictFileTooBig: "Arquivo muito grande",
					dictInvalidFileType: "Tipo de arquivo inválido",
					dictMaxFilesExceeded: "Só é permitido o envio de um arquivo por vez",
					headers: { 'token': self.$session.get('token') },
					success: function(file, response){
						if (!file.accepted) {
							showNotification('Atenção','Ocorreu um erro ao fazer o upload do avatar', 'danger');
						} else {
							if (response.error) {
								Dropzone.forElement("#upload-avatar").removeAllFiles();
								showNotification('Atenção',response.message, 'warning');
							} else {
								showNotification('Sucesso',"Avatar alterado!", 'success');

								self.userGravatar = response.data.newavatar;
								self.$session.set('user_gravatar', response.data.newavatar);

								const payload = {
									'user_id': self.$session.get('user_id'),
									'token': self.$session.get('token'),
									'name': self.$session.get('name'),
									'email': self.$session.get('email'),
									'status': self.$session.get('status'),
									'user_group_id': self.$session.get('user_group_id'),
									'user_group_name': self.$session.get('user_group_name'),
									'user_type': self.$session.get('user_type'),
									'company_group_id': self.$session.get('company_group_id'),
									'company_group_name': self.$session.get('company_group_name'),
									'user_gravatar': self.$session.get('user_gravatar'),
								};

								self.changeUser(payload);
							}
						}
					},
					error: function(file, response){
						if (file.status == 'error') {
							Dropzone.forElement("#upload-avatar").removeAllFiles();
							showNotification('Atenção','Erro ao enviar a imagem: ' + response, 'danger');
						}
					},
					accept: function(file, done) {
						/*console.log('accept');
						console.log(file);*/
						done(); 
					}
				});

				$("#openModalUpload").click(function(){
					Dropzone.forElement("#upload-avatar").removeAllFiles();
				});

			},

			userDataMethods: function () {
				var self = this;

				$("#saveUserData").click(function() {
					var btn = $(this);
					btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

					if ($.trim($("#name").val()) == '') {
						showNotification('Atenção', 'O nome não foi informado!', 'warning');
						btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						return false;
					} else {
						$.ajax({
							method: "POST",
							url: API_BASE + 'user/updateName/' + self.$session.get('user_id'),
	                        headers: {
	                            'token': self.$session.get('token')
	                        },
							data: {
								new_name: $("#name").val()
							}
						}).done(function(result) {
							if(result.error == false){
								showNotification('Sucesso', 'Nome alterado com sucesso!','success');

								self.$session.set('name', $("#name").val());
								self.userName =  $("#name").val();

								const payload = {
									'user_id': self.$session.get('user_id'),
									'token': self.$session.get('token'),
									'name': $("#name").val(),
									'email': self.$session.get('email'),
									'status': self.$session.get('status'),
									'user_group_id': self.$session.get('user_group_id'),
									'user_group_name': self.$session.get('user_group_name'),
									'user_type': self.$session.get('user_type'),
									'company_group_id': self.$session.get('company_group_id'),
									'company_group_name': self.$session.get('company_group_name'),
									'user_gravatar': self.$session.get('user_gravatar'),
								};

								self.changeUser(payload);
							}else{
								showNotification('Atenção',result.error, 'danger');
								btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
								return false;
							}
							btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						})
						.fail(function(error) {
							console.log(error.responseText);
							btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						});
					}
				});
			},

			userPasswordMethods: function () {
				var self = this;

				$("#savePassword").click(function() {
					var btn = $(this);

					btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

					if ($.trim($("#new_password").val()) != $.trim($("#repeat_new_password").val())) {
						showNotification('Atenção', 'A nova senha não confere!', 'warning');
						btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						return false;
					} else if ($.trim($("#new_password").val()) == '' || $.trim($("#repeat_new_password").val()) == '' || $.trim($("#old_password").val()) == '') {
							showNotification('Atenção', 'Alguns campos não foram preenchidos!', 'warning');
							btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
							return false;
					} else {
						const userId = self.authenticationModule.user.user_id;

						$.ajax({
							method: "POST",
							url: `${API_BASE}user/checkPassword/${userId}`,
	                        headers: {
	                            'token': self.$session.get('token')
	                        },
							data: {
								password: $("#old_password").val()
							}
						}).done(function(result) {
							if(result.error == false){
								$.ajax({
									method: "POST",
									url: `${API_BASE}user/updatePassword/${userId}`,
			                        headers: {
			                            'token': self.$session.get('token')
			                        },
									data: {
										new_password: $("#new_password").val()
									}
								}).done(function(result) {
									if(result.error == false){
										showNotification('Sucesso', 'Senha alterada com sucesso!','success');
									} else {
											showNotification('Atenção',result.message, 'danger');
											btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
											return false;
									}
									btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
								})
								.fail(function(error){
									console.log(error.responseText);
									btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
								});
							} else {
									showNotification('Atenção',result.message, 'danger');
									btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
									return false;
							}
							btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						}).fail(function(error){
								console.log(error.responseText);
								btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
						});
					}
				});
			},
			remove() {
        		blockPage('Excluindo imagem...');
				const userId = this.$session.get('user_id');

				this.$http.get(this.$store.state.API_BASE+'user/removeAvatar/'+userId, {headers: {'token': this.$session.get('token')}})
					.then((result) => {
						if (result.body.error == false) {
							const response = result.body.data.user_default_avatar;
							const defaultAvatar = this.$store.state.API_BASE+response;
							this.userGravatar = defaultAvatar;
							this.$session.set('user_gravatar', defaultAvatar);

							const payload = {
								'user_id': this.$session.get('user_id'),
								'token': this.$session.get('token'),
								'name': this.$session.get('name'),
								'email': this.$session.get('email'),
								'status': this.$session.get('status'),
								'user_group_id': this.$session.get('user_group_id'),
								'user_group_name': this.$session.get('user_group_name'),
								'user_type': this.$session.get('user_type'),
								'company_group_id': this.$session.get('company_group_id'),
								'company_group_name': this.$session.get('company_group_name'),
								'user_gravatar': this.$session.get('user_gravatar'),
							};

							this.$store.dispatch('changeUser', payload);
							unblockPage();
							showNotification('Sucesso',"Imagem excluída!", 'success');

						} else {
							unblockPage();
							showNotification("Atenção",result.body.message,"danger");
							return false;
						}							
					},(error) => {
						unblockPage();
						showNotification('Atenção', 'Oops! Ocorreu um erro ao tentar excluir a imagem.', 'danger');
						console.error(error);
					}
				);
			},
		}
	}
</script>

<style>
.theme--dark .m-dropzone__msg-title {
	color: white !important;
	margin: 0 0 5px 0;
	padding: 0;
	font-weight: 400;
	font-size: 1.1rem;
}

</style>