import Repository from '@/repositories/base/Repository';
import OperatorDistribution from '@/domain/models/OperatorDistribution';
import ISendOperatorDistributionParams from '@/repositories/parameters/ISendOperatorDistributionParams';
import OperatorDistributionList from '@/domain/models/OperatorDistributionList';
import OperatorUser from '@/domain/models/OperatorUser';
import IOptions from '@/domain/interfaces/ISelectOptions';
import Company from '@/domain/models/Company';

export default class OperatorDistributionRepository extends Repository {
  public async getOperatorDistributions(
    companyGroupId: number,
    companyIds: number[],
    search?: string,
  ): Promise<Array<OperatorDistributionList>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/operator-distributions`,
      {
        params: { search },
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    if (this.isStatusOK(status)) {
      return response.data.map(OperatorDistributionList.make);
    }

    throw new Error('Ops, algo inesperado ocorreu durante a listagem das distribuições de operadores!');
  }

  public async getOperatorDistribution(
    companyGroupId: number,
    companyIds: number[],
    id: number,
  ): Promise<OperatorDistribution> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/operator-distributions/${id}`,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    if (this.isStatusOK(status)) {
      return OperatorDistribution.make(response.data);
    }

    throw new Error('Ops, algo inesperado ocorreu na obtenção da distribuição do operador!');
  }

  public async getBillingManagerUsers(
    companyGroupId: number,
  ): Promise<OperatorUser[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/users/operator-distributions`);

    return response.data.map(OperatorUser.make);
  }

  public async sendOperatorDistribution(
    companyGroupId: number,
    companyIds: number[],
    record: ISendOperatorDistributionParams,
  ): Promise<void> {
    const { status } = await this.datasource.send(
      `company-groups/${companyGroupId}/operator-distributions`,
      record,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a criação da distribuição do operador!');
    }
  }

  public async updateOperatorDistribution(
    companyGroupId: number,
    companyIds: number[],
    id: number,
    record: ISendOperatorDistributionParams,
  ): Promise<void> {
    const { status } = await this.datasource.update(
      `company-groups/${companyGroupId}/operator-distributions/${id}`,
      record,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a atualização da distribuição do operador!');
    }
  }

  public async destroyOperatorDistribution(
    companyGroupId: number,
    companyIds: number[],
    id: number,
  ): Promise<void> {
    const { status } = await this.datasource.destroy(
      `company-groups/${companyGroupId}/operator-distributions/${id}`,
      { headers: { 'company-ids': JSON.stringify(companyIds) } },
    );

    if (!this.isStatusOK(status)) {
      throw new Error('Ops, algo inesperado ocorreu durante a exclusão da distribuição do operador!');
    }
  }

  async getCompanies(group: number | string): Promise<Array<IOptions<number>>> {
    const { status, data: response } = await this.datasource.get(`company-groups/${group}/companies`);

    if (status >= 200 && status < 300) {
      const companies: Array<Company> = response.data.map(Company.make);

      const selectOptions = companies.map((company) => {
        const { id, name } = company;
        return { value: id, text: name };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das filiais do grupo.');
  }
}
