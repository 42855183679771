<template>
  <v-row>
    <v-col>
      <breadcrumbs page-title="Remessas Enviadas" />
      <v-card class="elevation-0">
        <v-card-title>
          <v-col cols="12" lg="4" md="4" sm="12" xs="12">
            <v-select
              v-model="configs.filterStatus"
              id="m_form_operation"
              filled
              hide-details
              label="Filtrar por status"
              :items="filterStatusItems"
            />
          </v-col>
          <v-col cols="12" lg="6" md="5" sm="12" xs="12">
            <div>
              <v-text-field
                filled
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar"
                v-model="configs.filterTerm"
              />
            </div>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="12" xs="12">
            <v-btn
              @click="loadCnabRemittancesGenerated()"
              class="font-weight-bold"
              x-large
              block
              depressed
              color="success"
            >
              VISUALIZAR
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <div
              class="input-group date"
              id="dateStart"
              style="border-radius: 5px"
            >
              <input
                type="text"
                class="form-control m-input text-center"
                readonly=""
                placeholder="Data inicial"
              />
              <span class="input-group-addon">
                <i class="la la-calendar-check-o"></i>
              </span>
            </div>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <div
              class="input-group date"
              id="dateEnd"
              style="border-radius: 5px"
            >
              <input
                type="text"
                class="form-control m-input text-center"
                readonly=""
                placeholder="Data final"
              />
              <span class="input-group-addon">
                <i class="la la-calendar-check-o" />
              </span>
            </div>
          </v-col>
        </v-card-title>
        <v-card-text class="pa-6 pt-0">
          <table class="table table-striped m-table">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Sequência</th>
                <th>Banco</th>
                <th>Agência</th>
                <th>Conta</th>
                <th>Nº Borderô</th>
                <th>Nº Borderô ERP</th>
                <th>Data Emissão</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cnabGenerated, index) in listData" :key="index">
                <td style="vertical-align: middle">
                  <v-chip
                    v-if="cnabGenerated.type === 0"
                    dense
                    color="error"
                    small
                  >
                    Pagar
                  </v-chip>
                  <v-chip v-else dense small color="green" text-color="white">
                    Receber
                  </v-chip>
                  <v-tooltip
                    bottom
                    v-if="cnabGenerated.automaticRemittance === 'Y'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="ml-4"
                        color="blue"
                        dense
                      >
                        mdi-refresh-auto
                      </v-icon>
                    </template>
                    <span>Gerado automaticamente</span>
                  </v-tooltip>
                </td>
                <td style="vertical-align: middle">
                  {{ cnabGenerated.fileSequency }}
                </td>
                <td style="vertical-align: middle">
                  {{ cnabGenerated.codeBank }}
                </td>
                <td style="vertical-align: middle">
                  {{ cnabGenerated.agency }}
                </td>
                <td style="vertical-align: middle">
                  {{ cnabGenerated.account }}
                </td>
                <td style="vertical-align: middle">
                  {{
                    cnabGenerated.borderoNumber != 0
                      ? cnabGenerated.borderoNumber
                      : cnabGenerated.inncashBordero
                  }}
                </td>
                <td style="vertical-align: middle">
                  {{ cnabGenerated.erpBordero }}
                </td>
                <td style="vertical-align: middle">
                  {{ cnabGenerated.createdAt | moment("DD/MM/YY HH:mm") }}
                </td>
                <td style="vertical-align: middle">
                  <button-tooltip
                    fab
                    icon
                    small
                    v-if="cnabGenerated.type === 0"
                    button-icon="la la-bank"
                    tooltip-text="Retorno Bancário"
                    @click="handleSettledBankReturnScreen(cnabGenerated)"
                  />
                </td>
                <td class="m--align-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        icon
                        v-if="cnabGenerated.status === '2'"
                        small
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        :class="
                          loading[cnabGenerated.id]
                            ? 'btn-blink'
                            : 'm-btnhover-accent'
                        "
                      >
                        <v-icon
                          v-on:click="reprocessIntegration(cnabGenerated)"
                        >
                          mdi-reload
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Reprocessar integração</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        :class="
                          loading[cnabGenerated.id]
                            ? 'btn-blink'
                            : 'm-btnhover-accent'
                        "
                      >
                        <v-icon
                          v-on:click="openDownloadBankReceipt(cnabGenerated)"
                        >
                          la la-file-pdf-o
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Baixar Comprovante</span>
                  </v-tooltip>
                  <button-tooltip
                    fab
                    icon
                    small
                    buttonIcon="la la-cloud-download"
                    tooltipText="Baixar"
                    @click="downloadFile(cnabGenerated)"
                  />
                  <button-tooltip
                    fab
                    icon
                    small
                    buttonIcon="la la-trash"
                    tooltipText="Excluir"
                    @click="showModalDelete(cnabGenerated)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <div
          class="modal fade"
          id="m_delete_cnab"
          role="dialog"
          aria-labelledby="m_delete_cnab1"
          aria-hidden="false"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="">Exclusão</h5>
              </div>
              <form class="m-form m-form--fit m-form--label-align-right">
                <div v-show="automaticRemittanceAlert" class="text-center pa-8">
                  <v-icon color="error"> mdi-alert-outline </v-icon>
                  Essa remessa foi gerada automaticamente pelo InnCash
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 style="float: left">
                        Deseja excluir o borderô Nº
                        <span style="color: red">{{
                          cnabGenerated.borderoNumber
                        }}</span
                        >?
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn m-btn--air btn-success"
                    @click="deleteCnabGenerated()"
                  >
                    Excluir
                  </button>
                  <button
                    type="button"
                    class="btn m-btn--air btn-primary"
                    @click="closeCnabGenerated()"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      cnabRemittancesGenerated: [],
      cnabGenerated: {},
      configs: {
        filterTerm: "",
        filterStatus: "",
      },
      automaticRemittanceAlert: false,
      loading: {},
      use_ncc: false,
      filterStatusItems: [
        {
          text: "Todos os tipos",
          value: "",
        },
        {
          text: "Pagar",
          value: "0",
        },
        {
          text: "Receber",
          value: "1",
        },
      ],
    };
  },
  computed: {
    listData() {
      let dataRet = this.cnabRemittancesGenerated;

      if (this.configs.filterStatus) {
        dataRet = _.filter(
          dataRet,
          (data) => data.type == this.configs.filterStatus
        );
      }

      if (this.configs.filterTerm.toLowerCase()) {
        dataRet = _.filter(
          dataRet,
          (list) =>
            list.account
              .toLowerCase()
              .indexOf(this.configs.filterTerm.toLowerCase()) >= 0 ||
            list.agency
              .toLowerCase()
              .indexOf(this.configs.filterTerm.toLowerCase()) >= 0 ||
            list.codeBank
              .toLowerCase()
              .indexOf(this.configs.filterTerm.toLowerCase()) >= 0 ||
            (list.erpBordero &&
              list.erpBordero
                .toLowerCase()
                .indexOf(this.configs.filterTerm.toLowerCase()) >= 0) ||
            list.borderoNumber
              .toLowerCase()
              .indexOf(this.configs.filterTerm.toLowerCase()) >= 0
        );
      }

      return dataRet.map((obj) => {
        let typeCNAB = 0;

        typeCNAB = obj.type;

        if (obj.type === 0) {
          typeCNAB = 0;
        }

        if (this.use_ncc === 1 && obj.e2Tipo === "NCC") {
          typeCNAB = 1;
        }

        return Object.assign({}, obj, {
          type: typeCNAB,
        });
      });
    },
  },
  mounted: function () {
    this.use_ncc = this.$session.get("use_ncc");

    let date_initial = moment().format("01/MM/YYYY");

    $("#dateStart > input").val(date_initial);
    $("#dateStart > input").datepicker({
      autoclose: true,
      format: "dd/mm/yyyy",
      todayHighlight: !0,
      orientation: "bottom left",
      defaultDate: date_initial,
      templates: {
        leftArrow: '<i class="la la-angle-left"></i>',
        rightArrow: '<i class="la la-angle-right"></i>',
      },
    });

    let date_finish = moment().format("DD/MM/YYYY");

    $("#dateEnd > input").val(date_finish);
    $("#dateEnd > input").datepicker({
      autoclose: true,
      format: "dd/mm/yyyy",
      todayHighlight: !0,
      orientation: "bottom left",
      defaultDate: date_finish,
      templates: {
        leftArrow: '<i class="la la-angle-left"></i>',
        rightArrow: '<i class="la la-angle-right"></i>',
      },
    });

    this.loadCnabRemittancesGenerated();
  },
  methods: {
    loadCnabRemittancesGenerated: function () {
      let dateStart = $("#dateStart > input").val();
      let dateEnd = $("#dateEnd > input").val();

      if ($.trim(dateStart) == "") {
        showNotification(
          "Atenção",
          "A data inicial deve ser informada",
          "warning"
        );
        return false;
      }

      if ($.trim(dateEnd) == "") {
        showNotification(
          "Atenção",
          "A data final deve ser informada",
          "warning"
        );
        return false;
      }

      blockPage("Carregando os dados...");

      const params = {
        dateStart: dateStart,
        dateEnd: dateEnd,
        status: this.configs.filterStatus,
        search: this.configs.filterTerm,
      };

      this.$http
        .get(this.$store.state.API_BASE + "cnabremittancegenerated/listAll", {
          headers: { token: this.$session.get("token") },
          params: params,
        })
        .then(
          (result) => {
            this.cnabRemittancesGenerated = result.body.data;
            unblockPage();
            if (this.cnabRemittancesGenerated.length == 0) {
              showNotification("Atenção", "Sem dados para listar", "warning");
            }
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },

    reprocessIntegration(cnab) {
      Swal.fire({
        title: "Reprocessar integração",
        html: "</br>Deseja reprocessar a integração de borderô?",
        width: "800px",
        showCancelButton: true,
        confirmButtonText: "Reprocessar integração",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "receipt-swal-btn",
          cancelButton: "receipt-swal-btn",
          denyButton: "receipt-swal-btn",
        },
        allowEscapeKey: true,
        cancelButtonColor: "rgb(48, 133, 214)",
        focusConfirm: false,
        focusDeny: false,
        focusCancel: true,
        showCloseButton: true,
      }).then(async (result) => {
        if (
          result.isDismissed == true &&
          (result.dismiss == "backdrop" || result.dismiss == "esc")
        ) {
          return false;
        }

        try {
          let url = `${
            this.$store.state.API_BASE
          }company-groups/${this.$session.get(
            "company_group_id"
          )}/remittances/${cnab.id}/reprocess`;

          await this.$http.put(
            url,
            {},
            {
              headers: {
                token: this.$session.get("token"),
                "company-ids": JSON.stringify([cnab.companyId]),
              },
            }
          );

          showNotification("Sucesso", "Integração reprocessada", "success");
        } catch (error) {
          if (error.body && error.body.message) {
            showNotification("Atenção", error.body.message, "danger");
          } else {
            console.log(error);
            showNotification(
              "Atenção",
              "Ocorreu ao reprocessar a requisição",
              "danger"
            );
          }
        } finally {
          this.loading[cnab.id] = false;
          this.$forceUpdate();
        }
      });
    },

    /**
     *
     * DOWNLOAD DE pdf
     * novas funcoes para download de remessas enviadas
     *
     */
    openDownloadBankReceipt(cnab, index) {
      Swal.fire({
        icon: "question",
        title: "Comprovante de Pagamento",
        html: "</br>Deseja baixar os comprovantes de pagamento juntos ou em páginas separadas?",
        confirmButtonText: "Comprovantes Separados",
        cancelButtonText: "Comprovantes Juntos",
        customClass: {
          confirmButton: "receipt-swal-btn",
          cancelButton: "receipt-swal-btn",
        },
        cancelButtonColor: "rgb(48, 133, 214)",
        showCancelButton: true,
        allowOutsideClick: true,
      }).then(({ value }) => {
        if (value) {
          this.downloadBankReceipt(cnab, index, value);
          //this.downloadReceipt(cnab, index, value);
        }
      });
    },
    async downloadBankReceipt(cnab, index, separateReceipts) {
      this.loading[cnab.id] = true;
      this.$forceUpdate();

      try {
        let url = `${this.$store.state.API_BASE}cnabremittancegenerated/receipt/`;

        url = url + cnab.borderoNumber;

        if (separateReceipts) {
          url = url + "/separate";
        }

        const result = await this.$http.get(url, {
          headers: { token: this.$session.get("token") },
        });

        const pdfSource = `data:application/pdf;base64,${result.data}`;

        const filename = this.getBankReceiptFilename(cnab);

        this.clickToDownload(pdfSource, filename);
      } catch (error) {
        if (error.body && error.body.message) {
          showNotification("Atenção", error.body.message, "danger");
        } else {
          console.log(error);
          showNotification(
            "Atenção",
            "Ocorreu um erro inesperado ao baixar o arquivo",
            "danger"
          );
        }
      } finally {
        this.loading[cnab.id] = false;
        this.$forceUpdate();
      }
    },
    async downloadReceipt(cnab, index, typeOfPrint) {
      blockPage("Carregando os dados...");

      const params = {
        dateStart: dateStart,
        dateEnd: dateEnd,
        status: this.configs.filterStatus,
        search: this.configs.filterTerm,
      };

      this.$http
        .get(this.$store.state.API_BASE + "cnabremittancegenerated/listAll", {
          headers: { token: this.$session.get("token") },
          params: params,
        })
        .then(
          (result) => {
            this.cnabRemittancesGenerated = result.body.data;
            unblockPage();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    /**
     *
     * DOWNLOAD DE pdf
     * novas funcoes para download de remessas enviadas
     *
     */
    openDownloadBankReceipt(cnab, index) {
      Swal.fire({
        title: "Comprovante de Pagamento",
        html: "</br>Como deseja baixar os comprovantes?",
        width: "800px",
        showCancelButton: true,
        showDenyButton: true,

        confirmButtonText: '<i class="fa fa-file-pdf-o"></i> Separados',
        cancelButtonText: '<i class="fa fa-file-pdf-o"></i>  Juntos',
        denyButtonText: '<i class="fa fa-file-archive-o"></i> Compactados',

        customClass: {
          confirmButton: "receipt-swal-btn",
          cancelButton: "receipt-swal-btn",
          denyButton: "receipt-swal-btn",
        },
        allowEscapeKey: true,
        cancelButtonColor: "rgb(48, 133, 214)",
        focusConfirm: false,
        focusDeny: false,
        focusCancel: true,
        showCloseButton: true,
      }).then((result) => {
        if (
          result.isDismissed == true &&
          (result.dismiss == "backdrop" || result.dismiss == "esc")
        ) {
          return false;
        }
        let value;
        if (result.isDismissed == true && result.dismiss == "cancel") {
          value = "together";
        } else if (result.isDismissed == false && result.value == true) {
          value = "separate";
        } else if (result.isDismissed == false && result.value == false) {
          value = "compress";
        }
        this.downloadBankReceipt(cnab, index, value);
      });
    },
    async downloadBankReceipt(cnab, index, separateReceipts) {
      this.loading[cnab.id] = true;
      this.$forceUpdate();
      let fileType = "pdf";
      try {
        let url = `${this.$store.state.API_BASE}cnabremittancegenerated/receipt/`;

        url = url + cnab.id;

        switch (separateReceipts) {
          case "together":
            break;
          case "separate":
            url = url + "/separate";
            break;
          case "compress":
            url = url + "/compress";
            fileType = "zip";
            break;
          default:
            return false;
        }
        const result = await this.$http.get(url, {
          headers: { token: this.$session.get("token") },
        });

        const pdfSource = `data:application/${fileType};base64,${result.data}`;

        url = `${this.$store.state.API_BASE}company/listOne/`;
        url = url + cnab.companyId;

        const companyData = await this.$http.get(url, {
          headers: { token: this.$session.get("token") },
        });

        let company = companyData.data.data[0];

        const filename = this.getBankReceiptFilename(cnab, company);

        this.clickToDownload(pdfSource, filename);
      } catch (error) {
        if (error.body && error.body.message) {
          showNotification("Atenção", error.body.message, "danger");
        } else {
          console.log(error);
          showNotification(
            "Atenção",
            "Ocorreu um erro inesperado ao baixar o arquivo",
            "danger"
          );
        }
      } finally {
        this.loading[cnab.id] = false;
        this.$forceUpdate();
      }
    },
    getBankReceiptFilename(cnab, company) {
      const cnpj = company.cnpj.replace(/[^\d]+/g, "");

      const emissionDate = new moment(cnab.createdAt);

      const formatedDate = emissionDate.format("DD-MM-YYYY");

      const filename = `Comprovante de Pagamento - ${cnpj} - ${cnab.borderoNumber} - ${formatedDate}`;

      return filename;
    },
    clickToDownload(file, filename) {
      const link = document.createElement("a");

      link.href = file;

      link.download = filename;

      link.click();
    },
    downloadFile: function (cnabGenerated) {
      blockPage("Carregando os dados...");
      $.ajax({
        method: "GET",
        url: this.$store.state.API_BASE + "cnabremittancegenerated/getCnabFile",
        headers: {
          token: this.$session.get("token"),
        },
        data: {
          file_id: cnabGenerated.id,
        },
      })
        .done((result) => {
          if (result.error) {
            showNotification("Atenção", result.message, "danger");
          } else {
            if (result.total_records > 0) {
              var blob = new Blob([atob(result.data)], {
                type: "application/octet-stream",
              });
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download =
                cnabGenerated.cnabConfig.fileName +
                "-" +
                moment(new Date()).format("DDMMYY") +
                "." +
                cnabGenerated.cnabConfig.extension;

              link.click();
            } else {
              showNotification("Atenção", "Sem dados para listar", "warning");
            }
          }

          unblockPage();
        })
        .fail((error) => {
          showNotification(
            "Atenção",
            "Opss!!! Não foi possível carregar os dados",
            "danger"
          );
          unblockPage();
        });
    },

    showModalDelete: function (cnabGenerated) {
      let self = this;
      checkImportBeforeSearch(self).then(
        function (result) {
          if (!result.body.payload) {
            self.showModal(cnabGenerated);
          } else {
            showNotification(
              "Atenção",
              "Existe uma importação não concluída. Aguarde até que finalize ou tente importar novamente.",
              "warning"
            );
            unblockPage();
          }
        },
        function (error) {
          showNotification(
            "Atenção",
            "Opa! Parece que algo saiu errado, tente recarregar a página",
            "danger"
          );
          unblockPage();
        }
      );
    },
    showModal: function (cnabGenerated) {
      this.cnabGenerated = cnabGenerated;
      $("#m_delete_cnab").modal("show");
      if (this.cnabGenerated.automaticRemittance === "Y") {
        this.automaticRemittanceAlert = true;
      }
    },
    deleteCnabGenerated() {
      $("#m_delete_cnab").modal("hide");
      this.automaticRemittanceAlert = false;
      blockPage("Excluindo o borderô...");

      $.ajax({
        method: "DELETE",
        url:
          this.$store.state.API_BASE +
          "cnabremittancegenerated/" +
          this.cnabGenerated.id,
        headers: {
          token: this.$session.get("token"),
        },
      })
        .done((result) => {
          const index = this.cnabRemittancesGenerated.findIndex(
            (x) => x.id === this.cnabGenerated.id
          );

          showNotification("Sucesso", "Cnab e borderô excluídos!", "success");

          this.cnabRemittancesGenerated.splice(index, 1);

          unblockPage();
        })
        .fail((error) => {
          unblockPage();
          showNotification(
            "Atenção",
            error.responseJSON.message,
            error.status == 404 ? "warning" : "danger"
          );
        });
    },
    closeCnabGenerated() {
      $("#m_delete_cnab").modal("hide");
      this.automaticRemittanceAlert = false;
    },
    handleSettledBankReturnScreen(cnabGenerated) {
      this.$router.push({
        name: "SettledBankReturnScreen",
        params: { remittanceId: cnabGenerated.id },
      });
    },
  },
};
</script>

<style scoped>
@-webkit-keyframes blinker {
  from {
    background-color: RGBA(77, 168, 208, 1);
    color: white;
  }
  to {
    background-color: RGBA(77, 168, 208, 0.2);
    color: grey;
  }
}

.btn-blink {
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}
</style>

<style>
.receipt-swal-btn {
  min-width: 200px;
  background-color: rgb(48, 133, 214);
  /*text-transform: capitalize;*/
}

.swal2-modal {
  width: 700px !important;
  min-width: 400px !important;
}
</style>
