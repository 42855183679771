<template>
  <v-data-table :headers="header" :items="items" group-by="bank_code">
    <!-- eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
			<button-tooltip
				fab
				icon
				small
				buttonIcon="mdi-pencil"
				tooltipText="Editar"
				@click="edit(item)"
			/>
    </template>

		<!-- eslint-disable-next-line -->
    <template v-slot:group.header="{ isOpen, toggle }">
      <th colspan="12">
        <v-icon @click="toggle">
          {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </th>
    </template>
  </v-data-table>
</template>

<script>

export default {
	name: 'ApiBankingConfigListTable',
	props: {
		items: Array,
	},
	data() {
		return {
			header: [
				{ text: 'Banco', value: 'bank_name' },
				{ text: 'Nome', value: 'name' },
				{ text: 'Serviço', value: 'service' },
				{ text: 'Versão', value: 'version' },
				{ text: '', value: 'actions' },
			],
		};
	},
	methods: {
		edit(item) {
			const params = {
				service: item.id,
				type: item.company_type,
				company: item.company_type_id,
			};

      this.$router.push({ name: 'APIConfigurationsEdit', params });
    },
  },
};
</script>

<style></style>
