<template>
  <v-row class="pa-5">
    <v-col class="pa-0">
      <v-card class="elevation-0 pa-1">
        <v-card-title>
          <h4 class="text-h6 font-weight-bold ml-3 mt-2">
            Alteração Dados PIX 
          </h4>
          <v-spacer />
          <v-btn 
            class="elevation-0 mr-2"
            color="disable"
            @click="close">
            <v-icon class="mr-2">
              mdi-close
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="elevation-0"
            color="info"
            :disabled="!validate()"
            @click="update">
            <v-icon dark class="mr-2">
              mdi-pencil-outline
            </v-icon>
            Alterar
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" />
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <label for="type">Tipo</label>
              <v-select
                name="type"
                id="type"
                outlined
                dense
                :items="pixOptions"
                v-model="form.type"
              />
            </v-col>
          </v-row>
          <template v-if="form.type == 1">
            <v-row>
              <v-col cols="12" lg="12" md="12" sm="12">
                <label>Chave CPF, CNPJ, Telefone, E-mail ou Aleatória</label>
                <v-text-field
                  id="key"
                  outlined
                  dense
                  required
                  maxlength="77"
                  counter="77"
                  v-model="form.key"
                  v-mask="masker"
                />
              </v-col>
            </v-row>
          </template>
          <div v-if="form.type == 2">
            <qr-code-reader :qrcode="form.qrcode" @input="form.qrcode = $event"/>
          </div>
          <template v-if="form.type == 3">
            <v-row>
              <v-col cols="12" lg="4" md="4" sm="4">
                <label>Código do Banco</label>
                <v-text-field
                    id="code"
                    required
                    outlined
                    dense
                    maxlength="3"
                    v-model="form.code"
                  />
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="4">
                <label>Agência</label>
                <v-text-field
                    id="agency"
                    required
                    outlined
                    dense
                    maxlength="5"
                    v-model="form.agency"
                  />
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="4">
                <label>Dígito Agência</label>
                <v-text-field
                    id="digAgency"
                    outlined
                    dense
                    maxlength="1"
                    v-model="form.digAgency"
                  />
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="4">
                <label>Conta Corrente</label>
                <v-text-field
                    id="account"
                    outlined
                    dense
                    maxlength="12"
                    v-model="form.account"
                  />
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="4">
                <label>Dígito Conta</label>
                <v-text-field
                    id="digAccount"
                    outlined
                    dense
                    maxlength="1"
                    v-model="form.digAccount"
                  />
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="4">
                <label>Tipo de Conta</label>
                <v-select
                name="accountType"
                id="accountType"
                outlined
                dense
                :items="accountTypeOptions"
                v-model="form.accountType"
                />
              </v-col>
            </v-row>
            <span class="update-warning">
              *Será alterado o cadastro do fornecedor no ERP.
            </span>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import RemittancePayableService from "@/services/remittance_payable.service";
import CustomModal from "../../../src/components/defaults/CustomModal.vue";
import CustomSelect from "../../../src/components/fields/CustomSelect.vue";
import QRCodeReader from "../../../src/components/defaults/QRCodeReader.vue";

export default {
  name: "PixUpdate",
  components: { CustomModal, CustomSelect, "qr-code-reader": QRCodeReader },
  props: {
    item: Object,
    companyId: Number | String,
  },
  data() {
    return {
      loading: false,
      pixKeyType: null,
      theMask: null,
      rules: {
        required: value => !!value || 'Campo Obrigatório.',
      },
      pixOptions: [
        {
          text:'Chave Pix',
          value:1,
        },
        {
          text:'QR Code',
          value:2,
        },
        {
          text:'Dados Bancários',
          value:3,
        },
      ],
      accountTypeOptions: [
        {
          text:'Conta Corrente',
          value:1
        },
        {
          text:'Conta Poupança',
          value:2
        },
      ],
      form: {
        type: 1,
        key: "",
        qrcode: "",
        code: "",
        agency: "",
        digAgency:"", 
        account: "",
        digAccount:"", 
        accountType: "",
      },
    };
  },

  mounted() {
    if (!this.item) {
      return;
    }

    this.form.type = this.item.tipo_pix || 1;
    this.form.key = this.item.A2_ZCHVPIX || "";
    this.form.qrcode = this.item.E2_ZCHVPIX || "";
    this.form.code = this.item[this.item.banco];
    this.form.agency = this.item[this.item.agencia];
    this.form.digAgency = this.item[this.item.dvage];
    this.form.digAccount = this.item[this.item.dvcta];
    this.form.accountType = this.item[this.item.tipcta];
  },

  computed: {
    masker() {
      this.pixKeyType = 0;
      let pixKey = this.form.key.replaceAll('.','').replaceAll('-', '').replaceAll('/','');
      let letter = /[^A-Za-z]+/;
      let number = /[^0-9]+/;
      let firstCharacter = pixKey.charAt(0);

      let specialCharacters = /[!@#$%^&*(),.?":{}|<>+;¨`´ªº°/~§=_¹²³£¢¬']/;

      if(!isNaN(pixKey) && pixKey.length > 0) {
        if(firstCharacter !='+' && pixKey.length > 2 && pixKey.length <= 11) {
          this.pixKeyType = 1;
        }
        if(firstCharacter !='+' && pixKey.length >= 12 && pixKey.length <= 14) {
          this.pixKeyType = 2;
        }
      }

      if(this.pixKeyType == 0) {
        this.theMask = null;

        if(pixKey.includes('@')) {
          pixKey = this.form.key.replaceAll('/','').replaceAll('-','').replaceAll(/\s+/g,'');
          this.form.key = pixKey;
          this.theMask = null;
          this.pixKeyType = 3;
        }

        if(pixKey.length >= 32  && letter.test(pixKey)  &&
          number.test(pixKey)  && !specialCharacters.test(this.form.key)) {
            pixKey = this.form.key.replaceAll('@','');
            this.form.key = pixKey;
            this.pixKeyType = 4;
        }

        if(firstCharacter =='+') {
            pixKey = this.form.key;
            this.form.key = pixKey;
            this.pixKeyType = 5;
        }
        
      }
     
      switch(this.pixKeyType) {
        case 1: 
          if(pixKey.includes('@') || pixKey.includes('/')) {
            break;
          } else {
            this.theMask = '###.###.###-##';
          };
          break;

        case 2: 
          this.theMask = '##.###.###/####-##'; 
          break;

        case 3: 
          if(pixKey.includes('@') && !pixKey.includes('.')) {
            break;
          } else {
            this.theMask = null;
          };
          break;

        case 4: 
          if (pixKey.length != 36){ 
            break;         
          }
          this.theMask = 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX';
          break;

        case 5: 
          this.theMask = '+##(##)#####-####';
          break;

        default:
          break;
      }

      return this.theMask;

    }
  },  
  methods: {
    async update() {
      this.validateUpdate();

      if(this.validateUpdate() === false) {
        return false;
      } 
      else {
        blockPage("Aguarde");
        const data = {
          id: this.item[this.form.type == 2 ? "ID" : "FORNECEDOR_ID"],
          companyId: this.item.TABLE_COMPANY_CODE,
          key_id: this.item.key_id,
          date: this.item.E2_ZDTFLUX,
          billPrefix: this.item.E2_PREFIXO,
          billNumber: this.item.E2_NUM,
          billParcel: this.item.E2_PARCELA,
          billType: this.item.E2_TIPO,
          providerBranch: this.item.E2_FILIAL,
          providerCode: this.item.E2_FORNECE,
          providerStore: this.item.E2_LOJA,
          bankCodeField: this.item.banco,
          agencyField: this.item.agencia,
          digAgencyField:this.item.dvage,
          accountField: this.item.conta,
          digAccountField:this.item.dvcta, 
          accountTypeField: this.item.tipcta,
          id_customer: this.item.id_customer,
          company_token: this.item.company_token,
          branch_token: this.item.branch_token,
          source_table: this.item.source_table,
          use_ncc:
            this.$session.get("use_ncc") === 1 && this.item.E2_TIPO === "NCC",
          ...this.form,
        };      
        if (this.form.type == 1 || this.form.type == 3) {
          data["providerBranch"] = this.item.A2_FILIAL;
        }

        const remittancePayableService = new RemittancePayableService();
        
        const response = await remittancePayableService
          .updatePixData()
          .update(data)
          .catch((e) => {
            unblockPage();
            showNotification(
              "Atenção",
              e.response.data.message || "Não foi possível atualizar os dados",
              "danger"
            );
            return false;
          });

        unblockPage();
        if (!response || response.error) {
          return;
        }

        showNotification(
          "Sucesso",
          "Dados PIX alterados com sucesso!",
          "success"
        );
        this.close();
      }
    },
    close() {
      this.$emit("close", this.form);
    },
    validateUpdate(){
      if(this.form.type === 1) {
          let pixKey = this.form.key.replaceAll('/','').replaceAll('-','').replaceAll(/\s+/g,'').replaceAll('.','');
          let letter = /[^A-Za-z]+/;
          let number = /[^0-9]+/;
          let specialCharacters = /[!#$%^&*(),?":{}|<>+;¨`´ªº°/~§=¹²³£¢¬']/;

          if(this.pixKeyType == 1){
            if(pixKey.length == 11 && !pixKey.includes('@')) {
              return true;
            }
            showNotification("Atenção", "Chave CPF inválida!","danger");
            return false;
          }

          if(this.pixKeyType == 2){
            if(pixKey.length == 14 && !pixKey.includes('@')) {
              return true;
            }
            showNotification("Atenção", "Chave CNPJ inválida!","danger");
            return false;
          }

          if(this.pixKeyType == 3) {
            pixKey = this.form.key.replaceAll('/','').replaceAll('-','');
            this.form.key = pixKey;
            if(pixKey.includes('@') && pixKey.includes('.') && !specialCharacters.test(this.form.key)) {
              return true;
            }
            showNotification("Atenção", "Chave E-mail inválida!","danger");
            return false;
          }

          if(this.pixKeyType == 4) {
            if(pixKey.length == 32  && letter.test(pixKey)  &&
              number.test(pixKey)  && !specialCharacters.test(this.form.key) && !pixKey.includes('@') && !pixKey.includes('_') && !this.form.key.includes('.')) {
              return true;
            }
            showNotification("Atenção", "Chave Aleatória inválida!","danger");
            return false;
          }

          if(this.pixKeyType == 5) {
            let pixKey = this.form.key.replaceAll('+','').replaceAll('-', '').replaceAll('(','').replaceAll(')','');
            let codCountry = pixKey.substring(0, 2);
            let ddd = pixKey.substring(2, 4);

            if(pixKey.length >= 13 && codCountry == 55 && ((ddd>=91 && ddd<=99)||
              (ddd>=81 && ddd<=89)||ddd==77||ddd==79|| 
              (ddd>=73 && ddd<=75)||ddd==71||ddd==22||
              (ddd>=61 && ddd<=69)||ddd==51||ddd==24||
              (ddd>=41 && ddd<=49)||ddd==27||ddd==28||
              (ddd>=31 && ddd<=35)||ddd==37||ddd==38||
              (ddd>=11 && ddd<=19)||(ddd>=53 && ddd<=55)|| ddd==21)){
              return true;
            }
            showNotification("Atenção", "Chave Telefone inválida!","danger");
            return false;
          }
        showNotification("Atenção", "Chave Pix inválida!","danger");
        return false;
      }
    },
    validate() {
      if (this.form.type == 1 && this.form.key) {
        return true;
      }
      if (this.form.type == 2 && this.form.qrcode) {
        return true;
      }
      if (
        this.form.type == 3 &&
        this.form.code &&
        this.form.agency &&
        this.form.account &&
        this.form.accountType
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.update-warning {
  color: #868e96;
  font-size: 1rem;
}
</style>