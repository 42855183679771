


























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SelectLimitedHeight',
  props: {
    value: {
      type: [Array],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    const model = this.value;

    return {
      model,
    };
  },
  watch: {
    model() {
      this.$emit('input', this.model);
    },
    value() {
      this.model = this.value;
    },
  },
});
