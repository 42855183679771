export default class CreditVariables {
  constructor(
    public id: number,
    public name: string,
    public companyId: number,
    public value: string,
    public active: boolean,
  ) {}

  public static make(data: any): CreditVariables {
    return new CreditVariables(
      data.id,
      data.name,
      data.companyId,
      data.value,
      data.active === 'T',
    );
  }
}
