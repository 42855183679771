













































import {
 Vue, Component, Prop, Emit,
} from 'vue-property-decorator';
import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';
import BillingRemittanceActionType from '@/domain/enums/BillingRemittanceActionType';

interface ActionData {
  option: string;
  item: BillingRemittanceAccountReceivable;
}

@Component({})
export default class BillingRemittanceListAction extends Vue {
  @Prop({
    type: Object as () => BillingRemittanceAccountReceivable,
  })
  item!: BillingRemittanceAccountReceivable;

  @Emit()
  action(option: string, item: BillingRemittanceAccountReceivable): ActionData {
    return { option, item };
  }

  readonly ActionType = BillingRemittanceActionType;

  get canClearBlock(): boolean {
    return (
      Boolean(Number(this.$session.get('user_access-clear_bank_slip_data')))
    );
  }
}
