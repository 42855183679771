export default class OrderItems {
  constructor(
    public name: string,
    public quantity: number,
    public stValue: number,
    public totalStValue: number,
    public totalValue: number,
    public unitValue: number,
  ) {}

  public static make(data: any): OrderItems {
    return new OrderItems(
      data.name,
      data.quantity,
      data.stValue,
      data.totalStValue,
      data.totalValue,
      data.unitValue,
    );
  }
}
