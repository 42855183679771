<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Configurações de DDA"/>
			<page-card dividerOn>
				<template #title>
					<v-row>
						<v-col cols="12" lg="4" md="4" sm="12" xs="12">
							<v-select 
								v-model="configs.filterStatus"
								filled
								label="Filtrar por status"
								hide-details
								:items="filterStatusItems"/>
						</v-col>
						<v-col cols="12" lg="6" md="5" sm="12" xs="12">
							<v-text-field
								filled
								hide-details
								prepend-inner-icon="mdi-magnify"
								label="Pesquisar"
								v-model="configs.filterTerm"
							/>
						</v-col>
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<v-btn
								@click="add"
								class="font-weight-bold"
								x-large
								block
								depressed
								color="primary"
							>
								ADICIONAR
							</v-btn>
						</v-col>
					</v-row>
				</template>
				<template #body>
					<table class="table table-striped m-table">
						<thead>
							<tr>
								<th>Status</th>
								<th>Empresa</th>
								<th>Banco</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="ddaconfig in listData" v-bind:key="ddaconfig.id">
								<td style="vertical-align: middle;">
									<v-chip
										v-if="ddaconfig.status == 0"
										dense
										small>
										Inativo
									</v-chip>
									<v-chip
										v-else
										dense
										small
										color="green"
										text-color="white">
										Ativo
									</v-chip>
								</td>
								<td style="vertical-align: middle;">{{ ddaconfig.name }}</td>
								<td style="vertical-align: middle;">{{ ddaconfig.code_bank }}</td>
								<td class="m--align-right">
									<button-tooltip
										fab
										icon
										small
										buttonIcon="mdi-pencil"
										tooltipText="Editar"
										@click="edit(ddaconfig)"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</page-card>
		</v-col>
	</v-row>
</template>

<script>
	import _ from 'lodash';
	import { API_BASE } from '@config/api';
	
	export default {
		data () {
			return {
				ddaconfigs: [],
				configs: {
					filterTerm: '',
					filterStatus: ''
				},
				filterStatusItems: [
					{
						text: "Todos os status",
						value: "",
					},
					{
						text: "Inativos",
						value: "0",
					},
					{
						text: "Ativos",
						value: "1",
					},
				]
			}
		},

		computed: {
			listData() {
				let dataRet = this.ddaconfigs;

				if (this.configs.filterStatus) {
					dataRet = _.filter(dataRet, data => data.status == this.configs.filterStatus)
				}

				if (this.configs.filterTerm) {
					dataRet = _.filter(dataRet, list => list.name.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0 || list.code_bank.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0)
				}

				return dataRet
			}
		},

		mounted: function () {
			this.loadConfigs();
		},

		methods: {
			loadConfigs: function () {
				blockPage('Carregando os dados...');

				this.$http.get(API_BASE + 'dda/config/listAll', {headers: {'token': this.$session.get('token')}}).then(
					(result) => {
						this.ddaconfigs = result.body.data;
						unblockPage();
					},
					(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						unblockPage();
					}
				)
			},
			add() {
				this.$router.push('/ddaconfig/new');
			},
			edit(ddaconfig) {
				const params = {
					dda_config_id: ddaconfig.id
				};
				this.$router.push({name: 'newddaconfig',params});
			},
		}
	}
</script>
