






















































import {
  Component,
  Mixins,
  Emit,
  Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import DialogHelper from '@/helpers/DialogHelper';
import PreNegotiationRepository from '@/repositories/PreNegotiationRepository';

@Component
export default class ConfirmDeletePreNegotiationDialog extends
Mixins<ModelManagementMixin<boolean>>(
  ModelManagementMixin,
) {
  @Prop({
    type: Array,
    required: true,
  }) preNegotiationIds!: number[];

  @Prop({
    type: Number,
    required: true,
  }) companyId!: number;

  public loading: boolean = false;

  private readonly preNegotiationRepository
    : PreNegotiationRepository = new PreNegotiationRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get dialogWidth(): string {
    return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
      smAndDown: '100%',
      md: '60%',
      default: '40%',
    });
  }

  @Emit('on-close')
  public close(reload: boolean = false): boolean {
    return reload;
  }

  public async removePreNegotiations(): Promise<void> {
    try {
      this.loading = true;

      await this.preNegotiationRepository.removePreNegotiations(
        this.companyGroupId,
        this.companyId,
        this.preNegotiationIds,
      );
    } catch (error) {
      this.$notification.error('Houve um problema ao remover as Pré-Negociações selecionadas');
    } finally {
      this.loading = false;
      this.close(true);
    }
  }
}
