<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :return-value.sync="model"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <inn-text-field
        v-model="selected"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
        readonly
        clearable
        icon="mdi-calendar"
        :hide-details="rules.length == 0"
        :rules="rules"
        @click:clear="emitClearedDateRange"
      >
      </inn-text-field>
    </template>
    <v-date-picker v-model="model" no-title scrollable range>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="uppercase"
        text
        @click="menu = false"
      >Cancelar</v-btn>
      <v-btn
        color="primary"
        class="uppercase"
        text
        @click="emitSelectedDateRange"
      >OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'InnDateRangeField',
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    required: {
      type: Boolean,
      default: false,
    },
    shouldBeGreater: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: this.value,
      inputValue: '',
      menu: false,
    };
  },
  watch: {
    value() {
      this.model = this.value;
    },
    model() {
      this.$emit('input', this.model);
    },
  },
  computed: {
    sortedDays() {
        const model = this.model ? [...this.model] : [];
        return model.sort((a, b) => {
          const unixA = this.$moment(a).unix();
          const unixB = this.$moment(b).unix();
          return unixA - unixB;
        });
    },
    selected: {
      get() {
        const moment = this.$moment;
        return this.sortedDays.map((m) => moment(m).format('DD/MM/YYYY')).join(' - ');
      },
      set(val) {
        this.inputValue = val;
      },
    },
    rules() {
      const rules = [];
      const vm = this;

      if (this.required) rules.push((v) => !!v || 'Esse campo é obrigatório');

      if (this.shouldBeGreater) {
        rules.push((v) => {
          const dates = v.split(' - ');
          const today = vm.$moment();

          return (
            dates.filter((d) => {
              const selected = vm.$moment(d, 'DD/MM/YYYY');
              return selected.diff(today, 'days') >= 0;
            }).length > 0 || 'Escolha uma intervalo atual ou futura'
          );
        });
      }

      return rules;
    },
  },
  methods: {
    emitSelectedDateRange() {
      this.$refs.menu.save(this.model);

      if (this.model.length < 1) {
        return;
      }

      this.$emit('on-select-range-date');
    },
    emitClearedDateRange() {
      this.model = [];

      this.$emit('on-clear-range-date');
    },
  },
};
</script>
