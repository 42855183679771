import PaymentRemittanceComment from './PaymentRemittanceComment';

export default class PaymentRemittanceDetails {
  constructor(
    public abatement: number,
    public balance: number,
    public bankCode: string,
    public flowDate: string,
    public companyName: string,
    public decrease: number,
    public dueDate: string,
    public emissionDate: string,
    public increase: number,
    public nature: string,
    public number: string,
    public originalValue: number,
    public retentionPCC: number,
    public corporateName: string,
    public document: string,
    public fantasyName: string,
    public totalValue: number,
    public interactions: Array<PaymentRemittanceComment>,
  ) {}

  static make(data: any): PaymentRemittanceDetails {
    const interactions: Array<PaymentRemittanceComment> = data.interactions
      ? data.interactions.map(PaymentRemittanceComment.make)
      : [] as Array<PaymentRemittanceComment>;

    return new PaymentRemittanceDetails(
      data.abatement ?? 0,
      data.balance ?? 0,
      data.bank_code ?? '',
      data.cash_flow_date ?? '',
      data.company_name ?? '',
      data.decrease ?? 0,
      data.due_date ?? '',
      data.emission_date ?? '',
      data.increase ?? 0,
      data.nature ?? '',
      data.number ?? '',
      data.original_value ?? 0,
      data.retention_pcc ?? 0,
      data.supplier_corporate_name ?? '',
      data.supplier_document ?? '',
      data.supplier_fantasy_name ?? '',
      data.total_value ?? 0,
      interactions,
    );
  }
}
