


















































































































































































































































































































































































  import {
    Vue,
    Component,
    Prop,
    Emit,
    Ref,
    Watch,
  } from 'vue-property-decorator';
  import { PropType } from 'vue';
  import { DataOptions } from 'vuetify';
  import { VForm } from '@/types/VForm';
  import IVDataTableHeader from '@/types/IVDataTableHeader';
  import SelectOptions from '@/domain/interfaces/ISelectOptions';

  import DDAConciliation from '@/domain/models/DDAConciliation';
  import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';
  import IDDAConciliationInvoice from '@/domain/interfaces/IDDAConciliationInvoice';

  import OriginType from '@/domain/enums/DDAConciliationOriginType';
  import ActionType from '@/domain/enums/DDAConciliationActionType';

  import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';
  import FilterParametersRepository from '@/repositories/FilterParametersRepository';
  import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
  import FilterParameterDDAConciliationSearch from '@/domain/models/filter-parameters/FilterParameterDDAConciliationSearch';

  import {
    formateDate,
    validateDateRange,
  } from '@/utils/date';

  import {
    formateErrorForNotification,
    formateCNPJ,
    formateCurrency,
    scrollTo,
  } from '../utils';

  import DdaConciliationInvoiceSupplier from './InvoiceSupplier.vue';
  import InvoiceCurrencyInput from './InvoiceCurrencyInput.vue';

  interface DateRangeError {
    emission: boolean,
    due: boolean,
    initialMessage: string,
    endMessage: string,
  }

  interface Invoice {
    id: number | string,
    'erp_accounts': Array<{
      'id_customer': string,
      'increase_value': number,
      'decrease_value': number,
    }>,
    'due_date': string,
    'supplier_id': number | string,
    'company_group_id': number | string,
  }

  @Component({
    components: {
      DdaConciliationInvoiceSupplier,
      InvoiceCurrencyInput,
    },
  })
  export default class DDAConciliationInvoice extends Vue {
    @Ref('formInvoice') readonly formInvoice!: VForm;
    @Ref('formSave') readonly formSave!: VForm;

    @Prop(Boolean) readonly open!: boolean;

    @Prop({
      type: Object as () => DDAConciliation,
    }) readonly item!: DDAConciliation;

    @Prop({
      type: Array as PropType<Array<number>>,
    }) readonly initialCompanies!: Array<number>;

    @Watch('open')
    changedOpen(open: boolean): void {
      if (open) {
        this.dueDateSave = this.ddaDue;
        this.data = {
          ...this.data,
          companies: this.initialCompanies,
          per_page: 10,
          page: 1,
        };
        this.loadData();
      } else {
        this.items = [];
        this.selectedItems = [];
        this.suppliers = [];
        this.searchSuppliers = '';
        this.serverItemsLength = 0;
        this.data = {} as IDDAConciliationInvoice;
        this.currentData = {} as IDDAConciliationInvoice;
        this.formInvoice.resetValidation();
        this.formSave.resetValidation();
        this.dataTableOptions = {
          ...this.dataTableOptions,
          page: 1,
          itemsPerPage: 10,
        };
        this.openInvoiceSupplier = false;
        this.invoiceSupplier = '';
      }
    }

    @Watch('searchSuppliers')
    changedSearchSuppliers(search: string): void {
      if (search && search.length) {
        this.loadSuppliersDebounce(search);
      } else {
        clearTimeout(this.timerSuppliers);
      }
    }

    @Watch('dataTableOptions')
    pagination() {
      if (!this.loading && !this.loadingItems && this.open) {
        this.currentData = {
          ...this.currentData,
          page: this.dataTableOptions.page,
          per_page: this.dataTableOptions.itemsPerPage,
        };
        this.handlerLoadMovements(this.currentData);
        scrollTo('invoice-table');
      }
    }

    @Emit()
    close(): ActionType {
      return ActionType.INVOICE;
    }

    @Emit()
    reload(): boolean {
      this.close();
      return true;
    }

    readonly filterParametersRepository:
      FilterParametersRepository = new FilterParametersRepository();

    readonly DDAConciliationRepository:
      DDAConciliationRepository = new DDAConciliationRepository();

    readonly formateDate = formateDate;
    readonly formateCNPJ = formateCNPJ;
    readonly formateCurrency = formateCurrency;

    data: IDDAConciliationInvoice = {} as IDDAConciliationInvoice;
    currentData: IDDAConciliationInvoice = {} as IDDAConciliationInvoice;

    selectedItems: Array<DDAConciliationMovement> = [];
    items: Array<DDAConciliationMovement> = [];

    loading: boolean = false;
    loadingItems: boolean = false;
    loadingTypes: boolean = false;
    loadingSuppliers: boolean = false;
    loadingCompanies: boolean = false;

    companies: Array<SelectOptions> = [];
    suppliers: Array<SelectOptions> = [];
    timerSuppliers!: ReturnType<typeof setTimeout>;
    searchSuppliers: string = '';

    openInvoiceSupplier: boolean = false;
    invoiceSupplier: string = '';

    dueDateSave: string = '';

    types: Array<SelectOptions> = [];

    serverItemsLength: number = 0;

    headers: Array<IVDataTableHeader> = [
      { text: 'Seleção', value: 'data-table-select' },
      { text: 'Emissão', value: 'emission_date' },
      { text: 'Vencimento', value: 'due_date' },
      { text: 'Título', value: 'title' },
      { text: 'Razão Social', value: 'name' },
      { text: 'Valor', value: 'value' },
      { text: 'Decréscimo', value: 'decrease' },
      { text: 'Acréscimo', value: 'increase' },
      { text: 'Saldo', value: 'balance' },
    ];

    dateRangeError: DateRangeError = {
      emission: false,
      due: false,
      initialMessage: 'Data inicial deve ser menor ou igual a data final',
      endMessage: 'Data final deve ser menor ou igual a data inicial',
    };

    dataTableOptions: DataOptions = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    };

    dataTableFooterOptions: Object = {
      'items-per-page-options': [10, 50, 100],
      'disable-items-per-page': false,
      'disable-pagination': false,
    }

    get dda() {
      return this.item.movements
        ?.find((mov) => mov.origin === OriginType.DDA) || {} as DDAConciliationMovement;
    }

    get ddaDocument() {
      return this.dda.document;
    }

    get ddaBranch() {
      return this.dda.branch_code;
    }

    get ddaTitle() {
      return this.dda.title;
    }

    get ddaValue() {
      return this.dda.value;
    }

    get ddaEmission() {
      return this.dda.emission_date;
    }

    get ddaDue() {
      return this.dda.due_date;
    }

    get ddaCompanyId() {
      return this.dda.company_id;
    }

    get groupId(): number {
      return this.$session.get('company_group_id');
    }

    get selectedItemsTotal(): number {
      return this.selectedItems
        .reduce((total, item) => total + (item.balance ?? 0), 0);
    }

    get diff(): number {
			return this.ddaValue - this.selectedItemsTotal;
    }

    changedCompanies(): void {
      this.suppliers = [];
      this.data.suppliers = [];
    }

    validateAllDateRanges(data: IDDAConciliationInvoice): boolean {
      const initialEmission = data.initial_emission_date;
      const endEmission = data.end_emission_date;
      const initialDue = data.initial_due_date;
      const endDue = data.end_due_date;

      const areValidEmissionDates = validateDateRange(initialEmission, endEmission);
      const areValidDueDates = validateDateRange(initialDue, endDue);

      if (!areValidEmissionDates) {
        this.dateRangeError.emission = true;
        this.$notification.error('Intevalo de emissão inválido!');
      } else {
        this.dateRangeError.emission = false;
      }

       if (!areValidDueDates) {
        this.dateRangeError.due = true;
        this.$notification.error('Intevalo de vencimento inválido!');
      } else {
        this.dateRangeError.due = false;
      }

      if (!areValidEmissionDates && !areValidDueDates) {
        this.$notification.error('Intevalo de emissão e de vencimento inválidos!');
      }

      return areValidEmissionDates && areValidDueDates;
    }

    handlerFilter(): void {
      const validated = this.validateAllDateRanges(this.data)
        ? this.formInvoice.validate()
        : false;

      if (validated) {
        this.currentData = {
          ...this.data,
          page: 1,
          per_page: this.dataTableOptions.itemsPerPage,
        };
        this.handlerLoadMovements(this.currentData);
      }
    }

    async loadPaymentAccountsTypes(): Promise<void> {
      try {
        this.loadingTypes = true;

        const types = await this.DDAConciliationRepository.getPaymentAccountsTypes();
        this.types = types;
      } catch (error: any) {
        const errorMessage = formateErrorForNotification(error);
        this.$notification.error(errorMessage);
      } finally {
        this.loadingTypes = false;
      }
    }

    async loadCompanies(): Promise<void> {
      try {
        this.loadingCompanies = true;
        const companies = await this.DDAConciliationRepository.getCompanies();
        this.companies = companies;
      } catch (error: any) {
        const errorMessage = formateErrorForNotification(error);
        this.$notification.error(errorMessage);
      } finally {
        this.loadingCompanies = false;
      }
    }

    async loadSuppliers(search: string): Promise<void> {
      try {
        this.loadingSuppliers = true;

        const { companies } = this.data;

        const suppliers = await this.DDAConciliationRepository.getSuppliers(
          this.groupId, companies ?? [], search,
        );

        this.suppliers.push(...suppliers);
      } catch (error: any) {
        const errorMessage = formateErrorForNotification(error);
        this.$notification.error(errorMessage);
      } finally {
        this.loadingSuppliers = false;
      }
    }

    loadSuppliersDebounce(search: string): void {
      clearTimeout(this.timerSuppliers);

      this.timerSuppliers = setTimeout(() => {
        this.loadSuppliers(search);
      }, 500);
    }

    async loadData(): Promise<void> {
      try {
        this.loading = true;
        await this.loadCompanies();
        if (!this.types.length) await this.loadPaymentAccountsTypes();
        await this.loadFilterParameters();
      } catch (error: any) {
        const message = formateErrorForNotification(error);
        this.$notification.error(message);
      } finally {
        this.loading = false;
      }
    }

    async loadFilterParameters(): Promise<void> {
      try {
        const filterParameters = await this.filterParametersRepository
          .getFilterByGroup(GroupFilterParametersEnum.DDA_CONCILIATION_INVOICE);
        const definedFilters = FilterParameterDDAConciliationSearch.make(filterParameters);

        this.data.initial_emission_date = definedFilters.initial_emission_date;
        this.data.end_emission_date = definedFilters.end_emission_date;
        this.data.initial_due_date = definedFilters.initial_due_date;
        this.data.end_due_date = definedFilters.end_due_date;
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
      }
    }

    async handlerLoadMovements(parameters: IDDAConciliationInvoice) {
      try {
        this.loadingItems = true;
        this.dataTableFooterOptions = {
          ...this.dataTableFooterOptions,
          'disable-items-per-page': true,
          'disable-pagination': true,
        };

        const data: IDDAConciliationInvoice = {
          ...parameters,
          value: parameters.value == '0' ? undefined : parameters.value,
        };

        await this.loadERPs(data);

        this.filterParametersRepository
          .setFilter(GroupFilterParametersEnum.DDA_CONCILIATION_INVOICE, [
            { key: 'initial_due_date_dda_conciliation_search', value: parameters.initial_due_date },
            { key: 'end_due_date_dda_conciliation_search', value: parameters.end_due_date },
            { key: 'initial_emission_date_dda_conciliation_search', value: parameters.initial_emission_date },
            { key: 'end_emission_date_dda_conciliation_search', value: parameters.end_emission_date },
          ]);
      } catch (error: any) {
        const message = formateErrorForNotification(error);
        this.$notification.error(message);
      } finally {
        this.loadingItems = false;
        this.dataTableFooterOptions = {
          ...this.dataTableFooterOptions,
          'disable-items-per-page': false,
          'disable-pagination': false,
        };
      }
    }

    async loadERPs(parameters: object): Promise<void> {
      const { companies } = this.data;
      const { data, meta } = await this.DDAConciliationRepository
        .getERPs(this.groupId, companies ?? [], parameters);

      data.forEach((item) => {
        const index = this.selectedItems.findIndex((it) => it.id === item.id);
        if (index !== -1) {
          item.increase = this.selectedItems[index].increase;
          item.decrease = this.selectedItems[index].decrease;
          item.balance = this.selectedItems[index].balance;
          this.selectedItems.splice(index, 1, item);
        }
      });

      this.items = data;
      this.serverItemsLength = meta;
    }

    getTitleSelectedItem(item: any): string {
      let title = '';

      title = `
        ${item.title} - 
        ${formateDate(item.emission_date)} - 
        ${formateCurrency(item.balance)}
      `;

      return title;
    }

    removeSelectedItem(item: any): void {
      const index = this.selectedItems.indexOf(item);
      this.selectedItems.splice(index, 1);
    }

    closeInvoiceSupplier(): void {
      this.openInvoiceSupplier = false;
    }

    handlerSave(): void {
      if (this.selectedItems.length <= 0) {
				this.$notification.error('Nenhuma registro selecionado!');
        return;
			}

      if (this.selectedItemsTotal.toFixed(2) != this.ddaValue.toFixed(2)) {
        this.$notification.error('Valor total selecionado não é o mesmo do DDA.');
        return;
      }

      if (!this.formSave.validate()) {
        this.$notification.error('Data de vencimento da fatura inválida!');
        return;
      }

      const supplierTest = this.selectedItems[0].supplier_id_customer;
      const sameSupplier = this.selectedItems
        .every((item) => item.supplier_id_customer == supplierTest);

      if (!!supplierTest && sameSupplier) {
        this.invoiceSupplier = supplierTest;
        this.save();
      } else {
        this.openInvoiceSupplier = true;
      }
    }

    save(supplier?: string) {
      const { id } = this.dda;
      const erpAccounts = this.selectedItems.map((item) => {
        const { id, increase, decrease } = item;
        return {
          id_customer: `${id}`,
          increase_value: increase ?? 0,
          decrease_value: decrease ?? 0,
        };
      });
      const dueDate = this.dueDateSave;
      const supplierId = supplier ?? this.invoiceSupplier;
      const companyGroupId = this.groupId;

      const data: Invoice = {
        id,
        erp_accounts: erpAccounts,
        due_date: dueDate,
        supplier_id: supplierId,
        company_group_id: companyGroupId,
      };

      this.generateInvoice(data);
    }

    async generateInvoice(data: Invoice) {
      try {
        this.$dialog.startLoading();

        const group = this.groupId;

        const success = await this.DDAConciliationRepository.invoice(group, data);

        if (success) {
          this.$notification.success('Fatura gerada com sucesso!');
          this.reload();
        }
      } catch (error: any) {
        this.$dialog.stopLoading();
        const message = formateErrorForNotification(error);
        this.$notification.error(message);
      }
    }

    onValueChange(item: DDAConciliationMovement): void {
      const { value, increase, decrease } = item;

      item.balance = value + (increase ?? 0) - (decrease ?? 0);
    }
  }
