export default class Menu {
  constructor(
    public id : number,
    public title : string,
    public icon: string,
    public routeName: string,
    public order: number,
    public moduleId : number,
    public permissionId : number|null,
    public parent : Menu|null,
    public children : Menu[],
  ) {}

  static make(data : any) : Menu {
    const parent = data.parent_menu
      ? Menu.make(data.parent_menu)
      : null;
    const children = data.children
      ? data.children.map(Menu.make)
      : [];

    return new Menu(
      data.id,
      data.title,
      data.icon,
      data.route_name,
      data.order,
      data.module_id,
      data.permission_id,
      parent,
      children,
    );
  }
}
