<template>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                </div>
            </div>
            <div class="m-portlet__head-tools">
                <router-link :to="{ name: 'accessusergroup' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
                <button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
            </div>
        </div>
        <div class="m-portlet__body">
            <!--begin: Search Form -->
            <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                <ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link active" data-toggle="tab" href="#tab1" role="tab"
                           aria-expanded="true">
                            Acessos de menu
                        </a>
                    </li>
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link" data-toggle="tab" href="#tab2" role="tab"
                           aria-expanded="false">
                            Permissões
                        </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="tab1" role="tabpanel">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <button type="button" class="btn m-btn--pill btn-primary btn-sm invertAccess">Inverter
                                    seleção
                                </button>
                            </div>
                        </div>
                        <div class="row" id="frmAccess">
                            <div class="col-sm-6">
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Fluxo de caixa</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="cashflow" id="cashflow" value="">
												<span class="accessSwitch" input-field="cashflow"
                                                      input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Fluxo de caixa realizado</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="cashflow_realized" value="">
												<span class="accessSwitch" input-field="cashflow_realized" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Fluxo de caixa projetado</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="cashflow_projected" value="">
												<span class="accessSwitch" input-field="cashflow_projected" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Adiantamentos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="advancepayments" id="advancepayments"
                                                       value="">
												<span class="accessSwitch" input-field="advancepayments"
                                                      input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div v-show="admin" class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Grupo de Empresas</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="program_companygroup" id="program_companygroup" value="">
												<span class="accessSwitch" input-field="program_companygroup" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Empresas</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="company" id="company" value="">
												<span class="accessSwitch" input-field="company" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Configurações de Empresa</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="program_companyconfiguration" id="program_companyconfiguration" value="">
												<span class="accessSwitch" input-field="program_companyconfiguration" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Tipos de
                                        usuário</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="user_group" id="user_group" value="">
												<span class="accessSwitch" input-field="user_group"
                                                      input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Remessas Enviadas</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="cnabremittancegenerated" value="">
												<span class="accessSwitch" input-field="cnabremittancegenerated" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Config DDA</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="ddaconfig" value="">
												<span class="accessSwitch" input-field="ddaconfig" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Conciliação DDA</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="ddaconciliation" value="">
												<span class="accessSwitch" input-field="ddaconciliation" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Forma de Pagamento</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="paymentmethod" value="">
												<span class="accessSwitch" input-field="paymentmethod" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Motivos de Baixa</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="acquittancereason" value="">
												<span class="accessSwitch" input-field="acquittancereason" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Envio Extrato Bancário</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="conciliationupload" value="">
												<span class="accessSwitch" input-field="conciliationupload" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Conciliação Extrato Bancário</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="statementconciliation" value="">
												<span class="accessSwitch" input-field="statementconciliation" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Envio Folha Pagamento</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="payroll" value="">
												<span class="accessSwitch" input-field="payroll" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Acessos do tipo de
                                        usuário</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="user_group_access" id="user_group_access"
                                                       value="">
												<span class="accessSwitch" input-field="user_group_access"
                                                      input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Usuários</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="user" id="user" value="">
												<span class="accessSwitch" input-field="user" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Bloqueios</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="block" id="block" value="">
												<span class="accessSwitch" input-field="block" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Agrupadores</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="grouper" value="">
												<span class="accessSwitch" input-field="grouper" input-value="1"></span>
											</label>
                                            <input class="hidden" type="checkbox" name="program_grouperiten" id="program_grouperiten" value="">
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Configuração Remessa CNAB</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="cnabremittance" value="">
												<span class="accessSwitch" input-field="cnabremittance" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Config Segmentos Cnab</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="cnabremittanceconfig" value="">
												<span class="accessSwitch" input-field="cnabremittanceconfig" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Envio DDA</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="ddaupload" value="">
												<span class="accessSwitch" input-field="ddaupload" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Remessa Pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="remittancepayable" value="">
												<span class="accessSwitch" input-field="remittancepayable" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Config Conciliação Bancária</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="conciliationconfig" value="">
												<span class="accessSwitch" input-field="conciliationconfig" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Config Ações Conciliação Bancária</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="conciliationactionconfig" value="">
												<span class="accessSwitch" input-field="conciliationactionconfig" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>  
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Config Bancárias</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="bankconfig" value="">
												<span class="accessSwitch" input-field="bankconfig" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div> 
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Config Retorno Bancário</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="bankretconfig" value="">
												<span class="accessSwitch" input-field="bankretconfig" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>

                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Config API bancária</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="apibankingconfig" value="">
												<span class="accessSwitch" input-field="apibankingconfig" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Remessa de Cobrança</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="billingremittance" value="">
												<span class="accessSwitch" input-field="billingremittance" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Cadastro de ocorrências</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="occurrences" value="">
												<span class="accessSwitch" input-field="occurrences" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Envio manual de retornos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="grouper" id="manualreturns" value="">
												<span class="accessSwitch" input-field="manualreturns" input-value="1"></span>
											</label>
										</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="tab2" role="tabpanel">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <button type="button" class="btn m-btn--pill btn-primary btn-sm invertPermission">
                                    Inverter seleção
                                </button>
                            </div>
                        </div>
                        <div class="row" id="frmPermission">
                            <div class="col-md-6">
                                <h4><i class="la la-check-square"></i> Alterar Data</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Pedidos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_date_pe" id="change_date_pe"
                                                       input-value="PE">
												<span class="permissionSwitch" input-field="change_date_pe"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_date_tp" id="change_date_tp"
                                                       input-value="TP">
												<span class="permissionSwitch" input-field="change_date_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_date_tr" id="change_date_tr"
                                                       input-value="TR">
												<span class="permissionSwitch" input-field="change_date_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Adiantamentos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_date_ad" id="change_date_ad"
                                                       input-value="AD">
												<span class="permissionSwitch" input-field="change_date_ad"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <h4><i class="la la-check-square"></i> Inclui acréscimo</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="register_addition_tp"
                                                       id="register_addition_tp" input-value="TP">
												<span class="permissionSwitch"
                                                      input-field="register_addition_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="register_addition_tr"
                                                       id="register_addition_tr" input-value="TR">
												<span class="permissionSwitch"
                                                      input-field="register_addition_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <h4><i class="la la-check-square"></i> Inclui decréscimo</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="register_decrease_tp"
                                                       id="register_decrease_tp" input-value="TP">
												<span class="permissionSwitch"
                                                      input-field="register_decrease_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="register_decrease_tr"
                                                       id="register_decrease_tr" input-value="TR">
												<span class="permissionSwitch"
                                                      input-field="register_decrease_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <h4><i class="la la-check-square"></i> Altera forma de pagamento</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_payment_condition_tp"
                                                       id="change_payment_condition_tp" input-value="TP">
												<span class="permissionSwitch"
                                                      input-field="change_payment_condition_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_payment_condition_tr"
                                                       id="change_payment_condition_tr" input-value="TR">
												<span class="permissionSwitch"
                                                      input-field="change_payment_condition_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <h4><i class="la la-check-square"></i> Altera taxa de câmbio</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_exchange_rate_tp"
                                                       id="change_exchange_rate_tp" input-value="TP">
												<span class="permissionSwitch"
                                                      input-field="change_exchange_rate_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_exchange_rate_tr"
                                                       id="change_exchange_rate_tr" input-value="TR">
												<span class="permissionSwitch"
                                                      input-field="change_exchange_rate_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <h4><i class="la la-check-square"></i> Altera taxa de juros</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_interest_rate_tp"
                                                       id="change_interest_rate_tp" input-value="TP">
												<span class="permissionSwitch"
                                                      input-field="change_interest_rate_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_interest_rate_tr"
                                                       id="change_interest_rate_tr" input-value="TR">
												<span class="permissionSwitch"
                                                      input-field="change_interest_rate_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>

                                <br>
                                <h4><i class="la la-check-square"></i> Conciliação bancária</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Conciliar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="reconcile"
                                                       id="reconcile" input-value="1">
												<span class="permissionSwitch"
                                                      input-field="reconcile"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Incluir movimento a pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="include_pay_movement"
                                                       id="include_pay_movement" input-value="1">
												<span class="permissionSwitch"
                                                      input-field="include_pay_movement"></span>
											</label>
										</span>
                                    </div>
                                    <label class="col-8 col-form-label" style="text-align: left;">Incluir movimento a receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="include_receive_movement"
                                                       id="include_receive_movement" input-value="1">
												<span class="permissionSwitch"
                                                      input-field="include_receive_movement"></span>
											</label>
										</span>
                                    </div>
                                    <label class="col-8 col-form-label" style="text-align: left;">Baixar título a receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="write_off_receivables"
                                                       id="write_off_receivables" input-value="1">
												<span class="permissionSwitch"
                                                      input-field="write_off_receivables"></span>
											</label>
										</span>
                                    </div>
                                </div>
                            </div>                            
                            <div class="col-md-6">
                                <h4><i class="la la-check-square"></i> Altera valor</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Saldos
                                        bancários</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_amount_sb" id="change_amount_sb"
                                                       input-value="SB">
												<span class="permissionSwitch" input-field="change_amount_sb"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Pedidos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_amount_pe" id="change_amount_pe"
                                                       input-value="PE">
												<span class="permissionSwitch" input-field="change_amount_pe"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_amount_tp" id="change_amount_tp"
                                                       input-value="TP">
												<span class="permissionSwitch" input-field="change_amount_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="change_amount_tr" id="change_amount_tr"
                                                       input-value="TR">
												<span class="permissionSwitch" input-field="change_amount_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <h4><i class="la la-check-square"></i> Inclui comentário</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Saldos
                                        bancários</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="include_comment_sb" id="include_comment_sb"
                                                       input-value="SB">
												<span class="permissionSwitch" input-field="include_comment_sb"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Pedidos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="include_comment_pe" id="include_comment_pe"
                                                       input-value="PE">
												<span class="permissionSwitch" input-field="include_comment_pe"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="include_comment_tp" id="include_comment_tp"
                                                       input-value="TP">
												<span class="permissionSwitch" input-field="include_comment_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="include_comment_tr" id="include_comment_tr"
                                                       input-value="TR">
												<span class="permissionSwitch" input-field="include_comment_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Adiantamentos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="include_comment_ad" id="include_comment_ad"
                                                       input-value="AD">
												<span class="permissionSwitch" input-field="include_comment_ad"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <h4><i class="la la-check-square"></i> Aprovação</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Saldos
                                        bancários</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="approve_sb" id="approve_sb"
                                                       input-value="SB">
												<span class="permissionSwitch" input-field="approve_sb"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Pedidos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="approve_pe" id="approve_pe"
                                                       input-value="PE">
												<span class="permissionSwitch" input-field="approve_pe"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        pagar</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="approve_tp" id="approve_tp"
                                                       input-value="TP">
												<span class="permissionSwitch" input-field="approve_tp"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Titulos a
                                        receber</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="approve_tr" id="approve_tr"
                                                       input-value="TR">
												<span class="permissionSwitch" input-field="approve_tr"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <h4><i class="la la-check-square"></i> Outros acessos</h4>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Recebe mensagem de
                                        fechamento de caixa</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="receive_message_box_closing"
                                                       id="receive_message_box_closing" input-value="1">
												<span class="permissionSwitch"
                                                      input-field="receive_message_box_closing"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Configura agrupadores
                                        dinâmicos</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="configure_dynamic_groupers"
                                                       id="configure_dynamic_groupers" input-value="1">
												<span class="permissionSwitch"
                                                      input-field="configure_dynamic_groupers"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Incluir
                                        simulações</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="include_simulation" id="include_simulation"
                                                       input-value="1">
												<span class="permissionSwitch" input-field="include_simulation"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Visualiza Dashboard PowerBI</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="view_dashboard_power_bi" id="view_dashboard_power_bi" input-value="1">
												<span class="permissionSwitch" input-field="view_dashboard_power_bi"></span>
											</label>
										</span>
                                    </div>
                                </div>
                                <div class="m-form__group form-group row" style="padding-top: 0; padding-bottom: 0;">
                                    <label class="col-8 col-form-label" style="text-align: left;">Visualiza Faturamento no Fluxo Realizado</label>
                                    <div class="col-4">
										<span class="m-switch m-switch--sm m-switch--icon">
											<label>
												<input type="checkbox" name="view_billing_on_realized" id="view_billing_on_realized" input-value="1">
												<span class="permissionSwitch" input-field="view_billing_on_realized"></span>
											</label>
										</span>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {API_BASE} from '@config/api';

    export default {
        data() {
            return {
                programs: [],
                admin: false,
            }
        },
        mounted: function () {
            this.loadData();
            this.loadMethod();
        },

        methods: {
            loadData: function () {
                var self = this;
                self.admin = self.$session.get('user_type') == 'O';
                blockPage('Carregando os dados...');

                $.ajax({
                    method: "GET",
                    url: API_BASE + 'access/listAllAccess/' + self.$route.params.user_group_id,
                    headers: {
                        'token': self.$session.get('token')
                    }
                }).done(function (result) {
                    var statusDesc = "";
                    $.each(result.data.access[0], function (i, item) {
                        if ($.trim(item) != '' && $.trim(item) != 0) {
                            $("#" + i).click();
                            $("#" + i).val(item);
                        }
                    });

                    $.each(result.data.permission, function (i, item) {
                        if ($.trim(item) != '' && $.trim(item) != 0) {
                            $("#" + i).click();
                            //$("#"+i).val(item);
                        }
                    });

                    unblockPage();
                }).fail(function (error) {
                    unblockPage();
                    console.log(error.responseText);
                });


            },

            loadMethod: function () {
                var self = this;

                $(".accessSwitch").click(function () {
                    var inputId = $(this).attr('input-field');
                    var inputValue = $(this).attr('input-value');

                    if ($.trim($("#" + inputId).val()) == '') {
                        $("#" + inputId).val(inputValue);
                    } else {
                        $("#" + inputId).val("");
                    }
                });

                $(".invertAccess").click(function () {
                    $.each($('input, select, span', '#frmAccess'), function () {
                        if ($(this).hasClass('accessSwitch')) {
                            $(this).click();
                        }
                    });
                });

                /*
                *   Funcao que altera o valor do input hidden de agrupadores nivel 2
                *   Funciona para que o banco de dados libere acesso as duas telas de agrupadores
                *   !!Metodos alternativos de Resolucao!!
                */
                $("#grouper").bind('change, click', function() {
                    $("#program_grouperiten").val($("#grouper").val());
                })

                $(".invertPermission").click(function () {
                    $.each($('input, select, span', '#frmPermission'), function () {
                        if ($(this).hasClass('permissionSwitch')) {
                            $(this).click();
                        }
                    });
                });

                $("#btn-save").click(function () {
                    var arrayDataAccess = {}, arrayDataPermission = [], i = 0;
                    var btn = $(this);

                    btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

                    $.each($('input', '#frmAccess'), function () {
                        var $this = $(this);

                        arrayDataAccess[this.id] = this.value;

                        i++;
                    });

                    i = 0;
                    $.each($('input', '#frmPermission'), function () {
                        var $this = $(this);
                        var value = "";

                        if ($("#" + this.id + ":checked").length > 0) {
                            value = $(this).attr('input-value');
                        }

                        arrayDataPermission[i] = {};
                        arrayDataPermission[i][this.id] = value;

                        i++;
                    });

                    $.ajax({
                        method: "POST",
                        url: API_BASE + 'access/addAccess/' + self.$route.params.user_group_id,
                        headers: {
                            'token': self.$session.get('token')
                        },
                        data: {
                            arrayDataAccess: JSON.stringify(arrayDataAccess),
                            arrayDataPermission: JSON.stringify(arrayDataPermission)
                        }
                    }).done(function (result) {
                        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

                        if (result.error == false) {
                            showNotification('Sucesso', 'Acessos registrado!', 'success');
                        } else {
                            showNotification('Atenção', result.error, 'danger');
                            return false;
                        }
                    }).fail(function (error) {
                        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

                        showNotification('Atenção', 'Erro ao salvar as alterações!', 'danger');
                        console.log(error);
                    });
                });
            }
        }
    }
</script>