import { formatToSpecificFormat } from '@/utils/date';
import { toCurrency } from '@/utils/index';
import SettleBankAccountStatusEnum from '@/views/CnabRemittanceGenerated/enums/SettleBankAccountStatusEnum';
import IGroupedTransactionsInvoice from '@/views/CnabRemittanceGenerated/interfaces/IGroupedTransactionsInvoice';
import ISettledBankAccountTransaction from '@/views/CnabRemittanceGenerated/interfaces/ISettledBankAccountTransaction';
import SettleBankAccountFormatterHelper from '@/views/CnabRemittanceGenerated/helpers/SettleBankAccountFormatterHelper';

export default class SettleBankAccount {
    constructor(
        public transactions: IGroupedTransactionsInvoice[],
        public completeTransactions: ISettledBankAccountTransaction[],
        public erpBordero: string,
        public borderoNumber: string,
        public companyName: string,
        public type: 0 | 1,
        public companyCode: string,
        public branchCode: string,
        public isOpen: boolean,
    ) {}

    public static make(data: any): SettleBankAccount {
      const formattedTransactions = data.transactions
        .map((transaction: any) => this.formatTransaction(data.type, transaction));

      const groupedTransactionInvoices = SettleBankAccountFormatterHelper
        .groupTransactions(formattedTransactions);

      return new SettleBankAccount(
        groupedTransactionInvoices,
        formattedTransactions,
        data.erpBordero,
        data.borderoNumber,
        data.companyName,
        data.type,
        data.companyCode,
        data.branchCode,
        false,
      );
    }

    private static formatTransaction(
      settledBanksReturnType: number,
      transaction: any,
    ): ISettledBankAccountTransaction {
      const {
        bank_code,
        bank_agency,
        bank_agency_digit,
        bank_account,
        bank_account_digit,
        bank_occurrence,
        hasAProblemWhenSendingToBank,
        title_payment_receipt_customer_supplier,
        title_payment_receipt_customer_supplier_store,
        provider_name,
        title_payment_receipt_prefix,
        title_payment_receipt_number,
        title_payment_receipt_portion,
        title_payment_receipt_type,
        invoice_number,
        bordero_number,
        title_payment_receipt_value,
        title_payment_receipt_interest,
        title_payment_receipt_discount,
        hasSettleValue,
        hasInclusionDate,
        title_payment_receipt_credit_date,
        title_payment_receipt_low_date,
        hasSettleDate,
        description,
        observation,
        id_customer,
        title_payment_receive_net_value,
        status,
        action,
        account_invoice_link,
        invoice_link,
      } = transaction;

      const definedInclusionDate = this.getInclusionDate(
        settledBanksReturnType,
        hasInclusionDate,
        title_payment_receipt_credit_date,
        title_payment_receipt_low_date,
      );
      const definedLowDate = this.getLowDate(
        hasSettleDate,
        action,
        status,
        title_payment_receipt_credit_date,
      );
      const amountPaidValue = this.getAmountPaidValue(
        hasSettleValue,
        bank_occurrence,
        action,
        status,
        title_payment_receipt_value,
        title_payment_receive_net_value,
      );
      const transactionStatus = this.getIfStatusAreSuccessOrFail(
        bank_occurrence,
        transaction.status,
        hasAProblemWhenSendingToBank,
      );
      const occurrence = `${bank_occurrence} - ${description}`;

      return {
        bankCode: bank_code,
        bankAgency: bank_agency,
        bankAgencyDigit: bank_agency_digit,
        bankAccount: bank_account,
        bankAccountDigit: bank_account_digit,
        bankOccurrence: bank_occurrence,
        titlePaymentReceiptCustomerSupplier: title_payment_receipt_customer_supplier,
        titlePaymentReceiptCustomerSupplierStore: title_payment_receipt_customer_supplier_store,
        providerName: provider_name,
        titlePaymentReceiptPrefix: title_payment_receipt_prefix,
        titlePaymentReceiptNumber: title_payment_receipt_number,
        titlePaymentReceiptPortion: title_payment_receipt_portion,
        titlePaymentReceiptType: title_payment_receipt_type,
        invoiceNumber: invoice_number,
        borderoNumber: bordero_number,
        titlePaymentReceiptValue: title_payment_receipt_value,
        titlePaymentReceiptInterest: title_payment_receipt_interest,
        titlePaymentReceiptDiscount: title_payment_receipt_discount,
        hasSettleValue,
        hasInclusionDate,
        hasSettleDate,
        description,
        observation,
        idCustomer: id_customer,
        inclusionDate: definedInclusionDate,
        lowDate: definedLowDate,
        occurrence,
        amountPaidValue,
        transactionStatus,
        invoiceLink: account_invoice_link,
        accountInvoiceLink: invoice_link,
      };
    }

    private static getInclusionDate(
      settledBanksReturnType: number,
      hasInclusionDate: boolean,
      titlePaymentReceiptCreditDate: string,
      titlePaymentReceiptLowDate: string,
    ): string {
      if (!hasInclusionDate) {
        return '';
      }

      if (settledBanksReturnType === 0 && titlePaymentReceiptCreditDate) {
        return formatToSpecificFormat(titlePaymentReceiptCreditDate, 'dd/mm/yyyy', 'ddmmyyyy');
      }

      if (settledBanksReturnType === 1 && titlePaymentReceiptLowDate) {
        return formatToSpecificFormat(titlePaymentReceiptLowDate, 'dd/mm/yyyy', 'ddmmyyyy');
      }

      return '';
    }

    private static getLowDate(
      hasSettleDate: boolean,
      action: string,
      status: string,
      titlePaymentReceiptCreditDate: string,
    ): string {
      if (
        !hasSettleDate
        || !titlePaymentReceiptCreditDate
        || (action === '1' && status === SettleBankAccountStatusEnum.ERROR)
      ) {
        return '';
      }

      return formatToSpecificFormat(titlePaymentReceiptCreditDate, 'dd/mm/yyyy', 'ddmmyyyy');
    }

    private static getAmountPaidValue(
      hasSettleValue: boolean,
      bankOccurrence: string,
      action: string,
      status: string,
      titlePaymentReceiptValue: string,
      titlePaymentReceiveNetValue: string,
    ): string {
      let definedValue = '0';

      if (hasSettleValue) {
        definedValue = titlePaymentReceiptValue;
      }

      if (bankOccurrence === '00' && status === SettleBankAccountStatusEnum.SYNCED && action === '1') {
        definedValue = titlePaymentReceiveNetValue;
      }

      return toCurrency(definedValue, {}, true);
    }

    private static getIfStatusAreSuccessOrFail(
      bankOccurrence: string,
      status: string,
      hasAProblemWhenSendingToBank: boolean,
    ): 'success' | 'fail' | '' {
      if (bankOccurrence === 'BD' || (status === '1' && !hasAProblemWhenSendingToBank)) {
        return 'success';
      }

      if (bankOccurrence !== 'BD' || (status === '2' || hasAProblemWhenSendingToBank)) {
        return 'fail';
      }

      return '';
    }
}
