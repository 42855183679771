export default class Occurrence {
  constructor(
    public code: string,
    public description: string,
    public date?: string,
  ) {}

  static make(data: any): Occurrence {
    return new Occurrence(
      data.code ?? '',
      data.description ?? '',
      data.date,
    );
  }
}
