<style>


</style>
<template>
    <div>
        <div class="collapse show">
            <div class="row levels level-four"
                 style="padding-top: 6px;padding-bottom: 6px;color: black;">
                <div class="col-sm-3" style="padding-right:0;padding-left:20px;">
                     <span class="description"
                           style="float: left;padding-left: 3px;padding-right: 30px;width: 330px;white-space: normal;">
                    {{spanDescription}}
                    </span>
                </div>
                <div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">
                    <div class="row" style="padding-right:0;padding-left:0;">
                        <div class="col-sm-1 text-center"
                             style="padding-right:0;padding-left:0;">&nbsp;
                        </div>

                        <date-value :cash-flow-hidden-dates="cashFlowHiddenDates" :identify-value="identifyValue"
                                    v-for="(dataValue,key) in mainData.data_values" :key="'L4-' + key"
                                    class-value="col-sm-2 text-right containerLevelFour containerDate"
                                    :data-value="dataValue"></date-value>

                        <div class="col-sm-1 text-center"
                             style="padding-right:0;padding-left:0;">&nbsp;
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
    import DateValue from './DateValue.vue'

    export default {
        props: ['typeLevel', 'dataRows', 'cashFlowHiddenDates', 'spanDescription','identifyValue'],
        components: {DateValue},
        data() {
            return {
                showSection: false,
                description: "",
                mainData: this.dataRows
            }
        },
        watch: {
            dataRows: function (newVal, oldVal) {
                var self = this;
                self.mainData = newVal;
            }
        }
        ,
        methods: {
            toggle() {
                this.showSection = !this.showSection
            },
        }
    }
</script>
