import { formatToSpecificFormat, ptBrDateTimeFormat, dateTimeFormatWithSeconds } from '@/utils/date';

export default class NewsList {
  constructor(
    public id: number,
    public status: string,
    public title: string,
    public text: string,
    public startDateTime: string,
    public finalDateTime: string,
  ) {}

  static make(data: any): NewsList {
    return new NewsList(
      data.id,
      data.status,
      data.title,
      data.text,
      formatToSpecificFormat(data.start_date_time, ptBrDateTimeFormat, dateTimeFormatWithSeconds),
      formatToSpecificFormat(data.final_date_time, ptBrDateTimeFormat, dateTimeFormatWithSeconds),
    );
  }
}
