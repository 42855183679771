













































  import {
    Vue,
    Component,
    Prop,
    Emit,
    Ref,
    Watch,
  } from 'vue-property-decorator';
  import { DateTime } from 'luxon';
  import { VForm } from '@/types/VForm';
  import PaymentRemittancesSummary from '@/domain/models/PaymentRemittancesSummary';
  import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
  import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import { formatErrorForNotification } from '@/utils/error';

  @Component
  export default class PaymentRemittancesSummaryBalance extends Vue {
    @Ref('form') readonly form!: VForm;

    @Prop({
      type: Boolean,
    }) readonly open!: boolean;

    @Prop({
      type: Object as () => PaymentRemittancesSummary,
    }) readonly item!: PaymentRemittancesSummary;

    @Emit()
    close(reload = false) {
      return { type: ActionType.EDIT, reload };
    }

    @Watch('open')
    onChangeOpen(open: boolean) {
      if (open) {
        this.value = this.item && this.item.bankBalance ? this.item.bankBalance.sent : 0;
      } else {
        this.value = 0;
        this.loading = false;
        this.form.resetValidation();
      }
    }

    readonly paymentRemittanceRepository:
      PaymentRemittanceRepository = new PaymentRemittanceRepository();

    readonly required = (v: string) => !!v || 'Valor obrigatório!';
    value: number = 0;
    loading: boolean = false;

    save(): void {
      if (this.loading) return;

      const isValidform = this.form.validate();

      if (isValidform) {
        this.handleSave(this.value);
      }
    }

    async handleSave(value: number) {
      try {
        this.loading = true;

        const { id } = this.item;
        const date = DateTime.now().toFormat('yyyy-MM-dd');

        const data = {
          _identifyValue: 'BC',
          _field: 'valor',
          _date: date,
          _keyId: id,
          _data: value,
          _dataOld: undefined,
        };

        await this.paymentRemittanceRepository.updateInternal('savedata', data);

        this.$notification.success('Saldo alterado com sucesso!');
        this.close(true);
      } catch (error: any) {
        const message = formatErrorForNotification(error);
        this.$notification.error(message);
      } finally {
        this.loading = false;
      }
    }
  }
