













































































import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import BillingHistorySupport from '@/components/timeline/support/HistoryTimelineSuport';
import BillingHistory from '@/domain/models/BillingHistory';
import IButtonOption from '@/plugins/inn-design/components/buttons/interfaces/IButtonOption';

@Component
export default class EditorMenu extends Mixins(ModelManagementMixin) {
  public eventInteractionOptions: IButtonOption[] = [
    { text: 'Concluir com sucesso', value: 'conclude-success' },
    { text: 'Concluir como falha', value: 'conclude-fail' },
  ];

  @Prop({
    type: Object,
    required: true,
  }) readonly event!: BillingHistory;

  @Prop(Element) readonly selected!: Element;

  public getToolbarTitleColorBasedOnStatus(): string {
    return BillingHistorySupport.getColorByStatus(this.event.status);
  }

  public handleEdit(): void {
    this.$emit('edit', this.event);
  }

  public handleClientClick(): void {
    this.$router.push(`/clientes/${this.event.idCustomer}/historico-de-cobranca`);
  }

  public handleSelectedOption(selectedOption: string): void {
    this.$emit(selectedOption, this.event);
  }
}
