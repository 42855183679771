import { render, staticRenderFns } from "./SearchIncomeRecord.vue?vue&type=template&id=5232b871&scoped=true"
import script from "./SearchIncomeRecord.vue?vue&type=script&lang=js"
export * from "./SearchIncomeRecord.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5232b871",
  null
  
)

export default component.exports