import IVuetifyBreakpoints from '@/helpers/interfaces/IVuetifyBreakpoints';
import IVuetifyBreakpointsString from '@/helpers/interfaces/IVuetifyBreakpointsString';

export default class DialogHelper {
  public static getDialogWidth(
    breakpoint: IVuetifyBreakpoints,
    breakpointValue: IVuetifyBreakpointsString = {},
  ): string {
    if (breakpoint.mdAndDown) {
      return breakpointValue.smAndDown ?? '';
    }

    if (breakpoint.smAndDown) {
      return breakpointValue.smAndDown ?? '';
    }

    if (breakpoint.md) {
      return breakpointValue.md ?? '';
    }

    if (breakpoint.xs) {
      return breakpointValue.xs ?? '';
    }

    return breakpointValue.default ?? '';
  }
}
