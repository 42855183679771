import FilterParameterHelper from '@/helpers/FilterParameterHelper';

export default class FilterParameterConciliationList {
    public constructor(
      public direction: boolean,
      public sort: string,
      public dateInitial: string,
      public dateFinish: string,
      public dateProInitial: string,
      public dateProFinish: string,
      public status: Array<number>,
      public companyId: number,
      public bank: string,
    ) {
    }

    public static make(data: any): FilterParameterConciliationList {
      const { direction, sort } = FilterParameterHelper
        .defineOrdenation(data.sort_conciliation_record);

      return new FilterParameterConciliationList(
        direction,
        sort,
        data.date_initial_conciliation_record ?? '',
        data.date_finish_conciliation_record ?? '',
        data.date_pro_initial_conciliation_record ?? '',
        data.date_pro_finish_conciliation_record ?? '',
        data.status_conciliation_record ? JSON.parse(data.status_conciliation_record) : [],
        parseInt(data.company_id_conciliation_record, 10),
        data.bank_conciliation_record,
      );
    }
}
