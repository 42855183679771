enum SettleBankAccountStatusEnum {
  'RECENTLY_IMPORTED' = '0',
  'SYNCED' = '1',
  'ERROR' = '2',
  'REGISTER_NOT_FOUND' = '3',
  'IS_ARTIFICIAL_INVOICE' = '4',
  'PROCESSING' = '5',
}

export default SettleBankAccountStatusEnum;
