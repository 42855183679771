export const toNFormat = (num, digits = 2) => {
  if (Number.isNaN(num) || Number.isNaN(digits)) throw new Error('Only numeric arguments allowed');
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find((el) => num >= el.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const toCurrency = (value, config = {}, brazilianCurrency = false) => {
  const toFormat = Number(value);

  if (Number.isNaN(toFormat)) {
    return value;
  }

  const defaultConfigs = {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  let definedNumberFormatLocale;

  if (brazilianCurrency) {
    definedNumberFormatLocale = 'pt-BR';
  }

  // eslint-disable-next-line
  var formatter = new Intl.NumberFormat(definedNumberFormatLocale, {
    ...defaultConfigs,
    ...config,
  });

  return formatter.format(toFormat);
};

export const pluralize = (value, singular = '', plural = '') => (Number.isNaN(value) || value <= 1 ? `${value} ${singular}` : `${value} ${plural}`);

export const arraySum = (arr) => {
  if (arr.some(Number.isNaN)) {
    throw new Error('The function Array.prototype.sum only accepts numerial arrays');
  }
  return arr.reduce((acc, el) => acc + el, 0);
};

export const arrayMax = (arr) => {
  if (arr.some(Number.isNaN)) {
    throw new Error('The function Array.prototype.max only accepts numerial arrays');
  }
  return arr.reduce((a, b) => Math.max(a, b));
};

export const arraySumProperty = (arr, prop) => {
  if (arr.some((el) => Number.isNaN(el[prop]))) {
    throw new Error('The function Array.prototype.sumProp only accepts numerial arrays');
  }
  return arr.reduce((a, b) => a + b[prop], 0);
};

export const kebabCase = (text, separator) => {
  text = text
    .toString()
    .toLowerCase()
    .trim();

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'ae', from: '[Ä]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[ŒÖ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ŚŜŞŠ]' },
    { to: 'ss', from: '[ß]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'ue', from: '[Ü]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: "[·/_,:;']" },
  ];

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to);
  });

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if (typeof separator !== 'undefined' && separator !== '-') {
    text = text.replace(/-/g, separator);
  }

  return text;
};

export const isNullOrEmpty = (test) => typeof test === 'undefined' || test === null || test == '' || test == 0;
