export default class Module {
  constructor(
    public id : number,
    public name : string,
    public icon: String,
    public description : string,
    public initialRoute : string,
    public isPurchased : boolean,
    public isActive : boolean,
  ) {}

  static make(data : any) : Module {
    return new Module(
      data.id,
      data.name,
      `mdi-${data.icon}`,
      data.description,
      data.initial_route,
      data.is_purchased,
      data.is_active,
    );
  }
}
