























































































import {
 Component,
 Vue,
 Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import UpdateClientInfoParams from '@/views/clients/types/UpdateClientInfoParams';
import SerasaFile from '@/views/clients/components/SerasaFile.vue';
import ClientModule from '@/stores/modules/ClientModule';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import NavigationTabs from '@/components/navigation/tabs/NavigationTabs.vue';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import ClientRepository from '@/repositories/ClientRepository';
import IWebhookBaseParams from '@/repositories/parameters/IWebhookBaseParams';
import NavigationTabsType from '@/types/NavigationTabsType';
import CurrencyHelper from '@/helpers/CurrencyHelper';
import StringHelper from '@/helpers/StringHelper';
import { DateTime } from 'luxon';
import StatusChip from '@/components/tables/StatusChip.vue';
import StatusEnum from '@/domain/enums/StatusEnum';

@Component({
  components: {
    NavigationTabs,
    SerasaFile,
    StatusChip,
  },
})
export default class Client extends Vue {
  public selectedTab: number = 0;

  public tabs: NavigationTabsType[] = [
    {
      name: 'Ficha financeira',
      path: `${this.baseSpecificCustomerPath}/`,
    },
    {
      name: 'Informações gerais',
      path: `${this.baseSpecificCustomerPath}/informacoes-gerais`,
    },
    {
      name: 'Contatos',
      path: `${this.baseSpecificCustomerPath}/contatos`,
    },
    {
      name: 'Inf. Complementares',
      path: `${this.baseSpecificCustomerPath}/informacoes-complementares`,
    },
    {
      name: 'Vendas',
      path: `${this.baseSpecificCustomerPath}/vendas`,
    },
    {
      name: 'Histórico de Cobrança',
      path: `${this.baseSpecificCustomerPath}/historico-de-cobranca`,
    },
  ];
  public readonly rulesHelper: InputRulesHelper = new InputRulesHelper();
  public readonly clientModule: ClientModule = getModule(ClientModule);
  public readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  public readonly clientRepository: ClientRepository = new ClientRepository();

  public additionalPageTitleInformation: string = '';

  get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  public get idCustomer(): string {
    return this.$route.params.idCustomer;
  }

  public get companyId(): string {
    return this.$route.params.companyId;
  }

  public get showSerasaConsultationActions(): boolean {
    if (this.$route.name === null || this.$route.name === undefined) {
      return false;
    }

    return ['ClientGeneral', 'ClientInformation'].includes(this.$route.name);
  }

  public get baseSpecificCustomerPath(): string {
    return `/clientes/${this.idCustomer}`;
  }

  public status = [
    {
      value: StatusEnum.ACTIVE,
      label: 'Ativo',
      color: 'success',
    },
    {
      value: StatusEnum.INACTIVE,
      label: 'Inativo',
      color: 'error',
    },
  ];

  @Watch('$route')
  public onChangeRoute() {
    this.selectedTab = this.defineTabByRoute(this.$route.name);
  }

  public async created(): Promise<void> {
    this.selectedTab = this.defineTabByRoute(this.$route.name);
    this.loadInitialInformation();
  }

  public defineTabByRoute(routeName: string | null | undefined): number {
    switch (routeName) {
      case 'ClientFinancialStatement':
        return 0;
      case 'ClientGeneral':
        return 1;
      case 'ClientContacts':
        return 2;
      case 'ClientInformation':
        return 3;
      case 'ClientSells':
        return 4;
      case 'ClientHistory':
        return 5;
      default:
        return 0;
    }
  }

  public async loadInitialInformation(): Promise<void> {
    this.$dialog.startLoading();

    await Promise.all([this.getClientById()]);

    this.$dialog.stopLoading();

    this.generateTitleAdditionalInformation();
  }

  public async getClientById(): Promise<void> {
    try {
      this.clientModule.setClientLoading(true);
      const client = await this.clientRepository
        .getClientById(this.idCustomer, this.companyGroupId, this.companyIds);

      client.limit = CurrencyHelper
      .toCurrency(client.limit, { showCurrencySymbol: false });
      client.balanceDue = CurrencyHelper
        .toCurrency(client.balanceDue, { showCurrencySymbol: false });

      this.clientModule.setClient(client);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o cliente selecionado!');
    } finally {
      this.clientModule.setClientLoading(false);
    }
  }

  public generateTitleAdditionalInformation() {
    this.additionalPageTitleInformation = `${this.clientModule.client.companyName} - ${this.clientModule.client.cnpj}`;
  }

  public async saveChanges(): Promise<void> {
    const inputErrors = this.getInputErrors();

    if (inputErrors.length > 0) {
      this.$notification.warn(inputErrors[0]);

      return;
    }

    if (this.clientModule.client.companyId === null) {
      this.$notification.error('Este cliente não possui o id da empresa que pertence!');

      return;
    }

    this.$dialog.startLoading();

    const clientParameters: IWebhookBaseParams<UpdateClientInfoParams> = {
        id: this.idCustomer,
        data: {
          phone: this.clientModule.client.phone.replace('-', ''),
          ddd: this.clientModule.client.ddd!,
          invoiceMail: this.clientModule.client.invoiceMail,
          financialMail1: this.clientModule.client.financialMail1,
          // financialMail2: this.clientModule.client.financialMail2,
          financialName1: this.clientModule.client.financialName1,
          financialName2: this.clientModule.client.financialName2,
          group: this.clientModule.client.group,
          subGroup: this.clientModule.client.subGroup,
          deadline: this.clientModule.client.deadline,
          limit: this.getFormattedLimit(this.clientModule.client.limit),
          risk: this.clientModule.client.risk,
          clientClass: this.clientModule.client.clientClass,
          notes: this.clientModule.client.notes,
          deadlineForBlocking: this.clientModule.client.deadlineForBlocking,
          creditBlockingSituation: this.clientModule.client.creditBlockingSituation,
        },
    };

    try {
      await this.clientRepository.updateCustomerAdditionalInformation(
        this.clientModule.client.companyId,
        this.companyGroupId,
        clientParameters,
      );

      this.$notification.success('Alterações do cliente salvas com sucesso!');
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao salvar as alterações do cliente!');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public getFormattedLimit(limit: string | null |number | undefined): number|undefined {
    if (typeof limit === 'number') {
      return limit;
    }

    if (typeof limit === 'string') {
      return parseFloat(
        limit
          .replaceAll('.', '')
          .replaceAll(',', '.'),
      );
    }

    return undefined;
  }

  public getInputErrors(): string[] {
    const inputErrors: string[] = [];

    if (!this.clientModule.client.ddd) {
      inputErrors.push('DDD obrigatório.');
    }

    if (this.clientModule.client.ddd && this.clientModule.client.ddd.length !== 2) {
      inputErrors.push('DDD deve ser ter somente 2 caracteres.');
    }

    if (this.clientModule.client.phone && this.clientModule.client.phone.length > 15) {
      inputErrors.push('Telefone deve ter no máximo 15 caracteres.');
    }

    if (this.clientModule.client.invoiceMail) {
      if (this.clientModule.client.invoiceMail.length > 150) {
          inputErrors.push('Email NFE deve ter no máximo 150 caracteres.');
      }
      const splitedMails = this.clientModule.client.invoiceMail.replaceAll(' ', '').split(';');

      if (!splitedMails.every((mail) => StringHelper.isEmail(mail))) {
        inputErrors.push('Email NFE está inválido!');
      }
    }

    if (this.clientModule.client.financialMail1) {
      if (this.clientModule.client.financialMail1.length > 150) {
          inputErrors.push('Email Financeiro 1 deve ter no máximo 150 caracteres.');
      }

      if (!StringHelper.isEmail(this.clientModule.client.financialMail1)) {
        inputErrors.push('Email Financeiro 1 está inválido!');
      }
    }

    // if (this.clientModule.client.financialMail2) {
    //   if (this.clientModule.client.financialMail2.length > 150) {
    //       inputErrors.push('Email Financeiro 2 deve ter no máximo 150 caracteres.');
    //   }

    //   if (!StringHelper.isEmail(this.clientModule.client.financialMail2)) {
    //     inputErrors.push('Email Financeiro 2 está inválido!');
    //   }
    // }

    if (this.clientModule.client.group && this.clientModule.client.group.length > 10) {
      inputErrors.push('Grupo deve ter no máximo 10 caracteres.');
    }

    if (this.clientModule.client.subGroup && this.clientModule.client.subGroup.length > 10) {
      inputErrors.push('Sub-Grupo deve ter no máximo 10 caracteres.');
    }

    if (this.clientModule.client.deadline && this.clientModule.client.deadline.length > 3) {
      inputErrors.push('Sub-Grupo deve ter no máximo 3 caracteres.');
    }

    if (!this.clientModule.client.creditBlockingSituation) {
      inputErrors.push('Situação de bloqueio de crédito é obrigatório.');
    }

    const today = DateTime.now().toFormat('yyyy-MM-dd');
    if (
      this.clientModule.client.deadlineForBlocking
      && this.clientModule.client.deadlineForBlocking < today
    ) {
      inputErrors.push('A data limite bloqueio deve ser maior que a data atual.');
    }

    return inputErrors;
  }
}
