<template>
  <v-textarea
    v-model="model"
    :label="label"
    :placeholder="placeholder"
    filled
    :rules="allRules"
    v-on="$listeners"
    v-bind="$attrs"
  ></v-textarea>
</template>

<script>
import ModelManagementMixin from '@/mixins/ModelManagementMixin';

export default {
  name: 'InnTextarea',
  mixins: [ModelManagementMixin],
  props: {
    label: String,
    placeholder: String,
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allRules() {
      const requiredRule = (v) => !!v || 'Esse campo é obrigatório';
      return this.required ? [...this.rules, requiredRule] : this.rules;
    },
  },
};
</script>

<style></style>
