














































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import ArtificialInvoice from '@/domain/models/ArtificialInvoice';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import { toCurrency } from '@/utils/';
import { formateDate } from '@/utils/date';
import { formatErrorForNotification } from '@/utils/error';
import { formatCNPJ, formatCPF, formatTitle } from '../../utils';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionViewDetails extends Vue {
  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Emit()
  close(reload: boolean = false): CloseActionData {
    return {
      type: ActionType.VIEW_INVOICE,
      reload,
    };
  }

  @Watch('open')
  onChangeOpen() {
    if (this.open) {
      this.loadInvoice();
    } else {
      this.loading = false;
      this.invoice = {} as ArtificialInvoice;
    }
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly formateDate = formateDate;
  readonly formatTitle = formatTitle;
  readonly formatCNPJ = formatCNPJ;
  readonly formatCPF = formatCPF;
  readonly formatCurrency = toCurrency;

  loading: boolean = false;
  invoice: ArtificialInvoice = {} as ArtificialInvoice;

  showDocument(document: string|null) {
    if (!document) {
      return '';
    }

    return document.length >= 14 ? formatCNPJ(document) : formatCPF(document);
  }
  async loadInvoice() {
    if (this.item.origin !== 'inncash' || this.item.type !== 'FT') {
      this.$notification.warn('Fatura inválida para visualização!');
      return;
    }

    try {
      this.loading = true;

      const groupId = this.$session.get('company_group_id');
      const { id, company_id: companyId } = this.item;

      this.invoice = await this.paymentRemittanceRepository
        .getInvoice(groupId, companyId, `${id}`);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      this.close();
    } finally {
      this.loading = false;
    }
  }
}
