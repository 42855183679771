import StatementConciliation from '@/domain/models/StatementConciliation';

export default class DeconciliationMovements {
  constructor(
    public deconciliatedConciliationId: number | string | null,
    public erpMovements: StatementConciliation[],
    public bankMovements: StatementConciliation[],
  ) {}

  static make(data: any): DeconciliationMovements {
    const formattedErpMovements = data.erp_movements
        .map((movement: any, index: number) => StatementConciliation.make(movement, index));
    const formattedBankMovements = data.bank_movements
        .map((movement: any, index: number) => StatementConciliation.make(movement, index));

    return new DeconciliationMovements(
        null,
        formattedErpMovements,
        formattedBankMovements,
    );
  }
}
