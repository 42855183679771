





























































































import {
  Component,
  Prop,
  Mixins,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import SecuritiesRepository from '@/repositories/SecuritiesRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientModule from '@/stores/modules/ClientModule';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import VMenuOptions from '@/types/VMenuOptions';
import IDecreaseSecuritiesParams from '@/repositories/parameters/IDecreaseSecuritiesParams';
import IIncreaseSecuritiesParams from '@/repositories/parameters/IIncreaseSecuritiesParams';
import IFiltersToConsiderOnFinancialStatement from '../interfaces/IFiltersToConsiderOnFinancialStatement';

@Component
export default class InclusionSecurityDialog extends Mixins<ModelManagementMixin<boolean>>(
  ModelManagementMixin,
) {
  @Prop({
    type: Array,
    required: true,
    default: () => ([]),
  }) readonly selectedSecuritiesIdCustomer!: string[];

  @Prop({
    type: Boolean,
    required: true,
    default: false,
  }) readonly isSelectingAllSecurities!: boolean;

  @Prop({
    type: Object,
    required: true,
    default: () => ({}),
  }) readonly filters!: IFiltersToConsiderOnFinancialStatement;

  @Prop({
    type: Number,
    required: true,
  }) readonly companyId!: number;

  public selectedInclusionOption: string = 'increase';
  public inclusionValue: number = 0;
  public message: string = '';

  public model: boolean = this.value;
  public loading: boolean = false;

  private readonly securitiesRepository: SecuritiesRepository = new SecuritiesRepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly clientModule: ClientModule = getModule(ClientModule);

  public inclusionOptions: VMenuOptions[] = [
    { text: 'Acréscimo', value: 'increase' },
    { text: 'Decréscimo', value: 'decrease' },
  ]

  public get dialogWidth(): string {
    if (this.$vuetify.breakpoint.smAndDown) {
      return '100%';
    }

    if (this.$vuetify.breakpoint.md) {
      return '60%';
    }

    return '40%';
  }

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get idCustomerFromRoute(): string|undefined {
    return this.$route.params.idCustomer;
  }

  public async increase(): Promise<void> {
    if (!this.message) {
      this.$notification.error('O campo de motivo é obrigatório');

      return;
    }

    this.loading = true;

    const params: IIncreaseSecuritiesParams = {
      addition_balance: this.inclusionValue,
      type: 'account_receivable',
      message: this.message,
      securities_ids_customer: this.selectedSecuritiesIdCustomer,
      is_selecting_all_securities: this.isSelectingAllSecurities,
      number: this.filters.number,
      start_date_emission: this.filters.start_date_emission,
      end_date_emission: this.filters.end_date_emission,
      start_date_actual_expiration: this.filters.start_date_actual_expiration,
      end_date_actual_expiration: this.filters.end_date_actual_expiration,
      consider_expired: Boolean(this.filters.consider_only?.expired),
      consider_to_expired: Boolean(this.filters.consider_only?.to_expired),
      consider_paid_out: Boolean(this.filters.consider_only?.paid_out),
    };

    if (this.idCustomerFromRoute) {
      params.id_customer = this.idCustomerFromRoute;
    }

    try {
      await this.securitiesRepository.changeIncreaseSecurities(
        this.companyGroupId,
        this.companyId,
        params,
      );

      this.$notification.info('A inclusão de acréssimo foi mandada para a fila de execução!');

      this.closeDialog(true);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao fazer o acréssimo!');

      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async decrease(): Promise<void> {
    if (!this.message) {
      this.$notification.error('O campo de motivo é obrigatório');

      return;
    }

    this.loading = true;

    const params: IDecreaseSecuritiesParams = {
      decrease_balance: this.inclusionValue,
      type: 'account_receivable',
      message: this.message,
      securities_ids_customer: this.selectedSecuritiesIdCustomer,
      is_selecting_all_securities: this.isSelectingAllSecurities,
      number: this.filters.number,
      start_date_emission: this.filters.start_date_emission,
      end_date_emission: this.filters.end_date_emission,
      start_date_actual_expiration: this.filters.start_date_actual_expiration,
      end_date_actual_expiration: this.filters.end_date_actual_expiration,
      consider_to_expired: Boolean(this.filters.consider_only?.expired),
      consider_expired: Boolean(this.filters.consider_only?.to_expired),
      consider_paid_out: Boolean(this.filters.consider_only?.paid_out),
    };

    if (this.idCustomerFromRoute) {
      params.id_customer = this.idCustomerFromRoute;
    }

    try {
      await this.securitiesRepository.changeDecreaseSecurities(
        this.companyGroupId,
        this.companyId,
        params,
      );

      this.$notification.info('A inclusão de decrésimo foi mandada para a fila de execução!');

      this.closeDialog(true);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao fazer o decrésimo!');

      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public closeDialog(reloadScreen: boolean = false): void {
    this.$emit('on-close', reloadScreen);
  }

  public handleCancel(): void {
    this.closeDialog();
  }

  public async handleConfim(): Promise<void> {
    if (this.selectedInclusionOption === 'increase') {
      await this.increase();

      return;
    }

    await this.decrease();
  }
}
