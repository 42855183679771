enum PaymentMethodType {
  OTHERS = '0',
  BANK_SLIP = '1',
  TED = '2',
  DOC = '3',
  MONEY = '4',
  TED_OTHER = '5',
  DOC_OTHER = '6',
  PIX = '7',
  GPS = '8',
  DARF = '9',
  CONCESSIONAIRE = '10',
  TRIBUTE = '11',
  FGTS = '13'
}

export default PaymentMethodType;
