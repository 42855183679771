<template>
  <v-btn class="button" :ripple="false" @click="generatePdf">
    Salvar Código em PDF
    <v-icon color="white">mdi-file-download-outline</v-icon>
  </v-btn>
</template>

<script>
/* eslint-disable no-undef */

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const INNCASH_LOGO = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABFsAAAE4CAYAAACE+PxbAAAgA'
  + 'ElEQVR4nO3de7B3e10X8M/mHFAu8cDh+SWgyEVA8pZA4RV1zEVKajaFSDUOzEhFE3mhootZ45SXprIIotJElJhSAnEgc1'
  + 'YaCQmIyJm8jGkIAt5Yj8CDeEAUdn/89j5nP/vZl99v3b6312vmmXMOPHv/Puv7/ay1v9/3Xr/1O4iGHR4epi4BWMjBwUH'
  + 'qEpjo+rVrqUtoVeofjk7eHV25ejV1CQDAOW5NXQAAkETqUOU859UlhAEAiiFsAYD65Rqs7OP0MQhfAIBsCVsAoD41hCuX'
  + 'Eb4AANm6teuH21MXMdET+27zu6mLAICEWghXLiN8AWA2169dK/Vnq59/mbg1Iv546iImuiV1AQCQQKmLwLWcHB8LTwBgV'
  + 'd5GBADlELCMI3gBAFYlbAGA/AlZ5nM8lkIXAGAxwhYAyJOAZVlCFwBgMcIWAMiLkGVd3mIEAMxO2AIAeRCypOduFwBgFs'
  + 'IWAEhLyJIfoQsAMImwBQDSELLkT+gCAIxyt9QFAEBjDkPQUhrzBQDsxZ0tALAOG/ayucsFANiZO1sAYHmClnq4MwkAuJS'
  + 'wBQCWY2NeL/MKAJxL2AIAy7AZr58wDQA4k7AFAOZlA94e8w0A3EDYAgDzselul5ANALiTsAUAprPR5pg+AACELQAwkc01'
  + 'pwnfAKBxwhYAGM+GmovoDwBolLAFAPbnzgV2pU8AoEHCFgDYj80z+9IzANAYYQsA7M6mmbH0DgA0RNgCALuxWWYqbz8Dg'
  + 'EYIWwDgcjbIzEk/AUDlhC0AcDEbY5agrwCgYsIWADifDTFL0l8AUClhCwCczUaYNegzAKiQsAUAbmYDzJr0GwBURtgCAD'
  + 'ey8SUFfQcAFRG2AMBdbHhJSf8BQCWELQCwZaMLAMAshC0AIGghH3oRACogbAGgdTa35EZPAkDhhC0AtMymllzpTQAomLA'
  + 'FgFbZzJI7PQoAhRK2AADkS+ACAAUStgDQIhtYAAAWI2wBoDWCFkqjZwGgMMIWAFpi00qp9C4AFETYAgBQBoELABRC2AJA'
  + 'K2xUAQBYhbAFgBYIWqiFXgaAAghbAADKInABgMzdmroAAFiYjemyDi75/40/ANAcYQsANbPRn+ayIGWO72GOxjmMeeYHA'
  + 'FiAsAUAOJZi8376NYUvuxG0AEDGhC0A1Mqm/XI5btiFLxfLcc4AgFOELQDUyAb9fKVt1k/W2/q8ljZ3ANAsYQsAtKGGjf'
  + 'rxMbQWutQwdwDQFGELALVpbSN+kVo36a2ELrXOHwBUT9gCAPVpZZNea+jSyvwBQLWaDlsODqxlACpT26Z7jBZ/uNUUurQ'
  + '4fwBQnabDFgCoiE36dgxKDVzMHwBURNgCQC1K3WRPZZN+o9LucjF/AFChu6UuAAAYzUb9fLmPzUHkXyMAMJKwBYAalHIX'
  + 'w5xs1C+X6xjlWhcAMBNvIyKJrh8+NiIeExGPiIiHRsQnRsTHR8RtEXH/o3/eOyLucfQl94qIO47+/Y6IeO/Rn9+OiHce/'
  + 'fmViPjFiHhb320+ssqB0JyuH+4V2979pNj27UMj4kFxc+/e/ehLTvbu78W2b98T2959R0S8KyJ+Oba9+2t9t/noKgdCyW'
  + 'zU95PT24qymLvr166lLmFta8x9FnNbkitXr876/Srt67WuW8X379z9xGhL9mxxfSpsYXFHwcrjIuLzIuKzIuLTI+KRsf+'
  + 'dVVdO/PNBF/y9D3X98JaI+OmIeH1E/ETfbYY9XwuOg5UnRMTnHP3zMyLi4bH/xf5k7z74gr93R9cPPxsRb4yIn4qI1/Td'
  + '5j17vlaLcthEr6W4hUZGUj4817ytJ9Ucn/W65p19pPxZpn8ZY+2eLa5PD7p+KH2Rev++27wvdRHcqOuHT4+IPx0RT45ty'
  + 'HKPi79icf8nIl4dET8cEW/qu03pfc8Cun44iIjHRsSXHv353Ii4JWFJhxHxs7Ht3Vf03eb2hLWsbo/fUrZyPme9oCjI2v'
  + '2y2LyN/U1uhXcAlHANcP6e4s6WIvr2pKx7eIk7W65fu1baHB1baq5yH4/selTYwmy6fviUiHhaRDwlIj45cTkXeVdEvCQ'
  + 'iXtx3m19KXQzpdf3w2Ih4akR8dWzvXMnVr0bED0TE9/fd5ldTF7O0HRfOpf8M21V2C4jCVfG2ksbDlpLPfedzNBu2lNy3'
  + 'J2XXw8KWG8w5P8ZgAmHLTLp+eHpEPD1xGft6Wd9tnj/lGxy9RegpEfHXYnsXQGleFxH/JiJe3nebP0xdTApdP/yNiPgLq'
  + 'evY0/f13eb7pnyDrh/uHRF/Mba9+7g5ilpZH9vefXWtz3kRttwpiwVDhZbqndXmq8GwpcbzfY5+KXJcrly9Ouu5knFfFz'
  + 'k/e8jiZ5Sw5QbNXlfOkLQ/PbNlPg+LiC9MXcSeRr8loeuH+0XEsyLimyKi5CdSff7Rn3d2/fBtsd3EfyhxTWt7ZJTXu68'
  + 'Z+4VdP1yNiK+PiGfHXc9SKVF39Of/df3wTyPiJa0GhpXLYhFbqbmf4WKullPLov8sx8emf+pTc9+epIfrUlvfJu1PH/3M'
  + 'Xrp+uHfXD/8wIt4eEd8WZQctJz0kIl4YEW/r+uGvdP0giKxM1w9Xun74jtj27jdH2UHLSY+MiBfFNnT5y0fPnWlFbQuC0'
  + '1qay1TmGmNztYzDqP88P9bSsdbsMNqdy1aPuxa1z1+S4xO2sJOuHw66fnhGbD9e+Vujno3qaQ+MiH8fEbd3/fCk1MUwXd'
  + 'cPtxy9VeqtEfHc2H4sc40eGtvnubyp64fPS10Mk9m8l+EgzNUSal/0X6TlYy+dedvSw2Vpbb5WPV5hC5fq+uHREfE/I+J'
  + '74+KPXK7Jp0bEj3X98NKuHz4udTGM0/XDZ0TEG2L7bJMHJC5nLY+PiNd1/fAfun64f+piFtTSwoBljQlLhCzLaG3RfxHj'
  + 'UA59ezbjkrfW52eVYxe2cK6ju1meHduPTS7tmR5zeVpE/GLXD38+dSHsruuHu3X98A8i4s0R8SdS15PIMyPiF7p++NLUh'
  + 'bA3m/j17TPm5md+rS/6z2Nc8mZ+dmOc8mM+thbvTWELZ+r64baIeGVEPC8iPiZxOandFhEv6/rhRUefYEPGun54UGw/qe'
  + 'efhIeAPygifrTrh+d1/XCP1MWwExv5dC4be3ezLMOi/3LGKD/mZH/GLA/m4WaLjYmwhZt0/fCYiHhjRHxF6loy8/SIeEP'
  + 'XD49KXQhn6/rhcRHxpoj44tS1ZObZEfHarh8ekrqQmdS6ULCRz5OQZRl+270fY5UHfTuN8UvH2F9skbERtnCDrh++KLbP'
  + 'uHhk4lJy9WmxfQBpl7oQbtT1w5dHxGsj4uNT15KpJ0TEm7t++JzUhUDGDk79u5BlGRb849gspWXs52Ms12W8dzP7NVbYw'
  + 'p26fviyiPhvUe8nDc3lSkS8+ujTmchA1w9Pi4iXR8S9UteSuU1E/LhnEGXJpj4fQpZlWfRPZwzXZ8znZ0zXYZz3N9uYCV'
  + 'uIiIiuH54c22e03DN1LYW4e0R8b9cPfzt1Ia3r+uFrIuIlsZ0TLnfPiPjBrh++LnUhI9W4aLCxpwXuypiXsVyPsV6O68K'
  + 'yjO14s4ydsIXo+uGzI+JlYbM6xj/r+uHvpi6iVV0/PCkivj9cy/Z1t4j47q4fnpW6EKAJFvzLMK7LEgSsxzjPz5hON3kM'
  + 'bVAa1/XDwyPiVeGOlim+veuHb0pdRGu6fvi0iPivISSc4t96O1xy7mqhdhb8yzK+yzCu6zPm5GhSXwpb2nbP2D7n4gGpC'
  + '6nAv7BpXdX9IuIVEXGf1IVU4Hu6fvhzqYsAqmTzBOzDNWMexnFeo8dT2NK2Z0TEZ6YuoiLf3fXDl6QuohF/PXxi1lzuFh'
  + 'Ev7frhCakL2UFtiwd3tVCz2s5X2qF30zL+5GhUXwpb2ubtF/O6JbYPHn1U6kIaoHfn9bER8cquHz4hdSFAFWyWKJXezYN'
  + '5oArCFpjX/SPih7t+uHfqQmBPD4yIH+r6QZC1Dne1UCubJEqld/NiPsjN3j0pbIH5fUpEPD91ETDCZ0fEt6cuAiiWzRGl'
  + '0rt5Mi/kZq+eFLbAMp7e9cPTUhcBIzyn64cnpy7iDDUtuNzVQo1qOkeBfLi2kJude1LYAst5ftcPD0xdBIzw3V0/3C91E'
  + 'UAxbIYomf7NnzmiSMIWWM5tEfHC1EXACA+OiO9KXQRQBJsgSqZ/gTF2unYIW2BZX9X1w59JXQSM8PSuHz43dREV8hYiam'
  + 'KjCqzF9YbiCFtgec/r+uFjUhcBI7yg64dbUhcRFliQI+clpdPD5TFn5OTSfhS2wPIeERFfn7oIGOEzI+IZqYsAsmPDA6T'
  + 'i+kNOLuxHYQus47keOEqhvqXrh3umLqIS3kIEkAcbdmBxwhZYx20R8ZzURcAID4mIv5q6CCAbNqlAaq5D5OTcfhS2wHqe'
  + '3fXDfVMXASM8t+uHe6QuAkjOBoca6OM6mEeyJ2yB9VwJdwhQpgdGxNemLgIAADJ0ZvgnbIF1fUPXD3dPXQSM8JyuH1I8c'
  + '6SW31x5Xgulq+VcBIBVCFtgXQ+OiK9MXQSM8JiI+KLURQBJCFqohV6uh19ikD1hC6zPW4ko1bNSFwAANO0gBC3k6aYwV9'
  + 'gC6/uSrh8elroIGOGrun64LXURwKrcCQDkQMhCcYQtsL6DiPia1EXACHePiK9OXUSBLA4B0hMclknIQkluuM4IWyCNp6U'
  + 'uAEZ6auoCgNXYnAKpCFkonrAF0viMrh8enboIGOELu374uNRFAIsTtAApCFmohrAF0vny1AXACAcR8WWpiwAAqiNkoQZ3'
  + '/rJC2ALpCFsold6FurmrBcpycMGfEpRUK+zs1tQFUKU/iIh3RcQ7IuLXI+KOiPi9iPhobAO++0bE/SLiEyPi4RHR6qebf'
  + 'H7XD/fpu80HUhfCnT4c2759Z0T8Rmx7946I+EhE3BIRVyLi/hHx0Ih4WGz7uEVd1w+39N3mI6kLAYAd1BYg7hNMnPy7uY'
  + '2DgIWqCVuYw3sj4tUR8bqIeFNE/Fzfbf5g1y/u+uHjI+KzIuKJsf2N+SOXKDJDd4+Iz46I/5G6kIYNEfGqiPjfse3dX9g'
  + 'nQDj6CO8/GRFfEBFfGdsAsQX3jYjHRsTPpC4EmF1umzHgLlPDiVyCFyFLmfaZNz9LQtjCeB+OiP8SES+KiJ+c8hvuvtv8'
  + 'ekS8/OjPN3b98GkR8XUR8fTY3klQsy8KYcvaPhgRL4mIF0fE6/tu89Gx36jvNm+PiLdHxA9FxLO7fnh8RDwzIv5SRNxnc'
  + 'qV5+4JYPmzxgxrIwS4bDNcrljZ3QHEQ6/etkKUsU+br9Ne2do08jIgDYQv7+mBE/MuIeF7fbd69xAv03ebnI+Ibun74+x'
  + 'Hx7Ij4e1Fv6PL5qQtoyPsj4jsj4oV9t3nvEi/Qd5s3R8Sbu374OxHxnKM/917itTLwxNheCwBqMnZzcd7XtbbBYBlLhRT'
  + 'H33fpPhWylGPpXoto6LroAbns4wci4lF9t/nmpYKWk/puc0ffbb4zts/G+J6lXy+Rx3f94Dxc1mFE/LuIeGTfbb5tqaDl'
  + 'pL7bvL/vNv8oIh4R2zvAavT41AUAs2tmAXzKkg8TLe1BpbRpyf7U+2VY8zrVzDXRJo9d/E5EfEXfbb726C0/q+q7zfv6b'
  + 'vPM2D7P5drar7+w+0TEJ6UuomK/ERFf3HebZ/XdZlj7xftu8+6+23xNRDw1tnfW1OQhXT9sUhdRgCYWE1CgFCFIMxsMZr'
  + 'XmBnju76ffy5BqnqrvD2ELl/m5iHh8321elbqQvtu8OrYP0v2l1LXMzB0Cy3hDRDyu7zavSV1I321+MCI+NyJ+LXUtM3t'
  + 's6gKA2bRyV0sOG8AcaoCzzNGX+rscOcxV6tdflLCFi/x0RDyx7zbZbBD7bvOrEfE5sa2tFn8sdQEV+vGI+FN9t/nt1IUc'
  + '67vNL8Q2LPz51LXM6FNTFwCwh9wW9TlsdOC0Kc8t0s/lyGmuqu0dYQvnuT0intR3m+upCzmt7zbvi4gvjXrucPnk1AVU5'
  + 'rWxfdvbHakLOe0o/HlSRLwjdS0zeVTqAgB2kPtCPvf6SCv3u870b3lyna9c6xrrUNjCWX4rIp6cY9By7Oghp18ZER9IXc'
  + 'sMHp26gIq8NSK+qu82H0xdyHn6bvObEfFnI+L3U9cyA0Eh1CH3zdwUJS3eS6qVuu3Si0KWMuU+Z7nXtxdhC6d9NCKeerQ'
  + 'hzFrfbX4lIp6Zuo4ZeEDuPD4cEU/pu817Uhdymb7b3B7bj4Uu3SNSFwBwjlI3giXWzPJSBKLn9WKp5xbmbXXCFk77rr7b'
  + '/GTqInbVd5v/HBEvT13HRPft+uHeqYuowD/uu81bUhexq77bvCAiXpO6jokenLoAgDOUvqEovX6WkcMdaHqTNVTTZ8IWT'
  + 'vrNiPiW1EWM8Lci4kOpi5joE1IXULj/GxH/PHURI/zNiPhI6iImuEfXD380dRGZy2FxDBeprUdrWaTXchzMa+3z9eDUH8'
  + 'pV2vyVVu+ZhC2c9K05PlT0Mn23eVtEvCB1HRO5Q2Cab+m7zR+kLmJffbf5uYh4ceo6JtK7QC6qWJyfUNvxMI/DqC8khSo'
  + 'JWzj21oj4j6mLmOBfR8Qfpi5iggekLqBgt0fED6UuYoLvSl3ARLelLgCgYgIXznMYghd2U+p1pNS67yRs4djzS7wz4Fjf'
  + 'bd4ZZW+4r6QuoGD/qu82xS40+m7z8xHRp65jgvst+L2L/yELmSv22nmGmq8XNR8b8xC6QIaELURsP8XlB1IXMYOXpi5gg'
  + 'iU3rDW7HmWHbMdKPv/0LpBaC2FEC8e4pFbG7zDc8cKNSu/9ousXthAR8Yq+2/xO6iJm0EfE+1MXMdIfSV1Aof5Tic8ZOs'
  + 'OPxDb0LNF9UxcANK3ohTgsTPACCQlbiIh4ZeoC5tB3m9+PiB9NXcdI90hdQKF+JHUBc+i7zfWIeG3qOkbycwRgHYIlpnD'
  + 'XS3tcMxKzSOajEfFjqYuY0U+lLmCke6UuoEB3RMT/Sl3EjF6XuoCR3NkCpGIjAeMJX2Bhwhbe2Heb96QuYkZvSF0Aq/mJ'
  + 'vtt8KHURM3p96gKApthclUnAxFKEL+Sq2OuesIU3pi5gZm+JiI+kLoJV/HTqAmb2M6kLYDEWrTC/YhffJKNn9iN4gYmEL'
  + 'bwldQFzOvr46nelroNV/GzqAuZ09JDq301dBwDZExqwNsFLeVwnMiBsoaqw5cjbUxfAKm5PXcAC3p66AIAC2ERAOoIX2J'
  + 'GwhV9OXcAC3NlSvzv6bvPrqYtYwDtTFwBAEQRO4xi3eQle4ALClra9++jjkmtzPXUBLK7WUOIDqQvIkIUxzM/GCJib0AV'
  + 'OEba07TdSF7CQGgMkblTr3UsfTF0Ai7EAhXkIYJlKDy1L6AJHhC1tqzVseX/qAljcb6UuYCF6F4BdCQ3ImdCF5glb2uYt'
  + 'C5TqfakLWMhHUxcAAA0QVK1H6EKzhC1t85YFSvWHqQsAAGBnQheaI2xp2x2pC4CRvN2GEllkwjTuRmBO+ikNoQvNELa07'
  + 'cOpC4CRvN2mLRbEAFAXgctyrJsyIWwBAIAy2VRNY/zScpcLVRO2AMB6LCoB8iJwSc/PRqokbAEAAFomcElP4EJ1hC0AsC'
  + '4LSgC4mbcVURVhCwAl8FtHAJbk50w+BC5UQdgCAAAgcMmJwIXiCVsAYH0WkQB5Erjkw89KiiZsAQAAuIvABZhM2AJAKWp'
  + 'b/PqNHUC+DqK+nzsl8rOSYglbAAAAziZwSU/gQpGELQCQjgUkQP4ELun5eUlxhC0AlKTGBa8FJED+vK0I2IuwBQDgLsIv'
  + '4CICl3RcnymKsAUA0rOAzMthmBPKoE/TcJdLOnqeYghbAChNrQtcC8j0Ts+B0AW4iNAFOJewBQDg4lBF4JIH80CuhC7rc'
  + 'i2gCMIWAMiHBWS+3OUCXEboAtxJ2AJAiWpezNrQr2+fMRe6AJcRuizPdZjsCVsAID8WkesZO9ZCF1LTf/k7CMELNEvYAg'
  + 'B5spFa3hxjXOI8lbzxK3G8IULwAs0RtgBQKgtWpphz0+4uF2Afgpd5uO6SNWELAOTLQrIsQhfWos/qIXiBSglbAChZC4t'
  + 'Tm6r5LT2mQpdlGVtqJXiBighbACB/Nu/zWXMczRlL0FdtELzsxvlAtm5NXQAAsLPDsPAeK9WC/Ph1zdu8nAu05HSvCxig'
  + 'AO5sAaB0rW24LLL3l8OY5XZ3UmvnTS1y6iHScdcLFEDYAgDlseHaXW5jlVs9JTOWcGPwInyBjAhbAKhBiwvM3O6UyFGu4'
  + '2Pu5tPSOLZ0rDVZ+3wXvEAmhC0AUDYbsJuVEmaUUifp6ZMyHZ7z72tpJXhxfpAlYQsAtah9MXkRm/a7lDgOqeavlnOmxD'
  + 'mnbjlek1sIXSArwhYAqEdui/s15bi52Vfp9adU89jVfGy1KeE6JHSBlQhbAKiJBWQZi/051Xa8tR3PmmoctxqPqUa7nrc'
  + '5zafQBRYmbAGgNhaPW7Vv2h0fZ6lpzGo6lpqVPk9+ZsJChC0AULfDqGvjXtOx7GLp461xo1VDf9RwDLUbe27mOLc1Xgcg'
  + 'OWELADWycDxbyUFFybXPoeVjH6Pk8Sq59hbMcS0yx9CAW1MXAACs7uRCP+dgyobkRsfjkfOc5eQwyhsrPZ+v2ufmIOo/R'
  + 'liVsAWAWlk47ub0GKXcnJqv3cwdutR8rpQSuNQ6/rVYYn5K6U1gJGELADWreRO5lPPGa+5NgXlhLbnfEeRcyNfScyNwgY'
  + 'oJWwCAXdgQ5sUGbX+5hS7OqXytOTe5BC76EWbmAbkA1C6HRSzMaYmebuk8Sf2w5dSvz/lSzY1+gAq5swWAFng7EXDamg+'
  + 'Kdv3JX+o5SnnnVepjhyoJWwAAyrHkRqzlUHLu4KXVcSxRbnO19tuKcjt+qIawBYBWtLyRpA4tvdUnJdeJNuQ8z2vd5ZLz'
  + 'GEDxhC0AtETgAhdzjlC7kvp7ibe6lXT8UDRhCwBA/tzVAtOVHDScrn3Xa0LJx7wr10eyJGwBoDV+c09p1t5IOEeoTY39X'
  + 'OMxQVV89DMALfJbMEqhV2E8H7MNJCNsAaBVNrFwPucHJROyAMkJWwBomQ0lOdOfsD8hC5AFYQsArbOhJUc59GUONcCu3M'
  + '3SJtcpsiVsAQDIi80DABRO2AIANrfkI7dezK0eOI9eBbIibAGALQt1Usu1B3OtCwCyJWwBgLvYVJKK3oPpnEdtMd9kTdg'
  + 'CADeyeGNtJfRcCTUCQDaELQBwMxtLuJnzghLo0zaYZ7InbAGAs1nIsbSD0GcAUCVhCwCcz0aYpZTaW6XWTVv0KZCcsAUA'
  + 'LmbRztxK76nS66cN+rRe5pYiCFsA4HIWdsylll6q5TgAYBHCFgDYjedrMJX+gXU55+pjTimGsAUA9mOhxxg19k2Nx0R99'
  + 'Gk9zCVFEbYAwP4s+NhHzf1S87FRD30KrE7YAgDjWLyzixb6pIVjBNJynaE4whYAGM9zXDhPa73R0rFSJj1aLnNHkYQtAD'
  + 'CdhSAntdoPrR435dCjwGqELQAwD4t4IvRB68dP/vRoWcwXxRK2AMB8WnvrCHcx93cxDuROj5bBPFE0YQsAzM8CsS3m+2b'
  + 'GhNzp0byZH4onbAGAZbjToX7m+GLGZnnGeBrjlyfzQhWELQCwLIvG+ghZdmeclmNs52Ec82I+qIawBQCWZ3NeD/O4P/0/'
  + 'P+M5Lz2aB3NAVYQtALAeC/pymbvpjN88jONyjG06xp7qCFsAYH027uUwV/MyltMYv+UZ43W5xlKtW1MXAAANO15gHiatg'
  + 'rNY/C9H34+jJ9ejR9ehp6maO1sAID2/2cuHuViPcd6dsUrDuC/DdZYmuLMFAPLht6npWPinoecvpi/T06Pz0tM0Q9gCAP'
  + 'mxuF+PhX8e9PzN9GZe9Og0+pnmCFsAIF8nF6cW+POx6M/XQeh1/Zk3oct+9DPNErYAQBks8Kez6C9Dq72uP8vSap/uSj/'
  + 'TPGELAJTF3S77seAvVyu9rkfLJnS5i16GE26NiG9MXcREH0xdwJH/HhHvS13Ent6SuoCFmIv9vCIi3p7w9cd4Q+oCFmIu'
  + '2Nfpha3F/pYFf31q3NDq07q0Eg6eRS/DGZwYAGTp+rVrqUsoXUuL/SbXM1euXh31dRWdWyX2eJO9epax/XuejPu6xD7dR'
  + 'Va9PHc/wRy8jQgA6nTWQriWRX9Wi3ySKeVOAv3atlruQNTHsCdhCwC0o8QAxgKfXeS0odWzXCSnXj2PHoYZCFsAoG0XLa'
  + 'rX2ARY1LOE8/pqzp7Wu8wh1TVY/wIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
  + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
  + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAZQ5SFzDG9WvXDvf465ce45WrVydUAze7fu1ay'
  + 'pff+/xwDpCbfa7zV65eLfJnGQAA9br15H/sGWKswiIaWNuCYdmu19hJ1z3hGQAApHXr5X8FgBGmhNenv1boDAAABRG2AM'
  + 'xriTsEj7+n0AUAAAogbAGYxxpvwxS6AABAAe6WugCAwh3GOkHL6dcEAAAyJWwBGC9l6JEi5AEAAHYgbAEYJ5egI5c6AAC'
  + 'AI8IWgP3lFnDkVg8AADRN2AKwn1yDjVzrAgCA5ghbAHYn0AAAAC7lo58BdjNn0HL6o5vn+t6HZ3zvIl25erWK4wAAoE1T'
  + 'whYLYaAVc4QhF10z5wxfqglcAACgVO5sAVjWmODj+Gu8bQkAAArkmS0AF5sSeEy9w2RKUAMAACTizpZTrl+7ts/GauqmZ'
  + 'u/XunL16p3/w8q1Ruy/6Rz7mmM2t6OP7+SYJrb2fF7o+rVrY7+01fk7ac75OYjdxvTM1xwxj2PDpVHHfN787XN9O/l8l6'
  + 'PjzfnaOOb1spgTAAB2J2xpRwnPcZj6nIpjuR/nSWOP+fjrctqOmnEAAAcpSURBVDnWqW93yXX+cnkbz2WBy5Qxm/PhvMd'
  + 'ymsPLTL02LtUjc3zfUucEAKB4wpay7fob72NTNhVL3tUy92YltyDiPHNuplId6xIbzdTHNNVSdZ91vucYEpz83qXOYUpL'
  + 'hzfmBABgBZ7Zwi6WCloOR3zvfSz9/cdaoq4Ux7n0a6aev1XfDrXia605rqnmb9+xmXqH2a4uqmuteUl9XgEANEHYUr61N'
  + 'hVzW7OOXI45Yp27CdZQ62vl7uDEn32l2mTXGrjMFbS0Ni8AAE0QttRhyU3FEne1tLqxWOu31jW8Rg6vuS9vzzhfCfOXgn'
  + 'EBAKiUsKUeS2z0aglacnjtWpg/xkoxf0sF0XNdG1MHdM4pAICFCFva1erdCalqyOHYp8rhGHJ91kjqTfMuSqhxCXMHLrX'
  + '1Rg7nNQBAdXwaUV3m/HSiuTcULS/oazj2KcdwVm9M/Zjv3DewpVvqLrVUc7fvtXHO193l7+xam+ssAEAhpoQtSyzqbKCm'
  + 'myNwyfE3tydfY2rvlbhZn/P49zX29S4a45TH07LLrg/7nBepAow1nHeNyDWwOf13xzzst7RrIgBA1ryNiKmWfk7LWZ+8M'
  + 'uUTWY7l+naUk847zqnHvoYxG8R91LrJT2VsT+XehydNfTvRkiH0WX93ynle0rwAAFRJ2FKnKZuKXDaxu240Sggexsjl2M'
  + 'f0Q+2b9tNKq/3g1L9Prb+kj59fa67WHtOlvgcAACMJW+o1ZgOUy6cPrbVZX3rTt8Tbb+b4+0uaUktJm/bSzR3U5dSDczo'
  + '89c8lzXHHHgAAmRC21G3JRftS37v1jUbJbxvIoQa4zNKhXqnngfASAGBGwhaWtPbivdRNzlzmPv7cHwYKY7USFudWDwBA'
  + 'M3z0c/2W+MSQnDcqJX9CSskbo5Jr52znnUe1zPXc14o1xmWuT5ICAGBhwpY2zLmpqG1BX9tHnpYcNo2Rw/zlUMNU+35c/'
  + 'GmlH3+uzAsAQKGELezDwn13rTzn4VhLAU9N5pq3Uud/rnByzvN3zrEsdV4AAIrnmS3tyH0zn3t9UJMxnz5Wqxw+pvmYOQ'
  + 'EAqMSUO1tsjssz5be4Jc13a2+lgX04N/JjTgAAKuPOFnZRUtACc1j644FTKaXOtaX8yHVzAgBQIWELu7AZgPI5j/NjTgA'
  + 'AKuUBuW2ZsrAv6RNXbGBIIedzZKm3D9Zyro09jlRzftFr1jInAABFE7YAnC3XZ/8c17TkJn/X733y7+U4VruYWvfYwGXM'
  + '6+7yOqf/TqnzAgBQNGFLO+ZYcC/5W9yc7woYI9eN+lJqmrsplu7jwzP+fe67T1qayzk/+jqH8AsAgEx4Zss0pWym56yzl'
  + 'GPeVS6bmNLHtfT657T2WMz5ermcDwAAUDRhSzprbchK2gTPdfcNzCWn8OGi3j685P/nZnOP1z7fb5+/m1MPAgCwI2FLGq'
  + 'Vvinat3yZhP7mFTeZvvLnDj12/15TXbWm+l7oGl35tBwBgJsKWui258M9xs1LDRqfk45/z+Rc53akxJYSY4+GruYxDLVp'
  + '6ho3eAQBIRNgy3b63juf69qGD2H9TkVPgstQne0wx9vuvdfxLmCNcOP3fOYQvKQKXkp79kkv/5SCXscilDgCAJrUQtqzx'
  + 'W8xdXmPuTeicr5Xjp2is8baJnC053/t87Vofabvr1+UQvIyxa90lHl9J4eDYa2PKILqk8QUA4EgLYUvEzZuXJTY0532vO'
  + 'V9njQV0Lne3HH/vpcZ1rbcGTL0j4rLjz+05L2O/Z04B4dKvefpunbmvR2vdhTO15rVDpak9Nue1ccnrbElzAgBQrVtTF7'
  + 'CypReROSxS59i0Huz5fQ7P+T5jv99Z379lqY9/7Pwdf815vZH6uMaY2stLmyMQqnHeamBeAAAK0lrYUrs57w7ILXCZ09p'
  + '3ReR07GNNOYa5jz31A0tznc/zxmVqWFaDua6Nc18Xx6hpXgAAqtXK24guctbCNfVmrlY5jGuqGnI49hrkMo651HEst3py'
  + 'Mvdb1OZ6C5A5AwComLAlvTmfAzH36+b0/JY52NxMY/xulMt4LHEu12KpZwHVdm0EAGBmpYYta2wcSnqNJR8uOvemouU7S'
  + '9asYanXSjmOOczhaalrSv36p+VWT+5K+jkDAMAeSg1balBC0LKUg6gjeBhjjWNf4/uvPX85zeFpKeob85ql992+lr42lv'
  + 'B2orEfXQ0AwEQlhy2l/kYw943jWZa6Zb7UOZzDGr1Vw+Y61/k7y1pB2pTXaOWatlYInXPgktucAAA0peSwJWK9zd5cr3P'
  + 'RJ1yMseZdLUsGLq1sAE9bo7eW1vL8nWeJ2ufulSX7rtR5S23p+TUvAAArquGjn48XkGvcMj7mIYf7PnBxqQcpzrHQXvIj'
  + 'b0/WN/Y1St1MTJn7XT85ZekHdM7xOqXO33mm9vRadyYt9XDtVA+FXfutlfse60UfB73kteD473hYLwDACv4/RJx6DFzWY'
  + 'mYAAAAASUVORK5CYII=';

export default {
  name: 'PixBillingPDFButton',
  props: {
    timeToExpire: Number,
    requestId: Number,
    qrcode: String,
    name: String,
    value: String,
    logoPath: String,
    expirationDate: String,
    extras: Array,
  },
  data() {
    return {
      qrcodeDataURL: '',
      logoDataURL: '',
    };
  },
  mounted() {
    const imgQrcode = new Image();
    const imgLogo = new Image();

    imgQrcode.setAttribute('crossOrigin', 'anonymous');
    imgLogo.setAttribute('crossOrigin', 'anonymous');

    imgQrcode.onload = () => {
      this.qrcodeDataURL = this.loadedImageTodDataurl(imgQrcode);
    };

    imgLogo.onload = () => {
      this.logoDataURL = this.loadedImageTodDataurl(imgLogo);
    };

    imgQrcode.src = this.qrcode;
    imgLogo.src = this.logoPath;
  },
  methods: {
    loadedImageTodDataurl(img) {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      return canvas.toDataURL('image/png');
    },
    generatePdf() {
      const lineBreak = '\r\n';

      const extrasTable = {
        layout: 'noBorders',
        margin: [0, 10],
        table: {
          headerRows: 0,
          widths: [80, '*'],
          body: this.extras.map((extra) => [
            {
              text: extra.label,
              style: 'label',
            },
            {
              text: extra.value,
              style: 'smallText',
            },
          ]),
        },
      };

      const docDefinition = {
        pageOrientation: 'portrait',
        footer: () => ({
          layout: 'noBorders',
          table: {
            style: {
              background: '#2C2E3E',
            },
            widths: ['*'],
            heights: ['*'],
            body: [
              [
                {
                  text: 'Powered by',
                  color: '#fff',
                  fillColor: '#2C2E3E',
                  alignment: 'center',
                  margin: [0, 12, 0, 0],
                  style: {
                    fontSize: 10,
                  },
                },
              ],
              [
                {
                  image: INNCASH_LOGO,
                  width: 60,
                  fillColor: '#2C2E3E',
                  margin: [0, 0, 0, 12],
                  alignment: 'center',
                },
              ],
            ],
          },
        }),
        pageMargins: [20, 30, 20, 60],
        content: [
          {
            width: 240,
            image: this.logoDataURL,
            margin: [0, 0, 0, 16],
            alignment: 'center',
          },
          lineBreak,
          lineBreak,
          {
            image: this.qrcodeDataURL,
            width: 180,
            alignment: 'center',
            style: 'image',
          },
          lineBreak,
          lineBreak,
          [
            {
              text: `Este é o QR Code para realizar o pagamento de R$${this.value} referente aos dados abaixo:`,
              style: 'text',
              alignment: 'justify',
            },
            extrasTable,
            {
              text: 'Para realizar o pagamento, escaneie o QR Code com seu app de pagamento.',
              style: 'text',
              alignment: 'justify',
            },
            {
              text: `Código válido até ${this.expirationDate}.`,
              style: 'text',
              alignment: 'justify',
            },
          ],
        ],
        styles: {
          header: {
            fontSize: 22,
            bold: true,
            color: '#575962',
          },
          text: {
            fontSize: 16,
            color: '#575962',
          },
          label: {
            fontSize: 10,
            bold: true,
            color: '#575962',
          },
          smallText: {
            fontSize: 10,
            color: '#575962',
          },
          footer: {
            background: '#2C2E3E',
          },
          'extras-table': {
            'margin-top': '22px',
            'margin-bottom': 22,
          },
          'footer-text': {
            fontSize: 10,
            color: '#FFF',
          },
        },
      };
      pdfMake.createPdf(docDefinition).download(`pix-${this.requestId}`);
    },
  },
};
</script>

<style></style>
