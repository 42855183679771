import Rest from './base/Rest';

export default class CompanyService extends Rest {
	static resource = 'company';

	listAll() {
        this.relationship = 'listAllActv';
        return this;
    }

    listOne() {
        this.relationship = 'listOne';
        return this;
    }

    listAllbyGroup() {
        this.relationship = 'listAllByGroup';
        return this;
    }

    listAllByUser() {
        this.relationship = 'listAll';
        return this;
    }
}
