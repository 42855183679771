<template>
  <div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title"></div>
      </div>
      <div class="m-portlet__head-tools">
        <router-link
          :to="{ name: 'grouperiten' }"
          class="m-btn btn btn-warning mr-3"
          >Cancelar</router-link
        >
        <button id="btn-save" type="button" class="m-btn btn btn-success">
          Salvar
        </button>
      </div>
    </div>
    <div class="m-portlet__body">
      <!--begin: Search Form -->
      <div
        class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
      >
        <div class="row" id="frmGrouper">
          <input
            type="hidden"
            name="company_group_id"
            id="company_group_id"
            v-model="company_group_id"
          />
          <input
            type="hidden"
            name="grouper_iten_id"
            id="grouper_iten_id"
            v-model="grouper_iten_id"
          />
          <input
            type="hidden"
            name="company_group_erp"
            id="company_group_erp"
            v-model="company_group_erp"
          />
          <div class="col-md-8">
            <div class="form-group">
              <label>Empresa</label>
              <select
                class="form-control select2 hide"
                field-name="Empresa"
                id="company_id"
                name="company_id"
                style="width: 100%"
              >
                <option value="">Todas</option>
                <option
                  v-for="(company, idx) in companys"
                  :key="idx"
                  :value="company.id"
                  :erp-code="company.erp_company_code"
                >
                  {{ company.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Agrupador</label>
              <select
                class="form-control select2 hide"
                field-name="Agrupador"
                id="grouper_id"
                name="grouper_id"
                style="width: 100%"
                required
              >
                <option value="">Selecione...</option>
                <option
                  v-for="(grouper, idx) in groupers"
                  :key="idx"
                  :value="grouper.id"
                  :groupper-type="grouper.type"
                  :table-name="grouper.table_mooring"
                >
                  {{ grouper.description }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Descrição</label>
              <input
                type="text"
                class="form-control"
                field-name="Descrição"
                id="description"
                name="description"
                required
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Código ERP</label>
              <input
                id="erp_code"
                class="form-control"
                name="erpCode"
                type="text"
                maxlength="15"
                field-name="Código ERP"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form_group">
              <label>Onde Mostrar?</label>
              <select
                class="form-control select2 hide"
                field-name="Onde Mostrar"
                id="where_show"
                required
                name="where_show"
                style="width: 100%"
                multiple="multiple"
              >
                <option value="1">Fluxo Previsto</option>
                <option value="2">Fluxo Realizado</option>
                <option value="4">Fluxo Projetado</option>
                <option value="3">Faturamento</option>
              </select>
            </div>
          </div>
          <div class="col-md-3" v-show="showElement()">
            <div class="form-group">
              <label>Valor editável pelo Usuário</label>
              <select
                class="form-control select2 hide"
                field-name="Valor editável pelo Usuário"
                id="user_edit_value"
                name="user_edit_value"
                style="width: 100%"
                required="showElement()"
              >
                <option value="">Selecione...</option>
                <option value="S">Sim</option>
                <option value="N">Não</option>
              </select>
            </div>
          </div>
          <div class="col-md-3" v-show="showElement()">
            <div class="form-group">
              <label>Simulação</label>
              <select
                class="form-control select2 hide"
                field-name="Simulação"
                id="simulation"
                name="simulation"
                style="width: 100%"
                required="showElement()"
              >
                <option value="">Selecione...</option>
                <option value="S">Sim</option>
                <option value="N">Não</option>
              </select>
            </div>
          </div>
          <div class="col-md-3" v-show="showElement()">
            <div class="form-group">
              <label>Calcula juros</label>
              <select
                class="form-control select2 hide"
                field-name="Calcula juros"
                id="calculate_interest"
                name="calculate_interest"
                style="width: 100%"
                required="showElement()"
              >
                <option value="">Selecione...</option>
                <option value="N">Não calcula</option>
                <option value="S">Soma</option>
                <option value="D">Subtrai</option>
              </select>
            </div>
          </div>
          <div class="col-md-3" v-show="showElement()">
            <div class="form-group">
              <label>Taxa de juros (%a.m.)</label>
              <input
                type="text"
                class="form-control"
                field-name="Taxa de juros (%a.m.)"
                id="interest_rate"
                name="interest_rate"
                required="showElement()"
                style="text-align: right"
              />
            </div>
          </div>
          <div class="col-md-3" v-show="showElement(grouper)">
            <div class="form-group">
              <label>Calcula Inadimplência (CR)</label>
              <select
                class="form-control select2 hide"
                field-name="Calcula Inadimplência"
                id="calculate_default"
                name="calculate_default"
                style="width: 100%"
                required="showElement(grouper)"
              >
                <option value="">Selecione...</option>
                <option value="S">Sim</option>
                <option value="N">Não</option>
              </select>
            </div>
          </div>
          <div class="col-md-3" v-show="showElement(grouper)">
            <div class="form-group">
              <label>% Inadimplência (CR)</label>
              <input
                type="text"
                class="form-control"
                field-name="% Inadimplência"
                id="default_rate"
                name="default_rate"
                required="showElement(grouper)"
                style="text-align: right"
              />
            </div>
          </div>
          <div class="col-md-3" v-show="showElement(grouper)">
            <div class="form-group">
              <label>Mostrar agrupador sem dados</label>
              <select
                class="form-control select2 hide"
                field-name="Mostrar agrupador sem dados"
                id="show_no_data"
                name="show_no_data"
                style="width: 100%"
                required="showElement(grouper)"
              >
                <option value="">Selecione...</option>
                <option value="S">Sim</option>
                <option value="N">Não</option>
              </select>
            </div>
          </div>
          <div class="col-md-3" v-show="showElement(grouper)">
            <div class="form-group">
              <label>Calcula projeção</label>
              <select
                class="form-control select2 hide"
                field-name="Calcula projeção"
                id="calculate_projection"
                name="calculate_projection"
                style="width: 100%"
              >
                <option value="">Selecione...</option>
                <option value="1">Não calcula</option>
                <option value="2">Histórico médio Previsto</option>
                <option value="3">Histórico médio Realizado</option>
              </select>
            </div>
          </div>
          <div class="col-md-3" v-show="showElement(grouper) && showQuantity">
            <div class="form-group">
              <label>Qtd. meses para média</label>
              <input
                type="text"
                class="form-control"
                field-name="Qtd. meses para média"
                id="quantity_month_avarage"
                name="quantity_month_avarage"
                style="text-align: right"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Status</label>
              <select
                class="form-control select2 hide"
                field-name="Status"
                id="status"
                name="status"
                style="width: 100%"
              >
                <option value="0">Inativo</option>
                <option value="1">Ativo</option>
              </select>
            </div>
          </div>
          <div class="col-md-12" style="margin-top: 20px">
            <h4 v-show="showElement()">Data a ser considerada</h4>
          </div>
          <div class="col-md-4">
            <div class="form-group" v-show="showElement()">
              <label>Campo</label>
              <select
                class="form-control select2 hide"
                field-name="Campo"
                id="considered_date_field"
                name="considered_date_field"
                style="width: 100%"
                required="showElement()"
              >
                <option value="">Selecione...</option>
                <option
                  v-for="(consideredField, idx) in consideredFields"
                  :key="idx"
                  :value="consideredField.fieldName"
                  :selected="consideredField.fieldName == considered_date_field"
                >
                  {{ consideredField.fieldDescription }} ({{
                    consideredField.fieldName
                  }})
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-show="showElement()">
            <div class="form-group">
              <label>Operador</label>
              <select
                class="form-control select2 hide"
                field-name="Operador"
                id="considered_data_operator"
                name="considered_data_operator"
                style="width: 100%"
              >
                <option value="">Nenhum</option>
                <option value="S">Soma</option>
                <option value="D">Diminui</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-show="showElement()">
            <div class="form-group">
              <label>Quantidade (D+1)</label>
              <input
                type="text"
                class="form-control"
                field-name="Quantidade (D+1)"
                id="considered_date_quantity"
                name="considered_date_quantity"
              />
            </div>
          </div>
          <div class="col-md-4" v-show="showElement()">
            <div class="form-group">
              <label>Mostra datas futuras</label>
              <select
                class="form-control select2 hide"
                field-name="Mostra datas futuras"
                id="considered_future_date"
                name="considered_future_date"
                style="width: 100%"
              >
                <option value="S">Sim</option>
                <option value="N" selected>Não</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" v-show="showElement()">
            <div class="form-group">
              <label>Mostra Vencidos</label>
              <select
                class="form-control select2 hide"
                field-name="Mostra Vencidos"
                id="considered_expired_date"
                name="considered_expired_date"
                style="width: 100%"
              >
                <option value="S">Sim</option>
                <option value="N" selected>Não</option>
              </select>
            </div>
          </div>
          <div class="col-md-12" style="margin-top: 20px">
            <h4>Filtros</h4>
          </div>
          <div class="col-md-4">
            <input type="hidden" name="editing" id="editing" value="" />
            <div class="form-group">
              <label>Campo</label>
              <select
                class="form-control select2 hide"
                field-name="Campo"
                id="erp_field"
                name="erp_field"
                style="width: 100%"
              >
                <option value="">Selecione...</option>
                <option
                  v-for="(filterField, idx) in filterFields"
                  :key="idx"
                  :value="filterField.fieldName"
                >
                  {{ filterField.fieldDescription }} ({{
                    filterField.fieldName
                  }})
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Operador</label>
              <select
                class="form-control select2 hide"
                field-name="Operador"
                id="erp_operator"
                name="erp_operator"
                style="width: 100%"
              >
                <option value="">Selecione...</option>
                <option value="=">Igual a</option>
                <option value="<">Menor que</option>
                <option value="<=">Menor ou igual a</option>
                <option value=">">Maior que</option>
                <option value=">=">Maior ou igual a</option>
                <option value="LIKE">Contém a expressão</option>
                <option value="NOT LIKE">Não contém</option>
                <option value="<>">Diferente de</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Expressão</label>
              <input
                type="text"
                class="form-control"
                field-name="Expressão"
                id="expression"
                name="expression"
              />
            </div>
          </div>
          <div class="col-md-2" style="padding-top: 25px; text-align: right">
            <button
              type="button"
              id="addFilter"
              class="btn m-btn--pill btn-success"
            >
              <i class="la la-plus"></i>
            </button>
          </div>
          <div class="col-md-3" style="margin-bottom: 20px; padding-top: 6px">
            <h4>Outros operadores:</h4>
          </div>
          <div class="col-md-9" style="margin-bottom: 20px">
            <button
              type="button"
              class="addOperators btn m-btn--pill btn-success"
              data-operator="("
            >
              (
            </button>
            <button
              type="button"
              class="addOperators btn m-btn--pill btn-success"
              data-operator=")"
            >
              )
            </button>
            <button
              type="button"
              class="addOperators btn m-btn--pill btn-success"
              data-operator="AND"
            >
              E
            </button>
            <button
              type="button"
              class="addOperators btn m-btn--pill btn-success"
              data-operator="OR"
            >
              OU
            </button>
          </div>
          <div id="filters-iten" style="width: 100%"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      companys: [],
      groupers: [],
      grouper: {},
      filterFields: [],
      considered_date_field: "",
      grouper_iten_id: this.$route.params.grouper_iten_id,
      company_group_id: this.$session.get("company_group_id"),
      company_group_erp: this.$session.get("erp_company_group_code"),
      whereShow: "",
      showQuantity: false,
    };
  },

  mounted: function () {
    this.loadCompanys();
    this.loadGroupers();
    this.loadMethod();

    setTimeout(this.loadGrouperIten(), 1000);
  },

  computed: {
    consideredFields() {
      return _.filter(this.filterFields, ["fieldType", "D"]);
    },
  },

  methods: {
    loadCompanys: function () {
      blockPage("Carregando os dados...");

      this.$http
        .get(this.$store.state.API_BASE + "company/listAll", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          (result) => {
            this.companys = result.body.data;
            unblockPage();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },

    loadGroupers: function () {
      this.$http
        .get(this.$store.state.API_BASE + "grouper/listGrouper", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          (result) => {
            this.groupers = result.body.data;
            unblockPage();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },

    loadGrouperIten: function () {
      var self = this;

      $.ajax({
        method: "GET",
        url:
          self.$store.state.API_BASE +
          "grouperiten/listOne/" +
          self.$route.params.grouper_iten_id,
        headers: {
          token: self.$session.get("token"),
        },
      })
        .done(function (result) {
          var totFilters = 0;
          var whereShow = [];
          unblockPage();

          $("#company_id").val(result.data.grouper_iten[0].company_id);
          $("#description").val(result.data.grouper_iten[0].description);
          $('#erp_code').val(result.data.grouper_iten[0].erp_code);
          $("#grouper_id").val(result.data.grouper_iten[0].grouper_id);
          $("#user_edit_value").val(
            result.data.grouper_iten[0].user_edit_value
          );
          $("#simulation").val(result.data.grouper_iten[0].simulation);
          $("#calculate_interest").val(
            result.data.grouper_iten[0].calculate_interest
          );
          $("#interest_rate").val(
            numeral(result.data.grouper_iten[0].interest_rate).format("0,0.00")
          );
          self.considered_date_field =
            result.data.grouper_iten[0].considered_date_field;
          $("#considered_data_operator").val(
            result.data.grouper_iten[0].considered_data_operator
          );
          $("#considered_date_quantity").val(
            result.data.grouper_iten[0].considered_date_quantity
          );
          $("#considered_future_date").val(
            result.data.grouper_iten[0].considered_future_date
          );
          $("#considered_expired_date").val(
            result.data.grouper_iten[0].considered_expired_date
          );
          $("#show_no_data").val(result.data.grouper_iten[0].show_no_data);
          $("#calculate_projection").val(
            result.data.grouper_iten[0].calculate_projection
          );
          $("#quantity_month_avarage").val(
            result.data.grouper_iten[0].quantity_month_avarage
          );
          $("#status").val(result.data.grouper_iten[0].status);

          $("#calculate_default").val(
            result.data.grouper_iten[0].calculate_default
          );
          $("#default_rate").val(
            numeral(result.data.grouper_iten[0].default_rate).format("0,0.00")
          );

          whereShow = result.data.grouper_iten[0].where_show.split(";");
          $("#where_show").val(whereShow);
          $("#where_show").trigger("change");

          $("#grouper_id").trigger("change");

          var companyText = $("#company_id option:selected").text();
          if (companyText == "") {
            companyText = "Todas";
          }

          $("#select2-company_id-container").attr("title", companyText);
          $("#select2-company_id-container").html(companyText);

          $("#select2-grouper_id-container").attr(
            "title",
            $("#grouper_id option:selected").text()
          );
          $("#select2-grouper_id-container").html(
            $("#grouper_id option:selected").text()
          );

          $("#select2-user_edit_value-container").attr(
            "title",
            $("#user_edit_value option:selected").text()
          );
          $("#select2-user_edit_value-container").html(
            $("#user_edit_value option:selected").text()
          );

          $("#select2-simulation-container").attr(
            "title",
            $("#simulation option:selected").text()
          );
          $("#select2-simulation-container").html(
            $("#simulation option:selected").text()
          );

          $("#select2-calculate_interest-container").attr(
            "title",
            $("#calculate_interest option:selected").text()
          );
          $("#select2-calculate_interest-container").html(
            $("#calculate_interest option:selected").text()
          );

          $("#select2-show_no_data-container").attr(
            "title",
            $("#show_no_data option:selected").text()
          );
          $("#select2-show_no_data-container").html(
            $("#show_no_data option:selected").text()
          );

          $("#select2-calculate_projection-container").attr(
            "title",
            $("#calculate_projection option:selected").text()
          );
          $("#select2-calculate_projection-container").html(
            $("#calculate_projection option:selected").text()
          );
          $("#calculate_projection").trigger("change");

          $("#select2-quantity_month_avarage-container").attr(
            "title",
            $("#quantity_month_avarage option:selected").text()
          );
          $("#select2-quantity_month_avarage-container").html(
            $("#quantity_month_avarage option:selected").text()
          );

          $("#select2-status-container").attr(
            "title",
            $("#status option:selected").text()
          );
          $("#select2-status-container").html(
            $("#status option:selected").text()
          );

          $("#select2-considered_date_field-container").attr(
            "title",
            result.data.grouper_iten[0].considered_date_field_description
          );
          $("#select2-considered_date_field-container").html(
            result.data.grouper_iten[0].considered_date_field_description
          );

          $("#select2-considered_date_quantity-container").attr(
            "title",
            $("#considered_date_quantity option:selected").text()
          );
          $("#select2-considered_date_quantity-container").html(
            $("#considered_date_quantity option:selected").text()
          );

          $("#select2-considered_data_operator-container").attr(
            "title",
            $("#considered_data_operator option:selected").text()
          );
          $("#select2-considered_data_operator-container").html(
            $("#considered_data_operator option:selected").text()
          );

          $("#select2-considered_future_date-container").attr(
            "title",
            $("#considered_future_date option:selected").text()
          );
          $("#select2-considered_future_date-container").html(
            $("#considered_future_date option:selected").text()
          );

          $("#select2-considered_expired_date-container").attr(
            "title",
            $("#considered_expired_date option:selected").text()
          );
          $("#select2-considered_expired_date-container").html(
            $("#considered_expired_date option:selected").text()
          );

          $("#select2-calculate_default-container").attr(
            "title",
            $("#calculate_default option:selected").text()
          );
          $("#select2-calculate_default-container").html(
            $("#calculate_default option:selected").text()
          );

          $.each(result.data.grouper_iten_filter, function () {
            totFilters++;

            var html = "";

            if (this.type == "E" || $.trim(this.type) == "") {
              html =
                '<span id="iten_' +
                totFilters +
                '" itemId="' +
                totFilters +
                '" class="m-badge m-badge--primary m-badge--wide ui-draggable-handle" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-erp_field="' +
                this.field +
                '" data-erp_field_description="' +
                this.field_description +
                '" data-erp_operator="' +
                this.operator +
                '" data-erp_operator_description="' +
                this.operator_description +
                '" data-expression="' +
                this.expression +
                '">';
              html +=
                '<span class="text">' +
                this.field_description +
                " " +
                this.operator_description +
                ' "' +
                this.expression +
                '"' +
                "</span>";
              html +=
                '<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="' +
                totFilters +
                '"></i></span>';
              html += "</span>";
            } else if (this.type == "O") {
              html =
                '<span id="iten_' +
                totFilters +
                '" itemId="' +
                totFilters +
                '" class="operator m-badge m-badge--primary m-badge--wide ui-draggable-handle" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-operator="' +
                this.operator +
                '">';
              html +=
                '<span class="text">' + this.operator_description + "</span>";
              html +=
                '<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="' +
                totFilters +
                '"></i></span>';
              html += "</span>";
            }

            $("#filters-iten").append(html);
          });

          if (totFilters >= 1) {
            $("#grouper_id").attr("disabled", true);
          }
        })
        .fail(function (error) {
          console.log(error.responseText);
          $("#loading").css("display", "none");
        });
    },
    loadFilterFields: function (table, companyErpCode) {
      var self = this;

      self.filterFields = [];
      if ($.trim(table) != "" && $.trim(companyErpCode) != "") {
        blockPage("Carregando os dados...");
        const url = `${self.$store.state.API_BASE}fields`;

        $.ajax({
          type: "GET",
          url,
          headers: {
            token: self.$session.get("token"),
          },
          data: {
            table: table,
            company: companyErpCode,
          },
        })
          .done((result) => {
            if (result.error) {
              unblockPage();
              showNotification("Atenção", result.message, "danger");
              return false;
            }

            result.payload.forEach((element) => {
              self.filterFields.push({
                fieldDecimalPrecision: element.decimal_precision,
                fieldDescription: element.field_description,
                fieldName: element.client_field,
                fieldPrecision: element.precision,
                fieldType: element.field_type,
              });
            });

            var field = "TABLE_COMPANY_CODE";
            var fieldName = "TABLE_COMPANY_CODE";

            if (
              $("#grouper_id option:selected").attr("groupper-type") === "S"
            ) {
              field = "cp.TABLE_COMPANY_CODE";
            }

            if (
              $("#grouper_id option:selected").attr("groupper-type") === "E"
            ) {
              field = "rc.TABLE_COMPANY_CODE";
            }

            self.filterFields.push({
              fieldDecimalPrecision: 0,
              fieldDescription: fieldName,
              fieldName: field,
              fieldPrecision: 2,
              fieldType: "C",
            });

            unblockPage();
          })
          .fail(function (error) {
            console.log("error");
            console.log(error);
            showNotification(
              "Atenção",
              "Opa! Ocorreu um problema ao estabelecer a conexão com o ERP, por favor, tente novamente mais tarde.",
              "danger"
            );
            unblockPage();
          });
      }
    },

    loadMethod: function () {
      var self = this;

      numeral.language("pt-br");

      $(".select2").select2();
      $("#cnpj").mask("00.000.000/0000-00");
      $("#phone").mask("(00) 00000-0000");
      $("#zipcode").mask("0000-000");
      $("#interest_rate").mask("00.000.000.000,00", { reverse: true });
      $("#default_rate").mask("00.000.000.000,00", { reverse: true });

      $("#where_show").change(function () {
        self.whereShow = $("#where_show").val();
      });

      $("#considered_data_operator").change(function () {
        $("#considered_date_quantity").removeAttr("required");

        if (this.value != "") {
          $("#considered_date_quantity").attr("required", "required");
        }
      });

      $("#calculate_interest").change(function () {
        $("#interest_rate").attr("required", "required");

        if (this.value == "N") {
          $("#interest_rate").removeAttr("required");
        }
      });

      $("#calculate_default").change(function () {
        $("#default_rate").attr("required", "required");

        if (this.value == "N") {
          $("#default_rate").removeAttr("required");
        }
      });

      $("#calculate_projection").change(function () {
        self.showQuantity = false;

        if (this.value == "2" || this.value == "3") {
          self.showQuantity = true;
        }
      });

      $("#filters-iten").sortable({
        opacity: 0.5,
        cursor: "-webkit-grabbing",
        update: function (event, ui) {
          //self.saveGroupersOrder();
        },
      });

      $("#grouper_id").change(function () {
        var $this = $(this);
        var grouperId = $("#grouper_id").val();
        self.loadFilterFields(
          $("#grouper_id option:selected").attr("table-name"),
          $("#company_group_erp").val()
        );
        self.grouper = self.groupers.filter(function (grouperAux) {
          return grouperAux.id == grouperId;
        });
        self.grouper = self.grouper[0];
      });

      $(".addOperators").click(function () {
        var totFilters = 0;
        var operator = $(this).data("operator");
        var description_operator = $(this).text();

        $.each($("span", ".m-badge"), function () {
          if (!$(this).hasClass("text")) {
            totFilters++;
          }
        });
        totFilters++;

        $("#filters-iten").append(
          '<span id="iten_' +
            totFilters +
            '" itemId="' +
            totFilters +
            '" class="operator m-badge m-badge--primary m-badge--wide" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-operator="' +
            operator +
            '">' +
            '<span class="text">' +
            description_operator +
            "</span>" +
            '<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="' +
            totFilters +
            '"></i></span>' +
            "</span>"
        );
      });

      $("#addFilter").click(function () {
        var totFilters = 0,
          exit = false;

        if (
          $.trim($("#erp_field option:selected").text()) == "Selecione..." ||
          $.trim($("#erp_operator option:selected").text()) == "Selecione..."
        ) {
          exit = true;
        }

        if (exit) {
          showNotification(
            "Atenção",
            "Todos os campos do filtro devem ser preenchidos!",
            "warning"
          );
          return false;
        }

        $.each($("span", ".m-badge"), function () {
          if (!$(this).hasClass("text")) {
            totFilters++;
          }
        });
        totFilters++;

        if (totFilters >= 1) {
          $("#grouper_id").attr("disabled", true);
        }

        if ($.trim($("#editing").val()) != "") {
          var itemId = $("#editing").val();
          var $div = $("#iten_" + itemId);

          $div.data("erp_field", $("#erp_field option:selected").val());
          $div.data(
            "erp_field_description",
            $("#erp_field option:selected").text()
          );
          $div.data("erp_operator", $("#erp_operator option:selected").val());
          $div.data(
            "erp_operator_description",
            $("#erp_operator option:selected").text()
          );
          $div.data("expression", $("#expression").val());

          $div
            .find(".text")
            .text(
              $("#erp_field option:selected").text() +
                " " +
                $("#erp_operator option:selected").text() +
                ' "' +
                $("#expression").val() +
                '"'
            );
        } else {
          $("#filters-iten").append(
            '<span id="iten_' +
              totFilters +
              '" itemId="' +
              totFilters +
              '" class="m-badge m-badge--primary m-badge--wide" style="font-size: 1rem;margin-right: 10px;margin-top: 6px;margin-bottom: 6px;padding: 6px;cursor: pointer;" data-erp_field="' +
              $("#erp_field option:selected").val() +
              '" data-erp_field_description="' +
              $("#erp_field option:selected").text() +
              '" data-erp_operator="' +
              $("#erp_operator option:selected").val() +
              '" data-erp_operator_description="' +
              $("#erp_operator option:selected").text() +
              '" data-expression="' +
              $("#expression").val() +
              '">' +
              '<span class="text">' +
              $("#erp_field option:selected").text() +
              " " +
              $("#erp_operator option:selected").text() +
              ' "' +
              $("#expression").val() +
              '"' +
              "</span>" +
              '<span class="remove" style="margin-left: 10px;margin-right: 5px;"><i class="fa remove fa-trash-o" itemId="' +
              totFilters +
              '"></i></span>' +
              "</span>"
          );
        }

        $("#erp_field").val("");
        $("#select2-erp_field-container").attr("title", "Selecione...");
        $("#select2-erp_field-container").html("Selecione...");
        $("#erp_operator").val("");
        $("#select2-erp_operator-container").attr("title", "Selecione...");
        $("#select2-erp_operator-container").html("Selecione...");
        $("#expression").val("");
        $("#editing").val("");
      });

      $(document).on("click", "i.fa", function () {
        var $this = $(this);
        var itemId = $this.attr("itemId");
        var $div = $("#iten_" + itemId);

        if ($this.hasClass("remove")) {
          $("#iten_" + itemId).remove();

          var totFilters = 0;
          $.each($("span", ".m-badge"), function () {
            if (!$(this).hasClass("text")) {
              totFilters++;
            }
          });
          totFilters++;

          if (totFilters <= 0) {
            $("#grouper_id").attr("disabled", false);
          }
        }
      });

      $(document).on("click", "span.text", function () {
        var $this = $(this);
        var itemId = $this.parent().attr("itemId");
        var $div = $("#iten_" + itemId);

        if (
          $this.parent().hasClass("m-badge") &&
          !$this.parent().hasClass("operator")
        ) {
          $("#editing").val(itemId);

          $("#erp_field").val($div.data("erp_field"));
          $("#select2-erp_field-container").attr(
            "title",
            $div.data("erp_field_description")
          );
          $("#select2-erp_field-container").html(
            $div.data("erp_field_description")
          );

          $("#erp_operator").val($div.data("erp_operator"));
          $("#select2-erp_operator-container").attr(
            "title",
            $div.data("erp_operator_description")
          );
          $("#select2-erp_operator-container").html(
            $div.data("erp_operator_description")
          );

          $("#expression").val($div.data("expression"));
        }
      });

      $("#btn-save").click(function () {
        var arrayData = [],
          arrayDataFilter = [],
          exitButton = true,
          fieldName = "",
          i = 0,
          post = "",
          id = "",
          totExpression = 0,
          totOperator = 0,
          totOperatorCap = 0;
        var btn = $(this);

        btn
          .addClass("m-loader m-loader--right m-loader--light")
          .attr("disabled", true);

        $.each($("input, select", "#frmGrouper"), function () {
          var $this = $(this);
          var name = $this.attr("name");
          id = $this.attr("id");

          if (
            $.trim(this.value) == "" &&
            $this.attr("required") == "required" &&
            self.showElement()
          ) {
            exitButton = true;
            fieldName = $this.attr("field-name");

            $(".form-group:has(#" + this.id + ")").addClass("has-danger");
            $("#" + this.id).addClass("input-error");
            $("#" + this.id).css("border-color", "red");
            return false;
          } else {
            if (id == "interest_rate" || id == "default_rate") {
              exitButton = false;
              arrayData[i] = {};
              arrayData[i][this.id] = numeral().unformat(this.value);
            } else {
              exitButton = false;
              arrayData[i] = {};
              if (name == "where_show") {
                arrayData[i][this.id] = $this.val().join(";");
              } else if (id == "interest_rate") {
                arrayData[i][this.id] = numeral().unformat(this.value);
              } else {
                arrayData[i][this.id] = this.value;
              }
            }
          }

          i++;
        });

        if (exitButton) {
          btn
            .removeClass("m-loader m-loader--right m-loader--light")
            .attr("disabled", false);
          showNotification(
            "Atenção",
            "O campo <strong>" + fieldName + "</strong> não foi informado!",
            "warning"
          );
          return false;
        }

        i++;
        arrayData[i] = {};
        arrayData[i]["considered_date_field_description"] = $(
          "#considered_date_field option:selected"
        ).text();

        i = 0;
        $.each($("span.text", "#filters-iten"), function () {
          var $this = $(this);
          var type = "expression";
          var order = i + 1;

          if ($this.parent().hasClass("operator")) {
            if (
              $this.parent().data("operator") == "AND" ||
              $this.parent().data("operator") == "OR"
            ) {
              totOperator++;
            } else {
              totOperatorCap++;
            }

            type = "operator";
          } else if (!$this.parent().hasClass("operator")) {
            totExpression++;
          }

          arrayDataFilter[i] = {};
          arrayDataFilter[i]["type"] = type;
          arrayDataFilter[i]["order"] = order;
          arrayDataFilter[i]["field"] =
            type == "expression" ? $this.parent().data("erp_field") : "";
          arrayDataFilter[i]["field_description"] =
            type == "expression"
              ? $this.parent().data("erp_field_description")
              : "";
          arrayDataFilter[i]["operator"] =
            type == "expression"
              ? $this.parent().data("erp_operator")
              : $this.parent().data("operator");
          arrayDataFilter[i]["operator_description"] =
            type == "expression"
              ? $this.parent().data("erp_operator_description")
              : $this.text();
          arrayDataFilter[i]["expression"] =
            type == "expression" ? $this.parent().data("expression") : "";

          i++;
        });

        if (i > 0) {
          if (
            totExpression > 1 &&
            (totExpression != totOperator + 1 || totOperatorCap % 2 != 0)
          ) {
            btn
              .removeClass("m-loader m-loader--right m-loader--light")
              .attr("disabled", false);
            showNotification(
              "Atenção",
              "A quantidade de operadores informada está incorreta!",
              "warning"
            );
            return false;
          }
        }

        $.ajax({
          method: "POST",
          url: self.$store.state.API_BASE + "grouperiten/edit",
          headers: {
            token: self.$session.get("token"),
          },
          data: {
            arrayData: JSON.stringify(arrayData),
            arrayDataFilter: JSON.stringify(arrayDataFilter),
          },
        })
          .done(function (result) {
            btn
              .removeClass("m-loader m-loader--right m-loader--light")
              .attr("disabled", false);

            self.$router.replace({ name: "grouperiten" });

            if (result.error == false) {
              showNotification("Sucesso", "Agrupador alterado!", "success");
            } else {
              showNotification("Atenção", result.error, "danger");
              return false;
            }
          })
          .fail(function (error) {
            btn
              .removeClass("m-loader m-loader--right m-loader--light")
              .attr("disabled", false);
            console.log(error.responseText);
          });
      });

      $("input").keyup(function () {
        if ($("#" + this.id).hasClass("input-error")) {
          $("#" + this.id).removeClass("input-error");
          $("#" + this.id).css("border-color", "#ebedf2");
          $(".form-group:has(#" + this.id + ")").removeClass("has-danger");
        }
      });
    },
    showElement: function () {
      return (
        !this.whereShow ||
        (this.whereShow &&
          this.whereShow.length > 0 &&
          (this.whereShow.includes("1") || this.whereShow.includes("4")))
      );
    },
  },
};
</script>