export default class FilterParameterHistoryTimeline {
    public constructor(
      public date: string,
      public channel: string,
    ) {
    }

    public static make(data: any): FilterParameterHistoryTimeline {
      return new FilterParameterHistoryTimeline(
          data.date_billing_history ?? '',
          data.channel_billing_history ?? '',
      );
    }
}
