



























































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import MainGrouperFilter from '@/views/cashFlowGroupers/mainGrouper/components/MainGrouperFilter.vue';
import MainGrouperDataTable from '@/views/cashFlowGroupers/mainGrouper/components/MainGrouperDataTable.vue';
import DeleteMainGrouperDialog from '@/views/cashFlowGroupers/mainGrouper/components/DeleteMainGrouperDialog.vue';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import CashFlowGrouperRepository from '@/repositories/CashFlowGrouperRepository';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import MainGrouperList from '@/domain/models/groupers/MainGrouperList';
import IMainGroupersListFilter from '@/views/cashFlowGroupers/mainGrouper/interfaces/IMainGroupersListFilter';
import IDataTableOptions from '@/helpers/interfaces/IDataTableOptions';

export default defineComponent({
    name: 'MainGrouperCashFlowList',
    components: {
      MainGrouperFilter,
      MainGrouperDataTable,
      DeleteMainGrouperDialog,
    },
    data() {
      const cashFlowGrouperRepository: CashFlowGrouperRepository = new CashFlowGrouperRepository();
      const filterParametersRepository:
        FilterParametersRepository = new FilterParametersRepository();

      const showFilters: boolean = true;
      const loading: boolean = false;
      const hasFirstLoading: boolean = false;
      const isDeleteDialogOpen: boolean = false;

      const totalItems: number = 0;
      const selectedMainGrouperToDeleteId: number = 0;

      const mainGrouperItems: MainGrouperList[] = [];

      return {
        showFilters,
        loading,
        hasFirstLoading,
        isDeleteDialogOpen,
        totalItems,
        selectedMainGrouperToDeleteId,
        mainGrouperItems,
        cashFlowGrouperRepository,
        filterParametersRepository,
      };
    },
    computed: {
      ...mapState({
        companyGroupId: (state: any) => Number(state.authentication.user.company_group_id),
      }),
      mainGrouperFilter(): { validate: () => IMainGroupersListFilter|false } {
        const mainGrouperFilter = this.$refs.MainGrouperFilter as any;

        return mainGrouperFilter;
      },
      mainGrouperDataTable(): { getDataTableOptions: () => IDataTableOptions } {
        const MainGrouperDataTable = this.$refs.MainGrouperDataTable as any;

        return MainGrouperDataTable;
      },
      hasMainGroupers(): boolean {
        return false;
      },
    },
    methods: {
      async getMainGroupersCashFlow(
        filters: IMainGroupersListFilter,
        dataTableOptions: IDataTableOptions,
      ): Promise<void> {
        try {
          if (!this.hasFirstLoading) {
            this.hasFirstLoading = true;
          }

          this.loading = true;

          const { data, total } = await this.cashFlowGrouperRepository.getMainGroupersTableList(
            this.companyGroupId,
            filters.selectedCompanies,
            {
              filter: {
                search: filters.search,
                status: filters.status,
                screens_shown: filters.screensShown,
                tables: filters.tables,
              },
              items_per_page: dataTableOptions.itemsPerPage,
              page: dataTableOptions.page,
              sort: dataTableOptions.formattedSort,
            },
          );

          this.setFilterParameter(filters);

          this.mainGrouperItems = data;
          this.totalItems = total;

          if (total > 0) {
            this.showFilters = false;
          }
        } catch (error) {
          this.$notification.error(this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar os agrupadores.'));
        } finally {
          this.loading = false;
        }
      },
      setFilterParameter(filters: IMainGroupersListFilter) {
        this.filterParametersRepository.setFilter(
          GroupFilterParametersEnum.MAIN_GROUPER_LIST,
          [
          {
              key: 'tables',
              value: JSON.stringify(filters.tables),
            },
            {
              key: 'screens_to_shown',
              value: JSON.stringify(filters.screensShown),
            },
            {
              key: 'status',
              value: JSON.stringify(filters.status),
            },
            {
              key: 'company_ids',
              value: JSON.stringify(filters.selectedCompanies),
            },
          ],
        );
      },
      handleGetMainGroupers(): void {
        const dataTableOptions = this.mainGrouperDataTable.getDataTableOptions();
        const filters = this.mainGrouperFilter.validate();

        if (!filters) {
          return;
        }

        this.getMainGroupersCashFlow(filters, dataTableOptions);
      },
      handleCreateMainGrouper(): void {
        this.$router.push({ name: 'MainGrouperCashFlowCreate' });
      },
      handleRemoveMainGrouper(mainGrouperId: number): void {
        this.selectedMainGrouperToDeleteId = mainGrouperId;
        this.isDeleteDialogOpen = true;
      },
      handleDataTableChanges(): void {
        if (!this.hasFirstLoading) {
          return;
        }

        this.handleGetMainGroupers();
      },
      handleCloseDeleteDialog(reload: boolean): void {
        this.isDeleteDialogOpen = false;
        this.selectedMainGrouperToDeleteId = 0;

        if (reload) {
          this.handleGetMainGroupers();
        }
      },
    },
});
