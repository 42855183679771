










































































































































































import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import {
  Vue,
  Component,
  Prop,
  Emit,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/VForm';
import { toCurrency } from '@/utils/';
import { formatErrorForNotification } from '@/utils/error';
import IOption from '@/domain/interfaces/ISelectOptions';
import IInvoice from '@/domain/interfaces/IPaymentRemittancesInvoice';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import { formatTitle, hideDuplicateOverflow } from '../../utils';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionGenerateInvoice extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<PaymentRemittance>,
  }) readonly items!: Array<PaymentRemittance>;

  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  @Prop({
    type: String,
  }) name!: string;

  @Emit()
  close(reload: boolean = false): CloseActionData {
    this.paymentForm = null;

    return {
      type: ActionType.GENERATE_INVOICE,
      reload,
    };
  }

  @Watch('open')
  public async onChangeOpen(value: boolean): Promise<void> {
    if (value) {
      await Promise.all([
        this.loadCompanies(),
        this.loadPaymentForms(),
      ]);

      const supplierId = this.items[0].supplier_id_customer;
      const sameSupplier = this.items
        .every((item) => item.supplier_id_customer === supplierId);

      if (sameSupplier) {
        const supplierCode = this.items[0].supplier_code_number;
        this.handleLoadSuppliers(supplierCode);
        this.supplier = supplierId;
      }

      if (this.isAllItensTheSamePaymentType) {
        this.paymentForm = this.items[0].form_of_payment;
      }
    } else {
      this.date = '';
      this.company = Number(undefined);
      this.supplier = '';
      this.paymentForm = null;
      this.paymentMethods = [];
      this.form.resetValidation();
    }
  }

  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);
  private readonly paymentRemittanceRepository
    : PaymentRemittanceRepository = new PaymentRemittanceRepository();
  public readonly inputRules
    : InputRulesHelper = new InputRulesHelper();

  readonly formatCurrency = toCurrency;
  readonly formatTitle = formatTitle;
  readonly required = (v: string) => !!v || 'Campo obrigatório!';
  timerSuppliers!: ReturnType<typeof setTimeout>;

  date: string = '';
  company: number = Number(undefined);
  supplier: string = '';
  public paymentForm: string|null = null;

  public paymentFormSearch: string = '';

  loading: boolean = false;
  loadingSuppliers: boolean = false;
  public loadingPaymentForm: boolean = false;

  companyOptions: Array<IOption<number>> = [];
  suppliersOptions: Array<IOption<string>> = [];
  public paymentMethods: IOption<string>[] = [];

  setSuppliers = new Set<string>();

  get companyGroupId(): number {
    return Number(this.authenticationModule.user.company_group_id);
  }

  public get companyIds(): number[] {
    return this.authenticationModule.companyIds;
  }

  get total(): number {
    return this.items
      .reduce((acc, item) => acc + item.total, 0);
  }

  public get isAllItensTheSamePaymentType(): boolean {
    if (this.items.length < 1) {
      return false;
    }

    const { form_of_payment: firstFormOfPayment } = this.items[0];

    return this.items.every((item) => item.form_of_payment === firstFormOfPayment);
  }

  public get isAllNccType(): boolean {
    return this.items.every(({ type }) => type === 'NCC');
  }

  save() {
    const isValidform = this.form.validate();

    if (isValidform) {
      const titles = this.items.map((item) => item.id_customer);

      if (!titles.length) {
        this.$notification.error('Nenhum título selecionado!');
        return;
      }

      const { date, supplier, company } = this;

      const params: IInvoice = {
        company_id: company,
        supplier_id: supplier,
        due_date: date,
        accounts_payable: titles,
        payment_form: this.paymentForm!,
      };

      this.handleInvoice(params);
    }
  }

  async changedCompany(): Promise<void> {
    this.setSuppliers.clear();

    const supplierOlderValue = this.suppliersOptions
      .find((option) => option.value === this.supplier);

    this.suppliersOptions = [];

    if (supplierOlderValue && supplierOlderValue.text) {
      const search = supplierOlderValue.text.split(' ')[0];
      await this.handleLoadSuppliers(search);
    }

    if (!this.suppliersOptions.length) this.supplier = '';
  }

  async loadCompanies(): Promise<void> {
    try {
      this.loading = true;

      const result = await this.paymentRemittanceRepository
        .getCompanies(this.companyGroupId);

      this.companyOptions = this.companies.length
        ? result.filter((res) => this.companies.includes(res.value))
        : result;
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async loadPaymentForms(): Promise<void> {
    try {
      this.loadingPaymentForm = true;
      const paymentMethods = await this.paymentRemittanceRepository.getMethods(
        this.companyGroupId,
        this.companyIds,
      );

      this.paymentMethods = paymentMethods;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as condições de pagamento.');
    } finally {
      this.loadingPaymentForm = false;
    }
  }

  debounceSuppliers(search: string): void {
    clearTimeout(this.timerSuppliers);

    this.timerSuppliers = setTimeout(() => {
      this.handleLoadSuppliers(search);
    }, 500);
  }

  async loadSuppliers(search?: string): Promise<void> {
    if (!search || this.suppliersOptions.some((item) => item.text === search)) return;

    this.loadingSuppliers = true;

    const companyArray = this.company ? [this.company] : [];
    const suppliers = await this.paymentRemittanceRepository.getSuppliers(
      this.companyGroupId,
      companyArray,
      search,
      this.isAllNccType,
    );

    const concat = [...this.suppliersOptions, ...suppliers];

    this.suppliersOptions.push(...concat.filter((account) => {
      const duplicated = this.setSuppliers.has(account.value);
      this.setSuppliers.add(account.value);
      return !duplicated;
    }));

    this.loadingSuppliers = false;
  }

  async handleLoadSuppliers(search: string) {
    try {
      await this.loadSuppliers(search);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }

  async handleInvoice(params: IInvoice): Promise<void> {
    try {
      this.$dialog.startLoading();

      const result = await this.paymentRemittanceRepository
        .invoice(this.companyGroupId, params.company_id, params);

      if (result) this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      hideDuplicateOverflow(this);
    }
  }
}
