enum DDAConciliationStatusType {
  PENDING = '0',
  CONCILIATED = '1',
  DIVERGENCE = '2',
  NOT_FOUND_ERP = '3',
  ERP_WITH_BARCODE = '4',
  NOT_FOUND_DDA = '5',
  PENDING_CONCILIATION_ERP = '6',
  ERP_CONCILIATION_FAILURE = '7',
  UNDONE = '8',
  CANCELED = '9',
  WITHOUT_ACCOUNT_PAYABLE = '10',
}

export default DDAConciliationStatusType;
