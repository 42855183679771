import ISettledBankAccountTransaction from '@/views/CnabRemittanceGenerated/interfaces/ISettledBankAccountTransaction';
import IGroupedTransactionsInvoice from '@/views/CnabRemittanceGenerated/interfaces/IGroupedTransactionsInvoice';

interface ITransactionGroup {
  data: ISettledBankAccountTransaction,
  isInvoice: boolean;
}

export default class SettleBankAccountFormatterHelper {
  public static inclusionStatus = 'BD';
  public static bankOccurrencesStatus = ['00', 'DV', 'NA'];

  public static groupTransactions(
    transactions: ISettledBankAccountTransaction[],
  ): IGroupedTransactionsInvoice[] {
    const groupedTransactions: IGroupedTransactionsInvoice[] = [];

    const onlyInclusions = this.getTransactionsThatAreOnlyInvoicesOrNot(transactions, 'inclusion');
    const onlyOccurrences = this.getTransactionsThatAreOnlyInvoicesOrNot(transactions, 'occurrence');
    const onlyOthers = this.getTransactionsThatAreOnlyInvoicesOrNot(transactions, 'others');

    const effectedOccurrencesThatExistsOnInclusionsIndexes: number[] = [];
    const inclusionsAndOthers = [...onlyInclusions, ...onlyOthers];

    for (let i = 0; i < inclusionsAndOthers.length; i += 1) {
      const { data: inclusionData, isInvoice } = inclusionsAndOthers[i];

      const groupedTransactionObject: IGroupedTransactionsInvoice = {
        inclusion: {
          transactionData: inclusionData,
          isOpen: false,
          isInvoice,
          childs: [],
        },
      };

      if (isInvoice) {
        groupedTransactionObject.inclusion.childs = this.getTransactionsThatAreChilds(
          groupedTransactionObject.inclusion.transactionData,
          transactions,
          'inclusion',
        );
      }

      const effectedOccurrenceFromActualInclusion = onlyOccurrences
        .find((occurrenceData, occurrencesIndex) => {
          const isEqualIdCustomer = occurrenceData.data.idCustomer === inclusionData.idCustomer;

          if (isEqualIdCustomer) {
            effectedOccurrencesThatExistsOnInclusionsIndexes.push(occurrencesIndex);
          }

          return isEqualIdCustomer;
        });

      if (effectedOccurrenceFromActualInclusion) {
        groupedTransactionObject.occurrence = {
          transactionData: effectedOccurrenceFromActualInclusion.data,
          isOpen: false,
          isInvoice: effectedOccurrenceFromActualInclusion.isInvoice,
          childs: [],
        };

        if (effectedOccurrenceFromActualInclusion.isInvoice) {
          groupedTransactionObject.occurrence.childs = this.getTransactionsThatAreChilds(
            groupedTransactionObject.occurrence.transactionData,
            transactions,
            'occurrence',
          );
        }
      }

      groupedTransactions.push(groupedTransactionObject);
    }

    return groupedTransactions;
  }

  private static getTransactionsThatAreOnlyInvoicesOrNot(
    transactions: ISettledBankAccountTransaction[],
    typeOfChild: 'inclusion' | 'occurrence' | 'others',
  ): ITransactionGroup[] {
    const filteredTransactions = [];

    for (let i = 0; i < transactions.length; i += 1) {
      const {
        accountInvoiceLink,
        bankOccurrence,
        titlePaymentReceiptPrefix,
      } = transactions[i];

      const isTransactionInvoiceType = titlePaymentReceiptPrefix === 'INN';
      let isTransactionNotInvoiceChild = false;

      if (!isTransactionInvoiceType) {
        isTransactionNotInvoiceChild = !(transactions.some(
          (transaction) => transaction.titlePaymentReceiptPrefix === 'INN'
            && transaction.invoiceLink === accountInvoiceLink
            && this.validateBasedOnType(transaction.bankOccurrence, typeOfChild),
        ));
      }

      if (
        this.validateBasedOnType(bankOccurrence, typeOfChild)
        && (titlePaymentReceiptPrefix === 'INN' || isTransactionNotInvoiceChild)
      ) {
        filteredTransactions.push({
          data: transactions[i],
          isInvoice: isTransactionInvoiceType,
        });
      }
    }

    return filteredTransactions;
  }

  private static getTransactionsThatAreChilds(
    invoiceTransaction: ISettledBankAccountTransaction,
    transactions: ISettledBankAccountTransaction[],
    typeOfChild: 'inclusion' | 'occurrence',
  ): ISettledBankAccountTransaction[] {
    const filterType = typeOfChild === 'inclusion' ? [this.inclusionStatus] : this.bankOccurrencesStatus;

    return transactions.filter((childTransaction) => (
      childTransaction.titlePaymentReceiptPrefix !== 'INN'
      && invoiceTransaction.invoiceLink === childTransaction.accountInvoiceLink
      && filterType.includes(childTransaction.bankOccurrence)
    ));
  }

  private static validateBasedOnType(
    bankOccurrence: string,
    type: 'inclusion' | 'occurrence' | 'others',
  ): boolean {
    if (type === 'inclusion') {
      return bankOccurrence === this.inclusionStatus;
    }

    if (type === 'occurrence') {
      return this.bankOccurrencesStatus.includes(bankOccurrence);
    }

    return ![...this.bankOccurrencesStatus, this.inclusionStatus].includes(bankOccurrence);
  }
}
