<template>
    <paramns-list-structure
      class="mt-4"
      :paramnsVisibility="paramnsVisibility"
      :tableVisibility="listFiles.length > 0"
    >
      <template v-slot:search>
        <h3 class="header-title">Parâmetros</h3>
      </template>
      <template v-slot:actions>
        <v-row justify="end">
          <custom-button
            :disabled="checkSearchButtonDisabled()"
            buttonClass="btn-success"
            @click="loadFiles"
            label="Visualizar"
          />
          <v-btn icon class="ml-1" depressed @click="toggleParams()">
            <v-icon
              x-large
              class="text--disabled chevron"
              :class="!paramnsVisibility ? 'toggle-chevron' : ''"
            >
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:paramns>
        <dda-files-search
          :searchData="searchData"
          :paramnsVisibility="paramnsVisibility"
          :setSearchData="setSearchData"
          @companies-loaded="loadFiles"
        />
      </template>

      <template v-slot:table>
        <v-container class="pa-0" fluid>
          <v-row class="ma-0 px-8 py-4" justify="center">
						  <table class="table">
                <thead class="table-head-resume">
                  <tr>
                    <th class="text-center">Data/Hora</th>
                    <th>Usuário</th>
                    <th class="text-center">Arquivo</th>
                    <th class="text-center">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(file, index) in listFiles" v-bind:key="file.id" v-bind:class="[(index % 2) == 0 ? '' : 'zebra']">
                    <td class="text-center" style="vertical-align: middle;">
                      {{ file.created_at | moment("DD/MM/YYYY HH:mm") }}
                    </td>
                    <td style="vertical-align: middle;">{{ file.user }}</td>
                    <td class="text-center" style="vertical-align: middle;">{{ file.file_name }}</td>
                    <td class="text-center" style="vertical-align: middle;">
                      <a
                        class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
                        title="Baixar"
                      >
                        <i
                          style="font-size:20px"
                          class="la la-cloud-download"
                          v-on:click="downloadFile(file)"
                        ></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
          </v-row>
        </v-container>
      </template>
    </paramns-list-structure>
</template>

<script>
import _ from "lodash";
import ParamnsListStructure from "@/components/defaults/ParamnsListStructure.vue";
import DDAFilesSearch from "./Search.vue";
import CustomButton from '../../../src/components/buttons/CustomButton.vue';
import { API_BASE } from '@config/api'

export default {
  components: { ParamnsListStructure, "dda-files-search": DDAFilesSearch, CustomButton },
  data() {
    const date = moment().format('DD/MM/YYYY');
    return {
      listFiles: [],
			paramnsVisibility: true,
      searchData: {
				issueDate: {
					initial: date,
					final: date,
				},
				dueDate: {
					initial: date,
					final: date,
				},
        provider: null,
        value: "",
        company: null,
      }
    };
  },
  methods: {
    loadFiles: function(dontHideParameters) {
      if (this.checkSearchButtonDisabled()) {
        return;
      }

      blockPage("Carregando os dados...");
      this.listFiles = [];
      let value = $("#value").val();

      $.ajax({
        method: "GET",
        url: API_BASE + "dda/files/listAll",
        headers: {
          token: this.$session.get("token")
        },
        data: {
          issueDate: {
            initial: this.convertDate(this.searchData.issueDate.initial),
            final: this.convertDate(this.searchData.issueDate.final)
          },
          dueDate: {
            initial: this.convertDate(this.searchData.dueDate.initial),
            final: this.convertDate(this.searchData.dueDate.final)
          },
          value: value,
				  companyId: this.searchData.companyId,
				  registrationNumber: $('#registration_number').val().length > 0 ? $('#registration_number').val() : '',
        }
      })
        .done(result => {
          if (result.error) {
            showNotification("Atenção", result.message, "danger");
          } else {
            if (result.total_records > 0) {
              this.listFiles = result.data;
              if (!dontHideParameters) {
                this.paramnsVisibility = false;
              }
            } else {
              showNotification("Atenção", "Sem dados para listar", "warning");
            }
          }

          unblockPage();
        })
        .fail(error => {
          showNotification("Atenção", "Opss!!! Não foi possível carregar os dados", "danger");
          unblockPage();
        });
    },
    downloadFile: function(file) {
      blockPage("Carregando os dados...");

      $.ajax({
        method: "GET",
        url: API_BASE + "dda/files/getContent",
        headers: {
          token: this.$session.get("token")
        },
        data: {
          file_id: file.file_id
        }
      })
        .done(result => {
          if (result.error) {
            showNotification("Atenção", result.message, "danger");
          } else {
            if (result.total_records > 0) {
              var blob = new Blob([result.data[0].file_content], {
                type: "application/octet-stream"
              });
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = file.file_name;

              link.click();
            } else {
              showNotification("Atenção", "Sem dados para listar", "warning");
            }
          }

          unblockPage();
        })
        .fail(error => {
          showNotification("Atenção", "Opss!!! Não foi possível carregar os dados", "danger");
          unblockPage();
        });
    },
    converBarCode(event) {
      let codBarras = "";

      if (this.textSearch.length > 40) {
        codBarras = Codbarras.verificaBarra(this.textSearch, event.target);
        if (codBarras) this.textSearch = codBarras;
      }
    },
    convertDate(date) {
      if (!date) {
        return '';
      }
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
		toggleParams() {
      this.paramnsVisibility = !this.paramnsVisibility;
    },
    setSearchData(newData) {
      this.searchData = { ...this.searchData, ...newData };
    },
    checkSearchButtonDisabled() {
      return !(this.searchData.issueDate.initial
        && this.searchData.issueDate.final
        && this.searchData.dueDate.initial
        && this.searchData.dueDate.final
        && this.searchData.companyId);
    },
  }
};
</script>

<style scoped>
	.zebra {
		background: #f0f4f7;
	}

	.padding-zero {
		padding-right:0px;
		padding-left:0;
	}

	[class*=" la-"], [class^=la-] {
    font-size: 21px;
	}

	.m-checkbox-inline .m-checkbox, .m-checkbox-inline .m-radio, .m-radio-inline .m-checkbox, .m-radio-inline .m-radio {
		margin-bottom: 13px;
	}

	.table td, .table th {
		border-top: transparent;
    font-size: 12px;
    padding: 0.5rem;
	}

	.table th {
		font-weight: 500;
	}

	.cursor-pointer{
		cursor: pointer;
	}
</style>