









































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';

import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { formatErrorForNotification } from '@/utils/error';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActioChangeMethod extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Array as () => Array<PaymentRemittance>,
  }) readonly items!: Array<PaymentRemittance>;

  @Prop({
    type: Array as () => Array<number>,
  }) readonly companies!: Array<number>;

  @Emit()
  close(reload = false): CloseActionData {
    return { type: ActionType.CHANGE_METHOD, reload };
  }

  @Watch('open')
  onChangeOpen(value: boolean) {
    if (value) {
      this.loadData();
    } else {
      this.method = '';
      this.checkbox = false;
      this.form.resetValidation();
    }
  }
  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly required = (value: Array<any> | string) => value.length > 0 || 'Campo obrigatório!';

  options: Array<ISelectOptions<string>> = [];

  method: string = '';
  checkbox: boolean = false;
  loading: boolean = false;

  allCompanies: Array<number> = [];

  get group(): number {
    return this.$session.get('company_group_id');
  }

  get showCheckbox(): boolean {
    return this.items.length === 1 && this.items[0].origin !== 'inncash';
  }

  save(): void {
    if (this.loading) {
      this.$notification.warn('Aguarde o carregamento das informações necessárias.');
      return;
    }

    const isValidform = this.form.validate();

    if (isValidform) {
      const { method, checkbox, items } = this;

      if (items.length > 1) {
        this.handleMultipleUpdateMethod(method, checkbox, items);
      } else {
        this.handleUpdateMethod(method, checkbox, items[0]);
      }
    }
  }

  async loadCompanies(): Promise<void> {
    const response = await this.paymentRemittanceRepository.getCompanies(this.group);
    this.allCompanies = response.map((company) => company.value);
  }

  async loadMethods(): Promise<void> {
    const companies = this.companies.length ? this.companies : this.allCompanies;
    this.options = await this.paymentRemittanceRepository.getMethods(this.group, companies);
  }

  async loadData(): Promise<void> {
    try {
      this.loading = true;
      if (!this.companies.length) await this.loadCompanies();
      await this.loadMethods();
    } catch (error: any) {
      this.close();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  async handleUpdateMethod(method: string, check: boolean, item: PaymentRemittance): Promise<void> {
    try {
      this.$dialog.startLoading();

      const success = item.origin === 'inncash'
        ? await this.updateInvoice(method, item)
        : await this.updateTitle(method, check, item);

      if (success) {
        this.close(true);
        this.$notification.success('Forma de pagamento alterada com sucesso!');
      } else {
        throw new Error('Ocorreu um erro ao fazer a alteração dos dados!');
      }
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      this.$dialog.stopLoading();
    }
  }

  async updateInvoice(method: string, item: PaymentRemittance): Promise<any> {
    const params = {
      field: {
        invoice_form_payment: method,
      },
      where: {
        branch: item.branch,
        provider_code: item.supplier_code_number,
        provider_store: item.supplier_store_number,
        invoice_number: item.number,
      },
    };

    await this.paymentRemittanceRepository.updateInvoice(params);

    return true;
  }

  async updateTitle(
    method: string,
    check: boolean,
    item: PaymentRemittance,
  ): Promise<boolean> {
    const useNCC = Boolean(this.$session.get('use_ncc')) && item.type === 'NCC';

    const params = {
      id: item.id_customer,
      data: {
        payment_form: method,
        type: useNCC
          ? 'account_receivable'
          : 'account_payable',
      },
      event: 'payment_form',
    };

    const response = await this.paymentRemittanceRepository
      .updateERP(this.group, item.company_id, params);

    if (response.error) throw new Error('Ocorreu um erro ao fazer a alteração dos dados!');

    if (check) {
      const supplierParams = {
        id: item.supplier_id_customer,
        data: {
          payment_form: method,
          type: useNCC ? 'customer' : 'provider',
        },
        event: 'provider_payment_form',
      };

      await this.paymentRemittanceRepository
        .updateERP(this.group, item.supplier_company_id, supplierParams);
    }

    const paramsUpdate = {
      identify_values: 'SE2',
      id: item.id,
      field: 'E2_ZFORPG',
      value: method,
    };

    await this.paymentRemittanceRepository.updateInternal('updateImportTable', paramsUpdate);

    return true;
  }

  async handleMultipleUpdateMethod(
    method: string,
    check: boolean,
    items: Array<PaymentRemittance>,
  ): Promise<void> {
    try {
      this.$dialog.startLoading();

      const responses = await Promise.allSettled(
        items.map((item) => (
          item.origin === 'inncash'
            ? this.updateInvoice(method, item)
            : this.updateTitle(method, check, item)
        )),
      );

      const someReponseHasRejected = responses
        .some((response) => response.status === 'rejected');

      if (someReponseHasRejected) {
        const numbers = responses
          .reduce((prev, response, index) => {
            if (response.status === 'rejected') {
              if (prev.length === 0) {
                prev = items[index].number;
              } else {
                prev += index + 1 !== responses.length
                  ? `, ${items[index].number}`
                  : `e ${items[index].number}.`;
              }
            }
            return prev;
          }, '');

        this.$notification.error(`Ocorreu um erro ao fazer a alteração dos dados dos registros: ${numbers}`);
      } else {
        this.$notification.success('Formas de pagamento alteradas com sucesso!');
      }

      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
      this.$dialog.stopLoading();
    }
  }
}
