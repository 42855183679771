import Rest from './base/Rest';

export default class CnabFiles extends Rest {
	static resource = 'cnab';

	read() {
		this.relationship = 'files';

		return this;
	}

	directories() {
		this.relationship = 'directories';

		return this;
	}

	files() {
		this.relationship = 'files';

		return this;
	}

	receipts() {
		this.relationship = 'files/receipts';

		return this;
	}

	download() {
		this.relationship = 'files/download';
		return this;
	}
}
