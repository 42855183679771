

















import {
  Vue,
  Component,
  Emit,
  Prop,
} from 'vue-property-decorator';

@Component
export default class TableActionButton extends Vue {
  @Prop({
    type: String,
    required: true,
    default: '',
  }) label!: string;

  @Prop({
    type: String,
    required: true,
    default: '',
  }) icon!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) disabled?: boolean;

  @Emit('click') click(value: PointerEvent) {
    return value;
  }
}
