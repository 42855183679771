































































































































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import PredictedFlowFilter from '@/views/cashFlow/predictedFlow/components/PredictedFlowFilter.vue';
import PredictedFlowList from '@/views/cashFlow/predictedFlow/components/PredictedFlowList.vue';
import IncludeIncreaseDecreaseDialog from '@/views/cashFlow/predictedFlow/components/eventDialogs/IncludeIncreaseDecreaseDialog.vue';
import IncludeCommentDialog from '@/views/cashFlow/predictedFlow/components/eventDialogs/IncludeCommentDialog.vue';
import ChangeValueDialog from '@/views/cashFlow/predictedFlow/components/eventDialogs/ChangeValueDialog.vue';
import ChangeDateDialog from '@/views/cashFlow/predictedFlow/components/eventDialogs/ChangeDateDialog.vue';
import ChangePaymentFormDialog from '@/views/cashFlow/predictedFlow/components/eventDialogs/ChangePaymentFormDialog.vue';
import ChangeReceiveFormDialog from '@/views/cashFlow/predictedFlow/components/eventDialogs/ChangeReceiveFormDialog.vue';
import ShowAccountDetailsDialog from '@/views/cashFlow/predictedFlow/components/eventDialogs/ShowAccountDetailsDialog.vue';
import UpdateBankDataDialog from '@/views/cashFlow/predictedFlow/components/eventDialogs/UpdateBankDataDialog.vue';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import CashFlowRepository from '@/repositories/CashFlowRepository';
import PredictedFlowDownloadReportTypesEnum from '@/views/cashFlow/predictedFlow//enums/PredictedFlowDownloadReportTypesEnum';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import PeriodicityEnum from '@/views/cashFlow/predictedFlow/enums/PeriodicityEnum';
import ReportTypesEnum from '@/views/cashFlow/predictedFlow/enums/ReportTypesEnum';
import CashFlowList from '@/domain/models/cashFlow/CashFlowList';
import IPredictedFlowFilters from '@/views/cashFlow/predictedFlow/interfaces/IPredictedFlowFilters';
import IPredictedFlowButtonOptionSelectedValues from '@/views/cashFlow/predictedFlow/interfaces/IPredictedFlowButtonOptionSelectedValues';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';
import PredictedFlowActionsEnum from '@/views/cashFlow/predictedFlow//enums/PredictedFlowActionsEnum';
import VMenuOptions from '@/types/VMenuOptions';

export default defineComponent({
  components: {
    PredictedFlowFilter,
    PredictedFlowList,
    IncludeIncreaseDecreaseDialog,
    IncludeCommentDialog,
    ChangeValueDialog,
    ChangeDateDialog,
    ChangePaymentFormDialog,
    ChangeReceiveFormDialog,
    ShowAccountDetailsDialog,
    UpdateBankDataDialog,
  },
  data() {
    const cashFlowRepository: CashFlowRepository = new CashFlowRepository();
    const filterParametersRepository:
        FilterParametersRepository = new FilterParametersRepository();

    const periodicityDateCorrespondentToSelectedTotal: string|undefined = undefined;

    const loading: boolean = false;
    const showFilters: boolean = true;
    const hasPredictedFlow: boolean = true;

    const selectedItemIndex: number|undefined = undefined;
    const selectedTotalIndex: number|undefined = undefined;

    const selectedReportType: ReportTypesEnum|false = false;
    const selectedPeriodicity: PeriodicityEnum|undefined = undefined;

    const selectedRegister: IPredictedFlowGrouperList|undefined = undefined;

    const cashFlowListData: CashFlowList|undefined = undefined;

    const showDialogs = {
      include_increase_decrease: false,
      include_comment: false,
      change_value: false,
      change_date: false,
      change_payment_form: false,
      change_receive_form: false,
      generate_billing_remittance: false,
      show_account: false,
      update_bank_data: false,
    };

    const downloadOptions: VMenuOptions[] = [
      { text: 'PDF', value: PredictedFlowDownloadReportTypesEnum.pdf },
      { text: 'Planilha', value: PredictedFlowDownloadReportTypesEnum.spreadsheet },
    ];

    return {
      cashFlowRepository,
      filterParametersRepository,
      periodicityDateCorrespondentToSelectedTotal:
        periodicityDateCorrespondentToSelectedTotal as string|undefined,
      loading,
      showFilters,
      hasPredictedFlow,
      selectedItemIndex: selectedItemIndex as number|undefined,
      selectedTotalIndex: selectedTotalIndex as number|undefined,
      selectedReportType: selectedReportType as ReportTypesEnum|false,
      selectedPeriodicity: selectedPeriodicity as PeriodicityEnum|undefined,
      selectedRegister: selectedRegister as IPredictedFlowGrouperList|undefined,
      cashFlowListData: cashFlowListData as CashFlowList|undefined,
      showDialogs,
      downloadOptions,
    };
  },
  computed: {
    ...mapState({
        companyGroupId: (state: any) => Number(state.authentication.user.company_group_id),
    }),
    getToggleIcon(): string {
      return this.showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
    getToggleTooltip(): string {
      return this.showFilters ? 'Ocultar Parâmetros' : 'Exibir Parâmetros';
    },
    predictedFlowFilter(): { validate: () => IPredictedFlowFilters|false } {
      return this.$refs.PredictedFlowFilter as any;
    },
    hasCashFlowItems(): boolean {
      if (this.cashFlowListData && this.cashFlowListData.cashFlowItems.length > 0) {
        return true;
      }

      return false;
    },
  },
  methods: {
    async getPredictedFlow(filters: IPredictedFlowFilters): Promise<void> {
      try {
        this.$dialog.startLoading();

        const response = await this.cashFlowRepository.getCashFlowPredictedByFilters(
          this.companyGroupId,
          filters.selectedCompanies,
          {
            initial_date: filters.initialDate,
            final_date: filters.finalDate,
            consider_bank_balance: filters.considerBankBalance,
            periodicity: filters.periodicity,
            show: filters.show,
          },
        );

        this.setFilterParameter(filters);

        this.cashFlowListData = response;
      } catch (error) {
        this.$notification.error('Houve um problema ao tentar gerar o Fluxo Previsto.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    setFilterParameter(filters: IPredictedFlowFilters) {
        this.filterParametersRepository.setFilter(
          GroupFilterParametersEnum.PREDICTED_FLOW,
          [
            {
              key: 'initial_date',
              value: filters.initialDate,
            },
            {
              key: 'final_date',
              value: filters.finalDate,
            },
            {
              key: 'consider_bank_balance',
              value: filters.considerBankBalance,
            },
            {
              key: 'periodicity',
              value: filters.periodicity,
            },
            {
              key: 'show',
              value: filters.show,
            },
            {
              key: 'report_type',
              value: filters.reportType,
            },
            {
              key: 'company_ids',
              value: JSON.stringify(filters.selectedCompanies),
            },
          ],
        );
    },
    validateFilters(): false|IPredictedFlowFilters {
      const filters = this.predictedFlowFilter.validate();

      if (!filters) {
        this.selectedPeriodicity = undefined;
        this.selectedReportType = false;
        return false;
      }

      this.selectedPeriodicity = filters.periodicity;
      this.selectedReportType = filters.reportType ?? false;

      return filters;
    },
    handleToggle() {
      this.showFilters = !this.showFilters;
    },
    handleShow() {
      const filters = this.validateFilters();

      if (!filters) {
        return;
      }

      this.getPredictedFlow(filters);
    },
    handlePredictedFlowListEvent(data : IPredictedFlowButtonOptionSelectedValues): void {
      this.showDialogs[data.event] = true;
      this.selectedRegister = data.item;

      this.selectedItemIndex = data.itemIndex;
      this.selectedTotalIndex = data.totalIndex;

      this.periodicityDateCorrespondentToSelectedTotal = data.periodicityCorrespondentDate;
    },
    reload() {
      this.handleShow();
    },
    handleCloseDialog(action: PredictedFlowActionsEnum): void {
      this.showDialogs[action] = false;
      this.selectedRegister = undefined;
      this.selectedItemIndex = undefined;
      this.selectedTotalIndex = undefined;
    },
    async handleDownloadReport(type: string): Promise<void> {
      try {
        const filters = this.validateFilters();

        if (!filters) {
          return;
        }

        this.$dialog.startLoading();

        const blobFile = await this.cashFlowRepository.downloadReportPredictedFlow(
          this.companyGroupId,
          filters.selectedCompanies,
          type === 'pdf' ? PredictedFlowDownloadReportTypesEnum.pdf : PredictedFlowDownloadReportTypesEnum.spreadsheet,
          {
            initial_date: filters.initialDate,
            final_date: filters.finalDate,
            consider_bank_balance: filters.considerBankBalance,
            periodicity: filters.periodicity,
            show: filters.show,
          },
        );

        const typeBlob = type === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([blobFile], { type: typeBlob });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', '');
        link.click();
      } catch (error) {
        this.$notification.error('Houve um problema ao baixar o relatório.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
  },
});
