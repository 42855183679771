





































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';
import ActionType from '@/domain/enums/DDAConciliationActionType';
import boletoValidator from '@mrmgomes/boleto-utils';

@Component
export default class DDAConciliationBarcode extends Vue {
  @Prop(Boolean) readonly open!: boolean;

  @Prop(Boolean) readonly register!: boolean;

  @Prop({
    type: Object as () => DDAConciliationMovement,
  }) readonly item!: DDAConciliationMovement;

  @Watch('open')
  changedOpen(open: boolean) {
    if (open) {
      if (this.barcode) {
        this.digitableLine = boletoValidator.codBarras2LinhaDigitavel(this.barcode, true) || '';
      }

      this.barcodeInput = this.barcode;
    }
  }

  @Emit()
  close(): ActionType {
    this.digitableLine = '';
    this.barcodeInput = '';
    return ActionType.BARCODE;
  }

  barcodeInput: string = '';
  digitableLine: string = '';

  get barcode(): string {
    return this.item.barcode;
  }

  set barcode(value: string) {
    this.item.barcode = value;
  }

  selectCode(e: any): void {
    const barcodeText = document.querySelector(`#${e.target.id}`) as HTMLInputElement;
    console.log(e);
    barcodeText.select();

    if (!this.register) this.clipboardCopy(e.target.value);
  }

  async clipboardCopy(value: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(value);
      this.$notification.success('Código copiado com sucesso!');
    } catch (error) {
      console.error(error);
    }
  }

  save(): void {
    if (this.barcodeInput) {
      const barcodeIsValid = boletoValidator
        .validarBoleto(this.barcodeInput).sucesso || false;

      if (barcodeIsValid) {
        // call repository to send barcode
        // if successs run next lines
        this.barcode = this.barcodeInput;
        this.close();
      } else {
        this.$notification.error('Código de barras inválido!');
      }
    }
  }

  digitableLineChanged(value: string): void {
    if (value) {
      this.barcodeInput = '';

      const response = boletoValidator
        .validarBoleto(value);

      if (response.sucesso) {
        this.barcodeInput = response.codigoBarras;
      } else {
        this.$notification.warn(response.mensagem);
      }
    }
  }

  barcodeInputChanged(value: string): void {
    value = value.replace(/[^0-9]/g, '');

    if (value) {
      this.digitableLine = '';

      if (value.length >= 44) {
        const response = boletoValidator.validarBoleto(value);

        if (response.sucesso) {
          this.digitableLine = response.linhaDigitavel;
        } else {
          this.$notification.warn(response.mensagem);
        }
      }
    }
  }
}
