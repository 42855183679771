<template>
  <v-row>
    <v-col>
			<breadcrumbs page-title="Configurações CNAB"/>
      <page-card dividerOn>
        <template #title>
        <v-row>
          <v-col cols="12" lg="3" md="3" sm="12" xs="12">
            <v-select 
              v-model="configs.filterStatus"
              filled
              hide-details
              label="Filtrar por status"
              :items="filterStatusItems"/>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="12" xs="12">
            <v-select 
              v-model="configs.filterOperation"
              filled
              hide-details
              label="Filtrar por operação"
              :items="filterOperationItems"/>
          </v-col>
        	<v-col cols="12" lg="4" md="3" sm="12" xs="12">
              <v-text-field
              filled
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Pesquisar"
              v-model="configs.filterTerm"
            />
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="12" xs="12">
						<v-btn
							@click="add"
              class="font-weight-bold"
							x-large
							block
							depressed
							color="primary"
						>
							ADICIONAR
						</v-btn>            
          </v-col>
        </v-row>
        </template>
        <template #body class="pa-6 pt-0">
          <v-col cols="12" lg="12" md="12" sm="12" xs="12">
            <table class="table table-striped m-table">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Operação</th>
                  <th>Descrição</th>
                  <th>Tamanho</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cnabRemittance in listData" v-bind:key="cnabRemittance.id">
                  <td style="vertical-align: middle;">
                    <v-chip
                    v-if="cnabRemittance.type == 0"
                    dense
                    small
                    color="error"
                    >
                    Pagar
                  </v-chip>
                  <v-chip
                    v-else
                    dense
                    small
                    color="green"
                    text-color="white">
                    Receber
                  </v-chip>
                  </td>
                  <td style="vertical-align: middle;">
                    <span v-if="cnabRemittance.operation == 0">Remessa</span>
                    <span v-else>Retorno</span>
                  </td>
                  <td style="vertical-align: middle;">{{ cnabRemittance.description }}</td>
                  <td style="vertical-align: middle;">{{ cnabRemittance.size }}</td>
                  <td class="m--align-right">
                    <button-tooltip
                      fab
                      icon
                      small
                      buttonIcon="mdi-content-copy"
                      tooltipText="Duplicar"
                      @click="duplicateCnab(cnabRemittance)"
                    />
                    <button-tooltip
                      fab
                      icon
                      small
                      buttonIcon="mdi-pencil"
                      tooltipText="Editar"
                      @click="edit(cnabRemittance)"
                    />
                    <button-tooltip
                      fab
                      icon
                      small
                      buttonIcon="mdi-delete"
                      tooltipText="Remover"
                      @click="remove(cnabRemittance)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12" xs="12">
            <cnab-replicate ref="CnabReplicate"></cnab-replicate>
          </v-col>
        </template>
      </page-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import CnabReplicate from "./cnab-replicate.vue";
import { API_BASE } from '@config/api'

export default {
  components: {
    CnabReplicate,
  },
  name: "cnabremittance",
  data() {
    return {
      cnabRemittances: [],
      configs: {
        filterTerm: "",
        filterStatus: "",
        filterOperation: ""
      },
      filterOperationItems: [
        {
          text: 'Todas as operações',
          value: '',
        },
        {
          text: 'Remessa',
          value: '0',
        },
        {
          text: 'Retorno',
          value: '1',
        },
      ],
      filterStatusItems: [
        {
          text: "Todos os tipos",
          value: "",
        },
        {
          text: "Pagar",
          value: "0",
        },
        {
          text: "Receber",
          value: "1",
        },
      ]
    };
  },

  computed: {
    listData() {
      let dataRet = this.cnabRemittances;

      if (this.configs.filterStatus) {
        dataRet = _.filter(dataRet, data => data.type == this.configs.filterStatus);
      }

      if (this.configs.filterOperation) {
        dataRet = _.filter(dataRet, data => data.operation == this.configs.filterOperation);
      }

      if (this.configs.filterTerm.toLowerCase()) {
        dataRet = _.filter(
          dataRet,
          list => list.description.toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0
          || list.size.toString().toLowerCase().indexOf(this.configs.filterTerm.toLowerCase()) >= 0
        );
      }

      return dataRet;
    }
    
  },
  mounted: function() {
    this.loadCnabRemittances();
  },
  methods: {
    loadCnabRemittances: function() {
      blockPage("Carregando os dados...");

      this.$http
        .get(API_BASE + "cnabremittance/listAll", {
          headers: { token: this.$session.get("token") }
        })
        .then(
          result => {
            this.cnabRemittances = result.body.data;
            unblockPage();
          },
          error => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    duplicateCnab: function(cnabRemittance) {
      // console.log(this.$session); 
      this.$refs.CnabReplicate.openModal(cnabRemittance);
      /*
                console.log('company_group_id: ' + this.$session.get('company_group_id'));

                if (confirm("Deseja realmente duplicar a configuração de CNAB?")){

                    var database = prompt("Informe o nome da base de dados", "fluxocaixa");
                    
                    if(!database){
                        alert("Dados inválidos!");
                        return;
                    }

                    this.processDuplicateCnab(cnabRemittance.id, this.$session.get('company_group_id'));
                }
                */
    },
    /*
     *   REMOVE
     *   Funcao padrao de comunicacao para remocao
     *   o padrao usado pode ser encontrado em bankconfig.vue
     */
    remove: function(cnabRemittance) {
      Swal.fire({
        title: "Atenção!",
        text: "Deseja realmente excluir?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#5867dd",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
        focusCancel: true
      }).then(result => {
        if (result.value) {
          this.$http
            .delete(API_BASE + "cnabremittance/delete/" + cnabRemittance.id, {
              headers: { token: this.$session.get("token") }
            })
            .then(
              result => {
                showNotification("Sucesso", "Registro removido com sucesso", "success");
                this.cnabRemittances.splice(this.cnabRemittances.indexOf(cnabRemittance), 1);
              },
              error => {
                showNotification("Atenção", error.body.message, "danger");
              }
            );
        }
      });
    },
    add() {
      this.$router.push('/cnabremittance/new');
		},	

    edit(cnabRemittance){
      	const params = {
					cnab_remittance_id: cnabRemittance.id,
				};
				this.$router.push({name: 'editcnabremittance',params});
    }
  }
};
</script>