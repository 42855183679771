











































import {
  Component,
  Mixins,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { formatErrorForNotification } from '@/utils/error';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import ClientModule from '@/stores/modules/ClientModule';

@Component
export default class ConfirmSendingBillingEmailDialog
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);

  private readonly clientModule: ClientModule = getModule(ClientModule);

  public readonly accountsReceivableRepository
    : AccountsReceivableRepository = new AccountsReceivableRepository();

  close() {
    this.model = false;
    this.loading = false;

    if (this.noContactToSendEmail) {
      this.$forceUpdate();
      this.$router.push(`/clientes/${this.idCustomerOrDocument}/contatos`);
    }
  }

  public loading: boolean = false;

  public get maxWidth(): string {
    if (this.$vuetify.breakpoint.xs) return '100%';

    if (this.$vuetify.breakpoint.smAndDown) return '80%';

    if (this.$vuetify.breakpoint.mdAndDown) return '60%';

    return '40%';
  }

  public get companyGroupId(): number {
    return parseInt(this.authenticationModule.user.company_group_id, 10);
  }

  public get companyId(): number {
    return this.clientModule.client.companyId!;
  }

  public get origin(): 'document' | 'customer' | 'credit' {
    return (this.$route.params.type ?? 'credit') as 'document' | 'customer' | 'credit';
  }

  public get idCustomerOrDocument(): string {
    if (this.origin === 'credit') {
      return this.$route.params.idCustomer;
    }

    return this.$route.params.idCustomerOrDocument;
  }

  public get noContactToSendEmail(): boolean {
    return !this.clientModule.client.financialName1 || !this.clientModule.client.financialMail1;
  }

  public async sendPendingAccountsReceivable(): Promise<void> {
    try {
      this.loading = true;

      await this.accountsReceivableRepository
        .sendPendingAccountsReceivableToCustomerMails(
          this.companyGroupId,
          this.companyId,
          this.idCustomerOrDocument,
        );

      this.$notification.success('Email enviado a fila!');
      this.close();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
