import Bank from '@/domain/models/Bank';
import Company from '@/domain/models/Company';
import CostCenter from '@/domain/models/CostCenter';
import ValueClass from '@/domain/models/ValueClass';
import AccountingItem from '@/domain/models/AccountingItem';
import FinancialNature from '@/domain/models/FinancialNature';
import ConciliationConfig from '@/domain/models/ConciliationConfig';
import IStatementConciliationSearch from '@/domain/interfaces/IStatementConciliationSearch';
import IStatementConciliationWriteOff from '@/domain/interfaces/IStatementConciliationWriteOff';
import ERPMovement from '@/domain/models/ERPMovement';
import BankMovement from '@/domain/models/BankMovement';
import StatementConciliationHistory from '@/domain/models/StatementConciliationHistory';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import IStatementConciliationHistoryFilter from '@/domain/interfaces/IStatementConciliationHistoryFilter';
import AccountReceivableType from '@/domain/models/AccountsReceivableType';
import AcquittanceReasonOption from '@/domain/models/AcquittanceReasonOptions';
import StatementConciliation from '@/domain/models/StatementConciliation';
import SettleAccount from '@/domain/models/SettleAccount';
import IStatementConciliationFilterData from '@/domain/interfaces/IStatementConciliationFilter';
import StatementConciliationSettleAccountData from '@/domain/models/StatementConciliationSettleAccount';
import IDeconciliation from '@/domain/interfaces/IDeconciliation';
import IConciliation from '@/domain/interfaces/IConciliation';
import IIncludeMovement from '@/domain/interfaces/IIncludeMovement';
import IIncludeMovementWithTransfer from '@/domain/interfaces/IIncludeMovementWithTransfer';
import IConciliationERPs from '@/domain/interfaces/IConciliationERPs';
import ISettleAccountsReceivable from '@/domain/interfaces/ISettleAccountsReceivable';
import SummaryBankingMovement from '@/domain/models/SummaryBankingMovement';
import IConciliationDivergent from '@/domain/interfaces/IConciliationDivergents';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import DeconciliationMovements from '@/domain/models/DeconciliationMovements';
import { ICreateMovementAdvanceData } from '@/views/statementConciliation/utils/interfaces';

import Repository from './base/Repository';

export default class StatementConciliationRepository extends Repository {
  async getCompanies(): Promise<Array<Company>> {
    const { status, data: response } = await this.datasource.get('company/listAllActv');

    if (status == 200) {
      if (response.error) throw new Error(`Empresas: ${response.message}`);
      return response.data;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das companhias do grupo.');
  }

  async getBanksByCompany(company: number): Promise<Array<Bank>> {
    const { status, data: response } = await this.datasource.get(`bank/listAllByCompany/${company}`);
    if (status == 200) {
      if (response.error) throw new Error(`Bancos por Empresa: ${response.message}`);

      const banks = response.data.map(Bank.make);
      return banks;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos banco da companhia.');
  }

  async getFinancialNatures(company: number, search?: string)
    : Promise<Array<FinancialNature>> {
    const { status, data: response } = await this.datasource.get('financialnature/listAll', {
      params: {
        search: search || '',
        limit: 50,
        company_id: company,
      },
    });
    if (status == 200) return response.data;
    throw new Error('Ops, algo inesperado aconteceu na listagem das naturezas financeiras.');
  }

  async getCostCenters(company: number, search?: string)
    : Promise<Array<CostCenter>> {
    const { status, data: response } = await this.datasource.get('costcenter/listAll', {
      params: {
        search: search || '',
        limit: 50,
        company_id: company,
      },
    });

    if (status == 200) return response.data;
    throw new Error('Ops, algo inesperado aconteceu na listagem dos centros de custo.');
  }

  async getAccountingItems(company: number, search?: string)
    : Promise<Array<AccountingItem>> {
    const { status, data: response } = await this.datasource.get('accountingitem/listAll', {
      params: {
        search: search || '',
        limit: 100,
        company_id: company,
      },
    });

    if (status == 200) return response.data;
    throw new Error('Ops, algo inesperado aconteceu na listagem dos itens contábeis.');
  }

  async getValueClasses(company: number, search?: string)
    : Promise<Array<ValueClass>> {
    const { status, data: response } = await this.datasource.get('valueclass/listAll', {
      params: {
        search: search || '',
        limit: 100,
        company_id: company,
      },
    });

    if (status == 200) return response.data;
    throw new Error('Ops, algo inesperado aconteceu na listagem das classes de valor.');
  }

  async getConciliationConfigs(company: number, bank: string)
    : Promise<Array<ConciliationConfig>> {
    const { status, data: response } = await this.datasource.get(`conciliation/action/listOneByCompanyBank/${company}/${bank}`);

    if (status == 200) {
      if (response.error) throw new Error(`Configuração de Conciliação: ${response.message}`);
      return response.data.conciliation_config;
    }
    throw new Error('Ops, algo inesperado aconteceu na obtenção das configuração de conciliação.');
  }

  async getERPMovements(
    group: number,
    company: number,
    params: IStatementConciliationSearch,
  ): Promise<DataWithPaginationInfo<Array<ERPMovement>>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/conciliations/search/erp-movements`, {
        headers: { 'company-id': company },
        params,
      });

    if (status == 200) return { data: response.data, meta: response.meta.total };
    throw new Error('Ops, algo inesperado aconteceu na obtenção dos movimentos.');
  }

  async getBankMovements(
    group: number,
    company: number,
    params: IStatementConciliationSearch,
  ): Promise<DataWithPaginationInfo<Array<BankMovement>>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/conciliations/search/bank-movements`, {
        headers: { 'company-id': company },
        params,
      });

    if (status == 200) return { data: response.data, meta: response.meta.total };
    throw new Error('Ops, algo inesperado aconteceu na obtenção dos movimentos bancários.');
  }

  async getHistory(params: IStatementConciliationHistoryFilter):
    Promise<Array<StatementConciliationHistory>> {
    const { status, data: response } = await this.datasource.get('conciliation/history/listAll', {
      params,
    });

    if (status == 200) {
      if (response.error) throw new Error(`Histórico: ${response.message}`);
      return response.data;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das classes de valor.');
  }

  async getCustomers(group: number, company: number, search: string, limit: number)
    : Promise<SelectOptions<string>[]> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/customers/search/like`, {
        headers: { 'company-id': company },
        params: {
          search,
          limit,
        },
      });

    if (status == 200) {
      const customers = response.data.map((customer: any) => {
        const {
            code, store, id, name,
        } = customer;
        return { text: `${code} ${store} - ${name}`, value: id };
      });
      return customers;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos clientes.');
  }

  async getTypes(): Promise<Array<AccountReceivableType>> {
    const { status, data: response } = await this.datasource.get('accountsReceivable/types');

    if (status == 200) {
      if (response.error) throw new Error(`Tipos: ${response.message}`);
      return response.data;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos tipos operacionais.');
  }

  async getReasons(group: number, company: number): Promise<Array<AcquittanceReasonOption>> {
    const { status, data: response } = await this.datasource.get(`acquittancereason/getOptions/${group}/${company}`);

    if (status == 200) {
      if (response.error) throw new Error(`Motivos: ${response.message}`);
      return response.data;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos motivos de baixa.');
  }

  async getConciliations(
    group: number,
    company: number,
    params: IStatementConciliationFilterData,
  ): Promise<Array<StatementConciliation>> {
    const { status, data: response } = await this.datasource.get(`company-groups/${group}/conciliations`, {
      headers: { 'company-id': company },
      params,
    });

    if (status === 200) {
      return response.data.map(StatementConciliation.make);
    }

    throw new Error('Ops, algo inesperado aconteceu na listagem das conciliações.');
  }

  async getSettleAccounts(group: number, company: number, params: IStatementConciliationWriteOff)
  : Promise<DataWithPaginationInfo<Array<SettleAccount>>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/conciliations/search/settle-accounts`, {
        headers: { 'company-id': company },
        params,
      });

    if (status == 200) return { data: response.data, meta: response.meta.total };
    throw new Error('Ops, algo inesperado aconteceu na listagem dos movimentos bancários.');
  }

  async getParametersFilter(): Promise<IStatementConciliationFilterData> {
    const { status, data: response } = await this.datasource.get('fieldparameter/getDataStatementConciliation');

    if (status == 200) {
      if (response.error) throw new Error(`Parâmetros do Filtro: ${response.message}`);
      return response.data;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos parâmetros.');
  }

  async getFilterParametersSettleAccounts(group: number, company: number)
  : Promise<StatementConciliationSettleAccountData> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/filter-parameters/conciliations/settle-accounts`, {
        headers: { 'company-id': company },
      });

    if (status == 200) return StatementConciliationSettleAccountData.make(response.data);
    throw new Error('Ops, algo inesperado aconteceu na listagem dos parâmetros.');
  }

  async deconciliation(
    group: number,
    company: number,
    data: IDeconciliation,
  ): Promise<DeconciliationMovements> {
    const { status, data: response } = await this.datasource.destroy(`/company-groups/${group}/conciliations`, {
      headers: { 'company-id': company },
      data,
    });

    if (status == 200) {
      return DeconciliationMovements.make(response.data);
    }

    throw new Error('Ops, algo inesperado aconteceu durante a desconciliação.');
  }

  async conciliation(
    group: number,
    company: number,
    data: IConciliation,
  ): Promise<StatementConciliation> {
    const { status, data: response } = await this.datasource.send(`/company-groups/${group}/conciliations`, data, {
      headers: { 'company-id': company },
    });

    if (status == 200) {
      const formattedConciliation = StatementConciliation.make(response.data, 0);
      formattedConciliation.shouldShowTableInformation = true;

      return formattedConciliation;
    }

    throw new Error('Ops, algo inesperado aconteceu durante a conciliação.');
  }

  async conciliationERPs(group: number, company: number, data: IConciliationERPs)
  : Promise<StatementConciliation[]> {
    const { status, data: response } = await this.datasource.send(`/company-groups/${group}/conciliations/erps`, data, {
      headers: { 'company-id': company },
    });

    if (status == 200) {
      return response.data.map((item: any, index: number) => {
        const formattedConciliation = StatementConciliation.make(item, index);
        formattedConciliation.shouldShowTableInformation = true;

        return formattedConciliation;
      });
    }

    throw new Error('Ops, algo inesperado aconteceu durante a conciliação do ERPs.');
  }

  async includeBankMovement(group: number, company: number, data: IIncludeMovement)
  : Promise<boolean> {
    const { status } = await this.datasource.send(`company-groups/${group}/conciliations/includes`,
      data,
      {
        headers: { 'company-id': company },
      });

    if (status == 200) return true;
    throw new Error('Ops, algo inesperado aconteceu durante a inclusão da movimentação bancária.');
  }

  async includeBankMovementWithTranfer(
    group: number,
    company: number,
    data: IIncludeMovementWithTransfer,
  ): Promise<boolean> {
    const { status } = await this.datasource.send(`/company-groups/${group}/conciliations/transfers`,
      data,
      {
        headers: { 'company-id': company },
      });

    if (status == 200) return true;
    throw new Error('Ops, algo inesperado aconteceu durante a inclusão da movimentação bancária.');
  }

  async getFinancialNatureDescriptionByCod(cod: string): Promise<string> {
    const { status, data: response } = await this.datasource.get(`financialnature/listOne/${cod}`);

    if (status == 200) {
      if (response.error) throw new Error(`Descrição da Natureza Financeira: ${response.message}`);
      return response.data[0].ed_descric;
    }
    throw new Error('Ops, não foi possível obter a descrição da natureza financeira.');
  }

  async getBankById(id: number): Promise<Bank> {
    const { status, data: response } = await this.datasource.get(`bank/listOne/${id}`);
    if (status == 200) {
      if (response.error) throw new Error(`Banco pelo ID: ${response.message}`);
      return response.data;
    }
    throw new Error('Ops, algo inesperado aconteceu na obtenção do banco.');
  }

  async writeOffMovement(group: number, company: number, data: ISettleAccountsReceivable)
    : Promise<boolean> {
    const { status } = await this.datasource
      .send(`company-groups/${group}/conciliations/settle-accounts-receivable`,
        data, { headers: { 'company-id': company } });

    if (status == 200) return true;
    throw new Error('Ops, algo inesperado aconteceu durante a baixa de título a receber.');
  }

  async getSummary(
    group: number,
    company: number,
    bank_id: string,
    initial_movement_date: string,
    end_movement_date: string,
  ): Promise<SummaryBankingMovement> {
    const { status, data: response } = await this.datasource
    .get(`company-groups/${group}/conciliations/summaries`, {
      headers: {
        'company-id': company,
      },
      params: {
        initial_movement_date,
        end_movement_date,
        bank_id,
      },
    });
    if (status == 200) return response.data;
    throw new Error('Ops, algo inesperado aconteceu na obtenção do resumo.');
  }

  async conciliationDivergents(group: number, company: number, data: IConciliationDivergent)
  : Promise<boolean> {
    const { status, data: response } = await this.datasource
      .send(`company-groups/${group}/conciliations/divergences`,
        data, { headers: { 'company-id': company } });

    if (status == 200) return true;
    throw new Error('Ops, algo inesperado aconteceu durante a conciliação dos divergentes.');
  }

  public async generateAdvance(
    companyGroupId: number,
    companyId: number,
    data: ICreateMovementAdvanceData,
  ): Promise<void> {
    const { status } = await this.datasource.send(`/company-groups/${companyGroupId}/conciliations/advances`, data, {
      headers: { 'company-id': companyId },
    });
  }
}
