import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';

export default class BillingRemittancePaymentMethod {
  constructor(
    public description: string,
    public items: Array<BillingRemittanceAccountReceivable>,
    public total: number,
  ) {}

  static make(data: any): BillingRemittancePaymentMethod {
    const items = data.items.map(BillingRemittanceAccountReceivable.make);
    return new BillingRemittancePaymentMethod(
      data.description,
      items,
      data.total,
    );
  }
}
