import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import Repository from '@/repositories/base/Repository';

export default class FilterParametersRepository extends Repository {
  public async setFilter(
    group: GroupFilterParametersEnum,
    filterParameters: { key: string, value: unknown }[],
  ): Promise<void> {
    const { status } = await this.datasource.send('filter-parameters', {
      filterParameters,
      group,
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }
  }

  public async getFilterByGroup(group: GroupFilterParametersEnum): Promise<any[]> {
    const { status, data: response } = await this.datasource.get('filter-parameters', {
        params: { group },
    });

    if (!this.isStatusOK(status)) {
        throw new Error('Aconteceu um erro inesperado');
    }

    return response.data;
  }
}
