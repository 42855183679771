






















































import { defineComponent } from '@vue/composition-api';
import CashFlowGrouperRepository from '@/repositories/CashFlowGrouperRepository';
import DialogHelper from '@/helpers/DialogHelper';

export default defineComponent({
  name: 'DeleteMainGrouperDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    id: {
      required: true,
      type: Number,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
  },
  computed: {
    dialogWidth(): string {
      return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
        smAndDown: '100%',
        md: '60%',
        default: '40%',
      });
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const cashFlowGrouperRepository: CashFlowGrouperRepository = new CashFlowGrouperRepository();

    const loading: boolean = false;
    const isOpen: boolean = this.value;

    return {
      cashFlowGrouperRepository,
      loading,
      isOpen,
    };
  },
  methods: {
    async removeMainGrouper(): Promise<void> {
      try {
        this.loading = true;

        await this.cashFlowGrouperRepository.deleteMainGrouper(
          this.companyGroupId,
          this.id,
        );

        this.$notification.success('Grupo removido com sucesso!');
        this.close(true);
      } catch (error) {
        this.$notification.error('Houve um problema ao remover o grupo principal');
      } finally {
        this.loading = false;
      }
    },
    close(reload: boolean): void {
      this.$emit('close', reload);
    },
  },
});
