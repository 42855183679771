import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c(VRow,{attrs:{"widht":"100%","justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_vm._v(" Ocorrências do Título "+_vm._s(_vm.item.prefixCode)+" - "+_vm._s(_vm.item.documentNumber)+" - "+_vm._s(_vm.item.documentType)+" ")]),_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c(VCardText,{staticStyle:{"max-height":"300px"}},[(_vm.occurrences.length > 0)?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Data e Hora")]),_c('th',[_vm._v("Código")]),_c('th',[_vm._v("Descrição")])])]),_c('tbody',_vm._l((_vm.occurrences),function(occurrence,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.formatDateHour(occurrence.date)))]),_c('td',[_vm._v(_vm._s(occurrence.code))]),_c('td',[_vm._v(_vm._s(occurrence.description))])])}),0)]},proxy:true}],null,false,3267667836)}):_c(VRow,[(_vm.loading)?_c(VCol,[_vm._v(" Carregando... ")]):_c(VCol,[_vm._v(" Nenhum resultado encontrado. ")])],1)],1),_c(VCardActions,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"12","lg":"2","md":"3","sm":"4","xs":"12"}},[_c(VBtn,{staticClass:"font-weight-medium",attrs:{"color":"warning","depressed":"","large":"","block":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" FECHAR ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }