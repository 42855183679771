import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"PredictedFlowFilterForm"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"3","md":"3","sm":"6","xs":"12"}},[_c('inn-date-field',{attrs:{"label":"Data inicial","placeholder":"Selecione a data inicial","height":"56px","required":true,"buttons":false,"disabled":_vm.isLoading},model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"3","md":"3","sm":"6","xs":"12"}},[_c('inn-date-field',{attrs:{"label":"Data final","placeholder":"Selecione a data final","height":"56px","required":true,"buttons":false,"disabled":_vm.isLoading},model:{value:(_vm.finalDate),callback:function ($$v) {_vm.finalDate=$$v},expression:"finalDate"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.companyOptions,"disabled":_vm.isLoading,"label":"Empresas","placeholder":"Deixe em branco para todos","height":"56px","deletable-chips":"","small-chips":"","clearable":"","multiple":"","filled":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"subtitle-1 mr-1"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedCompanies.length - 1)+" outros) ")]):_vm._e()]}}]),model:{value:(_vm.selectedCompanies),callback:function ($$v) {_vm.selectedCompanies=$$v},expression:"selectedCompanies"}})],1)],1),_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"items":_vm.bankBalanceOptions,"label":"Considerar Saldos Bancários","filled":"","dense":"","clearable":""},model:{value:(_vm.considerBankBalance),callback:function ($$v) {_vm.considerBankBalance=$$v},expression:"considerBankBalance"}})],1),_c(VCol,[_c(VSelect,{attrs:{"items":_vm.periodicityOptions,"label":"Periodicidade","filled":"","dense":"","clearable":""},model:{value:(_vm.periodicity),callback:function ($$v) {_vm.periodicity=$$v},expression:"periodicity"}})],1),_c(VCol,[_c(VSelect,{attrs:{"items":_vm.showOptions,"label":"Mostrar","required":"","filled":"","dense":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1),_c(VCol,[_c(VSelect,{attrs:{"items":_vm.reportTypeOptions,"label":"Tipo de Relatório","filled":"","dense":"","clearable":""},model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }