<template>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
					<h3 class="m-portlet__head-text">
						{{type}}
					</h3>
                </div>
            </div>
            <div class="m-portlet__head-tools">
                <div class="btn-group m-btn-group--air" role="group" aria-label="...">
                    <router-link  :to="{ name: 'bankretconfig' }" class="m-btn btn btn-warning">Cancelar
                    </router-link>
                    <button id="btn-save" @click="save" class="m-btn btn btn-success" form="formCnabConfig">Salvar
                    </button>
                </div>
            </div>
        </div>
        <div class="m-portlet__body">
			<div
				id="bank-ret-config-add-form"
				class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
			>
				<div class="row">
					<!-- <input type="hidden" name="company_group_id" id="company_group_id" v-model="company_group_id"> -->
					<div class="col-md-9">
						<div class="form-group">
							<label>Empresa</label>
							<select class="form-control select2" field-name="Empresa" id="company_id" name="company_id" style="width: 100%;" v-model="company">
								<option value="0">Todas</option>
								<option v-for="company in companys" :value="company.id" v-bind:key="company.id">{{ company.name }}</option>
							</select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>Status</label>
							<select class="form-control select2 hide" field-name="Status" id="status" name="status" style="width: 100%;" v-model="status">
								<option value="0">Inativo</option>
								<option value="1" selected>Ativo</option>
							</select>
						</div>
					</div>             
					<div class="col-md-6">
						<div :class="['form-group']">
							<label>Banco</label>
							<select class="form-control select2 hide"
									id="code_bank" name="code_bank" style="width: 100%;" field-name="Banco" v-model="bank">
								<option :value="undefined">Selecione...</option>
								<option v-for="bank in banks" :value="bank.code" v-bind:key="bank.code">{{ bank.code}} - {{ bank.name}}
								</option>
							</select>
						</div>
					</div>		
					<div class="col-md-6">
						<div :class="['form-group']">
							<label>Retorno CNAB</label>
							<select class="form-control hide select2" id="cnab_remi_config_id" name="cnab_remi_config_id" style="width: 100%;" field-name="Retorno CNAB" v-model="cnabRemittance">
								<option :value="undefined">Selecione...</option>
								<option v-for="cnabRemittanceConfig in cnabRemittanceConfigs" :value="cnabRemittanceConfig.id" v-bind:key="cnabRemittanceConfig.id">
									{{ cnabRemittanceConfig.description }}
								</option>
							</select>
						</div>
					</div>	
				</div>
			</div>
        </div>
    </div>
</template>

<script>
	import { API_BASE } from '@config/api';

    export default {
        data() {
            return {
                company_group_id: this.$session.get('company_group_id'),
                cnabRemittanceConfigs: {},
				banks: [],
				bankret: {},
                companys: {},
				sendData: {},
				type: "Salvar",
				//selected models
				cnabRemittance: undefined,
				bank: undefined,
				status: undefined,
				company: undefined
            }
        },
        mounted: function () {

            this.loadBanks();
            this.loadCnabRemittanceConfigs();
			this.loadCompanys();

			if(this.$route.params.bankretConfigId) this.loadBankret(this.$route.params.bankretConfigId);

            $(".select2").select2({placeholder: 'Selecione...'});
			$(".select2CnabRemittance").select2({placeholder: "Selecione a remessa..."});
        },
        methods: {

            loadCnabRemittanceConfigs: function () {
                var self = this;
                this.$http.get(API_BASE + 'cnabremittance/all/configs/' + this.$session.get("company_group_id") , {headers: {'token': this.$session.get('token')}}).then(
                    function (result) {
                        self.cnabRemittanceConfigs = result.body.data
                    },
                    function (error) {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                    }
                ).bind(this);
            },

            loadCompanys: function () {
                var self = this;
            	blockPage('Carregando os dados...');

                this.$http.get(API_BASE+'company/listAll', {headers: {'token': this.$session.get('token')}}).then(
                	(result) =>{
						self.companys = result.body.data
						unblockPage();
                	},
                	(error) => {
						showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
		            	unblockPage();
                	}
                )
            },
            
            loadBanks: function () {
                var self = this;
                this.$http.get(API_BASE + 'bank/listAll/' + this.$session.get('company_group_id'), {headers: {'token': this.$session.get('token')}}).then(
                    function (result) {
						self.banks = result.body.data
                    },
                    function (error) {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                    }
                ).bind(this);
			},
			
			loadBankret: function (id) {
				var self = this;
				self.type = "Atualizar";

                this.$http.get(API_BASE + 'bankret/config/find/' + id, {headers: {'token': this.$session.get('token')}}).then(
                    function (result) {
						let bankret = result.body.data;
						self.bank = bankret.code_bank;
						self.status = bankret.status;
						self.cnabRemittance = bankret.cnab_remi_config_id;
						self.company = bankret.company_id;
                        unblockPage();
                    },
                    function (error) {
                        showNotification('Atenção', 'Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
                        unblockPage();
                    }
                ).then(() => {
					$(".select2").change();
				});
			},

            save: function () {
                let withError;
				let arrayData = {}, i = 0;

				arrayData.company_group_id = this.company_group_id;

				$.each($('#bank-ret-config-add-form :input'), function(){
					if($.trim(this.value) == ''){
						withError = true;

						$(".form-group:has(#"+this.id+")").addClass('has-danger');
						$("#"+this.id).addClass('input-error');
						$("#"+this.id).css('border-color','red');
						showNotification('Atenção', 'O campo <strong>'+ $("#"+this.id).attr('field-name')+'</strong> não foi informado!', 'warning');

						$("#"+this.id).keyup(function(){
							if ($("#"+this.id).hasClass('input-error')) {
								$("#"+this.id).removeClass('input-error');
								$("#"+this.id).css('border-color', '#ebedf2');
								$(".form-group:has(#"+this.id+")").removeClass('has-danger');
							}
						});						
					}else {
						arrayData[this.id] = this.value;						
					}
					
					i++;
				});

				if(withError) return false;

				if(this.$route.params.bankretConfigId) arrayData["id"] = this.$route.params.bankretConfigId;

				$.ajax({
					method: "POST",
					url: API_BASE + 'bankret/config/save',
					headers: {
						'token': this.$session.get('token')
					},
					data: {
						data: JSON.stringify(arrayData),
					}
				}).done((result) => {
					if(!result.error){
						showNotification('Sucesso', 'Processo concluído com sucesso!','success');

						this.$router.replace({name: 'bankretconfig'});
					}else{
						showNotification('Atenção',result.message, 'danger');
						return false;
					}
				}).fail((error) => {
					console.log(error.responseText);
				});
            }
		}
	}
</script>
