



















































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import {
  dateNow,
  getObjectDate,
  formatToSpecificFormat,
  dateEqualToday,
  dateGreaterThanToday,
} from '@/utils/date';
import StringHelper from '@/helpers/StringHelper';
import BillingHistory from '@/domain/models/BillingHistory';
import VMenuOptions from '@/types/VMenuOptions';
import IButtonOption from '@/plugins/inn-design/components/buttons/interfaces/IButtonOption';

@Component
export default class ScheduleSummary extends Vue {
  public todayDay: number = 0;
  public todayYear: number = 0;
  public todayMonthWritten: string = ''
  public billingHistoryInteractionOptions: VMenuOptions[] = [
    { text: 'Editar', value: 'edit' },
    { text: 'Histórico', value: 'history' },
    { text: 'Concluir com sucesso', value: 'conclude-success' },
    { text: 'Concluir como falha', value: 'conclude-fail' },
  ];

  created() {
    const nowDate = dateNow();
    const objectDate = getObjectDate(nowDate);

    this.todayMonthWritten = StringHelper.capitalize(formatToSpecificFormat(nowDate, 'MMMM'));
    this.todayDay = objectDate.day;
    this.todayYear = objectDate.year;
  }

  @Prop({
    type: Array,
    default: () => ([]),
  }) readonly billingHistories!: BillingHistory[];

  @Prop({
    type: Boolean,
    default: false,
  }) readonly loading!: Boolean;

  public get todayEvents(): BillingHistory[] {
    return this.billingHistories
      .filter((event) => dateEqualToday(event.date));
  }

  public get nextEvents(): BillingHistory[] {
    return this.billingHistories
      .filter((event) => dateGreaterThanToday(event.date))
      .map((event) => ({
        ...event,
        date: formatToSpecificFormat(event.date, 'dd/MM/yyyy'),
      }));
  }

  public handleSelectedOption(event: string, billingHistory: BillingHistory): void {
    this.$emit(event, billingHistory);
  }

  public getStatusClassColor(status: string): string {
    switch (status) {
      case 'success':
        return 'green-schedule-action-color';
      case 'failure':
        return 'red-schedule-action-color';
      default:
        return 'blue-schedule-action-color';
    }
  }

  public getOptionsBasedOnStatus(status: string): IButtonOption[] {
    if (status === 'success' || status === 'failure') {
      return this.billingHistoryInteractionOptions.filter((billingHistory) => billingHistory.value === 'history');
    }

    return this.billingHistoryInteractionOptions;
  }
}
