


























import {
  Component,
  Vue,
} from 'vue-property-decorator';
import PreNegotiationSelectedAccountsTable from '@/views/preNegotiation/installment/PreNegotiationSelectedAccountsTable.vue';
import PreNegotiationInstallmentsTable from '@/views/preNegotiation/installment/PreNegotiationInstallmentsTable.vue';

@Component({
  components: {
    PreNegotiationSelectedAccountsTable,
    PreNegotiationInstallmentsTable,
  },
})
export default class PreNegotiationInstallments extends Vue {
}
