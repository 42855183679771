export default {
  props: ['value'],
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    model() {
      this.$emit('input', this.model);
    },
    value() {
      this.model = this.value;
    },
  },
};
