import HealthCheckStatusEnum from '@/views/healthCheck/enums/HealthCheckStatusEnum';
import IntegratorJobsEnum from '@/views/healthCheck/enums/IntegratorJobsEnum';

export default class HealthCheck {
  constructor(
    public schedulesStatus: { name: IntegratorJobsEnum, status: HealthCheckStatusEnum }[],
    public apiStatus: HealthCheckStatusEnum,
    public authStatus: HealthCheckStatusEnum,
    public virtualMachineStatus: HealthCheckStatusEnum,
  ) { }

  static make(data: any): HealthCheck {
    return new HealthCheck(
      data.schedules_status,
      data.api_status,
      data.auth_status,
      data.virtual_machine_status,
    );
  }
}
