
























































import {
  Component,
  Vue,
  Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import CreditRulerepository from '@/repositories/CreditRuleRepository';
import GeneralCreditRuleForm from '@/views/creditRules/components/GeneralCreditRuleForm.vue';
import ErpCreditRuleBuilder from '@/views/creditRules/components/ErpCreditRuleBuilder.vue';
import CreditRule from '@/domain/models/CreditRule';
import IGeneralCreditRuleForm from '@/views/creditRules/interfaces/IGeneralCreditRuleForm';
import ISaveCreditRuleParams from '@/views/creditRules/interfaces/ISaveCreditRuleParams';
import IErpCreditRuleAndFilterGroup from '@/views/creditRules/interfaces/IErpCreditRuleAndFilterGroup';

@Component({
  components: {
    GeneralCreditRuleForm,
    ErpCreditRuleBuilder,
  },
})
export default class CreditRuleUpsert extends Vue {
  private readonly creditRulerepository: CreditRulerepository = new CreditRulerepository();
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

  public definedGeneralCreditRule: IGeneralCreditRuleForm|null = null;

  public definedRulesJson: IErpCreditRuleAndFilterGroup|null = null;

  public selectedCompanies: number[] = [];

  public loadingCreditRuleInfo: boolean = true;

  public get isUpdate(): boolean {
    return !!this.$route.params.id && !!this.$route.params.companyId;
  }

  public get getUpsertTitle(): string {
    return this.isUpdate ? 'Atualizar Regra' : 'Nova regra';
  }

  @Ref('GeneralCreditRuleForm') readonly generalCreditRuleForm!: { getGeneralRuleInfo: () => IGeneralCreditRuleForm|boolean };

  @Ref('ErpCreditRuleBuilder') readonly erpCreditRuleBuilder!: { getCreditRules: () => IErpCreditRuleAndFilterGroup|boolean };

  public async created(): Promise<void> {
    if (this.isUpdate) {
      await this.getCreditRuleById();
    }

    this.loadingCreditRuleInfo = false;
  }

  public async getCreditRuleById(): Promise<void> {
    this.$dialog.startLoading();

    try {
      const creditRule = await this.creditRulerepository.getCreditRuleById(
        this.authenticationModule.user.company_group_id,
        parseInt(this.$route.params.companyId, 10),
        parseInt(this.$route.params.id, 10),
      );

      this.setCreditRuleValues(creditRule);
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar a regra de crédito selecionada.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public setCreditRuleValues(creditRule: CreditRule): void {
      this.definedGeneralCreditRule = {
        id: creditRule.id,
        name: creditRule.name,
        description: creditRule.description,
        companyIds: creditRule.companies,
        active: creditRule.active,
      };

      this.definedRulesJson = {
        rule: creditRule.rule,
        filter: creditRule.filter,
      };
      this.selectedCompanies = creditRule.companies;
  }

  public async saveCreditRule(params: ISaveCreditRuleParams): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.creditRulerepository.insertCreditRule(
        this.authenticationModule.user.company_group_id,
        params,
      );

      this.$notification.success('Regra de crédito criada com sucesso!');

      this.$router.push({ name: 'CreditRulesList' });
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao inserir a regra de crédito.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async updateCreditRule(id: string, params: ISaveCreditRuleParams): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.creditRulerepository.updateCreditRule(
        this.authenticationModule.user.company_group_id,
        id,
        params,
      );

      this.$notification.success('Regra de crédito atualizada com sucesso!');

      this.$router.push({ name: 'CreditRulesList' });
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao atualizar os dados da regra de crédito selecionada.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public handleCompaniesSetted(companyIds: number[]): void {
    this.selectedCompanies = companyIds;
  }

  public validateCreditRule(): void {
    const generalCreditRule = this.generalCreditRuleForm.getGeneralRuleInfo();
    const erpCreditRules = this.erpCreditRuleBuilder.getCreditRules();

    if (!generalCreditRule || !erpCreditRules) {
      return;
    }

    const ruleParams = {
      ...generalCreditRule as IGeneralCreditRuleForm,
      ...erpCreditRules as IErpCreditRuleAndFilterGroup,
    };

    if (this.isUpdate) {
      this.updateCreditRule(this.$route.params.id, ruleParams);

      return;
    }

    this.saveCreditRule(ruleParams);
  }

  public cancel(): void {
    this.$router.push({ name: 'CreditRulesList' });
  }
}
