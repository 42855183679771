<template>
  <v-sheet class="page-content" :class="`page-content--${kebabCase(title)}`">
    <v-container fluid class="pb-0 px-md-8 pt-md-5 pb-md-1">
      <slot name="header">
        <v-row align="center" justify="space-between">
          <v-col cols="auto">
            <slot name="title">
              <h3>{{ title }}</h3>
            </slot>
          </v-col>
          <v-col cols="auto">
            <slot name="actions"></slot>
          </v-col>
        </v-row>
      </slot>
    </v-container>
    <v-divider></v-divider>
    <v-container fluid class="px-md-8 pt-md-2 pb-md-6">
      <slot name="body"></slot>
    </v-container>
  </v-sheet>
</template>

<script>
import { kebabCase } from '@/utils';

export default {
  name: 'PageContent',
  props: {
    title: String,
  },
  methods: {
    kebabCase,
  },
};
</script>

<style lang="sass">
.page-content h3
  font-size: 1.3rem
  font-weight: 500
  font-family: Roboto
  margin: 0
</style>
