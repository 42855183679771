export default class LoginNews {
  constructor(
    public image_url: string,
    public icon: string,
    public title: string,
    public text: string,
    public link: string,
  ) {}

  static make(data: any): LoginNews {
    return new LoginNews(
        data.image_url,
        data.icon,
        data.title,
        data.text,
        data.link,
    );
  }
}
