import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"return-value":_vm.model,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.model=$event},"update:return-value":function($event){_vm.model=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('inn-text-field',_vm._g(_vm._b({attrs:{"readonly":"","clearable":"","icon":"mdi-calendar","hide-details":_vm.rules.length == 0,"rules":_vm.rules},on:{"click:clear":_vm.emitClearedDateRange},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'inn-text-field',Object.assign({}, attrs, _vm.$attrs),false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VSpacer),_c(VBtn,{staticClass:"uppercase",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancelar")]),_c(VBtn,{staticClass:"uppercase",attrs:{"color":"primary","text":""},on:{"click":_vm.emitSelectedDateRange}},[_vm._v("OK")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }