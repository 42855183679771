







































































import {
 Vue, Component, Prop, Emit, Ref, Watch,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import IOption from '@/domain/interfaces/ISelectOptions';
import EAction from '@/domain/enums/InstructionRemittanceAction';
import InstructionRemittance from '@/domain/models/InstructionRemittance';
import InstructionsRemittanceRepository from '@/repositories/InstructionsRemittanceRepository';
import { formatErrorForNotification } from '@/utils/error';
import {
  EInstructionRemittanceType as EType,
  EInstructionRemittanceTypeTranslate as ETypeTranslate,
} from '@/domain/enums/InstructionRemittanceType';

@Component
export default class InstructionsRemittanceChangeInstruction extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Boolean,
    default: false,
    required: true,
  })
  readonly open!: boolean;

  @Prop({
    type: Array as () => Array<InstructionRemittance>,
    default: [] as Array<InstructionRemittance>,
    required: true,
  })
  readonly items!: Array<InstructionRemittance>;

  @Prop({
    type: Array as () => Array<number>,
    default: [] as Array<number>,
    required: true,
  })
  readonly company!: Array<number>;

  @Emit()
  close(reload = false): { type: EAction; reload: boolean } {
    return {
      type: EAction.CHANGE_INSTRUCTION,
      reload,
    };
  }

  @Watch('open')
  onChangeOpen(value: boolean) {
    if (!value) {
      this.loading = false;
      this.instruction = null;
      this.form.resetValidation();
    }
  }

  @Watch('instruction')
  onChangeInstruction() {
    this.instructionValue = null;
  }

  readonly required = (value: string) => !!value || 'Campo obrigatório!';

  readonly instructionsRemittanceRepository: InstructionsRemittanceRepository =
    new InstructionsRemittanceRepository();

  loading: boolean = false;
  instruction: Exclude<keyof typeof EType, 'UPDATE' | 'DELETE'> | null = null;
  instructionValue: string | number | null = null;

  options: Array<IOption<string>> = [
    {
      value: EType.UPDATE_DUE,
      text: ETypeTranslate.UPDATE_DUE,
    },
    {
      value: EType.UPDATE_DESCREASE,
      text: ETypeTranslate.UPDATE_DESCREASE,
    },
    {
      value: EType.UPDATE_INCREASE,
      text: ETypeTranslate.UPDATE_INCREASE,
    },
    {
      value: EType.UPDATE_VALUE,
      text: ETypeTranslate.UPDATE_VALUE,
    },
  ];

  get valueLabel(): string {
    if (this.instruction) {
      const instructions = {
        [EType.UPDATE_DESCREASE]: 'Valor do Decréscimo',
        [EType.UPDATE_DUE]: 'Valor da Data',
        [EType.UPDATE_INCREASE]: 'Valor do Acrescimo',
        [EType.UPDATE_VALUE]: 'Valor do Título',
      };

      return instructions[this.instruction] ?? '';
    }

    return '';
  }

  get item(): InstructionRemittance {
    return this.items.length ? this.items[0] : ({} as InstructionRemittance);
  }

  get isUpdateInValue(): boolean {
    if (!this.instruction) return false;

    return ['UPDATE_INCREASE', 'UPDATE_DESCREASE', 'UPDATE_VALUE'].includes(
      this.instruction,
    );
  }

  get isFieldDisabled() {
    return this.instruction === EType.UPDATE_VALUE;
  }

  async save(): Promise<void> {
    try {
      if (!this.instruction) return;

      this.$dialog.startLoading();

      const groupId = this.$session.get('company_group_id');
      const { company } = this;
      const { id, idCustomer } = this.item;

      if (this.instruction === EType.UPDATE_VALUE) {
        this.instructionValue = 0;
      }

      const translatedFieldName = {
          UPDATE_DUE: 'date',
          UPDATE_DESCREASE: 'discount',
          UPDATE_INCREASE: 'interest',
          UPDATE_VALUE: 'value',
        };

      const field = translatedFieldName[this.instruction];
      const value = this.instructionValue !== null ? this.instructionValue.toString() : '';
      const data = { field_name: field, new_value: value };

      await this.instructionsRemittanceRepository.changeType(
        groupId,
        idCustomer,
        id,
        company,
        data,
      );

      this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
