<template>
    <div style="display: inline;">
        <popper trigger="click"
                :options="{
                  placement: 'right',
                  modifiers: { offset: { offset: '0,10px' } }
                }">
            <div class="popper" :style="{width: boxSize + 'px'}">
                <span style="overflow-wrap: break-word;">{{description}}</span>
            </div>
            <a slot="reference" style="cursor: pointer;">
                <i class="la  la-ellipsis-h" v-if="description.length > 20"></i>
            </a>
        </popper>
    </div>
</template>

<script>
    import Popper from 'vue-popperjs';

    export default {
        components: {
            'popper': Popper
        },
        name: "Popper-Innova",
        props: ['description', 'boxSize'],
    }
</script>