
































import {
  Component,
  Mixins,
  Prop,
  Emit,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';

@Component
export default class RoutineLockDialog extends Mixins<ModelManagementMixin<boolean>>(
  ModelManagementMixin,
) {
  public model: boolean = this.value;

  public get dialogWidth(): string {
    if (this.$vuetify.breakpoint.smAndDown) {
      return '100%';
    }

    if (this.$vuetify.breakpoint.md) {
      return '60%';
    }

    return '40%';
  }

  @Prop({
    type: String,
    required: true,
  }) readonly userName!: string;

  @Emit('close')
  public handleBack() {
    return true;
  }
}
