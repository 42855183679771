



















































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

import DDAConciliation from '@/domain/models/DDAConciliation';
import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';

import StatusType from '@/domain/enums/DDAConciliationStatusType';
import OriginType from '@/domain/enums/DDAConciliationOriginType';
import ActionType from '@/domain/enums/DDAConciliationActionType';

import { formateDate } from '@/utils/date';
import { formateCurrency } from '../utils';

@Component({})
export default class DDAConciliationResolve extends Vue {
  @Prop(Boolean) readonly open!: boolean;

  @Prop({
    type: Object as () => DDAConciliation,
  }) readonly item!: DDAConciliation;

  @Emit()
  close(): ActionType {
    return ActionType.RESOLVE;
  }

  @Emit()
  conciliation(hasDivergence: boolean, dueDate?: string)
  : { hasDivergence: boolean, dueDate: string | undefined } {
    this.close();

    return { hasDivergence, dueDate };
  }

  @Watch('open')
  opened() {
    if (this.open) {
      this.dueDate = this.dda.due_date;
    } else {
      this.dueDate = '';
    }
  }

  readonly formateCurrency = formateCurrency;
  readonly formateDate = formateDate;

  readonly StatusType = StatusType;

  dueDate: string = '';

  get status(): StatusType {
    return this.item.status;
  }

  get dda(): DDAConciliationMovement {
    return this.item.movements?.find((mov) => mov.origin === OriginType.DDA)
    || {} as DDAConciliationMovement;
  }

  get erpSelected(): DDAConciliationMovement {
    return this.item.movements
      ?.find((mov) => mov.origin === OriginType.ERP && mov.selected === true)
      || {} as DDAConciliationMovement;
  }

  get divergentValue(): boolean {
    if (this.erpSelected.balance) return this.dda.value !== this.erpSelected.balance;
    return this.dda.value !== this.erpSelected.value;
  }

  get divergentDue(): boolean {
    return this.dda.due_date !== this.erpSelected.due_date;
  }

  get hasDivegence(): boolean {
    return this.divergentValue || this.divergentDue;
  }

  get hasDivegenceBarcode(): boolean {
    return (this.dda.barcode != this.erpSelected.barcode);
  }

  handleConciliation(): void {
    if (this.status == StatusType.DIVERGENCE) {
      if (this.divergentDue) {
        if (!this.dueDate) return;

        this.conciliation(this.hasDivegence, this.dueDate);
      } else {
        this.conciliation(this.hasDivegence);
      }
    } else {
      this.conciliation(this.hasDivegenceBarcode);
    }
  }
}
