




































import {
  Vue,
  Component,
  Prop,
  Watch,
  Emit,
} from 'vue-property-decorator';

@Component
export default class EditorMenu extends Vue {
  @Prop({
    type: Number,
    default: Number(undefined),
  }) readonly value!: number;

  @Prop({
    type: String,
    default: 'Digite um novo valor:',
  }) readonly label!: string;

  @Watch('value')
  watchValue() {
    this.model = this.value;
    this.onChangeValue();
  }

  @Watch('model')
  watchModel() {
    if (this.isPercent()) this.$emit('input', Number(this.model));
  }

  @Emit()
  public onChangeValue(): number {
    return this.model;
  }

  errorMessages: string = '';
  model: number = this.value;

  isPercent() {
    return (
      !Number.isNaN(this.model)
      && Number(this.model) >= 0
      && Number(this.model) <= 100
    );
  }

  percentCheck() {
    this.errorMessages = !this.isPercent() ? 'Esse campo deve ser um valor entre 0 e 100' : '';
    return true;
  }
}
