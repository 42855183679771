import IReloadScreenActions from '@/views/cashFlow/predictedFlow/interfaces/reloadScreenActions/IReloadScreenActions';

export default {
  namespaced: true,
  state: {
    isEventSend: false,
    eventData: {},
  },
  mutations: {
    triggerEventSend(state: any, eventData: IReloadScreenActions) {
      state.isEventSend = true;
      state.eventData = eventData;
    },
    emptyEvent(state: any) {
      state.isEventSend = false;
      state.eventData = {};
    },
  },
};
