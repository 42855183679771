export default class VanList {
    constructor(
        public id: number,
        public description: string,
    ) {}

    static make(data: any): VanList {
        return new VanList(
            data.id,
            data.description,
        );
    }
}
