import UserType from '../enums/UserType';

export default class User {
  constructor(
    public id : number,
    public name: string,
    public email: string,
    public status : boolean,
    public userType : UserType,
    public userGroupId : number,
    public companyGroupId: number,
    public companyIds : number[],
    public erpCode : string,
    public powerBIUrl : string,
    public forceChangePassword : boolean,
  ) {}

  static make(data : any) : User {
    const userTypeKey = data.user_type ?? '';
    const userType = UserType[userTypeKey as keyof typeof UserType];

    const companyIds = data.user_company && data.user_company.length > 0
      ? data.user_company.map((comp : any) => comp.company_id)
      : [];

    return new User(
      data.id,
      data.name,
      data.email,
      data.status,
      userType,
      data.user_group_id,
      data.company_group_id,
      companyIds,
      data.erp_code,
      data.url_powerbi,
      data.force_change_password,
    );
  }
}
