<template>
  <v-container
    class="px-md-8"
    fluid
  >
    <history-timeline></history-timeline>
  </v-container>
</template>

<script>
import HistoryTimeline from '@/components/timeline/HistoryTimeline.vue';

export default {
  name: 'OrderHistory',
  components: {
    HistoryTimeline,
  },
};
</script>
