import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{directives:[{name:"sticky",rawName:"v-sticky",value:(_vm.expand && _vm.items.length > 9 ? _vm.items : []),expression:"expand && items.length > 9 ? items : []"}],ref:"sticky",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id_customer","hide-default-header":"","hide-default-footer":"","disable-pagination":"","disable-filtering":"","show-select":"","dense":""},on:{"input":function($event){return _vm.input($event, _vm.title)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
  var ref_on = ref.on;
  var sort = ref_on.sort;
  var toggleDefault = ref_on['toggle-select-all'];
  var ref_props = ref.props;
  var ref_props_options = ref_props.options;
  var sortBy = ref_props_options.sortBy;
  var sortDesc = ref_props_options.sortDesc;
  var someItems = ref_props.someItems;
  var everyItem = ref_props.everyItem;
return [_c('thead',{staticClass:"v-data-table-header table-payment-remittances"},[_c('tr',{attrs:{"role":"columnheader","scope":"col"}},_vm._l((_vm.headers),function(ref){
  var _obj;

  var value = ref.value;
  var text = ref.text;
  var sortable = ref.sortable;
  var align = ref.align;return _c('sticky-cell',{key:value,class:( _obj = {
            'custom-sortable': sortable,
            'justify-center': !align
          }, _obj[("justify-" + align)] = !!align, _obj['text-center'] =  true, _obj ),on:{"click":function($event){sortable && sort(value)}}},[(value === 'data-table-select')?_c('div',{class:[
              'd-flex',
              'flex-column',
              !!align
                ? ("justify-" + align)
                : 'justify-center' ]},[(!_vm.isSticky)?_c('toggle-button',{attrs:{"value":_vm.expand,"small":""},on:{"click":_vm.toggleExpand}}):_vm._e(),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VSimpleCheckbox,_vm._g(_vm._b({attrs:{"ripple":false,"value":everyItem,"indeterminate":someItems,"small":""},on:{"input":_vm.toggle}},'v-simple-checkbox',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Selecionar todos")])])],1):(value === 'action')?_c('div',{class:[
              'd-flex',
              'flex-column',
              'align-center' ]},[_c('menu-header',{attrs:{"generate":_vm.generate,"invoice":_vm.invoice,"approve":_vm.approve},on:{"click":_vm.handleClickAction}})],1):(value === 'title')?_c('div',{class:[
              'd-flex',
              'flex-column',
              !!align
                ? ("justify-" + align + " text-" + align)
                : 'justify-center'
            ]},[_c('span',{},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(sortable)?_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(sortDesc[0] && sortBy[0] == value ? 'mdi-arrow-down' : 'mdi-arrow-up')+" ")]):_vm._e()],1):_c('div',{class:[
              'd-flex',
              'align-center',
              'justify-start',
              'text-center',
              { 'active': sortBy[0] == value }
            ]},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',[_vm._v(_vm._s(text))]),(_vm.currencyColumns.includes(value))?_c('span',[_vm._v(" "+_vm._s(_vm.sum(value))+" ")]):_vm._e()]),(sortable)?_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(sortDesc[0] && sortBy[0] == value ? 'mdi-arrow-down' : 'mdi-arrow-up')+" ")]):_vm._e()],1)])}),1)])]}},{key:"body",fn:function(ref){
            var items = ref.items;
            var headers = ref.headers;
            var select = ref.select;
            var isSelected = ref.isSelected;
return [_c('tbody',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}]},_vm._l((items),function(item){return _c('tr',{key:item.id_customer},_vm._l((headers),function(header){return _c('td',{key:item.id+header.value,class:[
            !!header.align ? ("text-" + (header.align)) : '',
            header.value !== 'data-table-select'
            && header.value !== 'action'
              ? 'cel-content'
              : '',
            header.value === 'title'
              ? 'cel-title'
              : '',
            header.value === 'data_of_payment'
              ? 'cel-payment-data'
              : '' ]},[(header.value === 'data-table-select')?_c('span',[_c(VSimpleCheckbox,{attrs:{"value":isSelected(item),"readonly":_vm.isItemDisabled(item),"disabled":_vm.isItemDisabled(item),"ripple":false,"color":_vm.statusColor(item.status)},on:{"input":function($event){return _vm.handleSelectSingularItem(item, select, isSelected)}}})],1):(header.value === 'title')?_c('span',{class:{
              'd-flex': true,
              'align-center': true,
              'justify-space-between': true,
              'mr-4': true,
              'not-liberated': _vm.isItemDisabled(item),
            }},[_vm._v(" "+_vm._s(_vm.formatTitle(item, _vm.name))+" "),(item.comment.length)?_c(VBtn,{staticClass:"ml-1",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.openComment(item)}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-comment-text-outline ")])],1):_vm._e()],1):(header.value === 'due_date')?_c('span',{class:{ 'not-liberated': _vm.isItemDisabled(item) }},[_vm._v(" "+_vm._s(_vm.formatDate(item.due_date))+" ")]):(header.value === 'data_of_payment')?_c('span',{class:{ 'not-liberated': _vm.isItemDisabled(item) },domProps:{"innerHTML":_vm._s(_vm.formatPaymentDataToHTML(item))}}):(header.value === 'status')?_c('span',{staticClass:"mr-4"},[_c('status-icon',{attrs:{"list":_vm.statusList,"code":item[header.value]}})],1):(header.value === 'action')?_c('span',[_c('menu-row',{attrs:{"item":item},on:{"action":_vm.action}})],1):(_vm.currencyColumns.includes(header.value))?_c('span',{class:{
              'mr-4': true,
              'not-liberated': _vm.isItemDisabled(item),
              'align-cell-value': header.value === 'total',
            }},[(header.value === 'total')?_c('div',{staticClass:"d-flex"},[(item.inncash_value)?_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-cash ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.makeBalance(item)))+" ")])],1):(header.value === 'sent'
              && _vm.isStatusToApproveOrReprove(item.status))?_c('span',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.makeBalance(item)))+" ")]):(header.value === 'approved'
              && item.status === _vm.statusEnum.APPROVED)?_c('span',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.makeBalance(item)))+" ")]):_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item[header.value])))])]):_c('span',{class:{
              'mr-4': true,
              'not-liberated': _vm.isItemDisabled(item)
            }},[_vm._v(" "+_vm._s(item[header.value])+" ")])])}),0)}),0)]}}]),model:{value:(_vm.selecteds),callback:function ($$v) {_vm.selecteds=$$v},expression:"selecteds"}})}
var staticRenderFns = []

export { render, staticRenderFns }