import Rest from './base/Rest';

export default class RemittancePayableService extends Rest {
	static resource = 'remittancepayable';

	updatePixData() {
		this.relationship = 'updatePixData';

		return this;
	}
}
