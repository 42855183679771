<template>
  <v-app-bar class="noPrint" style="z-index:1049" dense dark fixed clipped-left color="#2c2e3e" app>
    <v-toolbar-title>
      <v-img src="@/assets/inncash-logo-white.webp" width="104"></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <night-switch />

    <module-selector v-if="availableModules.length > 1"></module-selector>

    <user-profile-menu />
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import UserProfileMenu from '@/components/userProfile/UserProfileMenu.vue';
import NightSwitch from '@/components/night_switch/NightSwitch.vue';
import ModuleSelector from '@/components/modules/ModuleSelector.vue';

export default {
  name: 'Toolbar',
  data: () => ({
    buttons: {},
    user: {},
    avatar: null,
  }),
  components: {
    UserProfileMenu,
    NightSwitch,
    ModuleSelector,
  },
  computed: {
    ...mapState('authentication', ['availableModules']),
  },
};
</script>
