export default class TradingPolicy {
  constructor(
    public id: number,
    public delay: number,
    public maximumDiscount: number,

    public minimumInterest: number,
    public standardInterest: number,

    public minimumDeadline: number,
    public standardDeadline: number,
    public maximumDeadline: number,

    public periodicityInstallments: string,
    public amountInstallments: number,
    public differentInstallments: boolean,

    public type?: string,
    public userId?: number,
    public name?: string,
  ) {}

  static make(data: any): TradingPolicy {
    let type;
    if (data.user_id) type = 'operator';
    if (data.id_customer) type = 'customer';

    const getPeriodicityTextType = (periodicity: string) => {
      switch (periodicity) {
        case '1': return 'daily';
        case '2': return 'weekly';
        case '3': return 'fortnightly';
        case '4': return 'monthly';
        case '5': return 'custom';
        default: return 'daily';
      }
    };

    return new TradingPolicy(
      data.id ?? 0,
      data.delay ?? 0,
      data.minimum_discount ?? 0,

      data.minimum_interest ?? 0,
      data.standard_interest ?? 0,

      data.minimum_deadline ?? 0,
      data.standard_deadline ?? 0,
      data.maximum_deadline ?? 0,

      getPeriodicityTextType(data.periodicity_installments),
      data.amount_installments ?? 0,
      data.different_installments ?? false,

      type,
      data.user_id,
      data.name,
    );
  }
}
