import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"left":_vm.isLeft,"right":_vm.isRight,"top":_vm.isTop,"bottom":_vm.isBottom},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({class:(_vm.customButtonClass + " " + _vm.cssClass)},'v-btn',Object.assign({}, attrs, _vm.$attrs),false),Object.assign({}, on, _vm.$listeners)),[(_vm.buttonIcon)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.buttonIcon)+" ")]):(_vm.buttonText)?_c('span',[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])}
var staticRenderFns = []

export { render, staticRenderFns }