export default class NumberHelper {
  public static isNumeric(value: string|number|null|undefined): boolean {
    if (typeof value === 'undefined' || value === null) {
      return false;
    }

    if (typeof value === 'number') {
      return true;
    }

    return !Number.isNaN(value) && !Number.isNaN(parseFloat(value));
  }

  public static toStringFinancialNumberToFloatFormat(value: string): number {
    return parseFloat(
      value
        .replace('.', '')
        .replace(',', '.'),
    );
  }
}
