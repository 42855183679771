export default class FilterParameterDDAConciliationSearch {
  public constructor(
    public initial_due_date: string,
    public end_due_date: string,
    public initial_emission_date: string,
    public end_emission_date: string,
  ) {
  }

  public static make(data: any): FilterParameterDDAConciliationSearch {
    return new FilterParameterDDAConciliationSearch(
      data.initial_due_date_dda_conciliation_search ?? '',
      data.end_due_date_dda_conciliation_search ?? '',
      data.initial_emission_date_dda_conciliation_search ?? '',
      data.end_emission_date_dda_conciliation_search ?? '',
    );
  }
}
