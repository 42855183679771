















































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import BillingHistoryFilterParams from '@/components/timeline/types/BillingHistoryFilterParams';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import EventsRepository from '@/repositories/EventsRepository';
import BillingHistory from '@/domain/models/BillingHistory';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import FilterParameterHistoryTimeline from '@/domain/models/filter-parameters/FilterParameterHistoryTimeline';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import HistoryTimelineItem from '@/components/timeline/HistoryTimelineItem.vue';
import HistoryUpsertDialog from '@/components/timeline/HistoryUpsertDialog.vue';
import ConcludeEventDialog from '@/components/timeline/ConcludeEventDialog.vue';

@Component({
  components: {
    HistoryTimelineItem,
    HistoryUpsertDialog,
    ConcludeEventDialog,
  },
})
export default class HistoryTimeLine extends Vue {
  public loading: boolean = false;
  public callUpsertDialog: boolean = false;
  public callConcludeDialog: boolean = false;

  public filterChannel: string = '';
  public filterDate: string = '';
  public selectedEventAction: string = '';

  public selectedEventId: number|null = null;

  public readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();;
  public authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  public selectedEvent: BillingHistory|null = null;
  public events: BillingHistory[] = [];
  public channels: SelectOptions[] = [
    { value: 'whatsapp', text: 'Whatsapp' },
    { value: 'phone', text: 'Telefone' },
    { value: 'email', text: 'E-mail' },
    { value: 'sms', text: 'SMS' },
  ];

  public get idCustomer(): string {
    return this.$route.params.idCustomer;
  }

  public get companyGroupId(): string {
    return this.authenticationModule.user.company_group_id;
  }

  public async created(): Promise<void> {
    await this.getGroupFilterParameters();
    await this.getClientEvents();
  }

  public async getGroupFilterParameters(): Promise<void> {
    this.loading = true;

    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.BILLING_HISTORY);
      const formattedFilters = FilterParameterHistoryTimeline.make(filterParameters);

      this.applyFiltersOnActualPage(formattedFilters);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.loading = false;
    }
  }

  public applyFiltersOnActualPage(filters: FilterParameterHistoryTimeline): void {
    this.filterDate = filters.date;
    this.filterChannel = filters.channel;
  }

  public async getClientEvents(): Promise<void> {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const params: BillingHistoryFilterParams = this
      .setFilterParameters(this.filterChannel, this.filterDate);

    try {
      await this.filterParametersRepository.setFilter(GroupFilterParametersEnum.BILLING_HISTORY, [
        { key: 'date_billing_history', value: this.filterDate },
        { key: 'channel_billing_history', value: this.filterChannel },
      ]);

      this.events = await new EventsRepository()
        .getBillingHistoryEventsByCustomerId(this.idCustomer, this.companyGroupId, params);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as interações financeiras deste cliente!');
    } finally {
      this.loading = false;
    }
  }

  public setFilterParameters(channel: string, date: string): BillingHistoryFilterParams {
    return {
      channel: channel || '',
      date: date || '',
    };
  }

  public getEventById(idToFind: number): BillingHistory | null {
    const selectedEvent = this.events.find(({ id }) => id === idToFind);

    if (typeof selectedEvent === 'undefined') {
      return null;
    }

    return selectedEvent;
  }

  public handleCreate(): void {
    this.callUpsertDialog = true;
  }

  public handleEdit(id: number): void {
    this.selectedEvent = this.getEventById(id);
    this.callUpsertDialog = true;
  }

  public showConcludeDialog(id: number, action: string): void {
    this.selectedEventId = id;
    this.selectedEventAction = action;
    this.callConcludeDialog = true;
  }

  public reset(resetList: boolean): void {
    this.selectedEvent = null;

    if (resetList) {
      this.getClientEvents();
    }
  }
}
