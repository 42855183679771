




































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import { formatErrorForNotification } from '@/utils/error';
import RemittanceRepository from '@/repositories/RemittanceRepository';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import AccountType from '@/domain/enums/AccountTypeEnum';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionRemove extends Vue {
  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Emit()
  close(reload = false): CloseActionData {
    return { type: ActionType.REMOVE, reload };
  }

  readonly remittanceRepository: RemittanceRepository = new RemittanceRepository();

  save(): void {
    this.handleRemoveRemittance();
  }

  async handleRemoveRemittance(): Promise<void> {
    if (!this.item.cnab_remi_generated_id) {
      this.$notification.warn('Título em borderô pelo ERP, não pode ser removido pelo inncash.');
      return;
    }

    try {
      this.$dialog.startLoading();

      const companyGroupId = this.$session.get('company_group_id');

      const {
        origin,
        cnab_remi_generated_id: cnabRemiGeneratedId,
      } = this.item;

      const type = origin.trim() === 'ERP'
        ? AccountType.PAYABLE
        : AccountType.INVOICE;

      const idCustomer = type === AccountType.PAYABLE
        ? this.item.id_customer
        : `${this.item.id}`;

      await this.remittanceRepository.excludeFromRemittance(
        companyGroupId,
        cnabRemiGeneratedId,
        idCustomer,
        type,
      );

      this.$notification.success('Título removido do borderô com sucesso!');
      this.close(true);
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }
}
