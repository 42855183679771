
















































import Component, { mixins } from 'vue-class-component';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import Permission from '@/domain/models/Permission';
import PermissionCategory from '@/domain/models/PermissionCategory';
import PermissionItemsList from './PermissionItemsList.vue';

interface IGroupPermissions {
  category: PermissionCategory;
  permissions: Permission[];
}

@Component({
  components: {
    PermissionItemsList,
  },
})
export default class ActionPermissionsTab extends mixins<ModelManagementMixin<Permission[]>>(
  ModelManagementMixin,
) {
  public considerAllActions: boolean = false;

  get groupedPermissions(): IGroupPermissions[] {
    const initial: IGroupPermissions[] = [];
    return this.model.reduce((acc, permission) => {
      const index: number = acc.findIndex(
        (previous) => previous.category.id == permission.category!.id,
      );
      if (index >= 0) {
        acc[index].permissions = acc[index].permissions.concat(permission);
        return acc;
      }
      return acc.concat({
        category: permission.category!,
        permissions: [permission],
      });
    }, initial);
  }

  public get getTextTitleColor() {
    if (this.$vuetify.theme.dark) {
      return 'white--text';
    }

    return 'grey--text text--darken-4';
  }

  public handleConsiderAllActions(isActive: boolean): void {
    this.model.forEach((permission) => {
      permission.isActive = isActive;
    });
  }
}
