import Bank from '@/domain/models/Bank';
import Company from '@/domain/models/Company';
import Supplier from '@/domain/models/Supplier';
import BankConfig from '@/domain/models/BankConfig';
import BankAccount from '@/domain/models/BankAccount';
import PaymentMethod from '@/domain/models/PaymentMethod';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import ArtificialInvoice from '@/domain/models/ArtificialInvoice';
import Details from '@/domain/models/PaymentRemittanceDetails';
import Summary from '@/domain/models/PaymentRemittancesSummary';
import GeneratePaymentRemittanceResponse from '@/domain/models/GeneratePaymentRemittanceResponse';

import IOptions from '@/domain/interfaces/ISelectOptions';
import IFilter from '@/domain/interfaces/IPaymentRemittancesFilter';
import IGenerate from '@/domain/interfaces/IPaymentRemittancesGenerate';
import IInvoice from '@/domain/interfaces/IPaymentRemittancesInvoice';
import ISummary from '@/domain/interfaces/IPaymentRemittancesSummary';

import Repository from './base/Repository';

export default class PaymentRemittanceRepository extends Repository {
  async getCompanies(group: number | string): Promise<Array<IOptions<number>>> {
    const { status, data: response } = await this.datasource.get(`company-groups/${group}/companies`);

    if (status >= 200 && status < 300) {
      const companies: Array<Company> = response.data.map(Company.make);

      const selectOptions = companies.map((company) => {
        const { id, name } = company;
        return { value: id, text: name };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das filiais do grupo.');
  }

  async getMethods(group: number | string, companies: Array<number>)
    : Promise<Array<IOptions<string>>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${group}/payment-methods`,
      {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
      },
    );

    if (status >= 200 && status < 300) {
      const methods: Array<PaymentMethod> = response.data.map(PaymentMethod.make);

      const selectOptions = methods.map((method) => {
        const { code, description } = method;
        return { value: code, text: description };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das formas de pagamento.');
  }

  async getBanks(group: number | string): Promise<Array<IOptions<string>>> {
    const { status, data: response } = await this.datasource.get(`bank/listAll/${group}`);

    if (status >= 200 && status < 300) {
      if (response.error) throw new Error(`Portador/Banco: ${response.message}`);

      const banks: Array<Bank> = response.data.map(Bank.make);

      const selectOptions = banks.map((bank) => {
        const { code, name } = bank;
        return { value: code, text: `${code} - ${name}` };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos bancos.');
  }

  async getPrefixes(companyGroupId: number | string, companyIds: Array<number>)
    : Promise<Array<IOptions<string>>> {
    const { status, data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/remittances/accounts-payable/prefixes`,
      {
        headers: {
          'company-ids': JSON.stringify(companyIds),
        },
      },
    );

    if (status.toString().startsWith('2')) {
      return (response.data as Array<string>).map((prefix) => ({ value: prefix, text: prefix }));
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem de prefixo.');
  }

  async getBankAccounts(
    group: number | string,
    companies: Array<number>,
    search: string,
  ): Promise<Array<IOptions<string>>> {
    const { status, data: response } = await this.datasource.get(`/company-groups/${group}/banks/search/like`,
      {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
        params: {
          limit: 25,
          search,
        },
      });

    if (status >= 200 && status < 300) {
      const banks: Array<BankAccount> = response.data.map(BankAccount.make);

      const selectOptions = banks.map((bank) => {
        const {
          id,
          code,
          agency,
          account,
          name,
        } = bank;

        return { value: id, text: `${code} ${agency} ${account} - ${name}` };
      });

      return selectOptions;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das contas bancárias.');
  }

  async getSummaries(
    group: number | string,
    companies: Array<number>,
    params: ISummary,
  ): Promise<Array<Summary>> {
    const { status, data: response } = await this.datasource
      .get(`/company-groups/${group}/remittances/accounts-payable/summaries`,
        {
          headers: {
            'company-ids': JSON.stringify(companies),
          },
          params,
        });

    if (status >= 200 && status < 300) {
      const data = response.data.map(Summary.make);

      return data;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem do resumo bancário.');
  }

  async getList(group: number | string, companies: Array<number>, params: IFilter)
    : Promise<Record<string, Array<PaymentRemittance>>> {
    const { status, data: response } = await this.datasource.get(`/company-groups/${group}/remittances/accounts-payable`,
      {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
        params,
      });

    if (status >= 200 && status < 300) {
      if (!params.group_by.length) {
        return {
          Títulos: response.data.map(PaymentRemittance.make),
        };
      }

      const data = Object.keys(response.data)
        .reduce((acc, key) => ({
          ...acc,
          [key]: response.data[key].map(PaymentRemittance.make),
        }), {} as Record<string, Array<PaymentRemittance>>);

      return data;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das remessas de pagamento.');
  }

  async getGroupConfig(group: number): Promise<CompanyGroupConfig> {
    const { status, data: response } = await this.datasource.get(`companygroup/listOne/${group}`);

    if (status >= 200 && status < 300) {
      return CompanyGroupConfig
        .make(response.data[0]);
    }
    throw new Error('Ops, algo inesperado aconteceu na obtenção das configurações do grupo.');
  }

  async getSuppliers(
    group: number,
    companies: Array<number>,
    search: string,
    isAllAccountsNcc: boolean,
  )
    : Promise<Array<IOptions<string>>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/suppliers/search/like`, {
        headers: {
          'company-ids': JSON.stringify(companies || []),
        },
        params: {
          limit: 15,
          search,
          only_customers: Number(isAllAccountsNcc),
          only_suppliers: Number(!isAllAccountsNcc),
        },
      });

    if (status >= 200 && status < 300) {
      if (response.error) throw new Error(`Fornecedores: ${response.message}`);

      return response.data.map((item: any) => {
        const supplier = Supplier.make(item);
        return { value: supplier.id, text: `${supplier.code} - ${supplier.name} - ${supplier.document}` };
      });
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos fornecedores.');
  }

  async getAllSuppliers(
    group: number,
    companies: Array<number>,
    search: string,
  )
    : Promise<Array<IOptions<string>>> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/suppliers/search/like`, {
        headers: {
          'company-ids': JSON.stringify(companies || []),
        },
        params: {
          limit: 15,
          search,
          only_customers: 1,
          only_suppliers: 1,
        },
      });

    if (status >= 200 && status < 300) {
      if (response.error) throw new Error(`Fornecedores: ${response.message}`);

      return response.data.map((item: any) => {
        const supplier = Supplier.make(item);
        return { value: supplier.id, text: `${supplier.code} - ${supplier.name} - ${supplier.document}` };
      });
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem dos fornecedores.');
  }

  async getAllBanksConfig(companies: Array<number>): Promise<Array<BankConfig>> {
    const { status, data: response } = await this.datasource.get('bankconfig/listAllBanks/', {
      headers: {
        'company-ids': JSON.stringify(companies),
      },
    });

    if (status >= 200 && status < 300) {
      if (response.error) throw new Error(`Configurações bancárias: ${response.message}`);
      if (response.total_records == 0) throw new Error('Nenhuma configuração bancária encontrada.');

      const data = response.data.map(BankConfig.make);

      const banksConfig = data.filter(
        (bankConfig: BankConfig) => bankConfig.tipo == 'PAGAR' && bankConfig.send_types.length > 0,
      );

      return banksConfig;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem das configurações bancárias.');
  }

  async generate(group: number, companies: Array<number>, params: IGenerate)
    : Promise<GeneratePaymentRemittanceResponse> {
    const { status, data: response } = await this.datasource
      .send(`/company-groups/${group}/remittances/accounts-payable`, params, {
        headers: {
          'company-ids': JSON.stringify(companies),
        },
      });

    if (status >= 200 && status < 300) {
      return GeneratePaymentRemittanceResponse.make(response.data);
    }

    if (status === 409 || status === 400) {
      throw new Error(response.message);
    }

    throw new Error('Ops, algo inesperado aconteceu na geração da remessa.');
  }

  async approvals(group: number, remi: number, companies: Array<number>, titles: Array<string>)
    : Promise<number> {
    const { status, data: response } = await this.datasource
      .send(`/company-groups/${group}/remittances/${remi}/accounts-payable/approvals`, {
        accounts_payable: titles,
      },
        {
          headers: {
            'company-ids': JSON.stringify(companies),
          },
        });

    if (status >= 200 && status < 300) {
      return remi;
    }
    throw new Error('Ops, algo inesperado aconteceu na aprovação da remessa.');
  }

  async disapprovals(
    group: number,
    remi: number,
    companies: Array<number>,
    titles: Array<string>,
    comment?: string,
  ): Promise<number> {
    const { status, data: response } = await this.datasource
      .send(`/company-groups/${group}/remittances/${remi}/accounts-payable/disapprovals`, {
        accounts_payable: titles,
        comment,
      },
        {
          headers: {
            'company-ids': JSON.stringify(companies),
          },
        });

    if (status >= 200 && status < 300) {
      return remi;
    }
    throw new Error('Ops, algo inesperado aconteceu na reprovação da remessa.');
  }

  async invoice(group: number, company: number, params: IInvoice): Promise<boolean> {
    const { status, data: response } = await this.datasource
      .send(
        `/company-groups/${group}/remittances/accounts-payable/invoices`,
        params,
        {
          headers: {
            'company-ids': JSON.stringify([company]),
          },
        },
      );

    if (status >= 200 && status < 300) {
      return true;
    }
    if (status === 400) {
      throw new Error(response.message);
    }

    throw new Error('Ops, algo inesperado aconteceu na geração da fatura.');
  }

  async getInvoice(groupId: number, company: number, id: string)
    : Promise<ArtificialInvoice> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${groupId}/direct-debits/conciliations/invoices/${id}`, {
        headers: { 'company-id': company },
      });

    if (status == 200) {
      const artificialInvoice = ArtificialInvoice.make(response.data);
      return artificialInvoice;
    }
    throw new Error('Ops, algo inesperado aconteceu na listagem da fatura.');
  }

  async removeInvoice(group: number, company: number, idCustomer: string): Promise<boolean> {
    const { status, data: response } = await this.datasource
      .destroy(
        `/company-groups/${group}/remittances/accounts-payable/invoices/${idCustomer}`,
        {
          headers: {
            'company-ids': JSON.stringify([company]),
          },
        },
      );

    if (status >= 200 && status < 300) {
      return true;
    }

    if (status == 409) {
      throw new Error(response?.data?.message ?? 'Fatura em remessa, remova a remessa primeiro.');
    }

    throw new Error(response?.data?.message ?? 'Ops, algo inesperado aconteceu na remoção da fatura.');
  }

  async updateInvoice(params: object): Promise<any> {
    const { status, data: response } = await this.datasource
      .patch('artificial-invoices', params);

    if (status >= 200 && status < 300) {
      return response;
    }
    throw new Error('Ops, algo inesperado aconteceu na alteração dos dados da fatura.');
  }

  async updateERP(group: number, company: number, params: object): Promise<any> {
    const { status, data: response } = await this.datasource
      .patch(`company-groups/${group}/erps`, params, {
        headers: {
          'company-id': company,
        },
      });

    if (status >= 200 && status < 300) {
      return response;
    }
    throw new Error('Ops, algo inesperado aconteceu na alteração dos dados do título.');
  }

  async updateInternal(type: 'updateImportTable' | 'savedata', params: object): Promise<any> {
    const { status, data: response } = await this.datasource
      .send(`internaltable/${type}`, params);

    if (status >= 200 && status < 300) {
      return response;
    }
    throw new Error('Ops, algo inesperado aconteceu na atualização interna.');
  }

  async comment(group: number, company: number, id: string, type: null | 'invoices', comment: string)
    : Promise<boolean> {
    const { status, data: response } = await this.datasource
      .send(`company-groups/${group}/remittances/accounts-payable/${type ? 'invoices/' : ''}${id}/comments`, {
        comment,
      }, {
        headers: {
          'company-id': company,
        },
      });

    if (status >= 200 && status < 300) {
      return true;
    }
    throw new Error('Ops, algo inesperado aconteceu na criação do comentário.');
  }

  async getDetails(group: number, company: number, type: null | 'invoices', id: string)
    : Promise<Details> {
    const { status, data: response } = await this.datasource
      .get(`company-groups/${group}/remittances/accounts-payable/${type ? 'invoices/' : ''}${id}`, {
        headers: {
          'company-id': company,
        },
      });

    if (status >= 200 && status < 300) {
      const details = Details.make(response.data);

      return details;
    }
    throw new Error('Ops, algo inesperado aconteceu na obtenção dos detalhes.');
  }

  async changeInvoice(group: number, company: number, id: string, record: unknown)
    : Promise<boolean> {
    const { status } = await this.datasource.update(`company-groups/${group}/remittances/accounts-payable/invoices/${id}`, record, {
      headers: {
        'company-id': company,
      },
    });

    if (status >= 200 && status < 300) {
      return true;
    }
    throw new Error('Ops, algo inesperado aconteceu na criação do comentário.');
  }
}
