export default class FinancialStatementChart {
  constructor(
    public totalExpired: number,
    public totalToExpire: number,
    public totalPaidOut: number,
    public averageMaturity: number,
    public averageDelay: number,
  ) {}

  static make(data: any): FinancialStatementChart {
      return new FinancialStatementChart(
        data.total_expired,
        data.total_to_expire,
        data.total_paid_out,
        data.average_maturity,
        data.average_delay,
      );
  }
}
