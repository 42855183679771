export default class SearchCustomer {
  constructor(
    public code: string,
    public name: string,
    public store: string,
  ) {}

  static make(data: any): SearchCustomer {
    return new SearchCustomer(
      data.code,
      data.name,
      data.store,
    );
  }
}
