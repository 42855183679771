import Repository from '@/repositories/base/Repository';
import BillingHistoryFilterParams from '@/components/timeline/types/BillingHistoryFilterParams';
import UpsertBillingHistoryParams from '@/components/timeline/types/UpsertBillingHistoryParams';
import IConcludeInteractionParams from '@/components/timeline/interfaces/IConcludeInteractionParams';
import BillingHistory from '@/domain/models/BillingHistory';
import RangeDate from '@/types/RangeDate';

export default class EventsRepository extends Repository {
  public async getBillingHistoryEventsByCustomerId(
    customerId: string,
    moduleId: string,
    params: BillingHistoryFilterParams,
  ): Promise<BillingHistory[]> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/customers/billing-history/${customerId}`, {
        params,
    });

    if (!this.isStatusOK(status)) {
        throw new Error('Aconteceu um erro inesperado');
    }

    return response.data.map(BillingHistory.make);
  }

  public async getAllBillingHistoryEvents(
    moduleId: string,
    companyIds: number[],
    params: RangeDate,
  ): Promise<BillingHistory[]> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/customers/billing-history/`, {
      params,
      headers: {
        'Company-Ids': JSON.stringify(companyIds),
      },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return response.data.map(BillingHistory.make);
  }

  public async insertEvent(
    moduleId: string,
    companyId: number,
    register: UpsertBillingHistoryParams,
  ): Promise<boolean> {
    const { status, data: response } = await this.datasource.send(`company-groups/${moduleId}/customers/billing-history/`, register, {
      headers: { 'company-id': companyId },
    });

     if (status == 200) {
      if (!response.error) {
        return true;
      }

      throw new Error(response.message);
    }

    throw new Error('Aconteceu algum erro inesperado');
  }

  public async updateEvent(
    moduleId: string,
    register: UpsertBillingHistoryParams,
  ): Promise<boolean> {
    const { status, data: response } = await this.datasource.update(`company-groups/${moduleId}/customers/billing-history/${register.id}`, register);

     if (status == 200) {
      if (!response.error) {
        return true;
      }

      throw new Error(response.message);
    }
    throw new Error('Aconteceu algum erro inesperado');
  }

  public async concludeEvent(
    companyGroupId: number,
    id: number,
    params: IConcludeInteractionParams,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroupId}/customers/billing-history/conclude/${id}`, params);
  }
}
