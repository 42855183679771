<template>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                </div>
            </div>
            <div class="m-portlet__head-tools">
                <router-link :to="{ name: 'companygroup' }" class="m-btn btn btn-warning mr-3">Cancelar</router-link>
                <button id="btn-save" type="button" class="m-btn btn btn-success">Salvar</button>
            </div>
        </div>
        <div class="m-portlet__body">
            <!--begin: Search Form -->
            <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">

                <ul class="nav nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--primary" role="tablist">
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link active" data-toggle="tab" href="#tab1" role="tab"
                           aria-expanded="true">
                            Cadastro
                        </a>
                    </li>
                    <li class="nav-item m-tabs__item">
                        <a class="nav-link m-tabs__link" data-toggle="tab" href="#tab2" role="tab"
                           aria-expanded="false">
                            Filiais
                        </a>
                    </li>
                </ul>


                <div class="tab-content" id="frmCompany">
                    <div class="tab-pane active" id="tab1" role="tabpanel">
                        <div class="row" id="frmCompanyGroup">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Descrição</label>
                                    <input type="text" class="form-control" field-name="Descrição" id="description"
                                           name="description" required>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Prefixo</label>
                                    <input type="text" class="form-control" id="prefix_table" name="prefix_table"
                                            disabled value="ICASH_">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tabela de importação</label>
                                    <input type="text" class="form-control" field-name="Tabela de importação"
                                            id="name_table" name="name_table">
                                </div>
                            </div>                           
                            <div class="col-md-3">
								<div class="form-group">
									<label>Utiliza dígito agência</label>
									<select class="form-control select2 hide" field-name="Dígito agência" 
                                            id="a2_zdigag" name="a2_zdigag" style="width: 100%;" required>										
										<option value="1">Sim</option>
                                        <option value="0">Não</option>										
									</select>
                                </div>
                            </div>
                            <div class="col-md-3 digag">
                                <div class="form-group">
                                    <label>Dígito agência</label>
                                    <input type="text" class="form-control hide" field-name="Dígito agência" 
                                            id="digag" name="digag"/>
                                </div>
                            </div>
                            <div class="col-md-3">
								<div class="form-group">
									<label>Utiliza dígito conta</label>
									<select class="form-control select2 hide" field-name="Dígito conta" 
                                            id="a2_zdigcta" name="a2_zdigcta" style="width: 100%;" required>									
										<option value="1">Sim</option>
                                        <option value="0">Não</option>										
									</select>
                                </div>
                            </div>
                            <div class="col-md-3 digcta">
                                <div class="form-group">
                                    <label>Dígito da conta</label>
                                    <input type="text" class="form-control hide" field-name="Dígito conta" 
                                            id="digcta" name="digcta"/>
                                </div>
                            </div>
                            <div class="col-md-3">
								<div class="form-group">
									<label>Responsável pela cobrança</label>
									<select class="form-control select2 hide" field-name="Responsável pela cobrança" 
                                            id="invoice_generator" name="invoice_generator" style="width: 100%;" required>									
										<option value="BANK">Banco</option>
                                        <option value="SELF">Cliente</option>									
									</select>
                                </div>
                            </div>
                            <div class="col-md-3">
								<div class="form-group">
									<label>Aprovação para cobrança?</label>
									<select class="form-control select2 hide" field-name="Aprovação para cobrança?" 
                                            id="billing_approval" name="billing_approval" style="width: 100%;" required>									
										<option value="Y">Sim</option>
                                        <option value="N">Não</option>									
									</select>
                                </div>
                            </div>
                            <div class="col-md-3">
								<div class="form-group">
									<label>Limite de remessas</label>
									<input type="text" class="form-control hide" field-name="Limite de remessas" 
                                            id="remittance_limit" name="remittance_limit"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Imagem</label>
                                    <div></div>
                                    <label class="custom-file">
                                        <input type="file" class="custom-file-input" field-name="Imagem" id="image"
                                               name="image">
                                        <span class="custom-file-control"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="tab-pane" id="tab2" role="tabpanel">
                        <div class="row">
                            <form novalidate data-vv-scope="formBranch" @submit.prevent="addBranch"
                                  class="row">
                                <div class="col-md-3">
                                    <div :class="['form-group',errors.has('formBranch.code_field') ? 'has-danger' :'']">
                                        <label>Código Filial</label>
                                        <input v-model="branch.code" type="text"
                                               data-vv-as="'Código Filial'"
                                               v-validate="'required'"
                                               name="code_field"
                                               :class="['form-control']">
                                        <label v-show="errors.has('formBranch.code_field')">{{
                                            errors.first('formBranch.code_field') }}</label>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div :class="['form-group',errors.has('formBranch.description_field') ? 'has-danger' :'']">
                                        <label>Descrição Filial</label>
                                        <input v-model="branch.description" type="text"
                                               data-vv-as="'Descrição Filial'"
                                               v-validate="'required'"
                                               name="description_field"
                                               :class="['form-control']">
                                        <label v-show="errors.has('formBranch.description_field')">{{
                                            errors.first('formBranch.description_field') }}</label>
                                    </div>
                                </div>
                                <div class="col-md-1" style="padding-top: 25px;text-align: right;">
                                    <button class="btn m-btn--pill btn-success"><i
                                            class="la la-plus"></i></button>

                                </div>
                            </form>
                        </div>

                        <table class="table table-striped m-table">
                            <thead>
                            <tr>
                                <th>Código Filial</th>
                                <th>Descrição Filial</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(branch, index) in branches">
                                <td style="vertical-align: middle;">{{ branch.code }}</td>
                                <td style="vertical-align: middle;">{{ branch.description }}</td>
                                <td class="m--align-right">
                                    <a @click="removeBranch(branch)"
                                       class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill">
                                        <i class="la la-trash"></i>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>


                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { API_BASE } from '@config/api'
    
    export default {
        data() {
            return {
                imageLoaded: "",
                validationContent: {
                    required: true,
                    regex: /^[^"]+$/,
                },
                branches: [],
                branch: {}
            }
        },

        mounted: function () {
            this.loadMethod();
        },

        methods: {
            loadMethod: function () {
                var self = this;

                $(".select2").select2();
                $("#cnpj").mask("00.000.000/0000-00");
                $("#phone").mask("(00) 00000-0000");
                $("#zipcode").mask("0000-000");

                $("#btn-save").click(function () {
                    var arrayData = [], exitButton = true, fieldName = '', i = 0, post = '';

                    $.each($('input, select', '#frmCompanyGroup'), function () {
                        var $this = $(this);

                        if ($.trim(this.value) == '') {
                            exitButton = true;
                            fieldName = $this.attr('field-name');

                            $(".form-group:has(#" + this.id + ")").addClass('has-danger');
                            $("#" + this.id).addClass('input-error');
                            $("#" + this.id).css('border-color', 'red');
                            return false;
                        } else {
                            exitButton = false;
                            arrayData[i] = {};
                            arrayData[i][this.id] = this.value;
                        }

                        i++;
                    });

                    if (exitButton) {
                        showNotification('Atenção', 'O campo <strong>' + fieldName + '</strong> não foi informado!', 'warning');
                        return false;
                    }

                    if (self.imageLoaded != "") {
                        arrayData[arrayData.length] = {'image_company': self.imageLoaded};
                    }

                    arrayData[arrayData.length] = {branches: self.branches};

                    blockPage('Salvando dados...');
                    $.ajax({
                        method: "POST",
                        url: API_BASE + 'companygroup/new',
                        headers: {
                            'token': self.$session.get('token')
                        },
                        data: {
                            arrayData: JSON.stringify(arrayData)
                        }
                    }).done(function (result) {
                        if (result.error == false) {
                            showNotification('Sucesso', 'Novo grupo de empresa registrado!', 'success');

                            $.each($('input', '#frmCompanyGroup'), function () {
                                this.value = '';
                            });
                            self.$router.replace({name: 'CompanyGroups'});
                        } else {
                            showNotification('Atenção', result.message, 'danger');
                            return false;
                        }
                    }).fail(function (error) {
                        console.log(error.responseText);
                    }).always(function () {
                        unblockPage();
                    });
                });

                $("#a2_zdigag").val() == 1 ? $(".digag").show("fast")
                                           : $(".digag").hide("fast")
                $("#a2_zdigag").on('change', (sel) => {
                    $(".digag").toggle("slow")
                })

                $("#a2_zdigcta").val() == 1 ? $(".digcta").show("fast")
                                            : $(".digcta").hide("fast")
                $("#a2_zdigcta").on('change', (sel) => {
                    $(".digcta").toggle("slow")
                })
                
                $('input', '#frmCompanyGroup').keyup(function () {
                    if ($("#" + this.id).hasClass('input-error')) {
                        $("#" + this.id).removeClass('input-error');
                        $("#" + this.id).css('border-color', '#ebedf2');
                        $(".form-group:has(#" + this.id + ")").removeClass('has-danger');
                    }
                });
                $("#image").on("change", function () {
                    self.loadImage();
                    let fileName = $(this).val().split('\\').pop();
                    $(this).next('.custom-file-control').addClass("selected").html(fileName);
                })
            },
            loadImage: function () {
                let self = this;
                let fileContent = $('#image')[0];
                if (fileContent.files.length > 0) {
                    let file = fileContent.files[0];
                    let fr = new FileReader();
                    fr.readAsDataURL(file);
                    fr.onload = function (e) {
                        self.imageLoaded = e.target.result;
                    };
                } else {
                    self.imageLoaded = "";
                }
            },
            addBranch() {
                let self = this;
                self.$validator.validateAll('formBranch').then((result) => {
                    if (result) {
                        self.branches.push(self.branch);
                        self.branch = {};
                    }
                });
            },
            removeBranch(branch) {
                let self = this;
                self.branches.splice(self.branches.indexOf(branch), 1);
            }


        }
    }
</script>
