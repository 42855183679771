






















































import Component, { mixins } from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Route } from 'vue-router';
import UserGroupModule from '@/stores/modules/UserGroupModule';
import StartLoadingMixin from '@/mixins/ts/StartLoadingMixin';
import Permission from '@/domain/models/Permission';
import PermissionType from '@/domain/enums/PermissionType';
import UserGroupPermissionRepository from '@/repositories/UserGroupPermissionRepository';
import IPermissionSaveParams from '@/repositories/parameters/IPermissionSaveParams';
import ActionPermissionsTab from './components/ActionPermissionsTab.vue';
import MenuPermissionsTab from './components/MenuPermissionsTab.vue';

@Component({
  components: {
    MenuPermissionsTab,
    ActionPermissionsTab,
  },
})
export default class UserGroupModules extends mixins(StartLoadingMixin) {
  moduleName: String = '';
  userGroupName: String = '';
  userGroupStore?: UserGroupModule;
  menuPermissions: Permission[] = [];
  actionPermissions: Permission[] = [];
  permissionParameters: IPermissionSaveParams = {
    permission_ids: [],
  };
  readonly tabs: string[] = ['Menus', 'Permissões'];
  readonly userGroupPermissionRepository = new UserGroupPermissionRepository();
  get groupId(): number {
    return Number(this.$route.params.groupId);
  }

  get moduleId(): number {
    return Number(this.$route.params.moduleId);
  }

  created() {
    this.userGroupStore = getModule(UserGroupModule, this.$store);
    this.userGroupName = this.userGroupStore.userGroupName;
    this.moduleName = this.userGroupStore.moduleName;

    this.$dialog.startLoading();
    this.userGroupPermissionRepository
      .getAll(this.groupId, this.moduleId)
      .then((permissions) => {
        this.menuPermissions = permissions.filter(
          (permission) => permission.type == PermissionType.MENU,
        );
        this.actionPermissions = permissions.filter(
          (permission) => permission.type == PermissionType.ACTION,
        );
      })
      .catch(this.$notification.error)
      .finally(() => this.$dialog.stopLoading());
  }

  handleSave() {
    const parameters: IPermissionSaveParams = {
      permission_ids: [
        ...this.menuPermissions.filter(this.isPermissionActive).map((p) => p.id),
        ...this.actionPermissions.filter(this.isPermissionActive).map((p) => p.id),
      ],
    };

    this.$dialog.startLoading();
    this.userGroupPermissionRepository
      .save(parameters, this.groupId, this.moduleId)
      .then((result) => {
        if (result) {
          this.$notification.success('Permissões salvas com sucesso!');
        } else {
          this.$notification.success('Aconteceu um erro ao salvar as permissões, tente novamente.');
        }

        this.$router.replace(`/grupos-de-usuario/${this.groupId}`);
      })
      .catch(this.$notification.error)
      .finally(() => this.$dialog.stopLoading());
  }

  isPermissionActive(permission: Permission) {
    return permission.isActive ?? false;
  }

  handleCancel() {
    this.$router.replace(`/grupos-de-usuario/${this.groupId}`);
  }

  beforeRouteLeave(to: Route, from: Route, next: Function) {
    this.userGroupStore!.clear();
    next();
  }
}
