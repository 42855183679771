export default class CreditRuleList {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public active: 'active' | 'inactive',
    public ruleIndex: number,
    public ruleAuthorization: string,
  ) {}

  public static make(data: any): CreditRuleList {
    return new CreditRuleList(
      data.id,
      data.name,
      data.description,
      data.active,
      data.ruleIndex,
      data.ruleAuthorization,
    );
  }
}
