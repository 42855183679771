import qs from 'qs';
import UserGroup from '@/domain/models/UserGroup';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import Repository from '@/repositories/base/Repository';
import IUserGroupCreateParams from '@/repositories/parameters/IUserGroupCreateParams';
import IUserGroupUpdateParams from '@/repositories/parameters/IUserGroupUpdateParams';
import ILaravelOrdenationTableParams from './parameters/ILaravelOrdenationTableParams';

export default class UserGroupRepository extends Repository {
  async getAll(
    dataTableConfig?: ILaravelOrdenationTableParams,
  ) : Promise<DataWithPaginationInfo<UserGroup[]>> {
    const { status, data: response } = await this.datasource.get('/user-groups', {
      params: dataTableConfig,
      paramsSerializer: qs.stringify,
    });

    if (!this.isStatusOK(status)) {
      throw new Error(response.message ?? 'Aconteceu um erro inesperado');
    }

    return {
      data: response.data.map(UserGroup.make),
      meta: response.meta,
    };
  }

  async get(id : number) : Promise<UserGroup> {
    const { status, data: response } = await this.datasource.get(`/user-groups/${id}`);
    if (status == 200) {
      return UserGroup.make(response.data);
    }
    if (status == 401) {
      throw new Error('Você não tem permissão para essa ação');
    }
    throw new Error(response.message ?? 'Aconteceu um erro inesperado');
  }

  async save(record : IUserGroupCreateParams) : Promise<UserGroup> {
    const { status, data: response } = await this.datasource.send('/user-groups', record);
    if (status == 201) {
      return UserGroup.make(response.data);
    }
    throw new Error(response.message ?? 'Aconteceu um erro inesperado');
  }

  async update(record : IUserGroupUpdateParams) : Promise<UserGroup> {
    const { status, data: response } = await this.datasource
      .update(`/user-groups/${record.id}`, record);
    if (status == 200) {
      return UserGroup.make(response.data);
    }
    if (status == 401) {
      throw new Error('Você não tem permissão para essa ação');
    }
    throw new Error(response.message ?? 'Aconteceu um erro inesperado');
  }

  async delete(recordId : number) : Promise<String> {
    const { status, data: response } = await this.datasource.destroy(`/user-groups/${recordId}`);
    if (status == 200) {
      return response.message;
    }
    if (status == 401) {
      throw new Error('Você não tem permissão para essa ação');
    }
    throw new Error('Aconteceu um erro inesperado');
  }
}
