




































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import IIconInformation from '@/views/login/interfaces/IIconInformation';

@Component
export default class LoginInformation extends Vue {
  icons: IIconInformation[] = [
    {
      name: 'mdi-web',
      link: 'https://inn.cash/',
    },
    {
      name: 'mdi-instagram',
      link: 'https://www.instagram.com/inn.cash/',
    },
    {
      name: 'mdi-linkedin',
      link: 'https://www.linkedin.com/company/inncash/',
    },
    {
      name: 'mdi-youtube',
      link: 'https://www.youtube.com/@inncash8003/featured',
    },
    {
      name: 'mdi-whatsapp',
      link: 'https://api.whatsapp.com/send?phone=5548988334371&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Innova%20Connect',
    },
  ];

  redirectTo(url: string): void {
    window.open(url);
  }
}
