import Menu from '@/domain/models/Menu';
import Repository from './base/Repository';

export default class MenuRepository extends Repository {
  async getByModuleId(moduleId : number) : Promise<Menu[]> {
    const { status, data: response } = await this.datasource.get(`/modules/${moduleId}/menus`);
    if (this.isStatusOK(status)) {
      const menus = response.data.map(Menu.make);
      return this.createMenuTree(menus);
    }
    throw new Error('Aconteceu um erro inesperado');
  }

  private createMenuTree(menus: Menu[]): any[] {
    return menus.map((menu: Menu) => {
      if (!menu.parent) {
        menu.children = menus.filter((childMenu) => childMenu?.parent?.id == menu.id);
      }

      return menu;
    }).filter((menu) => !menu.parent);
  }
}
