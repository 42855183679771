import PaymentMethodType from '../enums/PaymentMethodType';
import PaymentRemittancesStatusType from '../enums/PaymentRemittancesStatusType';
import Company from './Company';

export default class PaymentRemittance {
  constructor(
    public account_code: string,
    public account_payable_supplier_document: string,
    public actual_expiration_date: string,
    public agency_code: string,
    public bank_code: string,
    public bank_number: string,
    public barcode: string,
    public bordero_number: string | null,
    public branch: string,
    public cnab_remi_generated_id: number | null,
    public code: string,
    public coin: string,
    public comment: string,
    public company: Company,
    public company_id: number,
    public dda_record: string | null,
    public decrease: number,
    public due_date: string,
    public exchange_rate: string | null,
    public fees: number,
    public form_of_payment: string,
    public form_of_payment_description: string,
    public form_of_payment_type: PaymentMethodType,
    public id: number,
    public id_customer: string,
    public increase: number,
    public number: string,
    public origin: string,
    public parcel: string,
    public period: string,
    public number_reference: string,
    public ported: string,
    public prefix: string,
    public released_remittance_payble: string | null,
    public status: PaymentRemittancesStatusType,
    public supplier_account_digit: string,
    public supplier_account_number: string,
    public supplier_account_type: string,
    public supplier_agency: string,
    public supplier_agency_digit: string,
    public supplier_bank: string,
    public supplier_branch: string,
    public supplier_code_number: string,
    public supplier_company_id: number,
    public supplier_document: string,
    public supplier_fantasy_name: string,
    public supplier_id: number,
    public supplier_id_customer: string,
    public supplier_name: string,
    public supplier_store_number: string,
    public supplier_type: string,
    public supplier_z_document: string,
    public supplier_z_name: string,
    public table_company_code: string,
    public total: number,
    public inncash_value: number,
    public total_amended: number,
    public type: string,
    public user_interaction: boolean,
    // Dados faltando abaixo
    public user: string,
    public sent: number,
    public approved: number,
    public discount: number,
    public pixKey: string,
    public pixType: string,
    public supplierPixKey: string,
    public supplierPixType: string,
  ) {}

  static make(data: any): PaymentRemittance {
    const company = Company.make(data.company);
    const total = data.total ? +data.total : 0;

    const status = data.status ?? PaymentRemittancesStatusType.NOT_SENT;

    const sent = status === PaymentRemittancesStatusType.PENDING
      || status === PaymentRemittancesStatusType.PARTIALLY_APPROVED
        ? total
        : 0;

    const approved = status === PaymentRemittancesStatusType.APPROVED
      ? total
      : 0;

    return new PaymentRemittance(
      data.account_code ?? '',
      data.account_payable_supplier_document ?? '',
      data.actual_expiration_date ?? '',
      data.agency_code ?? '',
      data.bank_code ?? '',
      data.bank_number ?? '',
      data.barcode ?? '',
      data.bordero_number ?? null,
      data.branch ?? '',
      data.cnab_remi_generated_id ?? null,
      data.code ?? '',
      data.coin ?? '',
      data.comment ?? '',
      company,
      data.company_id ?? 0,
      data.dda_record ?? null,
      data.decrease ? +data.decrease : 0,
      data.due_date ?? '',
      data.exchange_rate ?? null,
      data.fees ? +data.fees : 0,
      data.form_of_payment ?? '',
      data.form_of_payment_description ?? '',
      data.form_of_payment_type ?? PaymentMethodType.OTHERS,
      data.id ?? 0,
      data.id_customer ?? '',
      data.increase ? +data.increase : 0,
      data.number ?? '',
      data.origin ?? '',
      data.parcel ?? '',
      data.period ?? '',
      data.number_reference ?? '',
      data.ported ?? '',
      data.prefix ?? '',
      data.released_remittance_payble ?? null,
      status,
      data.supplier_account_digit ?? '',
      data.supplier_account_number ?? '',
      data.supplier_account_type ?? '',
      data.supplier_agency ?? '',
      data.supplier_agency_digit ?? '',
      data.supplier_bank ?? '',
      data.supplier_branch ?? '',
      data.supplier_code_number ?? '',
      data.supplier_company_id ?? 0,
      data.supplier_document ?? '',
      data.supplier_fantasy_name ?? '',
      data.supplier_id ?? 0,
      data.supplier_id_customer ?? '',
      data.supplier_name ?? '',
      data.supplier_store_number ?? '',
      data.supplier_type ?? '',
      data.supplier_z_document ?? '',
      data.supplier_z_name ?? '',
      data.table_company_code ?? '',
      total,
      Number(data.inncash_value ?? 0),
      data.total_amended ? +data.total_amended : total,
      data.type ?? '',
      data.user_interaction ?? false,
      data.user ?? '',
      sent,
      approved,
      data.discount ?? 0,
      data.pix_key ?? '',
      data.pix_type ?? '',
      data.supplier_pix_key ?? '',
      data.supplier_pix_type ?? '',
    );
  }
}
