


















































































































































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import ChannelInfoIdentifiers from '@/components/timeline/types/ChannelInfoIdentifiers';
import HistoryTimelineActionsOptions from '@/components/timeline/types/HistoryTimelineActionsOptions';
import BillingHistory from '@/domain/models/BillingHistory';
import BillingHistorySupport from '@/components/timeline/support/HistoryTimelineSuport';
import EventStatusEnum from '@/components/timeline/enums/EventStatusEnum';
import IButtonOption from '@/plugins/inn-design/components/buttons/interfaces/IButtonOption';

@Component
export default class HistoryTimelineItem extends Vue {
  public channelInfoIdentifiers: ChannelInfoIdentifiers = {
    phone: { icon: 'mdi-phone', name: 'Telefone' },
    sms: { icon: 'mdi-message-processing', name: 'SMS' },
    email: { icon: 'mdi-email', name: 'E-Mail' },
    whatsapp: { icon: 'mdi-whatsapp', name: 'Whatsapp' },
    default: { icon: 'mdi-calendar', name: 'indefinido' },
  };

  @Prop(BillingHistory) readonly event!: BillingHistory;

  public get actionsOptions(): HistoryTimelineActionsOptions[] {
    const actionsOptionsArray = [
      {
        text: 'Editar',
        actionEmit: 'EDIT',
        icon: 'mdi-pencil',
      },
    ];

    if (this.isActive) {
      actionsOptionsArray.unshift(...[
        {
          text: 'Concluir com sucesso',
          actionEmit: 'CONCLUDE_SUCCESS',
          icon: 'mdi-check',
        },
        {
          text: 'Concluir com falha',
          actionEmit: 'CONCLUDE_FAIL',
          icon: 'mdi-close',
        },
      ]);
    }

    return actionsOptionsArray;
  }

  public get isReducedWidth(): boolean {
    return this.$vuetify.breakpoint.width < 700;
  }

  public get isActive(): boolean {
    return this.event.status === EventStatusEnum.active;
  }

  public get timeRange(): string {
    return `${this.event.initialRangeTime} - ${this.event.finalRangeTime}`;
  }

  public get getStatusColor(): string {
    return BillingHistorySupport.getColorByStatus(this.event.status);
  }

  public get getTextStatusOfEvent(): string {
    switch (this.event.status) {
      case EventStatusEnum.active: return 'Em andamento';
      case EventStatusEnum.success: return 'Finalizado com sucesso';
      case EventStatusEnum.failure: return 'Finalizado com falha';
      default: return '';
    }
  }

  public get getFormattedActionOptionsToButtonOptions(): IButtonOption[] {
    return this.actionsOptions.map(({ actionEmit, text }) => ({ text, value: actionEmit }));
  }

  public takeActionEmit(actionName: string, interactionId: number): void {
    if (actionName === 'CONCLUDE_SUCCESS') {
      this.$emit('click:conclude-success', interactionId, 'success');

      return;
    }

    if (actionName === 'CONCLUDE_FAIL') {
      this.$emit('click:conclude-fail', interactionId, 'failure');

      return;
    }

    this.$emit('click:edit', interactionId);
  }
}
