import LooseObjectType from '@/types/LooseObjectType';
import IUserCompanies from '@/domain/interfaces/IUserCompanies';

export default class Authentication {
  constructor(
    public id: number,
    public name: string,
    public erpCode: string,
    public erpCompanyGroupCode: string|null,
    public token: string,
    public email: string,
    public status: number,
    public userGroupId: number|null,
    public userGroupName: string,
    public userType: string,
    public useWebhook: string,
    public isAdmin: number,
    public urlPowerbiDashboard: string|null,
    public urlPowerbiDashboardDarkmode: string|null,
    public companyGroupId: number|null,
    public companyGroupName: string|null,
    public companyGroupImage: string|null,
    public useNcc: number,
    public userGravatar: string|null,
    public userPermission: LooseObjectType<'0' | '1'>,
    public userAccess: LooseObjectType<'0' | '1'>,
    public userCompany: IUserCompanies[],
    public block: Array<any>,
    public userConfig: LooseObjectType,
    public urlPowerbi: string|null,
    public movideskId: string|null,
    public forceChangePassword: boolean,
    public useActiveDirectory: boolean,
  ) { }

  static make(data: any): Authentication {
    return new Authentication(
      data.id,
      data.name,
      data.erp_code,
      data.erp_company_group_code,
      data.token,
      data.email,
      data.status,
      data.user_group_id,
      data.user_group_name,
      data.user_type,
      data.use_webhook,
      data.is_admin,
      data.url_powerbi_dashboard,
      data.url_powerbi_dashboard_darkmode,
      data.company_group_id,
      data.company_group_name,
      data.company_group_image,
      data.use_ncc,
      data.user_gravatar,
      data.user_permission,
      data.user_access,
      data.user_company,
      data.block,
      data.user_config,
      data.url_powerbi,
      data.movidesk_id,
      data.force_change_password,
      data.use_active_directory,
    );
  }
}
