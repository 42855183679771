










































































































import {
  Component,
  Emit,
  Mixins,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { formatErrorForNotification } from '@/utils/error';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import IOptions from '@/domain/interfaces/ISelectOptions';
import StatusType from '@/domain/enums/StatusType';
import ClientContact from '@/domain/models/ClientContact';
import ClientRepository from '@/repositories/ClientRepository';

@Component
export default class OtherContactDialog
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  @Ref('filter') readonly filter!: VForm;

  @Prop({
    type: String,
    required: true,
    default: '',
  }) readonly type!: 'CREATE' | 'UPDATE' | 'DELETE';

  @Prop({
    type: Object as () => ClientContact,
    required: true,
    default: {} as ClientContact,
  }) contact!: ClientContact;

  @Prop({
    type: String,
    default: '',
    required: true,
  }) customer!: string;

  @Prop({
    type: Number,
    required: true,
    default: 0,
  }) company!: number;

  @Emit('close')
  close(reload: boolean = false) {
    this.model = false;
    this.loading = false;
    this.data = {} as ClientContact;
    if (this.type !== 'DELETE') this.filter.resetValidation();

    return reload;
  }

  @Watch('model')
  onOpen(value: boolean) {
    if (
      value
      && this.type === 'UPDATE'
      && !!this.contact.id
    ) {
      const { phone } = this.contact;
      const formattedPhone = this.formatPhone(phone);

      this.data = {
        ...this.contact,
        phone: formattedPhone,
      };
    }
  }

  public readonly clientRepository: ClientRepository = new ClientRepository();
  public data: ClientContact = {} as ClientContact;
  public loading: boolean = false;

  public titles: Record<string, string> = {
    CREATE: 'Novo Contato',
    UPDATE: 'Editar Contato',
    DELETE: 'Excluir Contato',
  };

  public status: Array<IOptions<StatusType>> = [
    {
      value: StatusType.ACTIVE,
      text: 'Ativo',
    },
    {
      value: StatusType.INACTIVE,
      text: 'Inativo',
    },
  ];

  public rule: Record<string, any> = {
    required: (v: string) => !!v || 'Campo obrigatório.',
    email: (v: string) => /.+@.+\..+/.test(v) || 'Email inválido.',
    phone: (v: string) => (!!v && v.length > 13) || 'Telefone inválido.',
  };

  public get maxWidth(): string {
    if (this.$vuetify.breakpoint.xs) {
      return '100%';
    }

    if (this.$vuetify.breakpoint.smAndDown) {
      return '80%';
    }

    if (this.$vuetify.breakpoint.mdAndDown) {
      return '60%';
    }

    return '40%';
  }

  public get phoneMask(): string | null {
    if (!this.data.phone) return null;

    const { length } = this.data.phone
      .replaceAll(' ', '');

    return length <= 13
      ? '(##) ####-####?'
      : '(##) # ####-####';
  }

  public formatPhone(value: string): string {
    return value.length > 10
      ? value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
      : value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  public save(): any {
    if (this.type === 'DELETE') {
      return this.deleteContact(this.contact.id);
    }

    if (this.filter.validate()) {
      return this.type === 'UPDATE'
        ? this.updateContact(this.data)
        : this.createContact(this.data);
    }

    return null;
  }

  public async deleteContact(id: number): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      await this.clientRepository
        .destroyCustomerContact(group, this.company, this.customer, id);

      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async updateContact(data: ClientContact): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      const {
        id,
        name,
        email,
        phone,
        department,
        status,
      } = data;

      const record: Partial<ClientContact> = {
        name,
        email,
        phone: phone.replace(/[^\d]/g, ''),
        department,
        status,
      };

      await this.clientRepository
        .updateCustomerContact(group, this.company, this.customer, id, record);

      this.contact.name = name;
      this.contact.email = email;
      this.contact.phone = phone.replace(/[^\d]/g, '');
      this.contact.status = status;
      this.contact.department = department;

      this.close();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }

  public async createContact(data: ClientContact): Promise<void> {
    try {
      this.loading = true;

      const group: number = this.$session.get('company_group_id');

      const {
        name,
        email,
        phone,
        department,
        status,
      } = data;

      const record: Partial<ClientContact> = {
        name,
        email,
        phone: phone.replace(/[^\d]/g, ''),
        department,
        status,
      };

      await this.clientRepository
        .sendCustomerContact(group, this.company, this.customer, record);

      this.close(true);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
