<template>
  <v-container>
		<v-row no-gutters>
			<v-col xs="10" md="5">Ocultar parâmetros ao realizar consulta</v-col>
			<v-col xs="2">
				<custom-switch
					name="autoToggleParamsSelect"
					id="autoToggleParamsSelect"
					:value="user_config.autotoggle_paramns"
					@input="user_config.autotoggle_paramns = !user_config.autotoggle_paramns"
				/>
			</v-col>
		</v-row>
		 <v-row no-gutters>
			<v-col xs="10" md="5">Exibir número do Borderô gerado</v-col>
			<v-col xs="2">
				<custom-switch
					name="autoToggleRemittanceConfirmMessageSelect"
			 		  id="autoToggleRemittanceConfirmMessageSelect"
					:value="user_config.autotoggle_remittance_confirm_message"
					@input="user_config.autotoggle_remittance_confirm_message = !user_config.autotoggle_remittance_confirm_message"
				/>
			</v-col>
		</v-row>

		<v-row justify="center" class="m-portlet__foot m-portlet__foot--fit pa-4 mt-12">
			<custom-button
				:loading="loading"
				buttonClass="btn-success"
				@click="onSubmit()"
				label="Salvar"
			/>
		</v-row>
	</v-container>
</template>

<script>
import CustomSwitch from '../../../src/components/fields/CustomSwitch.vue';
import UserService from '@/services/user.service';
import CustomButton from '../../../src/components/buttons/CustomButton.vue';

export default {
	name: 'UserConfig',
	components: { CustomSwitch, CustomButton },
  data() {
    return {
			user_config: {
				id: null,
				user_id: null,
				autotoggle_paramns: true,
				autotoggle_remittance_confirm_message: false,
			},
			loading: false,
    };
  },
  mounted: function() {
    this.loadConfig();
  },
  methods: {
    loadConfig: function() {
			const userService = new UserService();
			userService.config().get('')
        .then(result => this.user_config = result.data)
				.catch(() => {});
    },
		onSubmit() {
			this.loading = true;
			const userService = new UserService();
			userService.config().update(this.user_config)
        .then(() => {
					this.$session.set('user_config_autotoggle_paramns', this.user_config.autotoggle_paramns);
					this.$session.set('user_config_autotoggle_remittance_confirm_message', this.user_config.autotoggle_remittance_confirm_message);
					showNotification('Sucesso', 'Dados alterados com sucesso!', 'success');
        })
				.catch((error) => this.$helpers.defaultCatchError(error))
				.finally(() => this.loading = false);
		},
  }
};
</script>
