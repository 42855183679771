import AccountActionsEnum from '@/repositories/parameters/AccountLogs/enums/AccountActionsEnum';

export default class ListAccountLogs {
    constructor(
      public action: AccountActionsEnum,
      public content: string,
      public createdAt: string,
    ) {}

    static make(data: any): ListAccountLogs {
        return new ListAccountLogs(
            data.action,
            data.content,
            data.created_at,
        );
    }
}
