import Repository from '@/repositories/base/Repository';
import ISaveCreditRuleParams from '@/views/creditRules/interfaces/ISaveCreditRuleParams';
import CreditRule from '@/domain/models/CreditRule';
import CreditRuleList from '@/domain/models/CreditRuleList';
import CreditVariables from '@/domain/models/CreditVariables';
import IUpdateindexesFromCreditTotalizersParams from '@/repositories/parameters/IUpdateindexesFromCreditTotalizersParams';

export default class CreditRuleRepository extends Repository {
  public async getCreditRulesByCompanyId(
    companyGroupId: string,
    companyId: number,
  ): Promise<CreditRuleList[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/credit-rules`, {
      headers: { 'company-id': companyId },
    });

    return response.data.map(CreditRuleList.make);
  }

  public async getCreditRuleById(
    companyGroupId: string,
    companyId: number,
    creditRuleId: number,
  ): Promise<CreditRule> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/credit-rules/${creditRuleId}`, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });

    return CreditRule.make(response.data);
  }

  public async getVariablesFromCompanyId(
      companyGroupId: number,
      companyIds: number[],
  ): Promise<CreditVariables[]> {
    const { status, data: response } = await this.datasource.get(`company-groups/${companyGroupId}/credit-rules/variables`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return response.data.map(CreditVariables.make);
  }

  public async insertCreditRule(
    companyGroupId: string,
    creditRulesParams: ISaveCreditRuleParams,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/credit-rules`, creditRulesParams);
  }

  public async updateCreditRule(
    companyGroupId: string,
    creditRuleId: string,
    creditRulesParams: ISaveCreditRuleParams,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroupId}/credit-rules/${creditRuleId}`, creditRulesParams);
  }

  public async adjustCreditRulesIndexesFromCompanyId(
    companyGroupId: string,
    companyId: number,
    correlationBetweenIndexAndCreditRulesId: IUpdateindexesFromCreditTotalizersParams[],
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroupId}/credit-rules/update/indexes`, {
      correlationBetweenIndexAndCreditRulesId,
    }, {
      headers: { 'company-id': companyId },
    });
  }

  public async removeCreditRule(
    companyGroupId: string,
    companyId: number,
    id: number,
  ): Promise<void> {
    await this.datasource.destroy(`company-groups/${companyGroupId}/credit-rules/${id}`, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });
  }
}
