














































































































































































import {
  Vue,
  Component,
  Watch,
} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import OrderRepository from '@/repositories/OrderRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import RoutineLockDialog from '@/components/dialogs/routineLock/RoutineLockDialog.vue';
import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import Orders from '@/domain/models/Orders';
import FilterParameterOrdersList from '@/domain/models/filter-parameters/FilterParameterOrdersList';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import DataTableHelper from '@/helpers/DataTableHelper';
import DateInputHelper from '@/helpers/DateInputHelper';
import OrderSupport from '@/views/orders/support/OrderSupport';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import TableUtils from '@/utils/tableUtils';
import OnRequestTableOptions from '@/types/OnRequestTableOptions';
import VMenuOptions from '@/types/VMenuOptions';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';
import RangeDate from '@/types/RangeDate';
import LooseObjectType from '@/types/LooseObjectType';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import CompanyRepository from '@/repositories/CompanyRepository';
import RoutineLockRepository from '@/repositories/RoutineLockRepository';
import RoutineLockEnum from '@/domain/enums/RoutineLockEnum';
import GenerateTitlesReleasedByRangeDateDialog from '@/views/clients/components/GenerateTitlesReleasedByRangeDateDialog.vue';

@Component({
  components: {
    DynamicColumnMenu,
    ButtonFilterMenuOptions,
    RoutineLockDialog,
    GenerateTitlesReleasedByRangeDateDialog,
  },
})
export default class OrderList extends Vue {
  public search: string = '';
  public consideredColumnToSearch: string = '';
  public userNameUsingRoutineLock: string = '';

  public loading: Boolean = false;
  public showDialogOrderInUse: boolean = false;
  public isGenerateTitlesReleasedByDateDialogOpen: boolean = false;

  public selectedCompanies: number[] = [];
  public totalItems: number = 0;
  public shouldBringOnlyBlockedOrders: string = '0';

  public defaultButtonFilterMenuOption: VMenuOptions | null = null;

  public readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();
  public readonly orderRepository: OrderRepository = new OrderRepository();
  public readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  public readonly orderSupport: OrderSupport = OrderSupport;
  private readonly companyRepository:
    CompanyRepository = new CompanyRepository();
  private readonly routineLockRepository:
    RoutineLockRepository = new RoutineLockRepository();

  public dataTableOptions: DataOptions = DataTableHelper.getDefaultDataTableOptions();
  public footerOptions: DataTableFooterOptions = DataTableHelper.getDefaultFooterProps();

  public formattedInclusionDate: Partial<RangeDate> = {};
  public headerKeysObject: LooseObjectType<string> = {
    situation: 'data_table_orders_list_show_column_situation',
    number: 'data_table_orders_list_show_column_number',
    companyName: 'data_table_orders_list_show_column_company_name',
    document: 'data_table_orders_list_show_column_document',
    salesChannel: 'data_table_orders_list_show_column_sales_channel',
    inclusionDate: 'data_table_orders_list_show_column_inclusion_date',
    sector: 'data_table_orders_list_show_column_sector',
    district: 'data_table_orders_list_show_column_district',
  }

  public inclusionDate: string[] = []
  public companyOptions: ISelectOptions<number>[] = [];
  public items: Orders[] = [];
  public availableHeaders: IVDataTableHeader[] = [
    { text: 'Situação', value: 'status', show: true },
    { text: 'Número', value: 'number', show: true },
    { text: 'Razão Social', value: 'companyName', show: true },
    { text: 'CNPJ', value: 'document', show: true },
    { text: 'Setor', value: 'sector', show: true },
    { text: 'Distrito', value: 'district', show: true },
    { text: 'Canal de Vendas', value: 'salesChannel', show: true },
    { text: 'Código do Banco', value: 'bankCode', show: true },
    { text: 'Data de Inclusão', value: 'inclusionDate', show: true },
    { text: 'Hora de Corte', value: 'cutoffTime', show: true },
  ];
  public considerBlockedOrdersOptions: ISelectOptions<string>[] = [
    { text: 'Não', value: '0' },
    { text: 'Sim', value: '1' },
    { text: 'Ambos', value: '' },
  ];

  public get headers(): IVDataTableHeader[] {
    return this.availableHeaders
      .filter((h) => h.show)
      .concat(
        {
          text: '',
          value: 'actions',
          class: 'action-columns',
          cellClass: 'action-columns',
          sortable: false,
        },
        {
          text: 'column',
          value: 'column',
          class: 'dynamic-columns',
          cellClass: 'dynamic-columns',
          sortable: false,
        },
      );
  }

  public get filtersFromInput(): VMenuOptions[] {
    return this.availableHeaders
      .filter(({ value }) => ['number', 'companyName', 'document', 'sector', 'district'].includes(value))
      .map(({ text, value }) => ({ text, value }));
  }

  public get hasSelectedCompanies(): boolean {
    return this.selectedCompanies.length > 0;
  }

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    if (!this.hasSelectedCompanies) {
      return;
    }

    this.getAllOrders(this.selectedCompanies, { resetToFirst: false });
  }

  public async created(): Promise<void> {
    this.getCompanies();
    await this.getGroupFilterParameters();

    if (this.hasSelectedCompanies) {
      this.getAllOrders(this.selectedCompanies, { resetToFirst: false });
    }
  }

  public async getGroupFilterParameters(): Promise<void> {
    this.loading = true;

    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.ORDERS);
      const formattedFilters = FilterParameterOrdersList.make(filterParameters);

      this.applyFiltersOnActualPage(formattedFilters);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.loading = false;
    }
  }

  public applyFiltersOnActualPage(filters: FilterParameterOrdersList): void {
    this.dataTableOptions.sortBy = filters.tableSort;
    this.dataTableOptions.sortDesc = filters.tableDirection;
    this.dataTableOptions.itemsPerPage = filters.rowsPerPage;
    this.dataTableOptions.page = filters.actualPage;
    this.selectedCompanies = filters.selectedCompanies;
    this.inclusionDate = filters.inclusionDateRange;
    this.shouldBringOnlyBlockedOrders = filters.shouldBringBlockedOrdersOnOrdersList;

    const formattedInclusionDateRange = DateInputHelper
      .formatRangeDate(filters.inclusionDateRange);

    if (formattedInclusionDateRange) {
      this.formattedInclusionDate = formattedInclusionDateRange;
    }

    this.defaultButtonFilterMenuOption = FilterParameterHelper
      .getFormattedColumnObjectOfOptionsButton(this.filtersFromInput, filters.columnToSearch);

    this.availableHeaders = FilterParameterHelper
      .defineColumnsToShowOnSpecificHeader(this.availableHeaders, filters.columnsToShow);
  }

  public async getAllOrders(
    selectedCompanies: number[],
    onRequestTableOptions: OnRequestTableOptions = {},
  ): Promise<void> {
    if (this.loading) {
      return;
    }

    if (onRequestTableOptions.resetToFirst) {
      this.dataTableOptions.page = 1;
    }

    this.loading = true;

    const { sortDesc, sortBy } = this.dataTableOptions;
    const formattedSort = TableUtils.tableOrdenationFormatterToLaravel(sortDesc[0], sortBy[0]);

    try {
      this.filterParametersRepository
        .setFilter(GroupFilterParametersEnum.ORDERS, [
          { key: 'sort_table_orders_list', value: formattedSort },
          { key: 'column_to_search_orders_list', value: this.consideredColumnToSearch },
          { key: 'date_range_orders_list', value: JSON.stringify(this.inclusionDate) },
          { key: 'actual_page_orders_list', value: this.dataTableOptions.page },
          { key: 'rows_per_page_orders_list', value: this.dataTableOptions.itemsPerPage },
          { key: 'selected_company_orders_list', value: JSON.stringify(selectedCompanies) },
          { key: 'should_bring_blocked_orders_on_orders_list', value: this.shouldBringOnlyBlockedOrders },
        ]);

      if (this.selectedCompanies.length < 1) {
        this.items = [];
        this.totalItems = 0;
        this.dataTableOptions.page = 1;

        return;
      }

      const { data, total } = await this.orderRepository.listAll(
        selectedCompanies,
        this.authenticationModule.user.company_group_id,
        {
          search: this.search,
          columnToSearch: this.consideredColumnToSearch,
          initialInclusionDate: this.formattedInclusionDate.initialDate,
          finalInclusionDate: this.formattedInclusionDate.finalDate,
          sort: formattedSort,
          actualPage: this.dataTableOptions.page,
          rowsPerPage: this.dataTableOptions.itemsPerPage,
          shouldBringOnlyBlockedOrders: this.shouldBringOnlyBlockedOrders
            ? this.shouldBringOnlyBlockedOrders
            : null,
        },
      );

      this.items = data;
      this.totalItems = total;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os pedidos!');
    } finally {
      this.loading = false;
    }
  }

  public async getCompanies(): Promise<void> {
    this.$dialog.startLoading();

    try {
      const companies = await this.companyRepository.getCompaniesByLoggedUser(
        parseInt(this.authenticationModule.user.company_group_id, 10),
      );

      this.companyOptions.push(...companies.map(({ fantasyName, id }) => ({
        text: fantasyName,
        value: id,
      })));
    } catch (error) {
      const message = this.$helpers.extractAxiosErrorMessage(error, 'Houve um problema ao requisitar as empresas.');

      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async verifyIfRoutineIsLocked(selectedIdCustomer: string): Promise<boolean> {
    this.$dialog.startLoading();

    try {
      const response = await this.routineLockRepository.verifyIfRoutineLockExists({
        routine_code: selectedIdCustomer,
        routine_name: RoutineLockEnum.SELECT_ORDER,
      });

      if (response.isLocked) {
        this.userNameUsingRoutineLock = response.userName;
        this.showDialogOrderInUse = true;
      }

      return response.isLocked;
    } catch (error) {
      this.$notification.error('Houve um problema ao verificar se o pedido estava em uso.');

      return true;
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async handleClickRow(
    { innId, customerInnId, companyId }: Orders,
    specificPage: string,
  ): Promise<void> {
    const isLocked = await this.verifyIfRoutineIsLocked(innId);

    if (isLocked) {
      return;
    }

    let routeString = `/pedidos/${innId}/${customerInnId}/${companyId}`;

    if (specificPage !== '') {
      routeString += `/${specificPage}`;
    }

    this.$router.push(routeString);
  }

  public handleSearch(search: string): void {
    if (!this.hasSelectedCompanies) {
      return;
    }

    this.search = search;

    this.getAllOrders(this.selectedCompanies, { resetToFirst: false });
  }

  public handleSelectedOptionChange(selectedOption: VMenuOptions | null): void {
    let definedValueToConsider = '';

    if (selectedOption !== null) {
      definedValueToConsider = selectedOption.value;
    }

    this.consideredColumnToSearch = definedValueToConsider;

    if (!this.search) {
      return;
    }

    if (!this.hasSelectedCompanies) {
      return;
    }

    this.getAllOrders(this.selectedCompanies, { resetToFirst: true });
  }

  public async handleSelectDateRange(): Promise<void> {
    await this.$nextTick();

    const formattedInclusionDate = DateInputHelper.formatRangeDate(this.inclusionDate);

    if (formattedInclusionDate === null) {
      this.$notification.warn('O range de data está inválido!');

      return;
    }

    this.formattedInclusionDate = formattedInclusionDate;

    if (!this.hasSelectedCompanies) {
      return;
    }

    this.getAllOrders(this.selectedCompanies, { resetToFirst: true });
  }

  public async handleSelectCompany(selectedCompanies: number[]): Promise<void> {
    this.getAllOrders(selectedCompanies, { resetToFirst: true });
  }

  public async handleCloseRoutineLockDialog(): Promise<void> {
    this.showDialogOrderInUse = false;
    this.userNameUsingRoutineLock = '';
  }
}
