import OriginType from '@/domain/enums/DDAConciliationOriginType';

export default class DDAConciliationMovement {
  constructor(
    public id: number | string,
    public origin: OriginType,
    public document: string,
    public name: string,
    public title: string,
    public prefix: string,
    public type: string,
    public barcode: string,
    public value: number,
    public emission_date: string,
    public due_date: string,
    public company_id: number,
    public branch_code: number,
    public decrease?: number,
    public increase?: number,
    public balance?: number,
    public supplier_id_customer?: string,
    public artificial_invoice_id?: number,
    public selected?: boolean,
    public companyId?: number,
  ) {}

  static make(data: any): DDAConciliationMovement {
    const id = data.origin == 'DDA' ? +data.id : `${data.id_customer}`;
    const value = parseFloat(data.value);

    const numberWithInstallmentSeparator = data.number && data.installment ? '/' : '';
    const numberWithInstallment = `${data.number ?? ''}${numberWithInstallmentSeparator}${data.installment ?? ''}`;

    const titleWithInstallmentSeparator = data.title && data.installment ? '/' : '';
    const titleWithInstallment = `${data.title ?? ''}${titleWithInstallmentSeparator}${data.installment ?? ''}`;

    const title = data.title ? titleWithInstallment : numberWithInstallment;
    const decrease = data.decrease_value ? parseFloat(data.decrease_value) : 0;
    const increase = data.increase_value ? parseFloat(data.increase_value) : 0;
    const balance = data.balance ? parseFloat(data.balance) : 0;

    return new DDAConciliationMovement(
      id,
      data.origin,
      data.document,
      data.name,
      title,
      data.prefix,
      data.type,
      data.barcode,
      value,
      data.emission_date,
      data.due_date,
      data.company_id,
      data.branch_code,
      decrease,
      increase,
      balance,
      data.supplier_id_customer,
      data.artificial_invoice_id,
      false,
      data.company_id,
    );
  }
}
