<template>
  <v-row no-gutters class="footer-container">
    <v-col class="footer" xs="12">
      <v-container class="full-height d-flex">
        <v-row
          class="mt-0"
          no-gutters
          align-content="center"
          justify="space-between"
        >
          <v-col xs="2" md="2" class="no-print">
            <v-img v-if="logoPath" :src="logoPath"></v-img>
          </v-col>
          <v-col xs="2" md="1" class="inncash-logo-column">
            <a href="https://inn.cash/" target="_blank" alt="Inncash">
              <span class="credit-text">Powered by:</span>
              <v-img src="@/assets/inncash-logo-white.webp"></v-img>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: 'TokenPagesFooter',
  props: {
    logoPath: null,
  },
};
</script>

<style scoped>
.footer-container {
  height: 80px;
  display: flex;
  align-content: center;
}
.footer {
  background: #2c2e3e;
  color: #fff;
  font-size: 2em !important;
  height: 100%;
}
.footer a {
  color: #fff;
  text-decoration: none;
}
.full-height {
  height: 100%;
}
.credit-text {
  font-size: 14px;
  display: block;
  color: #fff;
  text-decoration: none;
}
.credit-text:hover {
  text-decoration: none;
}

@media print {
  .footer {
    height: 80px;
    align-content: center;
  }

  .footer-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    -webkit-print-color-adjust: exact;
  }

  .footer-container .inncash-logo-column {
    flex: 1;
    text-align: center;
  }

  .footer-container .inncash-logo-column .v-image {
    max-width: 100px;
    display: inline-block;
  }
}
</style>
