








































import Component, { mixins } from 'vue-class-component';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import Permission from '@/domain/models/Permission';
import PermissionItemsList from './PermissionItemsList.vue';

@Component({
  components: {
    PermissionItemsList,
  },
})
export default class MenuPermissionsTab extends mixins<ModelManagementMixin<Permission[]>>(
  ModelManagementMixin,
) {
  public considerAllGroups: boolean = false;

  public get getTextTitleColor() {
    if (this.$vuetify.theme.dark) {
      return 'white--text';
    }

    return 'grey--text text--darken-4';
  }

  public handleConsiderAllGroups(isActive: boolean): void {
    this.model.forEach((permission) => {
      permission.isActive = isActive;
    });
  }
}
