export default class OperatorAbsence {
  constructor(
    public initialDate: string,
    public endDate: string,
    public substituteId: number,
  ) {}

  static make(data: any): OperatorAbsence {
    return new OperatorAbsence(
      data.initial_date,
      data.end_date,
      data.substitute_id,
    );
  }
}
