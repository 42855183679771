import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v(" Títulos da fatura ")]),_c('div',[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]),_c(VCardText,[_c(VDataTable,{attrs:{"hide-default-footer":"","items":_vm.invoiceAccounts,"headers":_vm.headers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }