import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider),_c(VRow,{staticClass:"align-center justify-space-between mt-4"},[_c(VCol,{attrs:{"sm":"9","cols":"12"}},[_c('span',{staticClass:"text-h5"},[_vm._v("Outros Contatos")])]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"sm":"3","xs":"12","cols":"12"}},[_c(VBtn,{attrs:{"large":"","block":"","color":"info"},on:{"click":function($event){return _vm.handleOpen('CREATE')}}},[_vm._v(" ADICIONAR CONTATO ")])],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.contacts,"loading":_vm.loading,"loading-text":"Carregando contatos...","no-data-text":"Nenhum contato encontrado.","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('StatusChip',{attrs:{"value":value ? 'A' : 'I',"list":_vm.status}})]}},{key:"item.phone",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPhone(value))+" ")]}},{key:"item.email",fn:function(ref){
var value = ref.value;
return [_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('action-button',{attrs:{"label":"Editar","icon":"mdi-pencil","disabled":_vm.loading},on:{"click":function($event){return _vm.handleOpen('UPDATE', item)}}}),_c('action-button',{attrs:{"label":"Excluir","icon":"mdi-delete","disabled":_vm.loading},on:{"click":function($event){return _vm.handleOpen('DELETE', item)}}})]}}])})],1)],1),_c('other-contact-dialog',{attrs:{"type":_vm.type,"contact":_vm.contact,"customer":_vm.customer,"company":_vm.company},on:{"close":_vm.handleclose},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }