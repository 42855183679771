export default class Supplier {
  constructor(
    public id: string,
    public name: string,
    public code: string,
    public document: string,
  ) {}

  static make(data: any): Supplier {
    return new Supplier(
      data.id,
      data.name,
      data.code,
      data.document ?? '',
    );
  }
}
