import ColumnToShow from '@/types/ColumnToShow';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';

export default class FilterParameterOrdersList {
    public constructor(
        public tableDirection: boolean[],
        public tableSort: string[],
        public columnToSearch: string,
        public inclusionDateRange: string[],
        public rowsPerPage: number,
        public actualPage: number,
        public columnsToShow: ColumnToShow[],
        public selectedCompanies: number[],
        public shouldBringBlockedOrdersOnOrdersList: string,
    ) {
    }

    public static make(data: any): FilterParameterOrdersList {
      const dataColumn = {
        data_table_orders_list_show_column_situation: 'situation',
        data_table_orders_list_show_column_number: 'number',
        data_table_orders_list_show_column_company_name: 'companyName',
        data_table_orders_list_show_column_document: 'document',
        data_table_orders_list_show_column_sales_channel: 'salesChannel',
        data_table_orders_list_show_column_inclusion_date: 'inclusionDate',
        data_table_orders_list_show_column_sector: 'sector',
        data_table_orders_list_show_column_district: 'district',
      };

      let arrayDirection: boolean[] = [];
      let arraySort: string[] = [];

      if (data.sort_table_orders_list) {
        const { direction, sort } = FilterParameterHelper
        .defineOrdenation(data.sort_table_orders_list);

        arrayDirection = [direction];
        arraySort = [sort];
      }

      const parsedRowsPerPage = parseInt(data.rows_per_page_orders_list, 10);
      const parsedActualPage = parseInt(data.actual_page_orders_list, 10);

      const inclusionDate = data.date_range_orders_list
      ? JSON.parse(
        data.date_range_orders_list,
      ) : [];

      let selectedCompanies = [];

      if (data.selected_company_orders_list) {
        const parsedCompanies = JSON.parse(data.selected_company_orders_list);

        if (typeof parsedCompanies === 'number') {
          selectedCompanies = [parsedCompanies];
        } else {
          selectedCompanies = parsedCompanies;
        }
      }

      return new FilterParameterOrdersList(
        arrayDirection,
        arraySort,
        data.column_to_search_orders_list ?? 'search',
        inclusionDate,
        !Number.isNaN(parsedRowsPerPage) ? parsedRowsPerPage : 10,
        !Number.isNaN(parsedActualPage) ? parsedActualPage : 1,
        FilterParameterHelper.mountColumnToShowArray(data, dataColumn),
        selectedCompanies,
        data.should_bring_blocked_orders_on_orders_list,
      );
    }
}
