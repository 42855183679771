







































import {
  Component,
  Emit,
  Mixins,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';

@Component
export default class PayrollSelectHeader
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
    @Emit()
    click(): boolean {
      return true;
    }

    toggle() {
      this.model = !this.model;
    }
  }
