export default class PaymentMethod {
  constructor(
    public id: number,
    public code: string,
    public description: string,
    public method_type: string,
    public type: number,
  ) {}

  static make(data: any): PaymentMethod {
    return new PaymentMethod(
      data.id,
      data.code ?? '',
      data.description ?? '',
      data.method_type ?? '',
      data.type ?? 0,
    );
  }
}
