enum PaymentRemittancesStatusType {
  NOT_SENT = '',
  PENDING = '0',
  APPROVED = '1',
  DISAPPROVED = '2',
  PARTIALLY_APPROVED = '3',
  SENT = '4',
}

export default PaymentRemittancesStatusType;
