



































































































































































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import PaymentRemittanceDetails from '@/domain/models/PaymentRemittanceDetails';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { formatErrorForNotification } from '@/utils/error';
import { formateDate } from '@/utils/date';
import { toCurrency } from '@/utils/';
import { formatCNPJ, formatCPF, formatTitle } from '../../utils';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionViewDetails extends Vue {
  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Emit()
  close(reload: boolean = false): CloseActionData {
    return {
      type: ActionType.VIEW_DETAILS,
      reload,
    };
  }

  @Watch('open')
  onChangeOpen() {
    if (this.open) {
      this.loadDetails();
    } else {
      this.tabs = null;
      this.loading = false;
      this.details = {} as PaymentRemittanceDetails;
    }
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly formatTitle = formatTitle;
  readonly fromatDate = formateDate;
  readonly formatCurrency = toCurrency;

  tabs: any = null;
  loading: boolean = false;

  details: PaymentRemittanceDetails = {} as PaymentRemittanceDetails;

  get isInvoice(): boolean {
    return this.item
      && this.item.origin === 'inncash'
      && this.item.type === 'FT';
  }

  get title(): string {
    return this.isInvoice
      ? 'Detalhes da Fatura'
      : 'Detalhes do Título a Pagar';
  }

  formatDocument(value: string): string {
    return value.length >= 14
      ? formatCNPJ(value)
      : formatCPF(value);
  }

  formatDateAndHour(value: string): string {
    const date = DateTime.fromISO(value).toLocaleString();
    const hour = DateTime.fromISO(value).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    return `${date} ${hour}`;
  }

  formatType(value: string): string {
    switch (value) {
      case 'comment':
        return 'Comentário';
      case 'approve_accounts_payable':
        return 'Aprovação';
      case 'disapprove_account_payable':
        return 'Reprovação';
      case 'created_invoice':
        return 'Criação da Fatura';
      case 'account_payable_in_a_remittance':
        return 'Geração da Remessa';
      default:
        return value;
    }
  }

  async loadDetails(): Promise<void> {
    try {
      this.loading = true;

      const group = this.$session.get('company_group_id');
      const company = this.item.company_id;
      const type = this.isInvoice ? 'invoices' : null;
      const id = this.item.id_customer;

      this.details = await this.paymentRemittanceRepository.getDetails(group, company, type, id);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
