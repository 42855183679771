import RangeDate from '@/types/RangeDate';
import { validateDateRange } from '@/utils/date';

export default class DateInputHelper {
  public static formatRangeDate(dateField: string[]): Partial<RangeDate> | null {
    const formattedDate: Partial<RangeDate> = {};

    if (dateField[0]) {
       ({ 0: formattedDate.initialDate } = dateField);
    }

    if (dateField[1]) {
      ({ 1: formattedDate.finalDate } = dateField);
    }

    if (
      formattedDate.initialDate
      && formattedDate.finalDate
      && !validateDateRange(formattedDate.initialDate, formattedDate.finalDate)
    ) {
      return null;
    }

    return { ...formattedDate };
  }
}
