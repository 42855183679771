




















































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';
import PaymentRemittancesSummary from '@/domain/models/PaymentRemittancesSummary';
import ActionType from '@/domain/enums/PaymentRemittancesActionType';

@Component
export default class PaymentRemittancesSummaryMenu extends Vue {
  @Prop({
    type: String,
  }) type!: string;

  @Prop({
    type: Object as () => PaymentRemittancesSummary,
    required: true,
  }) item!: PaymentRemittancesSummary;

  @Emit('action')
  action(
    type: ActionType.EDIT
      | ActionType.VIEW_DETAILS
      | ActionType.APPROVE
      | ActionType.DISAPPROVE,
  ) {
    const { item } = this;
    return { type, summary: item };
  }

  readonly ActionType = ActionType;
}
