






















































































import {
  Component,
  Prop,
  Emit,
  Watch,
  Mixins,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { formatErrorForNotification } from '@/utils/error';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import BillingPendencyRepository from '@/repositories/BillingPendencyRepository';
import BillingPendencyHistory from '@/domain/models/BillingPendencyHistory';
import BillingPendency from '@/domain/models/BillingPendency';

@Component
export default class BillingPendencyHistoryDialog
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
  @Prop({
    type: Object as () => BillingPendency,
    required: true,
    default: {} as BillingPendency,
  }) public readonly pendency!: BillingPendency;

  @Emit()
  public close(reload: boolean = false): boolean {
    this.model = false;
    this.loading = false;
    this.history = [];

    return reload;
  }

  @Watch('model')
  public onChangeOpen() {
    if (this.model) {
      this.loadHistory();
    }
  }

  readonly billingPendencyRepository:
    BillingPendencyRepository = new BillingPendencyRepository();

  public loading: boolean = false;

  public history: Array<BillingPendencyHistory> = [];

  public formatDateAndHour(value: string): string {
    const date = DateTime.fromISO(value).toLocaleString();
    const hour = DateTime.fromISO(value).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    return `${date} ${hour}`;
  }

  public async loadHistory(): Promise<void> {
    try {
      this.loading = true;

      const group = this.$session.get('company_group_id');
      const { id } = this.pendency;

      this.history = await this.billingPendencyRepository
        .getPendencyHistory(group, id);
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
