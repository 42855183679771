import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"450px","hide-overlay":"","persistent":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6"},[_c(VIcon,{staticClass:"mr-4",attrs:{"large":"","color":"error"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" Títulos de Diferentes Fornecedores ")],1),_c(VCardText,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center mb-2",attrs:{"cols":"12"}},[_vm._v(" Selecione abaixo o fornecedor da fatura. ")])],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"centered-input",attrs:{"cols":"12"}},[_c(VForm,{ref:"form-supplier"},[_c(VAutocomplete,{attrs:{"loading":_vm.loadingSuppliers,"items":_vm.suppliers,"search-input":_vm.searchSuppliers,"label":"Fornecedores","placeholder":"Selecione um fornecedor.","no-data-text":_vm.loadingSuppliers
              ? 'Carregando fornecedores...' : 'Nenhum fornecedor correspondente encontrado.',"rules":[_vm.valueRequerid],"clearable":"","filled":"","dense":""},on:{"update:searchInput":function($event){_vm.searchSuppliers=$event},"update:search-input":function($event){_vm.searchSuppliers=$event}},model:{value:(_vm.supplier),callback:function ($$v) {_vm.supplier=$$v},expression:"supplier"}})],1)],1)],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"px-8",attrs:{"dark":"","depressed":"","color":"info"},on:{"click":_vm.close}},[_vm._v(" VOLTAR ")]),_c(VBtn,{staticClass:"px-4",attrs:{"dark":"","depressed":"","color":"success"},on:{"click":_vm.handleNext}},[_vm._v(" CONTINUAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }