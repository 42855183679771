













































































































































import {
  Vue,
  Component,
  Ref,
} from 'vue-property-decorator';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import { dateNow, formateDate, validateDateRange } from '@/utils/date';
import { VForm } from '@/types/VForm';

interface History {
  type: number,
  description: string,
  user: string,
  date: string,
}

interface HistoryFilterData {
  'initial_date': string,
  'end_date': string,
  'operation_type'?: number,
  search?: string,
  'users_id'?: Array<number>,
  'order_by'?: string,
  'sort_by'?: string,
}

export interface DateRangeError {
  error: boolean,
  initialMessage: string,
  endMessage: string,
}

@Component
export default class DdaConciliationHistory extends Vue {
  @Ref('form-filter') readonly formFilter!: VForm;

  readonly formateDate = formateDate;

  loading: boolean = false;
  showFilter: boolean = true;
  showItems: boolean = false;

  data: HistoryFilterData = {
    initial_date: dateNow(),
    end_date: dateNow(),
  };

  types: Array<SelectOptions> = [
    { text: 'Automática', value: 0 },
    { text: 'Manual', value: 1 },
  ];

  headers: Array<IVDataTableHeader> = [
    { text: 'Tipo', value: 'type', align: 'center' },
    { text: 'Descrição', value: 'description', align: 'center' },
    { text: 'Usuário', value: 'user', align: 'center' },
    { text: 'Data', value: 'date', align: 'center' },
  ];

  items: Array<History> = [
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 1,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
    {
      type: 0,
      description: 'Conciliação automática de [00 - NF - 000000000 - 000000 - 00]',
      user: '',
      date: '2022-02-25',
    },
  ]

  errorDateRange: DateRangeError = {
    error: false,
    initialMessage: 'Data inicial deve ser menor ou igual a data final.',
    endMessage: 'Data final deve ser menor ou igual a data inicial.',
  };

  get toggleIcon(): string {
    return this.showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down';
  }

  get toggleTooltip(): string {
    return this.showFilter ? 'Ocultar Parâmetros' : 'Exibir Parâmetros';
  }

  mounted() {
    this.handlerLoadHistory();
  }

  handlerToggle(): void {
    this.showFilter = !this.showFilter;
  }

  formateType(type: number): string {
    return type ? 'Manual' : 'Automático';
  }

  validateDateRanges(data: HistoryFilterData): boolean {
    const initialDue = data.initial_date;
    const endDue = data.end_date;

    const areValidDueDates = validateDateRange(initialDue, endDue);

    if (!areValidDueDates) {
      this.errorDateRange.error = true;
      this.$notification.error('Intevalo de datas inválido!');
    } else {
      this.errorDateRange.error = false;
    }

    return areValidDueDates;
  }

  handlerLoadHistory(): void {
    const validatedDateRange = this.validateDateRanges(this.data);
    const validatedFilter = this.formFilter.validate();

    if (validatedDateRange && validatedFilter) {
      console.log(this.data);
      this.loading = true;

      setTimeout(() => {
        this.showFilter = false;
        this.showItems = true;
        this.loading = false;
      }, 2500);
    }
  }
}
