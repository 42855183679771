import Customer from '@/domain/models/Customer';

export default class StatementConciliationSettleAccountData {
  constructor(
    public initial_emission_date: string,
    public end_emission_date: string,
    public initial_due_date: string,
    public end_due_date: string,
    public is_open: string,
    public value?: string,
    public prefix?: string,
    public number?: string,
    public types?: Array<string>,
    public customers?: Array<Customer>,
    public sort?: string,
  ) {}

  static make(data: any): StatementConciliationSettleAccountData {
    const customers = data.customers
      .map((customer: any) => Customer.make(customer));

    return new StatementConciliationSettleAccountData(
      data.initial_emission_date,
      data.end_emission_date,
      data.initial_due_date,
      data.end_due_date,
      data.is_open,
      data.value,
      data.prefix,
      data.number,
      data.types,
      customers,
      data.sort,
    );
  }
}
