import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000","scrollable":""},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_c(VRow,{attrs:{"justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_vm._v(" Ocorrências do Título "+_vm._s(_vm.formatTitle(_vm.item))+" ")]),_c(VCol,{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto","xs":"auto"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c(VCardText,{staticStyle:{"maxHeight":"400px"}},[(_vm.loading)?_c('span',[_vm._v("Carregando...")]):(!_vm.loading && _vm.occurrences.length === 0)?_c('span',[_vm._v("Ocorrência não encontrada.")]):[_c(VDataTable,{attrs:{"headers":[
          { text: 'Data e Hora', value: 'date', sortable: false },
          { text: 'Código', value: 'code', sortable: false },
          { text: 'Descrição', value: 'description', sortable: false } ],"items":_vm.occurrences,"hide-default-footer":""}})]],2),_c(VCardActions,{staticClass:"justify-end"},[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"6","sm":"6","xs":"12"}},[_c(VBtn,{attrs:{"dark":"","block":"","depressed":"","color":"warning"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Fechar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }