import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":_vm.small,"icon":""},on:{"click":_vm.toggle}},'v-btn',attrs,false),on),[_c(VIcon,{class:{ rotate: _vm.model },attrs:{"dense":_vm.small}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.model ? 'Ocultar' : 'Exibirr'))])])}
var staticRenderFns = []

export { render, staticRenderFns }