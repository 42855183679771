export default class BillingMethod {
    constructor(
        public id: number,
        public code: string,
        public status: string,
        public description: string,
        public method_type?: string,
        public company_group_id?: number,
        public company_id?: number,
    ) {}

    static make(data: any): BillingMethod {
        return new BillingMethod(
            data.id,
            data.code,
            data.status,
            data.description,
            data.method_type,
            data.company_group_id,
            data.company_id,
        );
    }
}
