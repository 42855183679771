import AccountReceivableLog from './AccountReceivableLog';

export default class AccountReceivableDetail {
  constructor(
    public companyName: string,
    public corporateName: string,
    public tradeName: string,
    public prefix: string,
    public number: string,
    public type: string,
    public document: string,
    public value: number,
    public valueChanged: number,
    public increase: number,
    public feels: number,
    public decrease: number,
    public total: number,
    public balance: number,
    public nature: string,
    public emissionDate: string,
    public dueDate: string,
    public flowDate: string,
    public bankCode: string,
    public interactions: Array<AccountReceivableLog>,
  ) {}

  static make(data: any): AccountReceivableDetail {
    const interactions = data.interactions && data.interactions.length
      ? data.interactions.map(AccountReceivableLog.make)
      : [];

    return new AccountReceivableDetail(
      data.company_name ?? '',
      data.corporate_name ?? '',
      data.trade_name ?? '',
      data.prefix ?? '',
      data.number ?? '',
      data.type ?? '',
      data.document ?? '',
      data.value ?? 0,
      data.value_changed ?? 0,
      data.increase ?? 0,
      data.feels ?? 0,
      data.decrease ?? 0,
      data.total ?? 0,
      data.balance ?? 0,
      data.nature ?? '',
      data.emission_date ?? '',
      data.due_date ?? '',
      data.flow_date ?? '',
      data.bank_code ?? '',
      interactions,
    );
  }
}
