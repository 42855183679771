







































import {
  Component,
  Vue,
  Prop,
  Emit,
Watch,
} from 'vue-property-decorator';
import { dateGreaterOrEqualToday, formatToSpecificFormat, defaultDateFormat } from '@/utils/date';

@Component
export default class DatePickerButton extends Vue {
  public model: string = '';
  public menu: boolean = false;

  @Prop({
    type: String,
    required: true,
  }) readonly date!: string;

  @Emit('on-date-selection')
  public onDateSelection(): string {
    return this.model;
  }

  @Watch('date')
  public onValueChange(): void {
    this.model = this.date;
  }

  public created(): void {
    this.model = this.date;
  }

  public setDateToBrazilianFormat(date: string): string {
    if (date) {
      return formatToSpecificFormat(date, 'dd/MM/yyyy');
    }

    return '';
  }

  public handleEditButtonClick(): void {
    this.menu = !this.menu;
  }

  public handleDateSelected(): void {
    if (!dateGreaterOrEqualToday(this.model, defaultDateFormat)) {
      this.$notification.error('Escolha uma data atual ou futura');

      return;
    }

    this.menu = false;
    this.onDateSelection();
  }
}
