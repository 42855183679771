


























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import IStatementConciliationHistoryFilter from '@/domain/interfaces/IStatementConciliationHistoryFilter';
import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';
import { dateNow } from '@/utils/date';

@Component
export default class StatementConciliationHistory extends Vue {
  public showList: boolean = true;
  public dividerOn: boolean = false;
  public loadingButton: boolean = false;
  public initialDate: string = dateNow();
  public finalDate: string = dateNow();
  public conciliationHistory: any[] = [];
  public filters: IStatementConciliationHistoryFilter = {
    dateStart: '',
    dateEnd: '',
    operation_type: '',
    search: '',
  };
  public typeItems: any[] = [
    { text: 'Todos', value: '' },
    { text: 'Automática', value: 0 },
    { text: 'Manual', value: 1 },
  ];
  public headers: any[] = [
    {
      text: 'Tipo',
      value: 'operation_type',
      filterable: false,
      sortable: false,
      align: 'left',
      width: '10%',
    },
    {
      text: 'Usuário',
      value: 'user_name',
      filterable: false,
      sortable: false,
      align: 'center',
      width: '15%',
    },
    {
      text: 'Data e Hora',
      value: 'created_at',
      filterable: true,
      sortable: false,
      align: 'center',
      width: '20%',
    },
    {
      text: 'Descrição',
      value: 'description',
      filterable: true,
      sortable: false,
      align: 'left',
      width: '55%',
    },
  ];
  mounted() {
    this.handlerToggle();
  }
  readonly historyRepository:
    StatementConciliationRepository = new StatementConciliationRepository();

  dateFormat(value: string): string {
    const time = value;
    const date = time.substring(0, 10);
    return date.split('-').reverse().join('/');
  }
  hourFormat(value: string): string {
    const time = value;
    const hour = time.substring(11, 19);
    return hour;
  }

  async handlerToggle(): Promise<void> {
    try {
      this.loadingButton = true;
      this.filters.dateStart = this.dateFormat(this.initialDate);
      this.filters.dateEnd = this.dateFormat(this.finalDate);
      const params = this.filters;
      const result = await this.historyRepository.getHistory(params);
      this.conciliationHistory = result;
    } catch (error: any) {
      console.error(error);
      this.$notification.error(error);
    } finally {
      if (this.conciliationHistory.length) {
        this.showList = false;
        this.dividerOn = true;
        this.loadingButton = false;
      } else {
        this.$notification.warn('Nenhum registro localizado');
        this.showList = true;
        this.dividerOn = false;
        this.loadingButton = false;
      }
    }
  }
}
