<style>

</style>
<template>
    <div>
        <div class="collapse show">
            <div class="row levels level-one"
                 style="padding-top: 6px;padding-bottom: 6px;background-color: #9bbfd8;color: black;font-weight: bold;">
                <div class="col-sm-3" style="padding-right:0;padding-left:5px;">
                    <i :class="(showSection ? 'fa fa-chevron-right' : 'fa fa-chevron-right') + ' item-collapse'" data-toggle="collapse" @click="toggle"
                       style="font-size: 11px;padding-top: 3px;float: left;cursor: pointer;"></i>
                    <span class="description" style="padding-left: 3px;width: 185px;white-space: normal;">
                    {{spanDescription}}
                </span>
                </div>
                <div class="col-sm-8 mainInfos" style="padding-right:0;padding-left:0;">
                    <div class="row" style="padding-right:0;padding-left:0;">
                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;
                        </div>

                        <div
                                class="col-sm-2 text-right containerLevelOne containerDate"
                                style="padding-right:0;padding-left:0;"
                                v-show="verifyShowDate(dataValue.date)"
                                v-for="(dataValue, idx) in mainData.data_values" :key="'L1-' + idx">
                            <span>{{dataValue.value | formatNumber}}</span>
                        </div>

                        <div class="col-sm-1 text-center" style="padding-right:0;padding-left:0;">&nbsp;
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="showSection" v-if="childName">
                <slot :name="childName"></slot>
            </div>

        </div>
    </div>
</template>


<script>
    export default {
        props: ['typeLevel', 'dataRows', 'cashFlowHiddenDates', 'childName', 'spanDescription', 'type'],
        data() {
            return {
                showSection: false,
                description: "",
                mainData: this.dataRows
            }
        },
        watch: {
            dataRows: function (newVal, oldVal) {
                var self = this;
                self.mainData = newVal;
            }
        },
        methods: {
            toggle() {
                this.showSection = !this.showSection;
                //this.mainData.show = !this.showSection;
            },
            verifyShowDate(dateParam) {
                var self = this;
                var lShow = true;
                for (var i = 0; i < self.cashFlowHiddenDates.length; i++) {
                    if (self.cashFlowHiddenDates[i].dateHidden == dateParam || self.cashFlowHiddenDates[i].dateNoConvert == dateParam) {
                        lShow = false;
                        break;
                    }
                }

                return lShow;

            },
        }
    }
</script>
