class DocumentFormattation {
  public chooseTheValidDocumentFormattation(rawDocument: string): string {
      if (rawDocument === null || typeof rawDocument === 'undefined' || rawDocument === '') {
          return '';
      }

      switch (rawDocument.length) {
          case 11: return this.formatToCpf(rawDocument);
          case 14: return this.formatToCnpj(rawDocument);
          default: return '';
      }
  }

  public formatToCnpj(rawDocument: string): string {
      return this.applyMask(rawDocument, '##.###.###/####-##');
  }

  public formatToCpf(rawDocument: string): string {
      return this.applyMask(rawDocument, '###.###.###-##');
  }

  public removeFormattationFromCnpj(rawDocument: string): string {
    return rawDocument
        .replaceAll('.', '')
        .replaceAll('/', '')
        .replaceAll('-', '');
  }

  public formatPhoneNumber(phoneNumber: string|null|undefined): string {
      if (!phoneNumber) {
          return '';
      }

      const phoneNumberWithoutEmptySpaces = phoneNumber.trim();
      const divisionIndex = phoneNumberWithoutEmptySpaces.length === 9 ? 5 : 4;

      const firstPart = phoneNumberWithoutEmptySpaces.slice(0, divisionIndex);
      const secondPart = phoneNumberWithoutEmptySpaces.slice(divisionIndex);

      return `${firstPart}-${secondPart}`;
  }

  public getMaskBasedOnPhoneNumber(phoneNumber: string): string {
    return phoneNumber.length === 9 ? '#####-####' : '####-####';
  }

  private applyMask(value: string, mask: string): string {
      let valueIndex = 0;
      let maskedValue = '';

      const maskLength = mask.length;
      for (let i = 0; i < maskLength; i += 1) {
          const maskChar = mask[i];
          if (maskChar == '#') {
              maskedValue += value[valueIndex];
              valueIndex += 1;
          } else {
              maskedValue += maskChar;
          }
      }

      return maskedValue;
  }
}

export default new DocumentFormattation();
