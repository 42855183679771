<template>
  <div class="mx-4">
    <v-card flat>
      <v-container class="m-portlet px-0" fluid :loading="loading">
        <v-row class="px-8 py-5">
          <v-col xl="3" lg="3" md="6" sm="6" xs="12">
            <slot name="search"></slot>
          </v-col>
          <v-col xl="9" lg="9" md="6" sm="6" xs="12">
            <slot name="actions"></slot>
          </v-col>
        </v-row>

        <v-divider v-show="paramnsVisibility" />
        <v-slide-y-reverse-transition>
          <v-row class="px-8 py-5" v-show="paramnsVisibility">
            <v-col cols="12">
              <slot name="paramns"></slot>
            </v-col>
          </v-row>
        </v-slide-y-reverse-transition>
      </v-container>
    </v-card>

    <v-card class="m-portlet" flat v-show="tableVisibility">
      <v-container class="m-portlet px-0" fluid>
        <slot name="table"></slot>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ParamsListStructure',
  props: {
    loading: Boolean,
    paramnsVisibility: Boolean,
    tableVisibility: Boolean,
  },
};
</script>

<style></style>
