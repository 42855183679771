import { DataOptions } from 'vuetify';
import DataTableFooterOptions from '@/types/DataTableFooterOptions';

export default class DataTableHelper {
  public static getDefaultFooterProps(
    customFooterProps: Partial<DataTableFooterOptions> = {},
  ): DataTableFooterOptions {
    return { 'items-per-page-options': [5, 10, 15, 50], ...customFooterProps };
  }

  public static getDefaultDataTableOptions(
    customDataTableOptions: Partial<DataOptions> = {},
  ): DataOptions {
    return {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      ...customDataTableOptions,
    };
  }

  public static formatTableOrdenationColumn(
    direction: boolean | undefined,
    column: string | undefined,
  ): string {
    const isDescDirection = direction ?? false;
    const definedColumn = column ?? '';

    return `${isDescDirection ? '-' : ''}${definedColumn}`;
  }
}
