<template>
  <v-container class="ticket-container">
    <v-row
      v-if="loading"
      class="footer-row d-flex justify-center align-content-center"
    >
      <v-col xs="1" sm="1" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          class="mx-auto"
          size="48"
        ></v-progress-circular>
      </v-col>
      <v-col xs="12" sm="12">
        <h2 class="text-center">Preparando o download!</h2>
      </v-col>
    </v-row>
    <v-row v-if="error" justify="center" align="center" class="footer-row">
      <v-col xs="12" sm="6">
        <h2 class="text-center">
          <v-icon size="34" class="timer-icon">
            mdi-alert-cicle-outline
          </v-icon>
          {{ error }}
        </h2>
      </v-col>
    </v-row>
    <v-row
      v-if="!error && !loading"
      justify="center"
      align="center"
      class="footer-row"
    >
      <v-col xs="12" sm="6">
        <h2 class="text-center">
          <v-icon size="34" class="timer-icon"> mdi-file-check-outline </v-icon>
          Download concluído!
        </h2>
      </v-col>
    </v-row>
    <token-pages-footer />
  </v-container>
</template>

<script>
/* eslint-disable no-undef */
import TokenPagesFooter from '../../components/defaults/TokenPagesFooter.vue';

export default {
  components: { TokenPagesFooter },
  name: 'TicketDownload',
  props: {
    token: null,
  },
  data() {
    return {
      loading: true,
      error: null,
    };
  },
  mounted() {
    this.loadTicket();
  },
  methods: {
    loadTicket() {
      this.$http.get(`${this.$store.state.API_BASE}boleto/${this.token}`,
      { responseType: 'blob' }).then(
        (result) => {
          var file = new Blob([result.bodyBlob], {
              type: 'application/pdf',
          });

          let filename = 'boleto.pdf';
          const disposition = result.headers.get('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=(([""]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/[""]/g, '');
            }
          }
          saveAs(file, filename);

          this.loading = false;
        },
        (error) => {
          const message = error.body?.data || 'Atenção! Este link é inválido ou já expirou!';

          this.error = message;
          this.loading = false;
        },
      );
    },
  },
};
</script>

<style>
.ticket-container.container {
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.7vh;
}
.ticket-container .footer-row {
  height: calc(100% - 80px);
  margin: 0;
}
.ticket-container h2 {
  font-size: 1.6rem;
  margin: 0;
}
</style>
