export default class PayrollSelectItem {
  constructor(
    public id: number,
    public idCustomer: string,

    public branch: string,
    public name: string,
    public prefix: string,
    public number: string,
    public installment: string,
    public type: string,
    public value: number,
    public emissionDate: string,
    public dueDate: string,

    public supplierId: number,
    public supplierIdCustomer: string,
    public supplierCode: string,
    public supplierName: string,
    public suppplierDocument: string,
  ) {}

  static make(data: any): PayrollSelectItem {
    return new PayrollSelectItem(
      data.id,
      data.id_customer ?? '',
      data.branch ?? '',
      data.name ?? '',
      data.prefix ?? '',
      data.number ?? '',
      data.installment ?? '',
      data.type ?? '',
      data.value ?? 0,
      data.emission_date ?? '',
      data.due_date ?? '',

      data.supplier_id ?? 0,
      data.supplier_id_customer ?? '',
      data.supplier_code ?? '',
      data.supplier_name ?? '',
      data.suppplier_document ?? '',
    );
  }
}
