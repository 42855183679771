import Repository from '@/repositories/base/Repository';
import CompanyConfigurations from '@/domain/models/CompanyConfigurations';

export default class CompanyConfigurationsRepository extends Repository {
async getCompanyConfigurationByCompanyId(
  companyGroupId: number,
  companyId: number,
) : Promise<CompanyConfigurations> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/configurations`, {
      headers: { 'company-id': companyId },
    });

    return CompanyConfigurations.make(response.data);
  }
}
