






























import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import ISettledBankAccountTransaction from '@/views/CnabRemittanceGenerated/interfaces/ISettledBankAccountTransaction';

@Component
export default class SettleBankReturnResumeTableTitleColumn extends Vue {
  @Prop({
    type: Number,
    required: true,
  }) readonly settledBankReturnType!: 0 | 1;

  @Prop({
    type: Object,
    required: true,
  }) readonly transaction!: ISettledBankAccountTransaction;

  public get getProviderCustomerSubTitle(): string {
    switch (this.settledBankReturnType) {
      case 0: return 'Fornecedor';
      case 1: return 'Cliente';
      default: return '';
    }
  }
}
