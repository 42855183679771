








































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import UserGroup from '@/domain/models/UserGroup';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import OnRequestTableOptions from '@/types/OnRequestTableOptions';
import TableUtils from '@/utils/tableUtils';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import IFieldChoises from '@/types/IFieldChoises';

@Component
export default class UserGroupList extends Vue {
  public filterTerm: string = '';

  public totalItems: number = 0;

  public loading: boolean = false;

  public userGroups: UserGroup[] = [];
  public readonly statusItems: IFieldChoises[] = [
    { value: '', label: 'Todos os status' },
    { value: '0', label: 'Inativos' },
    { value: '1', label: 'Ativos' },
  ];
  public readonly headers: IVDataTableHeader[] = [
    {
      text: 'Status',
      value: 'status',
      filterable: false,
      sortable: false,
    },
    {
      text: 'Grupo Empresa',
      value: 'companyGroup.description',
      filterable: false,
      sortable: false,
    },
    {
      text: 'Nome do Grupo',
      value: 'name',
      filterable: true,
      sortable: false,
    },
    {
      text: '',
      value: 'edit',
      filterable: false,
      class: 'edit-columns',
      cellClass: 'edit-columns',
      sortable: false,
    },
  ];
  public dataTableOptions: DataOptions = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
  }
  public footerOptions = {
    'items-per-page-options': [5, 10, 15, 50],
  }

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    this.getUserGroups();
  }

  public handleSearch(search: string): void {
    const resetToFirst = !!this.filterTerm;

    this.filterTerm = search;
    this.getUserGroups({ resetToFirst });
  }

  public async getUserGroups(onRequestTableOptions: OnRequestTableOptions = {}): Promise<void> {
    if (this.loading) {
      return;
    }

    if (onRequestTableOptions.resetToFirst) {
      this.dataTableOptions.page = 1;
    }

    this.loading = true;

    const { sortDesc, sortBy } = this.dataTableOptions;
    const tableConfigParams: ILaravelOrdenationTableParams = {
      filter: { search: this.filterTerm },
      sort: TableUtils.tableOrdenationFormatterToLaravel(sortDesc[0], sortBy[0]),
      page: this.dataTableOptions.page,
      items_per_page: this.dataTableOptions.itemsPerPage,
    };

    try {
      const { data, meta } = await new UserGroupRepository().getAll(tableConfigParams);

      this.userGroups = data;
      this.totalItems = meta.total;
    } catch (error) {
      this.$notification.error('Opa! Parece que algo saiu errado, tente recarregar a página');
    } finally {
      this.loading = false;
    }
  }
  public handleNewGroup(): void {
    this.$router.push('/grupos-de-usuario/novo');
  }

  public getStatusColor(status: boolean): string {
    return status ? 'success' : '';
  }

  public getStatusText(status: boolean): string {
    return status ? 'Ativo' : 'Inativo';
  }
  public handleEdit(id: number): void {
    this.$router.push(`/grupos-de-usuario/${id}`);
  }
}
