export default class StringHelper {
  public static capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public static setStringWithSubQuoteValid(value: string|number): string|number {
    if (typeof value === 'number') {
      return value;
    }

    return value.replace(/[']/g, '\'$&');
  }

  public static isAlphanumeric(string: string): boolean {
    if (string.length < 1) {
      return false;
    }

    for (let i = 0; i < string.length; i += 1) {
      const code = string.charCodeAt(i);
      if (
          !(code > 47 && code < 58) // numeric (0-9)
          && !(code > 64 && code < 91) // upper alpha (A-Z)
          && !(code > 96 && code < 123) // lower alpha (a-z)
        ) {
        return false;
      }
    }
    return true;
  }

  public static isEmail(mailString: string): boolean {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mailString);
  }
}
