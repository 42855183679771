import Company from '@/domain/models/Company';
import CompanyBySearch from '@/domain/models/CompanyBySearch';
import Repository from '@/repositories/base/Repository';

export default class CompanyRepository extends Repository {
  async getAllActivie() : Promise<Company[]> {
    const { status, data: response } = await this.datasource.get('/company/listAllActivie');
    if (status == 200) {
      return response.data.map(Company.make);
    }
    throw new Error('Aconteceu um erro inesperado');
  }

  async getCompaniesByLoggedUser(companyGroupId: number): Promise<Company[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/companies`);

    return response.data.map(Company.make);
  }

  async getCompanyBySearch(
    companyGroupId: number,
    companyIds: number[],
    search: string,
  ): Promise<CompanyBySearch[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/companies/search`, {
      params: { search },
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return response.data.map(CompanyBySearch.make);
  }

  public async getCompanyByIds(
    companyGroupId: number,
    companyIds: number[],
  ): Promise<CompanyBySearch[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/companies/ids`, {
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return response.data.map(CompanyBySearch.make);
  }
}
