import Repository from '@/repositories/base/Repository';
import CreditTotalizer from '@/domain/models/CreditTotalizer';
import IUpsertTotalizerParams from '@/repositories/parameters/IUpsertTotalizerParams';
import CreditTotalizerList from '@/domain/models/CreditTotalizerList';

export default class CreditTotalizersRepository extends Repository {
  public async getTotalizerById(
    companyGroupId: string,
    companyId: number,
    id: number,
  ): Promise<CreditTotalizer> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/credit-totalizers/${id}`, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });

    return CreditTotalizer.make(response.data);
  }

  public async getTotalizersFromCompanyIds(
    companyGroupId: number,
    companyIds: number[],
    origin: 'list' | 'options',
  ): Promise<CreditTotalizerList[]> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/credit-totalizers`, {
      params: { origin },
      headers: { 'company-ids': JSON.stringify(companyIds) },
    });

    return response.data.map(CreditTotalizerList.make);
  }

  public async insertTotalizer(
    companyGroupId: string,
    params: IUpsertTotalizerParams,
  ): Promise<void> {
    await this.datasource.send(`company-groups/${companyGroupId}/credit-totalizers`, params);
  }

  public async updateTotalizer(
    companyGroupId: string,
    params: IUpsertTotalizerParams,
    id: number,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroupId}/credit-totalizers/${id}`, params);
  }

  public async removeTotalizer(
    companyGroupId: string,
    companyId: number,
    id: number,
  ): Promise<void> {
    await this.datasource.destroy(`company-groups/${companyGroupId}/credit-totalizers/${id}`, {
      headers: { 'company-id': JSON.stringify(companyId) },
    });
  }
}
