














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Module from '@/domain/models/Module';

@Component
export default class ModuleItem extends Vue {
  @Prop(Module) readonly moduleInfo!: Module;
  active: boolean = false;

  get iconColor() {
    if (!this.moduleInfo.isPurchased) {
      return '#CCCCCC';
    }
    if (this.active) {
      return '#45BBEB';
    }
    return '#999999';
  }

  get buttonColor() {
    if (!this.moduleInfo.isPurchased) {
      return 'grey lighten-5';
    }
    if (!this.active) {
      return 'grey';
    }
    return 'info';
  }

  created() {
    this.active = this.moduleInfo.isActive;
  }

  handlePermissonsClick() {
    this.$emit('update-permission', this.moduleInfo);
  }

  @Watch('active')
  onActiveChange(newActive: boolean) {
    this.$emit('change:active', this.moduleInfo.id, newActive);
  }
}
