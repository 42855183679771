













import {
  Component,
  Emit,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';

@Component
export default class PaymentRemittancesToggleButton
  extends Mixins<ModelManagementMixin<boolean>>(ModelManagementMixin) {
    @Prop({
      type: Boolean,
      default: false,
      required: false,
    }) readonly small?: boolean;

    @Emit('click') toggle() {
      return !this.model;
    }
}
