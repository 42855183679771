<template>
  <custom-modal
    :show="show"
    @close="closeModal"
  >
    <template v-slot:title>
      Detalhes do Título {{ item.prefixCode }} -
        {{ item.documentNumber }} - {{ item.documentType }}
    </template>

    <template v-slot:content>
      <label>
        {{item.documentNumber}}
      </label>
    </template >
  </custom-modal>
</template>

<script>
import CustomModal from '../../../components/defaults/CustomModal.vue';

export default {
	components: { CustomModal },
  name: 'BillingRemittanceDetailsModal',
  props: {
    show: Boolean,
    item: Object,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style></style>
