import EInstructionRemittanceStatus from '../enums/InstructionRemittanceStatus';
import { EInstructionRemittanceType } from '../enums/InstructionRemittanceType';
import { EInstructionRemittanceField } from '../enums/InstructionRemittanceField';

export default class InstructionRemittance {
  constructor(
    public name: string,
    public prefix: string,
    public number: string,
    public type: string,
    public balance: number,
    public bank: string,
    public id: number,
    public idCustomer: string,
    public action: EInstructionRemittanceType,
    public field: EInstructionRemittanceField,
    public oldValue: string,
    public newValue: string,
    public status: EInstructionRemittanceStatus,
    public code: string,
    public description: string,
    public branch: string,
    public installment: string,
    public companyId: number,
    public occurrenceId: number,
    public uid: string,
  ) {}

  static make(data: any): InstructionRemittance {
    const uid = `t:${data.id_customer}-i:${data.id}`;

    return new InstructionRemittance(
      data.customer_name ?? '',
      data.prefix ?? '',
      data.number ?? '',
      data.type ?? '',
      data.balance ?? 0,
      data.bank_code ?? '',
      data.id ?? 0,
      data.id_customer ?? '',
      data.action,
      data.field_name,
      data.old_value ?? '',
      data.new_value ?? '',
      data.status ?? EInstructionRemittanceStatus.PENDING,
      data.code ?? '',
      data.description ?? '',
      data.branch ?? '',
      data.installment ?? '',
      data.company_id,
      data.cnab_occurrence_id,
      uid,
    );
  }
}
