export default class PaymentRemittanceComment {
  constructor(
    public userName: string,
    public type: string,
    public content: string,
    public createdAt: string,
  ) {}

  static make(data: any): PaymentRemittanceComment {
    return new PaymentRemittanceComment(
      data.userName ?? '',
      data.type ?? '',
      data.content ?? '',
      data.createdAt ?? '',
    );
  }
}
