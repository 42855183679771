export default class CompanyConfigurations {
  constructor(
		public advanceCurrencyType: string,
		public advancePrefix: string,
		public advanceType: string,
    public ddaCurrencyType: string,
    public ddaPrefix: string,
    public ddaType: string,
    public bankTransactionCurrencyType: string,
    public bankTransactionTransferCurrencyType: string,
  ) { }

  static make(data: any): CompanyConfigurations {
    return new CompanyConfigurations(
      data.advance_currency_type,
      data.advance_prefix,
      data.advance_type,
      data.dda_currency_type,
      data.dda_prefix,
      data.dda_type,
      data.bank_transaction_currency_type,
      data.bank_transaction_transfer_currency_type,
    );
  }
}
