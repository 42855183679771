






































































































































































































































































































































  import {
    Vue,
    Component,
    Ref,
    Prop,
    Watch,
    Emit,
  } from 'vue-property-decorator';
  import { PropType } from 'vue';
  import { DataOptions } from 'vuetify';
  import { VForm } from '@/types/VForm';
  import IVDataTableHeader from '@/types/IVDataTableHeader';
  import SelectOptions from '@/domain/interfaces/ISelectOptions';

  import DDAConciliation from '@/domain/models/DDAConciliation';
  import DDAConciliationMovement from '@/domain/models/DDAConciliationMovement';
  import IDDAConciliationInvoice from '@/domain/interfaces/IDDAConciliationInvoice';

  import DDAConciliationRepository from '@/repositories/DDAConciliationRepository';

  import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
  import FilterParameterDDAConciliationSearch from '@/domain/models/filter-parameters/FilterParameterDDAConciliationSearch';
  import FilterParametersRepository from '@/repositories/FilterParametersRepository';

  import OriginType from '@/domain/enums/DDAConciliationOriginType';
  import ActionType from '@/domain/enums/DDAConciliationActionType';
  import StatusType from '@/domain/enums/DDAConciliationStatusType';

  import { formateDate, validateDateRange } from '@/utils/date';

  import DdaConciliationResolve from './Resolve.vue';

  import {
    scrollTo,
    formateCNPJ,
    formateCurrency,
    formateErrorForNotification,
  } from '../utils';
  import { IConciliation } from '../utils/interfaces';

  interface DateRangeError {
    emission: boolean,
    due: boolean,
    initialMessage: string,
    endMessage: string,
  }

  @Component({
    components: {
      DdaConciliationResolve,
    },
  })
  export default class DDAConciliationSearchERP extends Vue {
    @Ref('formSearchERP') readonly formSearchERP!: VForm;

    @Prop(Boolean) readonly open!: boolean;

    @Prop({
      type: Object as () => DDAConciliation,
    }) readonly item!: DDAConciliation;

    @Prop({
      type: Array as PropType<Array<number>>,
    }) readonly initialCompanies!: Array<number>;

    @Watch('open')
    changedOpen(open: boolean) {
      if (open) {
        this.data = {
          ...this.data,
          companies: this.initialCompanies,
          per_page: 10,
          page: 1,
        };
        this.loadData();
      } else {
        this.data = {} as IDDAConciliationInvoice;
        this.currentData = {} as IDDAConciliationInvoice;
        this.items = [];
        this.selectedItems = [];
        this.suppliers = [];
        this.serverItemsLength = 0;
        this.searchSuppliers = '';
        this.dataTableOptions = {
          ...this.dataTableOptions,
          page: 1,
          itemsPerPage: 10,
        };
        this.formSearchERP.resetValidation();
      }
    }

    @Watch('searchSuppliers')
    changedSearchSuppliers(search: string) {
      if (search && search.length) {
        this.suppliersDebounce(search);
      } else {
        clearTimeout(this.timerSuppliers);
      }
    }

    @Watch('dataTableOptions')
    pagination() {
      if (!this.loading && !this.loadingItems && this.open) {
        this.currentData = {
          ...this.currentData,
          page: this.dataTableOptions.page,
          per_page: this.dataTableOptions.itemsPerPage,
        };
        this.handlerLoadERPs(this.currentData);
        scrollTo('search-erp-table');
      }
    }

    @Emit()
    close(): ActionType {
      return ActionType.SEARCH_ERP;
    }

    @Emit()
    reload(): boolean {
      this.close();
      return true;
    }

    readonly formateDate = formateDate;
    readonly formateCNPJ = formateCNPJ;
    readonly formateCurrency = formateCurrency;

    readonly filterParametersRepository:
      FilterParametersRepository = new FilterParametersRepository();

    readonly DDAConciliationRepository:
      DDAConciliationRepository = new DDAConciliationRepository();

    data: IDDAConciliationInvoice = {} as IDDAConciliationInvoice;
    currentData: IDDAConciliationInvoice = {} as IDDAConciliationInvoice;

    selectedItems: Array<DDAConciliationMovement> = [];
    items: Array<DDAConciliationMovement> = [];
    serverItemsLength: number = 0;

    openResolve: boolean = false;
    itemResolve: DDAConciliation = {} as DDAConciliation;

    loading: boolean = false;
    loadingTypes: boolean = false;
    loadingItems: boolean = false;
    loadingSuppliers: boolean = false;
    loadingCompanies: boolean = false;

    companies: Array<SelectOptions> = [];
    suppliers: Array<SelectOptions> = [];
    timerSuppliers!: ReturnType<typeof setTimeout>;
    searchSuppliers: string = '';

    types: Array<SelectOptions> = [];

    headers: Array<IVDataTableHeader> = [
      { text: '', value: 'data-table-select' },
      { text: 'Emissão', value: 'emission_date' },
      { text: 'Vencimento', value: 'due_date' },
      { text: 'Título', value: 'title' },
      { text: 'Razão Social', value: 'name' },
      { text: 'Valor', value: 'value' },
      { text: 'Saldo', value: 'balance' },
    ];

    dateRangeError: DateRangeError = {
      emission: false,
      due: false,
      initialMessage: 'Data inicial deve ser menor ou igual a data final',
      endMessage: 'Data final deve ser menor ou igual a data inicial',
    };

    dataTableOptions: DataOptions = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    };

    dataTableFooterOptions: Object = {
      'items-per-page-options': [10, 50, 100],
      'disable-items-per-page': false,
      'disable-pagination': false,
    }

    get mainMovement() {
      return this.item.movements
        ?.find((mov) => mov.origin === OriginType.DDA) || {} as DDAConciliationMovement;
    }

    get mainMovementDocument() {
      return this.mainMovement.document;
    }

    get mainMovementBranch() {
      return this.mainMovement.branch_code;
    }

    get mainMovementTitle() {
      return this.mainMovement.title;
    }

    get mainMovementValue() {
      return this.mainMovement.value;
    }

    get mainMovementEmission() {
      return this.mainMovement.emission_date;
    }

    get mainMovementDue() {
      return this.mainMovement.due_date;
    }

    get mainMovementCompanyId() {
      return this.mainMovement.company_id;
    }

    get groupId(): number {
      return this.$session.get('company_group_id');
    }

    get selectedItemsTotal(): number {
      return this.selectedItems
        .reduce((total, item) => total + (item.balance ?? 0), 0);
    }

    get diff(): number {
			return this.mainMovementValue - this.selectedItemsTotal;
    }

    changedCompanies(): void {
      this.suppliers = [];
      this.data.suppliers = [];
    }

    validateAllDateRanges(data: IDDAConciliationInvoice): boolean {
      const initialEmission = data.initial_emission_date;
      const endEmission = data.end_emission_date;
      const initialDue = data.initial_due_date;
      const endDue = data.end_due_date;

      const areValidEmissionDates = validateDateRange(initialEmission, endEmission);
      const areValidDueDates = validateDateRange(initialDue, endDue);

      if (!areValidEmissionDates) {
        this.dateRangeError.emission = true;
        this.$notification.error('Intevalo de emissão inválido!');
      } else {
        this.dateRangeError.emission = false;
      }

       if (!areValidDueDates) {
        this.dateRangeError.due = true;
        this.$notification.error('Intevalo de vencimento inválido!');
      } else {
        this.dateRangeError.due = false;
      }

      if (!areValidEmissionDates && !areValidDueDates) {
        this.$notification.error('Intevalo de emissão e de vencimento inválidos!');
      }

      return areValidEmissionDates && areValidDueDates;
    }

    handlerFilter(): void {
      const validated = this.validateAllDateRanges(this.data)
        ? this.formSearchERP.validate()
        : false;

      if (validated) {
        this.currentData = {
          ...this.data,
          page: 1,
          per_page: this.dataTableOptions.itemsPerPage,
        };
        this.handlerLoadERPs(this.currentData);
      }
    }

    suppliersDebounce(search: string): void {
      clearTimeout(this.timerSuppliers);
      this.timerSuppliers = setTimeout(() => {
        this.loadSuppliers(search);
      }, 500);
    }

    getTitleSelectedItem(item: any): string {
      let title = '';

      title = `
        ${item.title} - 
        ${formateDate(item.emission_date)} - 
        ${formateCurrency(item.value)}
      `;

      return title;
    }

    removeSelectedItem(item: any): void {
      const index = this.selectedItems.indexOf(item);
      this.selectedItems.splice(index, 1);
    }

    async loadData(): Promise<void> {
      try {
        this.loading = true;
        await this.loadCompanies();
        if (!this.types.length) await this.loadTypes();
        await this.loadFilterParameters();
      } catch (error: any) {
        const message = formateErrorForNotification(error);
        this.$notification.error(message);
      } finally {
        this.loading = false;
      }
    }

    async loadFilterParameters(): Promise<void> {
      try {
        const filterParameters = await this.filterParametersRepository
          .getFilterByGroup(GroupFilterParametersEnum.DDA_CONCILIATION_SEARCH_ERP);
        const definedFilters = FilterParameterDDAConciliationSearch.make(filterParameters);

        this.data.initial_emission_date = definedFilters.initial_emission_date;
        this.data.end_emission_date = definedFilters.end_emission_date;
        this.data.initial_due_date = definedFilters.initial_due_date;
        this.data.end_due_date = definedFilters.end_due_date;
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
      }
    }

    async loadCompanies(): Promise<void> {
      try {
        this.loadingCompanies = true;
        const companies = await this.DDAConciliationRepository.getCompanies();
        this.companies = companies;
      } catch (error: any) {
        const errorMessage = formateErrorForNotification(error);
        this.$notification.error(errorMessage);
      } finally {
        this.loadingCompanies = false;
      }
    }

    async loadSuppliers(search: string): Promise<void> {
      try {
        this.loadingSuppliers = true;
        const { companies } = this.data;
        const suppliers = await this.DDAConciliationRepository.getSuppliers(
          this.groupId, companies ?? [], search,
        );

        this.suppliers.push(...suppliers);
      } catch (error: any) {
        const errorMessage = formateErrorForNotification(error);
        this.$notification.error(errorMessage);
      } finally {
        this.loadingSuppliers = false;
      }
    }

    async loadTypes(): Promise<void> {
      try {
        this.loadingTypes = true;

        const types = await this.DDAConciliationRepository.getPaymentAccountsTypes();
        this.types = types;
      } catch (error: any) {
        const errorMessage = formateErrorForNotification(error);
        this.$notification.error(errorMessage);
      } finally {
        this.loadingTypes = false;
      }
    }

    async handlerLoadERPs(parameters: IDDAConciliationInvoice): Promise<void> {
      try {
        this.loadingItems = true;
        this.dataTableFooterOptions = {
          ...this.dataTableFooterOptions,
          'disable-items-per-page': true,
          'disable-pagination': true,
        };

        const data: IDDAConciliationInvoice = {
          ...parameters,
          value: parameters.value == '0' ? undefined : parameters.value,
        };

        await this.loadERPs(data);

        this.filterParametersRepository
          .setFilter(GroupFilterParametersEnum.DDA_CONCILIATION_SEARCH_ERP, [
            { key: 'initial_due_date_dda_conciliation_search', value: parameters.initial_due_date },
            { key: 'end_due_date_dda_conciliation_search', value: parameters.end_due_date },
            { key: 'initial_emission_date_dda_conciliation_search', value: parameters.initial_emission_date },
            { key: 'end_emission_date_dda_conciliation_search', value: parameters.end_emission_date },
          ]);
      } catch (error: any) {
        const errorMessage = formateErrorForNotification(error);
        this.$notification.error(errorMessage);
      } finally {
        this.dataTableFooterOptions = {
          ...this.dataTableFooterOptions,
          'disable-items-per-page': false,
          'disable-pagination': false,
        };
        this.loadingItems = false;
      }
    }

    async loadERPs(parameters: IDDAConciliationInvoice): Promise<void> {
      const { companies } = this.data;
      const { data, meta } = await this.DDAConciliationRepository
        .getERPs(this.groupId, companies ?? [], parameters);

      this.items = data;
      this.serverItemsLength = meta;
    }

    handleSave() {
      if (this.selectedItems.length <= 0) {
				this.$notification.error('Nenhuma registro selecionado!');
        return;
			}

      const haveDivergence = (this.mainMovement.value != this.selectedItems[0].value)
        || (this.mainMovement.due_date != this.selectedItems[0].due_date);

      if (haveDivergence) {
        this.itemResolve = {
          status: StatusType.DIVERGENCE,
          movements: [
            this.mainMovement,
            { ...this.selectedItems[0], origin: OriginType.ERP, selected: true },
          ],
        };
        this.openResolve = true;
      } else {
        this.conciliation();
      }
    }

    closeResolve() {
      this.openResolve = false;
    }

    async conciliation(resolve?: { hasDivergence: boolean, dueDate: string | undefined }) {
      try {
        this.$dialog.startLoading();

        const group = this.groupId;

        const data: IConciliation = {
          id: +this.mainMovement.id,
          id_customer: `${this.selectedItems[0].id}`,
          has_divergence: resolve?.hasDivergence ?? false,
          due_date: resolve?.dueDate ?? undefined,
        };

        const ddaCompany = this.mainMovement.company_id;

        const success = await this.DDAConciliationRepository.conciliation(group, ddaCompany, data);

        if (success) {
          this.$notification.success('Conciliação gerada com sucesso!');
          this.reload();
        }
      } catch (error: any) {
        this.$dialog.stopLoading();
        const message = formateErrorForNotification(error);
        this.$notification.error(message);
      }
    }
  }
